<template> 
    <html>
        
        <v-card class="pa-2 oculto-impresion">
            <v-btn  x-large class="primary mx-5 oculto-impresion" @click="ImprimirUSB()"> <v-icon>mdi-printer</v-icon>Imprimir </v-btn>
			<v-btn x-large class="primary mx-5 oculto-impresion" @click="ImprimirRed()"> <v-icon>mdi-printer</v-icon>Imprimir Red</v-btn>
            <v-btn  x-large class="info mx-5 oculto-impresion" to="/facturar/0/0"> <v-icon>mdi-cash-register</v-icon>Continuar Facturando </v-btn>
            <v-btn  x-large class="success mx-5 oculto-impresion" to="/repVentas"> <v-icon>mdi-chart-areaspline</v-icon>Ver Reporte </v-btn>
        </v-card>
    <head>
        <link rel="stylesheet" href="style.css"> 
    </head>
    <body class="content">
        <div class="ticket">
            <img
                :src="Logo2"
                alt="Logotipo" >
               <div style="font-weight: bold; font-size:12px;" v-for="det in localidadencabezado" :key="det.nombre" id="centrado">
                 
				<p class="centrado "> 
                    <b class="primary--text" >{{det.nombre}} </b>  <br>{{det.razonSocial}},<br> {{det.dirreccion}}, Costa Rica <br>
					{{det.cedulaJuridica}} <br> {{det.telefono}}  <br>
                    {{det.correo}} <br> 
                    ------------------------------
					Vendedor: {{vendedor}} <br>
                    Fecha: {{fecha}} {{hora}}  <br>
                    Fact Interno: {{$route.params.id}} <br>
					{{tipoDocumento}} <br> 
					{{tipoPago}} <br> {{nombreReceptor}}  {{cedulaReceptor}} <br>
					{{nombreFantasia}} <br>
					{{telefono}} <br>
				</p>
			</div>
               ------------------------
            <table style="font-size:12px;">
                <thead>
                    <tr>
                        <th class="producto">Cant</th>
                        <th class="precio">Nombre</th>
                        <th class="precio">Precio</th>
                        
                    </tr>
                </thead>
                <tbody style="font-weight: bold;">
                  <tr v-for="det in detalles" :key="det.noFacturaReal">
                    <td style="text-align:center;"> {{det.cantidad}} </td>
                    <td style="text-align:center;">{{det.descripcion}}</td>
                    <td v-if="ModoCant == true" style="text-align:center;">{{det.precioUnitario }}</td>
                    <td v-if="ModoCant == false" style="text-align:center;">{{det.precioUnitario * det.cantidad }}</td>
                  </tr>
                </tbody>
                  
            </table>
			------------------------
			<table id="centrado" style="font-size:12px; text-align:center;">
                <thead>
                    <tr>
                        <th class="producto">Imp1%</th>
                        <th class="precio">Imp2%</th>
                        <th class="precio">Imp4%</th>
                        <th class="precio">Imp13%</th>
                    </tr>
                </thead>
                <tbody style="text-align:center;">
                  <tr>
                    <td style="text-align:center;"> {{imp1}} </td>
                    <td style="text-align:center;">{{imp2}}</td>
                    <td style="text-align:center;">{{imp4}}</td>
                    <td style="text-align:center;">{{imp13}}</td>
                  </tr>
                </tbody>
                  
            </table>
			<table class="centrado" style="font-size:12px; text-align:center;">
                <thead>
                    <tr>
                        <th class="producto">SubTotal:</th>
                        <th class="precio">Desc:</th>
                        <th class="precio">Vuelto:</th>
                    </tr>
                </thead>
                <tbody style="font-weight: bold;">
                  <tr>
                    <td  style="text-align:center;"> {{subtotal}} </td>
                    <td style="text-align:center;">{{(Desc).toFixed(2)}}</td>
                    <td style="text-align:center;">{{vuelto}}</td>
                  </tr>
                </tbody>
                  
            </table>
            <div class="centrado" style="font-size:20px">Total: {{total}} </div>
                ------------------------
            <p style="font-size:10px;" class="centrado ticket">¡GRACIAS POR SU COMPRA! {{clav1}} {{clav2}}
               <br> {{pieDocumento}} <br></p>
            <div style="text-align:center; ticket">
                <qrcode-vue :value="value" size="80" level="H"></qrcode-vue>
                Escanéame
            </div>    
        </div>
    </body>
    
</html>
</template>

<script >
import axios from 'axios'
import QrcodeVue from 'qrcode.vue'
  export default {
    data: () => ({
      
    dialog: false,
    
    cabeceraDetalles: [
    { text: 'Código', value: 'codigoProducto', sortable: false  },
    { text: 'Descripción', value: 'descripcion', sortable: false  },
    { text: 'Precio', value: 'actionprecio', sortable: false  },
    { text: 'Cantidad a debitar', value: 'actioncantidad', sortable: false  },
    ],
    editedIndex: -1,
    id: '',
    idrol:'',
    noFactura: 0,
    comprobanteModal: 0,
    justificacionNota: ["Ajuste de precio precio" ,"Saldo incobrable"," Pronto de pago","Error en cobro"],
    productosDatos:[],
    MensajeRespuesta: '',
    ClaveComprobante: '',
    EstadoNC: 0,
    ModalNotaCredito: false,
    total: 0,
	Desc: 0,
    justificacion: '',
    medioDePago : '',
    detalles: [],
    localidadencabezado:[],
    NombreLocalidad:'',
    CedulaLocalidad:'',
	DireccionLocalidad:'',
	tipoDocumento: '',
    Logo2: '',
    Logo3: '',
    clav1: '',
    clav2: '',
    TelefonoLocalidad:'',
    fecha: '',
    hora: '',
    nombreReceptor: '',
    cedulaReceptor:'',
	ModoCant: false,
    direccion:'',
    monedaPago: "CRC",
    telefonoReceptor:'',
    correoReceptor:'',
    noFacturaElectronica: '',
    vendedor: '',
    subtotal: 0,
    comentario: '',
	grabado:0 ,
	modoPDV:0,
	tipoPago: '',
    excento: 0,
	pieDocumento: "",
	iv: 0.0,
	vuelto: 0,
    tipo_documento: '',
    usuarios:[],    
    value: 'https://www.noah.cr',
    desserts: [],
    editedIndex: -1,
    imp: 0.0,
    imp1: 0.0,
    imp2: 0.0,
    imp4: 0.0,
    imp13: 0.0,
	telefono:'',
	nombreFantasia:'',
	descuento: 0,
	cedulaReceptor: '',
	ip1: "",
	devid2: "",
	ip2: "",
	devid1: "",
	nombreReceptor: '',
    }),

    created () {
	  this.listarLocalidad();
	  this.listarAux(this.$route.params.id);
      this.listarDetalles(this.$route.params.id);
	  this.validarImpresora();
	  this.selectPanelControl();
      
	  
      
    },
    components: {
      QrcodeVue,
    },

    methods: {
        async listarDetalles(id){
			let me=this;
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			await axios.get('api/RepVentasVistas/ListarDatosFactura/'+id+'/'+this.$store.state.usuario.localidad+'',configuracion).then(function(response){
				//console.log(response);
				me.detalles=response.data;
				me.EstadoNC = me.detalles[0].estadoNC;
				me.hora = me.detalles[0].hora;
				me.comentario = me.detalles[0].detalle;
				me.SumarDesc();
			}).catch(function(error){
				console.log(error);
			});
		},
		SumarDesc(){
			 for(var i = 0; i < this.detalles.length; ++i)
                {
                   this.Desc = this.Desc + this.detalles[i].montoDescuento;
                }
		},
		async imprimirTicket() {
			
			// Sección de detalles de los productos
			let imp13 = this.imp13.toFixed(2);
			let dataParaImprimir = {
				Localidad:this.localidadencabezado[0],
				productos: this.detalles,
				cliente: {
					nombre: this.nombreReceptor,
					cedula: this.cedulaReceptor,
					telefono: this.telefono,
					documento: this.tipoDocumento,
					tipoPago: this.tipoPago,
					copias:1,
					vendedor: this.vendedor,
					abono: 0,
					saldo:0,
				},
				moneda:"CRC",
				noFactura:this.$route.params.id,
				telefono: this.telefono,
				Fecha:this.fecha + this.hora,
				tipoPago:this.tipoPago,
				documento:this.tipoDocumento,
				total: this.total,
				subtotal:this.subtotal,
				descuento:this.Desc,
				vuelto:0,
				impEspecial: 0,
				impuestos: {
					iva13: this.imp13,
					iva1: this.imp1,
					iva2: this.imp2,
					iva4: this.imp4,
					otros: 0,
				},
				otrosDatos: {
					clave: this.clav1 +this.clav2,
					detalle:"",
				}
				
			};
			console.log(JSON.stringify(dataParaImprimir, null, 2));

			// Enviar el HTML al servidor Python para imprimir
			try {
				const response = await axios.post('http://localhost:5000/imprimir', dataParaImprimir);
				console.log('Ticket enviado a imprimir', response.data);
			} catch (error) {
				console.error('Error al enviar el ticket para imprimir', error);
			}
			this.FinalizarOrden();
		},
		async listarAux(id){
				let me=this;
				let aux = [];
                let header={"Authorization" : "Bearer " + this.$store.state.token};
                let configuracion= {headers : header};
                await axios.get('api/Facturas/ListarAuxTiquet/'+id+'/'+this.$store.state.usuario.localidad+'',configuracion).then(function(response){
                    //console.log(response);
                    aux=response.data;
					me.vuelto = aux[0].vuelto;
					me.tipoDocumento = aux[0].tipoDocumento;
					me.cedulaReceptor = aux[0].cedulaReceptor;
					me.total = aux[0].total;
					me.subtotal = aux[0].subTotal;
					me.nombreReceptor = aux[0].nombreReceptor;
					me.cedulaReceptor = aux[0].cedulaReceptor;
					me.imp1 = aux[0].impuesto1;
					me.imp2 = aux[0].impuesto2;
					me.imp4 = aux[0].impuesto4;
					me.imp13 = aux[0].impuesto13;
					me.tipoPago = aux[0].tipoPago;
					me.fecha = aux[0].fecha;
					me.vendedor = aux[0].vendedor;
					me.ClaveComprobante  = aux[0].claveComprobante;
					me.clav1 = me.ClaveComprobante.substring(0, 25);
					me.clav2 = me.ClaveComprobante.substring(25, 50);
					if(me.nombreReceptor != ''){
						me.obtenerCliente()
					}
                }).catch(function(error){
                    console.log(error);
                });	
			},
		validarImpresora(){
			let me=this;
			var localidadesArray=[];
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			axios.get('api/Impresoras/ListarLocalidad/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
				localidadesArray=response.data;

					for(var i = 0; i < localidadesArray.length; ++i){
						if(localidadesArray[i].nombreImpresora == "Caja"){
							me.ip1 = localidadesArray[i].ip
							me.devid1 = localidadesArray[i].devid
							me.existeprinter1 = true;
						}
					}
					
			})
		},
		async selectPanelControl(){  
			let me=this;
			var localidadesArray=[];
			var planes2=[];
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			await axios.get('api/PanelControls/ListarLocalidad/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
				localidadesArray=response.data;
				me.ModoCant= localidadesArray[0].modoCantidades; // Me indica si muestro unitario o total el precio de cada linea de producto
			})
			// axios.get('api/Localidades/ListarFiltrada/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
            //     planes2=response.data;
			// 	me.modoPDV = planes2[0].modoPDV;
			// 	me.localidadencabezado=planes2;
			// 	me.Logo2 = planes2[0].imagen;
			// 	me.pieDocumento = planes2[0].pieDocumento;
            // })
			
		},
        ImprimirRed(){
            var products = "";
            for(var i = 0; i < this.detalles.length; ++i)
                {
                    products += "<text>"+this.detalles[i].cantidad+"&#9; "+this.detalles[i].descripcion.substring(0, 22)+"&#9;"+this.detalles[i].precioUnitario * this.detalles[i].cantidad+"&#10;</text>"
                }
            var request = `<epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">
                <logo key1="48" key2="48"/>
                <text align="center"/>
                <text font="font_a"/>
                <text width="2" height="2"/>
                <text reverse="false" ul="false" em="false" color="color_1"/>
                <text>`+this.$store.state.usuario.localidad+`&#10;</text>
                <text width="1" height="1"/>
                <text>`+this.localidadencabezado[0].razonSocial+`&#10;</text>
                <text>`+this.localidadencabezado[0].dirreccion+`&#10;</text>
                <text>`+this.localidadencabezado[0].nombre+`&#10;</text>
                <text> `+this.localidadencabezado[0].cedulaJuridica+`&#10;</text>
                <text>`+this.localidadencabezado[0].telefono+`&#10;</text>
                <text>`+this.localidadencabezado[0].correo+`&#10;</text>
                <text reverse="false" ul="false" em="false" color="color_1"/>
                <text>---------------------------------------&#10;</text>
                <text>Vendedor: `+this.vendedor+`&#10;</text>
                <text>Fecha: `+this.fecha+`&#10;</text>
                <text>Factura: `+this.$route.params.id+`&#10;</text>
                <text>Cliente: `+this.nombreReceptor+`&#10;</text>
                <text>Cédula: `+this.cedulaReceptor+`&#10;</text>
                <text>Tipo de Documento: `+this.tipoDocumento+`&#10;</text>
                <text>`+this.tipoPago+`&#10;</text>
                <text>---------------------------------------&#10;</text>
                <text>Cantidad   &#9;   Producto   &#9;   Precio&#10;</text>
                `+products+`
                <text>---------------------------------------&#10;</text>
                <text>Imp%1 &#9; Imp%2 &#9; Imp%4 &#9; Imp%13 &#10;</text>
                <text>`+this.imp1+`&#9; `+this.imp2+`&#9; `+this.imp4+`&#9; `+this.imp13+`&#10;</text>
                <text>   SubTotal &#9; Vuelto &#9; Total   &#10;</text>
                <text>`+this.subtotal+`&#9; `+this.vuelto+`&#9; `+this.total+`&#10;</text>
                <text>---------------------------------------&#10;</text>
                <text>Detalle&#10;</text>
                <text>`+this.ClaveComprobante+`&#10;</text>
                <text>Autorizado mediante Resolucion DGT-R-033-2019 DEL 6-20-2019&#10;</text>
                <feed line="3"/>
                <cut type="feed"/>
                </epos-print>`;
                
                //Create a SOAP envelop
                var soap = '<s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">' +
                '<s:Body>' + request + '</s:Body></s:Envelope>';
                //Create an XMLHttpRequest object
                var xhr = new XMLHttpRequest();
                //Set the end point address
                var url = 'http://'+this.ip1+'/cgi-bin/epos/service.cgi?devid='+this.devid1+'&timeout=10000';
                //Open an XMLHttpRequest object
                xhr.open('POST', url, true);
                //<Header settings>
                xhr.setRequestHeader('Content-Type', 'text/xml; charset=utf-8');
                xhr.setRequestHeader('If-Modified-Since', 'Thu, 01 Jan 1970 00:00:00 GMT');
                // Send print document
                xhr.send(soap);
            
        },
		ImprimirUSB(){
			if(this.modoPDV == 6){
				setTimeout(function(){  this.imprimirTicket(); }, 3000);
			} else
			{
				setTimeout(function(){  window.print(); }, 3000); 
			}
		},
        listarLocalidad(){
            let me =this;
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
                axios.get('api/Localidades/ListarFiltrada/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
                    //console.log(response);
                    me.localidadencabezado=response.data;
					me.modoPDV = me.localidadencabezado[0].modoPDV;
                    me.Logo2 = me.localidadencabezado[0].imagen;
					me.pieDocumento = me.localidadencabezado[0].pieDocumento;
					if(me.modoPDV == 6){
						setTimeout(function(){  me.imprimirTicket(); }, 3000);
					} else
					{
						setTimeout(function(){  window.print(); }, 3000); 
					}
                }).catch(function(error){
                    console.log(error);
                });
        },
        obtenerCliente(){
			var self = this;
			var clientesArray = [];
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			axios.get('api/Empleados/ObtenerCliente', { params: {'clienteFactura': this.nombreReceptor}},configuracion).then(function(response){
				clientesArray=response.data;
				for(var i = 0; i < clientesArray.length; ++i)
				{
					self.telefono = clientesArray[i].telefono;
					self.nombreFantasia = clientesArray[i].nombreFantasia;
					break;
				} 
			})
        },
    },
  	}
</script>

<style lang="scss">>
    #espacioBoton {
        top: 10px;
    }
    /*#clienteBoton {
        bottom: 10px;
    }*/
    /* #clienteActualizar {
        bottom: 5px;
    }*/
    .content {
    max-width: 400px;
    margin: auto;
    }
    #factura {
        padding: 20px;
    }
    #ticket {
    width: 80px;
    max-width: 155px;
    }

    
    @import url('https://fonts.googleapis.com/css2?family=ABeeZee&family=Montserrat:wght@200;400&family=Open+Sans:wght@600&family=PT+Sans&display=swap');

    #logo {
        float: left;
        margin-left: 2%;
        margin-right: 2%;
    }
    #imagen {
        width: 100px;
    }

    #fact {
        font-size: 15px;
        font-weight: bold;
        text-align: center;
    }   

    #datos {
        float: left;
        margin-top: 0%;
        margin-left: 2%;
        margin-right: 2%;
    }

    #encabezado {
        text-align: center;
        margin-left: 10px;
        margin-right: 10px;
        font-size: 15px;
    }

    section {
        clear: left;
    }

    #cliente {
        text-align: left;
    }

    #facliente {
        width: 40%;
        border-collapse: collapse;
        border-spacing: 0;
        margin-bottom: 15px;
    }

    #fa {
        color: #FFFFFF;
        font-size: 14px;
    }
    @media print {
    .oculto-impresion,
    .oculto-impresion * {
        display: none !important;
    }
    }   
    #facarticulo {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        padding: 20px;
        margin-bottom: 15px;
    }

    #facarticulo thead {
        padding: 20px;
        background: #2183E3;
        text-align: center;
        border-bottom: 1px solid #FFFFFF;
    }

    #gracias {
        text-align: center;
    }
    tr.v-data-table__selected {
      background: #7d92f5 !important;
    }

    button#facturaBoton {
      margin: 18px;
    }

    button#hoyBoton {
      margin: 18px;
    }

    .tarjeta{
        margin-bottom: 15px!important;
        margin-top: 20px!important;
    }

    .efectivo{
        margin-bottom: 15px!important;
        margin-top: 20px!important;
    }

    .btnLimpiarGrid {
        margin-bottom: 20px;
    }

    button#productoBoton {
        top: 6px;
        margin-left: 35px;
    }

    td,
    th,
    tr,

    .v-dialog:not(.v-dialog--fullscreen) {
        max-height: 100%!important;
    }
    .centrado {
    text-align: center;
    align-content: center;
    }

    

    img {
        max-width: inherit;
        width: inherit;
    }
    .centrado {
    text-align: center;
    align-content: center;
    }

    .ticket {
        width: 220px;
        max-width: 220px;
    }

    img {
        max-width: inherit;
        width: inherit;
    }

</style>