<template>
  <v-content>
  <v-img height="200"
    dark
    :src="require('@/assets/home-hero.jpg')"
    class="white--text"
    gradient="to right, rgba(5, 11, 31, .8), rgba(5, 11, 31, .8)"
  >
  <v-btn color="white"  text rounded to="/"> <v-icon  >mdi-alarm-light</v-icon> Inicio</v-btn>
  <v-btn color="white"  text rounded  to="/login"> <v-icon >mdi-account-circle</v-icon> Iniciar Sesión</v-btn>
  
    <v-row
      align="center"
      justify="center" >
      <v-col class="text-center" cols="12">
        <v-flex xs12>
      
        <h1 class="Titulo text-uppercase">Videos Tutoriales </h1>
      <h4 class="subheading"> "Conviertete en un experto en Noah"</h4> 
      </v-flex>
        
      </v-col>
    </v-row>
  </v-img>
      <v-container grid-list-md>
                <v-layout wrap>
                <v-flex xs12 sm6 md4>
                    <iframe width="390" height="180" src="https://www.youtube.com/embed/NcYi-NMJmvI" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </v-flex>
                <v-flex xs12 sm6 md4 >
                    <iframe width="390" height="180" src="https://www.youtube.com/embed/cSkcshysR90" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </v-flex>
                <v-flex xs12 sm6 md4>
                   <iframe width="390" height="180" src="https://www.youtube.com/embed/_W7AIL_i-S0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </v-flex>
                <v-flex xs12 sm6 md4>
                   <iframe width="390" height="180" src="https://www.youtube.com/embed/4zpaZKf3lDw" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </v-flex>
                <v-flex xs12 sm6 md4>
                   <iframe width="390" height="180" src="https://www.youtube.com/embed/4tRnccyYvQk" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> </v-flex>
                <v-flex xs12 sm6 md4>
                   <iframe width="390" height="180" src="https://www.youtube.com/embed/F-b_odm5fnM" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> </v-flex>
                <v-flex xs12 sm6 md4>
                   <iframe width="390" height="180" src="https://www.youtube.com/embed/H0IsRzArgQw" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> </v-flex>
                <v-flex xs12 sm6 md4>
                    <iframe width="390" height="180" src="https://www.youtube.com/embed/8j8n6dVDfkg" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </v-flex>
                <v-flex xs12 sm6 md4>
                   <iframe width="390" height="180" src="https://www.youtube.com/embed/eQZsW3_yAsk" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> </v-flex>
                <v-flex xs12 sm6 md4>
                  <iframe width="390" height="180" src="https://www.youtube.com/embed/531raV34f0w" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>            </v-flex>
                </v-layout>
             </v-container>
  </v-content>
</template>


<script>
  
  export default {
    name: 'Upload',
    data () {
      return {
        TipoCedula : '',
        detalles:[],
        search: '',

      }
    },
    watch: {
      loader () {
        const l = this.loader
        this[l] = !this[l]

        setTimeout(() => (this[l] = false), 3000)

        this.loader = null
      },
    },
    methods: {
      onChangeFileUpload(event){
        this.selectedFile = event.target.files[0];
      },
    },
  }
</script>

<style scoped>
	img.preview {
		width: 200px;
	}

	.form{
		display: block;
		height: 400px;
		width: 400px;
		background: #ccc;
		margin: auto;
		margin-top: 40px;
		text-align: center;
		line-height: 400px;
		border-radius: 4px;
	}
	#app {
	font-family: 'Avenir', Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	margin-top: 60px;
	}
</style>

