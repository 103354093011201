<template>
    <div>
        <v-dialog v-model="comprobanteModal" max-width="1000px">
            <v-card class="pa-3">
                
                <v-card-text>
                    <v-btn class="mx-2" color="indigo" dark="" @click="crearPDFFactura()"><v-icon>mdi-file-pdf</v-icon> PDF</v-btn>
                    <v-btn class="mx-2" color="grey" dark="" onclick="window.print()"> <v-icon>mdi-printer</v-icon> A1</v-btn>
                    <v-btn class="mx-2" color="grey" dark="" :to="nofactura2"> <v-icon>mdi-printer</v-icon> POS </v-btn>
                    <v-btn class="oculto-impresion mx-2" color="grey" dark="" :to="'/facturar/0/0?duplicar=' + noFactura"> Duplicar</v-btn>
                    <v-btn class="mx-2" color="grey" dark="" @click="ReenvioFactura()" > <v-icon>mdi-cloud-download-outline</v-icon> XML FC </v-btn>
                    <v-btn class="mx-2" color="success" dark=" " @click="ReenvioFactura()" > <v-icon>mdi-email</v-icon><v-icon x-small="">mdi-send</v-icon> </v-btn>
                    <v-text-field label="Correo de reenvio" v-model="correo2"> </v-text-field> 
                    <div id="factura">
                        <header>
                            <div id="logo">
                                <img width="120" height="120" :src="Logo2">
                            </div>
                            <div v-for="det in localidadencabezado" :key="det.nombre" id="datos">
                                <p id="encabezado">
                                    <b class="info--text text-h4 font-weight-bold" > {{det.nombre}} </b> <b class="text-h6 primary--text"> <br>{{det.razonSocial}}, </b><br> {{det.dirreccion}}, Costa Rica<br> 
                                    <v-icon> mdi-card-bulleted </v-icon>: {{det.cedulaJuridica}} <br><v-icon>mdi-phone </v-icon> {{det.telefono}}<br>
                                    <v-icon>mdi-email </v-icon>{{det.correo}}
                                    
                                </p>
                            </div>
                            <div style="text-align:right;" class="font-weight-regular  body-2" id="fact">
                                <p>{{tipoDocumento}}<br>
                               NoFactura: {{noFactura}}<br>
                               
                               {{fecha}}--{{hora}} <br>
                               Vendedor: {{vendedor}}</p>
                                
                            </div>
                        </header>
                        <br>
                        <section>
                            <div>
                                <tbody>
                                    <tr>
                                        <td id="cliente">
                                            <strong class="primary--text text-h6"> Datos del cliente: </strong> <br>
                                            <strong><v-icon>mdi-account </v-icon></strong> {{nombreReceptor}}<br>
                                            <strong><v-icon>mdi-card-bulleted </v-icon></strong> {{cedulaReceptor}} <br>
                                            <strong><v-icon>mdi-email </v-icon></strong> {{correoReceptor}} <br>
                                        </td>
                                    </tr>
                                </tbody>
                            </div>
                        </section>
                        <br>
                        <section>
                            <div>
                                <v-simple-table id="facarticulo" >
                                    <thead>
                                        <tr id="fa" class="primary white--text">
                                            <th class="white--text text-center">Código</th>
                                            <th class="white--text text-center">Cant</th>
                                            <th class="white--text text-center">Descripción</th>
                                            <th class="white--text text-center">Precio IVI</th>
                                            <th class="white--text text-center">Grabado</th>
                                        </tr>
                                    </thead>
                                    <tbody> 
                                        <tr v-for="det in detalles" :key="det.noFacturaReal" >
                                            
                                            <td class="pa-3" style="text-align:center;"> {{det.codigoProducto}} </td>
                                            <td style="text-align:center;"> {{det.cantidad}} </td>
                                            <td style="text-align:center;">{{det.descripcion}}</td>
                                            <td style="text-align:center;">{{det.precioUnitario}}</td>
                                            <td style="text-align:center;">{{det.gravado}}</td> 
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr >
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th class="secondary rounded-l-xl" style="text-align:right;">SUBTOTAL</th>
                                            <th class="secondary" style="text-align:right;"> {{monedaPago}} {{subTotal}}</th>
                                        </tr>
                                        <tr>
                                            <th></th>
                                            <th class="text--grey"> Firma:</th>
                                            <th class="text--grey">________________________</th>
                                            <th class="secondary rounded-l-xl" style="text-align:right;">IVA</th>
                                            <th class="secondary" style="text-align:right;"> {{monedaPago}} {{iv}}</th>
                                        </tr>
                                        <tr>
                                            <th></th>
                                            <th class="text--grey">Cédula:</th>
                                            <th class="text--grey">________________________ </th>
                                            <th class="primary white--text rounded-l-xl" style="text-align:right;">TOTAL</th>
                                            <th class="primary white--text " style="text-align:right;"> {{monedaPago}} {{total}}</th>
                                        </tr>
                                    </tfoot>
                                    
                                </v-simple-table>
                            </div>
                        </section>
                        <br>
                        <br>
                        <footer>
                            <div>
                                <qrcode-vue :value="value" :size="size" level="H"></qrcode-vue>
                                Escanéame
                            </div>
                            <div id="gracias">
                                <p><b>Gracias por su compra!</b><br> Detalle:  {{comentario}}  <br> Facturas Disponibles en {{value}}
                                <br> Clave Comprobante: {{ClaveComprobante}} <br>
                                Autorizado mediante Resolucion DGT-R-033-2019 DEL 6-20-2019</p>
                            </div>
                        </footer>
                    </div>
                    <v-btn @click="OcultarComprobante()" color="error" flat><v-icon> mdi-exit-to-app </v-icon></v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-toolbar  dark color="boton" class="font-weight-light body-1 white--text">
            <h4 >Ficha cliente</h4>
			<v-list-item style="border-left: 1px solid rgb(224, 224, 224);">
					<v-list-item-avatar color="grey darken-3">
					<v-img
						class="elevation-6"
						src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
					></v-img>
					</v-list-item-avatar>
					<v-list-item-content>
						<v-list-item-title class="">{{nombreReceptor}}</v-list-item-title>
						<v-list-item-subtitle> Ced: {{cedulaReceptor}} <br> </v-list-item-subtitle>
					</v-list-item-content>
			</v-list-item>
			<v-spacer/>
			<div class="mx-2" style="border-left: 1px solid rgb(224, 224, 224);">
				Puntos: {{puntos}} 
			</div>
			<div class="mx-2" style="border-left: 1px solid rgb(224, 224, 224);">
				Compras: {{usuarios.length}} 
			</div>
			<div class="mx-2" style="border-left: 1px solid rgb(224, 224, 224);">
			Total ₡:{{total}} 
			</div>
        </v-toolbar>
        <v-row>
            
            <v-col cols="12" sm="12" md="6">
                <v-card>
					<p style="font-size:25px;"> Ultimos productos vendidos </p>
					<v-data-table
					:headers="headers2"
					:items="ProductosVendidos"
					:items-per-page="10"
					class="elevation-1"
				></v-data-table>
				</v-card>
            </v-col>
            <v-col cols="12" sm="12" md="6">    
                <v-data-table
                    :headers="headers"
                    :items="usuarios"
                    :search="search"
                    sort-by="noFactura"
                    sort-desc="noFactura"
                    class="elevation-1"

                    >
                    <template v-slot:item.mensajeRespuesta="{ item }">
                    <v-chip :color="getColor(item.mensajeRespuesta)" dark>{{ item.mensajeRespuesta }}</v-chip>
                    </template>
                    <template v-slot:top>
                    <v-toolbar flat color="white">
                        <v-btn @click="crearPDF()"> <v-icon>mdi-file </v-icon> </v-btn>
                        <v-toolbar-title>Compras Realizadas</v-toolbar-title>
                        <v-divider
                        class="mx-4"
                        inset
                        vertical
                        ></v-divider>
                        <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Buscar"
                        single-line
                        hide-details
                        ></v-text-field>
                         </v-toolbar>
                    </template>
                    <template v-slot:item.action="{ item }">
                    <v-icon
                        
                        class="mr-2"
                        color="primary" 
                        @click="MostrarComprobante(item)"
                    >
                        mdi-file-document
                    </v-icon>
                    
                    </template>

                    <template v-slot:no-data>
                        <v-data-table item-key="name" class="elevation-1" loading loading-text="Cargando datos... Por favor espere"></v-data-table>
                        <v-btn color="primary" @click="initialize">Reiniciar</v-btn>
                    </template>
                    
                </v-data-table>
                <v-snackbar
                        v-model="snackbar" top vertical="" :timeout="4000"
                        >
                        {{ Mensaje }}
                        <v-btn
                        color="pink"
                        text
                        @click="snackbar = false"
                        >
                        Cerrar
                        </v-btn>
                </v-snackbar>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import axios from 'axios'
import jsPDF from 'jspdf'
import autotable from 'jspdf-autotable'
import QrcodeVue from 'qrcode.vue'
import html2canvas from 'html2canvas';
  export default {
    data: () => ({
      
    dialog: false,
    search: '',
    value: 'https://www.noah.cr',
    size: 100,
    today: new Date().toISOString().substr(0, 10),
    focus: new Date().toISOString().substr(0, 10),
    headers: [
    { text: 'Opciones', value: 'action', sortable: false },
    { text: 'Factura', value: 'noFactura' },
    { text: 'Estado', value: 'mensajeRespuesta' },
    { text: 'Fecha', value: 'fecha' },
    { text: 'IVA 13%', value: 'impuesto13', sortable: false  },
    { text: 'Total', value: 'total', sortable: false  },  
    { text: 'TipoPago', value: 'tipoPago',  },
	{ text: 'Clave Comprobante', value: 'claveComprobante',  },            
    ],
	headers2: [
    { text: 'Item', value: 'descripcion', sortable: false },
    { text: 'Cant', value: 'cantidad' },
    { text: 'Precio', value: 'precioUnitario' },
    { text: 'Vendedor', value: 'vendedor' },
    { text: 'Fecha', value: 'fecha', sortable: false  },
    { text: 'Factura', value: 'noFacturaReal', sortable: false  },  
    ],
    cabeceraDetalles: [
    { text: 'Código', value: 'codigoProducto', sortable: false  },
    { text: 'Descripción', value: 'descripcion', sortable: false  },
    { text: 'Precio', value: 'actionprecio', sortable: false  },
    { text: 'Cantidad a debitar', value: 'actioncantidad', sortable: false  },
    ],
    tipoDocumentos: [
        'FISICA',
        'JURIDICA',
        'EXTRANJERO',
    ],
    tipoCedulaReceptor: '',
	ProductosVendidos:[],
    search: '',
    snackbar: false,
    Mensaje: '',
    emailRules: [
    v => !!v || 'El correo electrónico es requerido',
    v => /.+@.+\..+/.test(v) || 'El correo electrónico es requerido debe ser válido',],
    cedulaRules: [value => !!value || 'La cédula es requerida'
    , value => (value && value.length >= 9) || 'Debe ingresar 9 carácteres como mínimo',],
    nameRules: [
    value => !!value || 'El nombre es requerido',
    value => (value && value.length >= 10) || 'Debe ingresar 10 carácteres como mínimo',
    ],
    celRules: [
        value => !!value || 'El número es requerido',
        value => (value && value.length >= 8) || 'Debe ingresar 8 carácteres como mínimo',
        ],
    justificacionRules: [
        value => !!value || 'La justificación es requerida',
        value => (value && value.length >= 7) || 'Debe ingresar 7 carácteres como mínimo',
        ],
    editedIndex: -1,
    id: '',
    idrol:'',
    noFactura: 0,
    nofactura2: '/ticket/2',
    comprobanteModal: 0,
    justificacionNota: ["Ajuste de precio precio" ,"Saldo incobrable"," Pronto de pago","Error en cobro"],
    productosDatos:[],
    MensajeRespuesta: '',
    ClaveComprobante: '',
    EstadoNC: 0,
    ModalNotaCredito: false,
    total: 0,
    justificacion: '',
    medioDePago : '',
    detalles: [],
    localidadencabezado:[],
    NombreLocalidad:'',
    TotalIva: 0.0,
    CedulaLocalidad:'',
    ClaveNumerica: '',
    DireccionLocalidad:'',
    TelefonoLocalidad:'',
    fecha: '',
    hora: '',
    nombreReceptor: '',
    cedulaReceptor:'',
    direccion:'',
    monedaPago: "CRC",
    telefonoReceptor:'',
    correoReceptor:'',
    vendedor: '',
    subtotal: 0,
	puntos: 0,
    comentario: '',
    grabado:0 ,
    correo2: '',
    excento: 0,
    iv: 0.0,
    tipo_documento: '',
    usuarios:[],    
    desserts: [],
    editedIndex: -1,
    descuento: 0,
    }),
    components: {
      QrcodeVue,
    },

    computed: {
      formTitle () {
                return this.editedIndex === -1 ? 'Nuevo usuario' : 'Actualizar usuario'
        }


    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      
    },

    created () {
      this.listar();
	  this.listarProductos();
    },
    
    computed:{
    },

    methods: {
        calcularTotal(){
          for(var i=0;i<this.usuarios.length;i++){
            this.total=this.total+(this.usuarios[i].total * 1);
          }
        
      },
      calculariva(){
          for(var i=0;i<this.usuarios.length;i++){
            this.TotalIva=this.TotalIva+(this.usuarios[i].impuesto13 * 1);
          }
        
      },
        listarDetalles(id){
                let me=this;
                let header={"Authorization" : "Bearer " + this.$store.state.token};
                let configuracion= {headers : header};
                axios.get('api/RepVentasVistas/ListarDatosFactura/'+id+'/'+this.$route.params.localidad+'',configuracion).then(function(response){
                    //console.log(response);
                    me.detalles=response.data;
                    me.EstadoNC = me.detalles[0].estadoNC;
                    me.hora = me.detalles[0].hora;
                    me.vendedor = me.detalles[0].vendedor;
                    me.comentario = me.detalles[0].detalle;
                }).catch(function(error){
                    console.log(error);
                });
            },
        ReenvioFactura(){
            
            if(this.correo2 == ''){
                this.snackbar = true;
                this.Mensaje = 'Digite el correo al que desea reenviar la factura'
                return;
            }
            let header={"Authorization" : "Bearer " + this.$store.state.token};
                let configuracion= {headers : header};
                    let me=this;
                    axios.put('api/CorreosFacturas/Reenviar',{
                        'NoFactura':me.noFactura,
                        'Estado': 0,
                        'Correo2': me.correo2,
                        'Localidad':me.$store.state.usuario.localidad,
                    },configuracion).then(function(response){
                        me.snackbar = true;
                        me.Mensaje = 'Correo reenviado con éxito a '+me.correo2;                     
                    }).catch(function(error){
                        console.log(error);
                        me.snackbar = true;
                        me.Mensaje = 'Reenvio de factura a '+me.correo2+'. Disponible proximamente';   
                    });
        },
        listarLocalidad(localidad){
            let me =this;
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
                axios.get('api/Localidades/ListarFiltrada/'+this.$route.params.localidad,configuracion).then(function(response){
                    //console.log(response);
                    me.localidadencabezado=response.data;
                }).catch(function(error){
                    console.log(error);
                });

        },
		verLocalidad(item){
            this.NombreLocalidad= item.nombre;
            this.CedulaLocalidad= item.cedulaJuridica;
            this.DireccionLocalidad= item.dirreccion;
		},
        MostrarComprobante(item){
            this.nombreReceptor= item.nombreReceptor;
            this.cedulaReceptor = item.cedulaReceptor;
            this.telefonoReceptor = item.telefonoReceptor;
            this.vendedor = item.vendedor;
            this.comentario = item.detalle;
            this.correoReceptor = item.correoReceptor;
            this.noFactura = item.noFactura;
            this.tipoCedulaReceptor = item.tipoCedulaReceptor;
            this.fecha = item.fecha;
            this.subTotal = item.gravado;
            this.total = item.total;
            this.monedaPago = item.moneda;
            this.ClaveNumerica = item.claveComprobante;
            this.nofactura2 = "/ticket/"+this.noFactura;
            this.value = 'https://www.noah.cr/'+'perfilcliente/'+this.$store.state.usuario.localidad+'/'+this.cedulaReceptor;
            this.grabado = item.gravado;
            this.exento = item.exento;
            this.iv = item.impuesto;
            this.listarDetalles(item.noFactura);
            this.listarLocalidad(item.localidad);
            this.comprobanteModal = true;
        },
        OcultarComprobante(){
            this.comprobanteModal = 0;
        },
        obtenerCliente(){
			var self = this;
			var clientesArray=[];
			var clientePrevio="";
			if (this.idcliente.text === undefined)
			{
				clientePrevio = this.idcliente;
			}
			else
			{
				clientePrevio = this.idcliente.text;
			}
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			axios.get('api/Empleados/ObtenerCliente', { params: {'clienteFactura': clientePrevio}},configuracion).then(function(response){
				clientesArray=response.data;
				for(var i = 0; i < clientesArray.length; ++i)
				{
					self.clienteNombre = clientesArray[i].nombre;
					self.clienteCorreo = clientesArray[i].correo;
					self.clienteCedula = clientesArray[i].cedula;
					self.clienteTipoCedula = clientesArray[i].tipoCedula;
					self.clienteTelefono = clientesArray[i].telefono;
					break;
				} 
			})
        },
        crearPDF(){
           var columns = [
            {title: "Factura", dataKey: "noFactura"},
            {title: "Estado", dataKey: "mensajeRespuesta"}, 
            {title: "Fecha", dataKey: "fecha"}, 
            {title: "Excento", dataKey: "excento"},
            {title: "IVA 13%", dataKey: "impuesto13"},
            {title: "Total", dataKey: "total"},
            {title: "Cliente", dataKey: "cliente"},
            ];
            var rows = [];

            this.usuarios.map(function(x){
                rows.push({noFactura:x.noFactura,excento:x.exento,estado:x.mensajeRespuesta,fecha:x.fecha,iva:x.iv,total:x.total,cliente:x.nombreReceptor});
            });

            // Only pt supported (not mm or in)
            var doc = new jsPDF('p', 'pt');
            doc.autoTable(columns, rows, {
                margin: {top: 60},
                addPageContent: function(data) {
                    doc.text("Listado de Ventas", 40, 30);
                }
            });
            doc.save('ReporteVentas.pdf');
        },
        crearPDFFactura(){
             var quotes = document.getElementById('factura');
                html2canvas(quotes).then(function (canvas) {
                    var imgData = canvas.toDataURL('image/png');
                    var imgWidth = 210;
                    var pageHeight = 285;
                    var imgHeight = canvas.height * imgWidth / canvas.width;
                    var heightLeft = imgHeight;
                    var doc = new jsPDF();
                    var position = 0;

                    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);                    
                    doc.save('ComprobanteVenta.pdf');
                });
        },

        getColor (mensajeRespuesta) {
			if (mensajeRespuesta == 'aceptado') return '#8BC34A'
			if(mensajeRespuesta == 'Sin procesar') return 'orange'
			if(mensajeRespuesta == 'procesando') return '#42A5F5'
			if(mensajeRespuesta == 'rechazado') return '#FF1744'
			else  return '#42A5F5'
		},
       	async listar(){
			let me=this;
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			await axios.get('api/RepVentasVistas/ListarHaciendaCliente/'+this.$route.params.id+'/'+this.$route.params.localidad+'',configuracion).then(function(response){
				//console.log(response);
				me.usuarios=response.data;
				me.nombreReceptor = me.usuarios[0].nombreReceptor;
				me.cedulaReceptor = me.usuarios[0].cedulaReceptor;
				me.correoReceptor = me.usuarios[0].correoReceptor;
				me.telefonoReceptor = me.usuarios[0].telefonoReceptor;
				me.ConsultarPuntos();
				me.calculariva();
			}).catch(function(error){
				console.log(error);
			});
		},
		async ConsultarPuntos(){
			let self = this;
			let header = {"Authorization" : "Bearer "}; // Asegúrate de que el token de autorización esté incluido aquí
			let configuracion = {headers: header};

			// Añade la localidad como parte de la URL
			let url = `api/RepVentasVistas/CalcularPuntos/${this.$route.params.id}/${this.$route.params.localidad}`;

			await axios.get(url, configuracion).then(function(response){
				self.puntos = response.data;
			}).catch(function(error){
				console.error("Error en la consulta de puntos:", error);
				// Manejo de errores (opcional)
			});
		},

		listarProductos(){
			let me=this;
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			axios.get('api/RepVentasVistas/ListarPerfil/'+this.$route.params.localidad+'/'+this.$route.params.id+'',configuracion).then(function(response){
				//console.log(response);
				me.ProductosVendidos=response.data;
			}).catch(function(error){
				console.log(error);
			});
		},

      close () {
        this.dialog = false;
        this.limpiar();
          },
    

      activarDesactivarMostrar(accion,item){
                this.adModal=1;
                this.adNombre=item.nombre;
                this.adId=item.idusuario;                
                if (accion==1){
                    this.adAccion=1;
                }
                else if (accion==2){
                    this.adAccion=2;
                }
                else{
                    this.adModal=0;
                }
            },
            activarDesactivarCerrar(){
                this.adModal=0;
            },
      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.desserts[this.editedIndex], this.editedItem)
        } else {
          this.desserts.push(this.editedItem)
        }
        this.close()
      },
    },
  }
</script>
My CRUD