<template> 
	<html>
			<head>
				<link rel="stylesheet" href="style.css"> 
			</head>
			<body class="content">
				<div class="ticket">
					<div v-for="det in localidadencabezado" :key="det.nombre" class="centrado" id="centrado">
					
							<b class="primary--text" >{{det.nombre}} </b> <br>{{det.razonSocial}},<br> {{det.dirreccion}}, Costa Rica
							<v-icon>mdi-id-card </v-icon> {{det.cedulaJuridica}} <v-icon>mdi-phone </v-icon> {{det.telefono}} <v-icon>mdi-email </v-icon>
							{{det.correo}} <br>
							---------------------- <br>
					</div>
					
					<v-card-text v-for="det in cierre" :key="det.nombre" class="caption">
							Fecha: {{det.fecha}}  <br>
							Hora: {{det.hora}}  <br>
							Cajero: {{det.cajero}} <br>
							Reimpresión de Cierre : {{$route.params.id }} <br>
							
						
						------------------------------------------ <br>
						<div class="primary--text "> <b>Resumen del Conteo: ₡{{(calcularConteo)}} </b></div>
						<b> Total Conteo: $</b> {{(monedaDolar)}} <br>
						<b> Total Tarjetas: ₡</b> {{TotalTarjetas=(calcularTarjetas)}} <br>
						<b> Compras Caja Chica: ₡</b> {{det.pagosProveedores}} 
						<b> Resguardos: ₡</b> 0 <br>
						------------------------------------------ <br>
					
						<div class="primary--text "> <b>Resumen del Cierre </b></div>
						<b> Facturado Efectivo: ₡</b> {{(det.efectivo)}} <br>
						<b> Facturado Tarjeta: ₡</b> {{(det.tarjetas)}} <br>
						<b> Transferencias(Sinpe): ₡</b> {{(det.deposito)}} <br>
						<b> Diferencia Efectivo:₡</b> {{det.diferenciaE}} <br>
						<b> Diferencia Tarjeta:₡</b> {{det.diferenciaT}} <br>
						<b> Total Ventas: ₡</b> {{parseFloat(det.efectivo) +parseFloat(det.tarjetas) + parseFloat(det.deposito)}} <br>
						<b> Toal Ventas: $</b> {{(det.dolares)}} <br>
						<b> Diferencia: $</b> {{(det.diferenciaD)}} <br>
						------------------------------------------ <br>
						<b> Notas de credito: ₡</b> {{det.notasCredito}} <br>
						------------------------------------------ <br>
					</v-card-text> 
				</div>
			</body>
		<div>
			<v-btn class="primary mx-5 oculto-impresion" onclick="window.print()"> <v-icon>mdi-printer</v-icon>Imprimir Impresora USB</v-btn>
			<v-btn class="primary mx-5 oculto-impresion" @click="ImprimirCierre()"> <v-icon>mdi-printer</v-icon>Imprimir Impresora Red</v-btn>
			<v-btn dark class="red mx-5 oculto-impresion" @click="salir()"> <v-icon>mdi-close</v-icon>Cerrar </v-btn>
		</div>
	</html>
</template>

<script >
import axios from 'axios'
import QrcodeVue from 'qrcode.vue'
  export default {
    data: () => ({
    localidadencabezado: [],
    ConteoDatafonoBCR: 0,
	ConteoDatafonoBCR: 0,
	ConteoDatafonoLafise: 0,
	ConteoDatafonoNacional: 0,
	ConteoDatafonoPromerica: 0,
	ConteoDatafonoBAC: 0,
	VentasColonesEfectivo: 0,
	VentasColonesTarjeta: 0,
	VentaTransferenciaColones: 0,
	VentaTransferenciaDolares: 0,
	VentasDolaresTarjeta: 0,
	VentasDolaresEfectivo: 0,
	Logo2: '',
	cierre: []
    }),

    created () {
	  this.listarLocalidad();
      this.listarDetalles(this.$route.params.id);
	  this.validarImpresora();
      setTimeout(function(){  window.print(); }, 3000); 
	  
      
    },
    components: {
      QrcodeVue,
    },

    methods: {
		listarLocalidad(){
			var today = new Date();
            var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
            var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            this.fecha = date+' '+time;
            let me =this;
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
                axios.get('api/Localidades/ListarFiltrada/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
                    //console.log(response);
                    me.localidadencabezado=response.data;
                }).catch(function(error){
                    console.log(error);
                });

        },
		listarDetalles(id){
			var today = new Date();
            var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
            var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            this.fecha = date+' '+time;
            let me =this;
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
                axios.get('api/CierreCajas/ListarCierreId/'+id+'/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
                    //console.log(response);
                    me.cierre=response.data;
                }).catch(function(error){
                    console.log(error);
                });

        },
		validarImpresora(){
			if(localStorage.getItem("printer1") == null) {
				
				return;
			}

			this.ip1 = localStorage.getItem("printer1");
			this.devid1 = localStorage.getItem("devid1");
			this.ip2 = localStorage.getItem("printer2");
			this.devid2 = localStorage.getItem("devid2");
		},
        ImprimirRed(){
            var products = "";
            for(var i = 0; i < this.detalles.length; ++i)
                {
                    products += "<text>"+this.detalles[i].cantidad+"&#9; "+this.detalles[i].descripcion.substring(0, 22)+"&#9;"+this.detalles[i].precioUnitario * this.detalles[i].cantidad+"&#10;</text>"
                }


            var request = `<epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">
                <logo key1="48" key2="48"/>
                <text align="center"/>
                <text font="font_a"/>
                <text width="2" height="2"/>
                <text reverse="false" ul="false" em="false" color="color_1"/>
                <text>`+this.$store.state.usuario.localidad+`&#10;</text>
                <text width="1" height="1"/>
                <text>`+this.localidadencabezado[0].razonSocial+`&#10;</text>
                <text>`+this.localidadencabezado[0].dirreccion+`&#10;</text>
                <text>`+this.localidadencabezado[0].nombre+`&#10;</text>
                <text> `+this.localidadencabezado[0].cedulaJuridica+`&#10;</text>
                <text>`+this.localidadencabezado[0].telefono+`&#10;</text>
                <text>`+this.localidadencabezado[0].correo+`&#10;</text>
                <text reverse="false" ul="false" em="false" color="color_1"/>
                <text>---------------------------------------&#10;</text>
                <text>Vendedor: `+this.vendedor+`&#10;</text>
                <text>Fecha: `+this.fecha+`&#10;</text>
                <text>Factura: `+this.$route.params.id+`&#10;</text>
                <text>Cliente: `+this.nombreReceptor+`&#10;</text>
                <text>Cédula: `+this.cedulaReceptor+`&#10;</text>
                <text>Tipo de Documento: `+this.tipoDocumento+`&#10;</text>
                <text>`+this.tipoPago+`&#10;</text>
                <text>---------------------------------------&#10;</text>
                <text>Cantidad   &#9;   Producto   &#9;   Precio&#10;</text>
                `+products+`
                <text>---------------------------------------&#10;</text>
                <text>Imp%1 &#9; Imp%2 &#9; Imp%4 &#9; Imp%13 &#10;</text>
                <text>`+this.imp1+`&#9; `+this.imp2+`&#9; `+this.imp4+`&#9; `+this.imp13+`&#10;</text>
                <text>   SubTotal &#9; Vuelto &#9; Total   &#10;</text>
                <text>`+this.subtotal+`&#9; `+this.vuelto+`&#9; `+this.total+`&#10;</text>
                <text>---------------------------------------&#10;</text>
                <text>Detalle&#10;</text>
                <text>`+this.ClaveComprobante+`&#10;</text>
                <text>Autorizado mediante Resolucion DGT-R-033-2019 DEL 6-20-2019&#10;</text>
                <feed line="3"/>
                <cut type="feed"/>
                </epos-print>`;
                
                //Create a SOAP envelop
                var soap = '<s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">' +
                '<s:Body>' + request + '</s:Body></s:Envelope>';
                //Create an XMLHttpRequest object
                var xhr = new XMLHttpRequest();
                //Set the end point address
                var url = 'http://'+this.ip1+'/cgi-bin/epos/service.cgi?devid='+this.devid1+'&timeout=40000';
                //Open an XMLHttpRequest object
                xhr.open('POST', url, true);
                //<Header settings>
                xhr.setRequestHeader('Content-Type', 'text/xml; charset=utf-8');
                xhr.setRequestHeader('If-Modified-Since', 'Thu, 01 Jan 1970 00:00:00 GMT');
                // Send print document
                xhr.send(soap);
            
        },
        salir(){
		this.$store.dispatch("salir");
		},
        obtenerCliente(){
                var self = this;
                var clientesArray=[];
                var clientePrevio="";
                if (this.idcliente.text === undefined)
                {
                    clientePrevio = this.idcliente;
                }
                else
                {
                    clientePrevio = this.idcliente.text;
                }
                let header={"Authorization" : "Bearer "};
                let configuracion= {headers : header};
                axios.get('api/Empleados/ObtenerCliente', { params: {'clienteFactura': clientePrevio}},configuracion).then(function(response){
                    clientesArray=response.data;
                    for(var i = 0; i < clientesArray.length; ++i)
                    {
                      self.clienteNombre = clientesArray[i].nombre;
                      self.clienteCorreo = clientesArray[i].correo;
                      self.clienteCedula = clientesArray[i].cedula;
                      self.clienteTipoCedula = clientesArray[i].tipoCedula;
                      self.clienteTelefono = clientesArray[i].telefono;
                      break;
                    } 
                })
        },

    },
  }
</script>

<style lang="scss">>
    #espacioBoton {
        top: 10px;
    }
    .content {
    max-width: 400px;
    margin: auto;
    }
    #factura {
        padding: 20px;
    }
    #ticket {
    width: 60px;
    max-width: 155px;
    }


    #logo {
        float: left;
        margin-left: 2%;
        margin-right: 2%;
    }
    #imagen {
        width: 100px;
    }

    #fact {
        font-size: 15px;
        font-weight: bold;
        text-align: center;
    }   

    #datos {
        float: left;
        margin-top: 0%;
        margin-left: 2%;
        margin-right: 2%;
    }

    #encabezado {
        text-align: center;
        margin-left: 10px;
        margin-right: 10px;
        font-size: 15px;
    }

    section {
        clear: left;
    }

    #cliente {
        text-align: left;
    }

    #facliente {
        width: 40%;
        border-collapse: collapse;
        border-spacing: 0;
        margin-bottom: 15px;
    }

    #fa {
        color: #FFFFFF;
        font-size: 14px;
    }
    @media print {
    .oculto-impresion,
    .oculto-impresion * {
        display: none !important;
    }
    }   
    #facarticulo {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        padding: 20px;
        margin-bottom: 15px;
    }

    #facarticulo thead {
        padding: 20px;
        background: #2183E3;
        text-align: center;
        border-bottom: 1px solid #FFFFFF;
    }

    #gracias {
        text-align: center;
    }
    tr.v-data-table__selected {
      background: #7d92f5 !important;
    }

    button#facturaBoton {
      margin: 18px;
    }

    button#hoyBoton {
      margin: 18px;
    }

    .tarjeta{
        margin-bottom: 15px!important;
        margin-top: 20px!important;
    }

    .efectivo{
        margin-bottom: 15px!important;
        margin-top: 20px!important;
    }

    .btnLimpiarGrid {
        margin-bottom: 20px;
    }

    button#productoBoton {
        top: 6px;
        margin-left: 35px;
    }

    td,
    th,
    tr,

    .v-dialog:not(.v-dialog--fullscreen) {
        max-height: 100%!important;
    }
    .centrado {
    text-align: center;
    align-content: center;
    }

    

    img {
        max-width: inherit;
        width: inherit;
    }
    .centrado {
    text-align: center;
    align-content: center;
    }

    .ticket {
        width: 220px;
        max-width: 220px;
    }

    img {
        max-width: inherit;
        width: inherit;
    }

</style>