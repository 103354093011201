<template>
	<html>
		<div class="landscape"></div>
		<div class="filter"></div>
		<canvas id="canvas"></canvas>
	</html>
</template>

<script>
  export default {
    name: 'Stats',
     data: () => ({
      stats: [
          ['24k', 'Dispositivos'],
          ['100+', 'Clientes'],
          ['100%', 'Costarricense'],
          ['5m', 'Total Descargas'],
        ],
    }),
	
  }
  
</script>
