<template>
	
  <v-data-table
    :headers="headers"
    :items="articulos"
	dense
    sort-by="nombre"
	:search="search"
    class="elevation-1"

    >
	<template v-slot:item.categoria="props">
		<v-edit-dialog
		:return-value.sync="props.item.categoria"
		@save="Actualizar(props.item)"
		@cancel="cancel"
		@close="close"
		large
		>
		{{ props.item.categoria }} <br>
		<template v-slot:input>
			<v-select v-model="props.item.categoria" :items="Categorias" label="Categoría"/>
		</template>
		</v-edit-dialog>
	</template>
	<template v-slot:item.proveedor="props">
		<v-edit-dialog
		:return-value.sync="props.item.proveedor"
		@save="Actualizar(props.item)"
		@cancel="cancel"
		@close="close"
		large
		>
		{{ props.item.proveedor }} <br>
		<template v-slot:input>
			<v-select v-model="props.item.proveedor" :items="Proveedores" label="Proveedor"/>
			<v-btn color="primary" icon="" @click="GestionProveedores()"> <v-icon> mdi-account-multiple-plus </v-icon>  </v-btn>
		</template>
		</v-edit-dialog>
	</template>
	<template v-slot:item.innerPack="props">
		<v-edit-dialog
		:return-value.sync="props.item.innerPack"
		@save="Actualizar(props.item)"
		@cancel="cancel"
		@close="close"
		large
		>
		{{ props.item.innerPack }} <br>
		<template v-slot:input>
			<v-text-field
			onfocus="this.select();"
			v-model="props.item.innerPack"
			:label="'Ubicación del item: '"
			></v-text-field>
		</template>
		</v-edit-dialog>
	</template>
	<template v-slot:item.codigoRaiz="props">
		<v-edit-dialog
		:return-value.sync="props.item.codigoRaiz"
		@save="Actualizar(props.item)"
		@cancel="cancel"
		@close="close"
		large
		>
		{{ props.item.codigoRaiz }} <br>
		<template v-slot:input>
			<v-text-field
			onfocus="this.select();"
			v-model="props.item.codigoRaiz"
			:label="'Cabys: '"
			></v-text-field>
		</template>
		</v-edit-dialog>
	</template>
	<template v-slot:item.precioCompra="props">
		<v-edit-dialog
		:return-value.sync="props.item.precioCompra"
		@save="Actualizar(props.item)"
		@cancel="cancel"
		@close="close"
		large
		>
		{{ props.item.precioCompra }} <br>
		<template v-slot:input>
			<v-text-field
			onfocus="this.select();"
			v-model="props.item.precioCompra"
			:label="'Precio Compra: '"
			></v-text-field>
		</template>
		</v-edit-dialog>
	</template>
	<template v-slot:item.precioVenta="props">
		<v-edit-dialog
		:return-value.sync="props.item.precioVenta"
		@save="Actualizar(props.item)"
		@cancel="cancel"
		@close="close"
		large
		>
		{{ props.item.precioVenta }} <br>
		<template v-slot:input>
			<v-text-field
			onfocus="this.select();"
			v-model="props.item.precioVenta"
			:label="'Precio Venta: '"
			></v-text-field>
		</template>
		</v-edit-dialog>
	</template>
	<template v-slot:item.barcode="props">
		<v-edit-dialog
		:return-value.sync="props.item.barcode"
		@save="Actualizar(props.item)"
		@cancel="cancel"
		@close="close"
		large
		>
		{{ props.item.barcode }} <br>
		<template v-slot:input>
			<v-text-field
			onfocus="this.select();"
			v-model="props.item.barcode"
			:label="'Código Barras: '"
			></v-text-field>
		</template>
		</v-edit-dialog>
	</template>
	<template v-slot:item.descripcion="props">
		<v-edit-dialog
		:return-value.sync="props.item.descripcion"
		@save="Actualizar(props.item)"
		@cancel="cancel"
		@close="close"
		large
		>
		{{ props.item.descripcion }} <br>
		<p style="white-space: pre-line; font-size: 12px;">{{props.item.detalle}} </p>
		<template v-slot:input>
			<v-text-field
			onfocus="this.select();"
			v-model="props.item.descripcion"
			:label="'Código: '"
			></v-text-field>
		</template>
		</v-edit-dialog>
	</template>
	<template v-slot:item.tipoProducto="props">
		<v-edit-dialog
		:return-value.sync="props.item.tipoProducto"
		@save="Actualizar(props.item)"
		@cancel="cancel"
		@close="close"
		large
		>
		{{ props.item.tipoProducto }} <br>
		<p style="white-space: pre-line; font-size: 12px;">{{props.item.detalle}} </p>
		<template v-slot:input>
			<v-row>
				<v-select class="mx-3" dense v-model="props.item.tipoProducto"
				:items="familias" label="Familia"/>
				<v-icon @click="EliminarFamilia()" v-if="props.item.tipoProducto != ''" color="red">mdi-minus  </v-icon>
				<v-edit-dialog
					:return-value.sync="props.item.tipoProducto"
					@save="IngresarFamilia()"
					>
					<v-icon color="green">mdi-plus</v-icon> 
					<template v-slot:input>
						<v-text-field
						onfocus="this.select();"
						v-model="familia"
						label="Digite el nombre de la familia"
						counter
						></v-text-field>
					</template>
				</v-edit-dialog> 
			</v-row>
		</template>
		</v-edit-dialog>
	</template>
	<template v-slot:item.actionOnline="{ item }">
		<v-checkbox dense v-if="item.sincServer1 == 1" v-model="truevalue" color="green" > </v-checkbox>
		<v-checkbox dense v-if="item.sincServer1 == 0" v-model="truevalue" color="error" > </v-checkbox>
    </template>
    
    <template v-slot:top>
		<!-- FACTURA -->
      	<div id="bill" style="display: none;">
			<div style="margin: 0 auto; text-align: center;">
			<br>
			<label style="font-size: medium; font-weight: bold;">Movimiento Inventario</label>
			<br>
			<label style="font-size: medium;">{{localidad}}</label>
			<br>
			<label style="font-size: medium;">Tipo Movimiento {{DatoMovimiento}}</label>
			<br>
			<label style="font-size: medium;">Usuario: {{$store.state.usuario.nombre}}</label>
			<br><br>
			<v-divider color="black"></v-divider>
			<br>
			</div>
			<div style="margin: 0 auto;">
			<label style="font-size: medium;">Fecha: {{Fecha}}</label> <br>
			<label style="font-size: medium;">Nota: {{detalle}}</label>
			<br><br>
			<v-divider color="black"></v-divider>
			<br>
			<div style="display: grid; grid-template-columns: auto auto auto;">
				<table>
					<tr>
						<th>Cant</th>
						<th>Código</th>
						<th>Descripcion</th>
						<th>Precio</th>
					</tr>
					<tr v-for="item in detalles"
						:key="item">
						<td>{{item.cantidadIngreso}}</td>
						<td>{{item.idarticulo}}</td>
						<td>{{item.descripcion}}</td>
						<td>{{item.precio}}</td>
					</tr>
					</table>
			</div>
			<br><br>
			<v-divider color="black"></v-divider>
			<br>
			</div>
		</div>
		<!-- FACTURA -->
		<!-- Tarjeta Registro -->
		<v-overlay :value="modelCarga">
			<v-progress-circular
				indeterminate
				size="64"
			></v-progress-circular>
		</v-overlay>
		<div id="invoice2" style="display: none;" class="container-fluid invoice-container"> 
			<header>
				<table class="table table-bordered text-1 table-sm">
					<thead>
					<tr>
						<th>
							<div v-for="det in localidadencabezado" :key="det.nombre" id="datos">
								
									<h2> {{det.nombre}} </h2> <h3> {{det.razonSocial}} </h3>{{det.dirreccion}} Costa Rica<br> 
										{{det.cedulaJuridica}} <br>{{det.telefono}}<br>
									{{det.correo}}
								
							</div>
						</th>
						<th><div id="logo">
								<img :src="Logo3" width="120" height="120">
								<h4> Ficha técnica del articulo </h4>
							</div>
						</th>
					</tr>
					</thead>
				</table>
			</header>
				<!-- Passenger Details -->
				<h4 class="text-4 mt-2">Datos generales</h4>
				<div class="table-responsive">
				<table class="table table-bordered text-1 table-sm">
					<thead>
					<tr>
						<th>Código </th>
						<th>Nombre</th>
						<th>Categoria</th>
						<th>Descripción</th>
					</tr>
					</thead>
					<tbody>
					<tr>
						<td>{{codigoProducto}}</td>
						<td>{{descripcion}}</td>
						<td>{{cat1}}</td>
						<td>{{SubFamilia}}</td>
					</tr>
					</tbody>
				</table>
				</div>
				<br>
				<!-- Datos Generales -->
				<h4 class="text-4">Datos Generales</h4>
				<div class="table-responsive">
				<table class="table text-1 table-sm">
					<thead>
					<tr>
						<td colspan="4" class=""> Caracteristicas</td>
					</tr>
					</thead>
					<tbody>
					<tr v-for="item in Caracteristicas"
						 :key="item.name">
						<td class="fw-600 col-2">{{item.nombre}}</td>
						<td class="col-4">{{item.valor}}</td>
						<td class="fw-600 col-2">{{item.fecha}}s</td>
						<td class="col-4">{{item.usuario}}</td>
					</tr>
					</tbody>
				</table>
				</div>
				<!-- Kardex Details -->
				<h4 class="text-4">Kardex</h4>
				<div class="table-responsive">
				<table class="table text-1 table-sm">
					<thead>
					<tr>
						<td colspan="4" class=""> Kardex</td>
					</tr>
					</thead>
					<tbody>
					<tr >
						<td class="fw-600 col-2">Entrada</td>
						<td class="col-4">12-12-2022</td>
						<td class="fw-600 col-2">Fecha Registro</td>
						<td class="col-4"></td>
						<td class="fw-600 col-2">Costo</td>
						<td class="col-4">{{precioCompra}}</td>
						<td class="fw-600 col-2">Precio Venta</td>
						<td class="col-4">{{precioVenta}}</td>
						<td class="fw-600 col-2">Utilidad</td>
						<td class="col-4">{{precioVenta - precioCompra}}</td>
						<td class="fw-600 col-2">Existencia</td>
						<td class="col-4">0</td>
					</tr>
					</tbody>
				</table>
				</div>
		</div>
      	<v-dialog v-model="modelinventario" persistent max-width="1000" class="primary">
        	<v-toolbar color="primary" class="font-weight-light body-1">
              <h4>Movimientos de Inventario</h4>
        	</v-toolbar>
        <v-card>
          <v-container fluid>
            <v-col cols="12" sm="12">
            <v-form ref="form" v-model="validFactura"  @submit.prevent="facturar">
            <v-row>
			<v-col class="mt-2" cols="12" sm="6">
				<v-select v-model="DatoMovimiento" :rules="[v => !!v || 'El tipo de movimiento es requerido']" :items="TipoMovimiento" label="Tipo de Movimiento"></v-select>
			</v-col>
			<v-col class="mt-2" cols="12" sm="6">
				<v-text-field v-model="codigo" @keyup.enter="buscarCodigoBarras()"  label="Escanear Código"></v-text-field>
			</v-col>
			<v-col cols="12" sm="12">
				<v-combobox clearable @change="buscarProducto()"  :items="productos" v-model="codigo" label="Busqueda de producto por nombre"></v-combobox>
			</v-col>
                <v-col cols="12" sm="12">
                      <v-flex xs12 sm12 md12 lg12 xl12>
                        
							<v-data-table
								:headers="cabeceraDetalles"
								dense
								single-select
								:items="detalles"
								class="elevation-1"
								sort-by="id"
								sort-desc=""
								v-model="selected"
								@click:row="rowClick">
								<template slot="no-data">
									<h3>No hay artículos agregados al detalle.</h3>
								</template> 
								<template v-slot:item.actioncantidad="{ item }">
									<v-text-field
										onfocus="this.select();"
										ref="inputCantidadIngreso"
										v-model="item.cantidadIngreso"
										@keydown="preventNonNumericInput"
									></v-text-field>
								</template>



							</v-data-table>
                      </v-flex>
				<v-col class="mt-2" cols="12" sm="12">
                 <v-text-field v-model="detalle"  label="Detalle, comentario o justificación"></v-text-field>
              	</v-col>
                <v-btn :loading="loading" color="primary"  class="mr-4" style="top: 10px;" @click="generarInventario()">
                Generar Proceso
                </v-btn>
                <v-btn color="error" id="espacioBoton" @click="modelinventario = false">
                Cancelar
                </v-btn> 

                <!-- <v-switch v-model="switch1" disabled
                  color="success" flat :label="`Aprobado automático`">
				</v-switch> -->

                </v-col>
              </v-row>
              </v-form>
              </v-col>
          </v-container>
        </v-card>
      </v-dialog >
		<!-- Container -->
		<!-- Container -->
		<div id="invoice" style="display: none; container-fluid">
			<!-- Header -->
			<header>
			<div class="row align-items-center">
				<div class="col-sm-12 text-center text-sm-end">
				<h4 class="text-7 mb-0">Traslado de mercadería</h4>
				</div>
			</div>
			<hr>
			</header>
			
			<!-- Main Content -->
			<main>
			<div class="row">
				<div class="col-sm-6"><strong>Date:</strong> {{Fecha}}</div>
				<div class="col-sm-6 text-sm-end"> <strong>Reporte del trazlado:</strong> </div>
				
			</div>
			<hr>
			<div class="row">
				<div class="col-sm-12 text-sm-end order-sm-1"> <strong>Resumen del movimiento:</strong>
				<address>
				Origen: {{localidadorigen}}<br />
				Destino: {{localidaddestino}}<br />
				Usuario {{$store.state.usuario.nombre}}<br />
				</address>
				</div>
			</div>
				
			<div class="card">
				<div class="card-body p-0">
				<div class="table-responsive">
					<table class="table mb-0">
					<thead class="card-header">
					<tr>
						<td class="col-3"><strong>Código</strong></td>
						<td class="col-4"><strong>Descripción</strong></td>
						<td class="col-2 text-center"><strong>Precio</strong></td>
						<td class="col-1 text-center"><strong>Cantidad</strong></td>
					</tr>
					</thead>
					<tbody>
						<tr  v-for="item in detalles"
          				:key="item.idarticulo">
						<td class="col-3">{{item.idarticulo}}</td>
						<td class="col-4 text-1">{{item.descripcion}}</td>
						<td class="col-2 text-center">{{item.precio}}</td>
						<td class="col-1 text-center">{{item.cantidadIngreso}}</td>
						</tr>
					</tbody>
					</table>
				</div>
				</div>
			</div>
			</main>
			<!-- Footer -->
			<footer class="text-center mt-4">
			<br><br><br>
			<p> Firma Recibido: _______________________ </p>
			</footer>
		</div>	
		<v-dialog fullscreen v-model="modeltrazlado" persistent max-width="900" class="primary">
			<v-toolbar  dark color="primary" class="font-weight-light body-1">
				<h4>Traslado de mercadería</h4>
				
			</v-toolbar>
			<v-card>
			<v-container fluid>
				<v-col cols="12" sm="12">
				<v-form ref="form" v-model="validFactura"  @submit.prevent="facturar">
				<v-row>
				<v-col class="mt-2" cols="12" sm="4">
					<v-select filled v-model="localidadorigen" :rules="[v => !!v || 'El origen es requerido']" :items="localidades" label="Localidad Origen"></v-select>
				</v-col>
				<v-col class="mt-2" cols="12" sm="4">
					<v-select filled v-model="localidaddestino" :rules="[v => !!v || 'El destino es requerido']" :items="localidades" label="Localidad Destino"></v-select>
				</v-col>
				<v-col class="mt-2" cols="12" sm="4">
					<v-text-field filled v-model="codigo"  @keyup.enter="buscarCodigoBarras()"  label="Escanear Código"></v-text-field>
				</v-col>
				<v-col cols="12" sm="12">
					<v-combobox :disabled="!localidaddestino" filled clearable @change="buscarProducto()" :items="productos" v-model="codigo" label="Busqueda de producto por nombre"></v-combobox>
				</v-col>
					<v-col cols="12" sm="12">
						<v-flex xs12 sm12 md12 lg12 xl12>
							<v-data-table
									:headers="cabeceraDetalles"
									single-select
									:items="detalles"
									class="elevation-1"
									sort-by="id"
									sort-desc=""
									v-model="selected">
								<template slot="no-data">
									<h3>No hay artículos agregados al detalle.</h3>
								</template> 
								<template v-slot:item.actionmovimiento="{ item }">
									<v-icon
									large
									color="error"
									@click="eliminarProducto(item.id)"
									>
									mdi-delete
									</v-icon>
								</template>
								<template v-slot:item.actioncantidad="{ item }">
									<v-text-field
										ref="inputCantidadIngreso"
										@change="validarCantidad(item)"
										v-model="item.cantidadIngreso"
										onfocus="this.select();"
										@focus="handleFocus($event, item.id)"
										@keypress="preventNonNumericInput"
									></v-text-field>
								</template>
							</v-data-table>
						</v-flex>
					<v-col class="mt-2" cols="12" sm="12">
					<v-text-field v-model="detalle"  label="Detalle, comentario o justificación"></v-text-field>
					</v-col>
					<v-btn  color="primary" :loading="modelCarga" class="mr-4" style="top: 10px;" @click="generarTrazlado()">
					Generar Proceso
					</v-btn>
					<v-btn color="error" id="espacioBoton" @click="modeltrazlado = false">
					Cancelar
					</v-btn> 
					<v-spacer/>
					<v-btn  color="primary" :loading="modelCarga" class="mt-4" @click="PrintDetails()">
					<v-icon>mdi-printer </v-icon>
					</v-btn>
					</v-col>
				</v-row>
				</v-form>
				</v-col>
			</v-container>
			</v-card>
		</v-dialog>
      	
		<v-dialog v-model="modelSeriales" persistent max-width="800" class="primary">
        <v-toolbar  dark color="primary" class="font-weight-light body-1">
              <h4>Agregar códigos Seriales</h4>
              <v-card-subtitle>Producto: {{nombre}} </v-card-subtitle>
        </v-toolbar>
        <v-card>
          <v-container fluid>
            <v-col cols="12" sm="12">
            <v-form ref="form" v-model="validFactura"  @submit.prevent="facturar">
            <v-row>
			  <v-col class="mt-2" cols="12" sm="10">
                 <v-text-field clearable v-model="serial" @keyup.enter="agregarSerial()" label="Escanear o digitar Código"></v-text-field>
				 <v-menu
						v-model="menu21"
						:close-on-content-click="false"
						:nudge-right="40" 
						transition="scale-transition"
						offset-y
						min-width="290px"
					>
						<template v-slot:activator="{ on, attrs }">
						<v-text-field
							v-model="start"
							label="Fecha Vencimiento"
							prepend-icon="mdi-calendar"
							readonly
							persistent-hint
							type="date"
							hint="DD/MM/YYYY formato"
							v-bind="attrs"
							:rules="fechaInicialR"
							v-on="on"
						></v-text-field>
						</template>
						<v-date-picker v-model="start" @input="menu21 = false"></v-date-picker>
					</v-menu>
					<v-edit-dialog
							:return-value.sync="nombreproveedor"
							large
 							persistent
							@save="updateCliente()"
							@cancel="cancel"
							@open="open"
							@close="updateCliente()"
							>
							Proveedor: {{nombreproveedor }}
							<template v-slot:input>
								<v-select
								v-model="nombreproveedor"
								:items="Proveedores"
								label="Nombre del proveedor"
								counter
								></v-select>
								<v-btn color="primary" icon="" @click="GestionProveedores()"> <v-icon> mdi-account-multiple-plus </v-icon>  </v-btn>
							</template>
							</v-edit-dialog> 
              </v-col>
              <v-col class="mt-2" cols="12" sm="2">
                 <v-btn  @click="agregarSerial()"> Agregar</v-btn>
              </v-col>
                <v-col cols="12" sm="12">
                      <v-flex xs12 sm12 md12 lg12 xl12>
                        
                          <v-data-table
                              :headers="cabeceraSeriales"
                              item-key="name" 
                              single-select
                              :items="seriales"
                              class="elevation-1"
                              hide-default-footer=""
                              v-model="selected">
                              <template slot="no-data">
                                  <h3>No hay artículos agregados al detalle.</h3>
                              </template> 
                              <template v-slot:item.actionmovimiento="{ item }">
                                <v-icon
                                  large
                                  color="error"
                                  @click="eliminarProducto(item.id)"
                                >
                                  mdi-delete
                                </v-icon>
                              </template>
                              <template v-slot:item.actioncantidad="{ item }"> 
                                <v-text-field v-model="item.cantidadIngreso">  </v-text-field>
                              </template> 

                          </v-data-table>
                      </v-flex>
                <v-btn  color="primary"  class="mr-4" style="top: 10px;" @click="guardarSeriales()">
                Continuar Cargando
                </v-btn>
                <v-btn color="error" id="espacioBoton" @click="modelSeriales = false">
                Salir
                </v-btn> 
                </v-col>
              </v-row>
              </v-form>
              </v-col>
          </v-container>
        </v-card>
      	</v-dialog>
		<v-dialog v-model="modelAprobaciones" persistent max-width="1000" >
			<v-toolbar  dark color="primary" class="font-weight-light body-1">
				<h4>Aprobaciones de Inventario</h4>
				
			</v-toolbar>
			<v-card>
			<v-container fluid>
				<v-row>
					<v-col cols="12" sm="12">
						<v-flex xs12 sm12 md12 lg12 xl12>
							
							<v-data-table
								:headers="cabeceraAprobaciones"
								item-key="name" 
								single-select
								:items="listaAprobaciones"
								class="elevation-1"
								v-model="selected"
								@click:row="rowClick">
								<template slot="items" slot-scope="props">
										<v-autocomplete
											:items="saleables"
											:item-text="i => i.name"
											:item-value="i => i.id"
											hide-details
										></v-autocomplete>
									<tr>
									<td>{{props.item.idarticulo}}</td>
									<td>{{props.item.descripcion}}</td>
									<v-text-field type="number" v-model="props.item.cantidad"></v-text-field>
									<v-text-field type="number" v-model="props.item.precio"></v-text-field>
									<td> Eliminar </td>
									</tr>
								</template>
								<template slot="no-data">
									<h3>No hay artículos agregados al detalle.</h3>
								</template> 
								<template v-slot:item.actionmovimiento="{ item }">
									<v-icon
									large
									color="error"
									@click="DenegarProducto(item)"
									>
									mdi-delete-circle
									</v-icon>
								</template>
								<template v-slot:item.actioncantidad="{ item }"> 
									<v-icon large @click="AprobarProducto(item)" color="success">mdi-check-circle</v-icon>  
								</template> 

							</v-data-table>
						</v-flex>
					<!-- <v-btn  color="info" :loading="modelCarga" class="mr-4" style="top: 10px;" @click="aprobarProductos()">
					Aprobar Todo
					</v-btn> -->
					<v-btn color="error" id="espacioBoton" @click="modelAprobaciones = false">
					Salir
					</v-btn> 
					</v-col>
				</v-row>
			</v-container>
			</v-card>
		</v-dialog>
		<v-dialog v-model="modelFicha" max-width="1200">
			<v-toolbar dark color="primary">
				<div style="font-size:25px;">Ficha Producto: {{descripcion}} </div>
				
			</v-toolbar>
			<v-card>
				<v-container>
					<v-form ref="form" v-model="validFactura"  @submit.prevent="facturar">
						<v-row no-gutters>
							<v-col cols="12" sm="4">
								<v-text-field dense outlined v-model="cat1" label="Categoria 1"/>
								<v-text-field dense outlined onfocus="this.select();" v-model="codigoProducto" label="Código interno"/> 
								<v-textarea dense v-model="SubFamilia"  auto-grow
								outlined
								rows="1"
								label="Descripción del producto"/>
								<v-text-field dense outlined label="Alias" v-model="Alias" />
							</v-col>
							<v-col cols="12" sm="4">
								<v-select dense outlined v-model="familia"
								:items="familias" label="Familia"/>
								<v-text-field dense outlined onfocus="this.select();" v-model="cabys" label="Código CABYS"/>
								<v-text-field dense outlined onfocus="this.select();" v-model="barcode" label="Código de barras"/>
								<v-card-actions>
									<v-select v-model="proveedor" :items="Proveedores" label="Proveedor"/>
									<v-btn color="primary" icon="" @click="GestionProveedores()"> <v-icon> mdi-account-multiple-plus </v-icon>  </v-btn>
								</v-card-actions>
							</v-col>
							<v-col cols="12" sm="4">
								<v-text-field dense outlined onfocus="this.select();" type="number" v-model="precioVenta" label="Precio Venta"/>
								<v-text-field dense outlined onfocus="this.select();" type="number" v-model="precioCompra" label="Precio Compra"/>
								<v-text-field dense outlined onfocus="this.select();" type="number" v-model="precioMayor" label="Precio 2 (Mayor)"/>
								<v-text-field dense outlined onfocus="this.select();" type="number" v-model="precioDetalle" label="Precio 3(Detalle)"/>
								<v-text-field dense outlined onfocus="this.select();" type="number" v-model="precioCentral" label="Precio 4 (Central)"/>
							</v-col>
						</v-row>
							<v-divider/>
						<v-row>
							<v-col cols="12" sm="4">
								<img width="250" height="250" class="preview" :src="localizacion">
							</v-col>
							<v-col cols="12" sm="8">
								<v-simple-table dense>
									<template v-slot:default>
									<thead>
										<tr class="text-left">
											<h3> Caracteristicas </h3> 
										</tr>
										<tr>
											<th class="text-left">
												Nombre
											</th>
											<th class="text-left">
												Valor
											</th>
											<th class="text-left">
												Fecha
											</th>
											<th class="text-left">
												Usuario
											</th>
											<th class="text-left">
												Eliminar
											</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="item in Caracteristicas"
										:key="item.name"
										>
											<td>{{item.nombre}}</td>
											<td>{{item.valor}} </td>
											<td>{{item.fecha}}</td>
											<td>{{item.usuario}}</td>
											<td><v-icon @click="EliminarAgencia(item)" color="red">mdi-minus  </v-icon></td>
										</tr>
									</tbody>
									</template>
								</v-simple-table>
								<td>
									<h4> Agregar Caracteristica</h4>
									<v-card-actions>
										<v-text-field v-model="Caracteristica" dense outlined label="Caracteristica" /> <v-text-field v-model="valor" dense outlined class="mx-1" label="valor" /> <v-icon @click="AgregarFicha()" color="green">mdi-plus  </v-icon>
									</v-card-actions>
								</td>
								
								<v-btn @click="CargarCaracteristicas()" dark text color="boton"> Agregar a Descripción </v-btn>
							</v-col>
							<v-spacer/>
						</v-row>
					</v-form>
					<v-divider/>
					<v-btn color="green">  WhatsApp</v-btn>
					<v-btn @click="PrintDetails2()"> Kardex </v-btn>
					<v-btn> PDF </v-btn>
				</v-container>
			</v-card>
		</v-dialog>
		<v-dialog v-model="modelproveedor" persistent max-width="1400" class="primary">
			<v-toolbar  dark color="primary" class="font-weight-light body-1">
				<h4>Gestion de Proveedores</h4>
				
			</v-toolbar>
       	 <v-card>
          <v-container fluid>
            <v-row>
            <v-col cols="12" sm="5">
              <v-col cols="12" sm="12">
              <v-form ref="form" v-model="validFactura"  @submit.prevent="facturar">
              <v-row>
                <v-col class="mt-2" cols="12" sm="6">
                  <v-select outlined="" dense="" v-model="tpcedulaproveedor" :rules="[v => !!v || 'El tipo de cédula es requerido']" :items="tipoDocumentos" label="Tipo Cédula"></v-select>
                </v-col>
                <v-col class="mt-2" cols="12" sm="6">
                  <v-text-field outlined="" dense="" v-model="nombreproveedor" label="Nombre"></v-text-field>
                </v-col>
                <v-col class="mt-2" cols="12" sm="6">
                  <v-text-field outlined="" dense="" v-model="cedulaproveedor" label="Cedula"></v-text-field>
                </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field outlined="" dense="" clearable v-model="telefonoproveedor" label="Teléfono"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field outlined="" dense="" clearable v-model="correoproveedor" label="Correo"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <v-text-field outlined="" dense="" clearable v-model="direccionproveedor" label="Dirección"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12">
                        
                  <v-btn  color="primary"  class="mr-4" style="top: 10px;" @click="IngresarProveedor()">
                    <v-icon> mdi-content-save </v-icon>  Guardar
                  </v-btn>
                  <v-btn color="error" id="espacioBoton" @click="modelproveedor = false">
                    <v-icon> mdi-cancel </v-icon> Cancelar
                  </v-btn> 
                  </v-col>
                </v-row>
                </v-form>
                </v-col>
            </v-col>
            <v-col cols="12" sm="7">
                <v-data-table
                    :headers="ProveedoresDetalles"
                    single-select
                    :items="gestionProveedor"
                    class="elevation-1"
                    hide-default-footer=""
                    v-model="selected"
                    @click:row="rowClick">
                    
                    <template slot="no-data">
                        <h3>No hay artículos agregados al detalle.</h3>
                    </template> 
                    <template v-slot:item.actionmovimiento="{ item }">
                      <v-icon
                        large
                        color="error"
                        @click="deleteProveedor(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <template v-slot:item.actioncantidad="{ item }"> 
                      <v-text-field v-model="item.cantidadIngreso">  </v-text-field>
                    </template> 

                </v-data-table>
            </v-col>
            </v-row>
          </v-container>
        </v-card>
		</v-dialog>
    	  <v-snackbar
			v-model="snackbar" top vertical="" :timeout="4000"
			>
			{{ Mensaje }}
			<v-btn
			color="pink"
			text
			@click="snackbar = false"
			>
			Close
			</v-btn>
      	</v-snackbar>
      <v-row flat class="mt-4 mx-2" color="white">
		<v-select style="width:30%" prepend-inner-icon="mdi-filter" @change="SelectCat()" filled label="Filtrar por Categoría" :items="Categorias" v-model="Categoria" /> 
		<v-select style="width:30%" class="mx-2" prepend-inner-icon="mdi-filter" @change="SelectFams()" filled label="Filtrar por Familia" :items="familias" v-model="familia" /> 
		<v-select style="width:10%" class="mx-2" prepend-inner-icon="mdi-filter" @change="SelectDOC()" filled v-model="activo" :items="activos" label="Documentación" /> 
		<v-btn @click="initialize()" icon> <v-icon> mdi-eraser </v-icon> </v-btn>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
         <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar"
			filled
			@keyup="initializeFinder()"
			id="input"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
		<v-dialog v-model="dialog" persistent max-width="1800px">
            <template v-slot:activator="{ on }">
				<v-menu top :offset-y="offset">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="info"
                      dark
                      fab=""
                      v-bind="attrs"
					  :disabled="esVendedor"
                      v-on="on"
                      :loading="loading"
                    > <v-icon >mdi-dots-horizontal</v-icon>
                      
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item @click="Aprobaciones()">
                      <v-list-item-title><v-icon >mdi-beaker-check</v-icon> Aprobaciones</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="modelinventario= true">
                      <v-list-item-title > <v-icon >mdi-excavator</v-icon> Ajuste Inventario</v-list-item-title>
                    </v-list-item>
					<v-list-item @click="modeltrazlado= true">
                      <v-list-item-title > <v-icon >mdi-bank-transfer</v-icon> Traslado Mercadería</v-list-item-title>
                    </v-list-item>
                    <v-list-item to="/inventarioactual">
                      <v-list-item-title > <v-icon >mdi-guitar-acoustic</v-icon> Inventario Actual </v-list-item-title>
                    </v-list-item>
					<v-list-item to="/movimientoProductos">
                      <v-list-item-title > <v-icon >mdi-rv-truck</v-icon> Movimiento Productos </v-list-item-title>
                    </v-list-item>
                    <v-list-item v-on="on" rounded="">
                      <v-list-item-title  >
                       <v-icon >mdi-timeline-plus</v-icon> Nuevo Producto</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
          </template>
         	 <v-card>
				 <v-card-title style="font-size:23px;" class="boton white--text">
					Gestion de producto : {{descripcion}}
				</v-card-title>
					<v-row>
						<v-col cols="12" sm="3" >
							<div class="mx-2">
								<strong style="font-size:18px;">Aplicar gestion en </strong>
								
								<v-checkbox
									v-for="item in localidades"
          							:key="item"
									style="border-bottom: 1px solid rgb(224, 224, 224);"
									v-model="LocalidadesSelectas"
									:label="item"
									:value="item"
								></v-checkbox>
								<v-divider/>
								<strong style="font-size:18px;">Configuraciones </strong>
								<v-checkbox
									style="border-bottom: 1px solid rgb(224, 224, 224);"
									label="Compuesto"
									v-model="compuesto"
								></v-checkbox>
								<v-select
									style="border-bottom: 1px solid rgb(224, 224, 224);"
									v-model="activo"
									:items="activos"
									label="Mostrar Item en la web"
								></v-select>
								<v-checkbox
									style="border-bottom: 1px solid rgb(224, 224, 224);"
									v-model="Ivi"
									label="Precio Venta con I.V.A.I"
									:value="Ivi"
								></v-checkbox>
								<v-divider/>
								<template>
									<div>
										<strong style="font-size:18px;">Cargar Imagen </strong>
										<input type="file" @change="onChangeFileUpload" accept="image/*" >
									</div>
									<div v-if="uploadValue != 0 || uploadValue != 100">
										<p>Progreso: {{uploadValue.toFixed()+"%"}}
									<progress id="progress" :value="uploadValue" max="100" ></progress>  </p>
									
									</div>
									<div v-if="uploadValue != 0">
										<img width="170" height="170" class="preview" :src="localizacion">
									</div>
								</template>
							</div>
						</v-col>
						<v-col style="border-left: 1px solid rgb(224, 224, 224);" cols="12" sm="9">
							<v-row class="mx-1">
								<v-col style="border: 1px solid rgb(224, 224, 224);" cols="12" lg="8" md="8" sm="6" xs="6">
									<v-text-field filled hide-details="" v-model="descripcion" label="Nombre"></v-text-field>
									<v-text-field filled hide-details=""  v-model="cat1" label="Categoria 1">
									</v-text-field>
									<v-row>
										<v-select class="mx-3" filled hide-details="" v-model="familia"
										:items="familias" label="Familia"/>
										<v-icon @click="EliminarFamilia()" v-if="familia != ''" color="red">mdi-minus  </v-icon>
										<v-edit-dialog
											:return-value.sync="TourOperador"
											@save="IngresarFamilia()"
											>
											<v-icon color="green">mdi-plus</v-icon> 
											<template v-slot:input>
												<v-text-field
												onfocus="this.select();"
												v-model="familia"
												filled hide-details=""
												label="Digite el nombre de la familia"
												counter
												></v-text-field>
											</template>
										</v-edit-dialog> 
									</v-row>
									<v-row>
									<v-select class="mx-3" filled hide-details="" v-model="proveedor"
									:items="Proveedores" label="Proveedor">
									</v-select>
									<v-btn color="primary" icon="" @click="GestionProveedores()"> <v-icon> mdi-account-multiple-plus </v-icon>  </v-btn>
									</v-row>
								</v-col>
								<v-col style="border: 1px solid rgb(224, 224, 224);" cols="12" lg="4" md="4" sm="6" xs="6">
									<v-select filled hide-details=""  v-model="Categoria" :items="Categorias" label="Categoria"/>
									<v-text-field :disabled="formTitle == 'Actualizar'" filled hide-details="" onfocus="this.select();" v-model="codigoProducto" label="Código interno"/>
									<v-text-field filled hide-details="" onfocus="this.select();" v-model="cabys" label="Código CABYS"/>
									<v-text-field filled hide-details="" onfocus="this.select();" v-model="barcode" label="Código de barras"/>
									
								</v-col>
								<v-col style="border: 1px solid rgb(224, 224, 224);" cols="12" lg="4" md="3" sm="6" xs="6">
									<v-text-field filled hide-details="" onfocus="this.select();" type="number" v-model="precioVenta" label="Precio Venta"/>
									<v-text-field filled hide-details="" onfocus="this.select();" type="number" v-model="precioMayor" label="Precio 2 (Mayor)"/>
									<v-text-field filled hide-details="" onfocus="this.select();" type="number" v-model="precioDetalle" label="Precio 3(Detalle)"/>
									<v-text-field v-if="esSupervisor || esAdministrador || esBoss" filled hide-details="" onfocus="this.select();" type="number" v-model="precioCompra" label="Precio Compra"/>
									<v-text-field filled hide-details="" type="date" v-model="Fecha" label="Fecha Ingreso"/>
								</v-col>
								<v-col style="border: 1px solid rgb(224, 224, 224);" cols="12" lg="3" md="3" sm="6">
									<v-text-field filled hide-details="" onfocus="this.select();" hint="Digite unicamente números" type="number" persistent-hint v-model="nicotina" label="Color"/>
									<v-text-field filled hide-details="" onfocus="this.select();" v-model="maxDescuento" label="Max% Descuento"/>
									<v-select filled hide-details="" v-model="categoria" @change="EsGravado()"
									:items="Impuesto" label="% IVA"/>
									<v-select filled hide-details="" v-model="cocina"
									:items="Cocinas" label="Comanda"/>
									<v-text-field filled hide-details="" onfocus="this.select();" v-model="depresiacion" label="Depreciación"/>
									<v-text-field filled hide-details="" v-if="Categoria == 'ACTIVO'" onfocus="this.select();" v-model="vidaUtil" label="Vida Util en años"/>
								</v-col>
								<v-col style="border: 1px solid rgb(224, 224, 224);" cols="12" lg="3" md="3" sm="6">
									<v-text-field filled hide-details="" onfocus="this.select();" v-model="ubicacion" label="Ubicacion"/>
									<v-text-field filled hide-details="" onfocus="this.select();" v-model="sku" label="Código SKU"/>
									<v-combobox
										v-model="variaciones"
										chips
										clearable
										filled hide-details=""
										label="Tags o Tallas"
										persistent-hint
										hint="Seleccione cantidades predefinadas a vender o variaciones del producto, tallas ect."
										multiple
										variant="solo"
									>
										<template v-slot:selection="{ attrs, item, select, selected }">
										<v-chip
											v-bind="attrs"
											:model-value="selected"
											closable
											color="info"
											@click="select"
											@click:close="remove(item)"
										>
											<strong>{{ item }}</strong>&nbsp;
										</v-chip>
										</template>
									</v-combobox>
									
									<v-autocomplete
									:items="acabadosFiltrados"
									label="Acabados"
									chips
									filled
									closable-chips
									v-model="acabadosSeleccionados"
									multiple
									></v-autocomplete>
									
									
								</v-col>
								<v-col style="border: 1px solid rgb(224, 224, 224);" cols="12" lg="2" md="3" sm="6">
									<v-text-field filled hide-details="" onfocus="this.select();" v-model="maximo" label="Maximo"/>
									<v-text-field filled hide-details="" onfocus="this.select();" v-model="minimo" label="Minimos"/>
									<v-text-field filled hide-details="" onfocus="this.select();" v-model="comision" label="Comisión"/>
									<v-text-field filled hide-details="" onfocus="this.select();" v-model="Color" label="Color"/>
									<v-text-field filled hide-details=""  onfocus="this.select();" v-model="MaxFactura" label="% Max Fact"/>
								</v-col>
								<v-col style="border: 1px solid rgb(224, 224, 224);" cols="12" lg="10" md="10" sm="12" v-if="esProducto">	
									
									<v-textarea v-model="SubFamilia"  auto-grow
										solo
										rows="1"
										row-height="10"
										label="Descripción del producto">
									</v-textarea>
								</v-col>
								<v-col style="border: 1px solid rgb(224, 224, 224);" cols="12" sm="3" v-if="!esProducto">
									<v-menu
									ref="menu"
									v-model="menu6"
									:close-on-content-click="false"
									:nudge-right="40"
									:return-value.sync="time"
									transition="scale-transition"
									offset-y
									max-width="290px"
									min-width="290px"
									>
									<template v-slot:activator="{ on }">
										<v-text-field
										v-model="hora"
										label="Hora Inicial:"
										readonly
										v-on="on"
										></v-text-field>
									</template>
									<v-time-picker format="24h" @click:minute="$refs.menu.save(time)" @change="menu6 = false" min="05:00" v-if="menu6" v-model="hora" color="green lighten-1" :max="hora2" :allowed-minutes="(m) => m % 30 == 0" header-color="primary"></v-time-picker>
									</v-menu>
									
								</v-col>
								<v-col style="border: 1px solid rgb(224, 224, 224);" cols="12" sm="3" v-if="!esProducto">
								<div>
								</div>
									<v-menu
									ref="menu"
									v-model="menu7"
									:close-on-content-click="false"
									:nudge-right="40"
									:return-value.sync="time"
									transition="scale-transition"
									offset-y
									max-width="290px"
									min-width="290px"
									>
									<template v-slot:activator="{ on }">
										<v-text-field
										v-model="hora2"
										:rules="horaFinalR"
										label="Hora Final:"
										readonly
										v-on="on"
										></v-text-field>
									</template>
									<v-time-picker format="24h"  scrollable="" full-width @click:minute="$refs.menu.save(time)" v-if="menu7" v-model="hora2" color="green lighten-1" :min="hora"  :allowed-minutes="(m) => m % 60 == 0" header-color="primary"></v-time-picker>
										</v-menu>
								</v-col>
								<v-col style="border: 1px solid rgb(224, 224, 224);" cols="12" sm="3" v-if="!esProducto">
								<v-text-field v-model="hora4" type="tel" label="Duración Maxima en horas"> </v-text-field>
								</v-col>
								<v-col style="border: 1px solid rgb(224, 224, 224);" cols="12" sm="3" v-if="!esProducto">
								<v-text-field v-model="CantPersonas" type="tel" label="Cantidad máxima de personas"> </v-text-field>
								</v-col>
								<v-flex xs12 sm12 md12 v-show="valida">
									<v-alert
										text
										prominent
										v-for="v in validaMensaje" :key="v" v-text="v"
										type="error"
										icon="mdi-cloud-alert"
									>
									</v-alert>
								</v-flex>
							</v-row>
						</v-col>
					</v-row>
					<v-divider/>
					<v-card-actions>
						<v-btn color="success" outlined class="mx-4"  @click="abrirCabys()">Catalogo CABYS</v-btn>
						{{Cateogoria}}
						<v-spacer>
						</v-spacer>
						<v-btn :loading="modelCarga" color="error" outlined class="mx-4"  @click="close"> <v-icon> mdi-cancel </v-icon> Cancelar</v-btn>
						<v-btn :loading="modelCarga" color="green" outlined class="mx-4"  @click="guardar"> <v-icon> mdi-content-save </v-icon> {{ formTitle }}</v-btn>
					</v-card-actions>
			</v-card>
        </v-dialog>
      </v-row>
    </template>
    <template v-slot:item.action="{ item }">
      <v-menu top :offset-y="offset">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                fab=""
                v-bind="attrs"
                v-on="on"
                :loading="loading"
              > <v-icon color="info">mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="AñadirCodigo(item)">
                <v-list-item-title><v-icon >mdi-barcode-scan</v-icon> Gesionar códigos seriales</v-list-item-title>
              </v-list-item>
              <v-list-item >
                <v-list-item-title @click="DeleteProducto(item)" >
                  <v-icon color="info">mdi-dots-horizontal</v-icon>Eliminar </v-list-item-title>
              </v-list-item>
              <v-list-item @click="editItem(item)">
                <v-list-item-title >
                <v-icon >mdi-pencil</v-icon> Editar Producto</v-list-item-title>
              </v-list-item>
			  <v-list-item @click="OpenFicha(item)">
                <v-list-item-title >
                <v-icon >mdi-clipboard-check</v-icon> Ficha Producto</v-list-item-title>
              </v-list-item>
              
            </v-list>
      </v-menu>
    </template>
    
    <template v-slot:no-data>
      <p> Por favor digite el producto a buscar y digite enter en el buscador para obtener información de item </p>
    </template>
  </v-data-table>
  
</template>


<script>
import axios from 'axios'
// import firebase from "firebase";
  	export default {
    data: () => ({
		dialog: false,
		search: '',
		modelSeriales: false,
		comprobanteModal:true,
		seriales: [],
		vencimiento: "",
		idMovimiento: 0,
		serial : "",
		Variaciones: [{Variacion:"Talla",Estado:true,Opciones:[
				{title: 'XS', Estado: "Suspendido"},
				{title: 'S', Estado: "Disponible"},
				{title: 'M',Estado: "Sin Stock"},
				{title: 'L',Estado: "Disponible"},
				{title: 'XL',Estado: "Suspendido"},
		]}, 
		{Variacion:"Color",Estado:true,Opciones:[
				{title: 'Negro', Estado: "Suspendido"},
				{title: 'Gris', Estado: "Disponible"},
				{title: 'Azul',Estado: "Sin Stock"},
				{title: 'Rosa',Estado: "Disponible"},
				{title: 'Rojo',Estado: "Suspendido"},
				{title: 'Lila',Estado: "Suspendido"},
				{title: 'Verde',Estado: "Suspendido"},
		]}],
		variaciones:[],
		acabadosSeleccionados:[],
		cat1: "",
		impespecial:0,
		localidadorigen: "",
		localidaddestino: null,
		modeltrazlado:false,
		headers: [
			{ text: 'Opciones', value: 'action', sortable: false },
			{ text: 'Código', value: 'codigoProducto' },
			{ text: 'Nombre', value: 'descripcion' },
			{ text: 'Familia', value: 'tipoProducto' },
			{ text: 'CodigoBarras', value: 'barcode',  },
			{ text: 'Precio Venta', value: 'precioVenta',  },
			{ text: 'IVI', value: 'estadoInventario', },
			{ text: '% Imp', value: 'valorImpuesto', },
			{ text: 'PrecioCompra', value: 'precioCompra',  },
			{ text: 'Cabys', value: 'codigoRaiz',  },       
			{ text: 'Categoría', value: 'categoria',  },       
			{ text: 'Proveedor', value: 'proveedor',  }, 
			{ text: 'Ubicación', value: 'innerPack',  },  
			{ text: 'Docs', value: 'actionOnline',  },  
		],
		Impuesto:[    
			{ text: '0% Excento', value: 0  },
			{ text: '1% Canasta Bàsica e Insumos', value: 1  }, 
			{ text: '2% Medicamentos, Primas, ect.' , value: 2  },
			{ text: '4% Boletos y Salud', value: 4  }, 
			{ text: '13% Tarifa General', value: 13  },                      
			],
			Compuestos:[    
				{ text: 'NO', value: false  },
				{ text: 'SI', value: true  },     
			],
			Proveedores:[    
				{ text: 'Mi Tienda', value: "Mi Tienda"  },              
			],
			Cocinas:[    
				{ text: 'NO', value: 0  },
				{ text: 'Cocina', value: 1  }, 
				{ text: 'Bar', value: 2  }, 
				{ text: 'Kiosko 1', value: 3  }, 
				{ text: 'Kiosko 2', value: 4  }, 
									
			],
		codigo: '',
		timeouts: [],
		modelAprobaciones: false,
		tipoDocumentos: [
			'FISICA',
			'JURIDICA',
			'EXTRANJERO',
			],
		nombre: '',
		localidadencabezado:[],
		truevalue: true,
		uploadValue1:0,
		SubFamilia: '',
		stock: 0,
		modelproveedor: false,
		detalles:[],
		Fecha:"",
		Caracteristicas: [],
		detalle: '',
		time:"",
		start: null,
		menu21: null,
		cabeceraSeriales: [
		{ text: 'Código Producto', value: 'codigoproducto', sortable: false  },
		{ text: 'Nombre Producto', value: 'descricion', soTrtable: false  },
		{ text: 'Código Serial', value: 'codigoSerial', sortable: false  },
		{ text: 'Estado ', value: 'estado', sortable: false  },
		{ text: 'Vencimiento ', value: 'vencimiento', sortable: false  },
		{ text: 'Proveedor ', value: 'proveedor', sortable: false  },
		{ text: 'Eliminar ', value: 'actionmovimiento', sortable: false  },
		],
		cabeceraDetalles: [
			{ text: 'Código', value: 'idarticulo', sortable: false  },
			{ text: 'Descripción', value: 'descripcion', sortable: false  },
			{ text: 'Precio', value: 'precio', sortable: false  },
			{ text: 'Stock Origien', value: 'cantidad', sortable: false  },
			{ text: 'Stock Destino', value: 'cantidadDestino', sortable: false  },
			{ text: 'Cantidad Ingresar', value: 'actioncantidad', sortable: false  },
			{ text: 'Stock de', value: 'localidad', sortable: false  },
			{ text: 'Eliminar', value: 'actionmovimiento', sortable: false },
		],
		cabeceraAprobaciones: [
		{ text: 'Descripción', value: 'descripcion'  },
		{ text: 'Fecha', value: 'fecha' },
		{ text: 'Usuario', value: 'cajero' },
		{ text: 'Tipo', value: 'tipo' },
		{ text: 'Cantidad', value: 'cantidad' },
		{ text: 'Aprobar', value: 'actioncantidad', sortable: false  },
		{ text: 'Denegar', value: 'actionmovimiento', sortable: false },
		],
		ProveedoresDetalles: [
		{ text: 'Nombre', value: 'proveedor'},
		{ text: 'Tipo Ced', value: 'tipoCedula'},
		{ text: 'Cédula', value: 'cedula', sortable: false  },
		{ text: 'Correo', value: 'correo', sortable: false  },
		{ text: 'Dirección', value: 'direccion', sortable: false  },
		{ text: 'Eliminar', value: 'actionmovimiento', sortable: false },
		],
		precio_venta: 0,
		modelFicha: false,
		descripcion: '',
		valida: 0,
		Cat: '',
		CantPersonas: 2,
		TipoMovimiento: [
			{text:'Entrada', value: 'INGRESO'},
			{text:'Salida', value:'SALIDA'}],
			activos: [
				{text:'SI', value: 1},
				{text:'NO', value:0}],
		validaMensaje:[],

		Categoria: 'PRODUCTO',
		Categorias: ["PRODUCTO","INSUMO","SERVICIO","CONSIGNACION","ACTIVO"],
		esProducto: true,
		Ivi: true,
		articulos:[],
		adModal: 0,
		activo:0,
		Mensaje: '',
		snackbar: false,
		menu2: false,
		menu3: false,
		menu4: false,
		menu5: false,
		menu6: false,
		menu7: false,
		menu8: false,
		menu9: false,
		hora: "00:10",
		sku:"",
		Talla:"",
		Color:"",
		hora2: "24:00",
		hora4: 30,
		adAccion: 0,
		adNombre: '',
		adId: '',
		barcode: '',
		cantidadIngreso: 0,
		cabys: 0,
		listaAprobaciones: [],
		selectedFile: null,
		Costo:'',
		loading: false,
		cedulaproveedor: '',
		tpcedulaproveedor: '',
		nombreproveedor: '',
		telefonoproveedor: '',
		correoproveedor: '',
		direccionproveedor: '',
		codigoProducto: '',
		proveedor:'Mi Tienda',
		modelCarga: true,
		items:[],
		categoria:13,
		familia:'NA',
		precio: 0,
		modelinventario: false,
		familias:[],
		precioCompra:10,
		precioVenta: 0,
		precioMall: 0,
		precioCentral: 0,
		precioDetalle: 0,
		precioMayor: 0,
		maximo:5,
		minimo:1,
		LocalidadesSelectas:[],
		tipoProducto:'',
		maxDescuento: 90,
		comision:1,
		codigoCABYS: "",
		IdProductKey:0,
		localidades:[],
		idProductKey: 0,
		localizacion:'https://noah.cr/ImagenesGenerales/usuario.png',
		compuesto: false,
		uploadValue: 0,
		switch1: true,
		cocina: 0,
		estado: 0,
		DatoMovimiento: '',
		nombre: 'NA',
		SoN: 'S',
		localidad: "",
		productos: [],
		desserts: [],
		editedIndex: -1,
		editedItem: {
		stop: false,
			name: '',
			gestionProveedor: [],
		},
		defaultItem: {
			name: '',
			calories: 0,
			fat: 0,
			carbs: 0,
			protein: 0,
		},
    }),

    computed: {
		formTitle () {
			return this.editedIndex === -1 ? 'Agregar' : 'Actualizar'
		},
		esAdministrador(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='Administrador';
		},
		esSupervisor(){
       	 	return this.$store.state.usuario && this.$store.state.usuario.rol =='Supervisor';
		},
		esReceptor(){
       	 	return this.$store.state.usuario && this.$store.state.usuario.rol =='Recepcion';
		},
	  	esBodega(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='Bodegas';
		},
		esVendedor(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='Vendedor';
		},
		esCliente(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='Cliente';
		},
		esRecepcion(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='Recepcion';
		},
		esCrifasa(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='Crifasa';
		},
		esBoss(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='King Boss';
		},
		esMarketing(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='King Marketing';
		},
		esFacturador(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='King Facturador';
		},
      
    },

    watch: {
		dialog (val) {
			val || this.close()
		},
    },

    created () {
		this.initialize2()
		this.select()
		this.selectProveedores();
		this.selectProductos(this.$store.state.usuario.localidad);
		this.listarAsociadas();
		this.listarLocalidad();
		this.localidadorigen = this.$store.state.usuario.localidad;
		if(this.esBodega || this.esBoss){
			this.modeltrazlado = true;
		}
    },

    methods: {
		onChangeFileUpload(event){
		this.selectedFile = event.target.files[0];
		this.onUpload();
		},
		// Método para subir el archivo al servidor
		async onUpload() {
			if (!this.selectedFile) {
				alert('Por favor selecciona un archivo.');
				return;
			}

			// Crear un FormData y añadir el archivo
			const formData = new FormData();
			formData.append('file', this.selectedFile);

			try {
				// Realizar la solicitud POST al servidor en producción
				const response = await fetch('https://erp.pdvcostarica.com/api/upload', {
					method: 'POST',
					body: formData,
				});

				if (response.ok) {
					const data = await response.json();
					// Guardar la URL del archivo retornada por el servidor
					this.localizacion = data.url;
					alert('Imagen subida con éxito: ' + this.localizacion);
				} else {
					console.error('Error al subir la imagen:', response.statusText);
					alert('Hubo un error al subir la imagen.');
				}
			} catch (error) {
				console.error('Error en la solicitud:', error);
				alert('Error de red al intentar subir la imagen.');
			}
		},

		handleInput(value, item) {
			// Reemplaza cualquier caracter que no sea un número con una cadena vacía
			const onlyNumbers = value.replace(/[^0-9]/g, '');

			// Asigna el valor filtrado de nuevo al item
			item.cantidadIngreso = onlyNumbers;
		},
		handleFocus(event, id) {
			this.$refs['input'+id][0].select();
		},
		preventNonNumericInput(event) {
			const allowedChars = /[0-9]/;
			if (!event.key.match(allowedChars)) {
				event.preventDefault();
			}
		},
		validarCantidad(item) {
			if (item.cantidadIngreso > item.cantidad) {
				this.$set(item, 'cantidadIngreso', item.cantidad); // Usamos $set para forzar la reactividad
				this.snackbar = true;
				this.Mensaje = "No puedes trasladar más de "+item.cantidad + " cantidades de "+item.descripcion;
				
			}
		},
		listarAsociadas(){
			var items = [];
			let me=this;
			me.localidades.push(me.$store.state.usuario.localidad);
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			axios.get('api/LocalidadesAsociadas/'+this.$store.state.usuario.nombre,configuracion).then(function(response){
				items=response.data;
				items.map(function(x){
					me.localidades.push(x.localdiadAsociada);
				});
			}).catch(function(error){
				me.Mensaje = "Localidades Asociadas no encontradas";
				me.snackbar = true;
				me.modelCarga=false;
			});
		},
		printDiv(){
			var printContents = document.getElementById("bill").innerHTML;
			var originalContents = document.body.innerHTML;

			document.body.innerHTML = printContents;

			window.print('', '', 'height=500, width=500');

			document.body.innerHTML = originalContents;
		},
		PrintDetails(){
			var products = "";
			for(var i = 0; i < this.detalles.length; ++i)
				{
					products += `<tr>
								<td class="col-3">`+this.detalles[i].idarticulo+`</td>
								<td class="col-6 text-1">`+this.detalles[i].descripcion+`</td>
								<td class="col-3 text-center">`+this.detalles[i].precio+`</td>
								<td class="col-1 text-center">`+this.detalles[i].cantidadDestino+`</td>
								<td class="col-1 text-center">`+this.detalles[i].cantidadIngreso+`</td>
								</tr>`
				}
			var win = window.open('', '', 'height=1200, width=850');
				win.document.write(`
				<html lang="en">
						<head>
						<meta charset="utf-8" />
						<meta http-equiv="X-UA-Compatible" content="IE=edge">
						<meta name="viewport" content="width=device-width, initial-scale=1">
						<link href="images/favicon.png" rel="icon" />
						<title>General Invoice - Koice</title>
						<meta name="author" content="harnishdesign.net">

						<!-- Web Fonts
						======================= -->
						<link rel='stylesheet' href='https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900' type='text/css'>

						<!-- Stylesheet
						======================= -->
						<link rel="stylesheet" type="text/css" href="https://www.noah.cr/FacturaCSS/vendor/bootstrap/css/bootstrap.min.css"/>
						<link rel="stylesheet" type="text/css" href="https://www.noah.cr/FacturaCSS/vendor/font-awesome/css/all.min.css"/>
						<link rel="stylesheet" type="text/css" href="https://www.noah.cr/FacturaCSS/css/stylesheet.css"/>
						</head>
						<body>
						<!-- Container -->
						<div class="container-fluid invoice-container">
						<!-- Header -->
						<header>
						<div class="row align-items-center">
							<div class="col-sm-7 text-center text-sm-start mb-3 mb-sm-0">
							<img id="logo" style="max-width:100px" src="`+this.Logo3+`" title="Logotipo" alt="Koice" />
							</div>
							<div class="col-sm-5 text-center text-sm-end">
							<h4 class="text-7 mb-0">Traslado de Mercadería</h4>
							</div>
						</div>
						<hr>
						</header>
						
						<!-- Main Content -->
						<main>
						<div class="row">
							<div class="col-sm-6"><strong>Fecha:</strong> `+this.Fecha+`</div>
							<div class="col-sm-6 text-sm-end"> <strong>Número de traslado :</strong> `+this.idMovimiento+`</div>
							
						</div>
						<hr>
						<div class="row">
							<div class="col-sm-6 text-sm-end order-sm-1"> <strong>Info de transacción:</strong>
							<address>
							Usuario: `+this.$store.state.usuario.nombre+`<br />
							Origen: `+this.localidadorigen+`<br />
							Destino:`+this.localidaddestino+`<br />
							`+this.localidadencabezado[0].telefono+`
							</address>
							</div>
							<div class="col-sm-6 order-sm-0"> <strong>Datos del Negocio:</strong>
							<address>
							`+this.localidadencabezado[0].nombre+`<br />
							`+this.localidadencabezado[0].razonSocial+`<br />
							`+this.localidadencabezado[0].cedulaJuridica+`<br />
							`+this.localidadencabezado[0].telefono+`
							</address>
							</div>
						</div>
							
						<div class="card">
							<div class="card-body p-0">
							<div class="table-responsive">
								<table class="table mb-0">
								<thead class="card-header">
								<tr>
									<td class="col-3"><strong>Código I</strong></td>
									<td class="col-6"><strong>Nombre</strong></td>
									<td class="col-3 text-center"><strong>Precio Venta</strong></td>
									<td class="col-1 text-center"><strong>Stock Anterior</strong></td>
									<td class="col-1 text-center"><strong>Cantidad Trasladada</strong></td>
								</tr>
								</thead>
								<tbody>
									`+products+`
								</tbody>
								<tfoot class="card-footer">
									<tr>
										<td colspan="4" class="text-end"><strong>Mensajero:</strong></td>
										<td class="text-end">________</td>
									</tr>
									<tr>
										<td colspan="4" class="text-end"><strong>Entregado por: </strong></td>
										<td class="text-end">________</td>
									</tr>
									<tr>
										<td colspan="4" class="text-end border-bottom-0"><strong>Recibido por:</strong></td>
										<td class="text-end border-bottom-0">________</td>
									</tr>
								</tfoot>
								</table>
							</div>
							</div>
						</div>
						</main>
						<!-- Footer -->
						<footer class="text-center mt-4">
						<p class="text-1"><strong>NOTE :</strong> `+this.detalle+`.</p>
						<div class="btn-group btn-group-sm d-print-none"> <a href="window.print()" class="btn btn-light border text-black-50 shadow-none"><i class="fa fa-print"></i> Print</a> <a href="" class="btn btn-light border text-black-50 shadow-none"><i class="fa fa-download"></i> Download</a> </div>
						</footer>
						</div>
						</body>
					</html>
				`);
				setTimeout(() => {
					win.print();
				}, 2000);
				//win.window.close();
		},
		AgregarVariacion(){
			this.Variaciones.push(
				{
				id: Date.now(),
				Variacion:this.CategoriaVariacion,
				Estado:true,
				Opciones: this.chips,
				}
			);
			this.dialogCompuesto= false;
		},
		PrintDetails2(){
			var products = "";
			for(var i = 0; i < this.detalles.length; ++i)
				{
					products += `<tr>
								<td class="col-3">`+this.detalles[i].idarticulo+`</td>
								<td class="col-6 text-1">`+this.detalles[i].descripcion+`</td>
								<td class="col-3 text-center">`+this.detalles[i].precio+`</td>
								<td class="col-1 text-center">`+this.detalles[i].cantidadDestino+`</td>
								<td class="col-1 text-center">`+this.detalles[i].cantidadIngreso+`</td>
								</tr>`
				}
			var win = `
						<html lang="en">
						<head>
						<meta charset="utf-8" />
						<meta http-equiv="X-UA-Compatible" content="IE=edge">
						<meta name="viewport" content="width=device-width, initial-scale=1">
						<link href="images/favicon.png" rel="icon" />
						<title>General Invoice - Koice</title>
						<meta name="author" content="harnishdesign.net">

						<!-- Web Fonts
						======================= -->
						<link rel='stylesheet' href='https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900' type='text/css'>

						<!-- Stylesheet
						======================= -->
						<link rel="stylesheet" type="text/css" href="https://www.noah.cr/FacturaCSS/vendor/bootstrap/css/bootstrap.min.css"/>
						<link rel="stylesheet" type="text/css" href="https://www.noah.cr/FacturaCSS/vendor/font-awesome/css/all.min.css"/>
						<link rel="stylesheet" type="text/css" href="https://www.noah.cr/FacturaCSS/css/stylesheet.css"/>
						</head>
						<body>
						<!-- Container -->
						<div class="container-fluid invoice-container">
						<!-- Header -->
						<header>
						<div class="row align-items-center">
							<div class="col-sm-7 text-center text-sm-start mb-3 mb-sm-0">
							<img id="logo" style="max-width:100px" src="`+this.Logo3+`" title="Logotipo" alt="Koice" />
							</div>
							<div class="col-sm-5 text-center text-sm-end">
							<h4 class="text-7 mb-0">Traslado de Mercadería</h4>
							</div>
						</div>
						<hr>
						</header>
						
						<!-- Main Content -->
						<main>
						<div class="row">
							<div class="col-sm-6"><strong>Fecha:</strong> `+this.Fecha+`</div>
							<div class="col-sm-6 text-sm-end"> <strong>Número de trazlado `+this.barcode+`:</strong> 16835</div>
							
						</div>
						<hr>
						<div class="row">
							<div class="col-sm-6 text-sm-end order-sm-1"> <strong>Info de transacción:</strong>
							<address>
							Usuario: `+this.$store.state.usuario.nombre+`<br />
							Origen: `+this.localidadorigen+`<br />
							Destino:`+this.localidaddestino+`<br />
							`+this.localidadencabezado[0].telefono+`
							</address>
							</div>
							<div class="col-sm-6 order-sm-0"> <strong>Datos del Negocio:</strong>
							<address>
							`+this.localidadencabezado[0].nombre+`<br />
							`+this.localidadencabezado[0].razonSocial+`<br />
							`+this.localidadencabezado[0].cedulaJuridica+`<br />
							`+this.localidadencabezado[0].telefono+`
							</address>
							</div>
						</div>
							
						<div class="card">
							<div class="card-body p-0">
							<div class="table-responsive">
								<table class="table mb-0">
								<thead class="card-header">
								<tr>
									<td class="col-3"><strong>Código I</strong></td>
									<td class="col-6"><strong>Nombre</strong></td>
									<td class="col-3 text-center"><strong>Precio Venta</strong></td>
									<td class="col-1 text-center"><strong>Stock Anterior</strong></td>
									<td class="col-1 text-center"><strong>Cantidad Trasladada</strong></td>
								</tr>
								</thead>
								<tbody>
									`+products+`
								</tbody>
								<tfoot class="card-footer">
									<tr>
										<td colspan="4" class="text-end"><strong>Mensajero:</strong></td>
										<td class="text-end">________</td>
									</tr>
									<tr>
										<td colspan="4" class="text-end"><strong>Entregado por: </strong></td>
										<td class="text-end">________</td>
									</tr>
									<tr>
										<td colspan="4" class="text-end border-bottom-0"><strong>Recibido por:</strong></td>
										<td class="text-end border-bottom-0">________</td>
									</tr>
								</tfoot>
								</table>
							</div>
							</div>
						</div>
						</main>
						<!-- Footer -->
						<footer class="text-center mt-4">
						<p class="text-1"><strong>NOTE :</strong> `+this.detalle+`.</p>
						<div class="btn-group btn-group-sm d-print-none">  <a href="#" onclick="window.print();" class="btn btn-light border text-black-50 shadow-none"><i class="fa fa-print"></i> Print</a>  <a href="" class="btn btn-light border text-black-50 shadow-none"><i class="fa fa-download"></i> Volver</a> </div>
						</footer>
						</div>
						</body>
					</html>
				`;
				document.documentElement.innerHTML = win;
				setTimeout(() => {
					win.print();
				}, 2000);
				//win.window.close();
		},
		
		listarAsociadas(){
			var items = [];
			let me=this;
			me.localidades.push(me.$store.state.usuario.localidad);
			this.localidad = this.$store.state.usuario.localidad;
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			axios.get('api/LocalidadesAsociadas/'+this.$store.state.usuario.nombre,configuracion).then(function(response){
				items=response.data;
				items.map(function(x){
					me.localidades.push(x.localdiadAsociada);
				});
			}).catch(function(error){
				alert("Localidades Asociadas no encontradas");
				me.modelCarga=false;
			});
		},
		initialize(){
			let me=this;
			this.modelCarga = true;
			this.familia = "";
			let barcodepreview = Date.now();
			let idsa = barcodepreview.toString();
			this.codigoProducto = idsa.substring(5, 12);
			this.detalle = "Id Transacción: "+idsa.substring(5, 12),
			this.barcode = idsa.substring(5, 12);
			//let header={"Authorization" : "Bearer " + this.$store.state.token};
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			axios.get('api/Productos/ListarLocalidad/'+me.$store.state.usuario.localidad,configuracion).then(function(response){
			me.articulos=response.data;
			me.modelCarga = false;
			}).catch(function(error){
				alert("Producto no encontrado");
				me.modelCarga = false;
			});
		},
		initialize2(){
			let me=this;
			this.modelCarga = true;
			this.familia = "";
			this.Categoria = "";
			let barcodepreview = Date.now();
			let idsa = barcodepreview.toString();
			this.codigoProducto = idsa.substring(5, 12);
			this.barcode = idsa.substring(5, 12);
			this.idMovimiento = idsa.substring(5, 12);
			//let header={"Authorization" : "Bearer " + this.$store.state.token};
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			axios.get('api/Productos/ListarLocalidadTotal/'+me.$store.state.usuario.localidad,configuracion).then(function(response){
			me.articulos=response.data;
			me.modelCarga = false;
			}).catch(function(error){
				alert("Producto no encontrado");
				me.modelCarga = false;
			});
		},
		initializeFinder(){
			let me=this;
			let Objeto = [];
			let part1 = "";
			let myInput = document.getElementById('input');
			myInput = myInput.value;
			if (myInput.includes("/")) {
				let index = myInput.indexOf("/");
				part1 = myInput.substring(0, index);
			} else {
				part1 = myInput;
			}
			let cant = myInput.length;
			if ( cant > 2 ) {
				
				this.timeouts.map( timeout => {
					window.clearTimeout( timeout );
				} );
				this.timeouts.push(
					setTimeout( () => {
						this.loading = true;
						axios.get('api/Productos/ListarLocalidadBusqueda/'+this.localidad+'/'+part1).then( response => {
							Objeto = response.data
								// Cargar Productos 
								me.articulos=response.data;
								me.loading = false;
							} )
							.catch( error => { } );
					}, 500 )
				);
			} else {
				this.timeouts.map( timeout => {
					window.clearTimeout( timeout );
				} );
				this.articulos = [];
			}
			//Antiguo
			
		},
		async GestionarProducto(){
			if(this.LocalidadesSelectas.length == 0){
				this.Mensaje = "Seleccione al menos 1 localidad para continuar";
				this.snackbar = true;
				return;
			}
			for(var i=0;i<this.LocalidadesSelectas.length;i++){
			//	this.stock = this.LocalidadesSelectas.stock;
				this.localidad = this.LocalidadesSelectas[i];
				await	this.guardar();
				if(this.stop == true){
					return;
				}
			}
			//this.close();
			this.dialog = false;
			this.initializeFinder()
			this.selectProductos(this.localidad);
		},
		SelectCat(){
			let me=this;
			me.modelCarga = true;
			let barcodepreview = Date.now();
			let idsa = barcodepreview.toString();
			this.codigoProducto = idsa.substring(5, 12);
			this.barcode = idsa.substring(5, 12);
			//let header={"Authorization" : "Bearer " + this.$store.state.token};
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			axios.get('api/Productos/ListarLocalidadCategoria/'+me.$store.state.usuario.localidad+'/'+me.Categoria,configuracion).then(function(response){
			me.articulos=response.data;
			me.modelCarga = false;
			}).catch(function(error){
				alert("Producto no encontrado");
			});
		},
		SelectFams(){
			let me=this;
			me.modelCarga = true;
			let barcodepreview = Date.now();
			let idsa = barcodepreview.toString();
			this.codigoProducto = idsa.substring(5, 12);
			this.barcode = idsa.substring(5, 12);
			//let header={"Authorization" : "Bearer " + this.$store.state.token};
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			axios.get('api/Productos/ListarLocalidadFamilia/'+me.$store.state.usuario.localidad+'/'+me.familia,configuracion).then(function(response){
			me.articulos=response.data;
			me.modelCarga = false;
			}).catch(function(error){
				alert("Producto no encontrado");
			});
		},
		async EliminarAgencia (item) {
			
			if(confirm("Esta seguro que quiere eliminar la caracteristica: "+item.nombre+':'+item.valor)){
				var self = this;
				let header={"Authorization" : "Bearer "};
				let configuracion= {headers : header};
				await axios.delete('api/FichaProductos/'+item.nombre,{
					'Nombre':item.nombre,
				},configuracion).then(function (response) {
					self.Mensaje = "Caracteristica Eliminada correctamente";
					self.snackbar = true;
					self.SelectFichaProducto(self.codigoProducto);
				}).catch(function(error){
					self.Mensaje = "Lo sentimos, error eliminando Caracteristica. "+error;
					self.snackbar = true;}); 
			}
			
		},
		CargarCaracteristicas(){
			let car = '';
			for(var i=0;i<this.Caracteristicas.length;i++){
				car +=  `
				•`+this.Caracteristicas[i].nombre+': '+this.Caracteristicas[i].valor;
			}
			this.SubFamilia += car;
			this.guardar();
		},
		ElapsedTime(){
			let typingTimer= 0;                //timer identifier
			let doneTypingInterval = 1000;  //time in ms (5 seconds)
			let myInput = document.getElementById('input');

			//on keyup, start the countdown
				clearTimeout(typingTimer);
				if (myInput.value) {
					typingTimer = setTimeout(doneTyping, doneTypingInterval);
				}

			//user is "finished typing," do something
			function doneTyping () {
				alert("Buscando"+myInput.value);
				}
			
			
		},
		SelectDOC(){
			let me=this;
			me.modelCarga = true;
			let barcodepreview = Date.now();
			let idsa = barcodepreview.toString();
			this.codigoProducto = idsa.substring(5, 12);
			this.barcode = idsa.substring(5, 12);
			//let header={"Authorization" : "Bearer " + this.$store.state.token};
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			axios.get('api/Productos/ListarLocalidadDoc/'+me.$store.state.usuario.localidad+'/'+me.activo,configuracion).then(function(response){
			me.articulos=response.data;
			me.modelCarga = false;
			}).catch(function(error){
				alert("Producto no encontrado");
			});
		},
		close () {
			this.dialog = false;
			this.modelCarga =false;
			this.loading = false;
			this.limpiar();
			},
		limpiar(){
			this.precioVenta= 0;
			this.precioCompra=0;
			this.search = this.descripcion;
			this.descripcion="";
			this.barcode="";
			this.proveedor = "Mi Tienda";
			this.SoN = 'S';
			this.familia = "Mi Producto",
			this.maximo=5;
			this.minimo=1;
			this.comision = 5;
			this.categoria = 13;
			this.subFamilia="";
			this.codigoProducto=0;
			this.descripcion="";
			this.editedIndex=-1;
			this.sku = '';
			this.Color = "",
			this.Talla = "",
			this.maxDescuento = 0;
			let barcodepreview = Date.now();
			let idsa = barcodepreview.toString();
			this.codigoProducto = idsa.substring(5, 12);
			this.barcode = idsa.substring(5, 12);
		},
		buscarCodigo(){
				let me=this;
					me.errorArticulo=null;
					if (this.codigo != "")
					{
						let header={"Authorization" : "Bearer "};
						let configuracion= {headers : header};
						axios.get('api/ProductosWebs/BuscarCodigoVentaLocalidad/'+this.codigo+'/'+this.localidadorigen,configuracion)
						.then(function(response){
							me.agregarDetalle(response.data);
						}).catch(function(error){
							alert("No existe el artículo");
						});
					}
					this.codigo = "";
		},
		onChangeFileUpload2(event){
		for(var i=0;i<event.target.files.length;i++){
			this.selectedFile = event.target.files[i];
			const storageRef=firebase.storage().ref(`${this.selectedFile.name}`);
			const task = storageRef.put(this.selectedFile);
			task.on('state_changed',snapshot=>{
				let percentage = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
				this.uploadValue1 = percentage;
			}, error=>{console.log(error.message)},
			()=>{this.uploadValue1=100;
				task.snapshot.ref.getDownloadURL().then((url)=>{
				this.localizacion =url;
				this.items.push({src: url});
				});
			}
			);
			}
		
		},
		eliminarProductoserial(id){
			this.modelCarga = true;
			var me = this;
			if(confirm("Seguro que quiere eliminar el Serial ?")){
				me.seriales = me.seriales.filter(e => e.id != id)
				let header={"Authorization" : "Bearer " + this.$store.state.token};
				let configuracion= {headers : header};
				axios.post('api/CodigosSeriales/Delete',{
					'Id': id,
						
					},configuracion).then(function (response) {
						me.snackbar = true;
						me.Mensaje = 'Serial Eliminado Con éxito.';  
						me.modelCarga = false;
						
						
					})
					.catch(function (error) {
						me.snackbar = true;
						me.Mensaje = 'Error eliminando el serial.' +error;    
						me.modelCarga = false;
						})
			}


		},
		buscarProducto(){
			let me=this;
			if (this.localidadorigen != "")
			{
				let header={"Authorization" : "Bearer "};
				let configuracion= {headers : header};
				axios.get('api/ProductosWebs/BuscarCodigoVentaLocalidad/'+this.codigo.value+'/'+this.localidadorigen,configuracion)
				.then(function(response){
					me.agregarDetalle(response.data);
					me.modelCarga = false;
				}).catch(function(error){
					alert("No existe el artículo");
				});
			} else
			{
				this.Mensaje ="Debe seleccionar una localidad de origen";
				this.snackbar = true;
			}
		},
		buscarCodigoBarras(){
			let me=this;
			if (this.codigo != "")
			{
				let header={"Authorization" : "Bearer "};
				let configuracion= {headers : header};
				axios.get('api/ProductosWebs/BuscarCodigoBarrasLocalidad/'+this.codigo+'/'+this.localidadorigen,configuracion)
				.then(function(response){
					me.agregarDetalle(response.data);
				}).catch(function(error){
					me.Mensaje ="Articulo no encontrado, por favor verifique su código de barras";
					me.snackbar = true;
				});
			}
		},
		selectLocalidad(){
					let me=this;
					var localidadesArray=[];
					let header={"Authorization" : "Bearer "};
					let configuracion= {headers : header};
					axios.get('api/Localidades/SelectLocalidades',configuracion).then(function(response){
						localidadesArray=response.data;
						localidadesArray.map(function(x){
							me.localidades.push({text: x.nombre,value:x.nombre});
						});
					})
		},
		AprobarProducto(item){
		let header={"Authorization" : "Bearer " + this.$store.state.token};
		let configuracion= {headers : header};
			let me=this;
			axios.put('api/SalidaProductos/Aprobar',{
				'IdSalida':item.idsalida,
				'Descripcion':item.descripcion,
				'Codigo': item.codigo,
				'Localidad': item.localidad,
				'Costo': item.costo,
				'Cajero': item.cajero,
				'Cantidad': item.cantidad,
				'Estado': 0,
				'Tipo': item.tipo,
			},configuracion).then(function(response){
				me.snackbar = true;
				me.Mensaje = 'Registro Aprobado correctamente';
				me.Aprobaciones();                     
			}).catch(function(error){
				console.log(error);
				me.snackbar = true;
				me.Mensaje = 'Error al aprobar, reporte a su proveedor el problema';   
			});
		},
		DenegarProducto(item){
		
		let header={"Authorization" : "Bearer " + this.$store.state.token};
		let configuracion= {headers : header};
			let me=this;
			axios.put('api/SalidaProductos/Aprobar',{
				'IdSalida':item.idsalida,
				'Estado': -1,
			},configuracion).then(function(response){
				me.snackbar = true;
				me.Mensaje = 'Registro Denegado correctamente';
				me.Aprobaciones();                     
			}).catch(function(error){
				console.log(error);
				me.snackbar = true;
				me.Mensaje = 'Error al aprobar, reporte a su proveedor el problema';   
			});
		},
		agregarSerial(){
		this.modelCarga = true;
				this.seriales.push(
				{
				id: Date.now(),
				codigoSerial:this.serial,
				estado:"En Stock",
				codigoproducto: this.codigoProducto,
				descricion: this.nombre,
				}
				);
			var me = this;
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
				axios.post('api/CodigosSeriales/Crear',{
					'Estado': "En Stock",
					'Localidad': this.$store.state.usuario.localidad,
					'Codigoproducto': me.codigoProducto,
					'CodigoSerial': me.serial,
					'Usuario':this.$store.state.usuario.nombre,
					'Descricion': me.nombre,
					'Vencimiento': me.start,
					'Proveedor':me.nombreproveedor,
						
					},configuracion).then(function (response) {
						me.snackbar = true;
						me.Mensaje = 'Serial registrado de forma exitosa';  
						me.serial = '';
						me.modelCarga = false
					})
					.catch(function (error) {
						me.snackbar = true;
						me.Mensaje = 'El Codigo serial ya fue registrado.'+error;  
						me.modelcompra = false;
					})
		},
		eliminarProducto(id){
			//console.log(id)
			this.detalles = this.detalles.filter(e => e.id != id)
			//console.log(this.cards)
		},
		editarProductoSuma(index){
			this.cantidades = this.detalles[index].cantidad
			this.indexProducto = index 
			this.detalles[this.indexProducto].cantidad = this.cantidades + 1
		// this.sumarCantidadProducto();
		},
		editarProductoResta(index){
			if(this.detalles[index].cantidad != 1){
				this.cantidades = this.detalles[index].cantidad
				this.indexProducto = index 
				this.detalles[this.indexProducto].cantidad = this.cantidades - 1
			} 
			
		// this.sumarCantidadProducto();
		},
		AñadirCodigo(item){
			this.modelCarga = true;
			this.modelSeriales = true;
			this.codigoProducto = item.codigoProducto;
			this.nombre = item.descripcion;
			let me=this;
					let header={"Authorization" : "Bearer " };
					let configuracion= {headers : header};
					var productosArray=[];
					axios.get('api/CodigosSeriales/ObtenerSerial/'+me.$store.state.usuario.localidad+'/'+me.codigoProducto,configuracion).then(function(response){
						me.seriales=response.data;
						me.modelCarga = false;
					}).catch(function(error){
						me.modelCarga = false;
						me.Mensaje = error;
						me.snackbar = true;
						console.log(error);
					});

		},
		agregarDetalle(data = []){
					this.errorArticulo=null;
					if (data['codigoProducto'] != '' && data['codigoProducto'] != undefined)
					{
						this.codigo = data['codigoProducto'];
					}
					if (this.encuentra(data['codigoProducto'])){
						this.Mensaje = "El producto ya ha sido agregado.";
						this.snackbar = true;
					}
					else
					{
					if (this.codigo != '')
					{
						this.detalles.push(
							{
							id: this.detalles.length +2,
							idarticulo:data['codigoProducto'],
							descripcion:data['descripcion'],
							cantidad:data['cantidadInvActual'],
							precio:data['precioVenta'],
							gravado:data['exe_Grad'],
							localidad:data['sitio'],
							cantidadIngreso: 0,
							cantidadDestino: 0,
							}
							);
							
						this.codigo = '';
						this.modelCarga = false;
						this.$nextTick(() => {
								this.$refs.inputCantidadIngreso.focus();
							});
						if(this.localidaddestino != ''){
							this.BuscarStockDestino(data['codigoProducto'])
						}
					}
					else{
						alert("Debe digitar un código de producto o servicio");
					}
					}
		},
		async BuscarStockDestino(codigo){
			let me=this;
			let stock = 0;
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			await axios.get('api/ProductosWebs/ConsultaStock/'+codigo+'/'+this.localidaddestino,configuracion).then(function(response){
				stock=response.data;
				me.AsignarInventario(codigo,stock.stock)
			}).catch(function(error){
				//alert("Producto no encontrado en localidad "+me.localidaddestino + error);
				console.log(error);
			});
		},
		AsignarInventario(id,stock){
			for(var i=0;i<this.detalles.length;i++){
				if (this.detalles[i].idarticulo==id){
					this.detalles[i].cantidadDestino = stock;
				}
			}
			return 
		},
		encuentra(id){
					var sw=0;
					for(var i=0;i<this.detalles.length;i++){
						if (this.detalles[i].idarticulo==id){
							sw=1;
						}
					}
					return sw;
		},
		abrirCabys(){
		var url = 'https://app.powerbi.com/view?r=eyJrIjoiZDdmNTBlYTktYmE0Ni00NGM3LWJhNzYtMGZhNjBlNTQyYTdmIiwidCI6IjYxOGQwYTQ1LTI1YTYtNDYxOC05ZjgwLThmNzBhNDM1ZWU1MiJ9';
		var win = window.open(url, '_blank');
		win.focus();
		},
		
		generarInventario(){
			if (this.DatoMovimiento == ''){
					this.Mensaje = 'Seleccione el tipo de movimiento';
					this.snackbar = true;
					return
			
				}  {
				this.loading = true;
				var momentoActual = new Date() 
				var y = momentoActual.getFullYear();
				var m = momentoActual.getMonth() + 1;
				var d = momentoActual.getDate();
				var hora = momentoActual.getHours() 
				var minuto = momentoActual.getMinutes() 
				var segundo = momentoActual.getSeconds() 
				this.Fecha = m + "/" + d + "/" + y+"  "+hora+":"+minuto+":"+segundo;
				var me = this;
				let header={"Authorization" : "Bearer " + this.$store.state.token};
				let configuracion= {headers : header};
				axios.post('api/ProductosWebs/MovimientoInventario',{
						'Usuario': this.$store.state.usuario.nombre,
						'localidad': this.$store.state.usuario.localidad,
						'detalles': me.detalles,
						'detalle': me.detalle,
						'Aprobado': me.switch1,
						'TipoIngreso': me.DatoMovimiento,
						'NoFactura': me.idMovimiento,
						
					},configuracion).then(function (response) {
						me.Mensaje = "Ingreso de inventario realizado de forma exitosa";
						me.snackbar = true; 
						me.loading = false;
						me.finishOrder();
						me.detalles = [];
						me.modelCarga = false;  
						let barcodepreview = Date.now();
						let idsa = barcodepreview.toString();
						me.idMovimiento = idsa.substring(5, 12);
					})
					.catch(function (error) {
						alert(`No se puedo abrir el comprobante del movimiento, habilite permisos para ventanas emergentes. Proceso completado con éxito.`+error);  
					})
					} return;
		},
		finishOrder(){
		var divContents = document.getElementById("bill").innerHTML;
		var a = window.open('', '', 'height=500, width=500');
		a.document.write('<html>');
		a.document.write(divContents);
		a.document.write('</body></html>');
		a.print();
		},
		async generarTrazlado(){
			if (this.localidaddestino == ''){
					this.Mensaje = 'Seleccione la localidad Destino';
					this.snackbar = true;
					return
			
				}  {
			this.modelCarga = true;
			var me = this;
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			await  axios.post('api/ProductosWebs/TrazladoMercaderiaSalida',{
						'Usuario': this.$store.state.usuario.nombre,
						'localidad': this.localidadorigen,
						'detalles': me.detalles,
						'detalle': me.detalle,
						'Aprobado': me.switch1,
						'TipoIngreso': me.localidaddestino,
						'NoFactura': me.idMovimiento,
						
				},configuracion).then(function (response) {
					me.generarTrazladoIngreso();
					
					})
					.catch(function (error) {
						me.modelCarga = false;
						alert(`Lo sentimos ha ocurrido un error haciendo la salida de: `+me.localidadorigen+` o las ventas emergentes estan bloqueadas, por favor verifique el movimiento o notifique a su equipo de soporte 4070-1889`+error);  
				})
				} 
		},
		listarLocalidad(){
			let me =this;
			let logo = null;
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
				axios.get('api/Localidades/ListarFiltrada/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
					//console.log(response);
				me.localidadencabezado=response.data;
				me.Logo3 = me.localidadencabezado[0].imagen;
				
				
				}).catch(function(error){
					console.log(error);
				});
		},
		async generarTrazladoIngreso(){
			this.modelCarga = true;
			var me = this;
			var momentoActual = new Date() 
			var y = momentoActual.getFullYear();
			var m = momentoActual.getMonth() + 1;
			var d = momentoActual.getDate();
			var hora = momentoActual.getHours() 
			var minuto = momentoActual.getMinutes() 
			var segundo = momentoActual.getSeconds() 
			this.Fecha = m + "/" + d + "/" + y+"  "+hora+":"+minuto+":"+segundo;
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			await axios.post('api/ProductosWebs/TrazladoMercaderiaIngreso',{
						'Usuario': this.$store.state.usuario.nombre,
						'localidad': this.localidaddestino,
						'detalles': me.detalles,
						'detalle': me.detalle,
						'Aprobado': me.switch1,
						'TipoIngreso': me.localidadorigen,
						'NoFactura': me.idMovimiento,
						
				},configuracion).then(function (response) {
					me.snackbar = true;
					me.Mensaje = "Traslado de "+me.localidadorigen +" hacia "+me.localidaddestino+" realizado de forma exitosa";
					me.modeltrazlado = false;
					me.modelCarga = false;
					})
					.catch(function (error) {
						me.modelCarga = false;
						alert(`Lo sentimos ha ocurrido un error trazladando hacia `+me.localidaddestino+` , notifique a su equipo de soporte 4070-1889`+error);  
				})   
			me.PrintDetails2();
			me.detalles = [];
		},
    	IngresarProveedor(){
			if (this.nombreproveedor == ''){
						alert(`Seleccione el nombre del proveedor`); 
				
					}  {
			this.loading = true;
			var me = this;
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			axios.post('api/proveedores/Crear',{
					'Usuario': this.$store.state.usuario.nombre,
					'Localidad': this.$store.state.usuario.localidad,
					'Proveedor': me.nombreproveedor,
					'Cedula': me.cedulaproveedor.replace(/\s+/g, ''),
					'Correo': me.correoproveedor,
					'Telefono': me.telefonoproveedor,
					'TipoCedula': me.tpcedulaproveedor,
					'Dirreccion': me.direccionproveedor
					
			},configuracion).then(function (response) {
					alert(`Proveedor agregado con éxito`);  
				me.loading = false;
				me.modelproveedor = false;
				me.selectProveedores();
				me.nombreproveedor = '',
				me.cedulaproveedor = '',
				me.telefonoproveedor = '',
				me.direccionproveedor = '',
				me.correoproveedor = ''
				})
				.catch(function (error) {
					alert(`Lo sentimos ha ocurrido un error, notifique a su equipo de soporte 4070-1889`);  
			})
			} return;
    	},
		Aprobaciones(){
			this.modelAprobaciones = true;
			let me=this;
			let header={"Authorization" : "Bearer " };
			let configuracion= {headers : header};
			var familiasArray=[];
			axios.get('api/SalidaProductos/Listar/'+me.$store.state.usuario.localidad+'/1',configuracion).then(function(response){
				familiasArray=response.data;
				me.listaAprobaciones = familiasArray;
			}).catch(function(error){
				console.log(error);
			});
		},
		aprobarProductos() {
			let me = this;
			this.modelCarga = true;
			let header = {"Authorization": "Bearer "}; // Asegúrate de que el token de autenticación esté correctamente incluido aquí
			let configuracion = {headers: header};

			axios.put('api/SalidaProducto/AprobarMultiple', me.listaAprobaciones, configuracion)
				.then(function(response) {
					me.Mensaje = "Aprobación correcta";
					me.snackbar = true;
					me.listaAprobaciones = [];
					me.modelCarga = false;
					console.log("Respuesta del servidor:", response.data);
					// Aquí puedes manejar la respuesta. Por ejemplo, actualizar la UI para reflejar que los productos han sido aprobados.
				})
				.catch(function(error) {
					me.Mensaje = "Problema aprobando : "+error;
					me.snackbar = true;
					me.modelCarga = false;
					console.log("Error al aprobar productos:", error);
				});
		},

		EliminarFamilia(){
			if (this.familia == ''){
					alert(`Digite el nombre de la familia para guardarla`); 
				
				}  {
			this.loading = true;
			var me = this;
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			axios.delete('api/Familias/DeleteFamilias/'+this.familia+'/'+this.$store.state.usuario.localidad,{
				},configuracion).then(function (response) {
					alert(`Familia eliminada con éxito `+response);  
					me.loading = false;
					me.select();
					me.familia = '';
					})
					.catch(function (error) {
						alert(`La familia seleccionada no se puede eliminar, esta viene por defecto, lo sentimos.. :`+error);  
				})
				} return;
		},
		IngresarFamilia(){
			if (this.familia == ''){
					alert(`Digite el nombre de la familia para guardarla`); 
				
				}  {
			this.loading = true;
			var me = this;
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			axios.post('api/Familias/Crear',{
						'Nombre': this.familia,
						'Localidad': this.$store.state.usuario.localidad,
						
				},configuracion).then(function (response) {
						alert(`Familia agregada con éxito`);  
					me.loading = false;
					me.select();
					})
					.catch(function (error) {
						alert(`La familia ya existe, no es necesario agregarla o intente con un nombre distinto`);  
				})
				} return;
		},
		EsGravado(){
		if(this.categoria == 0){
			this.SoN = 'N'
			return;
		}
		this.SoN = 'S'
		},
		select(){
			let me=this;
			this.familias = [];
			let header={"Authorization" : "Bearer " };
			let configuracion= {headers : header};
			var familiasArray=[];
			axios.get('api/Familias/Select/'+me.$store.state.usuario.localidad,configuracion).then(function(response){
				familiasArray=response.data;
				familiasArray.map(function(x){
					me.familias.push(x.nombre);
				});
			}).catch(function(error){
				console.log(error);
			});
		},
      	GestionProveedores(){
			this.modelproveedor = true;
			let me=this;
			let header={"Authorization" : "Bearer " };
			let configuracion= {headers : header};
			var familiasArray=[];
			axios.get('api/Proveedores/Listar/'+me.$store.state.usuario.localidad,configuracion).then(function(response){
				me.gestionProveedor=response.data;
				me.Mensaje = "Provedores listados";
				me.snackbar = true;
				
			}).catch(function(error){
				console.log(error);
			});
		},
      	selectProveedores(){
			let me=this;
			let header={"Authorization" : "Bearer " };
			let configuracion= {headers : header};
			var proveedoresArray=[];
			axios.get('api/proveedores/Select/'+me.$store.state.usuario.localidad,configuracion).then(function(response){
				proveedoresArray=response.data;
				proveedoresArray.map(function(x){
					me.Proveedores.push(x.proveedor);
				});
			}).catch(function(error){
				console.log(error);
			});
        },
		selectProductos(localidad){
			let me=this;
			let header={"Authorization" : "Bearer " };
			let configuracion= {headers : header};
			var productosArray=[];
			axios.get('api/Productos/SelectProductos2/'+localidad,configuracion).then(function(response){
				productosArray=response.data;
				productosArray.map(function(x){
					me.productos.push({text:x.descripcion, value:x.codigoProducto});
				});
			}).catch(function(error){
				console.log(error);
			});
		},
		async Actualizar(item){
			this.loading = true;
			let me = this;
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			 await axios.put('api/Productos/Actualizar',{
				'codigoProducto':item.codigoProducto,
				'descripcion':item.descripcion,
				'barcode':item.barcode,
				'IdProductKey': item.idProductKey,
				'precioVenta':item.precioVenta,
				'precioDetalle':item.precioDetalle,
				'precioMall':item.PrecioMall,
				'precioMayor':item.precioMayor,
				'precioCentral':item.precioCentral,
				'precioCompra': item.precioCompra,
				'compuesto': item.compuesto,
				'Categoria':item.categoria, //Define si es un servicio o un Producto
				'Origen':item.origen, // Este campo es la hora inicial del servicio
				'InnerPack': item.innerPack, // Este campo es la Hora final del servicio
				'CantHoras': item.cantHoras, // Este campo define la cantidad de horas disponibles del servicio
				'comision': item.comision,
				'maxDescuento': item.maxDescuento,
				'proveedor': item.proveedor,
				'localizacion': item.localizacion,
				'Cocina':item.cocina,
				'minimo':item.minimo,
				'maximo':item.maximo,
				'EstadoInventario': item.estadoInventario,
				'valorImpuesto':item.valorImpuesto,
				'Exe_Grad':item.exe_Grad,
				'tipoProducto': item.tipoProducto,
				'SubFamilia': item.subFamilia,
				'Localidad' : item.localidad,
				'CantPersonas': item.cantPersonas,
				'CodigoRaiz': item.codigoRaiz,
				'MaxDescuento':item.maxDescuento,
				'SincServer1':item.sincServer1, // Mostrar Item en la web
				'Color':item.color,
				'CodigoCaja':item.codigoCaja,
				'Medidas':item.medidas,
				'PKG':0,
				'TipoAlimento':item.impespecial, // porcentaje de impuesto especial
			},configuracion).then(function(response){
				me.Mensaje = "Producto actualizado con éxito en "+me.localidad;
				me.snackbar = true;      
				me.dialog = false;       
				me.loading = false; 
				//me.initializeFinder();
			}).catch(function(error){
				alert("Error actualizando, código interno no encontradoen "+me.localidad + error)
			});
		},
      	async guardar() {
			if(this.LocalidadesSelectas.length === 0){
				this.Mensaje = "Seleccione al menos 1 localidad para continuar";
				this.snackbar = true;
				return;
			}
            if(this.Categoria == 'PRODUCTO'){ 
				if(this.validar()){
					return;
				}	
            }  else
              if(this.Categoria == 'SERVICIO' )
                if (this.validarServicio()){
					return;
			} 
            if(this.categoria == 0){
              this.SoN = 'N';
            }
			if(this.Ivi == true){
				this.Ivi = 1;
			}else
			{
				this.Ivi = 0;
			}
			const acabadosselectos = this.acabadosSeleccionados.join(', ');
			const variantes = this.variaciones.join(', ');
			this.modelCarga = true;
            let header={"Authorization" : "Bearer "};
                let configuracion= {headers : header};
                if (this.editedIndex > -1) {
                    //Código para editar
                    let me=this;
                    await axios.post('api/Productos/ActualizarProductoMultiple',{
                        'codigoProducto':me.codigoProducto,
                        'descripcion':me.descripcion,
                        'barcode':me.barcode,
                        'IdProductKey': me.idProductKey,
                        'precioVenta':me.precioVenta,
                        'precioDetalle':me.precioDetalle,
                        'precioMall':me.PrecioMall,
                        'precioMayor':me.precioMayor,
                        'precioCentral':me.precioCentral,
                        'precioCompra': me.precioCompra,
                        'compuesto': me.compuesto,
                        'Categoria':me.Categoria, //Define si es un servicio o un Producto
                        'Origen':me.hora, // Este campo es la hora inicial del servicio
                        'InnerPack': me.cat1, // Este campo es la Hora final del servicio
                        'CantHoras': me.hora4, // Este campo define la cantidad de horas disponibles del servicio
                        'comision': me.comision,
                        'maxDescuento': me.maxDescuento,
                        'proveedor': me.proveedor,
                        'localizacion': me.localizacion,
						'Cocina':me.cocina,
                        'minimo':me.minimo,
                        'maximo':me.maximo,
                        'EstadoInventario': me.Ivi,  // 
        				'Localidad': '',  // se usan las localidades del arreglo esta no se necesita
                        'valorImpuesto':me.categoria,
                        'Exe_Grad':me.SoN,
                        'tipoProducto': me.familia,
                        'SubFamilia': me.SubFamilia,
                        'CantPersonas': me.CantPersonas,
                        'CodigoRaiz': me.cabys,
						'MaxDescuento':me.maxDescuento,
						'SincServer1':me.activo, // Mostrar Item en la web
						'Color':me.Color,
						'CodigoCaja':me.sku,
						'Medidas':variantes,
						'PKG':me.nicotina,
						'Consignacion':acabadosselectos,
						'Dimensiones':me.MaxFactura,
						'Actividad':me.ubicacion,
						'Localidades':me.LocalidadesSelectas,
						'Usuario':me.$store.state.usuario.nombre,
                    },configuracion).then(function(response){
						const { actualizadas, noEncontradas } = response.data;
        
							// Construir mensajes basados en las respuestas
							let mensajeActualizadas = actualizadas.length > 0 ? "Localidades actualizadas: " + actualizadas.join(", ") : "No se actualizó ninguna localidad.";
							let mensajeNoEncontradas = noEncontradas.length > 0 ? "Localidades no encontradas: " + noEncontradas.join(", ") : "";
							me.familia = null;
							// Mostrar mensajes en el snackbar o similar
							alert(mensajeActualizadas + " " + mensajeNoEncontradas);
							me.modelCarga = false;   
							me.dialog = false;         
                    }).catch(function(error){
                        if (error.response && error.response.data) {
							// Mostrar mensaje de error detallado
							me.modelCarga = false; 
							alert("Error: " + error.response.data.Error + " Detalles: " + error.response.data.Detalle);
						} else {
							me.modelCarga = false; 
							// Error no relacionado con la respuesta del backend
							alert("Error de comunicación con el servidor.");
						}
                    });
                } else {

            let header={"Authorization" : "Bearer "};
            let configuracion= {headers : header};
                //Código para guardar
                let me=this;
                await axios.post('api/Productos/CrearMultiple',{
                    'codigoProducto':me.codigoProducto,
                    'descripcion':me.descripcion,
                    'barcode':me.barcode,
                    'precioVenta':me.precioVenta,
                    'precioDetalle':me.precioDetalle,
                    'precioMall':me.PrecioMall,
                    'precioMayor':me.precioMayor,
                    'precioCentral':me.precioCentral,
                    'precioCompra': me.precioCompra,
                    'compuesto': me.compuesto,
                    'comision': me.comision,
                    'maxDescuento': me.maxDescuento,
                    'proveedor': me.proveedor,
                    'localizacion': me.localizacion,
                    'minimo':me.minimo,
                    'maximo':me.maximo,
                    'EstadoInventario': me.Ivi,
                    'Categoria':me.Categoria, //Define si es un servicio o un Producto
                    'Origen':me.hora, // Este campo es la hora inicial del servicio
                    'InnerPack': me.cat1, // Este campo es la Hora final del servicio
                    'CantHoras': me.hora4, // Este campo define la cantidad de horas disponibles del servicio
                    'valorImpuesto':me.categoria,
                    'tipoProducto': me.familia,
                    'SubFamilia' :me.SubFamilia,
                    'Exe_Grad':me.SoN,
                    'Localidad' : '',
                    'CantPersonas': me.CantPersonas, // Este campo guarda la cantidad de personas soportadas por un servicio
                    'CodigoRaiz': me.cabys, // Guarda el Codigo CABYS
					'SincServer1':me.activo,
					'Color':me.Color,
					'CodigoCaja':me.sku,
					'Medidas':variantes,
					'PKG':me.nicotina,
					'Consignacion':acabadosselectos,
					'Dimensiones':me.MaxFactura,
					'Actividad':me.ubicacion,
					'Localidades':me.LocalidadesSelectas,
					'Usuario':me.$store.state.usuario.nombre,
                },configuracion).then(function(response){
					const { creadas, errores } = response.data;
					
					let mensajeCreadas = creadas.length > 0 ? "Localidades creadas: " + creadas.join(", ") : "No se creó el producto en ninguna localidad.";
					let mensajeErrores = errores.length > 0 ? "Errores: " + errores.join(", ") : "";

					// Mostrar mensajes en el snackbar o similar
					alert(mensajeCreadas + "\n" + mensajeErrores);
					
					me.modelCarga = false;
					me.dialog = false;
					me.familia = null;
                }).catch(error => {
					me.modelCarga = false;
					// Si el error es de validación o específico del servidor
					if (error.response) {
						if (error.response.status === 400) {
							// Puedes personalizar más dependiendo del cuerpo del error
							alert("Error: " + error.response.data);
						} else if (error.response.status === 404) {
							alert("No se encontraron recursos.");
						} else {
							alert("Error en el servidor.");
						}
					} else {
						// Error de red o desconocido
						alert("Error de conexión o error desconocido.");
					}
                });
              }
		},
		editItem (item) {
			this.codigoProducto = item.codigoProducto
			this.descripcion = item.descripcion
			this.localizacion = item.localizacion
			this.categoria = item.valorImpuesto
			this.Cat = item.categoria
			this.Categoria = item.categoria
			this.idProductKey = item.idProductKey
			this.SubFamilia = item.subFamilia
			this.barcode = item.barcode
			this.precioCompra = item.precioCompra
			this.comision = item.comision
			this.precioVenta = item.precioVenta
			this.hora = item.origen 
			this.cat1 = item.innerPack
			this.cabys = item.codigoRaiz
			this.hora4 = item.cantHoras
			this.familia = item.tipoProducto
			this.cocina = item.cocina
			this.proveedor = item.proveedor
			this.maximo = item.maximo
			this.Ivi = item.estadoInventario
			this.minimo = item.minimo
			this.maxDescuento = item.maxDescuento
			this.precioMall = item.precioMall
			this.precioCentral = item.precioCentral
			this.activo = item.sincServer1
			this.compuesto = item.compuesto
			this.precioDetalle = item.precioDetalle
			this.precioMayor = item.precioMayor
			this.editedIndex=1;
			this.CantPersonas = item.cantPersonas
			this.Talla = item.medidas
			this.Color = item.color
			this.sku = item.codigoCaja
			this.variaciones = item.medidas.split(', ')
			this.acabadosSeleccionados = item.consignacion.split(', ')
			this.impespecial = item.tipoAlimento
			this.dialog = true
		},
		OpenFicha(item){
			this.codigoProducto = item.codigoProducto
			this.descripcion = item.descripcion
			this.categoria = item.valorImpuesto
			this.Cat = item.categoria
			this.Categoria = item.categoria
			this.idProductKey = item.idProductKey
			this.SubFamilia = item.subFamilia
			this.barcode = item.barcode
			this.precioCompra = item.precioCompra
			this.comision = item.comision
			this.precioVenta = item.precioVenta
			this.hora = item.origen 
			this.cat1 = item.innerPack
			this.cabys = item.codigoRaiz
			this.hora4 = item.cantHoras
			this.familia = item.tipoProducto
			this.cocina = item.cocina
			this.proveedor = item.proveedor
			this.maximo = item.maximo
			this.Ivi = item.estadoInventario
			this.minimo = item.minimo
			this.maxDescuento = item.maxDescuento
			this.localizacion = item.localizacion
			this.precioMall = item.precioMall
			this.precioCentral = item.precioCentral
			this.activo = item.sincServer1
			this.compuesto = item.compuesto
			this.precioDetalle = item.precioDetalle
			this.precioMayor = item.precioMayor
			this.editedIndex=1;
			this.CantPersonas = item.cantPersonas
			this.Talla = item.medidas
			this.Color = item.color
			this.sku = item.codigoCaja
			this.modelFicha = true
			this.SelectFichaProducto(item.codigoProducto)
		},
		async SelectFichaProducto(item){
			let me=this;
			this.Agencias = [];
			var Data=[];
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			await axios.get('api/FichaProductos/'+this.$store.state.usuario.localidad+'/'+item,configuracion).then(function(response){
				Data=response.data;
				me.Caracteristicas = Data;
			})
		}, 
		async AgregarFicha () {
			if(this.valor == "" || this.Caracteristica == ''){
				this.Mensaje = "Debe Digitar el nombre del valor y caracteristica para continuar";
				this.snackbar = true;
				return;
			}
			var self = this;
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			await axios.post('api/FichaProductos',{
				'Usuario': this.$store.state.usuario.nombre,
				'Nombre':this.Caracteristica,
				'Valor':this.valor, 
				'Localidad':this.$store.state.usuario.localidad,
				'CodigoProducto':this.codigoProducto
			},configuracion).then(function (response) {
				self.Mensaje = "Caracteristica agregada correctamente";
				self.snackbar = true;
				self.SelectFichaProducto(self.codigoProducto);
			}).catch(function(error){
				self.Mensaje = "Lo sentimos, error Error agregando la Caracteristica. "+error;
				self.snackbar = true;}); 
		},
		validar(){
			this.valida=0;
			this.validaMensaje=[];

			if (this.descripcion.length<3 || this.descripcion.length>120){
				this.validaMensaje.push("El nombre debe tener más de 3 caracteres y menos de 120 caracteres.");
			}
			if (!this.familia){
				this.validaMensaje.push("Seleccione una familia.");
			}
			if (this.cabys.length != 13){
				this.validaMensaje.push("Verifique que el código cabys contega 13 digitos");
			}
			
			if (!this.proveedor){
				this.validaMensaje.push("Seleccione un proveedor");
			}
			if (!this.maximo){
				this.validaMensaje.push("Seleccione un Máximo");
			}
			if (!this.minimo){
				this.validaMensaje.push("Seleccione un Mínimo");
			}
			if (!this.proveedor){
				this.validaMensaje.push("Seleccione un proveedor");
			}
			if (!this.codigoProducto){
				this.validaMensaje.push("Seleccione un código de Producto");
			}
			if (!this.barcode){
				this.validaMensaje.push("Seleccione un código de barras");
			}
			if (this.validaMensaje.length){
				this.valida=1;
			}
			return this.valida;
		},
		validarServicio(){
			this.valida=0;
			this.validaMensaje=[];

			if (this.descripcion.length<3 || this.descripcion.length>50){
				this.validaMensaje.push("El nombre debe tener más de 3 caracteres y menos de 50 caracteres.");
			}
			if (!this.hora){
				this.validaMensaje.push("Seleccione una hora de inicio");
			}
			if (!this.hora2){
				this.validaMensaje.push("Seleccione una Hora Final");
			}
			if (!this.hora4){
				this.validaMensaje.push("Seleccione la cantidad de Horas");
			}
			if (this.validaMensaje.length){
				this.valida=1;
			}
			return this.valida;
		},
		EsProducto(){
			this.esProducto = true;
			this.Categoria = "PRODUCTO";
			if(this.editedIndex === -1){
				let barcodepreview = Date.now();
				let idsa = barcodepreview.toString();
				this.codigoProducto = idsa.substring(0, 8);
				this.barcode = idsa.substring(0, 8);
			}
		},
		EsServicio(){
			this.esProducto = false;
			this.Categoria = "SERVICIO";
			if(this.editedIndex === -1){
				let barcodepreview = Date.now();
				let idsa = barcodepreview.toString();
				this.codigoProducto = idsa.substring(0, 8);
				this.barcode = idsa.substring(0, 8);
			}
		},
		EsInsumo(){
			this.esProducto = true;
			this.Categoria = "INSUMO";
			if(this.editedIndex === -1){
				let barcodepreview = Date.now();
				let idsa = barcodepreview.toString();
				this.codigoProducto = idsa.substring(0, 8);
				this.barcode = idsa.substring(0, 8);
			}
			
		},
      	deleteProveedor (item) {
			var me = this;
			if(confirm("Seguro que quiere eliminar el proveedor "+item.proveedor+"?")){
				let header={"Authorization" : "Bearer " + this.$store.state.token};
				this.loading = true; 
				let configuracion= {headers : header};
				axios.post('api/Proveedores/DeleteProveedores',{
					'IdProveedorKey': item.idProveedorKey,
						
					},configuracion).then(function (response) {
						me.snackbar = true;
						me.Mensaje = 'Proveedor Eliminado Con éxito.';  
						me.GestionProveedores();
						me.selectProveedores();
						me.loading = false;
						
					})
					.catch(function (error) {
						me.snackbar = true;
						me.loading = false;
						me.Mensaje = 'Error eliminando el proveedor.';    
						})
			}
      	},
		DeleteProducto (item) {
			var me = this;
			if(confirm("Seguro que quiere eliminar el Producto "+item.descripcion+"?")){
				this.loading = true;
				let header={"Authorization" : "Bearer " + this.$store.state.token};
				let configuracion= {headers : header};
				axios.post('api/Productos/Delete',{
					'IdProductKey': item.idProductKey,
						
				},configuracion).then(function (response) {
					
					me.initialize();
					me.snackbar = true;
					me.Mensaje = 'Producto Eliminado Con éxito.';  
					me.loading = false;
					})
					.catch(function (error) {
						me.snackbar = true;
						me.loading = false;
						me.Mensaje = 'Error eliminando el Producto.';    
					})
			}
      	},
		close () {
			this.dialog = false
			this.limpiar()
			setTimeout(() => {
			this.editedItem = Object.assign({}, this.defaultItem)
			this.editedIndex = -1
			}, 300)
		},
    },
  }
</script>

<style scoped>
	::v-deep .v-data-table tbody tr:nth-child(odd) {
	background-color: #f5f5f5; /*color de fondo para filas impares*/
	}

	::v-deep .v-data-table tbody tr:nth-child(even) {
	background-color: #ffffff; /*color de fondo para filas pares*/
	}
	::v-deep .my-custom-class tbody tr:nth-child(odd) {
	background-color: #f5f5f5; /*color de fondo para filas impares*/
	}

	::v-deep .my-custom-class tbody tr:nth-child(even) {
	background-color: #ffffff; /*color de fondo para filas pares*/
	}
</style>