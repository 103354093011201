
<template>
    <v-container style="background-color: #23262d;" fluid>
        <template>
            <v-row class="mx-1">
                <v-card :style="{ height: 'auto' }" class="mx-auto justify-center align-center" width="270" flat v-for="(item , index) in usuarios" :key="index">
					<v-toolbar dark color="#23262d"> 
							<div >Orden {{item.noFactura}} </div> <v-spacer />
								
							<v-chip  color="#2797ce" >{{orderTimes[item.noFactura]}}</v-chip>
						</v-toolbar>
							<v-row class="mb-2 mx-2">
								<v-chip class="mt-2 white--text" :color="getColor(item.mesa)" style="font-size:20px">  {{item.mesa}}</v-chip>
							</v-row>

							<p class="mx-1" style="font-size:12px; color: secondary;"> 
								Fecha {{item.fecha}} <br>
								<b>Cliente:{{item.cliente}} </b> <br>
								Vendedor {{item.vendedor}}  <br>
								Detalle {{item.detallle}}  <br>
								
							</p>
						<v-simple-table dense>
							<template v-slot:default>
							<thead>
								<tr>
								<th class="text-left">
									Item
								</th>
								</tr>
							</thead>
							<tbody>
								<tr
								v-for="x in item.ordenes"
								:key="x.name"
								>
								
								<td @click="updateIvaById(x.idRegistro)" :class="{'tachado': tachados.includes(x.idRegistro)}">
									{{x.descripcion}} <b>x{{x.cantidad}}</b> <br>
									{{x.detalle}}
								</td>
								</tr>
							</tbody>
							</template>
						</v-simple-table>
                        <v-card-text>
							<v-btn
								color="success"
								block
								large
								:loading="loading"
								@click="updateIvaForMesa2(item.mesa)"
							>
								Despachar Todo
							</v-btn>
							
                        </v-card-text>
				</v-card>
            </v-row>
        </template>
		 <confirmation-dialog
			v-if="showConfirmation"
			:mensaje="'¿Estás seguro de que quieres buscar la mesa para la orden número ' + orderNumberConfirm + '?'"
			@confirm="confirmarBusqueda"
			@cancel="cancelarBusqueda"
		></confirmation-dialog>
    </v-container>
	
	
</template>
<script>
import ConfirmationDialog from './ConfirmationDialog.vue';

import axios from 'axios'
  export default {
	components: {
		ConfirmationDialog,
	},
    data: () => ({
		MetodosEntrega: ["Envio Propio","Retira en sucursal","Correos de CR","Otro"],  
		Localidades:[],
		Estados:[{text:"En Central",value:"0"},{text:"Esperando Sucursal",value:"1"},
		{text:"En Sucursal",value:"2"},{text:"Enviado",value:"3"},{text:"Entregado",value:"4"}
		,{text:"Cancelado",value:"5"},{text:"No se pudo entregar",value:"6"}],
        usuarios:[],    
		Mensajeros :[],
		itemTemporal:[],
		intervalID: null, // Almacena el ID del intervalo aquí
		showConfirmation: false,
      	orderNumberConfirm: null,
		multiplo:10,
        editedIndex: -1,
		timeUpdateInterval:null,
		tachados: [],
		MensajeroDialog:false,
		estado: 0,
		keySequence: '', // Aquí almacenaremos las teclas presionadas.
		orderTimes: {},
		loading : true,
		prevUsersLength: 0, // Añadir esta propiedad para almacenar la longitud anterior de 'usuarios'
      	sound: new Audio(require('@../../../assets/pageAssets/inbox.mp3'))
																	 // Ruta relativa al archivo de sonido
    }),
	mounted() {
		
		document.addEventListener('keyup', this.handleKeyUp);
		 // Establece un intervalo para actualizar el tiempo transcurrido cada segundo
    	this.timeUpdateInterval = setInterval(this.updateOrderTimes, 1000);
		this.enterFullscreen();
		// Obtener el elemento <v-col> por su ID
		var colElement = document.getElementById('columnadetalles');

		// Obtener la altura de la pantalla
		var screenHeight = window.innerHeight;

		// Establecer la altura del elemento al valor de la altura de la pantalla
		colElement.style.height = screenHeight + 'px';
	},
	beforeDestroy() {
		document.removeEventListener('keyup', this.handleKeyUp);
		clearInterval(this.timeUpdateInterval); // Limpia el intervalo que ya tienes
		clearInterval(this.intervalID); // Limpia el nuevo intervalo para evitar que se ejecute después de cambiar el componente
	},
	beforeRouteLeave(to, from, next) {
		clearInterval(this.intervalID);
		// Limpia otros recursos aquí
		next();
	},

    created () {
      	this.listar();
	  
    },

    methods: {
		listar() {
			let me = this;
			let header = {};
			let configuracion = { headers: header };

			consultarAPI();

			// Configura el intervalo para llamar al método cada 40 segundos.
			// Asegúrate de almacenar la referencia a este intervalo en una propiedad del componente
			this.intervalID = setInterval(consultarAPI, 40000);

			function consultarAPI() {
				axios.get('api/OrdenExpress/ListarCocina/' + me.$store.state.usuario.localidad, configuracion)
				.then(function(response) {
					const oldLength = me.usuarios.length; // Guardar la longitud anterior
					me.usuarios = response.data;
					me.loading = false;
					me.updateOrderTimes(); // <-- Actualiza el tiempo transcurrido inmediatamente después de cargar las órdenes.

					// Comprueba si la longitud de 'usuarios' ha aumentado
					if (me.usuarios.length > oldLength) {
					me.sound.play(); // Reproduce el sonido si la longitud ha aumentado
					}
				})
				.catch(function(error) {
					console.log(error);
					me.loading = false;
				});

			}
		},
		calculateTimeElapsed(Hora) {
			var now = new Date();
			var hor1 = now.getHours();
			var min1 = now.getMinutes();
			var sec1 = now.getSeconds();

			var hor2 = parseInt(Hora.substring(0, 2));
			var min2 = parseInt(Hora.substring(3, 5));

			var diffInSec = (hor1 * 3600 + min1 * 60 + sec1) - (hor2 * 3600 + min2 * 60);

			// Si la diferencia es negativa, sumamos 24 horas (en segundos) para ajustar el cálculo
			if (diffInSec < 0) diffInSec += 24 * 3600;

			var hours = Math.floor(diffInSec / 3600);
			diffInSec %= 3600;
			var minutes = Math.floor(diffInSec / 60);
			var seconds = diffInSec % 60;

			return hours + "h: " + minutes + "m: " + seconds + "s";
		},
		buscarMesa(orderNumber) {
			const orden = this.usuarios.find(u => u.noFactura == orderNumber);
			if (orden) {
				this.updateIvaForMesa2(orden.mesa);
			} else {
				console.log('Mesa no encontrada para la factura: ' + orderNumber);
			}
		},
		updateIvaForMesa2(mesa) {
			this.loading = true;
			let me = this;
			let localidad = this.$store.state.usuario.localidad;
			const requestData = { Mesa: mesa, Localidad: localidad };
			
			axios.post('/api/OrdenExpress/UpdateIvaForMesa', requestData)
				.then(response => {
					console.log(response.data);  // Aquí puedes manejar una respuesta exitosa.
					// Encuentra el índice del elemento con la 'mesa' dada
					const index = me.usuarios.findIndex(u => u.mesa === mesa);
					me.loading = false;
					// Si se encontró el índice, elimina el elemento del arreglo
					if (index !== -1) {
						me.usuarios.splice(index, 1);
					}
				})
				.catch(error => {
					console.error("Error updating iva:", error);
				});
		},
		
		SelccionaMensajero(item,estado){
			this.MensajeroDialog = true;
			this.estado = estado;
			this.itemTemporal = item;
		},
		updateOrdenExpress(data) {
            axios.put('/api/OrdenExpress/ActualizarOrden', data)
                .then(response => {
                    console.log('Actualizado exitosamente');
                })
                .catch(error => {
                    console.error('Hubo un error al actualizar:', error);
                });
        },
		handleKeyUp(event) {
			// Si el usuario presiona un número (teclado normal o numpad).
			if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105)) {
				let key = (event.keyCode >= 96 && event.keyCode <= 105) ? String.fromCharCode(event.keyCode - 48) : event.key;
				this.keySequence += key;
			}
			// Si el usuario presiona Enter.
			else if (event.keyCode == 13) {
				let orderNumber = parseInt(this.keySequence.trim());
				if (!isNaN(orderNumber)) {
					this.buscarMesa(orderNumber);
				}
				this.keySequence = ''; // Limpiar la secuencia de teclas.
			}
			// Si se presiona una tecla que no es número ni Enter.
			else {
				this.keySequence = '';
			}
		},
		confirmarBusqueda() {
			this.buscarMesa(this.orderNumberConfirm);
			this.showConfirmation = false;
		},
		cancelarBusqueda() {
			this.showConfirmation = false;
		},

		SaltosLinea(item){
			let srt = item.split(":");
			return srt;
		},
		getColor (estado) {
			if (estado == '0') return '#23262d'
			else 
			if (estado == '1') return 'error'
			else 
			if (estado == '2') return 'orange'
			else 
			if (estado == '3') return 'green'
			else 
			if (estado == '4') return 'indigo'
			
			else  return 'red'
		},
		updateOrderTimes() {
			this.orderTimes = {};  // Reiniciar el objeto orderTimes (si lo prefieres)
			for (let item of this.usuarios) {
				this.orderTimes[item.noFactura] = this.calculateTimeElapsed(item.hora);
			}
		},
		enterFullscreen() {
			const docEl = document.documentElement;
			if (docEl.requestFullscreen) {
			docEl.requestFullscreen().catch((error) => {
				console.log('Error al intentar ingresar al modo de pantalla completa:', error);
			});
			} else if (docEl.mozRequestFullScreen) { // Firefox
			docEl.mozRequestFullScreen().catch((error) => {
				console.log('Error al intentar ingresar al modo de pantalla completa:', error);
			});
			} else if (docEl.webkitRequestFullscreen) { // Chrome, Safari y Opera
			docEl.webkitRequestFullscreen().catch((error) => {
				console.log('Error al intentar ingresar al modo de pantalla completa:', error);
			});
			} else if (docEl.msRequestFullscreen) { // Internet Explorer/Edge
			docEl.msRequestFullscreen().catch((error) => {
				console.log('Error al intentar ingresar al modo de pantalla completa:', error);
			});
			}
		},

		transformDetalle(detalle) {
			return detalle.replace('•', '<br>•');
		},
		updateIvaById(idRegistro) {
			const requestData = { IdRegistro: idRegistro };
			this.loading = true;
			axios.post('/api/OrdenExpress/UpdateIvaById', requestData)
				.then(response => {
					console.log(response.data);
					
					// Agregar idRegistro al array tachados
					this.tachados.push(idRegistro);
					this.loading = false;
					const index = this.usuarios.findIndex(u => u.idRegistro === idRegistro);
					if (index !== -1) {
						this.usuarios.splice(index, 1);
					}
				})
				.catch(error => {
					console.error("Error updating iva by id:", error);
				});
		},

	
    
	cargarColor(){
		for(var i = 0; i < this.localidadesColor.length; ++i)
		{
			if(this.localidadesColor[i].value == this.localidad.value){
					this.color = this.localidadesColor[i].color;
					break;
			}
		}
		},
	},
  }
</script>
<style>
.tachado {
    text-decoration: line-through;
}
</style>