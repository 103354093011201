<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="usuarios"
            :search="search"
            sort-by="noFactura"
            sort-desc="noFactura"
			:loading="loading"
            class="elevation-1 pa-1"

            >
            <template class="primary" v-slot:item.actionUtilidad="{ item }"> 
				{{ (item.totalLinea - (item.precioCompra * item.cantidad)) -item.descuentos}}
			</template>
            <template v-slot:top>
                <v-row class="pa-2">
                    <v-col cols="12" lg="3" md="6" sm="8" xs="12">
                        <v-card-actions class="rounded-xl light-blue lighten-5 mx-5">
                             
                                <vue-excel-xlsx
                                    :data="usuarios"
                                    :columns="columns"
                                    :filename="'Reporte Ventas Producto Detalladas en:  '+$store.state.usuario.localidad +' Desde: '+start+' hasta '+end"
                                    :sheetname="'Ventas X Producto'"
                                    >
                                    <v-btn fab="" color="success">  <v-icon large="">mdi-file-excel </v-icon> </v-btn>
                                </vue-excel-xlsx> 
                            <v-card-title>Productos Detallados</v-card-title>
                        </v-card-actions>
                    </v-col>
                    <v-col cols="12" lg="4" md="6" sm="4" xs="12">    
                        <v-text-field v-model="search"  append-icon="mdi-magnify"
                         label="Buscar" single-line hide-details
                        />
						<v-select @change="listarFechas()" v-model="localidad" :items="Localidades" label="Localidad" />
                    </v-col>
                    <v-col cols="12" lg="2" md="6" sm="6" xs="6">
                        <v-text-field  v-model="start" type="date" label="Fecha inicial"></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="2" md="5" sm="5" xs="5">
                        <v-text-field  v-model="end"  type="date" label="Fecha final"></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="1" md="1" sm="1" xs="1">
                        <v-btn :loading="loading" color="info" @click="listarFechas()" fab > <v-icon fab> mdi-cloud-search </v-icon> </v-btn>
                    </v-col>
                </v-row>
               
            </template>

            <template v-slot:no-data>
                <v-btn color="primary" @click="initialize">Reiniciar</v-btn>
            </template>
        </v-data-table>
        <template class="pa-3">
            <v-snackbar
                    v-model="snackbar" top vertical="" :timeout="5000"
                    >
                    {{ Mensaje }}
                    <v-btn
                    color="info"
                    text
                    @click="snackbar = false"
                    >
                    Cerrar
                    </v-btn>
            </v-snackbar>
            
        </template>
    </div>
</template>

<script scoped>
import axios from 'axios'
import Chart from 'chart.js'
import jsPDF from 'jspdf'
import VueExcelXlsx from "vue-excel-xlsx";
import Vue from "vue";
import html2canvas from 'html2canvas';
import QrcodeVue from 'qrcode.vue'

Vue.use(VueExcelXlsx);
  export default {
    data: () => ({
    mesesValores: null,
    nombreMeses: [],
    totalMeses: [],
    value: 'https://www.noah.cr',
    size: 100,
    dialog: false,
    familia: '',
	loading:true,
	localidad: '',
	Localidades: [],
    search: '',
    ClienteSeleccionado : '',
    today: new Date().toISOString().substr(0, 10),
    focus: new Date().toISOString().substr(0, 10),
    headers: [
    { text: 'Codigo', value: 'codigoProducto' },
    { text: 'Nombre', value: 'descripcion' },
    { text: 'Cantidad', value: 'cantidad' },
    { text: 'Precio', value: 'precioUnitario' },
    { text: 'Descuentos', value: 'montoDescuento' },
    { text: 'Documento', value: 'tipoDocumento', sortable: false  },
    { text: 'No Fact', value: 'noFacturaReal' },
    { text: 'Hora', value: 'hora' },
	{ text: 'Fecha', value: 'fecha' },
	{ text: 'Vendedor', value: 'vendedor' },
	{ text: 'Cliente', value: 'nombreReceptor' },
	{ text: 'Cajero', value: 'cajero' },
    ],
    columns : [
            { label: 'Codigo', field: 'codigoProducto' },
            { label: 'Nombre', field: 'descripcion' },
            { label: 'Cantidad', field: 'cantidad' },
            { label: 'Precio', field: 'precioUnitario' },
            { label: 'Vendedor', field: 'vendedor' },
            { label: 'Precio', field: 'precioUnitario' },
            { label: 'NoFactura', field: 'noFacturaReal' },
            { label: 'Clave', field: 'claveComprobante' },
			{ label: 'Fecha', field: 'fecha' },
			{ label: 'Hora', field: 'hora' },
			{ label: 'Descuento', field: 'montoDescuento' },
			{ label: 'ValorImpuesto', field: 'valorImpuesto' },
			{ label: 'Detalle', field: 'detalle' },
			{ label: 'Cabys', field: 'codigoHacienda' },
			{ label: 'Cliente', field: 'nombreReceptor' },
			{ label: 'Cedula', field: 'CedulaReceptor' },
			{ label: 'Correo', field: 'correoReceptor' },
			{ label: 'Tipo Doc', field: 'tipoCedulaReceptor' },
			{ label: 'Telefono', field: 'telefonoReceptor' },
			{ label: 'Cajero', field: 'cajero' },
        ],
    cabeceraDetalles: [
    { text: 'Código', value: 'codigoProducto', sortable: false  },
    { text: 'Descripción', value: 'descripcion', sortable: false  },
    { text: 'Precio', value: 'actionprecio', sortable: false  },
    { text: 'Cantidad a debitar', value: 'actioncantidad', sortable: false  },
    ],
    usuarios: [],
    tipoDocumentos: [
        'FISICA',
        'JURIDICA',
        'EXTRANJERO',
    ],
    familias: [],
    Top10: [],
    DescTop: [],
    infoTop: [],
    Proveedores: [],
    proveedor: '',
    Mensaje: 'Seleccione un rango de fechas',
    snackbar: true,
    }),
    components: {
      QrcodeVue,
    },

    computed: {
    formTitle () {
            return this.editedIndex === -1 ? 'Nuevo usuario' : 'Actualizar usuario'
    },
	calcularUtilidad:function(){
        var resultado=0.0;
          for(var i=0;i<this.usuarios.length;i++){
			  resultado += this.usuarios[i].totalLinea - (this.usuarios[i].precioCompra * this.usuarios[i].cantidad) - this.usuarios[i].descuentos
          }
        
        return resultado.toFixed(2);
      },
	calcularTotal:function(){
        var resultado=0.0;
          for(var i=0;i<this.usuarios.length;i++){
			  resultado += this.usuarios[i].totalLinea - this.usuarios[i].descuentos
          }
        
        return resultado.toFixed(2);
      },
    },
    

    watch: {
      dialog (val) {
        val || this.close()
      },
      
    },


    methods: {
        select(){
			let me=this;
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			axios.get('api/RepVentasVistas/ListarDatosFactura2/'+this.today+'/'+this.today+'/'+this.localidad,configuracion).then(function(response){
				//console.log(response);
				me.usuarios=response.data;
				me.loading = false;
			}).catch(function(error){
				console.log(error);
			});
        },
        selectProveedores(){
			let me=this;
			let header={"Authorization" : "Bearer " };
			let configuracion= {headers : header};
			var proveedoresArray=[];
			axios.get('api/proveedores/Select/'+me.$store.state.usuario.localidad,configuracion).then(function(response){
				proveedoresArray=response.data;
				proveedoresArray.map(function(x){
					me.Proveedores.push(x.proveedor);
				});
			}).catch(function(error){
				console.log(error);
			});
		},
		async listarAsociadas(){
			var items = [];
			let me=this;
			me.Localidades.push(me.$store.state.usuario.localidad);
			this.localidad = this.$store.state.usuario.localidad;
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			await axios.get('api/LocalidadesAsociadas/'+this.$store.state.usuario.nombre,configuracion).then(function(response){
				items=response.data;
				items.map(function(x){
					me.Localidades.push(x.localdiadAsociada);
				});
			}).catch(function(error){
				alert("Producto no encontrado");
				me.modelCarga=false;
			});
		},
        FiltrarCliente2(){
            let amp = "";
            this.Datos = this.usuarios;
            this.usuarios = this.usuarios.filter(e => e.nombreReceptor == this.ClienteSeleccionado);
            this.snackbar = true;
            this.Mensaje = 'Filtro de vendedor seleccionado sobre rango de fecha listado'
        },
        FiltrarMoneda(){
            let amp = "";
            this.Datos = this.usuarios;
            amp = this.Datos.filter(e => e.moneda == this.familia);
            this.usuarios = amp;
            this.snackbar = true;
            this.Mensaje = 'Filtro Medio de Moneda Seleccionado sobre rango de fecha listado'
        },
        loadTop10(){
            let me=this;
            let mesn='';
            me.usuarios.map(function(x,index){
				if(index < 12){
					me.infoTop.push(x.cantidad);
					me.DescTop.push(x.descripcion);
				}
            });
            var ctx = document.getElementById("myChart");
            var myChart = new Chart(ctx, {
                type: 'pie',
                data: {
                    labels: me.DescTop,
                    datasets: [{
                        title: 'TOP 10',
                        data: me.infoTop,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.5)',
                            'rgba(54, 162, 235, 0.5)',
                            'rgba(255, 206, 86, 0.5)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.5)',
                            'rgba(255, 159, 64, 0.2)',
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)'
                        ],
                        borderColor: [
                            'rgba(255,99,132,1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)',
                            'rgba(255,99,132,1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        borderWidth: 1
                    }]
                },
                options: {
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero:true
                            }
                        }]
                    }
                }
            });
        },
        crearPDF(){
           var columns = [
            {title: "Factura", dataKey: "noFacturaReal"},
            {title: "Estado", dataKey: "estado"}, 
            {title: "Fecha", dataKey: "fecha"}, 
            {title: "Excento", dataKey: "excento"},
            {title: "IVA", dataKey: "iva"},
            {title: "Total", dataKey: "ventaTotal"},
            {title: "Cliente", dataKey: "cliente"},
            ];
            var rows = [];

            this.usuarios.map(function(x){
                rows.push({noFacturaReal:x.noFacturaReal,excento:x.exento,estado:x.mensajeRespuesta,fecha:x.fecha,iva:x.impuesto,ventaTotal:x.ventaTotal,cliente:x.nombreReceptor});
            });

            // Only pt supported (not mm or in)
            var doc = new jsPDF('p', 'pt');
            doc.autoTable(columns, rows, {
                margin: {top: 60},
                addPageContent: function(data) {
                    doc.text("Listado de Ventas", 40, 30);
                }
            });
            doc.save('ReporteVentas.pdf');
        },
        crearPDFFactura(){
            var fact = this.noFactura;
             var quotes = document.getElementById('factura');
                html2canvas(quotes).then(function (canvas) {
                    var imgData = canvas.toDataURL('https://i.ibb.co/7rj9fLf/the-one-blanco.jpg');
                    var imgWidth = 210;
                    var pageHeight = 285;
                    var imgHeight = canvas.height * imgWidth / canvas.width;
                    var heightLeft = imgHeight;
                    var doc = new jsPDF();
                    var position = 0;

                    doc.addImage(imgData, 'JPG', 0, position, imgWidth, imgHeight);                    
                    doc.save('ComprobanteVenta'+fact+'.pdf');
                });
        },
        listarFechas(){
            if(this.start == '' || this.end == ''){
                this.snackbar = true;
                this.Mensaje = 'Debe seleccionar una fecha inicial y final'
                return;
           }
            this.loading = true;
            let me=this;
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
            axios.get('api/RepVentasVistas/ListarDatosFactura2/'+this.start+'/'+this.end+'/'+this.localidad,configuracion).then(function(response){
                //console.log(response);
                me.usuarios=response.data;
				me.loading = false;
            }).catch(function(error){
                console.log(error);
                me.Mensaje  = error;
                me.snackbar = true;
				me.loading = false;
            });
        },

      close () {
        this.dialog = false;
        this.limpiar();
          },
    

      activarDesactivarMostrar(accion,item){
                this.adModal=1;
                this.adNombre=item.nombre;
                this.adId=item.idusuario;                
                if (accion==1){
                    this.adAccion=1;
                }
                else if (accion==2){
                    this.adAccion=2;
                }
                else{
                    this.adModal=0;
                }
            },
            activarDesactivarCerrar(){
                this.adModal=0;
            },
      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.desserts[this.editedIndex], this.editedItem)
        } else {
          this.desserts.push(this.editedItem)
        }
        this.close()
      },
    },
	created(){
		this.listarAsociadas();
		this.select();
	}
  }

</script>
My CRUD