<template>
  <v-row class="fill-height">
	<v-snackbar
		color="red accent-2"
		v-model="snackbar" top vertical="" :timeout="5000"
		>
		<v-alert
		prominent
		type="error"
		>
		<v-row align="center">
			<v-col class="grow">
			{{ Mensaje }}
			</v-col>
			<v-col class="shrink">
			<v-btn @click="snackbar = false">Cerrar</v-btn>
			</v-col>
		</v-row>
		</v-alert>
		
    </v-snackbar>
	<!-- Container -->
	
	<!-- FACTURA -->
	<div id="bill" style="display: none;">
		<header>
			<table >
				<thead>
				<tr>
					<th><div id="logo">
							<img :src="Logo3" width="120" height="120">
							<h4> Comprobante de abono </h4>
						</div>
					</th>
					<th>
						<div v-for="det in localidadencabezado" :key="det.nombre" id="datos">
							
								<h2> {{det.nombre}} </h2> <h3> {{det.razonSocial}} </h3>{{det.dirreccion}} Costa Rica<br> 
									{{det.cedulaJuridica}} <br>{{det.telefono}}<br>
								{{det.correo}}
							
						</div>
					</th>
				</tr>
				</thead>
				<tbody>
				<tr>
				</tr>
				</tbody>
			</table>
		</header>
		<div style="margin: 0 auto; text-align: center;">
		<br>
		<label style="font-size: medium; font-weight: bold;">Abono a Crédito</label>
		<br>
		<label style="font-size: medium;">{{$store.state.usuario.localidad}}</label>
		<br>
		<label style="font-size: medium;">Tipo Movimiento: Abono</label>
		<br>
		<label style="font-size: medium;">Usuario: {{$store.state.usuario.nombre}}</label>
		<br><br>
		<v-divider color="black"></v-divider>
		<br>
		</div>
		<div style="margin: 0 auto;">
		<label style="font-size: medium;">Fecha: {{Fecha}}</label><br>
		<label style="font-size: medium;">Hora: {{hora}}</label><br>
		<label style="font-size: medium;">Cliente: {{cliente}}</label>
		<br><br>
		<v-divider color="black"></v-divider>
		<div style="display: grid; grid-template-columns: auto auto auto;">
			<table>
				<tr>
					<th>Fact</th>
					<th>Saldo</th>
					<th>Vendedor</th>
					<th>Detalle</th>
				</tr>
				<tr v-for="item in detalles"
					:key="item">
					<td>{{item.factura}}</td>
					<td>{{item.credito}}</td>
					<td>{{item.cajero}}</td>
					<td>{{item.detalle}}</td>
				</tr>
				</table>
		</div>
		<br>
		<div style="display: grid; grid-template-columns: auto auto auto;">
			<table>
				<tr>
					<th>Total</th>
					<th>Abono</th>
					<th>Saldo</th>
				</tr>
				<tr>
					<td>{{total}}</td>
					<td>{{abono}}</td>
					<td>{{calcularSaldo}}</td>
				</tr>
				</table>
		</div>
		
		<v-divider color="black"></v-divider>
		<br><br>
		<label style="font-size: medium;">Sign:_______________</label> <br>
		<label style="font-size: medium;">Nota: {{Nota}}</label>
		
		<br>
		</div>
	</div>
	<!-- FACTURA -->
	<v-col cols="12" sm="12">
		<v-sheet height="64">
          <v-tabs background-color="white"
                slider-color="primary" 
                height="60"
              
                next-icon="mdi-arrow-right-bold-box-outline"
                prev-icon="mdi-arrow-left-bold-box-outline"
                show-arrows>
            <v-btn :loading="loading" :disabled="HoraHoy < 5 && esVendedor" color="primary" dark @click.stop="dialog = true">
              CREAR RESERVA
            </v-btn>
			<v-btn @click="dialogblock = true" outlined> Block</v-btn>
            <v-btn outlined  @click="setToday">
              VER HOY
            </v-btn> 
			<v-btn outlined :loading="loading" @click="GuardarOtas()">
              Orbe
            </v-btn> 
			<v-btn outlined href="https://reservations.orbebooking.com/Search/Init/9ryUa/en" target="_blank">  <a >Orbe Motor</a></v-btn>
			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn outlined color="grey" v-bind="attrs" v-on="on" class="btnLimpiarGrid mr-4" to="/conteos/Apertura">
					<v-icon > mdi-cash-lock-open </v-icon>
					</v-btn> 
				</template>
				<span>Apertura de Caja</span>
			</v-tooltip>
			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn outlined v-bind="attrs" color="grey" v-on="on" class="btnLimpiarGrid mr-4" to="/conteos/Cierre">
					<v-icon> mdi-cash-lock </v-icon>
					</v-btn> 
				</template>
				<span>Cierre de Caja</span>
			</v-tooltip>
				<v-btn fab text small @click="prev">
				<v-icon small>mdi-chevron-left</v-icon>
				</v-btn>
				<v-btn fab text small @click="next">
				<v-icon small>mdi-chevron-right</v-icon>
				</v-btn>
			<div>
				<v-text-field class="mt-2" dense outlined v-model="focus" label="Fecha:" type="date"> </v-text-field>
			</div>
			 <v-spacer/>
            <v-menu class="mt-2" bottom right>
              <template v-slot:activator="{ on }">
                <v-btn outlined v-on="on">
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Dia</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Semana</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'" >
                  <v-list-item-title>Mes</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = '4day'" >
                  <v-list-item-title>4 dias</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'category'">
                  <v-list-item-title>Habitaciones</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
			<div>
				<v-select class="mt-2" style="width:140px" outlined dense v-if="type == 'category'" v-model="CategoryDay" label="Días" :items="CategoryDays"> </v-select>
				<v-select style="width:140px" outlined dense v-if="type == 'category'" v-model="CategoryDay" label="Servicio" :items="prestadores"> </v-select>
			</div>
          </v-tabs>
      </v-sheet>
	</v-col>
    <v-col cols="12" sm="12">
        <v-calendar
			style="font-size:14px"
			dark
			ref="calendar"
			v-model="focus"
			:events="events"
			:categories="prestadores"
			:event-margin-bottom="4"
			category-show-all
			:category-days="CategoryDay"
			:now="today"
			interval-minutes="30"
			short-intervals=""
			interval-width="60"
			interval-height="15"
			interval-count="33"
			first-interval="2"
			first-time="08:00"
			short-months=""
			:type="type"
			day-format=""
			:event-ripple="false"
			:event-color="getEventColor"
			@contextmenu:time-category="startTime2"
			@mousedown:event="startDrag"
			@mousedown:time-category="startTime"
			@mousemove:time-category="mouseMove"
			@mouseup:time-category="endDrag"
			@mouseleave.native="cancelDrag"
			@click:event="showEvent"
        >
          <template v-slot:event="{ event, timed, eventSummary }">
            <div
              class="v-event-draggable"
              v-html="eventSummary()"
            ></div>
            <div
              v-if="timed"
              class="v-event-drag-bottom"
              @mousedown.stop="extendBottom(event)"
            ></div>
          </template>
        </v-calendar>
		<v-menu
			v-model="selectedOpen"
			:close-on-content-click="false"
			:activator="selectedElement"
			full-width
			offset-x
			>

			<v-card v-if="this.idusuario == 2" color="grey lighten-4" :width="1200" text>
				<v-toolbar :color="selectedEvent.color" dark>
				<v-btn  :disabled="selectedEvent.Estado == 'In House'" class="botonUsuario" @click="deleteEvent(selectedEvent)" icon>
					<v-icon>mdi-delete</v-icon>
				</v-btn>
				<v-toolbar-title > 
					<v-edit-dialog
						:return-value.sync="selectedEvent.name"
						@save="updateCliente()"
						@cancel="cancel"
						@open="open"
						@close="updateCliente()"
						>
							{{selectedEvent.name }}
						<template v-slot:input>
							<v-combobox
							onfocus="this.select();"
							v-model="selectedEvent.name"
							
							label="Titulo de la Reserva"
							counter
							></v-combobox>
						</template>
					</v-edit-dialog>  
				</v-toolbar-title>
				<div class="flex-grow-1"></div>
				</v-toolbar>
			<v-card-text>
			<container fluid>
				<v-row>
				<v-col cols="12" sm="5">
					<form v-if="currentlyEditing !== selectedEvent.id">
					{{ selectedEvent.details }}
					<b style="font-size: 30px"> Estado: {{selectedEvent.Estado}} </b> <br/> 
					<b class="text-h7"> Precio: {{selectedEvent.precio}}</b> •
					<b class="text-h7"> Anticipo Inicial:  {{selectedEvent.pago}}</b><br/>
					<b class="text-h7"> Personas: {{selectedEvent.personas}} </b> <b class="text-h7">• Noches: {{selectedEvent.Capacidad}} </b><br/>
					<b class="text-h7"> Usuario: {{selectedEvent.Usuario}} </b> •
					<b class="text-h7"> Vendedor: {{selectedEvent.vendedor}} </b> •
					<b class="text-h7"> ID o Cédula: {{selectedEvent.cedula}} </b> <br/>
					<b class="text-h7"> Correo: {{selectedEvent.correo}} </b> 
					<b> Código Ota (Guaranty_Code): {{selectedEvent.CodigoOta}} </b>•
					<b> Fecha Registro: {{selectedEvent.Fecha}} </b><br/>
					<b> Fecha y Hora Ingreso: {{selectedEvent.start}} o {{selectedEvent.start2}} </b><br/>
					<b> Fecha Salida: {{selectedEvent.end}} o {{selectedEvent.end2}} </b><br/>
					<b> Agencia : {{selectedEvent.agencia}} </b>•
					<b> Tour Operador : {{selectedEvent.TourOperador}} </b> •
					<div>  <v-edit-dialog
						:return-value.sync="selectedEvent.Cliente"
						@save="updateCliente()"
						@close="updateCliente()"
						>
						Cliente: {{selectedEvent.Cliente }}
						<template v-slot:input>
							<v-text-field
							onfocus="this.select();"
							v-model="selectedEvent.Cliente"
							label="Cliente"
							counter
							></v-text-field>
						</template>
						</v-edit-dialog> 
					</div>
					<div> Habitación: {{selectedEvent.category}} </div>
					<div> <v-edit-dialog
						:return-value.sync="selectedEvent.detalle"
						@save="updateCliente()"
						@close="updateCliente()"
						>
						Comentario: 	{{selectedEvent.detalle }}
						<template v-slot:input>
							<v-text-field
							onfocus="this.select();"
							v-model="selectedEvent.detalle"
							label="Comentario"
							counter
							></v-text-field>
						</template>
						</v-edit-dialog> 
					</div>
					<v-edit-dialog
						:return-value.sync="WhatsAppMensaje"
						large
						persistent
						@save="EnviarWhatsApp()"
						@cancel="cancel"
						@open="open"
						@close="close"
						>
						<template v-slot:input>
							<div class="mt-4 text-h6">
							<v-icon>mdi-whatsapp</v-icon> de confirmación
							</div>
							<v-text-field
							v-model="WhatsAppMensaje"
							label="Edit"
							single-line
							counter
							autofocus
							></v-text-field>
						</template>
					</v-edit-dialog>
					<v-edit-dialog
						:return-value.sync="selectedEvent.Telefono"
						>
						<div class="primary--text "> Teléfono: {{selectedEvent.Telefono}} </div>
						<template v-slot:input>
							<v-text-field
							onfocus="this.select();"
							v-model="selectedEvent.Telefono"
							label="Telefono para enviar WhatsApp"
							counter
							></v-text-field>
						</template>
					</v-edit-dialog> 
					</form>
					<form v-else>
					<v-row>
						<div style="display:none">
						<v-text-field clearable v-model="idreserva" type="text" hidden  label=""></v-text-field>
						<v-text-field clearable v-model="name" type="text" hidden label=""></v-text-field>
						</div>
						<v-col cols="12" sm="4" v-if="!esVendedor">
						<v-text-field clearable  v-model="start" :rules="fechaInicialR" type="date" label="Fecha inicial"></v-text-field>
						</v-col>
						<v-col cols="12" sm="4">
							<v-text-field clearable v-model="end" type="date" :rules="fechaFinalR" label="Fecha final"></v-text-field>
						</v-col>
						<v-col cols="12" sm="4" v-if="!esVendedor">
						<v-text-field clearable  v-model="clienteNombre"  label="Cliente"></v-text-field>
						</v-col>
						<div style="display:none">
						<v-text-field clearable v-model="color" hidden type="color" label=""></v-text-field>
						</div>
						<v-col cols="12" sm="4" v-if="!esVendedor">
						<v-select  id="areascomunes" :rules="[v => !!v || 'El área es requerida']" v-model="barbero" :items="prestadores" label="Habitación"></v-select>
						</v-col>
						<v-col cols="12" sm="4" v-if="!esVendedor">
						<v-text-field v-model="filial" :rules="[v => !!v || 'El usuario es requerido']"  label="Usuario"></v-text-field>
						</v-col>
						<v-col cols="12" sm="4">
						<v-text-field v-if="!esVendedor" clearable v-model="details" type="text" label="Comentario"></v-text-field>
						</v-col>
						<v-col cols="12" sm="4" v-if="!esVendedor">
							<v-menu v-if="combinado == false"
							ref="menu"
							v-model="menu2"
							:close-on-content-click="false"
							:nudge-right="40"
							:return-value.sync="time"
							transition="scale-transition"
							offset-y
							max-width="290px"
							min-width="290px"
							>
							<template v-slot:activator="{ on }">
								<v-text-field
								v-model="hora"
								:rules="horaInicialR"
								label="Hora Inicial:"
								
								v-on="on"
								></v-text-field>
							</template>
							<v-time-picker full-width @click:minute="$refs.menu.save(time)" v-if="menu2" v-model="hora" color="green lighten-1" :max="hora2" :allowed-minutes="(m) => m % 15 == 0" header-color="primary"></v-time-picker>
							</v-menu>
							
						</v-col>
						<v-col cols="12" sm="4" v-if="!esVendedor">  
							<v-menu v-if="combinado == false"
							ref="menu"
							v-model="menu3"
							:close-on-content-click="false"
							:nudge-right="40"
							:return-value.sync="time"
							transition="scale-transition"
							offset-y
							max-width="290px"
							min-width="290px"
							>
							<template v-slot:activator="{ on }">
								<v-text-field
								v-model="hora2"
								:rules="horaFinalR"
								label="Hora Final:"
								readonly
								v-on="on"
								></v-text-field>
							</template>
							<v-time-picker full-width @click:minute="$refs.menu.save(time)" v-if="menu3" v-model="hora2" color="green lighten-1" :min="hora" :allowed-minutes="(m) => m % 15 == 0" header-color="primary"></v-time-picker>
							</v-menu>
							<v-card-actions>
								<v-select :items="Vendedores" v-model="vendedor" type="text" label="Vendedor"></v-select> 
								<v-icon @click="EliminarVendedor()" v-if="vendedor != ''" color="red">mdi-minus  </v-icon>
								<v-edit-dialog
										:return-value.sync="TourOperador"
										@save="AgregarVendedor()"
										@cancel="cancel"
										@open="open"
										@close="close"
										>
										<v-icon color="green">mdi-plus  </v-icon> 
									<template v-slot:input>
										<v-text-field
										onfocus="this.select();"
										v-model="vendedor"
										label="Digite el vendedor"
										hint="Precione enter para guardar"
										counter
										></v-text-field>
									</template>
								</v-edit-dialog> 
							</v-card-actions>
						</v-col>
						<v-col cols="12" sm="4">
							<v-card-actions>
								<v-select v-model="agencia" :items="Agencias" type="text" label="Agencia"></v-select>
								<v-icon @click="EliminarAgencia()" v-if="agencia != ''" color="red">mdi-minus  </v-icon>
								
								<v-edit-dialog
									:return-value.sync="agencia"
									@save="AgregarAgencia()"
									@cancel="cancel"
									@open="open"
									@close="close"
									>
									<v-icon color="green">mdi-plus  </v-icon> 
								<template v-slot:input>
									<v-text-field
									onfocus="this.select();"
									v-model="agencia"
									label="Digite el nombre de la Agencia"
									counter
									></v-text-field>
								</template>
							</v-edit-dialog> 
								
							</v-card-actions>
							<v-card-actions>
								<v-select  v-model="TourOperador" :items="TourOperadores" type="text" label="Tour Operador"></v-select> 
								<v-icon @click="EliminarTour()" v-if="TourOperador != ''" color="red">mdi-minus  </v-icon>
								<v-edit-dialog
										:return-value.sync="TourOperador"
										@save="AgregarTour()"
										@cancel="cancel"
										@open="open"
										@close="close"
										>
										<v-icon color="green">mdi-plus  </v-icon> 
									<template v-slot:input>
										<v-text-field
										onfocus="this.select();"
										v-model="TourOperador"
										label="Digite el nombre del Tour Operador y precione Enter para guardar"
										counter
										></v-text-field>
									</template>
								</v-edit-dialog> 
							</v-card-actions>
						</v-col>
					</v-row>
				</form>
				</v-col>
				<v-col cols="12" sm="7">
				<div>
					<v-alert v-if="Cxc.length > 0"
						dense
						border="left"
						type="error"
						>
						Cuentas  <strong>pendientes</strong> Total: <strong> {{caclcularCXC}} </strong>
						</v-alert>
						<v-simple-table dense>
							<template v-slot:default>
							<thead>
								<tr>
									<th class="text-left">Check</th>
									<th class="text-left">Servicio</th>
									<th class="text-left">Usuario</th>
									<th class="text-left">Pendiente</th>
									<th class="text-left">Fecha</th>
									<th class="text-left">Hora</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="item in Cxc" :key="item">
									<td> <v-checkbox @change="calcularTotalCheck()" v-model="item.check" dense></v-checkbox></td>
									<td > {{item.detalle}} </td>
									<td> {{item.cajero}}  </td>
									<td> {{item.credito}} </td>
									<td> {{item.fecha}} </td>
									<td > {{item.hora}} </td>
								</tr>
							</tbody>
							</template>
						</v-simple-table>
						<v-btn small outlined @click="MarcarCxc()"> Marcar Todo</v-btn>
						<v-btn small outlined @click="PrintDetails"> Ficha Ingreso</v-btn>
						<v-btn small outlined @click="ReservarTour(selectedEvent)"> Reservar Tour \ SPA \..</v-btn>
						<v-btn small outlined class="white--text mx-2" color="primary" to="/facturar/0/0"> Nuevo Cargo</v-btn>
						
						<v-edit-dialog
							large
							persistent
							@save="CuentaMaestra()"
							@cancel="cancel"
							@open="open"
							@close="close"
							>
							<div><v-btn :disabled="calcularSaldo == 0" small outlined class="white--text mt-2" color="primary"> Tranferir Saldo</v-btn></div>
							<template v-slot:input>
								<div class="mt-4 text-h6">
								Transferir Saldo a Cliente
								</div>
								<v-select :disabled="calcularSaldo == 0" dense :hint="'Id de Servicio:' +IdMultiple" v-model="IdMultiple" :items="inhouses" ></v-select>
							</template>
						</v-edit-dialog>
					</div>
						<v-divider/>
					<v-row class="mt-3">
						<v-text-field readonly v-model="calcularSaldo" filled dense  label="Saldo" style="width:15%"/> 
						<v-text-field filled onfocus="this.select();"  @change="calcularPendiente()" v-model="abono" dense label="Abono" style="width:25%;"/>
						<v-select v-model="tipoPago" :items="TipoPagos" filled dense  label="Tipo Pago" style="width:25%"/> 
					</v-row>
					<v-row class="rounded-sm mx-1 accent">
						<h4 class="pa-2" style="color:#FFF">Pendiente Seleccionado: {{total}} </h4>
							<v-spacer/>
						<v-btn large min-width="10%" text dark style="border-left: 1px solid rgb(224, 224, 224);" @click="finishOrder()"> <v-icon >mdi-printer </v-icon> </v-btn>
						<v-btn min-width="20%" large :loading="loading"  color="#2797ce" dark @click="PagoMultiple()" > <v-icon> mdi-printer-pos</v-icon>Abonar </v-btn>
							
					</v-row>
					<div v-if="Resta.length > 0" >
						<v-alert
						dense
						border="left"
						type="warning"
						>
						Pendientes  <strong>Restaurante</strong> de esta  <strong> reserva</strong>
						</v-alert>
						<v-simple-table dense>
							<template v-slot:default>
							<thead>
								<tr>
									<th class="text-left">Servicio</th>
									<th class="text-left">Usuario</th>
									<th class="text-left">Pendiente</th>
									<th class="text-left">Fecha</th>
									<th class="text-left">Hora</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="item in Resta" :key="item">
									<td > {{item.detalle}} </td>
									<td> {{item.cajero}}  </td>
									<td> {{item.credito}} </td>
									<td> {{item.fecha}} </td>
									<td > {{item.hora}} </td>
								</tr>
							</tbody>
							</template>
						</v-simple-table>
					</div>
				</v-col>
				<v-col cols="12" sm="12">
				<h2> Servicios Cargados a {{selectedEvent.category}} </h2> Total: {{calcularTotal2}} / Código Habitación {{selectedEvent.IdMultiple}} / Total a facturar {{calcularTotalFacturar}}
					<v-simple-table>
						<template v-slot:default>
						<thead>
							<tr>
								<th class="text-left">Check</th>
								<th class="text-left">Facturación</th>
								<th class="text-left">Nombre</th>
								<th class="text-left">Habitación</th>
								<th class="text-left">Fecha</th>
								<th class="text-left">Precio Total</th>
								<th class="text-left">Personas</th>
								<th class="text-left">Comentario</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="item in detalles" :key="item.descripcion">
								<td> <v-checkbox v-model="item.check"/>  </td>
								<td> <v-chip :color="getColor(item.EstadoFacturacion)" :text-color="getColorText(item.EstadoFacturacion)">• {{ item.EstadoFacturacion }} </v-chip> </td>
								<td> {{item.descripcion}}</td>
								<td> {{item.prestador}} </td>
								<td> {{item.Fecha}}  </td>
								<td >{{item.pago}} </td>
								<td >{{item.personas}} </td>
								<td >{{item.comentario}} </td>
							</tr>
						</tbody>
						</template>
					</v-simple-table>
				</v-col>
			</v-row>
		</container>
		</v-card-text>
		<divider/>
		<v-card-actions>
			
			<v-btn type="text" color="error" @click="selectedOpen = false">
				Cerrar
			</v-btn>
			<v-btn text outlined small color="#7CB342" class="botonUsuario" :disabled="selectedEvent.Estado == 'In House'" @click.prevent="confirmEvent(selectedEvent)">
				Confirmar
			</v-btn>
			<v-btn text outlined small color="red" :disabled="selectedEvent.Estado == 'In House'"  class="botonUsuario" @click.prevent="NoPresentado(selectedEvent)">
				No Presentado
			</v-btn>
			<v-btn text outlined small color="orange" :disabled="selectedEvent.Estado == 'In House'"  class="botonUsuario" @click.prevent="PendienteEvento(selectedEvent)">
				Pendiente
			</v-btn>
			<v-btn text outlined small :disabled="selectedEvent.Estado == 'In House'" color="accent" class="botonUsuario"  v-if="currentlyEditing !== selectedEvent.id" @click.prevent="EditarProducto(selectedEvent)">
				Editar
			</v-btn>
			<v-btn text outlined small :loading="loading" :disabled="selectedEvent.Estado == 'In House'" color="#C8B81F" class="botonFacturar"  v-if="currentlyEditing !== selectedEvent.id" @click.prevent="CheckIn(selectedEvent)" >
				Check in
			</v-btn>
			<v-btn text outlined small :loading="loading"  type="text" color="accent" class="botonFacturar" :disabled="selectedEvent.Estado !='In House'" v-if="currentlyEditing !== selectedEvent.id" @click.prevent="CheckOut(selectedEvent)" >
				Check out
			</v-btn>
			<v-btn text outlined small :loading="loading"  type="text" color="accent" class="botonFacturar"  v-if="currentlyEditing !== selectedEvent.id" :disabled="calcularTotalFacturar == 0" @click.prevent="FacturarServicios()" >
				Facturar
			</v-btn>
			<v-btn color="primary" small v-else type="submit" v-bind:disabled="!validEdit" @click.prevent="updateEvent(selectedEvent)">
				Guardar
			</v-btn>
		</v-card-actions>
			</v-card>

			<v-card v-else color="grey lighten-4" :width="1000">
				<v-toolbar :color="selectedEvent.color" dark>
				<v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
				<div class="flex-grow-1"></div>
				</v-toolbar>
				<v-card-text>
				<container fluid>
				<v-row>
					<v-col cols="12" sm="12">
					<form v-if="currentlyEditing == true">
					{{ selectedEvent.details }}
					</form>
					<form v-else>
					<v-row>
						<div style="display:none">
						<v-text-field clearable v-model="idreserva" type="text" hidden  label=""></v-text-field>
						<v-text-field clearable v-model="name" type="text" hidden label=""></v-text-field>
						</div>
						<v-col cols="12" sm="4">
						<v-text-field clearable v-model="start" :rules="fechaInicialR" type="date" label="Fecha inicial"></v-text-field>
						</v-col>
						<v-col cols="12" sm="4">
						<v-text-field clearable v-model="end" type="date" :rules="fechaFinalR" label="Fecha final"></v-text-field>
						</v-col>
						<div style="display:none">
						<v-text-field clearable v-model="color" hidden type="color" label=""></v-text-field>
						</div>
						<v-col cols="12" sm="6">
						<v-combobox id="barberoCombobox" :rules="[v => !!v || 'El barbero es requerido']" v-model="barbero" :items="Areas" label="Habitación"></v-combobox>
						</v-col>
						<v-col cols="12" sm="6">
						<v-combobox v-model="filial" :rules="[v => !!v || 'El cliente es requerido']" :items="clientes" label="Filial"></v-combobox>
						</v-col>
						<v-col cols="12" sm="12">
						<v-text-field clearable v-model="details" type="text" label="Comentario"></v-text-field>
						</v-col>
						<v-col cols="12" sm="6">
							<v-menu
								ref="menu"
								v-model="menu4"
								:close-on-content-click="false"
								:nudge-right="40"
								:return-value.sync="time"
								transition="scale-transition"
								offset-y
								max-width="290px"
								min-width="290px"
							>
								<template v-slot:activator="{ on }">
								<v-text-field
									v-model="hora"
									:rules="horaInicialR"
									label="Hora Inicial:"
									readonly
									v-on="on"
								></v-text-field>
								</template>
								<v-time-picker full-width @click:minute="$refs.menu.save(time)" v-if="menu4" v-model="hora" color="green lighten-1" :max="hora2" :allowed-minutes="(m) => m % 15 == 0" header-color="primary"></v-time-picker>
							</v-menu>
						</v-col>
						<v-col cols="12" sm="6">
							<v-menu
								ref="menu"
								v-model="menu5"
								:close-on-content-click="false"
								:nudge-right="40"
								:return-value.sync="time"
								transition="scale-transition"
								offset-y
								max-width="290px"
								min-width="290px"
							>
								<template v-slot:activator="{ on }">
								<v-text-field
									v-model="hora2"
									:rules="horaFinalR"
									label="Hora Final:"
									readonly
									v-on="on"
								></v-text-field>
								</template>
								<v-time-picker full-width @click:minute="$refs.menu.save(time)" v-if="menu5" v-model="hora2" color="green lighten-1" :min="hora" :allowed-minutes="(m) => m % 15 == 0" header-color="primary"></v-time-picker>
								</v-menu>
						</v-col>
						</v-row>
					</form>
					</v-col>
				</v-row>
				</container>
			</v-card-text>
			<v-card-actions>
				<v-btn text color="error" @click="selectedOpen = false">
				Cerrar
				</v-btn>
			</v-card-actions>
			</v-card>
		</v-menu>
      
	  
	
    </v-col>
	<v-col cols="12" sm="12">
		<v-card>
			<v-card-title>
			<v-text-field
				v-model="search"
				append-icon="mdi-magnify"
				label="Search"
				single-line
				hide-details
			></v-text-field>
			<vue-excel-xlsx
				:data="events"
				:columns="headers2"
				:filename="'Lista de Productos '+$store.state.usuario.localidad"
				:sheetname="'Ventas'"
				>
				<v-btn fab="" color="success">  <v-icon large="">mdi-file-excel </v-icon> </v-btn>
			</vue-excel-xlsx>  
			</v-card-title>
			
			<v-data-table
			:headers="cabeceraTabla"
			:items="events"
			:search="search"
			>
				<template class="primary" v-slot:item.actiondelete="{ item }"> 
					<v-icon @click="deleteEvent(item)"> mdi-delete </v-icon>
					<v-icon @click="EditarProducto(item)"> mdi-file-account-outline</v-icon>
				</template> 
			</v-data-table>
		</v-card>
	</v-col>
	<!-- Tarjeta Registro -->
	<div id="invoice" style="display: none;" class="container-fluid invoice-container"> 
		<header>
			<table class="table table-bordered text-1 table-sm">
				<thead>
				<tr>
					<th><div id="logo">
							<img :src="Logo3" width="120" height="120">
							<h4> Tarjeta Registro / Registration Card </h4>
						</div>
					</th>
					<th>
						<div v-for="det in localidadencabezado" :key="det.nombre" id="datos">
							
								<h2> {{det.nombre}} </h2> <h3> {{det.razonSocial}} </h3>{{det.dirreccion}} Costa Rica<br> 
									{{det.cedulaJuridica}} <br>{{det.telefono}}<br>
								{{det.correo}}
							
						</div>
					</th>
				</tr>
				</thead>
				<tbody>
				<tr>
				</tr>
				</tbody>
			</table>
		</header>
			<!-- Passenger Details -->
			<h4 class="text-4 mt-2">Datos del Cliente/Guest Information</h4>
			<div class="table-responsive">
			<table class="table table-bordered text-1 table-sm">
				<thead>
				<tr>
					<th>Cédula/ID</th>
					<th>Nombre/Name</th>
					<th>Edad/Age</th>
					<th>Genero/Gender</th>
					<th>Nacionalidad/Nationality</th>
				</tr>
				</thead>
				<tbody>
				<tr>
					<td>{{selectedEvent.cedula}}</td>
					<td>{{selectedEvent.Cliente}}</td>
					<td></td>
					<td></td>
					<td></td>
				</tr>
				</tbody>
			</table>
			</div>
			<br>
			<div class="table-responsive">
			<table class="table table-bordered text-1 table-sm">
				<tbody>
				<tr>
					<td class="col-4"><span class="fw-600">Agent:</span>{{selectedEvent.Usuario}} </td>
					<td class="col-4"><span class="fw-600">Email:</span> {{selectedEvent.correo}}</td>
					<td class="col-4"><span class="fw-600">Phone No: </span> {{selectedEvent.Telefono}} </td>
				</tr>
				<tr>
					<td colspan="3"><span class="fw-600">DIRECCION RESIDENCIAL/homeaddress:</span> </td>
				</tr>
				</tbody>
			</table>
			</div>
			<!-- Passenger Details -->
			<h4 class="text-4">Datos de la reserva/ Reservation data</h4>
			<div class="table-responsive">
			<table class="table text-1 table-sm">
				<thead>
				<tr>
					<td colspan="4" class=""><span class="fw-600">Reference ID</span> {{selectedEvent.id}} <span style="text-align: right;"><span class="fw-600">Date of Booking</span> {{selectedEvent.start2}}</span></td>
				</tr>
				</thead>
				<tbody>
				<tr>
					<td class="fw-600 col-2">Transaction ID</td>
					<td class="col-4"></td>
					<td class="fw-600 col-2">Hab/Room No</td>
					<td class="col-4">{{selectedEvent.category}}</td>
				</tr>
				<tr>
					<td class="fw-600">Llegada /Arrival</td>
					<td>{{selectedEvent.start2}}</td>
					<td class="fw-600">Salida/Departure</td>
					<td>{{selectedEvent.end2}}</td>
				</tr>
				<tr>
					<td class="fw-600">Pers/max</td>
					<td>{{selectedEvent.personas}}</td>
					<td class="fw-600">Tarifa/Rate</td>
					<td>{{selectedEvent.precio}}</td>
				</tr>
				<tr>
					<td class="fw-600">DIRECCION RESIDENCIAL/homeaddress</td>
					<td>__</td>
					<td class="fw-600">TELEFONO/ phone nr.</td>
					<td> </td>
				</tr>
				<tr>
					<td class="fw-600">FORMA DE PAGO/ payment method</td>
					<td></td>
					<td class="fw-600">Pais/Country</td>
					<td></td>
				</tr>
				<tr>
					<td class="fw-600">Adultos/Adult</td>
					<td></td>
					<td class="fw-600">Under 18</td>
					<td></td>
				</tr>
				<tr>
					<td class="fw-600">Firma/Sign </td>
					<td>__________________________</td>
				</tr>
				</tbody>
			</table>
			</div>
			<!-- Acompañantes -->
			<h4 class="text-4 mt-2">Acompañantes / Companions</h4>
			<ul class="text-1">
			<li>Name:_________________________________________ Age:______________</li>
			<li>Name:_________________________________________ Age:______________</li>
			<li>Name:_________________________________________ Age:______________</li>
			<li>Name:_________________________________________ Age:______________</li>
			</ul>
			<br><br><br><br><br><br><br><br><br>
			<!-- Important Info -->
			<h4 class="text-4 mt-2">Important Instruction</h4>
			<ul class="text-1">
			<li>Check-In 3pm </li>
			<li>Check-out 11am, if you need late check-out you must request it at front desk to check if it
				can be authorized. Without prior coordination and authorization, late check-outs will be
				charged a $100 fine, and if the villas are delivered after 2pm, one more night will be
				charged.
			</li>
			<li>Under 18 can not drink liquor (even if their tutor says they authorize it), our law in Costa Rica does not allow anyone under 18 years of age to consume liquor or cigarettes. 
			</li>
			<li>Smoking will be completely prohibited inside the rooms or in areas that are not authorized
				for this, our no-smoking law # 9028 fines any institution that allows the consumption of
				cigarettes if it is not in the area authorized for smoking.
			</li>
			<li>Any damage to the property will be charged proportionally to what is affected.
			</li>
			<li>To charge any additional service to the room, you must have an open voucher like
			guarantee, and your signature will be requested for each service requested..
			</li>
			</ul>
			<!-- Important Info -->
			<h4 class="text-4 mt-2">Instrucciones Importantes</h4>
			<ul class="text-1">
			<li>Check-In 3pm</li>
			<li>Check-out 11am, en caso de necesitar late check-out deberá solicitar el mismo en recepción para ver si este se puede autorizar. Sin previa coordinación y autorización los late chechk-outs se procederán a cobrar $100 de multa, y si las villas son entregadas después de las 2pm se procederá a cobrar una noche más. 
			</li>
			<li>Ningún menor de edad podrá ingerir licor (aun cuando su tutor dijera que lo autoriza), nuestra ley en Costa Rica no permite que ningún menor de 18 años pueda consumir licor o cigarrillos.
			</li>
			<li>Sera completamente prohibido fumar dentro de las habitaciones o en áreas que no estén autorizadas para este, nuestra ley de no fumado # 9028 multa cualquier institución que permita el consumo de cigarrillo si no es en el área autorizado para fumado.
			</li>
			<li>Cualquier daño a la propiedad se procederá con un cobro proporcional a lo afectado.
			</li>
			<li>Para poder cargar cualquier servicio adicional a la villa deberá tener voucher abierto como garantía, y se le solicitará su firma en cada servicio solicitado.</li>
			</ul>
	</div>
	<v-dialog v-model="dialogPago" class="oculto-impresion" persistent max-width="1300">
            <v-card>
                <v-toolbar dark color="primary" class="oculto-impresion">
                <v-toolbar-title class="oculto-impresion">Datos de Facturación</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items class="oculto-impresion">
                    <v-btn icon dark @click="dialogPago = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar-items>
                </v-toolbar>
                <v-container fluid class="oculto-impresion">
                    <v-row>
                    <v-col cols="12" sm="4">
                        <v-text-field outlined type="text"  dense  v-model="clienteNombre" label="Nombre:"></v-text-field>
                        <v-select outlined type="tel" :items="tipoDocumentos"  dense  v-model="clienteTipoCedula" label="Tipo de Cédula:"></v-select>
						 <v-textarea
							label="Detalle:"
							auto-grow
							outlined
							rows="1"
							dense
							row-height="25"
							shaped
							v-model="detalleFactura"
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12" sm="4">
						
                        <v-text-field outlined type="number" @change="BuscarPersona()" clearable dense  v-model="clienteCedula" label="Cédula:"></v-text-field>
                        <v-text-field outlined type="email"  dense v-model="clienteCorreo" label="Correo:"></v-text-field>
                        <v-select outlined="" :readonly="esVendedor" dense v-model="vendedor" :items="Vendedores" color="success" :label="`Vendedor`"></v-select>
                    </v-col>
                    <v-col cols="12" sm="2">
						
                        <v-text-field outlined type="email"  dense v-model="clienteCorreo2" label="Correo2:"></v-text-field>
						<v-select v-model="tipoPago" :items="TipoPagos" outlined dense  label="Tipo Pago" /> 
					<v-select outlined="" dense v-model="tipoFactura"  :items="tipoFacturas" label="Tipo de Documento"></v-select>
                    </v-col>
					<v-col cols="12" sm="2">
						 <v-text-field append-icon="mdi-phone" outlined="" dense v-model="clienteTelefono" color="success" :label="`Tel`">
							</v-text-field><v-select dense outlined="" v-model="monedaPago" @change="Moneda()" :items="monedas" label="Moneda de Cobro"></v-select>
					
					</v-col>
                      <v-col cols="12" sm="6" class="grey lighten-4 blockquote">
                          <v-flex class="h6">
                            <div class="indigo--text"><small> SubTotal: {{monedaPago}} {{subtotal2}} </small> |  <small> IVA: {{monedaPago}}{{totalImpuesto}} </small> |</div>
                            <b style="font-size: 35px" class="primary--text">Total: {{monedaPago}} {{Total=calcularTotalFacturar}}</b>
                            <div style="font-size:10px"> Nota: Esta factura se crea para efectos contables y tributarios, no suma a su cierre de caja. </div>
                            
                          </v-flex>
                      </v-col>
						<v-col cols="10" sm="12">
							<v-btn block="" x-large :loading="loading" type="submit" color="primary" id="BotonPagar" autofocus class=" pa-2" style="top: 10px;" @click="FacturarNew()" @click.stop="dialog = false">
							Facturar
							</v-btn>
							<v-btn color="error" outlined large class="mr-4 mt-3" style="top: 10px; color: white;"  @click="dialogPago = false">
							<v-icon> mdi-exit-to-app </v-icon> Atrás
							</v-btn>
							
						</v-col>
					</v-row>
					
					</v-container>
            </v-card>
	</v-dialog>
	<v-dialog
        transition="dialog-top-transition"
        max-width="1300"
		v-model="dialogorbe"
      >
        <template >
          <v-card>
            <v-toolbar
              color="primary"
              dark
            >Reservas Entrantes</v-toolbar>
            <v-card-text>
				<v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      State
                    </th>
                    <th class="text-left">
                      Guest
                    </th>
					<th class="text-left">
                      Total
                    </th>
                    <th class="text-left">
                      Guaranty_Code
                    </th>
                    <th class="text-left">
                      Booking Code
                    </th><th class="text-left">
                      ResCode
                    </th>
                    <th class="text-left">
                      Room
                    </th>
                    <th class="text-left">
                      Check In
                    </th>
					<th class="text-left">
                      Check Out
                    </th>
                    <th class="text-left">
                      Adults
                    </th>
                    <th class="text-left">
                      Salesperson
                    </th>
					<th class="text-left">
                      Asignar
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in ReservasOrbe"
                    :key="item.codigop" 
					:class="item.color"
                  >
				  	
                    <td>{{ item.state }}</td>
                    <td>{{ item.Guest }}</td>
					<td>{{ item.total }}</td>
                    <td>{{ item.Guaranty_Code }}</td>
                    <td>{{ item.Booking }}</td>
                    <td>{{ item.ResCode }}</td>
                    <td>{{ item.Room }}</td>
					<td>{{ item.CheckIn }}</td>
					<td>{{ item.CheckOut }}</td>
					<td>{{ item.Adults }}</td>
					<td>{{ item.Salesperson }}</td>
					<td><v-btn :disabled="item.color =='warning'" @click="asignarReservas(item)" text outlined> ✔ </v-btn> </td>
				  </tr>
                </tbody>
              </template>
            </v-simple-table>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                text outlined color="red"
                @click="dialogorbe = false"
              >Close</v-btn>
			  <v-spacer/>
            </v-card-actions>
          </v-card>
        </template>
	</v-dialog>
	<v-dialog v-model="dialogblock" max-width="1000" >
		<v-card>
			<v-container>
				<v-toolbar dark color="primary">
					<v-toolbar-title>Bloqueo de: {{name}}</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-toolbar-items>
						<v-btn icon dark @click="dialog = false">
						<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-toolbar-items>
				</v-toolbar>
					<v-row>
						<v-col cols="12" sm="4">
						<v-menu
							v-model="menu20"
							:close-on-content-click="false"
							:nudge-right="40" 
							transition="scale-transition"
							offset-y
							min-width="290px"
						>
							<template v-slot:activator="{ on, attrs }">
							<v-text-field
								v-model="start"
								label="Fecha Inicio"
								prepend-icon="mdi-calendar"
								readonly
								persistent-hint
								type="date"
								hint="DD/MM/YYYY formato"
								v-bind="attrs"
								:rules="fechaInicialR"
								v-on="on"
							></v-text-field>
							</template>
							<v-date-picker :min="FechaInicio"  v-model="start" @input="menu20 = false"></v-date-picker>
						</v-menu>
						</v-col>
						<v-col cols="12" sm="4">
							<v-text-field clearable v-model="end" type="date" :rules="fechaFinalR" label="Fecha Salida"></v-text-field>
							</v-col>
						<v-col cols="12" sm="4">
						<v-select  id="areascomunes" :rules="[v => !!v || 'El área es requerida']" v-model="barbero" :items="prestadores" label="Habitación"></v-select>
						</v-col>
						<v-col cols="12" sm="4">
						<v-combobox id="bloqueos" :rules="[v => !!v || 'El área es requerida']" v-model="Bloqueo" :items="Bloqueos" label="Tipo De bloqueo"></v-combobox>
						</v-col>
						<v-col cols="12" sm="4">
						<v-text-field  v-model="details" type="text" label="Comentario"></v-text-field> 
						</v-col>
						<v-col cols="12" sm="4">
						<v-text-field v-model="idcliente.value" type="text" label="Nombre de Persona que Asiste"></v-text-field> 
						</v-col>
					</v-row>
					<v-divider></v-divider>
						<v-card-actions>
						<v-btn outlined color="teal darken-1" id="espacioBoton"  @click="reset">
							Limpiar
						</v-btn>
						<v-btn outlined color="error" id="espacioBoton" @click="dialogblock = false">
							Cancelar
						</v-btn> 
						<v-spacer></v-spacer>
						<v-btn outlined :loading="loading" type="submit"  dark color="green" id="espacioBoton"  @click="CrearBloqueo">
							Crear Bloqueo
						</v-btn>
						
						</v-card-actions>
				</v-container>
			</v-card>
	</v-dialog>
	<v-dialog v-model="dialog" max-width="1200" >
		<v-card>
			<v-toolbar dark color="primary">
				<v-toolbar-title>RESERVA: {{name}}</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-toolbar-items>
					<v-btn icon dark @click="dialog = false">
					<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar-items>
			</v-toolbar>
			<v-alert
			dense
			v-model="alerta"
			border="left"
			type="warning"
			>
			Ya existe una reserva en  <strong>{{barbero}}</strong> para este <strong> día</strong>
			</v-alert>
			<v-card-text>
				<v-form ref="form" v-model="valid" @submit.prevent="ValidarMultiple">
				<v-row>
					<v-col cols="12" lg="2" md="6" xs="3">
					<v-menu
						v-model="menu20"
						:close-on-content-click="false"
						:nudge-right="40" 
						transition="scale-transition"
						offset-y
						@change="calcularFechas()"
						min-width="290px"
					>
						<template v-slot:activator="{ on, attrs }">
						<v-text-field
							v-model="start"
							label="Fecha"
							prepend-icon="mdi-calendar"
							readonly
							@change="calcularFechas()"
							dense
							persistent-hint
							:disabled="CodigoOta != ''"
							type="date"
							hint="DD/MM/YYYY formato"
							v-bind="attrs"
							:rules="fechaInicialR"
							v-on="on"
						></v-text-field>
						</template>
						<v-date-picker @change="calcularFechas()" :min="FechaInicio"  v-model="start" @input="menu20 = false"></v-date-picker>
					</v-menu>
					<v-menu
						v-model="menu21"
						:close-on-content-click="false"
						:nudge-right="40" 
						transition="scale-transition"
						offset-y
						@change="calcularFechas()"
						min-width="290px"
					>
						<template v-slot:activator="{ on, attrs }">
						<v-text-field
							class="mt-3"
							v-model="end"
							label="Fecha salida"
							prepend-icon="mdi-calendar"
							readonly
							dense
							:disabled="CodigoOta != ''"
							persistent-hint
							type="date"
							@change="calcularFechas()"
							hint="DD/MM/YYYY formato"
							v-bind="attrs"
							:rules="fechaInicialR"
							v-on="on"
						></v-text-field>
						</template>
						<v-date-picker @change="calcularFechas()" v-model="end" @input="menu21 = false"></v-date-picker>
					</v-menu>
					</v-col>
					<v-col cols="12" lg="2" md="6" xs="3">
						<v-text-field
							label="Hora Inicio"
							v-model="hora"
							dense
							type="time"
							v-on="on"
							:disabled="CodigoOta != ''"
						></v-text-field>
						<v-text-field
							label="Hora Final"
							v-model="hora2"
							type="time"
							dense
							v-on="on"
							:disabled="CodigoOta != ''"
						></v-text-field>
					</v-col>
					<v-col cols="12" lg="4" md="6" xs="6">
						<v-card-actions > 
						<v-combobox  :background-color="ValidarColorNum(idcliente)" clearable @change="obtenerCliente()" dense v-model="idcliente" :items="clientes" label="Cliente"></v-combobox> 
							<div>   
								<v-icon @click="AbrirDialogCliente()" color="green">mdi-plus  </v-icon> 
								<v-icon @click="EditarCliente()"> mdi-pencil </v-icon>
								
								<v-menu
									v-model="menu"
									:close-on-content-click="false"
									:nudge-width="200"
									offset-x
									v-if="nombreFantasia != ''"
									>
									<template v-slot:activator="{ on, attrs }">
										<v-btn v-bind="attrs"
										v-on="on"  dark small fab color="red" >
											<v-icon > mdi-alarm-light </v-icon>
										</v-btn>
									</template>

									<v-card>
										<v-list>
										<v-list-item>
											<v-list-item-avatar>
											<img
												src="https://cdn.vuetifyjs.com/images/john.jpg"
												alt="John"
											>
											</v-list-item-avatar>

											<v-list-item-content>
											<v-list-item-title>Comentario Importante de </v-list-item-title>
											<v-list-item-subtitle>{{clienteNombre}}</v-list-item-subtitle>
											</v-list-item-content>

											<v-list-item-action>
											<v-btn
												:class="fav ? 'red--text' : ''"
												icon
												@click="fav = !fav"
											>
												<v-icon>mdi-heart</v-icon>
											</v-btn>
											</v-list-item-action>
										</v-list-item>
										</v-list>

										<v-divider></v-divider>

										<v-list>
										<v-list-item>
											<v-list-item-title>{{nombreFantasia}}</v-list-item-title>
										</v-list-item>
										</v-list>
									</v-card>
									</v-menu>
							</div>  
						</v-card-actions>
						<v-card-actions>
							<v-select  dense :items="Vendedores" v-model="vendedor" type="text" label="Vendedor"></v-select> 
							<v-icon @click="EliminarVendedor()" v-if="vendedor != ''" color="red">mdi-minus  </v-icon>
							<v-edit-dialog
									:return-value.sync="TourOperador"
									@save="AgregarVendedor()"
									@cancel="cancel"
									@open="open"
									@close="close"
									>
									<v-icon color="green">mdi-plus  </v-icon> 
								<template v-slot:input>
									<v-text-field
									onfocus="this.select();"
									v-model="vendedor"
									label="Digite el vendedor"
									hint="Precione enter para guardar"
									counter
									></v-text-field>
								</template>
							</v-edit-dialog> 
						</v-card-actions>
					</v-col>
					<v-col cols="12" lg="4" md="6" xs="6">
					<v-select dense @change="ValidarReserva" id="areascomunes" :rules="[v => !!v || 'El área es requerida']" v-model="barbero" :items="prestadores" label="Servicio o Habitación"></v-select>
					<v-select dense :hint="'Id de Servicio:' +IdMultiple" v-model="IdMultiple" :items="barberos" label="Habitación para aplicar cargo"></v-select>
					</v-col>
					<v-col cols="12" lg="4" md="6" sm="6">
						<v-row>
							{{tipoPago}}
							<v-combobox :disabled="id != 0" dense @change="buscarCodigoBoton()" style="width:100%" label="Seleccione el servicio a facturar" v-model="servicio" :items="productos"> </v-combobox>
							<v-text-field style="width:30%" dense filled onfocus="this.select();" v-model="precio" type="number" label="Precio Servicio"></v-text-field> 
							<v-text-field  style="width:30%" label="Cant" dense filled type="number" onfocus="this.select();" v-model="cantidad" />
							<v-text-field style="width:30%" filled dense onfocus="this.select();" v-model="tipoPago" type="number" label="Anticipo Pago"></v-text-field> 
							<v-select style="width:30%"  v-model="tipoPagoFactura" :items="TipoPagos" filled   label="Tipo Pago" /> 
							IVA: {{iva=calculariva}} | Precio Total: {{precioVenta=calcularTotalFactura}} | Dias {{restaFechas}}
						</v-row>	
					</v-col>
					<v-col cols="12" lg="4" md="6" sm="6">
						<v-card-actions>
							<v-select dense v-model="agencia" :items="Agencias" type="text" label="Agencia"></v-select>
							<v-icon @click="EliminarAgencia()" v-if="agencia != ''" color="red">mdi-minus  </v-icon>
							
							<v-edit-dialog
								:return-value.sync="agencia"
								@save="AgregarAgencia()"
								@cancel="cancel"
								@open="open"
								@close="close"
								>
							 	<v-icon color="green">mdi-plus  </v-icon> 
							<template v-slot:input>
								<v-text-field
								onfocus="this.select();"
								v-model="agencia"
								label="Digite el nombre de la Agencia"
								counter
								></v-text-field>
							</template>
						</v-edit-dialog> 
							
						</v-card-actions>
						<v-card-actions>
							<v-select dense v-model="TourOperador" :items="TourOperadores" type="text" label="Tour Operador"></v-select> 
							<v-icon @click="EliminarTour()" v-if="TourOperador != ''" color="red">mdi-minus  </v-icon>
							<v-edit-dialog
									:return-value.sync="TourOperador"
									@save="AgregarTour()"
									@cancel="cancel"
									@open="open"
									@close="close"
									>
									<v-icon color="green">mdi-plus  </v-icon> 
								<template v-slot:input>
									<v-text-field
									onfocus="this.select();"
									v-model="TourOperador"
									label="Digite el nombre del Tour Operador y precione Enter para guardar"
									counter
									></v-text-field>
								</template>
							</v-edit-dialog> 
						</v-card-actions>
					</v-col>
					<v-col cols="12" lg="4" md="6" xs="3">
						<v-select dense v-model="CantPersonas" :items="Personas" type="text" label="Personas"></v-select> 
						<v-text-field dense v-model="details" type="text" label="Comentario"></v-text-field> 
						Código Ota: {{CodigoOta}} CodRInterno: {{id}} 
					</v-col>
					</v-row>

					<v-divider></v-divider>
					<v-card-actions>
						<v-btn outlined color="teal darken-1" id="espacioBoton"  @click="reset">
							Limpiar
						</v-btn>
						<v-btn outlined color="error" id="espacioBoton" @click="CerrarDialog">
							Cancelar
						</v-btn> 
						<v-spacer></v-spacer>
						<v-btn v-if="id != ''" outlined :loading="loading" dark color="green" @click="AsignarEvento()">
							Asignar
						</v-btn>
						<v-btn v-if="id == 0" outlined :loading="loading" type="submit" dark color="green" id="espacioBoton"  @click.stop="dialog = false">
							Crear Reserva
						</v-btn>
					</v-card-actions>
				</v-form>
			</v-card-text>
		</v-card>
	</v-dialog>
	<v-dialog v-model="dialogCliente"  max-width="1000">
				<v-card>
				<v-toolbar dark color="primary">
					<v-toolbar-title> <div v-if="clienteExiste == false"> Agregar Cliente </div> <div v-if="clienteExiste == true"> Editar Cliente </div> </v-toolbar-title>
					<v-spacer></v-spacer>
					<v-toolbar-items>
						<v-btn icon dark @click="dialogCliente = false">
						<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-toolbar-items>
				</v-toolbar>
				<v-container fluid>
					<v-row>
					<v-col cols="12" sm="12">
						<v-form ref="form" v-model="validCliente"  @submit.prevent="crearCliente">
						<v-row>
							<v-col cols="12" sm="3">
								<v-text-field :rules="cedulaRules" @change="BuscarPersona()" outlined clearable v-model="cedula"  required type="tel" label="Número Cédula"></v-text-field>
							</v-col>
							<v-col cols="12" sm="6">
								<v-text-field :rules="nameRules" clearable v-model="nombre" outlined required type="text" label="Nombre (Razón Social)"></v-text-field>
							</v-col>
							<v-col cols="12" sm="3">
								<v-select v-model="tipoDocumento" outlined :items="tipoDocumentos" label="Tipo Documento"></v-select>
							</v-col>
							<v-col cols="12" sm="3">
								<v-text-field :rules="[v => !!v || 'El Telefono es requeido']" outlined clearable v-model="telefono" type="tel" label="Teléfono Celular"></v-text-field>
							</v-col>
							<v-col cols="12" sm="3">
								<v-text-field  outlined clearable v-model="celular" type="tel" label="Telefono Casa"></v-text-field>
							</v-col>
							<v-col cols="12" sm="3">
								<v-text-field clearable v-model="correo" outlined type="email" label="Correo Electrónico"></v-text-field>
							</v-col>
							<v-col cols="12" sm="3">
								<v-text-field type="email" outlined v-model="clienteCorreo2" label="Correo alternativo:"></v-text-field>
							</v-col>
							<v-col cols="12" sm="3">
								<v-text-field type="email" outlined v-model="limiteCredito" label="Limite Crédito:"></v-text-field>
							</v-col>
							<v-col cols="12" sm="6">
								<v-text-field outlined clearable v-model="nombreFantasia" type="text" label="Comentario"></v-text-field>
							</v-col>
							<v-col cols="12" sm="12">
							<v-divider/>
								<v-card-actions>
									<v-btn x-large text outlined color="teal darken-1" id="update" class="mr-4" style="color: white;" @click="limpiarCliente()">
									<v-icon> mdi-update </v-icon>
									Limpiar
									</v-btn>
									<v-btn x-large text outlined color="error" @click="dialogCliente = false">
									<v-icon> mdi-exit-to-app </v-icon>
									Salir
									</v-btn>
									<v-spacer/>
									<v-btn :loading="loading" x-large :disabled="validCliente == false" outlined v-if="clienteExiste == false" type="submit" color="primary" id="espacioBoton" class="mr-4">
									Crear Cliente
									</v-btn>
									<v-btn :loading="loading" x-large v-if="clienteExiste == true" @click="ActualizarCliente()"  color="primary" id="sn" class="mr-4" style="top: 10px;">
									Editar Cliente
									</v-btn>
								</v-card-actions>
							</v-col>
						</v-row>
						
						</v-form>
					</v-col>
					</v-row>
				</v-container>
				</v-card>
	</v-dialog>
	<v-dialog
			v-model="dialogFicha"
			width="1400"
			>
			<v-card>
				<v-card-title class="text-h5 grey lighten-2">
				 <v-edit-dialog
					:return-value.sync="idcliente"
					>
					Ficha Cliente {{idcliente }}
					<template v-slot:input>
						<v-combobox class="mt-3" clearable @change="selectFicha()" dense append-icon="mdi-account-circle-outline" v-model="idcliente" :items="clientes" label="Cliente"></v-combobox> 
					</template>
				</v-edit-dialog> 
				</v-card-title>
				<v-container>	
					 <v-edit-dialog
							:return-value.sync="NotaFicha"
							large
							persistent
							@save="CrearFicha()"
							@cancel="cancel"
							@open="open"
							@close="close"
							>
							<div> <v-btn color="secondary"> Agregar nota </v-btn> </div>
							<template v-slot:input>
								<div class="mt-4 text-h6">
								Agregar Nota
								</div>
								<v-text-field
								v-model="NotaFicha"
								single-line
								counter
								autofocus
								></v-text-field>
							</template>
							</v-edit-dialog>
					<v-simple-table height="400">
						<template v-slot:default>
						<thead>
							<tr>
							<th class="text-left">
								Usuario
							</th>
							<th class="text-left">
								Nota
							</th>
							<th class="text-left">
								Fecha
							</th>
							<th class="text-left">
								Hora
							</th>
							<th class="text-left">
								Localidad
							</th>
							
							</tr>
						</thead>
						<tbody>
							<tr
							v-for="item in fichas"
							:key="item.nombreCliente"
							>
							<td>{{ item.usuario }}</td>
							<td>{{ item.notaCliente }}</td>
							<td>{{ item.fecha }}</td>
							<td>{{ item.hora }}</td>
							<td>{{ item.localidad }}</td>
							</tr>
						</tbody>
						</template>
					</v-simple-table>
				</v-container>
				<v-divider></v-divider>

				<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="primary"
					text
					@click="dialogFicha = false"
				>
					Cerrar
				</v-btn>
				</v-card-actions>
			</v-card>
	</v-dialog>
		<template>
			<div class="text-center">
				<v-overlay :value="loading">
				<v-progress-circular
					indeterminate
					size="64"
				></v-progress-circular>
				</v-overlay>
			</div>
		</template>
  </v-row>
</template>
<script>
	// import { db } from '@/main'
	import axios from 'axios';
  export default {
    data: () => ({
		value: '',
		today: new Date().toISOString().substr(0, 10),
		focus: "",
		events: [],
		colors: ['#2196F3', '#3F51B5', '#673AB7', '#00BCD4', '#4CAF50', '#FF9800', '#757575'],
		names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
		dragEvent: null,
		categories: ["Daniela","Aby","Luna"],
		dragStart: null,
		currentlyEditing: false,
		agencia:"",
		ReservasOrbe:[],
		id: 0,
		typeToLabel: {
		month: 'Mes',
		week: 'Semana',
		day: 'Días',
		category: 'category',
		'4day': '4 Días', 
		},
		createEvent: null,
		type: "category",
		createStart: null,
		Bloqueo:null,
		Bloqueos:[{ text: 'Bloqueo Mantenimiento',color:'#78909C' },
        { text: 'Bloqueo Limpieza', color:'#F9A825' },
		{ text: 'Limpia sin Ingreso', value: 'Limpia sin ingreso',color:'#66BB6A' },
		{ text: 'Limpia con ingreso', value: 'Limpia con ingreso',color:'#000000' },
		{text: 'Sucia Sin Ingreso', value: 'Sucia Sin Ingreso',color:'#c35' },
		{text: 'Sucia Con Ingreso', value: 'Sucia Con Ingreso',color:'#0288D1' },],
		extendOriginal: null,
		headers2: [
        { label: 'Start', field: 'start' },
        { label: 'start2', field: 'start2' },
        { label: 'end', field: 'end' },
        { label: 'end2', field: 'end2'},
        { label: 'Nombre', field: 'name'},
        { label: 'Color', field: 'color'},
        { label: 'IdMultiple', field: 'IdMultiple'},       
        { label: 'Telefono', field: 'Telefono' },
        { label: 'Cliente', field: 'Cliente'} ,
		{ label: 'category', field: 'category'},
		{ label: 'Usuario', field: 'Usuario'},  
		{ label: 'Fecha', field: 'Fecha'}, 
		{ label: 'Estado', field: 'Estado'}, 
		{ label: 'detalle', field: 'detalle'},            
       ],
		repeticiones: [ { text: 'Ninguno', value: 0  },
    { text: 'Cada semana', value: 1  }, 
    { text: 'Cada 15', value: 2  }, 
    { text: 'Cada mes', value: 3  }, ],
  repite: 0,
  dragEvent: null,
	dragStart: null,
	createEvent: null,
	createStart: null,
	extendOriginal: null,
    menu3: false,
	WhatsAppMensaje:'',
	dialogorbe:false,
    Mensaje: "",
	CategoryDays:[1,2,3,4],
	CategoryDay:1,
    snackbar: false,
    menu4: false,
	celular: "",
    menu20: false,
    picture: '',
    menu5: false,
    menu6: false,
    menu7: false,
    menu8: false,
    menu9: false,
    valid: true,
    validDate: true,
    validEdit: true,
    validCliente: true,
    validBarbero: true,
    idEvento: 0,
	Vendedores: [],
    validFactura: true,
    descripcion: "",
    indexProducto: 0,
	IdMultiple:'',
    precio: 0,
    DiaHoy2: 0,
	CitasDelDia: [],
    forecast: [],
    descuento: 0,
    codigoArticulo : 0,
    sum2horas: '02:00',
    impuestoDialog: 0,
	codigo: 0,
    impuestoDialog2: 0,
    codigoArticulo2: 0,
    descripcion2: 0,
    cantidadDialog2: 0,
    gravado2: "",
    valorImpuesto2: 0,
    totalGravado:0,
    descuento2: 0,
    precio2: 0,
    descuento2: 0,
    subtotal: 0,
    servicio: '',
    iniciohora : '',
    subtotal2: 0,
    cantidad: 1,
    cantidadDialog: 0,
	Loc: '',
    tipoDocumento: null,
    tipoFactura: 1,
    tarjetaPagar:0,
    efectivoPagar:0,
    vueltoPagar:0,
	TipoPagos:["TARJETA","EFECTIVO","TARJETA","DEPOSITO","SINPE"],
    totalPagar:0,
    efectivoCampo:true,
    tarjetaCampo:true,
    selectedId: -1,
    selected: [],
    monedas: [
		'CRC',
		'USD',
    ],
    monedaPago: "USD",
    dataArticulo: [],
    singleSelect: true,
    tipoDocumentoBarbero: null,
    dialog: false,
    name: null,
    details: '',
    FechaInicio: '',
	Fecha: new Date().toISOString().substr(0, 10),
    start: new Date().toISOString().substr(0, 10), 
    end: new Date().toISOString().substr(0, 10),
    color: '#1976D2', // default event color
    estado: null,
    idcliente: [],
    idLocalidadCliente: null,
    clientes:[],
    productos:[],
    productosDatos:[],
	Agencias:[],
	TourOperadores:[],
	NoFactura: "",
	TourOperador: "",
    localidades:[],
	PosicionProductos:[],
    localidadesColor:[],
    idusuario: 2,
	nombreFantasia: "",
    overlay: false,
	menu21:false,
	clienteCorreo2: "",
    localidad: null,
    hora: '13:00',
	genero: "",
    hora2: '14:00',
    barbero: null,
	Botones: [],
    codigo:'',
	Cxc:[],
    search: '',
    stock: 0,
    precio_venta: 0,
    valida: 0,
    validaMensaje:[],
    filial: '',
    adModal: 0,
    adAccion: 0,
    adNombre: '',
    adId: '',
    barcode: '',
    Costo:'',
    loading: true,
    codigoProducto: '',
    proveedor:'',
    categoria:'',
    details: '',
    familia:'',
    familias:[],
    precioCompra:0,
	name: "",
    precioVenta: 0,
    maximo:0,
    minimo:0,
    tipoProducto:'',
    maxDescuento: 90,
	CodigoOta:'',
    comision:0,
    localizacion:'NA',
    compuesto: false,
	inhouses:[],
    cocina: 0,
	vendedor:0,
	tipoPagoFactura: '',
	NotaFicha: "",
    estado: 0,
    clienteExiste: false,
    cabeceraArticulos: [
    { text: 'Código Producto', value: 'codigoProducto' },
    { text: 'Descripción', value: 'descripcion' },
    { text: 'Categoría', value: 'categoria' },
    { text: 'Precio Venta', value: 'precioVenta', sortable: false  }            
    ],
    cabeceraTabla: [ 
    { text: 'Opciones', value: 'actiondelete' },
    { text: 'Cliente', value: 'name' },
    { text: 'Fecha Reserva', value: 'start2'  },
    { text: 'Código Hab', value: 'IdMultiple'  },
	{ text: 'Cliente', value: 'Cliente' },   
    { text: 'Fecha Salida', value: 'end2'  },
    { text: 'Servicio', value: 'category' },
    { text: 'Número', value: 'Telefono' },
    { text: 'Usuario', value: 'Usuario' },
    { text: 'Fecha Registro', value: 'Fecha' },   
	{ text: 'Agencia', value: 'agencia' }, 
	{ text: 'Tour Operador', value: 'tourOperador' }, 
	{ text: 'Precio', value: 'precio' }, 
	{ text: 'Anticipo', value: 'pago' }, 
	{ text: 'Personas', value: 'personas' }, 
	{ text: 'Estado', value: 'Estado' }, 
	{ text: 'Código Orbe', value: 'CodigoOta' }, 
	{ text: 'OutNum', value: 'end' }, 
	{ text: 'InNum', value: 'start' }, 
    ],
    valorImpuesto:0,
    gravado:"",
    articulos:[],
    texto:'',
	Resta: [],
    verArticulos:0,
    verNuevo:0,
	dialogblock:false,
    errorArticulo:null,
	fichas:[],
    totalParcial:0,
    totalImpuesto:0,
    selectedFile: null,
    totalDescuento:0,
    total:0,
    totalExento:0,
    barberos: [],
	tipoPago:0,
    Areas: [],
    impuesto: null,
    tipo_comprobante: null,
    detalles:[],
    facturaTemporal:[],
	abono: 0,
	total: 0,
    datosFacturaArreglo:[],
    hora3: '11:00',
    detallesEditar:[],
    HoraHoy : 0,
    codigoPais: "506",
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    dialogDate: false,
    dialogCliente: false,
    dialogBarbero: false,
    dialogProducto: false,
    dialogNuevoProducto: false,
    dialogAgregarProducto: false,
    uploadValue: 0,
    dialogFactura: false,
    dialogFacturaReserva: false,
	Logo3:'',
	alerta : false,
    dialogPago: false,
	dialogFicha: false,
    pago:0,
    proceso: null,
    idreserva: null,
    procede: true,
    nombre: "",
    color: 'success',
    cedula: "",
    colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
    telefono: "",
    correo: "",
    emailRules: [
        v => !!v || 'El correo electrónico es requerido',
        v => /.+@.+\..+/.test(v) || 'El correo electrónico es requerido debe ser válido',
      ],
    direccion: "",
    nombreBarbero: "",
    time:"",
    cedulaRules: [
        value => !!value || 'La cédula es requerida'
      ],
    nameRules: [
        value => !!value || 'El nombre es requerido',
        value => (value && value.length >= 3) || 'Debe ingresar 3 carácteres como mínimo',
      ],
    fechaInicialR: [
        value => !!value || 'La fecha inicial es requerida'
      ],
    horaInicialR: [
        value => !!value || 'La hora inicial es requerida'
      ],
    fechaFinalR: [
        value => !!value || 'La fecha final es requerida'
      ],
     horaFinalR: [
        value => !!value || 'La hora final es requerida'
      ],
    cedulaBarbero: "",
    telefonoBarbero: "",
	tipoDocumentos: [
		'FISICA',
		'JURIDICA',
		'DIMEX',
		'NITE',
	],
    correoBarbero: "",
    color1 : 'success',
    direccionBarbero: "",
	limiteCredito:0,
    CantidadHoras: 0,
	menu20: false,
    passwordBarbero: "",
    Personas: ["1","2","3","4","5","6","7","9","10","More"],
    passwordBarbero2: "",
    idrol: 1,
    combinado: false,
	correoLocalidad:'',
	CondicionVenta : 'CREDITO',
    resultadoCompra: 0,
    detalleFactura: "",
    procesoPago: true,
	localidadencabezado:[],
    flagProducto: false,
    codigoProductoViejo: "",
    auxVenta:0,
    clienteNombre: "",
    clienteCorreo: "",
    clienteTipoCedula: "FISICA",
    CantPersonas: 5,
	tipoFacturas: [
      { text: 'Factura Electronica', value: 1  },
      { text: 'Tiquete Electronico', value: 4  }, 
      { text: 'Recibo', value: 8  },  
    ],
    existe: 0,
    clienteCedula: "",
	iva:0,
    prestadores: [],
    clienteTelefono: "",
	Bloqueo : null,
    clienteIdReserva: 0,
    clienteId: 0,
    }),
	mounted () {
		this.selectBarbero();
		this.validarLocalidad();
		this.getEvents2();
		this.select();
		this.selectServicios();
		this.validarFocus();
		this.SelectFichaProducto();
		this.listarLocalidad();
	},
  	computed: {
		calcularimp13:function(){
			var resultado=0.0;
				for(var i=0;i<this.detalles.length;i++){
				if(this.detalles[i].gravado == 'S' && this.detalles[i].Ivi == false && this.detalles[i].valorImpuesto == 13 )
				{
					resultado=resultado+((this.detalles[i].precio -(this.detalles[i].precio* (this.detalles[i].descuento)/100)) * this.detalles[i].cantidad) * 0.13;
				}
				if(this.detalles[i].gravado == 'S' && this.detalles[i].Ivi == true && this.detalles[i].valorImpuesto == 13)
				{
					resultado=resultado+(((this.detalles[i].precio / 1.13) -((this.detalles[i].precio / 1.13)* (this.detalles[i].descuento)/100)) * this.detalles[i].cantidad) * 0.13;
				}
				}
				return resultado;
		},
		calculariva:function(){
			var resultado=0.0;
			resultado = (this.precio * this.cantidad ) * 0.13;
			return resultado.toFixed(2);
		},
		calcularTotalFactura:function(){
			var resultado=0.0;
			resultado = parseFloat(this.iva) + (parseFloat(this.precio) * parseFloat(this.cantidad));
			return resultado;
		},
		caclcularCXC:function(){
				var resultado=0.0;
				for(var i=0;i<this.Cxc.length;i++){
						resultado=resultado+this.Cxc[i].credito;
				}
				
				return resultado.toFixed(2);
		},
		calcularTotal2:function(){
				var resultado=0.0;
				for(var i=0;i<this.detalles.length;i++){
						resultado=resultado+this.detalles[i].pago;
				}
				
				return resultado.toFixed(2);
		},
		// Función para calcular los días transcurridos entre dos fechas
		restaFechas:function()
		{
			var date_1 = new Date(this.start);
			var date_2 = new Date(this.end);

			var day_as_milliseconds = 86400000;
			var diff_in_millisenconds = date_2 - date_1;
			var diff_in_days = diff_in_millisenconds / day_as_milliseconds;
			return diff_in_days;
		},
		calcularTotal:function(){
			var resultado=0.0;
			for(var i=0;i<this.Cxc.length;i++){
					resultado=resultado+(this.Cxc[i].credito * 1);
			}
			
			return resultado.toFixed(2);
		},
		calcularTotalFacturar:function(){
			var resultado=0.0;
			for(var i=0;i<this.detalles.length;i++){
				if(this.detalles[i].check == true){
					resultado=resultado+(this.detalles[i].pago * 1);
				}
					
			}
			
			return resultado.toFixed(2);
		},
		calcularSaldo:function(){
			var resultado=0.0;
			resultado = this.total - this.abono;
			
			return resultado.toFixed(2);
		},
    },
    methods: {
	startDrag ({ event, timed }) {
	if (event && timed) {
		this.dragEvent = event
		this.dragTime = null
		this.extendOriginal = null
	}
	},

	calcularTotalCheck(){
		var resultado=0.0;
		for(var i=0;i<this.Cxc.length;i++){
			if(this.Cxc[i].check == true)
			{	
				resultado=resultado+(this.Cxc[i].credito * 1);
			}
		}
		this.saldo = resultado.toFixed(2);
		this.total = resultado.toFixed(2);
		this.credito = resultado.toFixed(2);
	},
	listarLocalidad(){
		let me =this;
		let logo = null;
		let header={"Authorization" : "Bearer " + this.$store.state.token};
		let configuracion= {headers : header};
			axios.get('api/Localidades/ListarFiltrada/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
				//console.log(response);
				me.localidadencabezado=response.data;
				me.correoLocalidad = me.localidadencabezado[0].correo
				logo = me.localidadencabezado[0].imagen;
				function toDataURL(url, callback) {
				var xhr = new XMLHttpRequest();
				xhr.onload = function() {
					var reader = new FileReader();
					reader.onloadend = function() {
					callback(reader.result);
					}
					reader.readAsDataURL(xhr.response);
				};
				xhr.open('GET', url);
				xhr.responseType = 'blob';
				xhr.send();
				}
				var url2 = logo;
				
					toDataURL(url2, function(dataUrl) {
					console.log('RESULT:', dataUrl)
					me.Logo3 = dataUrl;
					}) 
				}).catch(function(error){
					console.log(error);
				});
			
	},
	selectServicios(){
		let me=this;
		let header={"Authorization" : "Bearer " };
		let configuracion= {headers : header};
		var productosArray=[];
		axios.get('api/Productos/SelectServicios/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
			productosArray=response.data;
			productosArray.map(function(x){
				me.productos.push({text:x.descripcion, value:x.codigoProducto});
			});
		}).catch(function(error){
			console.log(error);
		});
	},
	async CuentaMaestra(){
		for(var i = 0; i < this.Cxc.length; ++i)
		{	
			if(this.Cxc[i].check == true)
			{
			let self = this;
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			await axios.post('api/RepVentasVistas/CuentaMaestra',{
				'Localidad': self.$store.state.usuario.localidad,
				'CedulaCliente': self.IdMultiple ,
				'Factura':self.Cxc[i].factura,
			},configuracion).then(function (response) {
				self.Mensaje = "Saldo en Factura "+self.Cxc[i].factura+" Transferido con éxito " ;
				self.snackbar = true;
				self.Cxc = [];
			})
			.catch(function (error) {
				alert("Error tranfiriendo saldo Cuenta Maestro, Notifique a su técnico "+ error);
		
			})
			}
		}
		self.Cxc = [];
	},
	ReservarTour(ev){
		this.barbero = ev.category
		this.dialog = true;
	},
	  ValidarMultiple(){
			if(this.idcliente == ''){
			this.Mensaje = "Debe selccionar un cliente para continuar";
			this.snackbar = true;
			return;
			}
			if(this.start && this.end == ""){
				this.Mensaje = "Debe Fecha de inicio y final para continuar";
				this.snackbar = true;
			return;
			}
			this.AddMultiple();
				
		},
		LocalidadAsignada(){
			localStorage.setItem("loc",this.Loc)
			location.reload();
		},
		TransformarFecha(ev){
			var date1 = new Date(ev.start)
			date1 = date1.getTime();
			return date1;
		},
		ConfirmarWhatsapp(ev){
		var FechaInicio = ev.start2.substring(0,10);
		var link = location.origin+'/'+'confirmarcita/'+this.selectedEvent.Telelfono+'/'+this.Loc+'/'+FechaInicio;	
		if(this.Loc == 'Allure Belen'){
			this.WhatsAppMensaje = "Buenas tardes "+this.selectedEvent.Cliente+" le saludamos  de parte de "+this.Loc+" en el Hotel Marriott, nos gustaría confirmar su cita el día de "+this.selectedEvent.start2+" Agradecemos por favor confirmar dando click en el siguiente link. "+link+" Recuerde debe de usar su mascarilla al momento de ingresar a la propiedad del Hotel, no solo en el salón. Para atenderle de la mejor manera y con el fin de aprovechar al máximo su tiempo, le rogamos puntualidad,  y si se diera algún atraso, por favor comunicarse con nosotros para revisar la agenda. Como medidas ante el Covid-19 deben traer su propia mascarilla, les sugerimos venir con la menor cantidad de artículos personales y si no es necesario evitar venir acompañado, de presentar algún síntoma de resfrió agradecemos seguir los protocolos que sugiere el Ministerio de Salud y no acudir a lugares públicos. Tenemos implementadas todas las medias de limpieza y desinfección requeridas , para que ustedes nuestros clientes y colaboradores se sientan 100% seguros. Este mensaje fue generado de forma automatica, por favor no responda ya que no obtendrá respuesta" ;
			return;
		} else 
		if(this.Loc == 'Allure Escazu'){
			this.WhatsAppMensaje = "Buenas tardes "+this.selectedEvent.Cliente+", "+this.Loc+" le recuerda que nos encontramos ubicados en el nuevo Local en el Centro Comercial la Paco local 40. Favor confirmar su cita el día "+this.selectedEvent.start2+" en el siguiente link. "+link+" Agrega a Allure en  tus contactos y podrá ver nuestras promociones del mes✨ aprovecha y consulte ✨ Este mensaje fue generado de forma automatica, por favor no responda ya que no obtendrá respuesta" ;
			return;
		} 
			this.WhatsAppMensaje = "Estimad@ "+this.selectedEvent.Cliente+", "+this.Loc+" nos gustaría confirmar su cita el día de "+this.selectedEvent.start2+" por favor presione el siguiente link para confirmar. "+link+" Agrega a Allure en  tus contactos y podrá ver nuestras promociones del mes✨ aprovecha y consulte ✨ Este mensaje fue generado de forma automatica, por favor no responda ya que no obtendrá respuesta" ;
			

		},
		EnviarWhatsApp(){
        var request = require('request'); //bash: npm install request
        // URL for request POST /message
        var token = '62zr4v61f45hjzp8';
        var instanceId = '337326';
        var url = `https://api.chat-api.com/instance337326/message?token=62zr4v61f45hjzp8`;
        var data = {
            phone: this.codigoPais+this.selectedEvent.Telefono, // Receivers phone
            body: '➖➖➖'+this.WhatsAppMensaje+' ➖➖➖', // 
        };
        // Send a request
        request({
            url: url,
            method: "POST",
            json: data
        });
		this.Mensaje = 'Mensaje enviado correctamente';
		this.snackbar = true;
		this.PendienteEvento(this.selectedEvent);
	},
	FacturarServicios(){
		this.dialogPago = true;
		this.clienteCedula = this.selectedEvent.cedula;
		this.clienteNombre = this.selectedEvent.Cliente;
		this.clienteCorreo =this.selectedEvent.correo;
		this.vendedor = this.selectedEvent.Usuario;
		this.clienteTelefono = this.selectedEvent.Telefono;
		this.subtotal2 = parseFloat(this.Total) / 1.13;
		this.subtotal2 = this.subtotal2.toFixed(2);
		this.totalImpuesto = this.subtotal2 * 0.13;
		this.totalImpuesto = this.totalImpuesto.toFixed(2);
		let ivaTemp = 0;
		let subTemp = 0;
		for(var i = 0; i < this.detalles.length; ++i)
		{
			subTemp = parseFloat(this.detalles[i].pago) / 1.13;
			subTemp = subTemp.toFixed(2);
			//ivaTemp = subTemp * 0.13;
			//ivaTemp = ivaTemp.toFixed(2);
			if(this.detalles[i].check == true)
			{
				this.productosDatos.push(
				{
					CodigoProducto:"0001111",
					Cantidad:1, 
					UnidadMedida:"UND",
					Descripcion:this.detalles[i].descripcion,
					PrecioUnitario:this.detalles[i].pago,
					Id: this.detalles[i].id,
					MontoDescuento:0,                          
					ValorImpuesto:"13",
					Gravado:'S',
					CodigoHacienda: '6311100000000',
					EstadoImp: true,
					NumeroMM:"",
					TipoDocumento: this.tipoFactura,
					DiasCredito: 0,
					TipoCedulaReceptor: this.clienteTipoCedula.toUpperCase()
				}
			);
			}
		}
	},
	async FacturarNew(){
		
		var self = this;
		var momentoActual = new Date(); 
		var documentoHacienda = "";
			var link = '';
		var hora = momentoActual.getHours(); 
		var minuto = momentoActual.getMinutes(); 
		var segundo = momentoActual.getSeconds();
		if (this.tipoPago == '')
		{
		this.Mensaje = 'Debe seleccionar un tipo de pago';
		this.snackbar = true;
		return;
		} 

		if (this.tipoFactura == 1)
		{
		documentoHacienda = "FACTURA ELECTRONICA";
		}
		else if (this.tipoFactura == 4)
		{
		documentoHacienda = "TIQUETE ELECTRONICO";
		}
		else if (this.tipoFactura == 8)
		{
		documentoHacienda = "RECIBO";
		}
		this.loading = true;
		let header={"Authorization" : "Bearer "};
                let configuracion= {headers : header};
                await axios.post('api/Facturas/CrearFacturaReserva',{
                    'Detalle':this.detalleFactura,
                    'Gravado': parseFloat(this.subtotal2),
                    'Exento': 0,
                    'Iv': parseFloat(this.totalImpuesto),
                    'Total': this.Total,
                    'Localidad': this.$store.state.usuario.localidad,
                    'Cajero': this.$store.state.usuario.nombre,
                    'TipoPago': this.tipoPago,
                    'Descuento': 0,
                    'ImpServicio': 0,
                    'MontoExpress': 0,
                    'SubTotal': this.subtotal2,
                    'MontoExpres': 0,
                    'TipoDocumento': documentoHacienda,
                    'Nocomprobante': 0,
                    'NoNotaCredito': "",
                    'CondicionVenta': this.CondicionVenta,
                    'Correo2': this.clienteCorreo2,
                    'ClaveComprobante': 0,
                    'Moneda': this.monedaPago.toUpperCase(),//USD O CRC
                    'RefFacturaReal': "",
                    'RefComprobante': "",
                    'razonReferencia': "",
                    'MensajeRespuesta': "",
                    'NombreReceptor': this.clienteNombre,
                    'CedulaReceptor': this.clienteCedula,
                    'CorreoReceptor': this.clienteCorreo,
                    'TelefonoReceptor': this.clienteTelefono,
                    'Razon': "",
                    'RefFecha': "",
                    'Vuelto': 0,
                    'Impuesto1': 0,
                    'Impuesto4 ':0,
                    'Impuesto13': this.totalImpuesto,//total de Iva al 13%
                    'Impuesto2': 0,
                    'Sincronizado ': 0,
                    'Vendedor': this.vendedor,
                    'TipoCambio': '620',
                    'TotalSinR': this.Total,
                    'Hora': hora + ":" + minuto + ":" + segundo,
                    'DiasCredito': 0,
                    'Saldo': 0,
                    'Producto': this.productosDatos,
                    'Proforma': 0,
					'Motorizado':"",
					'TotalTarjeta':this.Total,
					'TotalEfectivo':0,
					'TotalSimpe':0,
                },configuracion).then(function (response) {
					alert("Factura Realizada con éxito")
					self.loading = false;	
					location.assign('/repventas'); 
                  })
                  .catch(function (error) {
					alert("Ocurrio un error de conexion, por favor verifique si la factura fue realizada")
					location.assign('/repventas'); 
                })
    },
	OrbeRequest(){
		http.onreadystatechange = function() {
			if (this.readyState == 4 && this.status == 200) {
			document.getElementById("demo").innerHTML =
			this.responseText;
			}
		};
		xhttp.open("GET", "xmlhttp_info.txt", true);
		xhttp.send();
	},
	async PagoMultiple(){
		let self = this;
		this.loading = true;
		var momentoActual = new Date(); 
		var hora = momentoActual.getHours(); 
		var minuto = momentoActual.getMinutes(); 
		var segundo = momentoActual.getSeconds();
		this.hora = hora + ":" + minuto + ":" + segundo;
		let abonoGenerar = 0;
		let saldoAbono = 0;
		let SaldoPendiente = 0;
		if(this.abono == 0){
			this.snackbar = true;
			this.Mensaje = 'Debe digitar un abono para continuar';
			this.loading = false;
			return
		}else
		if(this.tipoPago == 0){
			this.snackbar = true;
			this.Mensaje = 'Debe Digitar método de pago';
			this.loading = false;
			return
		}else
		if(parseFloat(this.abono) > parseFloat(this.total)){
		alert("Abono no puede ser mayor que saldo pendiente")
		this.loading = false;
		return
		} 
		saldoAbono= parseFloat(this.abono);
		for(var i=0;i<this.Cxc.length;i++){
			if(this.Cxc[i].check == true)
			{	
				this.cliente= self.Cxc[i].nombre
				this.detalles.push(self.Cxc[i]);
				if(saldoAbono > parseFloat(self.Cxc[i].credito) ){
					abonoGenerar = parseFloat(self.Cxc[i].credito)
					saldoAbono = saldoAbono - self.Cxc[i].credito 
					SaldoPendiente = 0;
				} else
				{ 
					abonoGenerar = parseFloat(saldoAbono)
					saldoAbono = parseFloat(self.Cxc[i].credito) - abonoGenerar;
					SaldoPendiente = self.Cxc[i].credito - abonoGenerar;
				}

				if(saldoAbono >= 0 ){
					let header={"Authorization" : "Bearer "};
					let configuracion= {headers : header};
					await axios.post('api/RepVentasVistas/AbonoCredito',{
						'SaldoAnterior': self.Cxc[i].credito,
						'Abono': abonoGenerar,
						'Saldo': SaldoPendiente,
						'TipoPago': self.tipoPago,
						'ComprobantePago':self.comprobantedeposito,
						'Banco':self.banco,
						'Señas':self.señas,
						'Factura':self.Cxc[i].factura ,
						'NombreCliente': self.Cxc[i].nombre ,
						'CedulaCliente': self.Cxc[i].idCliente ,
						'Usuario': self.$store.state.usuario.nombre,
						'FechaCompra': self.Cxc[i].fecha,
						'HoraCompra': self.hora,
						'Localidad':self.$store.state.usuario.localidad,
					},configuracion).then(function (response) {
						alert("Se abonó "+abonoGenerar+" a la factura: "+self.Cxc[i].factura+" Realizado, Saldo para el siguiemte: "+saldoAbono)
						
					})
					.catch(function (error) {
						alert("Error en el Abono, Notifique a su técnico "+ error);
						self.loading = false;
					})
				}
			}
		}
		self.Mensaje ='Abono éxitoso';
		self.snackbar = true;
		self.finishOrder();
		self.loading = false;
		self.Cxc = [];
		self.abono = 0;
	},
	getColor (mensajeRespuesta) {
		if (mensajeRespuesta == 'Pendiente') return 'indigo lighten-4'
		if(mensajeRespuesta == 'Facturado') return 'light-green lighten-4'
		else  return 'error'
	},
	getColorText (mensajeRespuesta) {
		if (mensajeRespuesta == 'Pendiente') return 'indigo darken-4'
		if(mensajeRespuesta == 'Facturado') return 'light-green darken-4'
		else  return 'white'
	},
	finishOrder(){
		var divContents = document.getElementById("bill").innerHTML;
		var a = window.open('', '', 'height=500, width=500');
		a.document.write('<html>');
		a.document.write(divContents);
		a.document.write('</body></html>');
		a.print();
	},
	asignarReservas(items){
		this.vendedor = items.Salesperson;
		this.nombreFantasia	= items.Room;
		this.tipoPago = items.Payment;
		this.start = items.CheckIn.substring(0,10);
		this.end= items.CheckOut.substring(0,10);
		this.agencia = items.Booking;
		this.CantPersonas = items.Adults;
		this.hora = "15:00";
		this.hora2 = "10:00";
		this.cedula = items.idcliente;
		this.clienteCedula = items.idcliente;
		this.clienteTelefono = items.phone;
		this.clienteCorreo = items.mail;
		this.nombre = items.Guest;
		this.telefono = items.phone;
		this.agencia = items.Booking;
		this.correo = items.mail;
		this.tipoPago = 0;
		this.servicio = items.Room;
		this.precio = items.total;
		this.idcliente = items.Guest;
		this.CodigoOta = items.ResCode;
		this.NoFactura = items.NoFactura,
		this.dialog = true;
		this.dialogCliente = true;
		this.id = items.id;
	},
	async select(){
		let me=this;
		var clientesArray=[];
		let header={"Authorization" : "Bearer "};
		let configuracion= {headers : header};
			axios.get('api/Empleados/SelectEmpleadosLocalidad/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
			clientesArray=response.data;
			clientesArray.map(function(x){
				me.clientes.push({text: (x.nombre+' | '+x.nombreFantasia), value:x.nombre , telefono:x.telefono});
			});
		})
	},
		
		setToday () {
			this.focus = this.today
		},
		SelectorColorBloqueo() {
			
		},
	calcularFechas()
	{
		var date_1 = new Date(this.start);
		var date_2 = new Date(this.end);

		var day_as_milliseconds = 86400000;
		var diff_in_millisenconds = date_2 - date_1;
		var diff_in_days = diff_in_millisenconds / day_as_milliseconds;
		this.cantidad = diff_in_days;
	},
		
	PrintDetails(){
      /*var divContents = document.getElementById("invoice").innerHTML;
      var a = window.open('', '', 'height=1200, width=850');
      a.document.write('<html>');
      a.document.write(divContents);
      a.document.write('</body></html>');
      a.document.close();
      a.print();
	  a.window.close();*/

		var win = window.open('', '', 'height=1200, width=850');
		win.document.write(`<html lang="en">
			<head>
			<style scoped>

				body {
				background: #e7e9ed;
				color: #535b61;
				font-family: "Poppins", sans-serif;
				font-size: 14px;
				line-height: 22px;
				}

				form {
				padding: 0;
				margin: 0;
				display: inline;
				}

				img {
				vertical-align: inherit;
				}

				a, a:focus {
				color: #0071cc;
				text-decoration: none;
				-webkit-transition: all 0.2s ease;
				transition: all 0.2s ease;
				}

				a:hover, a:active {
				color: #005da8;
				-webkit-transition: all 0.2s ease;
				transition: all 0.2s ease;
				}

				a:focus, a:active,
				.btn.active.focus,
				.btn.active:focus,
				.btn.focus,
				.btn:active.focus,
				.btn:active:focus,
				.btn:focus,
				button:focus,
				button:active {
				outline: none;
				}

				p {
				line-height: 1.9;
				}

				blockquote {
				border-left: 5px solid #eee;
				padding: 10px 20px;
				}

				iframe {
				border: 0 !important;
				}

				h1, h2, h3, h4, h5, h6 {
				color: #0c2f54;
				}


				table {
					border-collapse: collapse;
					border-spacing: 0;
					width: 100%;
					border: 1px solid #ddd;
					border-radius: 5px;
					color: #535b61;
				}

				th, td {
				text-align: left;
				padding: 16px;
				border: 1px solid #ddd;
				}

				tr:nth-child(even) {
				background-color: #f2f2f2;
				}

				/* =================================== */
				/*  Helpers Classes
				/* =================================== */
				/* Border Radius */
				.rounded-top-0 {
				border-top-left-radius: 0px !important;
				border-top-right-radius: 0px !important;
				}

				.rounded-bottom-0 {
				border-bottom-left-radius: 0px !important;
				border-bottom-right-radius: 0px !important;
				}

				.rounded-left-0 {
				border-top-left-radius: 0px !important;
				border-bottom-left-radius: 0px !important;
				}

				.rounded-right-0 {
				border-top-right-radius: 0px !important;
				border-bottom-right-radius: 0px !important;
				}

				/* Text Size */
				.text-0 {
				font-size: 11px !important;
				font-size: 0.6875rem !important;
				}

				.text-1 {
				font-size: 12px !important;
				font-size: 0.75rem !important;
				}

				.text-2 {
				font-size: 14px !important;
				font-size: 0.875rem !important;
				}

				.text-3 {
				font-size: 16px !important;
				font-size: 1rem !important;
				}

				.text-4 {
				font-size: 18px !important;
				font-size: 1.125rem !important;
				}

				.text-5 {
				font-size: 21px !important;
				font-size: 1.3125rem !important;
				}

				.text-6 {
				font-size: 24px !important;
				font-size: 1.50rem !important;
				}

				.text-7 {
				font-size: 28px !important;
				font-size: 1.75rem !important;
				}

				.text-8 {
				font-size: 32px !important;
				font-size: 2rem !important;
				}

				.text-9 {
				font-size: 36px !important;
				font-size: 2.25rem !important;
				}

				.text-10 {
				font-size: 40px !important;
				font-size: 2.50rem !important;
				}

				.text-11 {
				font-size: calc(1.4rem + 1.8vw) !important;
				}
				@media (min-width: 1200px) {
				.text-11 {
					font-size: 2.75rem !important;
				}
				}

				.text-12 {
				font-size: calc(1.425rem + 2.1vw) !important;
				}
				@media (min-width: 1200px) {
				.text-12 {
					font-size: 3rem !important;
				}
				}

				.text-13 {
				font-size: calc(1.45rem + 2.4vw) !important;
				}
				@media (min-width: 1200px) {
				.text-13 {
					font-size: 3.25rem !important;
				}
				}

				.text-14 {
				font-size: calc(1.475rem + 2.7vw) !important;
				}
				@media (min-width: 1200px) {
				.text-14 {
					font-size: 3.5rem !important;
				}
				}

				.text-15 {
				font-size: calc(1.5rem + 3vw) !important;
				}
				@media (min-width: 1200px) {
				.text-15 {
					font-size: 3.75rem !important;
				}
				}

				.text-16 {
				font-size: calc(1.525rem + 3.3vw) !important;
				}
				@media (min-width: 1200px) {
				.text-16 {
					font-size: 4rem !important;
				}
				}

				.text-17 {
				font-size: calc(1.575rem + 3.9vw) !important;
				}
				@media (min-width: 1200px) {
				.text-17 {
					font-size: 4.5rem !important;
				}
				}

				.text-18 {
				font-size: calc(1.625rem + 4.5vw) !important;
				}
				@media (min-width: 1200px) {
				.text-18 {
					font-size: 5rem !important;
				}
				}

				.text-19 {
				font-size: calc(1.65rem + 4.8vw) !important;
				}
				@media (min-width: 1200px) {
				.text-19 {
					font-size: 5.25rem !important;
				}
				}

				.text-20 {
				font-size: calc(1.7rem + 5.4vw) !important;
				}
				@media (min-width: 1200px) {
				.text-20 {
					font-size: 5.75rem !important;
				}
				}

				.text-21 {
				font-size: calc(1.775rem + 6.3vw) !important;
				}
				@media (min-width: 1200px) {
				.text-21 {
					font-size: 6.5rem !important;
				}
				}

				.text-22 {
				font-size: calc(1.825rem + 6.9vw) !important;
				}
				@media (min-width: 1200px) {
				.text-22 {
					font-size: 7rem !important;
				}
				}

				.text-23 {
				font-size: calc(1.9rem + 7.8vw) !important;
				}
				@media (min-width: 1200px) {
				.text-23 {
					font-size: 7.75rem !important;
				}
				}

				.text-24 {
				font-size: calc(1.95rem + 8.4vw) !important;
				}
				@media (min-width: 1200px) {
				.text-24 {
					font-size: 8.25rem !important;
				}
				}
				.text-25 {
				font-size: calc(2.025rem + 9.3vw) !important;
				}
				@media (min-width: 1200px) {
				.text-25 {
					font-size: 9rem !important;
				}
				}
				/* Line height */
				.line-height-07 {
				line-height: 0.7 !important;
				}

				.line-height-1 {
				line-height: 1 !important;
				}

				.line-height-2 {
				line-height: 1.2 !important;
				}

				.line-height-3 {
				line-height: 1.4 !important;
				}

				.line-height-4 {
				line-height: 1.6 !important;
				}

				.line-height-5 {
				line-height: 1.8 !important;
				}

				/* Font Weight */
				.fw-100 {
				font-weight: 100 !important;
				}

				.fw-200 {
				font-weight: 200 !important;
				}

				.fw-300 {
				font-weight: 300 !important;
				}

				.fw-400 {
				font-weight: 400 !important;
				}

				.fw-500 {
				font-weight: 500 !important;
				}

				.fw-600 {
				font-weight: 600 !important;
				}

				.fw-700 {
				font-weight: 700 !important;
				}

				.fw-800 {
				font-weight: 800 !important;
				}

				.fw-900 {
				font-weight: 900 !important;
				}

				/* Opacity */
				.opacity-0 {
				opacity: 0;
				}

				.opacity-1 {
				opacity: 0.1;
				}

				.opacity-2 {
				opacity: 0.2;
				}

				.opacity-3 {
				opacity: 0.3;
				}

				.opacity-4 {
				opacity: 0.4;
				}

				.opacity-5 {
				opacity: 0.5;
				}

				.opacity-6 {
				opacity: 0.6;
				}

				.opacity-7 {
				opacity: 0.7;
				}

				.opacity-8 {
				opacity: 0.8;
				}

				.opacity-9 {
				opacity: 0.9;
				}

				.opacity-10 {
				opacity: 1;
				}

				/* Background light */
				.bg-light {
				background-color: #FFF !important;
				}

				.bg-light-1 {
				background-color: #f9f9fb !important;
				}

				.bg-light-2 {
				background-color: #f8f8fa !important;
				}

				.bg-light-3 {
				background-color: #f5f5f5 !important;
				}

				.bg-light-4 {
				background-color: #eff0f2 !important;
				}

				.bg-light-5 {
				background-color: #ececec !important;
				}

				hr {
				opacity: 0.15;
				}

				.card-header {
				padding-top: .75rem;
				padding-bottom: .75rem;
				}

				/* Table */
				.table > :not(:last-child) > :last-child > * {
				border-bottom-color: inherit;
				}

				.table:not(.table-sm) > :not(caption) > * > * {
				padding: 0.75rem;
				}

				.table-sm > :not(caption) > * > * {
				padding: 0.3rem;
				}

				@media print {
				.table td, .table th {
					background-color: transparent !important;
				}

				.table td.bg-light, .table th.bg-light {
					background-color: #FFF !important;
				}

				.table td.bg-light-1, .table th.bg-light-1 {
					background-color: #f9f9fb !important;
				}

				.table td.bg-light-2, .table th.bg-light-2 {
					background-color: #f8f8fa !important;
				}

				.table td.bg-light-3, .table th.bg-light-3 {
					background-color: #f5f5f5 !important;
				}

				.table td.bg-light-4, .table th.bg-light-4 {
					background-color: #eff0f2 !important;
				}

				.table td.bg-light-5, .table th.bg-light-5 {
					background-color: #ececec !important;
				}
				}
				/* =================================== */
				/*  Layouts
				/* =================================== */
				.invoice-container {
				margin: 15px auto;
				padding: 70px;
				max-width: 850px;
				background-color: #fff;
				border: 1px solid #ccc;
				-moz-border-radius: 6px;
				-webkit-border-radius: 6px;
				-o-border-radius: 6px;
				border-radius: 6px;
				}

				@media (max-width: 767px) {
				.invoice-container {
					padding: 35px 20px 70px 20px;
					margin-top: 0px;
					border: none;
					border-radius: 0px;
				}
				}
				/* =================================== */
				/*  Extras
				/* =================================== */
				.bg-primary, .badge-primary {
				background-color: #0071cc !important;
				}

				.bg-secondary {
				background-color: #0c2f55 !important;
				}

				.text-secondary {
				color: #0c2f55 !important;
				}

				.text-primary {
				color: #0071cc !important;
				}

				.btn-link {
				color: #0071cc;
				}

				.btn-link:hover {
				color: #005da8 !important;
				}

				.border-primary {
				border-color: #0071cc !important;
				}

				.border-secondary {
				border-color: #0c2f55 !important;
				}

				.btn-primary {
				background-color: #0071cc;
				border-color: #0071cc;
				}
				.btn-primary:hover {
				background-color: #005da8;
				border-color: #005da8;
				}

				.btn-secondary {
				background-color: #0c2f55;
				border-color: #0c2f55;
				}

				.btn-outline-primary {
				color: #0071cc;
				border-color: #0071cc;
				}
				.btn-outline-primary:hover {
				background-color: #0071cc;
				border-color: #0071cc;
				color: #fff;
				}

				.btn-outline-secondary {
				color: #0c2f55;
				border-color: #0c2f55;
				}
				.btn-outline-secondary:hover {
				background-color: #0c2f55;
				border-color: #0c2f55;
				color: #fff;
				}

				.progress-bar,
				.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
				background-color: #0071cc;
				}

				.page-item.active .page-link,
				.custom-radio .custom-control-input:checked ~ .custom-control-label:before,
				.custom-control-input:checked ~ .custom-control-label::before,
				.custom-checkbox .custom-control-input:checked ~ .custom-control-label:before,
				.custom-control-input:checked ~ .custom-control-label:before {
				background-color: #0071cc;
				border-color: #0071cc;
				}

				.list-group-item.active {
				background-color: #0071cc;
				border-color: #0071cc;
				}

				.page-link {
				color: #0071cc;
				}
				.page-link:hover {
				color: #005da8;
				}

				/* Pagination */
				.page-link {
				border-color: #f4f4f4;
				border-radius: 0.25rem;
				margin: 0 0.3rem;
				}

				.page-item.disabled .page-link {
				border-color: #f4f4f4;
				}

			</style>
			</head>
			<body>`);
		win.document.write($("#invoice").html());
		win.document.write('</body></html>');
		setTimeout(function(){  win.print(); }, 3000); 
	},
		async CrearBloqueo(){
			if(this.start == null){
				this.Mensaje =  "Debe seleccionar fecha inicial"
				this.snackbar = true;
				return;
			} else
			if(this.end == null){
				this.Mensaje = "Debe seleccionar fecha final"
				this.snackbar = true;
				return;
			} else
			if(this.barbero == null){
				this.Mensaje = "Debe seleccionar Servicio o Habitación"
				this.snackbar = true;
				return;
			}else
			if(this.Bloqueo == null){
				this.Mensaje = "Debe seleccionar Tipo de bloqueo"
				this.snackbar = true;
				return;
			}
			let date1 = new Date(this.start + ' 06:00' )
			date1 = date1.getTime();
			let date2 = new Date(this.end + ' 22:00' )
			date2 = date2.getTime();
			let self = this;	
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			await axios.post('api/Reservas/Crear',{
				'name':"Bloqueo - " + this.Bloqueo.text,
				'filial':this.$store.state.usuario.nombre,
				'start':date1,
				'start2': this.start + ' 06:00' ,
				'end':date2,
				'end2':this.start + ' 22:00',
				'Localidad':this.Loc,
				'Telefono':0,
				'Cedula': 0,
				'Capacidad': "0",
				'Cliente':this.Bloqueo.text,
				'Estado':'Bloqueo',
				'Correo':"",
				'IdMultiple':"0",
				'Precio':0,
				'Usuario':this.$store.state.usuario.nombre,
				'category':this.barbero,
				'color':this.Bloqueo.color,
				'detalle':this.details,
				'NoReserva':0,
				'CodigoOta':this.CodigoOta,
			},configuracion).then(function (response) {
				self.loading = false;
				self.dialogblock = false;
				self.dialogorbe = false;
				self.getEvents2();
			
			}).catch(function(error){
				self.Mensaje = "Problema creando reserva,";
				self.snackbar = true;
				
			}); 
		},
		ValidarOverBooking(){
			for(var i = 0; i < this.events.length; ++i)
				{	
					if(this.events[i].start2.substring(0, 10) == this.start && this.events[i].category == this.barbero ){
						if(confirm('Ya existe una reserva este día, desea continuar?')){
							return true;
						}
						return false;
					}
				}
			return true;
		},
		ValidarReserva(){
			for(var i = 0; i < this.events.length; ++i)
				{	
					if(this.events[i].start2.substring(0, 10) == this.start && this.events[i].category == this.barbero ){
						this.alerta = true
						return;
					}
				}
			this.alerta = false;
			return;
		},
		ValidarDatos(){
			if(this.clienteCedula == ''){
				this.Mensaje =  "Debe seleccionar un cliente cargado"
				this.snackbar = true;
				return true;
			} else
			if(this.start == null){
				this.Mensaje =  "Debe seleccionar fecha inicial"
				this.snackbar = true;
				return true;
			} else
			if(this.end == null){
				this.Mensaje = "Debe seleccionar fecha final"
				this.snackbar = true;
				return true;
			} else
			if(this.barbero == null){
				this.Mensaje = "Debe seleccionar Servicio o Habitación"
				this.snackbar = true;
				return true;
			}else
			if(this.hora == null || this.hora2 == ''){
				this.Mensaje = "Debe seleccionar la hora de inicio"
				this.snackbar = true;
				return true;
			} else
			if(this.hora2 == null || this.hora2 == ''){
				this.Mensaje = "Debe seleccionar la hora de salida"
				this.snackbar = true;
				return true;
			}else
			if(this.IdMultiple  == ''){
				this.Mensaje = "Debe habitación para el cargo"
				this.snackbar = true;
				return true;
			}
			return false;
		},
		async AddMultiple(){	
			var clientePrevio="";
				if (this.idcliente.value === undefined)
			{
				clientePrevio = this.idcliente;
			}
			else
			{
				clientePrevio = this.idcliente.value;
			}
			var servicio="";
				if (this.servicio.text === undefined)
			{
				servicio = this.servicio;
			}
			else
			{
				servicio = this.servicio.text;
			}
			if(this.barbero == null){
				this.Mensaje = "Debe seleccionar una habitación para continuar";
				this.snackbar = true;
				return;
			}
			if(this.ValidarDatos()){
				return;
			}
			if(this.ValidarOverBooking()){

			this.loading = true;
			let barcodepreview = Date.now();
			let momentoActual = barcodepreview.toString();
			let date1 = null;
			let date2 = null;
			
			date1 = new Date(this.start + ' ' + this.hora)
			date1 = date1.getTime();

			date2 = new Date(this.end + ' ' + this.hora2)
			date2 = date2.getTime();
		
			
			let self = this;	
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			await axios.post('api/Reservas/Crear',{
				'name':clientePrevio + " - " + servicio,
				'filial':this.$store.state.usuario.nombre,
				'start':date1,
				'start2': this.start + ' ' + this.hora,
				'end':date2,
				'end2':this.end + ' ' + this.hora2,
				'Localidad':this.Loc,
				'Telefono':this.clienteTelefono,
				'Capacidad': this.restaFechas,
				'Cliente':clientePrevio,
				'Estado':'Check In',
				'Correo':this.clienteCorreo,
				'IdMultiple':this.IdMultiple,
				'Usuario':this.$store.state.usuario.nombre,
				'category':this.barbero,
				'color':"brown",
				'detalle':this.details,
				'Agencia':this.agencia,
				'Pago':this.tipoPago,
				'Precio':this.precioVenta,
				'Vendedor':this.vendedor,
				'Personas':this.CantPersonas,
				'TourOperador':this.TourOperador,
				'CedulaReceptor':this.clienteCedula,
				'diasCredito':15,
				'Cedula':this.clienteCedula,
				'Comprobante':this.cabys,
				'CodigoOta':this.CodigoOta,
				'EstadoFacturacion':"Pendiente",
			},configuracion).then(function (response) {
				self.loading = false;
				self.dialog = false;
				self.dialogorbe = false;
				self.Mensaje = "Reserva Creada con éxito";
				self.getEvents2();
				self.LimpiarReserva();
			}).catch(function(error){
				alert("Error creando reserva, reporte este incidente a su equipo de soporte: "+error)
				self.dialog= false;
				self.loading = false;
			}); 
			} else
			this.Mensaje = "Ya existe una reserva con esta fecha, por favor valide el Check in en otra habitación";
			this.snackbar = true;
		},
		LimpiarReserva(){
			this.agencia = '';
			this.start = '';
			this.end = '';
			this.agencia = '';
			this.TourOperador ='';
			this.barbero = '';
			this.vendedor ='';
			this.clienteNombre = '';
			this.idcliente = '';
			this.id = 0,
			this.clienteCedula = '';
			this.filial = '';
			this.barbero = '';
			this.IdMultiple = '';
			this.codigoOta = '';
			this.precio = 0;
			this.tipoPago = 0;
			this.CantPersonas =1;
			this.name = '';
			this.cantidad = 1;
			this.id = 0;
			this.CodigoOta = '';
			this.servicio = '';
		},
		MostrarRelaciones(ev){
			this.detalles = [];
			var FechaInicio = "";
			//var FechaInicio2 = ev.start2.substring(0,10);
			var FechaInicio2 = ev.Fecha;
			if(ev.detalle.substring(0, 18) != "Bloqueo de Horario")
			{
				for(var i = 0; i < this.events.length; ++i)
				{	
					if(typeof this.events[i].start2 != 'undefined' && this.events[i].start2 ){

					
						FechaInicio = this.events[i].Fecha
						if(ev.IdMultiple == this.events[i].IdMultiple)
						{	
							this.detalles.push({
							descripcion:  this.events[i].name,
							prestador: this.events[i].category,
							Fecha: this.events[i].start2,
							id: this.events[i].id,
							comentario: this.events[i].detalle,
							pago: this.events[i].precio,
							posicion: i,
							personas:this.events[i].personas,
							Estado:this.events[i].Estado,
							EstadoFacturacion:this.events[i].EstadoFacturacion,
							check: false,
						})
						}
					}	
				}
			}
		},
		async AsignarEvento () {
			if(this.barbero == ''){
				this.Mensaje = "Debe Seleccionar una habitación";
				this.snackbar = true;
				return;
			}else
			if(this.IdMultiple == ''){
				this.Mensaje = "Debe Seleccionar realizar el cargo";
				this.snackbar = true;
				return;
			}
			this.loading = true;

			let date1 = null;
			let date2 = null;
			
			date1 = new Date(this.start + ' ' + this.hora)
			date1 = date1.getTime();

			date2 = new Date(this.end + ' ' + this.hora2)
			date2 = date2.getTime();

			var self = this;	
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			await axios.post('api/Reservas/AsignarReserva',{
				'CodigoOta': this.CodigoOta,
				'category': this.barbero,
				'IdMultiple':this.IdMultiple,
				'start':date1,
				'Id':this.id,
				'start2': this.start + ' ' + this.hora,
				'end':date2,
				'end2':this.end + ' ' + this.hora2,
				'Localidad':this.Loc,
				'name':this.servicio,
				'Agencia':this.agencia,
				'Pago':this.tipoPago,
				'Precio':this.precioVenta,
				'NoFactura':this.NoFactura,
				'Usuario':this.$store.state.usuario.nonmbre,
			},configuracion).then(function (response) {
			self.Mensaje = "Asignado correctamente";
			self.snackbar = true;
			self.dialogorbe = false;
			self.dialog = false;
			self.loading = false;
			self.getEvents2();
			}).catch(function(error){
			self.Mensaje = "Error actualizando, por favor refresque el sitio y verifique si la cita esta donde corresponde. "+error;
			self.snackbar = true;
			self.loading = false;}); 

		},
		async PendienteEvento (ev) {
			if(ev.Estado == "Pendiente de revisión"){
				this.ActualizarReservado(ev);
				return;
			}
			if(confirm("Seguro Poner en pendiente las citas de este cliente?")){
					var self = this;	
					let header={"Authorization" : "Bearer "};
					let configuracion= {headers : header};
					await axios.post('api/Reservas/ActualizarEstado',{
						'Id': ev.id,
						'Estado': "Pendiente de revisión",
						'color': '#C8B81F'
					},configuracion).then(function (response) {
					self.Mensaje = "Actualizado correctamente";
					self.snackbar = true;
					self.getEvents2();
					self.selectedOpen = false;
					}).catch(function(error){
					self.Mensaje = "Error actualizando, por favor refresque el sitio y verifique si la cita esta donde corresponde. "+error;
					self.snackbar = true;}); 
				
			}   
		},
		async CheckIn (ev) {
			if(ev.Estado == "Pendiente de revisión"){
				this.ActualizarReservado(ev);
				return;
			}
			if(confirm("Seguro Desea Realizar Check in?")){
					let self = this;
					let header={"Authorization" : "Bearer "};
					let configuracion= {headers : header};
					await axios.post('api/Reservas/ActualizarEstado',{
						'Id': ev.id,
						'Estado': "In House",
						'color': '#C8B81F'
					},configuracion).then(function (response) {
					self.Mensaje = "Actualizado correctamente";
					self.snackbar = true;
					self.getEvents2()
					self.selectedOpen = false;
					}).catch(function(error){
					self.Mensaje = "Error actualizando, por favor refresque el sitio y verifique si la cita esta donde corresponde. "+error;
					self.snackbar = true;}); 

			}   
		},
		async CheckOut (ev) {
			if(ev.Estado == "Pendiente de revisión"){
				this.ActualizarReservado(ev);
				return;
			}
			if(this.Cxc.length > 0){
				this.Mensaje = "No puede realizar Check Out con cuentas Pendientes";
				this.snackbar = true;
				return;
			}
			if(ev.EstadoFacturacion == "Pendiente"){
				this.Mensaje = "Debe Facturar esta reserva para continuar"
				this.snackbar = true;
				return;
			}
			if(confirm("Seguro Desea Realizar Check Out?")){
					let self = this;
					let header={"Authorization" : "Bearer "};
					let configuracion= {headers : header};
					await axios.post('api/Reservas/ActualizarEstado',{
						'Id': ev.id,
						'Estado': "Check Out",
						'color': '#546E7'
					},configuracion).then(function (response) {
					self.Mensaje = "Actualizado correctamente";
					self.snackbar = true;
					self.getEvents2()
					self.selectedOpen = false;
					}).catch(function(error){
					self.Mensaje = "Error actualizando, por favor refresque el sitio y verifique si la cita esta donde corresponde. "+error;
					self.snackbar = true;}); 

			}   
		},
		async ActualizarReservado(ev){
			for(var i = 0; i < this.detalles.length; ++i)
				{	
					let header={"Authorization" : "Bearer "};
					let configuracion= {headers : header};
					await axios.post('api/Reservas/ActualizarEstado',{
						'Id': this.detalles[i].id,
						'Estado': "Reservado",
						'color': 'indigo'
					},configuracion).then(function (response) {
					self.Mensaje = "Actualizado correctamente";
					self.snackbar = true;
					self.selectedOpen = false;
					self.getEvents2();
					}).catch(function(error){
					self.Mensaje = "Error actualizando, por favor refresque el sitio y verifique si la cita esta donde corresponde. "+error;
					self.snackbar = true;}); 

					this.events[this.detalles[i].posicion].Estado = 'Reservado';
					this.events[this.detalles[i].posicion].color = 'indigo';
					
				}
		},
		obtenerCliente(){
			var self = this;
			var clientesArray=[];
			var clientePrevio="";
			if (this.idcliente.text === undefined)
			{
				clientePrevio = this.idcliente;
			}
			else
			{
				clientePrevio = this.idcliente.value;
			}
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			axios.get('api/Empleados/ObtenerCliente', { params: {'clienteFactura': clientePrevio}},configuracion).then(function(response){
				clientesArray=response.data;
				for(var i = 0; i < clientesArray.length; ++i)
				{
				self.clienteNombre = clientesArray[i].nombre;
				self.clienteCorreo = clientesArray[i].correo;
				self.clienteCedula = clientesArray[i].noEmpleado;
				self.clienteTipoCedula = clientesArray[i].tipoCedula;
				self.clienteTelefono = clientesArray[i].telefono;
				self.celular = clientesArray[i].celular;
				self.nombreFantasia = clientesArray[i].nombreFantasia;
				break;
				} 
			})
		},
		ActualizarCliente(){
			let me = this;
			this.loading = true;
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			axios.put('api/Empleados/Actualizar',{
					'Nombre':this.nombre,
					'TipoCedula':this.tipoDocumento,
					'Cedula': this.cedula,
					'NoEmpleado': this.cedula,
					'Telefono':this.telefono,
					'Periodo': 0,
					'Correo':this.correo,
					'Correo2': this.clienteCorreo2,
					'Edad':0,
					'Fecha':"",
					'ListaPrecio': "Venta",
					'Genero':"",
					'Fecha': this.start,
					'Cantidad':0,
					'Facebook':"",
					'Vendedor':"this.vendedor",
					'NombreFantasia':this.nombreFantasia,
					'Celular':this.celular,
			},configuracion).then(function(response){
				me.snackbar = true;
				me.Mensaje = "Cliente Actualizado con éxito, por favor seleccionelo nuevamente"; 
				me.clienteExiste = false;        
				me.dialogCliente = false;
				me.idcliente = "";  
				me.loading = false;
				me.clientes = [];        
				me.select();
			}).catch(err => {
				me.loading =false;
				//console.log(err.response);
				if (err.response.status==400){
					me.error="Correo ocupado por otro cliente";
				} else if (err.response.status==404){
					me.error="Cédula Ocupada por otro cliente";
				}else{
					me.error="Validar Campos Vacios";
				}
			});
		},
		async BuscarOrbe(xml){
			var parser, xmlDoc;
			let text = xml;
			
			parser = new DOMParser();
			xmlDoc = parser.parseFromString(text,"text/xml");

			var xmlrows = $(xmlDoc).find("RESERVATION"); 
			for (var i = 0; i < xmlrows.length; i++) {  
				var resCode = $(xmlrows[i]).find("Res_Code");  
				var resCode1 = $(resCode[0]).text(); 

				var name = $(xmlrows[i]).find("Global_Name");  
				var name1 = $(name[0]).text(); 

				var surname = $(xmlrows[i]).find("Global_Surname");  
				var surname2 = $(surname[0]).text(); 

				var mail = $(xmlrows[i]).find("Global_email");  
				var mail2 = $(mail[0]).text(); 

				var detalle = $(xmlrows[i]).find("Global_Obs1");  
				var detall1 = $(detalle[0]).text(); 

				var tel = $(xmlrows[i]).find("Global_Telephone1");  
				var tel1 = $(tel[0]).text(); 

				var room = $(xmlrows[i]).find("Type_Description");  
				var room1 = $(room[0]).text(); 

				var status = $(xmlrows[i]).find("Status");  
				var status1 = $(status[0]).text(); 

				var start = $(xmlDoc).find("Arrival");  
				var start1 = $(start[0]).text(); 

				var end = $(xmlrows[i]).find("Departure");  
				var end1 = $(end[0]).text(); 

				var adults = $(xmlrows[i]).find("Adults");  
				var adults2 = $(adults[0]).text(); 

				var descuento = $(xmlrows[i]).find("Discount");  
				var descuento1 = $(descuento[0]).text(); 

				var rate = $(xmlrows[i]).find("Average_Rate");  
				var rate1 = $(rate[0]).text(); 

				var amount = $(xmlrows[i]).find("Amount");  
				var amount1 = $(amount[0]).text(); 

				var booking = $(xmlrows[i]).find("BookingChannel");  
				var booking1 = $(booking[0]).text(); 
				
				var guarCode = $(xmlrows[i]).find("Guaranty_Code");  
				var guarCode1 = $(guarCode[0]).text(); 

				var agencyCode = $(xmlrows[i]).find("Agency_Code");  
				var agencyCode1 = $(agencyCode[0]).text(); 
				
				var Salesperson = $(xmlrows[i]).find("Salesperson");  
				var Salesperson1 = $(Salesperson[0]).text(); 

				var id = $(xmlrows[i]).find("Global_ID");  
				var Global_ID = $(id[0]).text(); 
				let date1 = null;
				let date2 = null;
				this.start = start1
				this.end =end1;

				// se pregunta si el estado de la reserva es Confirmed para almacenarla como nueva reserva
				if(status1 == 'Confirmed')
				{
					// Se pasa a formato fecha la hora y se almacena en la BD
					date1 = new Date(start1 + " 15:00")
					date1 = date1.getTime();

					date2 = new Date(end1 + " 10:00")
					date2 = date2.getTime();
					this.calcularFechas();
					let self = this;	
					let header={"Authorization" : "Bearer "};
					let configuracion= {headers : header};
					await axios.post('api/Reservas/Crear',{
						'name':name1 + " "+surname2+ " - " + room1,
						'filial':this.$store.state.usuario.nombre,
						'start':date1,
						'start2': start1 + " 15:00" ,
						'end':date2,
						'end2':end1 + " 10:00",
						'Localidad':this.Loc,
						'Telefono':tel1,
						'Capacidad': this.restaFechas,
						'Cliente':name1 + " "+surname2,
						'Estado':'Check In',
						'Correo':mail2,
						'IdMultiple':"",
						'Usuario':this.$store.state.usuario.nombre,
						'category':"Pendiente",
						'color':"red",
						'detalle':detall1,
						'Agencia':booking1,
						'Pago':rate1 * this.cantidad,
						'Precio':rate1 * this.cantidad,
						'Vendedor':Salesperson1,
						'Personas':adults2,
						'TourOperador':"",
						'CedulaReceptor':Global_ID,
						'diasCredito':15,
						'Cedula':Global_ID,
						'Comentario':guarCode1,
						'CodigoOta':resCode1,
						'EstadoFacturacion':"Pendiente",
					},configuracion).then(function (response) {
						alert("Nueva Reserva de Orbe entrante el día "+start1+" por favor asignela a la suite respectiva");
					}).catch(function(error){
						alert("Error creando reserva, reporte este incidente a su equipo de soporte y cargue manualmente la reserva de "+name1+" "+surname2+error)
						
					}); 

				} else
				if(status1 == 'Cancelled'){
					var self = this;	
					let header={"Authorization" : "Bearer "};
					let configuracion= {headers : header};
					await axios.post('api/Reservas/CancelarOrbe',{
						'CodigoOta': resCode1,
						'Localidad':this.Loc,
					},configuracion).then(function (response) {
					self.Mensaje = "Asignado correctamente";
					self.snackbar = true;
					self.dialogorbe = false;
					self.dialog = false;
					self.getEvents2();
					}).catch(function(error){
					self.Mensaje = "Error actualizando, por favor refresque el sitio y verifique si la cita esta donde corresponde. "+error;
					self.snackbar = true;});
				}
			}
			

			this.dialogorbe = true;
			this.getEvents2();
		},
		GuardarOtas(){
			this.loading = true;
			let me = this;
			let body = `<ReservationsRequest>
							<Username>NoahSuitree</Username>
							<Password>Geyah8ehaugheyrq3i8</Password>
							<HotelCode>NOAHSUITREE</HotelCode>
						</ReservationsRequest>`;
			let xhr = new XMLHttpRequest();
			let url = new URL('https://capi.orbebooking.com/OAF/BOOKRET/XML');
			xhr.open("POST", url)
			xhr.send([body]);
			// 4. This will be called after the response is received
				xhr.onload = function() {
				if (xhr.status != 200) { // analyze HTTP status of the response
					alert(`Error ${xhr.status}: ${xhr.statusText}`); // e.g. 404: Not Found
				} else { // show the result
					me.Mensaje = 'Nueva Busqueda de Otas '+xhr.response.length;// response is the server response
					me.snackbar = true;
					me.BuscarOrbe(xhr.response);
				}
				};
				xhr.onprogress = function(event) {
				if (event.lengthComputable) {
					
					alert(`New Otas Finded ${event.loaded} of ${event.total} bytes`);
					me.loading = false;
				} else {
					 // no Content-Length
					me.loading = false;
				}

				};

				xhr.onerror = function() {
				alert("Error de conexión con Orbe, por favor verifique si hay reservas entrantes desde el panel orbe");
				me.loading = false;
				};

			
		},
		MarcarCxc(){
			for (var i = 0; i < this.Cxc.length; i++) {  
				this.Cxc[i].check = true;
			}
		},
		async getEvents2 () {
			var fechaactual = new Date();
			fechaactual = fechaactual.getTime();
			let me=this;
			this.events=[];
			let date1 = null;
			let date2 = null;
			var localidadesArray=[];
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			await axios.get('api/Reservas/Listar/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
				localidadesArray=response.data;
				localidadesArray.map(function(x){
					date1 = new Date(x.start)
					date1 = date1.getTime();
					date2 = new Date(x.end)
					date2 = date2.getTime();
					me.events.push({
					id: x.id,
					start: parseInt(x.start),
					start2:x.start2,
					end2:x.end2,
					end: parseInt(x.end),
					name: x.name,
					color: x.color,
					IdMultiple : x.idMultiple,
					Telefono: x.telefono,
					Cliente: x.cliente,
					category: x.category,
					Usuario: x.usuario,
					Fecha: x.fecha,
					timed: true,
					cedula:x.cedula,
					Estado: x.estado,
					agencia: x.agencia,
					detalle: x.detalle,
					comentario: x.comentario,
					pago: x.pago,
					precio: x.precio,
					vendedor: x.vendedor,
					TourOperador: x.TourOperador,
					personas:x.personas,
					correo: x.correo,
					CodigoOta: x.codigoOta,
					EstadoFacturacion: x.estadoFacturacion,
					Capacidad :x.capacidad,
					NoFactura: x.noFactura,
					})
				});
			})
			this.loading = false;
			this.ReservasOrbe = [];
			for (var i = 0; i < this.events.length; i++) {  
				if(this.events[i].category == "Pendiente")
				//Se agrega la reserva en la tabla de pendientes orbe
				this.ReservasOrbe.push({
					ResCode:this.events[i].CodigoOta ,
					Guest: this.events[i].Cliente,
					mail: this.events[i].correo,
					detalle: this.events[i].detalle,
					phone: this.events[i].Telefono,
					Room:this.events[i].name,
					state: this.events[i].Estado,
					CheckIn: this.events[i].start2,
					CheckOut: this.events[i].end2,
					Adults :this.events[i].personas,
					Descuento:0,
					total:this.events[i].pago,
					Payment: this.events[i].pago,
					Booking: this.events[i].agencia,
					Guaranty_Code: this.events[i].comentario,
					Agency_Code: this.events[i].CodigoOta,
					Salesperson: this.events[i].vendedor,
					idcliente:this.events[i].cedula,
					NoFactura:this.events[i].NoFactura,
					id:this.events[i].id,
					})
			}
			
		},
		prev () {
		this.$refs.calendar.prev()
		},
		next () {
		this.$refs.calendar.next()
		},
		validarFocus(){
			if(localStorage.getItem("focus") == null) {
					this.focus = new Date().toISOString().substr(0, 10);
					return;
				}

			this.focus = localStorage.getItem("focus");
		},
      async startTime (tms) {
        const mouse = this.toTime(tms)

        if (this.dragEvent && this.dragTime === null) {
          const start = this.dragEvent.start

          this.dragTime = mouse - start
        } else {
          	this.createStart = this.roundTime(mouse)
		  	if(this.Bloqueo == true){
				let barcodepreview = Date.now();
				let idsa = barcodepreview.toString();
				var codigoProducto = idsa.substring(5, 12);
				this.createEvent = {
					name: `Bloqueo de #${tms.category}`,
					color: "grey",
					start: this.createStart,
					end: this.createStart,
					timed: true,
					category: tms.category,
					id: 0,
					Cliente: "Sin Definir",
					detalle: "Bloqueo de Horario realizado por: "+this.$store.state.usuario.nombre+' el ' +this.today,
					Estado: "Bloqueado",
					NoReserva: codigoProducto,
				}

					this.events.push(this.createEvent)
					
					var self = this;
					let header={"Authorization" : "Bearer "};
					let configuracion= {headers : header};
					await axios.post('api/Reservas/Crear',{
						'name':`Bloqueo de #${tms.category} a las ${this.createStart}`,
						'filial':"",
						'start': this.createStart,
						'start2': this.createStart,
						'end':this.createStart,
						'end2':this.createStart,
						'Localidad':this.$store.state.usuario.localidad,
						'Telefono':"",
						'Capacidad': "0",
						'Cliente':"NO",
						'Estado':"Bloqueado",
						'Correo':"",
						'IdMultiple':"0",
						'Precio':0,
						'Pago':'0',
						'Usuario':this.$store.state.usuario.nombre,
						'category':tms.category,
						'color':"grey",
						'detalle':"Bloqueo de Horario",
						'NoReserva':codigoProducto,
						'TourOperador':this.TourOperador,
						'CedulaReceptor':this.clienteCedula,
						'diasCredito':0,
						'CodigoOta':'',
					},configuracion).then(function (response) {
					self.Mensaje = "Bloqueo ha sido insertado correctamente";
					self.snackbar = true;
					
					}).catch(function(error){
						alert('Problema insertando bloqueo, por favor actualice el sitio y verifique si quedó registrado');
					}); 
					this.Bloqueo = false;
			}
			this.dialog = true;
			this.hora = tms.hour+":00";
			this.barbero = tms.category;
			this.DiaHoy2 = tms.day;
			this.start = tms.date; 
        }
      },
      extendBottom (event) {
        this.createEvent = event
        this.createStart = event.start
        this.extendOriginal = event.end
      },
	LocalidadAsignada(){
		localStorage.setItem("loc",this.Loc)
		location.reload();
	},
	ValidarColorNum(tel){
		
		if(parseInt(tel.telefono) < 50000000){
			return "red";
		}
		
		 return "white";
	},
      mouseMove (tms) {
        const mouse = this.toTime(tms)
        if (this.dragEvent && this.dragTime !== null) {
          const start = this.dragEvent.start
          const end = this.dragEvent.end
          const duration = end - start
          const newStartTime = mouse - this.dragTime
          const newStart = this.roundTime(newStartTime)
          const newEnd = newStart + duration

          this.dragEvent.start = newStart
          this.dragEvent.end = newEnd
		  this.dragEvent.category = tms.category
		  this.updateEvent(this.dragEvent,tms)
		  
        } else if (this.createEvent && this.createStart !== null) {
          const mouseRounded = this.roundTime(mouse, false)
          const min = Math.min(mouseRounded, this.createStart)
          const max = Math.max(mouseRounded, this.createStart)
          this.createEvent.start = min
          this.createEvent.end = max
		  this.updateEvent(this.createEvent,tms)
        }
      },
	convertidorFecha(fecha){
		var date1 = new Date(fecha) // 2301248
		date1 = date1.getTime();

		return date1;
	},
	obtenerTicketExpress(ev){
		this.loading = true;
          this.name = ev.name,
          this.color = ev.color;
          this.idEvento = ev.IdMultiple;
          this.servicio = ev.category;
          this.telefono = ev.Telefono
          this.filial = ev.Cliente;
          this.barbero = ev.category;
          this.details = ev.details;
          this.detalles = ev.Participantes;
          this.start = ev.start;
          this.end = ev.end;
          this.hora = ev.start;
          this.hora2 = ev.end;
          var self = this;
          var ticketsArray=[];
          var ticketNum = 0;
          let header={"Authorization" : "Bearer "};
          let configuracion= {headers : header};
          axios.get('api/OrdenExpress/SelectTickets', configuracion).then(function(response){
              ticketsArray=response.data;
              for(var i = 0; i < ticketsArray.length; ++i)
              {
                  ticketNum = ticketsArray[i].noTicket;
                break;
              }  
              self.OrdenExpress(ticketNum,ev.IdRecurrencia);
          }).catch(function(error){
              console.log(error);
          });
    },
	
	validarLocalidad(){
		if(localStorage.getItem("loc") == null) {
			this.Loc = this.$store.state.usuario.localidad;
			
			//this.selectColumnas();
			return;
		}
		
		this.Loc = localStorage.getItem("loc");
	},
	FindFicha(){
		this.dialogFicha = true;
		this.idcliente = this.selectedEvent.Cliente;
		this.selectFicha();
	},
	OrdenExpress(factura,id){
          this.loading = true;
          var NumeroFactura = factura +3;
          var momentoActual = new Date(); 
          var hora = momentoActual.getHours(); 
          var minuto = momentoActual.getMinutes(); 
          var segundo = momentoActual.getSeconds();
          var self = this;
          for(var i = 0; i < this.detalles.length; ++i)
          {
            this.productosDatos.push(
                  {
                    Codigop:this.detalles[i].idarticulo,
                    Cantidad:this.detalles[i].cantidad,
                    UnidadMedida:"UND",
                    Detalle: "",
                    Descripcion:this.detalles[i].descripcion,
                    PrecioFinal:this.detalles[i].precio,
                    MontoDescuento:this.detalles[i].descuento,                          
                    ValorImpuesto:this.detalles[i].valorImpuesto,
                    Descuento: 0,
                    Gravado:this.detalles[i].gravado,
                    mesa: this.filial,
                  }
                  );
            }
          let header={"Authorization" : "Bearer "};
                let configuracion= {headers : header};
                axios.post('api/ordenexpress/Crear',{
                      'noFactura': NumeroFactura,
                      'fecha': this.today.toString(),//MM/dd/YYYY
                      'cajero': this.barbero,
                      'total': this.calcularTotal2,
                      'nombre': this.filial,
                      'telefono': this.telefono,
                      'direccion': this.details,
                      'orden': this.orden,
                      'tipoPago1': 'EFECTIVO',
                      'Hora': hora + ":" + minuto + ":" + segundo,
                      'descuento': this.descuento,
                      'localidad': this.Loc,
                      'estado': 0,
                      'nota': this.details,
                      'TipoCompra': "Check In",
                      'detalles': this.productosDatos,
                      'correo': 'jeff@noah-systems.net',
                      'latitud': '0',
                      'longitud': '0',
                      
                },configuracion).then(function (response) {
                    self.insertarTicket2(NumeroFactura);
                    self.productosDatos = '';
                    self.loading = false;
                    self.checkEvent(id);
                    self.selectedOpen = false;
                  })
                  .catch(function (error) {
                    alert(`Lo sentimos ha ocurrido un error, notifiuelo al 4070-1889`);  
                })
               
    },
	reset() {
        this.$refs.form.reset()
    },
	async checkEvent (id) {
      	for(var i = 0; i < this.detalles.length; ++i)
			{	
				let header={"Authorization" : "Bearer "};
				let configuracion= {headers : header};
				await axios.post('api/Reservas/ActualizarEstado',{
					'Id': this.detalles[i].id,
					'Estado': "Check In",
					'color': 'teal'
				},configuracion).then(function (response) {
				self.Mensaje = "Actualizado correctamente";
				self.snackbar = true;
				self.selectedOpen = false;
				self.getEvents2()
				}).catch(function(error){
				self.Mensaje = "Error actualizando, por favor refresque el sitio y verifique si la cita esta donde corresponde. "+error;
				self.snackbar = true;}); 

				this.events[this.detalles[i].posicion].Estado = 'Check In';
				this.events[this.detalles[i].posicion].color = 'teal';
				
			}
      
      this.Mensaje = "Check In realizado";
      this.snackbar = true;
      setTimeout(function(){  location.reload(); }, 3000); 
    
    },
	CrearFicha(){
		var clientePrevio="";
			if (this.idcliente.text === undefined)
			{
				clientePrevio = this.idcliente;
			}
			else
			{
				clientePrevio = this.idcliente.value;
			}
		var self = this;
		let header={"Authorization" : "Bearer "};
		let configuracion= {headers : header};
		axios.post('api/NotasClientes/Crear',{
			'NombreCliente':clientePrevio,
			'Localidad':this.$store.state.usuario.localidad,
			'Usuario':this.$store.state.usuario.nombre,
			'Telefono':"",
			'NotaCliente':this.NotaFicha,
		},configuracion).then(function (response) {
			self.Mensaje = "Nota ingresada correctamente";
			self.snackbar = true;
			self.selectFicha();
			self.NotaFicha = "";
		}).catch(function(error){
			alert('Problema agregando la ficha: '+error);
		}); 
	},
	EditarCliente(){
		if(this.idcliente == ""){
			this.snackbar = true;
			this.Mensaje = 'Seleccione un cliente para editarlo';
			return;
		}	
		this.clienteExiste = true;
		this.nombre = this.idcliente.value;
		this.telefono = this.clienteTelefono;
		this.cedula = this.clienteCedula;
		this.correo = this.clienteCorreo;
		this.tipoDocumento = this.clienteTipoCedula;
		this.dialogCliente = true;
	},
	insertarTicket2(ticketNum){
                let header={"Authorization" : "Bearer "};
                let configuracion= {headers : header};
                let me=this;
                axios.post('api/OrdenExpress/CrearTicket',{
                    'NoTicket':ticketNum,
                },configuracion).then(function(response){    
                  me.dialog = false;       
                }).catch(function(error){
                    console.log(error);
                });
    },
	async AgregarTour () {
		if(this.TourOperador == "" || this.TourOperador == null){
			this.Mensaje = "Debe Digitar el nombre del Tour Operador para continuar";
			this.snackbar = true;
			return;
		}
		var self = this;
		let header={"Authorization" : "Bearer "};
		let configuracion= {headers : header};
		await axios.post('api/FichaProductos',{
			'Usuario': this.$store.state.usuario.nombre,
			'Nombre':this.TourOperador,
			'Valor':"Tour Operador", 
			'Localidad':this.$store.state.usuario.localidad,
			'CodigoProducto':"77"
		},configuracion).then(function (response) {
			self.Mensaje = "Tour Operador Agregada correctamente, Seleccione nuevamente la agencia requerida";
			self.snackbar = true;
			self.SelectFichaProducto();
		}).catch(function(error){
			self.Mensaje = "Lo sentimos, error Tour Agencia. "+error;
			self.snackbar = true;}); 
    },
	async EliminarTour () {
		if(this.TourOperador == "" || this.TourOperador == null){
			this.Mensaje = "Debe Digitar el nombre de la agencia para continuar";
			this.snackbar = true;
			return;
		}
		if(confirm("Esta seguro que quiere eliminar la agencia:"+this.TourOperador)){
			var self = this;
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			await axios.delete('api/FichaProductos/'+this.TourOperador,{
				'Nombre':this.TourOperador,
			},configuracion).then(function (response) {
				self.Mensaje = "Tour Operador Eliminado correctamente, Seleccione nuevamente la agencia requerida";
				self.snackbar = true;
				self.TourOperador = '';
				self.SelectFichaProducto();
			}).catch(function(error){
				self.Mensaje = "Lo sentimos, error eliminando Tour Operador. "+error;
				self.snackbar = true;}); 
		}
		
    },
	async AgregarAgencia() {
		if(this.agencia == "" || this.agencia == null){
			this.Mensaje = "Debe Digitar el nombre de la agencia para continuar";
			this.snackbar = true;
			return;
		}
		var self = this;
		let header={"Authorization" : "Bearer "};
		let configuracion= {headers : header};
		await axios.post('api/FichaProductos',{
			'Usuario': this.$store.state.usuario.nombre,
			'Nombre':this.agencia,
			'Valor':"Agencia", 
			'Localidad':this.$store.state.usuario.localidad,
			'CodigoProducto':"7784"
		},configuracion).then(function (response) {
			self.Mensaje = "Agencia Agregada correctamente, Seleccione nuevamente la agencia requerida";
			self.snackbar = true;
			self.SelectFichaProducto();
		}).catch(function(error){
			self.Mensaje = "Lo sentimos, error Agregando Agencia. "+error;
			self.snackbar = true;}); 
    },
	async AgregarVendedor() {
		if(this.vendedor == "" || this.vendedor == null){
			this.Mensaje = "Debe Digitar el nombre del vendedor para continuar";
			this.snackbar = true;
			return;
		}
		var self = this;
		let header={"Authorization" : "Bearer "};
		let configuracion= {headers : header};
		await axios.post('api/FichaProductos',{
			'Usuario': this.$store.state.usuario.nombre,
			'Nombre':this.vendedor,
			'Valor':"Vendedor", 
			'Localidad':this.$store.state.usuario.localidad,
			'CodigoProducto':"7784"
		},configuracion).then(function (response) {
			self.Mensaje = "Vendedor Agregado correctamente";
			self.snackbar = true;
			self.SelectFichaProducto();
		}).catch(function(error){
			self.Mensaje = "Lo sentimos, error Agregando vendedor. "+error;
			self.snackbar = true;}); 
    },
	async EliminarAgencia () {
		if(this.agencia == "" || this.agencia == null){
			this.Mensaje = "Debe Digitar el nombre de la agencia para continuar";
			this.snackbar = true;
			return;
		}
		if(confirm("Esta seguro que quiere eliminar la agencia:"+this.agencia)){
			var self = this;
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			await axios.delete('api/FichaProductos/'+this.agencia,{
				'Nombre':this.agencia,
			},configuracion).then(function (response) {
				self.Mensaje = "Agencia Eliminada correctamente, Seleccione nuevamente la agencia requerida";
				self.snackbar = true;
				self.agencia = '';
				self.SelectFichaProducto();
			}).catch(function(error){
				self.Mensaje = "Lo sentimos, error eliminando Agencia. "+error;
				self.snackbar = true;}); 
		}
		
    },
	async EliminarVendedor () {
		if(this.vendedor == "" || this.vendedor == null){
			this.Mensaje = "Debe seleccinoar el vendedor para continuar";
			this.snackbar = true;
			return;
		}
		if(confirm("Esta seguro que quiere eliminar el vendedor:"+this.vendedor)){
			var self = this;
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			await axios.delete('api/FichaProductos/'+this.vendedor,{
				'Nombre':this.vendedor,
			},configuracion).then(function (response) {
				self.Mensaje = "Vendedor eliminado correctamente" ;
				self.snackbar = true;
				self.vendedor = '';
				self.SelectFichaProducto();
			}).catch(function(error){
				self.Mensaje = "Lo sentimos, error eliminando Vendedor. "+error;
				self.snackbar = true;}); 
		}
		
    },
	async updateEvent (item,tms) {
		var self = this;
		let header={"Authorization" : "Bearer "};
		let configuracion= {headers : header};
		await axios.post('api/Reservas/ActualizarDrag',{
			'Id': item.id,
			'start': item.start,
			'start2': tms.date + " : "+Math.round(parseInt(tms.time)/15)*15,
			'end':item.end,
			'category':tms.category,
		},configuracion).then(function (response) {
		self.Mensaje = "Actualizado correctamente";
		self.snackbar = true;
		
		}).catch(function(error){
			self.Mensaje = "Error actualizando, por favor refresque el sitio y verifique si la cita esta donde corresponde. "+error;
			self.snackbar = true;}); 
    },
	async updateEventEdit () {
        db.collection('chineate').doc(this.selectedEvent.id).update({
					Participantes: this.detalles,
					detalle: this.details,
				}) 
				.catch(function (error) {
				alert("Error Actualizando la BD, por favor intentelo de nuevo en unos minutos");
			})
    },
	async updateCliente (ev) {
		let self = this;
        let header={"Authorization" : "Bearer "};
		let configuracion= {headers : header};
		await axios.post('api/Reservas/ActualizarTitulo',{
			'Id': ev.id,
			'Estado': "Confirmado por"+this.$store.state.usuario.nombre +' a las: '+this.today + ' '+hora+':'+minuto+':'+segundo,
			'color': '#7CB342'
		},configuracion).then(function (response) {
		self.Mensaje = "Actualizado correctamente";
		self.snackbar = true;
		self.getEvents2();
		self.selectedOpen = false;
		}).catch(function(error){
		self.Mensaje = "Error actualizando, por favor refresque el sitio y verifique si la cita esta donde corresponde. "+error;
		self.snackbar = true;}); 

    },
	async confirmEvent (ev) {
      if(ev.Estado == "Confirmado"){
			this.ActualizarReservado(ev);
			return;
		}
		var fechaactual = new Date();
		var self = this;
		fechaactual = fechaactual.getTime();
		var momentoActual = new Date(); 
		var hora = momentoActual.getHours(); 
		var minuto = momentoActual.getMinutes(); 
		var segundo = momentoActual.getSeconds();
      if(confirm("Seguro que desea confirmar la reserva con los servicios seleccionados?")){
        	let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			await axios.post('api/Reservas/ActualizarEstado',{
				'Id': ev.id,
				'Estado': "Confirmado por"+this.$store.state.usuario.nombre +' a las: '+this.today + ' '+hora+':'+minuto+':'+segundo,
				'color': '#7CB342'
			},configuracion).then(function (response) {
			self.Mensaje = "Actualizado correctamente";
			self.snackbar = true;
			self.getEvents2();
			self.selectedOpen = false;
			}).catch(function(error){
			self.Mensaje = "Error actualizando, por favor refresque el sitio y verifique si la cita esta donde corresponde. "+error;
			self.snackbar = true;}); 

		}  
			
    },
	async NoPresentado(ev) {
		if(ev.Estado == "No Presentado"){
			this.ActualizarReservado(ev);
			return;
		}
      if(confirm("Seguro que desea cambiar el estado a no presentado?")){
        	var self = this;
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			await axios.post('api/Reservas/ActualizarEstado',{
				'Id':ev.id,
				'Estado': "No Presentado",
				'color': 'red'
			},configuracion).then(function (response) {
			self.Mensaje = "Actualizado correctamente";
			self.snackbar = true;
			self.selectedOpen = false;
			self.getEvents2();
			}).catch(function(error){
			self.Mensaje = "Error actualizando, por favor refresque el sitio y verifique si la cita esta donde corresponde. "+error;
			self.snackbar = true;}); 

		}   
    },
	async getHorarios () {
        let snapshot = await db.collection('Horarios').where('Localidad', '==', this.Loc).get()
        const events = []
        this.existe = 0;
        let nombre = "";
        snapshot.forEach(doc => {
          let appData = doc.data()
          appData.id = doc.id
          
          events.push(appData)
        })
		// se hace un ciclo para recorrer todos los Eventos de Horarios
		for(var i = 0; i < events.length; ++i)
          {	// se hac un ciclo para recorrer todo los eventos dentro del arreglo Horario
			 for(var j = 0; j < events[i].Horario.length; ++j)
          		{ 	
					nombre = events[i].name;
					this.AddHorarioEvent(events[i].Horario[j], nombre);
				}
            }
      },
	async AddHorarioEvent(Horario,nombre){
	// Obtenemos el numero de mes actual
		var d =  new Date() ,
		month = d.getMonth(),
		month2 = d.getMonth() + 1;
		let dia = Horario.Dia ;
		// Este campo almacena el numero de dia del arreglo en conteo actual
		d.setDate(dia - 2);
		// Lo primero que hacemos es preguntar si este dia esta libre el colaborador
			// Si esta libre hacemos de una vez una reserva para que el dia quede bloqueado
			if (Horario.libre === false) {
				// obtiene todos los dias del mes
				while (d.getMonth() === month || d.getMonth() == month) {
					// Me da el dia de la semana 
					month = d.getMonth()
						this.events.push(
					{
						name: 'Libre',
						filial: nombre,
						category: nombre,
						start: "2021" + "-" + (month + 1) + "-" + d.getDate() + ' ' + '08:00',
						end: "2021" + "-" +  (month + 1) +"-" + d.getDate() + ' ' + '19:00',
						Estado: 'Bloqueado por Horario',
						Fecha: "2021" + "-" + d.getDate() + "-" + (month + 1) + ' ' + '09:00',
						Capacidad : "Bloqueado",
						Precio: 0,
						Usuario: nombre,
						Cliente : nombre,
						Participantes: [],
						Localidad : this.Loc,
						Telelfono: "",
						color: "#DADADA",
						nota: "Bloqueado Por Horario",
					}
					);
					
					d.setDate(d.getDate() + 7);
				} 
			} else // si el colaborador no tiene libre asigna los rangos de horas disponibles
			if (Horario.libre === true) {
				// obtiene todos los dias del mes
				while (d.getMonth() === month) {
					// Me da el dia de la semana 
					this.events.push(
					{
						name: 'Fuera',
						filial: nombre,
						category: nombre,
						start: "2021" + "-" + (month + 1) + "-" + d.getDate() + ' ' + '07:15',
						end: "2021" + "-" +  (month + 1) +"-" + d.getDate() + ' ' + Horario.inicio,
						Estado: 'Fuera',
						Fecha: "2021" + "-" +  (month + 1) +"-" + d.getDate() + ' ' + '09:00',
						Capacidad : "Por Entrar",
						Precio: 0,
						Usuario: nombre,
						Cliente : nombre,
						Participantes: [],
						Localidad : this.Loc,
						Telelfono: "",
						color: "#DADADA",
						nota: "Disponible",
					}
					);

					this.events.push(
					{
						name: 'Salió',
						filial: nombre,
						category: nombre,
						start: "2021" + "-" + (month + 1) + "-" + d.getDate() + ' ' + Horario.fin,
						end: "2021" + "-" +  (month + 1) +"-" + d.getDate() + ' ' + '21:00',
						Estado: 'Fuera',
						Fecha: "2021" + "-" +  (month + 1) +"-" + d.getDate() + ' ' + '09:00',
						Capacidad : 'Colaborador ya salió de su jornada',
						Precio: 0,
						Usuario: nombre,
						Cliente : nombre,
						Participantes: [],
						Localidad : this.Loc,
						Telelfono: "",
						color: "#DADADA",
						nota: 'Colaborador ya salió de su jornada',
					}
					);
					d.setDate(d.getDate() + 7);
				} 
			}
			
	},
	async eliminarProducto(ev,item){
		var name = ev.Cliente + " - " + item.descripcion;
		this.detalles = this.detalles.filter(e => e.id != item.id)
		for(var i = 0; i < this.events.length; ++i)
		{	
					if(this.events[i].IdMultiple == ev.IdMultiple)
						{	
				this.events[i].Participantes = this.detalles;
				db.collection('chineate').doc(this.events[i].id).update({
					Participantes: this.detalles,
				})
				.catch(function (error) {
				alert("Error al Cargar");
				})
							
						}
			if(this.events[i].name == name){
				await db.collection("chineate").doc(this.events[i].id).delete();
				this.events = this.events.filter(e => e.id != this.events[i].id)
			}
			this.Mensaje = "Eliminado exitosamente, sino ve los cambios recargue el sitio";
			this.snackbar = true;
		}
		ev = this.events;
		this.events = [];
		this.events = ev;
    },
		async eliminarProductoTotal (selectedEvents,id,nombre) {
			var Personas1 = [];
			Personas1 = selectedEvents.Participantes.filter(e => e.id != id)
			
			if(confirm("Seguro que desea eliminar el servicio?")){
			db.collection('chineate').doc(selectedEvents.id).update({
					Participantes: Personas1,
				})
				.catch(function (error) {
				alert("Error al Cargar");
			})
			alert("Servicio "+nombre+" eliminado con éxito, el sitió se actualizará para ver los cambios");
			location.reload();
			}
			// Guarda el registro de quien elimino la reserva y cuando
			try {
           	db.collection("Horarios").add({
              name: this.nombre,
              Horario: this.forecast,
              })
              alert("Feliciades "+this.$store.state.usuario.nombre+" Haz asignado la jornada laboral de: "+ this.nombre);
              this.dialogHorarios = false;
              }
              catch (error) {
				alert("Ocurrio un error almacenando en firebase" + error);
				console.log(error);
			}
			
		},
	showEvent ({ nativeEvent, event }) {
		const open = () => {
			this.selectedEvent = event
			//this.selectedEvent.start = event.start2;
			//this.selectedEvent.end = event.end2;
			//this.detalles = event.Participantes
			this.selectedElement = nativeEvent.target
			this.name = event.name;
			this.Cxc = [];
			setTimeout(() => this.selectedOpen = true, 10)
			this.MostrarRelaciones(event)
			if(event.IdMultiple != ""){
			this.CuentasPorCobrar(event.IdMultiple)
			}
			this.showInHouse();
			this.selectMesa(event.category);
		}
		if (this.selectedOpen) {
			this.selectedOpen = false
			setTimeout(open, 10)
		} else {
			open()
		}
		nativeEvent.stopPropagation()
	},
	showEvent2 (event) {
		this.selectedOpen = true;
		this.selectedEvent = event
		this.name = event.name;
		this.Cxc = [];
		this.MostrarRelaciones(event)
		if(event.IdMultiple != '')
		{
		this.CuentasPorCobrar(event.IdMultiple)
		}
		this.selectMesa(event.category);
	},
	CerrarDialog(){
		this.dialog = false;
		
		this.LimpiarReserva();
	},
	EditarProducto(event){
		this.vendedor = event.vendedor;
		this.start = event.start2.substring(0,10);
		this.end= event.end2.substring(0,10);
		this.agencia = event.agencia;
		this.CantPersonas = event.personas;
		this.hora = "15:00";
		this.hora2 = "10:00";
		this.cedula = event.cedula;
		this.clienteCedula = event.cedula;
		this.clienteTelefono = event.Telefono;
		this.clienteCorreo = event.mail;
		this.nombre = event.Cliente;
		this.telefono = event.Telefono;
		this.agencia = event.agencia;
		this.correo = event.correo;
		this.tipoPago = 0;
		this.category = event.category;
		this.barbero = event.category;
		this.servicio = event.name;
		this.precio = event.precio;
		this.idcliente = event.Cliente;
		this.CodigoOta = event.CodigoOta;
		this.id = event.id;
		this.precioVenta = event.precio;
		this.dialog = true;
	},
	CuentasPorCobrar(id){
		let me=this;
		let header={"Authorization" : "Bearer " + this.$store.state.token};
		let configuracion= {headers : header};
		axios.get('api/RepVentasVistas/ListarCreditosLocalidadCliente/'+this.$store.state.usuario.localidad+'/'+id,configuracion).then(function(response){
			//console.log(response);
			me.Cxc=response.data;
		}).catch(function(error){
			console.log(error);
		});
	},
	async selectMesa(id){  
		this.Resta = [];
		if(id == 0){
			return;
		}
		this.CuentaTemporal = [];
		this.TempMesa = [];
		let me=this;
		var tempMesa = [];
		let header={"Authorization" : "Bearer "};
		let configuracion= {headers : header};
		await axios.get('api/OrdenExpress/ListarMesa/'+id+"/"+me.$store.state.usuario.localidad,configuracion).then(function(response){
			tempMesa=response.data;
			me.modelCarga = false;
				//Recopila los productos en mesa
			tempMesa.map(function(x){
                me.Resta.push({detalle: x.descripcion,cajero:x.cajero
				,credito:x.precioFinal,fecha:x.fecha,hora:x.hora});
            });
	}); 
          
    },
	startTime2 (tms) {
        this.dialog = true;
		this.currentlyEditing = null;
        let hor = 0;
        this.detalles = [];
            const mouse = this.toTime(tms)

            if (this.dragEvent && this.dragTime === null) {
              const start = this.dragEvent.start

              this.dragTime = mouse - start
            } else {
              this.createStart = this.roundTime(mouse)
          
              this.hora = tms.hour+":"+Math.round(parseInt(tms.minute)/15)*15;
              this.barbero = tms.category;
              hor = tms.hour + 1
              this.DiaHoy2 = tms.day;
              this.start = tms.date;
            }
         // this.ValidarHorario();
      },
      endDrag () {
        this.dragTime = null
        this.dragEvent = null
        this.createEvent = null
        this.createStart = null
        this.extendOriginal = null
      },
      cancelDrag () {
        if (this.createEvent) {
          if (this.extendOriginal) {
            this.createEvent.end = this.extendOriginal
          } else {
            const i = this.events.indexOf(this.createEvent)
            if (i !== -1) {
              this.events.splice(i, 1)
            }
          }
        }

        this.createEvent = null
        this.createStart = null
        this.dragTime = null
        this.dragEvent = null
      },
	  crearCliente() 
		{
			this.loading = true;
			var self = this;
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			axios.post('api/Empleados/Crear',{
				'Nombre':this.nombre,
				'TipoCedula':this.tipoDocumento,
				'Cedula': this.cedula,
				'Localidad':this.$store.state.usuario.localidad,
				'Telefono':this.telefono,
				'Periodo': "0",
				'Correo':this.correo,
				'Empresa':this.$store.state.usuario.localidad,
				'NombreFantasia':"",
				'Correo2':this.clienteCorreo2,
				'Cantidad':this.limiteCredito,
				'Genero':"",
				'Edad':"0",
				'Facebook':"",
				'Vendedor': "Calendario",
				'ListaPrecio':"Venta",
				'NombreFantasia':this.nombreFantasia,
				'Celular':this.celular,
			},configuracion).then(function (response) {
			self.Mensaje = "Cliente ha sido insertado correctamente";
			self.snackbar = true;
			self.dialogCliente = false;
			self.loading = false;
			self.limpiarCliente();
			self.select();
			
			}).catch(function(error){
				alert('El email o cédula ya existe');
				self.loading = false;
			}); 
		},
      roundTime (time, down = true) {
        const roundTo = 15 // minutes
        const roundDownTime = roundTo * 60 * 1000

        return down
          ? time - time % roundDownTime
          : time + (roundDownTime - (time % roundDownTime))
      },
      toTime (tms) {
        return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
      },
	async selectBarbero(){
		let me=this;
		var barberosArray=[];
		this.barberos.push({text:'Cuenta Maestra',value:'90909090' })
		let header={"Authorization" : "Bearer "};
		let configuracion= {headers : header};
		await axios.get('api/Usuarios/SelectUsuariosCalendar/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
			barberosArray=response.data;
			for(var i = 0; i < barberosArray.length; ++i){
				me.prestadores.push(barberosArray[i].nombre);
				me.barberos.push({text:barberosArray[i].nombre,value:barberosArray[i].num_documento })
			}
		})
    },
	showInHouse(){
		this.inhouses = [];
		this.inhouses.push({text:'Cuenta Maestra',value:'90909090' })
		for(var i = 0; i < this.events.length; ++i){
			if(this.events[i].Estado == 'In House'){
				this.inhouses.push({text:this.events[i].category,value:this.events[i].IdMultiple })
			}
		}
	},
	async SelectFichaProducto(){
		let me=this;
		this.Agencias = [];
		this.Vendedores = [];
		var Data=[];
		let header={"Authorization" : "Bearer "};
		let configuracion= {headers : header};
		await axios.get('api/FichaProductos/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
			Data=response.data;
			for(var i = 0; i < Data.length; ++i){
				if(Data[i].valor == "Agencia"){
					me.Agencias.push(Data[i].nombre);
				} else
				if(Data[i].valor == "Tour Operador"){
					me.TourOperadores.push(Data[i].nombre);
				} else
				if(Data[i].valor == "Vendedor"){
					me.Vendedores.push(Data[i].nombre);
				}
			}
		})
    },
	async selectFicha(){
		let me=this;
		this.fichas = [];
		if(this.idcliente == ""){
			this.Mensaje = "Debe seleccionar un cliente";
			this.snackbar = true;
			return;
		}
		var clientePrevio="";
			if (this.idcliente.text === undefined)
			{
				clientePrevio = this.idcliente;
			}
			else
			{
				clientePrevio = this.idcliente.value;
			}
		let header={"Authorization" : "Bearer "};
		let configuracion= {headers : header};
		await axios.get('api/NotasClientes/ObtenerCliente/'+clientePrevio,configuracion).then(function(response){
			me.fichas=response.data;
			
		})
    }, 
      getEventColor (event) {
        const rgb = parseInt(event.color.substring(1), 16)
        const r = (rgb >> 16) & 0xFF
        const g = (rgb >> 8) & 0xFF
        const b = (rgb >> 0) & 0xFF

        return event === this.dragEvent
          ? `rgba(${r}, ${g}, ${b}, 0.7)`
          : event === this.createEvent
            ? `rgba(${r}, ${g}, ${b}, 0.7)`
            : event.color
      },
	  AbrirDialogCliente(){
		  this.dialogCliente = true;
	  },
	  selectBotones(){  
		let me=this;
		var localidadesArray=[];
		let header={"Authorization" : "Bearer "};
		let configuracion= {headers : header};
		axios.get('api/Botones/ListarLocalidad/'+me.$store.state.usuario.localidad,configuracion).then(function(response){
			localidadesArray=response.data;
			localidadesArray.map(function(x){
				me.Botones.push({text: x.nombre,value:x.idBoton});
				me.modelCarga = false;
			});
		})
    },
	selectPosicion(id){  
		let me=this;
		me.esCompuesto = false;
		var localidadesArray=[];
		let header={"Authorization" : "Bearer "};
		let configuracion= {headers : header};
		axios.get('api/PosicionProductoCajas/ListarCategoriaLocalidad/'+id+'/'+me.$store.state.usuario.localidad,configuracion).then(function(response){
			me.PosicionProductos=response.data;
			});
    },
	buscarCodigoBoton(codigoboton,valor0){
		let me=this;
		this.tipoPago = 0;
		this.cantidad = 1;
		let Datos = null;
		if (this.servicio.value === undefined)
			{
				this.codigo = this.servicio;
			}
			else
			{
				this.codigo = this.servicio.value;
			}

			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			axios.get('api/Productos/BuscarCodigoVenta/'+me.codigo+'/'+me.$store.state.usuario.localidad,configuracion)
			.then(function(response){
			Datos = response.data;
			me.precio = Datos.precioVenta;
			me.cabys = Datos.codigoRaiz;
				}).catch(function(error){
					me.Mensaje = "Producto no encontrado"
					me.snackbar = true;
					me.modelCarga = false;
					});
				
		this.codigo = "";
		this.calcularFechas();
	},
  async deleteEvent (ev) {
    //  this.loading = true;
		if(confirm("Seguro Desea Eliminar Esta Reserva, esto crea un registro y asignará a "+this.$store.state.usuario.nombre+" como responsable. Se enviará una notificación a la administración?")){
		let self = this;
		let header={"Authorization" : "Bearer "};
		let configuracion= {headers : header};
		await axios.delete('api/Reservas/'+ev.id,{
		},configuracion).then(function (response) {
		self.Mensaje = "Eliminado correctamente";
		self.snackbar = true;
		self.CrearRegistro(ev);
		self.getEvents2();
		
		}).catch(function(error){
			self.Mensaje = "Error actualizando, por favor refresque el sitio y verifique si la cita esta donde corresponde. "+error;
			self.snackbar = true;}); 
			self.loading = false;
			self.selectedOpen = false; 
		}
    },
	async CrearRegistro(ev){
		var self = this;	
		var mail = `
			<!DOCTYPE html>
				<html lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
				<head>
					<meta charset="utf-8">
					<meta name="viewport" content="width=device-width">
					<meta http-equiv="X-UA-Compatible" content="IE=edge">
					<meta name="x-apple-disable-message-reformatting">
					<title></title>
					
					<link href="https://fonts.googleapis.com/css?family=Roboto:400,600" rel="stylesheet" type="text/css">
					<!-- Web Font / @font-face : BEGIN -->
					<!--[if mso]>
						<style>
							* {
								font-family: 'Roboto', sans-serif !important;
							}
						</style>
					<![endif]-->

					<!--[if !mso]>
						<link href="https://fonts.googleapis.com/css?family=Roboto:400,600" rel="stylesheet" type="text/css">
					<![endif]-->

					<!-- Web Font / @font-face : END -->

					<!-- CSS Reset : BEGIN -->
					
					
					<style>
						/* What it does: Remove spaces around the email design added by some email clients. */
						/* Beware: It can remove the padding / margin and add a background color to the compose a reply window. */
						html,
						body {
							margin: 0 auto !important;
							padding: 0 !important;
							height: 100% !important;
							width: 100% !important;
							font-family: 'Roboto', sans-serif !important;
							font-size: 14px;
							margin-bottom: 10px;
							line-height: 24px;
							color:#8094ae;
							font-weight: 400;
						}
						* {
							-ms-text-size-adjust: 100%;
							-webkit-text-size-adjust: 100%;
							margin: 0;
							padding: 0;
						}
						table,
						td {
							mso-table-lspace: 0pt !important;
							mso-table-rspace: 0pt !important;
						}
						table {
							border-spacing: 0 !important;
							border-collapse: collapse !important;
							table-layout: fixed !important;
							margin: 0 auto !important;
						}
						table table table {
							table-layout: auto;
						}
						a {
							text-decoration: none;
						}
						img {
							-ms-interpolation-mode:bicubic;
						}
					</style>

				</head>

				<body width="100%" style="margin: 0; padding: 0 !important; mso-line-height-rule: exactly; background-color: #f5f6fa;">
					<center style="width: 100%; background-color: #f5f6fa;">
						<table width="100%" border="0" cellpadding="0" cellspacing="0" bgcolor="#f5f6fa">
							<tr>
							<td style="padding: 40px 0;">
									<table style="width:100%;max-width:620px;margin:0 auto;">
										<tbody>
											<tr>
												<td style="text-align: center; padding-bottom:25px">
													<a href="#"><img style="height: 120px" src="https://www.noah.cr/ImagenesGenerales/LogoNoahHoizontal.png" alt="logo"></a>
													<p style="font-size: 14px; color: #6576ff; padding-top: 12px;">Notificación automatica</p>
												</td>
											</tr>
										</tbody>
									</table>
									<table style="width:100%;max-width:620px;margin:0 auto;background-color:#ffffff;">
										<tbody>
											<tr>
												<td style="padding: 30px 30px 20px">
													<p style="margin-bottom: 10px;">Notificación el eliminación de reserva realziiado por: `+this.$store.state.usuario.nombre+`</p>
													<p style="margin-bottom: 10px;">Data;`+ev+`</p>
													<p style="margin-bottom: 10px;">Eliminado:`+ev.name+`</p>
												</td>
											</tr>
										</tbody>
									</table>
									<table style="width:100%;max-width:620px;margin:0 auto;">
										<tbody>
											<tr>
												<td style="text-align: center; padding:25px 20px 0;">
													<p style="font-size: 13px;">Copyright © 2022 Noah Systems. Gracias por utilizar nuestros servicios <br> Sistemas Infórmaticos <a style="color: #6576ff; text-decoration:none;" href="https://www.noah.cr">Noah SRL</a>.</p>
													<ul style="margin: 10px -4px 0;padding: 0;">
														<li style="display: inline-block; list-style: none; padding: 4px;"><a style="display: inline-block; height: 30px; width:30px;border-radius: 50%; background-color: #ffffff" href="https://www.facebook.com/NoahSystemsCR"><img style="width: 30px" src="https://www.noah.cr/ImagenesGenerales/brand-b.png" alt="brand"></a></li>
														<li style="display: inline-block; list-style: none; padding: 4px;"><a style="display: inline-block; height: 30px; width:30px;border-radius: 50%; background-color: #ffffff" href="https://www.instagram.com/noah.systems/?hl=es"><img style="width: 30px" src="https://www.noah.cr/ImagenesGenerales/brand-e.png" alt="brand"></a></li>
														<li style="display: inline-block; list-style: none; padding: 4px;"><a style="display: inline-block; height: 30px; width:30px;border-radius: 50%; background-color: #ffffff" href="https://www.youtube.com/channel/UCpMW0Xdqdha04qh6ehq3v-A?reload=9&app=desktop"><img style="width: 30px" src="https://www.noah.cr/ImagenesGenerales/brand-d.png" alt="brand"></a></li>
													</ul>
													<p style="padding-top: 15px; font-size: 12px;">Este correo fue generado de forma automatica por <a style="color: #6576ff; text-decoration:none;" href="https://noah.cr">noah.cr</a>. Para actualizar el correo de notificaciones, actualice desde su panel de control <a style="color: #6576ff; text-decoration:none;" href="https://www.noah.cr/panelcontrol">click here</a>.</p>
												</td>
											</tr>
										</tbody>
									</table>
							</td>
							</tr>
						</table>
					</center>
				</body>
				</html>`;
		let header={"Authorization" : "Bearer "};
		let configuracion= {headers : header};
		await axios.post('api/Reservas/CrearRegistro',{
			'Id': ev.id,
			'Pago': ev.pago,
			'Usuario':this.$store.state.usuario.nombre,
			'name': ev.name,
			'Localidad':this.$store.state.usuario.localidad,
			'Estado':mail,
			'Correo':this.correo
		},configuracion).then(function (response) {
		
		}).catch(function(error){
		
		}); 
	},
	editEvent(ev) 
		{
		this.currentlyEditing = ev.id,
		//this.dialog = true,
		this.name = ev.name,
		this.color = ev.color;
		this.servicio = ev.category;
		this.filial = ev.filial;
		this.idcliente = ev.Cliente;
		this.clienteNombre = ev.Cliente;
		this.barbero = ev.category;
		this.agencia = ev.agencia;
		this.vendedor = ev.vendedor;
		this.details = ev.detalle;
		this.TourOperador = ev.TourOperador;
		this.start = ev.start2.substring(0, 10);
		this.end = ev.end2.substring(0, 10);
		this.hora = ev.start2.substring(11, 16);
		this.hora2 = ev.end2.substring(11, 16);
	},
	limpiarCliente(){
        this.nombre = "";
        this.tipoDocumento = "";
        this.cedula = "";
        this.idLocalidadCliente = "";
        this.telefono = "";
        this.correo = "";
    },
	
  agregarDetalle(data = []){
                let barcodepreview = Date.now();
				let idsa = barcodepreview.toString();
				var codigoProducto = idsa.substring(5, 12);
                this.errorArticulo=null;
                if (data['codigoProducto'] != '' && data['codigoProducto'] != undefined)
                {
                    this.codigo = data['codigoProducto'];
                }
                  
                  if (this.codigo != '')
                  {
                      this.detalles.push(
                        {
                        id: codigoProducto,
                        idarticulo:data['codigoProducto'],
                        descripcion:data['descripcion'],
                        cantidad:1,
                        precio:data['precioVenta'],
                        descuento:0,
                        subtotal:data['precioVenta'],
                        hora: "12:00",
                        hora2: "10:00",
                        prestador: this.barbero,
                        }
                        );
                      this.codigo = '';
					  this.HorasSumar();
                  }
                  
                  else{
                    alert("Debe digitar un código de producto o servicio");
                  
                }
    },
	HorasSumar()  {
        var time1 = this.hora 
        var minute = 0;
        var hour=0;
        var hour2=0;
        var splitTime1 = [],
        time2 = this.CantidadHoras;
      if(this.detalles.length == 1){
          splitTime1= time1.split(':');
          hour = parseInt(splitTime1[0]);
          minute = parseInt(splitTime1[1]);
          
          minute = parseInt(minute) + parseInt(time2);
      } else
      if(this.detalles.length >= 2){
          splitTime1= this.hora2.split(':');
          hour = parseInt(splitTime1[0]);
		  
          minute = parseInt(splitTime1[1]);
          minute = parseInt(minute) + parseInt(time2);
      }
        if(minute >= 60){
          hour = hour + 1;
          minute = minute - 60;
            if(minute >= 60){
              hour = hour + 1;
              minute = minute - 60;
            } if(minute >= 60){
              hour = hour + 1;
              minute = minute - 60;
            }
        }
        if(minute == 0){
              minute = "00"
            }
        this.hora2 = hour+ ":"+minute;
		if(this.detalles.length == 1){
			this.detalles[0].hora = this.hora;
			this.detalles[0].hora2 = this.hora2;
		} else
		if(this.detalles.length == 2){
			this.detalles[1].hora = this.detalles[0].hora2
			this.detalles[1].hora2 = this.hora2;
		} else
		if(this.detalles.length == 3){
			this.detalles[2].hora = this.detalles[1].hora2
			this.detalles[2].hora2 = this.hora2;
		} 
		if(this.detalles.length == 4){
			this.detalles[3].hora = this.detalles[2].hora2
			this.detalles[3].hora2 = this.hora2;
		} 
		if(this.detalles.length == 5){
			this.detalles[4].hora = this.detalles[3].hora2
			this.detalles[4].hora2 = this.hora2;
		} 
    },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
	  rndCategory (arr) {
        return arr[this.rnd(0, arr.length - 1)]
      },
      rndElement (arr) {
        return arr[this.rnd(0, arr.length - 1)]
      },
    },
  }
</script>