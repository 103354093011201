import { render, staticRenderFns } from "./CreditosPagados.vue?vue&type=template&id=0c77f75c"
import script from "./CreditosPagados.vue?vue&type=script&scoped=true&lang=js"
export * from "./CreditosPagados.vue?vue&type=script&scoped=true&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports