<template>

  <div
    id="pro-features"
  >
  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3930.510357506678!2d-84.10485658506208!3d9.891383077608644!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8fa0fd81c9d8a915%3A0x22ba41f30dc15d03!2sNoah%20Systems!5e0!3m2!1ses!2scr!4v1617764060826!5m2!1ses!2scr" width="100%" height="500" frameborder="0" 
	 	style="border:0;" allowfullscreen="" aria-hidden="false"
		tabindex="0"></iframe>
	<div class="mini-spacer bg-extra-light"
	style="background-color: #f4f8fa">
      <v-container>
        <!-- -----------------------------------------------
            Start Contact Form
        ----------------------------------------------- -->
        <v-row justify="center">
          <v-col cols="12" sm="10" md="9" lg="7">
            <div class="text-center">
              <h2 class="ui-title font-weight-bold">¿Le gustaría que lo contactemos?</h2>
              <p>
                Dejanos la indicación de como contactarte y un ejecutivo se pondrá en contacto
				en cuando lo indiques. ¡Queremos ayudarte!
              </p>
            </div>
          </v-col>
        </v-row>
		  <v-row justify="center">
          <v-col cols="12" sm="10" md="12" lg="8">
            <div>
              <h4 style="margin: 20px 0 15px;
					line-height: 26px;
					font-size: 21px;" class="font-weight-medium contact-title mt-0">
                Quick Contact
              </h4>
              <form>
                <v-row class="mt-15">
                  <v-col cols="12" md="6" class="py-0">
                    <v-text-field
                      label="Name"
                      outlined
                      v-model="nombre"
                      placeholder="Nombre"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <v-text-field
                      label="Número"
                      outlined
                      type="number"
                      v-model="numero"
                      placeholder="Número"
                    ></v-text-field>
                  </v-col>
				  <v-col cols="12" md="6" class="py-0">
                    <v-select
                      :items="Metodos"
                  		label="Como le gustaría que le contacten?*"
						v-model="titulo"
                      	placeholder="Método"
						outlined
                    ></v-select>
                  </v-col>
				  <v-col cols="12" md="6" class="py-0">
                    <v-text-field
                      label="Email"
                      outlined
                      type="email"
                      v-model="email"
                      placeholder="Email"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-textarea
                      name="message"
                      outlined
                      label="Message"
                      rows="3"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-btn
                  outlined
				  style="padding: 25px 35px !important; background: #316ce8;"
                  color="white"
                  elevation="0"
				  :loading='loading'
                  @click="Enviar()"
                >
                  Submit
                </v-btn>
              </form>
            </div>
          </v-col>
          <v-col cols="12" sm="10" md="12" lg="4">
            <div style="padding: 40px;" class="primary contact-detail-card">
              <h2 class="detail-title font-weight-medium white--text">
                Sistemas Informáticos Noah SRL
              </h2>
              <p class="mt-15 op-8 mb-0 white--text">+(506) 4070-1889</p>
              <p class="op-8 mb-8 white--text">info@noah-systems.net</p>
              <p class="mt-15 op-8 mb-0 white--text">San José Costa Rica.</p>
              <p class="op-8 mb-0 white--text">Cartago, Metrocentro Local #3</p>
            </div>
          </v-col>
        </v-row>
        <!-- -----------------------------------------------
            End Contact Form
        ----------------------------------------------- -->
      </v-container>
    </div>
  </div>
</template>


<script>
import axios from 'axios';

  export default {
    
    
    props: {
      source: String,
    },
    data: () => ({
		model: true,
		nombre: '',
		email: '',
		loading: false,
    Metodos: ["WhatsApp","Llamada Telefónica","Correo electrónico"],
		telefono: '',
		numero: '',
		mensaje: '',
		titulo: '',
    }),
    computed: {
      
  },
    created() {
		
    },
    methods: {
      salir(){
      this.$store.dispatch("salir");
	},
	Enviar(){
          this.loading = true;
		let me = this;
          let header={"Authorization" : "Bearer "};
                let configuracion= {headers : header};
                axios.post('api/Contactos/Crear',{
                      'Nombre':this.nombre,
                      'Email':this.email,
                      'Telefono': this.numero,
                      'Titulo': this.titulo,
                      'Mensaje': this.mensaje,
                      'Localidad': "Demo Localidad",
                      'Otro': "jeff@noah-systems.net", // Este rubro es el emaill donde se envia el correo
                },configuracion).then(function (response) {

					me.loading= false;
					alert("Su mensaje fue enviado con éxito. En breve será contactado por un asesor.");
					me.nombre= "";
					me.email = "";
					me.titulo= "";
					me.numero = "";
					me.numero = "";
					me.mensaje = "";				
					
                  })
                  .catch(function (error) {
					  alert("Ocurrió un problema. Por favor reportelo al Tel:+ 506 24755111");
                })
    },
	validarIdioma(){
		if(localStorage.getItem("idioma") == null) {
			this.Pais = "ESP";
			return;
		}

		this.Pais = localStorage.getItem("idioma");
	},
    }

  }
</script>

