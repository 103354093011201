<template>
	<v-container fluid>
		<v-snackbar
                v-model="snackbar" top vertical="" :timeout="4000"
                >
                {{ Mensaje }}
                <v-btn
                color="pink"
                text
                @click="snackbar = false"
                >
                Close
                </v-btn>
        </v-snackbar>
		<v-row>
			<v-col cols="12" sm="12">
				<v-card>
				<v-toolbar
					flat
					color="black"
					dark
				>
					<v-toolbar-title>Botones</v-toolbar-title>
				<v-tooltip class="mx-2" bottom>
					<template v-slot:activator="{ on, attrs }">
							<v-btn small dark color="red" v-bind="attrs"
							v-on="on" href="https://www.youtube.com/watch?v=fSiB7UFTMXA" fab > <v-icon fab> mdi-youtube </v-icon> </v-btn>
					</template>
				<span>Da click para ver un video tutorial de ayuda en YouTube</span>
				</v-tooltip>			
				</v-toolbar>
				<v-tabs color="info" vertical v-model="tab" >
				
					<v-tab class="mt-4"
						v-for="item in Botones"
						:key="item.text"  @click="selectPosicion(item.value)"
					> 
					<v-text-field autofocus filled v-model="item.text"> </v-text-field>	
					<v-icon @click="BorrarBoton(item.value)" color="black" >mdi-delete</v-icon> 
					<v-icon @click="GuardarBoton(item.idBoton,item.text)" color="black" >mdi-content-save</v-icon> 
					</v-tab>
					<v-btn @click="AgregarBoton()" class="" color="green" fab> <v-icon color="white" large> mdi-plus-thick </v-icon> </v-btn>
					<v-tabs-items v-model="tab">
					<v-tab-item 
					v-for="item in Botones"
					:key="item.text"
					>
						<v-row  class="pa-0 ml-1">
							<v-card class="mt-2 rounded-xl mx-1" max-width="400" flat
							v-for="(platos , index) in PosicionProductos"
								:key="index">
								<v-card
									:elevation="n - 1"
									height="70"
									width="550"
									class="warning black--text"
								>
								
								<v-card-actions>
									<v-combobox :items="productos" onfocus="this.select();" @change="AgregarProducto(platos.descripcion,platos.id,platos.posicion)" dense outlined filled v-model="platos.descripcion"> </v-combobox>
									<v-btn fab dark="" small color="black" @click="eliminarPosicion(platos.id)"><v-icon dark>mdi-delete</v-icon> </v-btn>
									
								{{platos.posicion}}
								</v-card-actions>
								</v-card>
							</v-card>
							<v-btn @click="AgregarPosicion()" class="mt-4" color="green" fab large> <v-icon color="white" large> mdi-plus-thick </v-icon> </v-btn>
						</v-row>
					</v-tab-item>
				</v-tabs-items>
				</v-tabs>
				</v-card>
				<div class="grey--text text-caption">
				<v-icon small> mdi-home </v-icon> {{localidad}}<v-icon small> mdi-account </v-icon> {{nombre}}
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import axios from 'axios'
  export default {
    data: () => ({
      dialog: false,
      Botones: [],
	  MaxBoton: 0,
	  productos:[],
	  PosicionProductos:[],
	  Posiciones: [],
	  Categoria: "",
	  snackbar : false,
	  Mensaje :"",
	  nombre:"",
	  MaxPosicion: 0,
	  localidad: "",
    }),

    computed: {
      formTitle () {
                return this.editedIndex === -1 ? 'Nuevo usuario' : 'Actualizar usuario'
        }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      
    },

    created () {
      this.selectBotones();
	  this.selectProductos();
    },

    methods: {
		selectBotones(){  
			let me=this;
			var Posiciones=[];
			var localidadesArray=[];
			this.Botones = [];
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			axios.get('api/Botones/ListarLocalidad/'+me.$store.state.usuario.localidad,configuracion).then(function(response){
				localidadesArray=response.data;
				localidadesArray.map(function(x){
					me.Botones.push({text: x.nombre,value:x.idBoton});
				});
				localidadesArray.map(function(x){
                        Posiciones.push(x.idBoton);
                    });
				me.MaxBoton = Math.max.apply(null, Posiciones);
				if(me.MaxBoton == "-Infinity"){
					me.MaxBoton = 0;
				}

			})
			me.localidad= me.$store.state.usuario.localidad;
			me.nombre = me.$store.state.usuario.nombre;
    	},
		selectProductos(){
                let me=this;
                let header={"Authorization" : "Bearer " };
                let configuracion= {headers : header};
                var productosArray=[];
                axios.get('api/Productos/SelectProductosCategoria/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
                    productosArray=response.data;
                    productosArray.map(function(x){
                        me.productos.push({text:x.descripcion, value:x.codigoProducto});
                    });
                    me.modelCarga = false;
                }).catch(function(error){
                    console.log(error);
                });
            },
		async eliminarPosicion(id){
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			await axios.post('api/PosicionProductoCajas/EliminarPosicion',{
						'Id':id,
						
				},configuracion).then(function (response) {
						alert(`Producto Eliminado con éxito`);  
						location.reload();
					})
					.catch(function (error) {
						alert(`Error Eliminando`);  
				})
		},
		async BorrarBoton(id){
			if(confirm("Seguro que quiere eliminar este botón y sus productos asociados?")){

			
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			await axios.post('api/Botones/Eliminar',{
						'Id':id,
						'Localidad': this.$store.state.usuario.localidad,
						
				},configuracion).then(function (response) {
						alert(`Botón y productos asociados eliminados`);  
						location.reload();
					})
					.catch(function (error) {
						alert(`Error Eliminando`);  
				})
			}
		},
		AgregarPosicion(){
			this.PosicionProductos.push(
                  {
                  codigoProducto: 0,
                  descripcion:"Disponible",
                  id:-1,
                  posicion:this.MaxPosicion +1,
				  precio: 0,
                  }
                  );
		},
		AgregarBoton(){
			this.Botones.push(
                  {
                  idBoton: -1,
                  localidad:this.$store.state.usuario.localidad,
                  nombre:"Disponible",
                  }
                  );
		},
		GuardarBoton(id,text){
			let me=this;
			if (id == "-1")
			{	
				
				let header={"Authorization" : "Bearer " + this.$store.state.token};
				let configuracion= {headers : header};
				axios.post('api/Botones/Crear',{
							'IdBoton':me.MaxBoton + 1,
							'Nombre': text,
							'Localidad': this.$store.state.usuario.localidad,
							
					},configuracion).then(function (response) {
							alert(`Boton agregado con éxito`);  
							me.selectBotones();
							//location.reload();
						})
						.catch(function (error) {
							alert(`Producto ya existe, no es necesario agregarla`);  
					})
			} else if(id != "-1") 
				{
			
					let header={"Authorization" : "Bearer " + this.$store.state.token};
					let configuracion= {headers : header};
					axios.post('api/Botones/Actualizar',{
							'IdBoton':id,
							'Nombre': text,
							'Localidad': this.$store.state.usuario.localidad,
							
					},configuracion).then(function (response) {
							alert(`Boton Actualizado con éxito`);  
							me.selectBotones();
						})
						.catch(function (error) {
							alert(`Producto ya existe, no es necesario agregarla`);  
						})
				}
		},
	AgregarProducto(nombre,id,Posicion){
		let me=this;
		if (id == "-1")
		{	
			if(nombre.value == null){
				return;
			}
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			axios.post('api/PosicionProductoCajas/Crear',{
						'CodigoProducto':nombre.value,
						'Descripcion': nombre.text,
						'Categoria': this.Categoria,
						'Posicion': Posicion,
						'Localidad': this.$store.state.usuario.localidad,
						
				},configuracion).then(function (response) {
						me.Mensaje = "Producto Agregado con éxito.";
                    	me.snackbar = true;
						me.selectPosicion(me.Categoria);
					})
					.catch(function (error) {
						alert(`Producto ya existe, no es necesario agregarla`);  
				})
		} else
			if(id != "-1")
			{		 
				if(nombre.value == null){
					return;
				}
				let header={"Authorization" : "Bearer " + this.$store.state.token};
				let configuracion= {headers : header};
				axios.post('api/PosicionProductoCajas/Actualizar',{
							'CodigoProducto':nombre.value,
							'Descripcion': nombre.text,
							'Id': id,
							'Localidad': this.$store.state.usuario.localidad,
							
					},configuracion).then(function (response) {
							this.Mensaje = "Producto Actualizado.";
                    		this.snackbar = true;
							me.selectPosicion(me.Categoria);
						})
						.catch(function (error) {
							alert(`Producto ya existe, no es necesario agregarla`);  
					})
			}
    },
	selectPosicion(id){  
			let me=this;
			me.Categoria = id
			var PosicionesEnProductos=[];
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			axios.get('api/PosicionProductoCajas/ListarCategoriaLocalidad/'+id+'/'+me.$store.state.usuario.localidad,configuracion).then(function(response){
				me.PosicionProductos=response.data;
				
				me.PosicionProductos.map(function(x){
                        PosicionesEnProductos.push(x.posicion);
                    });
				me.MaxPosicion = Math.max.apply(null, PosicionesEnProductos)
				if(me.MaxPosicion == "-Infinity"){
					me.MaxPosicion = 0;
				}
			});
	},

    },
  }
</script>