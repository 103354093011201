<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            >
            <template v-slot:item.tipoMovimiento="{ item }">
            <v-chip :color="getColor(item.tipoMovimiento)" dark> {{getDescription(item.tipoMovimiento)}}</v-chip>
            </template>
            <template v-slot:top>
                <v-row class="pa-2">
                    <v-col cols="12" sm="2">
                            <v-card-title>Trazabilidad Productos</v-card-title>
							<b> Stock: </b> {{stock}}
                    </v-col>
					<v-col cols="12" sm="4">    
                        <v-select @change="limpiar()" filled  v-model="localidad" label="Bodega o Localidad" :items="Localidades"> </v-select>
					</v-col>
                    <v-col cols="12" sm="6">    
                        <v-combobox :loading="loading" id="buscador" filled clearable onfocus="this.select();"  prepend-inner-icon="mdi-briefcase-outline" @keyup="selectProductos()" @change="buscarProductoDirecto()" :items="productos" v-model="codigo" label="Código de producto o servicio"></v-combobox>
					</v-col>
                </v-row>
			</template>
        </v-data-table>
        <template class="pa-3">
            <v-snackbar
				v-model="snackbar" top vertical="" :timeout="5000"
				>
				{{ Mensaje }}
				<v-btn
				color="info"
				text
				@click="snackbar = false"
				>
				Cerrar
				</v-btn>
            </v-snackbar>
        </template>
    </div>
</template>

<script>
import axios from 'axios'
import VueExcelXlsx from "vue-excel-xlsx";
import Vue from "vue";

Vue.use(VueExcelXlsx);
  export default {
    data: () => ({
    dialog: false,
	loading:false,
	codigo:'',
    search: '',
	localidad: '',
	Localidades:[],
	items: [], // Datos modificados para el Excel
	productos:[],
	Mensaje: '',
	stock: 0,
	timeouts: [],
	snackbar:false,
    today: new Date().toISOString().substr(0, 10),
    focus: new Date().toISOString().substr(0, 10),
    headers: [
    { text: 'Codigo', value: 'codigo' },
    { text: 'Descripcion', value: 'descripcion' },      
    { text: 'Cantidad', value: 'cantidad',  },    
	{ text: 'Fecha', value: 'fecha',  },    
	{ text: 'Detalle', value: 'detalle',  },         
	{ text: 'Tipo', value: 'tipoMovimiento',  },   
    ],
    start: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
    end : '',
    descuento: 0,
    
    }),
    computed: {
    },
    watch: {
		dialog (val) {
			val || this.close()
		},
    },

    created () {
		//this.ObtenerDia();
      	this.listarAsociadas();
    },

    methods: {
		listarAsociadas(){
			var items = [];
			let me=this;
			me.Localidades.push(me.$store.state.usuario.localidad);
			this.localidad = this.$store.state.usuario.localidad;
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			axios.get('api/LocalidadesAsociadas/'+this.$store.state.usuario.nombre,configuracion).then(function(response){
				items=response.data;
				items.map(function(x){
					me.Localidades.push(x.localdiadAsociada);
				});
			}).catch(function(error){
				//alert("Producto no encontrado");
				me.modelCarga=false;
			});
		},
		limpiar(){
			this.items = [];
			this.stock = 0;
		},
		getColor (x) {
			if (x == 'INGRESO') return '#8BC34A'
			if(x == 'SALIDA') return '#FF1744'
			if(x == 3) return '#8BC34A'
			else  return '#FF1744'
		},
		getDescription (x) {
			if (x == 8) return 'Recibo'
			if(x == 1) return 'Factura Electrónico'
			if(x == 4) return 'Tiquete Electrónico'
			if(x == 3) return 'Nota de Crédito'
			else  return x
		},
		async BuscarInventario(codigo){
			let header={"Authorization" : "Bearer "};
			let me = this;
			let configuracion= {headers : header};
			await axios.get('api/ProductosWebs/ConsultaStock/'+codigo+'/'+this.localidad,configuracion).then(function(response){
				me.stock=response.data.stock;
			}).catch(function(error){
				//alert("Producto no encontrado"+error);
			});
		},
        buscarProductoDirecto(){
			let me=this;
			this.codigo = (this.codigo.value === undefined) ? this.codigo : this.codigo.value;


			if (this.codigo != "")
			{
				let header={"Authorization" : "Bearer "};
				let configuracion= {headers : header};
				axios.get('api/SalidaProductos/Historial/'+this.localidad+'/'+this.codigo,configuracion)
				.then(function(response){
					me.items = response.data;
					me.BuscarInventario(me.codigo);
				}).catch(function(error){
					//alert(error);
				});
			}
		},
		selectProductos(){
			let me=this;
			let Objeto = [];
			let myInput = document.getElementById('buscador');
			myInput = myInput.value;
			let cant = myInput.length;
			if ( cant > 2 ) {
				var search_term = myInput;
				this.timeouts.map( timeout => {
					window.clearTimeout( timeout );
				} );
				this.timeouts.push(
					setTimeout( () => {
						this.loading = true;
						axios.get('api/Productos/BuscadorEnter/'+this.localidad+'/'+myInput).then( response => {
							Objeto = response.data
								// Cargar Productos 
								Objeto.map(function(x){
									me.productos.push({text:x.descripcion, value:x.codigoProducto});
								});
								me.loading = false;
							} )
							.catch( error => { } );
					}, 500 )
				);
			} else {
				this.timeouts.map( timeout => {
					window.clearTimeout( timeout );
				} );
				this.productos = [];
			}
		},
    },
  }

</script>