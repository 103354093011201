<template>
  <div id="HelloWord">
    <HelloWorld v-if="logueado" />
    <Hero v-if="!logueado" />
    <Catalogo />
    <Features />
    <Affiliates />
    <Stats v-if="!logueado" />
    <ProFeatures v-if="!logueado" />
    <Contacto />
  </div>
</template>

<script>
import HelloWorld from "../components/HelloWorld";
import Hero from "../components/Hero";
import Features from "../components/Features";
import ProFeatures from "../components/ProFeatures";
import Stats from "../components/Stats";
import Affiliates from "../components/Affiliates";
import Catalogo from "../components/Catalogo";
import Contacto from "../components/Contacto";

export default {
  name: "HelloWord",
  components: {
    HelloWorld,
    Hero,
    ProFeatures,
    Features,
    Contacto,
    Affiliates,
    Catalogo,
    Stats,
  },
  computed: {
    logueado() {
      return this.$store.state.usuario;
    },
  },
};
</script>
