import { render, staticRenderFns } from "./RepXproducto.vue?vue&type=template&id=ac904e10&scoped=true"
import script from "./RepXproducto.vue?vue&type=script&scoped=true&lang=js"
export * from "./RepXproducto.vue?vue&type=script&scoped=true&lang=js"
import style0 from "./RepXproducto.vue?vue&type=style&index=0&id=ac904e10&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ac904e10",
  null
  
)

export default component.exports