<template>
    <div>
        <v-dialog v-model="ModalNotaCredito"  max-width="800" class="primary">
            <v-toolbar  dark color="primary" class="font-weight-light body-1">
                <h4>Cambiar estado de {{localidad}}</h4>
                
            </v-toolbar>
            <v-card>
            <v-container fluid>
                <v-col cols="12" class="mt--3" sm="12">
                <v-form ref="form" v-model="validFactura"  @submit.prevent="facturar">
                <v-row > 
                    <v-col  cols="12" sm="12">
                        <v-select v-model="estado"  :items="estados" label="Estado"></v-select>
                    </v-col>
                    
                    <v-col cols="12" sm="12">
                        <v-combobox :items="Mensajes" v-model="Mensaje" label="Mensaje"></v-combobox>
                    </v-col>
                        <v-col cols="12" sm="12">
                        <v-btn  color="primary"  class="mr-4" style="top: 10px;" @click="Actualizar()">
                        Actualizar Estado
                        </v-btn>
                        <v-btn color="error" id="espacioBoton" @click="ModalNotaCredito = false">
                        Cancelar
                        </v-btn> 
                        </v-col>
                </v-row>
                </v-form>
                </v-col>
            </v-container>
            </v-card>
        </v-dialog>
        <v-data-table
            :headers="headers"
            :items="usuarios"
            :search="search"

            >
            <template v-slot:item.estado="{ item }">
            <v-chip :color="getColor(item.estado)" dark>{{ item.estado }}</v-chip>
            </template>
            <template v-slot:top>
                <v-row class="pa-2">
                    <v-col cols="12" sm="3">
                            <v-card-title>Licencias</v-card-title>
                    </v-col>
                    <v-col cols="12" sm="4">    
                        <v-text-field v-model="search"  append-icon="mdi-magnify"
                         label="Buscar" single-line hide-details
                        ></v-text-field>
                    </v-col>
                </v-row>
               
            </template>
            <template v-slot:item.action="{ item }">
                <v-menu top :offset-y="offset">
                    <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        small
                        fab=""
                        v-bind="attrs"
                        v-on="on"
                        
                    > <v-icon color="info">mdi-dots-horizontal</v-icon>
                    </v-btn>
                    </template>
                    <v-list>
						<v-list-item @click="MostrarComprobante(item)">
							<v-list-item-title><v-icon >mdi-file-document</v-icon> 
								Cambiar estado
							</v-list-item-title>
						</v-list-item>
                    </v-list>
                </v-menu>
            <template >
            </template>
            </template>

            <template v-slot:no-data>
                <v-data-table item-key="name" class="elevation-1" loading loading-text="Cargando datos... Por favor espere"></v-data-table>
                <v-btn color="primary" @click="initialize">Reiniciar</v-btn>
            </template>
        </v-data-table>
        <template class="pa-3">
            <v-snackbar
                    v-model="snackbar" top vertical="" :timeout="5000"
                    >
                    {{ Mensaje }}
                    <v-btn
                    color="info"
                    text
                    @click="snackbar = false"
                    >
                    Cerrar
                    </v-btn>
            </v-snackbar>
            
            <v-row flat color="white"  class="pa-2">
                <v-row flat color="white"  class="rounded-xl light-blue lighten-5 mx-5">
                <v-card-text>
                    <b> Total de Clientes:  </b> {{usuarios.length}} <br>
                    <b> Total Clientes Activos:</b>  {{totalActivos}} <br>
                    <b> Total Inactivos:</b>  {{totalInactivos}} <br>
                    
                 
                </v-card-text>
                </v-row>
                <v-col cols="12" sm="6">
                    <canvas id="myChart" class="rounded-xl light-blue lighten-5 mx-5">
                        
                    </canvas>
                </v-col>
            </v-row>
        </template>
    </div>
</template>

<script scoped>
import axios from 'axios'
import VueExcelXlsx from "vue-excel-xlsx";
import Vue from "vue";
import QrcodeVue from 'qrcode.vue'

Vue.use(VueExcelXlsx);
  export default {
    data: () => ({
    mesesValores: null,
    nombreMeses: [],
    totalMeses: [],
    value: 'https://www.noah.cr',
    size: 100,
	localidad: "",
	estado: 0,
	Mensaje: "",
    dialog: false,
    Aceptadas: 0,
    esIVI: false,
    search: '',
    ClienteSeleccionado : '',
    today: new Date().toISOString().substr(0, 10),
    focus: new Date().toISOString().substr(0, 10),
    headers: [
	{ text: 'Opciones', value: 'action', sortable: false },
    { text: 'Cliente', value: 'localidad' },
    { text: 'Estado', value: 'estado' },
    { text: 'Ultimo Log', value: 'fechaUltimoLog' },
    { text: 'Mensaje', value: 'mensaje' },
    { text: 'Cedula', value: 'cedula' },
    { text: 'Telefono', value: 'telefono' },
    ],
    estados: [
        {text:'Activo', value: 1},
		{text:'Inactivo', value: 0},
		{text:'Activar y dejar mensaje', value: 3},
      
    ],
    PagosFiltrados: "",
    Monedas: ["USD","CRC"],
    TiposPago:["TARJETA","DEPOSITO","EFECTIVO","TRANSFERENCIA"],

    MonedaFiltrar : "",
    tipoCedulaReceptor: '',
      search: '',
    emailRules: [
    v => !!v || 'El correo electrónico es requerido',
    v => /.+@.+\..+/.test(v) || 'El correo electrónico es requerido debe ser válido',],
    cedulaRules: [value => !!value || 'La cédula es requerida'
    , value => (value && value.length >= 9) || 'Debe ingresar 9 carácteres como mínimo',],
    nameRules: [
    value => !!value || 'El nombre es requerido',
    value => (value && value.length >= 10) || 'Debe ingresar 10 carácteres como mínimo',
    ],
    celRules: [
        value => !!value || 'El número es requerido',
        value => (value && value.length >= 8) || 'Debe ingresar 8 carácteres como mínimo',
        ],
    justificacionRules: [
        value => !!value || 'La justificación es requerida',
        value => (value && value.length >= 7) || 'Debe ingresar 7 carácteres como mínimo',
        ],
    editedIndex: -1,
    id: '',
    idrol:'',
    noFactura: 0,
    nofactura2: '/ticket/2',
    comprobanteModal: 0,
    Mensajes: ["Su licencia se encuentra vencida por falta de pago, si ya cancelo por favor reporte a su proveedor" ,"Prorroga temporal activada"," Licencia Activada","Cliente de Baja"],
    productosDatos:[],
    MensajeRespuesta: '',
    ClaveComprobante: '',
    EstadoNC: 0,
    ModalNotaCredito: false,
    snackbar: false,
    Mensaje: "",
    correo2: '',
    VendedorSeleccionado: '',
    total: 0,
    justificacion: '',
    medioDePago : '',
	estadoXML: 0,
    detalles: [],
    localidadencabezado:[],
    NombreLocalidad:'',
    CedulaLocalidad:'',
    Datos: [],
    DireccionLocalidad:'',
    Logo3: '',
    TelefonoLocalidad:'',
	excento: 0,
	ShowNoFact: false,
	Cuentas: '',
    Logo2 : '',
    iv: 0.0,
    clientes: [],
    start : '',
    end : '',
    tipo_documento: '',
    Vendedores: [],
	usuarios:[],    
	modoPDV: 0,
    desserts: [],
    editedIndex: -1,
    descuento: 0,
    
    }),
    components: {
      QrcodeVue,
    },

    computed: {
    formTitle () {
            return this.editedIndex === -1 ? 'Nuevo usuario' : 'Actualizar usuario'
    },
    totalActivos:function(){
        var resultado=0.0;
          for(var i=0;i<this.usuarios.length;i++){
			  	if(this.usuarios[i].estado == 1) {
					 resultado=resultado+1
				  }
          }
        
        return resultado.toFixed(2);
      },
	  totalInactivos:function(){
        var resultado=0.0;
          for(var i=0;i<this.usuarios.length;i++){
			  	if(this.usuarios[i].estado == 0) {
					 resultado=resultado+1
				  }
          }
        
        return resultado.toFixed(2);
      },
    },
    
    created () {
      this.listar();
    },

    methods: {
		
       async listar(){
            let me=this;
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
            await axios.get('https://backendcrifasa.pdvcostarica.com/api/Licencias/Listar',configuracion).then(function(response){
                //console.log(response);
                me.usuarios=response.data;
            }).catch(function(error){
                alert(error)
            });
        },
        FacturarProforma(item){
            this.linkFacturar = 'facturar/'+item.noProforma+'/'+this.$store.state.usuario.localidad;
            location.assign(this.linkFacturar);
        },
        Actualizar () {
			this.loading = true;
			var me = this;
			if(confirm("Seguro que actualizar a  "+me.localidad+"?")){
				let header={"Authorization" : "Bearer " + this.$store.state.token};
				let configuracion= {headers : header};
				axios.post('https://backendcrifasa.pdvcostarica.com/api/Licencias/Actualizar',{
					'EstadoXML': me.EstadoXML,
					'Localidad': me.localidad,
					'Mensaje': me.Mensaje,
					'Estado': me.estado,
					'Usuario':me.$store.state.usuario.nombre,
						
				},configuracion).then(function (response) {
					
					me.snackbar = true;
					me.Mensaje = 'Cliente Actualizado Con éxito.'; 
					me.ModalNotaCredito = false;
					me.listar();
					
					})
					.catch(function (error) {
						me.snackbar = true;
						me.Mensaje = 'Error eliminando el Proforma.';    
					})
			}
		},
		getColor (estado) {
			if (estado == '1') return 'success'
			if(estado == '0') return 'red'
			else  return 'orange'
		},
	  MostrarComprobante(item){
		  	this.EstadoXML = item.estadoXML;
		  	this.ModalNotaCredito = true;
            this.localidad= item.localidad;
            this.Mensaje = item.Mensaje;
            this.estado = item.estado;
        },

		close () {
			this.dialog = false;
			this.limpiar();
		},
    },
  }

</script>
My CRUD