<template>
    <div>
        <v-dialog v-model="comprobanteModal" max-width="1000px">
            <v-card class="pa-3">
                
                <v-card-text>
                    <v-btn class="mx-2" color="indigo" dark="" @click="crearPDFFactura()"><v-icon>mdi-file-pdf</v-icon> PDF</v-btn>
                    <v-btn class="mx-2" color="grey" dark="" onclick="window.print()"> <v-icon>mdi-printer</v-icon> A1</v-btn>
                    <v-btn class="mx-2" color="grey" dark="" :to="nofactura2"> <v-icon>mdi-printer</v-icon> POS </v-btn>
                    <v-btn class="mx-2" color="grey" dark="" @click="ReenvioFactura()" > <v-icon>mdi-cloud-download-outline</v-icon>XML Resp</v-btn>
                    <v-btn class="mx-2" color="grey" dark="" @click="ReenvioFactura()" > <v-icon>mdi-cloud-download-outline</v-icon> XML FC </v-btn>
                    <v-btn class="mx-2" color="success" dark=" " @click="ReenvioFactura()" > <v-icon>mdi-email</v-icon><v-icon x-small="">mdi-send</v-icon> </v-btn>
                    <v-text-field label="Correo de reenvio" v-model="correo2"> </v-text-field> 
                    <div id="factura">
                        <header>
                            <div id="logo">
                                <img width="120" height="120" :src="Logo2">
                            </div>
                            <div v-for="det in localidadencabezado" :key="det.nombre" id="datos">
                                <p id="encabezado">
                                    <b class="info--text text-h4 font-weight-bold" > {{det.nombre}} </b> <b class="text-h6 primary--text"> <br>{{det.razonSocial}}, </b><br> {{det.dirreccion}}, Costa Rica<br> 
                                    <v-icon> mdi-card-bulleted </v-icon>: {{det.cedulaJuridica}} <br><v-icon>mdi-phone </v-icon> {{det.telefono}}<br>
                                    <v-icon>mdi-email </v-icon>{{det.correo}}
                                    
                                </p>
                            </div>
                            <div style="text-align:right;" class="font-weight-regular  body-2" id="fact">
                                <p>{{tipoDocumento}}<br>
                               NoFactura: {{noFactura}}<br>
                               
                               {{fecha}}--{{hora}} <br>
                               Vendedor: {{vendedor}}</p>
                                
                            </div>
                        </header>
                        <br>
                        <section>
                            <div>
                                <tbody>
                                    <tr>
                                        <td id="cliente">
                                            <strong class="primary--text text-h6"> Datos del cliente: </strong> <br>
                                            <strong><v-icon>mdi-account </v-icon></strong> {{nombreReceptor}}<br>
                                            <strong><v-icon>mdi-card-bulleted </v-icon></strong> {{cedulaReceptor}} <br>
                                            <strong><v-icon>mdi-email </v-icon></strong> {{correoReceptor}} <br>
                                        </td>
                                    </tr>
                                </tbody>
                            </div>
                        </section>
                        <br>
                        <section>
                            <div>
                                <v-simple-table id="facarticulo" >
                                    <thead>
                                        <tr id="fa" class="primary white--text">
                                            <th class="white--text text-center">Código</th>
                                            <th class="white--text text-center">Cant</th>
                                            <th class="white--text text-center">Descripción</th>
                                            <th class="white--text text-center">Precio IVI</th>
                                            <th class="white--text text-center">Grabado</th>
                                        </tr>
                                    </thead>
                                    <tbody> 
                                        <tr v-for="det in detalles" :key="det.noFacturaReal" >
                                            
                                            <td class="pa-3" style="text-align:center;"> {{det.codigoProducto}} </td>
                                            <td style="text-align:center;"> {{det.cantidad}} </td>
                                            <td style="text-align:center;">{{det.descripcion}}</td>
                                            <td style="text-align:center;">{{det.precioUnitario}}</td>
                                            <td style="text-align:center;">{{det.gravado}}</td> 
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr >
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th class="secondary rounded-l-xl" style="text-align:right;">SUBTOTAL</th>
                                            <th class="secondary" style="text-align:right;"> {{monedaPago}} {{subTotal}}</th>
                                        </tr>
                                        <tr>
                                            <th></th>
                                            <th class="text--grey"> Firma:</th>
                                            <th class="text--grey">________________________</th>
                                            <th class="secondary rounded-l-xl" style="text-align:right;">IVA</th>
                                            <th class="secondary" style="text-align:right;"> {{monedaPago}} {{iv}}</th>
                                        </tr>
                                        <tr>
                                            <th></th>
                                            <th class="text--grey">Cédula:</th>
                                            <th class="text--grey">________________________ </th>
                                            <th class="primary white--text rounded-l-xl" style="text-align:right;">TOTAL</th>
                                            <th class="primary white--text " style="text-align:right;"> {{monedaPago}} {{total}}</th>
                                        </tr>
                                    </tfoot>
                                    
                                </v-simple-table>
                            </div>
                        </section>
                        <br>
                        <br>
                        <footer>
                            <div>
                                <qrcode-vue :value="value" :size="size" level="H"></qrcode-vue>
                                Escanéame
                            </div>
                            <div id="gracias">
                                <p><b>Gracias por su compra!</b><br> Detalle:  {{comentario}}  <br> Facturas Disponibles en {{value}}
                                <br> Clave Comprobante: {{ClaveComprobante}} <br>
                                Autorizado mediante Resolucion DGT-R-033-2019 DEL 6-20-2019</p>
                            </div>
                        </footer>
                    </div>
                    <v-btn @click="OcultarComprobante()" color="error" flat><v-icon> mdi-exit-to-app </v-icon></v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-data-table
            :headers="headers"
            :items="usuarios"
            :search="search"
            sort-by="noFactura"
            sort-desc="noFactura"
            class="elevation-1 pa-1"

            >
            <template v-slot:item.mensajeRespuesta="{ item }">
            <v-chip :color="getColor(item.mensajeRespuesta)" dark>{{ item.mensajeRespuesta }}</v-chip>
            </template>
            <template v-slot:top>
                <v-row class="pa-2">
                    <v-col cols="12" sm="3">
                        <v-card-actions class="rounded-xl light-blue lighten-5 mx-5">
                                <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                                <v-btn small dark color="red" v-bind="attrs"
                                                v-on="on" href="https://youtu.be/_W7AIL_i-S0" fab > <v-icon fab> mdi-youtube </v-icon> </v-btn>
                                        </template>
                                    <span>Da click para ver un video tutorial de ayuda en YouTube</span>
                                </v-tooltip>
                                <vue-excel-xlsx
                                    :data="usuarios"
                                    :columns="columns"
                                    :filename="'Reporte de Compras '+$store.state.usuario.localidad"
                                    :sheetname="'Compras'"
                                    >
                                    <v-btn fab="" color="success">  <v-icon large="">mdi-file-excel </v-icon> </v-btn>
                                </vue-excel-xlsx> 
                            <v-card-title>Compras Aceptadas</v-card-title>
                        </v-card-actions>
                    </v-col>
                    <v-col cols="12" sm="4">    
                        <v-text-field v-model="search"  append-icon="mdi-magnify"
                         label="Buscar" single-line hide-details
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2">
                        <v-text-field  v-model="start" type="date" label="Fecha inicial emisión"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2">
                        <v-text-field  v-model="end"  type="date" label="Fecha final emisión"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="1">
                        <v-btn  color="info" @click="listarFechas()" fab > <v-icon fab> mdi-cloud-search </v-icon> </v-btn>
                    </v-col>
                </v-row>
               
            </template>
            <template v-slot:item.action="{ item }">
            <v-icon
                disabled
                class="mr-2"
                color="primary" 
                @click="MostrarComprobante(item)"
            >
                mdi-file-document
            </v-icon>
            <template >
            <v-icon
                small
                color="error"
                @click="EliminarCompra(item)"
            > 
                mdi-delete
            </v-icon>
            </template>
            
            </template>

            <template v-slot:no-data>
                <v-btn color="primary" @click="initialize">Reiniciar</v-btn>
            </template>
        </v-data-table>
        <template class="pa-3">
            <v-snackbar
                    v-model="snackbar" top vertical="" :timeout="5000"
                    >
                    {{ Mensaje }}
                    <v-btn
                    color="info"
                    text
                    @click="snackbar = false"
                    >
                    Cerrar
                    </v-btn>
            </v-snackbar>
            
            <v-row flat color="white"  class="pa-2">
                <v-col cols="12" xs='2' sm="4">
                    <v-row flat color="white"  class="rounded-xl light-blue lighten-5 mx-5">
                    <v-card-text>
                        <v-card-title class="primary--text text-xl-h4 text-lg-h6 text-sm-body-2"> <b>Resumen General </b></v-card-title>
                        <v-sub-title>Totales de ingresos </v-sub-title> <br>
                        <b> IVA 1%: </b>{{MonedaFiltrar}} {{TotalIva=(calcularIva1)}} <br>
                        <b> IVA 2%: </b>{{MonedaFiltrar}} {{TotalIva=(calcularIva2)}} <br>
                        <b> IVA 4%: </b>{{MonedaFiltrar}} {{TotalIva=(calcularIva4)}} <br>
                        <b> IVA 13% En TC o FC: </b>{{MonedaFiltrar}} {{TotalIva=(calcularIva13)}} <br>
                        <b> Total IVA: </b>{{MonedaFiltrar}} {{TotalIva=(calcularImpuestoTotal)}} <br>
                        <b> Total Compras :</b> {{MonedaFiltrar}} {{TotalVentas=(calcularTotal)}} <br>
                    
                    </v-card-text>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="5">
                    <canvas id="myChart" class="rounded-xl light-blue lighten-5 mx-5">
                        
                    </canvas>
                </v-col>
                <v-col cols="12" sm="3">
                    <v-select prepend-inner-icon="mdi-cash-100" outlined=""  @change="FiltrarMoneda()" :items="Monedas" v-model="MonedaFiltrar" label="Filtrar por tipo de moneda"> </v-select>
                    <v-btn block dark color="#FF1744" class="btnLimpiarGrid mr-4 pa-2" id="espacioBoton" @click="listar()">
                    <v-icon large=""> mdi-delete </v-icon> Limpiar todos los filtros </v-btn> 
                </v-col>
                
                
            </v-row>
        </template>
    </div>
</template>

<script>
import axios from 'axios'
import Chart from 'chart.js'
import jsPDF from 'jspdf'
import autotable from 'jspdf-autotable'
import VueExcelXlsx from "vue-excel-xlsx";
import Vue from "vue";
import html2canvas from 'html2canvas';
import QrcodeVue from 'qrcode.vue'

Vue.use(VueExcelXlsx);
  export default {
    data: () => ({
    mesesValores: null,
    nombreMeses: [],
    totalMeses: [],
    value: 'https://www.noah.cr',
    size: 100,
    dialog: false,
    search: '',
    ClienteSeleccionado : '',
    today: new Date().toISOString().substr(0, 10),
    focus: new Date().toISOString().substr(0, 10),
    headers: [
    { text: 'Opciones', value: 'action', sortable: false },
    { text: 'Factura', value: 'consecutivoDocumento' },
    { text: 'Estado', value: 'estado' },      
    { text: 'Proveedor', value: 'proovedor',  },  
    { text: 'Cédula Proveedor', value: 'cedula',  },  
    { text: 'Correo Proveedor', value: 'correoProv',  }, 
    { text: 'Receptor', value: 'razon_Social',  },   
    { text: 'Fecha Registro', value: 'fechaRegistro',  },  
    { text: 'Fecha Emisión', value: 'fecha',  },  
    { text: 'Tipo', value: 'tipoDocumento' },
    { text: 'Iva 1%', value: 'imp1', sortable: false  },
    { text: 'Iva 2%', value: 'imp2', sortable: false  },
    { text: 'Iva 4%', value: 'imp4', sortable: false  },
    { text: 'Iva 13%', value: 'imp13',  },  
    { text: 'Total Gravado', value: 'totalGravado', sortable: false  },  
    { text: 'Total Descuento', value: 'totalDescuento',  },             
    { text: 'Total Exento', value: 'totalExento',  },     
    { text: 'Total Iva', value: 'montoImpuesto',  },     
    { text: 'Total Neto', value: 'monto_Factura',  },    
    { text: 'Moneda', value: 'moneda',  }, 
	{ text: 'Detalle', value: 'justificacion',  },       
    { text: 'Clave Numérica', value: 'clave',  },               
    ],
    columns : [
            { label: 'Factura', field: 'consecutivoDocumento' },
            { label: 'Estado', field: 'estado' },      
            { label: 'Proveedor', field: 'proovedor',  },  
            { label: 'Cédula Proveedor', field: 'cedula',  },  
            { label: 'Correo Proveedor', field: 'correoProv',  }, 
            { label: 'Receptor', field: 'razon_Social',  },   
            { label: 'Fecha Registro', field: 'fechaRegistro',  },  
            { label: 'Fecha Emisión', field: 'fecha',  },  
            { label: 'Tipo', field: 'tipoDocumento' },
            { label: 'Iva 1%', field: 'imp1' },
            { label: 'Iva 2%', field: 'imp2' },
            { label: 'Iva 4%', field: 'imp4'},
            { label: 'Iva 13%', field: 'imp13',  },  
            { label: 'Total Gravado', field: 'totalGravado'},  
            { label: 'Total Descuento', field: 'totalDescuento',  },             
            { label: 'Total Exento', field: 'totalExento',  },     
            { label: 'Total Iva', field: 'montoImpuesto',  },     
            { label: 'Total Neto', field: 'monto_Factura',  },    
            { label: 'Moneda', field: 'moneda',  },    
			{ label: 'Detalle', field: 'justificacion',  },    
            { label: 'Clave Numérica', field: 'clave',  },     
        ],
    cabeceraDetalles: [
    { text: 'Código', value: 'codigoProducto', sortable: false  },
    { text: 'Descripción', value: 'descripcion', sortable: false  },
    { text: 'Precio', value: 'actionprecio', sortable: false  },
    { text: 'Cantidad a debitar', value: 'actioncantidad', sortable: false  },
    ],
    tipoDocumentos: [
        'FISICA',
        'JURIDICA',
        'EXTRANJERO',
    ],
    PagosFiltrados: "",
    Monedas: ["USD","CRC"],
    TiposPago:["TARJETA","DEPOSITO","EFECTIVO","TRANSFERENCIA"],

    MonedaFiltrar : "",
    tipoCedulaReceptor: '',
      search: '',
    emailRules: [
    v => !!v || 'El correo electrónico es requerido',
    v => /.+@.+\..+/.test(v) || 'El correo electrónico es requerido debe ser válido',],
    cedulaRules: [value => !!value || 'La cédula es requerida'
    , value => (value && value.length >= 9) || 'Debe ingresar 9 carácteres como mínimo',],
    nameRules: [
    value => !!value || 'El nombre es requerido',
    value => (value && value.length >= 10) || 'Debe ingresar 10 carácteres como mínimo',
    ],
    celRules: [
        value => !!value || 'El número es requerido',
        value => (value && value.length >= 8) || 'Debe ingresar 8 carácteres como mínimo',
        ],
    justificacionRules: [
        value => !!value || 'La justificación es requerida',
        value => (value && value.length >= 7) || 'Debe ingresar 7 carácteres como mínimo',
        ],
    editedIndex: -1,
    id: '',
    idrol:'',
    noFactura: 0,
    nofactura2: '/ticket/2',
    comprobanteModal: 0,
    justificacionNota: ["Ajuste de precio precio" ,"Saldo incobrable"," Pronto de pago","Error en cobro"],
    productosDatos:[],
    MensajeRespuesta: '',
    ClaveComprobante: '',
    EstadoNC: 0,
    ModalNotaCredito: false,
    snackbar: false,
    Mensaje: "",
    correo2: '',
    VendedorSeleccionado: '',
    total: 0,
    justificacion: '',
    medioDePago : '',
    detalles: [],
    localidadencabezado:[],
    NombreLocalidad:'',
    CedulaLocalidad:'',
    Datos: [],
    DireccionLocalidad:'',
    TelefonoLocalidad:'',
    fecha: '',
    hora: '',
    nombreReceptor: '',
    cedulaReceptor:'',
    direccion:'',
    monedaPago: "CRC",
    telefonoReceptor:'',
    correoReceptor:'',
    vendedor: '',
    subtotal: 0,
    comentario: '',
    grabado:0 ,
    excento: 0,
    Logo2 : '',
    iv: 0.0,
    clientes: [],
    start : '',
    end : '',
    tipo_documento: '',
    Vendedores: [],
    usuarios:[],    
    desserts: [],
    editedIndex: -1,
    descuento: 0,
    
    }),
    components: {
      QrcodeVue,
    },

    computed: {
    formTitle () {
            return this.editedIndex === -1 ? 'Nuevo usuario' : 'Actualizar usuario'
    },
    calcularTotal:function(){
        var resultado=0.0;
          for(var i=0;i<this.usuarios.length;i++){
                resultado=resultado+(this.usuarios[i].monto_Factura * 1);
          }
        
        return resultado.toFixed(2);
      },
      calcularNC:function(){
        var resultado=0.0;
          for(var i=0;i<this.usuarios.length;i++){
            if(this.usuarios[i].tipoFactura == 'NOTA CREDITO')
                resultado=resultado+(this.usuarios[i].ventaTotal * 1);
          }
        
        return resultado.toFixed(2);
      },
    calcularIva:function(){
        var resultado=0.0;
          for(var i=0;i<this.usuarios.length;i++){
            resultado=resultado+(this.usuarios[i].impuesto * 1);
          }
        
        return resultado.toFixed(2);
      
    },
    calcularIva13:function(){
        var resultado=0.0;
          for(var i=0;i<this.usuarios.length;i++){
            resultado=resultado+(this.usuarios[i].imp13 * 1);
          }
        
        return resultado.toFixed(2);
      
    },
    calcularIva1:function(){
         var resultado=0.0;
          for(var i=0;i<this.usuarios.length;i++){
            resultado=resultado+(this.usuarios[i].imp1 * 1);
          }
        
        return resultado.toFixed(2);
    },
    calcularIva2:function(){
         var resultado=0.0;
          for(var i=0;i<this.usuarios.length;i++){
            resultado=resultado+(this.usuarios[i].imp2 * 1);
          }
        
        return resultado.toFixed(2);
    },
    calcularIva4:function(){
         var resultado=0.0;
          for(var i=0;i<this.usuarios.length;i++){
            resultado=resultado+(this.usuarios[i].imp4 * 1);
          }
        
        return resultado.toFixed(2);
    },
    calcularImpuestoTotal:function(){
       var resultado=0.0;
          for(var i=0;i<this.usuarios.length;i++){
            resultado=resultado+(this.usuarios[i].imp13 + this.usuarios[i].imp1 + this.usuarios[i].imp2 + this.usuarios[i].imp4);
          }
        
        return resultado.toFixed(2);
    },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      
    },

    created () {
      this.listar();
     // this.selectVendedores();
      this.select();
    },

    methods: {
        select(){
                let me=this;
                var clientesArray=[];
                let header={"Authorization" : "Bearer "};
                let configuracion= {headers : header};
                 axios.get('api/Empleados/SelectEmpleadosLocalidad/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
                    clientesArray=response.data;
                    clientesArray.map(function(x){
                        me.clientes.push(x.nombre);
                    });
                })
        },
        listarDetalles(id){
                let me=this;
                let header={"Authorization" : "Bearer " + this.$store.state.token};
                let configuracion= {headers : header};
                axios.get('api/RepVentasVistas/ListarDatosFactura/'+id+'/'+this.$store.state.usuario.localidad+'',configuracion).then(function(response){
                    //console.log(response);
                    me.detalles=response.data;
                    me.EstadoNC = me.detalles[0].estadoNC;
                    me.hora = me.detalles[0].hora;
                    me.ClaveComprobante = me.detalles[0].claveComprobante;
                    me.vendedor = me.detalles[0].vendedor;
                    me.comentario = me.detalles[0].detalle;
                }).catch(function(error){
                    console.log(error);
                });
            },
        selectVendedores(){
            let me=this;
            let header={"Authorization" : "Bearer " };
            let configuracion= {headers : header};
            var proveedoresArray=[];
            axios.get('api/Usuarios/SelectUsuariosLocalidad/'+me.$store.state.usuario.localidad,configuracion).then(function(response){
                proveedoresArray=response.data;
                proveedoresArray.map(function(x){
                    me.Vendedores.push(x.nombre);
                });
            }).catch(function(error){
                console.log(error);
            });
        },
        listarLocalidad(localidad){
            let me =this;
            let LogoTest= '';
            let self = this;
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
                axios.get('api/Localidades/ListarFiltrada/'+localidad,configuracion).then(function(response){
                    //console.log(response);
                    me.localidadencabezado=response.data;
                    me.Logo2 = me.localidadencabezado[0].imagen;
                }).catch(function(error){
                    console.log(error);
                });

        },
        FiltrarCliente(){
            this.usuarios = this.usuarios.filter(e => e.medioDePago == this.PagosFiltrados);
            this.snackbar = true;
            this.Mensaje = 'Filtro Medio de Pago Seleccionado sobre rango de fecha listado'
        },
        FiltrarVendedor(){
            let amp = "";
            this.Datos = this.usuarios;
            this.usuarios = this.usuarios.filter(e => e.nombreReceptor == this.ClienteSeleccionado);
            this.snackbar = true;
            this.Mensaje = 'Filtro de cliente seleccionado sobre rango de fecha listado'
        },
        FiltrarCliente2(){
            let amp = "";
            this.Datos = this.usuarios;
            this.usuarios = this.usuarios.filter(e => e.nombreReceptor == this.ClienteSeleccionado);
            this.snackbar = true;
            this.Mensaje = 'Filtro de vendedor seleccionado sobre rango de fecha listado'
        },
        FiltrarMoneda(){
            let amp = "";
            this.Datos = this.usuarios;
            amp = this.Datos.filter(e => e.moneda == this.MonedaFiltrar);
            this.usuarios = amp;
            this.snackbar = true;
            this.Mensaje = 'Filtro Medio de Moneda Seleccionado sobre rango de fecha listado'
        },
            verLocalidad(item){
            this.NombreLocalidad= item.nombre;
            this.CedulaLocalidad= item.cedulaJuridica;
            this.DireccionLocalidad= item.dirreccion;
            },
        ReenvioFactura(){
            if(this.correo2 == ''){
                this.snackbar = true;
                this.Mensaje = 'Digite el correo al que desea reenviar la factura'
                return;
            }
            let header={"Authorization" : "Bearer " + this.$store.state.token};
                let configuracion= {headers : header};
                    let me=this;
                    axios.put('api/CorreosFacturas/Reenviar',{
                        'NoFactura':me.noFactura,
                        'Estado': 0,
                        'Correo2': me.correo2,
                        'ClaveNumerica':me.ClaveComprobante,
                    },configuracion).then(function(response){
                        me.snackbar = true;
                        me.Mensaje = 'Correo reenviado con éxito a '+me.correo2;                     
                    }).catch(function(error){
                        console.log(error);
                        me.snackbar = true;
                        me.Mensaje = 'Reenvio de factura a '+me.correo2+'. Disponible proximamente';   
                    });
        },
        loadProductosMasVendidos(){
            let me=this;
            let mesn='';
            me.mesesValores.map(function(x){
            switch(parseInt(x.etiqueta)){
                case 1:
                mesn='Enero';
                break;
                case 2:
                mesn='Febrero';
                break;
                case 3:
                mesn='Marzo';
                break;
                case 4:
                mesn='Abril';
                break;
                case 5:
                mesn='Mayo';
                break;
                case 6:
                mesn='Junio';
                break;
                case 7:
                mesn='Julio';
                break;
                case 8:
                mesn='Agosto';
                break;
                case 9:
                mesn='Setiembre';
                break;
                case 10:
                mesn='Octubre';
                break;
                case 11:
                mesn='Noviembre';
                break;
                case 12:
                mesn='Diciembre';
                break;
                default:
                mesn='Error';
            }
            me.nombreMeses.push(mesn);
            me.totalMeses.push(x.valor);
            });
            var ctx = document.getElementById("myChart");
            var myChart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: me.nombreMeses,
                    datasets: [{
                        label: 'Compras en los últimos 12 Meses',
                        data: me.totalMeses,
                        backgroundColor: [
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)',
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)'
                        ],
                        borderColor: [
                            'rgba(255, 206, 86, 1)',
                            'rgba(255,99,132,1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)',
                            'rgba(255,99,132,1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        borderWidth: 1
                    }]
                },
                options: {
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero:true
                            }
                        }]
                    }
                }
            });
        },
        getProductosMasVendidos(){
            let me=this;
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
            axios.get('api/RepVentasVistas/ComprasMes12/'+me.$store.state.usuario.localidad,configuracion).then(function(response){
                //console.log(response);
                me.mesesValores=response.data;
                me.loadProductosMasVendidos();
            }).catch(function(error){
                console.log(error);
                alert("Error de conexión de graficos")
            });    
        },
        MostrarComprobante(item){
            this.nombreReceptor= item.nombreReceptor;
            this.cedulaReceptor = item.cedulaReceptor;
            this.vendedor = item.vendedor;
            this.comentario = item.detalle;
            this.correoReceptor = item.correoReceptor;
            this.noFactura = item.noFacturaReal;
            this.tipoCedulaReceptor = item.tipoCedulaReceptor;
            this.fecha = item.fecha;
            this.subTotal = item.gravado;
            this.total = item.ventaTotal;
            this.monedaPago = item.moneda;
            this.nofactura2 = "/ticket/"+this.noFactura;
            this.value = location.hostname+'/perfilcliente/'+this.$store.state.usuario.localidad+'/'+this.cedulaReceptor;
            this.grabado = item.gravado;
            this.exento = item.exento;
            this.iv = item.impuesto;
            this.listarDetalles(item.noFacturaReal);
            this.listarLocalidad(item.localidad);
            this.comprobanteModal = true;
        },
        MostrarComprobante2(value){
            this.MostrarComprobante(value);
            
        },
        NotaCredito(item){
            this.nombreReceptor= item.nombreReceptor;
            this.cedulaReceptor = item.cedulaReceptor;
            this.correoReceptor = item.correoReceptor;
            this.noFactura = item.noFacturaReal;
            this.tipoCedulaReceptor = item.tipoCedulaReceptor;
            this.fecha = item.fecha;
            this.tipoCedula = item.tipoCedulaReceptor;
            this.telefonoReceptor = item.telefonoReceptor;
            this.ClaveComprobante = item.claveComprobante;
            this.descuento = 0;
            this.subTotal = item.gravado;
            this.medioDePago = item.medioDePago
            this.total = item.ventaTotal;
            this.grabado = item.gravado;
            this.exento = item.exento;
            this.iv = item.impuesto;
            this.listarDetalles(item.noFacturaReal);
            this.listarLocalidad(item.localidad);
            this.ModalNotaCredito = 1;
        },
        OcultarComprobante(){
            this.comprobanteModal = 0;
        },
    CrearNotaCredito() 
    {
      if (this.validarPago() != 1)
      {
        return;
      }
          var localidadTicket = this.obtenerTicket();
        
    },
    obtenerTicket(){
                var self = this;
                var ticketsArray=[];
                var ticketNum = 0;
                let header={"Authorization" : "Bearer "};
                let configuracion= {headers : header};
                var localidadPrevia = "";
                var arrayTicket = [];
                    localidadPrevia = this.$store.state.usuario.localidad;
                
                axios.get('api/Facturas/SelectTickets', { params: {'localidad': localidadPrevia}},configuracion).then(function(response){
                    ticketsArray=response.data;
                    for(var i = 0; i < ticketsArray.length; ++i)
                    {
                       ticketNum = ticketsArray[i].noTicket;
                      break;
                    }  
                    self.auxVentasInsertar(ticketNum,localidadPrevia);
                }).catch(function(error){
                    console.log(error);
                });
        },
        validarPago(){
                this.valida=1;

                if (!this.idcliente && this.tipoFactura == 1){
                    alert("Seleccione un cliente para realizar Nota crédito electrónica");
                    this.valida=0;
                }
                if (!this.tipoCedulaReceptor){
                    alert("Seleccione un tipo de documento.");
                    this.valida=0;
                }
                if (this.EstadoNC == 1){
                    alert("Factura ya contiene nota de crédito.");
                    this.valida=0;
                }
                if (this.detalles.length == 0){
                    alert("Debe ingresar productos que facturar.");
                    this.valida=0;
                }
                return this.valida;
        },
        facturar() {
        if (this.validarPago() != 0)
        {
            this.vueltoPagar = "";
            this.detalleFactura = "";
            this.totalPagar = this.total;
            this.dialogPago = true;
            this.efectivoPagar = "";
            this.tarjetaPagar = "";
            this.obtenerCliente();
        }
        else
        {
            this.dialogPago = false;
            return;
        }
        },
        obtenerCliente(){
                var self = this;
                var clientesArray=[];
                var clientePrevio="";
                if (this.idcliente.text === undefined)
                {
                    clientePrevio = this.idcliente;
                }
                else
                {
                    clientePrevio = this.idcliente.text;
                }
                let header={"Authorization" : "Bearer "};
                let configuracion= {headers : header};
                axios.get('api/Empleados/ObtenerCliente', { params: {'clienteFactura': clientePrevio}},configuracion).then(function(response){
                    clientesArray=response.data;
                    for(var i = 0; i < clientesArray.length; ++i)
                    {
                      self.clienteNombre = clientesArray[i].nombre;
                      self.clienteCorreo = clientesArray[i].correo;
                      self.clienteCedula = clientesArray[i].cedula;
                      self.clienteTipoCedula = clientesArray[i].tipoCedula;
                      self.clienteTelefono = clientesArray[i].telefono;
                      break;
                    } 
                })
        },
        EliminarCompra(item){
          if (confirm("Seguro que desea eliminar la Compra de  "+item.proovedor)){
             
          var me = this;
          let header={"Authorization" : "Bearer " + this.$store.state.token};
          let configuracion= {headers : header};
            axios.post('api/ComprasHaciendas/EliminarCompra',{
                  'IdDocumento': item.idDocumento,
                      
                },configuracion).then(function (response) {
                    me.snackbar = true;
                    me.Mensaje = 'Factura Eliminada Con éxito.';  
                    me.listar();
                    
                  })
                  .catch(function (error) {
                      me.snackbar = true;
                      me.Mensaje = 'Error Eliminando.';  
                     me.listar();  
                })
            } 
    },
        auxVentasInsertar(ticket, localidad){
          var self = this;
          var documentoHacienda = "";
          var formaPago = 0;
          var ticketObtenido = ticket +1;
          var localidadObtenida = localidad;
          var momentoActual = new Date(); 
          var hora = momentoActual.getHours(); 
          var minuto = momentoActual.getMinutes(); 
          var segundo = momentoActual.getSeconds();

          let header={"Authorization" : "Bearer "};
                let configuracion= {headers : header};
                axios.post('api/Facturas/CrearAux',{
                      'Fecha': this.today.toString(),//MM/dd/YYYY
                      'Detalle':"NOTA DE CREDITO",
                      'NoFactura':ticketObtenido,
                      'Gravado': parseFloat(this.grabado),
                      'Exento': parseFloat(this.excento),
                      'Iv': parseFloat(this.iv),
                      'Total': this.total,
                      'Localidad': this.$store.state.usuario.localidad,
                      'Cajero': this.$store.state.usuario.nombre,
                      'TipoPago': 'EFECTIVO',
                      'Descuento': this.descuento,
                      'ImpServicio': 0,
                      'MontoExpress': 0,
                      'SubTotal': this.subtotal,
                      'MontoExpres': 0,
                      'TipoDocumento': 'NOTA DE CREDITO',
                      'Nocomprobante': 0,
                      'NoNotaCredito': "",
                      'CondicionVenta': "CONTADO",
                      'ClaveComprobante': 0,
                      'Moneda': this.monedaPago.toUpperCase(),//USD O CRC
                      'RefFacturaReal': this.noFactura,
                      'RefComprobante': "",
                      'razonReferencia': this.justificacion,
                      'MensajeRespuesta': "", //
                      'NombreReceptor': this.nombreReceptor,
                      'CedulaReceptor': this.cedulaReceptor,
                      'CorreoReceptor': this.correoReceptor,
                      'Razon': this.justificacion,
                      'RefFecha': this.fecha,
                      'Vuelto': 0,
                      'Impuesto1': 0,
                      'Impuesto4 ': 0,
                      'Impuesto13': this.iv,//Solo trabaja con 0 o 13%
                      'Impuesto2': 0,
                      'Sincronizado ': 0,
                      'Vendedor': this.$store.state.usuario.nombre,
                      'TipoCambio': 590,
                      'TotalSinR': this.total,
                      'Hora': hora + ":" + minuto + ":" + segundo,
                      'Proforma': 0,
                },configuracion).then(function (response) {
                    self.datosFactura(ticketObtenido,localidadObtenida,formaPago,documentoHacienda.toUpperCase());
                  })
                  .catch(function (error) {
                })
        },
    datosFactura(ticketNum,localidadObtenida,formaPagoObtenido,documentoHaciendaObtenido){
        var momentoActual = new Date() 
        var hora = momentoActual.getHours() 
        var minuto = momentoActual.getMinutes() 
        var segundo = momentoActual.getSeconds() 
        var self = this;
        var documentoHacienda = 0;
        documentoHacienda = this.tipoFactura;
        
        for(var i = 0; i < this.detalles.length; ++i)
        {
          this.productosDatos.push(
            {
                CodigoProducto:this.detalles[i].idarticulo,
                Cantidad:this.detalles[i].cantidad,
                UnidadMedida:"UND",
                Descripcion:this.detalles[i].descripcion,
                PrecioUnitario:this.detalles[i].precio,
                MontoDescuento:this.detalles[i].descuento,
                ValorImpuesto:this.detalles[i].valorImpuesto,
                Gravado:this.detalles[i].gravado
            }
            );
          }
        let header={"Authorization" : "Bearer "};
        let configuracion= {headers : header};
        axios.post('api/Facturas/CrearDatosNota',{
                      'NoFacturaReal': ticketNum,
                      'TipoDocumento': 3,
                      'Nocomprobante': 0,
                      'CondicionVenta':"CONTADO",
                      'Moneda': this.monedaPago.toUpperCase(),//USD O CRC
                      'MedioDePago': this.medioDePago,
                      'CodigoProducto': "",
                      'Cantidad': 0,
                      'UnidadMedida': "UND",
                      'Descripcion': "",
                      'PrecioUnitario': 0,
                      'MontoDescuento': 0,
                      'DiasCredito': 15,
                      'Gravado': "",//S O N  S= GRAVADO O N= EXENTO
                      'Cajero': this.$store.state.usuario.nombre,
                      'ClaveComprobante':0,
                      'RefFacturaReal': this.noFactura,
                      'RefComprobante': this.ClaveComprobante,
                      'razonReferencia': this.justificacion,
                      'MensajeRespuesta': this.MensajeRespuesta,
                      'CodicionComprobante': "",
                      'EstadoComprobante': 0,
                      'FechaEnvio': "",
                      'FechaEstado': "",
                      'NombreReceptor': this.nombreReceptor,
                      'CedulaReceptor': this.cedulaReceptor,
                      'CorreoReceptor': this.correoReceptor,
                      'TipoCedulaReceptor': this.tipoCedulaReceptor.toUpperCase(), //FISICA, JURIDICA, EXTRANJERO
                      'TelefonoReceptor': this.telefonoReceptor,
                      'Localidad': this.$store.state.usuario.localidad,
                      'Razon': this.justificacion,
                      'RefFecha': this.fecha + " " + this.hora,
                      'Exonerado': false,
                      'Correo2': "",
                      'ValorImpuesto': 0, //0,1,2,4,13
                      'Detalle':'NOTA DE CREDITO',
                      'Sincronizado ': 0,
                      'Vendedor':this.$store.state.usuario.nombre,
                      'EstadoNC': 0,
                      'EstadoCierre': 0,
                      'Producto': this.detalles,
                  },configuracion).then(function (response) {
                    self.Mensaje = 'Nota de crédito realizada correctamente';
                    self.snackbar = true,
                    self.vueltoPagar = 0;
                    self.insertarTicket(ticketNum,localidadObtenida);
                  })
                  .catch(function (error) {
                    alert("Error en nota de crédito, favor revisar sus información o solicite ayuda al 4070-1889");
                })
        },
        insertarTicket(ticketNum, localidad){
                this.dialogPago = false;
                this.productos = [];
                this.barbero = "";
                this.idcliente= "";
                this.totalPagar = "";
                this.vueltoPagar = "";
                this.limpiar();
                let header={"Authorization" : "Bearer "};
                let configuracion= {headers : header};
                this.loading  = true;
                let me=this;
                axios.post('api/Facturas/CrearTicket',{
                    'NoTicket':ticketNum,
                    'Operador':localidad,
                    'Localidad':localidad,
                },configuracion).then(function(response){ 
                  me.dialogFactura = false;  
                  location.reload();                 
                }).catch(function(error){
                    console.log(error);
                });
    },
     limpiar(){
                this.MensajeRespuesta="";
                this.ClaveComprobante="";
                this.localidad="";
                this.detalles=[];
                this.name = '',
                this.details = '',
                this.start = '',
                this.end = '',
                this.color = '',
                this.hora = '',
                this.hora2 = '',
                this.codigo = ''
    },  
        crearPDF(){
           var columns = [
            {title: "Factura", dataKey: "noFacturaReal"},
            {title: "Estado", dataKey: "estado"}, 
            {title: "Fecha", dataKey: "fecha"}, 
            {title: "Excento", dataKey: "excento"},
            {title: "IVA", dataKey: "iva"},
            {title: "Total", dataKey: "ventaTotal"},
            {title: "Cliente", dataKey: "cliente"},
            ];
            var rows = [];

            this.usuarios.map(function(x){
                rows.push({noFacturaReal:x.noFacturaReal,excento:x.exento,estado:x.mensajeRespuesta,fecha:x.fecha,iva:x.impuesto,ventaTotal:x.ventaTotal,cliente:x.nombreReceptor});
            });

            // Only pt supported (not mm or in)
            var doc = new jsPDF('p', 'pt');
            doc.autoTable(columns, rows, {
                margin: {top: 60},
                addPageContent: function(data) {
                    doc.text("Listado de Ventas", 40, 30);
                }
            });
            doc.save('ReporteVentas.pdf');
        },
        crearPDFFactura(){
            var fact = this.noFactura;
             var quotes = document.getElementById('factura');
                html2canvas(quotes).then(function (canvas) {
                    var imgData = canvas.toDataURL('image/png');
                    var imgWidth = 210;
                    var pageHeight = 285;
                    var imgHeight = canvas.height * imgWidth / canvas.width;
                    var heightLeft = imgHeight;
                    var doc = new jsPDF();
                    var position = 0;

                    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);                    
                    doc.save('ComprobanteVenta'+fact+'.pdf');
                });
        },

        getColor (mensajeRespuesta) {
        if (mensajeRespuesta == 'aceptado') return '#8BC34A'
        if(mensajeRespuesta == 'Sin procesar') return 'orange'
        if(mensajeRespuesta == 'procesando') return '#42A5F5'
        else  return '#FF1744'
      },
       listar(){
           
            let me=this;
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
            axios.get('api/ComprasHaciendas/Listar/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
                //console.log(response);
                me.usuarios=response.data;
            }).catch(function(error){
                console.log(error);
            });
        },
        listarFechas(){
            if(this.start == '' || this.end == ''){
                this.snackbar = true;
                this.Mensaje = 'Debe seleccionar una fecha inicial y final'
                return;
           }
            let me=this;
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
            axios.get('api/ComprasHaciendas/ConsultaFechas/'+this.start+'/'+this.end+'/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
                //console.log(response);
                me.usuarios=response.data;
            }).catch(function(error){
                console.log(error);
            });
        },

      close () {
        this.dialog = false;
        this.limpiar();
          },
    

      activarDesactivarMostrar(accion,item){
                this.adModal=1;
                this.adNombre=item.nombre;
                this.adId=item.idusuario;                
                if (accion==1){
                    this.adAccion=1;
                }
                else if (accion==2){
                    this.adAccion=2;
                }
                else{
                    this.adModal=0;
                }
            },
            activarDesactivarCerrar(){
                this.adModal=0;
            },
      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.desserts[this.editedIndex], this.editedItem)
        } else {
          this.desserts.push(this.editedItem)
        }
        this.close()
      },
    },
  mounted(){
      this.getProductosMasVendidos();
  }
  }

</script>
My CRUD