<template>
  <v-data-table
    :headers="headers"
    :items="usuarios"
    :search="search"
    sort-by="nombre"
    class="elevation-1"

  >
    <template v-slot:top>
		<v-toolbar flat color="white">
			<v-toolbar-title>Reparaciones</v-toolbar-title>
			<v-divider
			class="mx-4"
			inset
			vertical
			></v-divider>
		<v-spacer></v-spacer>
			<v-text-field
			v-model="search"
			append-icon="mdi-magnify"
			label="Buscar"
			single-line
			hide-details
			></v-text-field>
			<v-spacer></v-spacer>
			<v-dialog v-model="dialog" max-width="1200">
			<template v-slot:activator="{ on }">
				<v-btn color="info" dark class="mb-2" v-on="on">Nueva Reparación</v-btn>
				</template>
				<v-card>
					<v-toolbar dark color="primary">
						<v-toolbar-title>Nueva Reparación</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-toolbar-items>
							<v-btn icon dark @click="dialog = false">
							<v-icon>mdi-close</v-icon>
							</v-btn>
						</v-toolbar-items>
					</v-toolbar>
					<v-container fluid>
					<v-row>
						<v-col cols="12" sm="12">
						<v-form ref="form" v-model="validCliente"  @submit.prevent="RegistrarOrden()">
							<v-row>
								<v-col cols="12" sm="6">
								<v-card-actions > 
								<v-combobox outlined="" clearable @change="obtenerCliente()" dense append-icon="mdi-account-circle-outline" v-model="idcliente" :items="clientes" label="Cliente"></v-combobox>
									<div>   
										<v-btn  small fab color="primary" id="clienteBoton" dark @click.stop="dialogcliente = true">
											<v-icon > mdi-account-multiple-plus </v-icon>
										</v-btn>
									</div>  
								</v-card-actions>
								</v-col>
								<v-col cols="12" sm="3">
								<v-text-field clearable dense outlined v-model="referencia" :rules="nameRules" required type="text" label="Referencia"></v-text-field>
								</v-col>
								<v-col cols="12" sm="3">
								<v-text-field clearable dense outlined v-model="domicilio" type="text" label="Domicilio"></v-text-field>
								</v-col>
								<v-col cols="12" sm="2">
								<v-text-field clearable dense outlined v-model="articulo" type="text" label="Articulo"></v-text-field>
								</v-col>
								<v-col cols="12" sm="2">
								<v-text-field   clearable dense outlined v-model="marca" type="text" label="Marca"></v-text-field>
								</v-col>
								<v-col cols="12" sm="2">
								<v-text-field clearable dense outlined v-model="modelo" :rules="[v => !!v || 'El Modelo es requerido']" type="text" label="Modelo"></v-text-field>
								</v-col>
								<v-col cols="12" sm="3">
								<v-text-field clearable dense outlined v-model="serie" type="tel" label="Serie o Placa"></v-text-field>
								</v-col>
								<v-col cols="12" sm="3">
									<v-select  dense outlined v-model="vendedor" append-icon="mdi-briefcase-account" :items="Vendedores" color="success" :label="`Técnico a cargo`"></v-select>
								</v-col>
								<v-col cols="12" sm="6" class="red--text">
								<v-textarea v-model="accesorios"  auto-grow
									outlined
									rows="1"
									dense
									row-height="30"
									shaped label="Accesorios"></v-textarea>
								</v-col>
								<v-col cols="12" sm="6" class="red--text">
								<v-textarea v-model="estado"  auto-grow
									outlined
									rows="1"
									dense
									row-height="30"
									shaped label="Estado del Articulo"></v-textarea>
								</v-col>
								<v-col cols="12" sm="6" class="red--text">
								<v-textarea v-model="falla"  auto-grow
									outlined
									rows="1"
									dense
									row-height="30"
									shaped label="Falla reportada"></v-textarea>
								</v-col>
								<v-col cols="12" sm="6" class="red--text">
								<v-textarea v-model="nota"  auto-grow
									outlined
									rows="1"
									dense
									row-height="30"
									shaped label="Nota del Cliente"></v-textarea>
								</v-col>
								<v-col cols="12" sm="6">
								<v-text-field  clearable v-model="costo"  required type="text" label="Costo del servicio"></v-text-field>
								<v-text-field  clearable v-model="adelanto"  required type="text" label="Adelanto"></v-text-field>
								Saldo: 
								</v-col>
								<v-col cols="12" sm="6" class="red--text">
								{{error}} 
								</v-col>
								<v-btn :loading="loading" type="submit" :disabled="!validCliente" color="primary" id="espacioBoton"  class="mr-4" style="top: 10px;">
									Registrar Orden
								</v-btn>
								<v-btn color="teal darken-1" id="espacioBoton" class="mr-4" style="top: 10px; color: white;" @click="limpiar()">
								<v-icon> mdi-update </v-icon>
								</v-btn>
								<v-btn color="error" id="espacioBoton" @click="dialog = false">
								<v-icon> mdi-exit-to-app </v-icon>
								</v-btn>
							</v-row>
						
						</v-form>
						</v-col>
					</v-row>
					</v-container>
					</v-card>
			</v-dialog>
			<v-dialog
			v-model="modelCarga"
			width="300"
			dark
			>
			<v-card
				color="white"
				
			>
				<v-card-text class="black--text">
				Por Favor, Espere..
				<v-progress-linear
					indeterminate
					color="black"
					class="mb-0"
				></v-progress-linear>
				</v-card-text>
				<v-img
					src="https://i.pinimg.com/originals/e2/6d/87/e26d873ee68fbcd9dc1c40d8b0c6b3b4.gif"
					class="my-3"
					contain
					height="200"
				></v-img>
			</v-card>
			</v-dialog>
			<v-dialog v-model="dialogcliente" max-width="1200">
				<v-card>
					<v-toolbar dark color="primary">
						<v-toolbar-title>{{ formTitle }}</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-toolbar-items>
							<v-btn icon dark @click="dialog = false">
							<v-icon>mdi-close</v-icon>
							</v-btn>
						</v-toolbar-items>
					</v-toolbar>
					<v-container fluid>
					<v-row>
						<v-col cols="12" sm="12">
						<v-form ref="form" v-model="validCliente"  @submit.prevent="RegistrarOrden()">
							<v-row>
								<v-col cols="12" sm="3">
								<v-text-field @change="BuscarPersona()" clearable v-model="cedula" :rules="cedulaRules" required type="text" label="Número Documento"></v-text-field>
								</v-col>
								<v-col cols="12" sm="6">
								<v-text-field clearable v-model="nombre" :rules="nameRules" required type="text" label="Nombre"></v-text-field>
								</v-col>
								<v-col cols="12" sm="3">
								<v-select v-model="tipoDocumento" :rules="[v => !!v || 'El tipo de documento es requerido']" :items="tipoDocumentos" label="Tipo Documento"></v-select>
								</v-col>
								<v-col cols="12" sm="2">
								<v-text-field clearable v-model="telefono" type="tel" label="Teléfono"></v-text-field>
								</v-col>
								<v-col cols="12" sm="2">
								<v-text-field   clearable v-model="celular" type="tel" label="Telefono 2"></v-text-field>
								</v-col>
								<v-col cols="12" sm="2">
								<v-text-field clearable v-model="DiasCreditoCliente" :rules="[v => !!v || 'Los días son Requeridos']" type="tel" label="Días de crédito"></v-text-field>
								</v-col>
								<v-col cols="12" sm="2">
								<v-text-field clearable v-model="edad"  type="tel" label="Edad"></v-text-field>
								</v-col>
								<v-col cols="12" sm="2">
								<v-select clearable v-model="genero" :items="generos" :rules="[v => !!v || 'EL genero es Requerido']" type="tel" label="Género"></v-select>
								</v-col>
								<v-col cols="12" sm="2">
								<v-text-field clearable v-model="limiteCredito"  type="tel" label="Limite crédito"></v-text-field>
								</v-col>
								<v-col cols="12" sm="3">
								<v-text-field clearable v-model="fechanacimiento" :rules="[v => !!v || 'Debe seleccionar alguna fecha']" type="date" label="Fecha Nacimiento"></v-text-field>
								</v-col>
								<v-col cols="12" sm="3">
								<v-text-field clearable v-model="correo" :rules="emailRules" type="email" label="Correo Electrónico"></v-text-field>
								</v-col>
								<v-col cols="12" sm="2">
								<v-text-field clearable v-model="correo2"  type="email" label="Correo secundario"></v-text-field>
								</v-col>
								<v-col cols="12" sm="2">
								<v-select clearable v-model="listaDePrecio" :items="listasDePrecios"  type="tel" label="Lista de Precio"></v-select>
								</v-col>
								<v-col class="mt-4" cols="12" sm="2">
									<v-select  dense v-model="vendedor" append-icon="mdi-briefcase-account" :items="Vendedores" color="success" :label="`Vendedor`"></v-select>
								</v-col>
								<v-col cols="12" sm="6" class="red--text">
								<v-textarea v-model="direccion"  auto-grow
									outlined
									rows="3"
									dense
									row-height="30"
									shaped label="Dirección"></v-textarea>
								</v-col>
								<v-col cols="12" sm="6">
								<v-text-field  clearable v-model="nombreFantasia"  required type="text" label="Nombre Comercial o fantasía"></v-text-field>
								</v-col>
								<v-col cols="12" sm="6" class="red--text">
								{{error}} 
								</v-col>
								<v-btn :loading="loading" type="submit" :disabled="!validCliente" color="primary" id="espacioBoton"  class="mr-4" style="top: 10px;">
								{{ formTitle }}
								</v-btn>
								<v-btn color="teal darken-1" id="espacioBoton" class="mr-4" style="top: 10px; color: white;" @click="limpiar()">
								<v-icon> mdi-update </v-icon>
								</v-btn>
								<v-btn color="error" id="espacioBoton" @click="dialog = false">
								<v-icon> mdi-exit-to-app </v-icon>
								</v-btn>
							</v-row>
						
						</v-form>
						</v-col>
					</v-row>
					</v-container>
					</v-card>
			</v-dialog>

			</v-toolbar>
	</template>
	<template v-slot:item.estado="props">
			<v-edit-dialog
			:return-value.sync="props.item.id"
			@save="ActualizarOrden(props.item)"
			@cancel="cancel"
			@close="close"
			large
			>
				<v-chip :color="getColor(props.item.estado)" :text-color="getColorText(props.item.estado)">• {{ props.item.estado }}</v-chip> <br>
			<template v-slot:input>
				<v-select  v-model="props.item.estado" :items="estadosReparaciones" label="Estado"/>
			</template>
			</v-edit-dialog>
	</template>
	<template v-slot:item.cliente="props">
			<v-edit-dialog
			:return-value.sync="props.item.cliente"
			@save="ActualizarOrden(props.item)"
			@cancel="cancel"
			@close="close"
			large
			>
			{{props.item.cliente}}
			<template v-slot:input>
				<v-text-field  v-model="props.item.cliente" label="Nombre"/>
			</template>
			</v-edit-dialog>
	</template>
	<template v-slot:item.nota="props">
			<v-edit-dialog
			:return-value.sync="props.item.nota"
			@save="ActualizarOrden(props.item)"
			@cancel="cancel"
			@close="close"
			large
			>
			{{props.item.nota}}
			<template v-slot:input>
				<v-text-field  v-model="props.item.nota" label="nota"/>
			</template>
			</v-edit-dialog>
	</template>
	<template v-slot:item.falla="props">
			<v-edit-dialog
			:return-value.sync="props.item.falla"
			@save="ActualizarOrden(props.item)"
			@cancel="cancel"
			@close="close"
			large
			>
			{{props.item.falla}}
			<template v-slot:input>
				<v-text-field  v-model="props.item.falla" label="falla"/>
			</template>
			</v-edit-dialog>
	</template>
	<template v-slot:item.costo="props">
			<v-edit-dialog
			:return-value.sync="props.item.costo"
			@save="ActualizarOrden(props.item)"
			@cancel="cancel"
			@close="close"
			large
			>
			{{props.item.costo}}
			<template v-slot:input>
				<v-text-field  v-model="props.item.costo" label="costo"/>
			</template>
			</v-edit-dialog>
	</template>
	<template v-slot:item.adelanto="props">
			<v-edit-dialog
			:return-value.sync="props.item.adelanto"
			@save="ActualizarOrden(props.item)"
			@cancel="cancel"
			@close="close"
			large
			>
			{{props.item.adelanto}}
			<template v-slot:input>
				<v-text-field  v-model="props.item.adelanto" type="num" label="costo"/>
			</template>
			</v-edit-dialog>
	</template>
	<template v-slot:item.tecnico="props">
			<v-edit-dialog
			:return-value.sync="props.item.tecnico"
			@save="ActualizarOrden(props.item)"
			@cancel="cancel"
			@close="close"
			large
			>
			{{props.item.tecnico}}
			<template v-slot:input>
				<v-select :items="Vendedores"  v-model="props.item.tecnico" label="tecnico"/>
			</template>
			</v-edit-dialog>
	</template>
    <template v-slot:item.action="{ item }">
      <v-icon
        
        class="mr-2"
        color="primary" 
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <template >
      <v-icon
        color="error"
        @click="EliminarOrden(item)"
      > 
        mdi-delete
      </v-icon>
    </template>
     
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">Reset</v-btn>
    </template>
  </v-data-table>
</template>

<script>
import axios from 'axios'

  	export default {
	data: () => ({
      
		dialog: false,
		search: '',
		idcliente: '',
		referencia: '',
		modelo: '',
		modelCarga: true,
		articulo: '',
		marca: '',
		Placa:'',
		accesorios: '',
		estado: '',
		falla: '',
		nota: '',
		costo: 0,
		adelanto: 0,
		headers: [
			{ text: 'Opciones', value: 'action', sortable: false },
			{ text: 'Id', value: 'id' },
			{ text: 'Cliente', value: 'cliente' },
			{ text: 'Articulo', value: 'articulo' },
			{ text: 'Marca', value: 'marca' },
			{ text: 'Modelo', value: 'modelo' },
			{ text: 'Tecnico', value: 'tecnico' },
			{ text: 'Estado', value: 'estado', },
			{ text: 'Falla', value: 'falla',  },
			{ text: 'Nota', value: 'nota',  },
			{ text: 'Fecha', value: 'fecha',},
			{ text: 'Usuario', value: 'usuario'},
			{ text: 'Costo', value: 'costo',},    
			{ text: 'Adelanto', value: 'adelanto',},          
		],
		search: '',
			editedIndex: -1,
			id: '',
			idrol:'',
			generos: ["MASCULINO","FEMENINO"],
			tipoDocumentos: [
			'FISICA',
			'JURIDICA',
			'EXTRANJERO',
			],
			roles:[                   
			],
			nombre:'',
			dialogcliente: false,
			correo2: '',
			error: null,
			correo: '',
			DiasCreditoCliente: 15,
			tipo_documento: '',
			documentos: ['DNI','DIMEX','Cédula Fisica','Cédula Juridica'],
			num_documento: '',
			direccion: '',
			cedula: '',
			edad: 0,
			listasDePrecios: ["Precio Venta", "Precio Detalle", "Precio Central","Precio Mayor", "Precio Mall"],
			listaDePrecio: 'Precio Venta',
			genero: '',
			direccion: '',
			fechanacimiento :'',
			estadosReparaciones:["PENDIENTE","REPARADO","NO SE PUDO REPARAR","ENTREGADO"],
			Vendedores : [],
			usuarios:[],    
			telefono: '',
			localidades:[],
			localidadesColor:[],
			idusuario: '',
			idLocalidadCliente: null,
			localidad: null,
			condicion: true,
			cedulaRules: [
			value => !!value || 'La cédula es requerida'
			],
			nameRules: [
				value => !!value || 'El nombre es requerido',
				value => (value && value.length >= 3) || 'Debe ingresar 3 carácteres como mínimo',
			],
			emailRules: [
			v => !!v || 'El correo electrónico es requerido',
			v => /.+@.+\..+/.test(v) || 'El correo electrónico es requerido debe ser válido',
			] ,
			email: '',
			password:'',
			actPassword:false,
			vendedor: '',
			nombreFantasia: "",
			passwordAnt:'',
			valida: 0,
			validaMensaje:[],
			loading: false,
			NoEmpleado: '',
			adModal: 0,
			celular:"",
			limiteCredito: 0,
			adAccion: 1,
			adNombre: '',
			adId: ""  ,
			clientes: [],
			desserts: [],
			login : '9781e684a504e3cd84b5331b2f9cbc9e',
			secretKey : 'ZPS8GwCyc40z1l0y'
    }),

    computed: {
      formTitle () {
                return this.editedIndex === -1 ? 'Nuevo Cliente' : 'Actualizar Cliente'
        },

    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      
    },

    created () {
      this.listar();
      this.selectVendedores();
      this.select();
    },

    methods: {
		getColor (mensajeRespuesta) {
			if (mensajeRespuesta == 'PENDIENTE') return 'indigo lighten-4'
			if(mensajeRespuesta == 'REPARADO') return 'orange lighten-4'
			if(mensajeRespuesta == 'NO SE PUDO REPARAR') return 'red-lighten-4'
			else  return 'ENTREGADO'
		},
		getColorText (mensajeRespuesta) {
			if (mensajeRespuesta == 'PENDIENTE') return 'indigo darken-4'
			if(mensajeRespuesta == 'REPARADO') return 'orange darken-4'
			if(mensajeRespuesta == 'NO SE PUDO REPARAR') return 'red-darken-4'
			else  return 'ENTREGADO'
		},
		select(){
			let me=this;
			var clientesArray=[];
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
				axios.get('api/Empleados/SelectEmpleadosLocalidad/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
				clientesArray=response.data;
				clientesArray.map(function(x){
					me.clientes.push({text: (x.nombre+' | '+x.nombreFantasia), text2:x.nombre,value:x.idEmpleado});
				});
			})
		},
		obtenerCliente(){
			this.idcliente = this.idcliente.text2 ?? this.idcliente;
		},
		async RegistrarOrden(){
			this.loading = true;
			this.error=null;
			let fechaActual = new Date(); // Esto obtiene la fecha y hora actual en JavaScript
			let fechaISO = fechaActual.toISOString(); // Esto convierte la fecha a una cadena en formato ISO 8601
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			let me=this;
			await axios.post('api/Reparaciones',{
					'Cliente':this.idcliente,
					'Articulo':this.articulo,
					'Domicilio': this.domicilio,
					'Marca': this.marca,
					'Modelo':this.modelo,
					'Tecnico': this.vendedor,
					'Falla':this.falla,
					'Nota': this.correo2,
					'Fecha':fechaISO, // Ingresar Fecha aqui
					'Serie':this.serie,
					'Adelanto':this.adelanto,
					'Referencia':this.referencia,
					'Accesorios':this.genero,
					'Usuario':this.$store.state.usuario.nombre,
					'Estado':'Pendiente',
					'Costo':this.costo,
					'Localidad':this.$store.state.usuario.localidad,
				},configuracion).then(function(response){
					//alert("Orden Registrada con exito");
					me.Mensaje = 'Registrado con exito '+response;
					me.snackbar = true;
					me.dialog = false;
					me.listar();
					me.loading =false;                      
				}).catch(err => {
					this.loading =false;
					//console.log(err.response);
					if (err.response.status==400){
						this.error="Correo ocupado por otro cliente";
					} else if (err.response.status==404){
						this.error="Cédula Ocupada por otro cliente";
					}else{
						this.error="Validar Campos Vacios";
					}
				});
		},
		async ActualizarOrden(item){
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			let me=this;
			await axios.put('api/Reparaciones/'+item.id,{
					'Cliente':item.cliente,
					'Articulo':item.articulo,
					'ID':item.id,
					'Domicilio': item.domicilio,
					'Marca': item.marca,
					'Modelo':item.modelo,
					'Tecnico': item.tecnico,
					'Falla':item.falla,
					'Nota': item.nota,
					//'Fecha':fechaISO, // Ingresar Fecha aqui
					'Serie':item.serie,
					'Adelanto':item.adelanto,
					'Referencia':item.referencia,
					'Usuario':this.$store.state.usuario.nombre,
					'Accesorios':item.genero,
					'Estado':item.estado,
					'Costo':item.costo,
					'Localidad':this.$store.state.usuario.localidad,
				},configuracion).then(function(response){
					//alert("Orden Registrada con exito");
					me.Mensaje = 'Actualizado con exito '+response;
					me.snackbar = true;
					me.dialog = false;
					me.listar();
					me.loading =false;                      
				}).catch(err => {
					this.loading =false;
					//console.log(err.response);
					if (err.response.status==400){
						this.error="Correo ocupado por otro cliente";
					} else if (err.response.status==404){
						this.error="Cédula Ocupada por otro cliente";
					}else{
						this.error="Validar Campos Vacios";
					}
				});
		},
		async EliminarOrden(item){
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			let me=this;
			await axios.delete('api/Reparaciones/'+item.id,{
					'Cliente':item.cliente,
					'Articulo':item.articulo,
					'ID':item.id,
					'Domicilio': item.domicilio,
					'Marca': item.marca,
					'Modelo':item.modelo,
					'Tecnico': item.tecnico,
					'Falla':item.falla,
					'Nota': item.nota,
					//'Fecha':fechaISO, // Ingresar Fecha aqui
					'Serie':item.serie,
					'Adelanto':item.adelanto,
					'Referencia':item.referencia,
					'Usuario':this.$store.state.usuario.nombre,
					'Accesorios':item.genero,
					'Estado':item.estado,
					'Costo':item.costo,
					'Localidad':this.$store.state.usuario.localidad,
				},configuracion).then(function(response){
					//alert("Orden Registrada con exito");
					me.Mensaje = 'Actualizado con exito '+response;
					me.snackbar = true;
					me.dialog = false;
					me.listar();
					me.loading =false;                      
				}).catch(err => {
					this.loading =false;
					//console.log(err.response);
					if (err.response.status==400){
						this.error="Correo ocupado por otro cliente";
					} else if (err.response.status==404){
						this.error="Cédula Ocupada por otro cliente";
					}else{
						this.error="Validar Campos Vacios";
					}
				});
		},
		async listar(){
			let me=this;
			this.localidad = me.$store.state.usuario.localidad;
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			await axios.get('api/Reparaciones/ListarReparaciones/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
				//console.log(response);
				me.usuarios=response.data;
				me.modelCarga = false;
			}).catch(function(error){
				console.log(error);
			});
		},

		close () {
			this.dialog = false;
			this.limpiar();
		},
      
		limpiar(){
			this.nombre = "";
			this.tipoDocumento = "";
			this.cedula = "";
			this.genero = "",
			this.edad = 0,
			this.idLocalidadCliente = "";
			this.telefono = "";
			this.correo = "";
			this.correo2 = "";
			this.limiteCredito = 0;
			this.DiasCreditoCliente = 0;
		},
		selectVendedores(){
			this.vendedor = this.$store.state.usuario.nombre;
			let me=this;
			let header={"Authorization" : "Bearer " };
			let configuracion= {headers : header};
			var proveedoresArray=[];
			axios.get('api/Usuarios/SelectUsuariosLocalidad/'+me.$store.state.usuario.localidad,configuracion).then(function(response){
				proveedoresArray=response.data;
				proveedoresArray.map(function(x){
					me.Vendedores.push(x.nombre);
				});
			}).catch(function(error){
				console.log(error);
			});
		},
		BuscarPersona(){
			this.modelproveedor = true;
			let me=this;
			var familiasArray=[];
			axios.get('https://apis.gometa.org/cedulas/'+this.cedula+'&key=u0vSHKh0dVRckNH').then(function(response){
				familiasArray=response.data;
				me.nombre = familiasArray.results[0].fullname;
				me.tipoDocumento = familiasArray.results[0].guess_type
				
				
			}).catch(function(error){
			this.snackbar = true;
			this.Mensaje = 'Error de conexión con el TSE, digite los datos de forma manual'
				console.log(error);
			});
		},
		guardar() {
			this.loading = true;
			this.error=null;
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			if (this.editedIndex > -1) {
				//Código para editar
				//Código para guardar
				let me=this;
				if (me.password!=me.passwordAnt){
					me.actPassword=true;
				}
				axios.put('api/Empleados/Actualizar',{
						'Nombre':this.nombre,
						'TipoCedula':this.tipoDocumento,
						'Cedula': this.cedula,
						'NoEmpleado': this.NoEmpleado,
						'Telefono':this.telefono,
						'Periodo': this.DiasCreditoCliente,
						'Correo':this.correo,
						'Correo2': this.correo2,
						'Edad':this.edad,
						'Fecha':this.fechanacimiento,
						'ListaPrecio':this.listaDePrecio,
						'Genero':this.genero,
						'Cantidad':this.limiteCredito,
						'Facebook':this.direccion,
						'Vendedor':this.vendedor,
						'NombreFantasia':this.nombreFantasia,
						'Celular':this.celular,
				},configuracion).then(function(response){
					me.close();
					me.listar();
					me.limpiar();  
					me.loading =false;                      
				}).catch(err => {
					this.loading =false;
					//console.log(err.response);
					if (err.response.status==400){
						this.error="Correo ocupado por otro cliente";
					} else if (err.response.status==404){
						this.error="Cédula Ocupada por otro cliente";
					}else{
						this.error="Validar Campos Vacios";
					}
				});
			} else {
				//Código para guardar
				let me=this;
				axios.post('api/Empleados/Crear',{
						'Nombre':this.nombre,
						'TipoCedula':this.tipoDocumento,
						'Cedula': this.cedula,
						'Localidad':this.$store.state.usuario.localidad,
						'Telefono':this.telefono,
						'Periodo': this.DiasCreditoCliente,
						'Correo':this.correo,
						'Fecha':this.fechanacimiento,
						'Correo2': this.correo2,
						'Edad':this.edad,
						'ListaPrecio':this.listaDePrecio,
						'Genero':this.genero,
						'Cantidad':this.limiteCredito,
						'Facebook':this.direccion,
						'Vendedor':this.vendedor,
						'NombreFantasia':this.nombreFantasia,
						'Celular':this.celular,
				},configuracion).then(function(response){
					me.close();
					me.listar();
					me.limpiar(); 
					me.loading =false;                       
				}).catch(err => {
					this.loading =false;
					//console.log(err.response);
					if (err.response.status==400){
						this.error="Correo ocupado por otro cliente";
					} else if (err.response.status==404){
						this.error="Cédula Ocupada por otro cliente";
					}else{
						this.error="Validar Campos Vacios";
					}
						});
					}
		},

		editItem (item) {
			this.id=item.idusuario;
			this.DiasCreditoCliente=item.periodo;
			this.direccion = item.facebook;
			this.nombre=item.nombre;
			this.cedula = item.cedula;
			this.tipoDocumento=item.tipoCedula;
			this.limiteCredito=item.cantidad;
			this.NoEmpleado = item.noEmpleado;
			this.edad=item.edad;
			this.telefono=item.telefono;
			this.listaDePrecio = item.listaPrecio;
			this.correo=item.correo;
			this.nombreFantasia= item.nombreFantasia;
			this.correo2=item.correo2;
			this.fechanacimiento=item.fecha;
			this.genero=item.genero;
			this.editedIndex=1;
			this.celular= item.celular;
			this.dialog = true;
			this.vendedor = item.vendedor;
		},
    
		cargarColor(){
			for(var i = 0; i < this.localidadesColor.length; ++i)
			{
				if(this.localidadesColor[i].value == this.localidad.value){
						this.color = this.localidadesColor[i].color;
						break;
				}
			}
			},
		validar(){
			this.valida=0;
			this.validaMensaje=[];

			if (this.nombre.length<3 || this.nombre.length>100){
				this.validaMensaje.push("El nombre debe tener más de 3 caracteres y menos de 100 caracteres.");
			}
			if (!this.idrol){
				this.validaMensaje.push("Seleccione un rol.");
			}
			if (!this.tipo_documento){
				this.validaMensaje.push("Seleccione un tipo documento.");
			}

			if (!this.email){
				this.validaMensaje.push("Ingrese el email del usuario.");
			}
			if (!this.password){
				this.validaMensaje.push("Ingrese el password del usuario.");
			}
			if (this.validaMensaje.length){
				this.valida=1;
			}
			return this.valida;
        },

      

		close () {
			this.dialog = false
			setTimeout(() => {
			this.editedItem = Object.assign({}, this.defaultItem)
			this.editedIndex = -1
			}, 300)
		},
      	activarDesactivarMostrar(accion,item){
			this.adModal=1;
			this.adNombre=item.nombre;
			this.adId=item.idusuario;                
			if (accion==1){
				this.adAccion=1;
			}
			else if (accion==2){
				this.adAccion=2;
			}
			else{
				this.adModal=0;
			}
		},
		activarDesactivarCerrar(){
			this.adModal=0;
		},
       activar(item){
			let me=this;
			this.adId=item.idusuario;
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			axios.put('api/Usuarios/Activar/'+this.adId,{},configuracion).then(function(response){
				me.adModal=0;
				me.adAccion=0;
				me.adNombre="";
				me.adId="";
				me.listar();                       
			}).catch(function(error){
				console.log(error);
			});
		},
		desactivar(item){
			let me=this;
			me.$router.push({ name: 'perfilcliente/Pollos%20Jeffry%20San%20Roque/3101110410' });
			
		},
		save () {
			if (this.editedIndex > -1) {
			Object.assign(this.desserts[this.editedIndex], this.editedItem)
			} else {
			this.desserts.push(this.editedItem)
			}
			this.close()
		},
    },
  }
</script>
My CRUD
