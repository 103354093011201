<template>
	
  <div style="background: #607df9;
	padding: 10px 0 5px;
	min-height: 600px;
	display: flex;
  align-items: center;">
    <v-container >
      <!-- -----------------------------------------------
            Start Banner
        ----------------------------------------------- -->
      <v-row justify="center">
		
        <v-col cols="12" md="7" lg="6" class="d-flex align-center">
			
          <div class="text-center text-md-left">
            <h1 style="font-size: 42px;
			line-height: 54px;
			margin: 20px 0 15px;" class="banner-title font-weight-bold white--text">
              Sistema de facturación electrónica y control de inventarios
            </h1>
            <h2 class="banner-subtitle white--text font-weight-regular">
				Más de 7 años ayudando a negocios a mejorar sus procesos en Costa Rica
            </h2>
            <div class="mt-16 pt-2">
              <v-btn
                color="error"
                class="mr-0 mr-md-8 mb-5 mb-md-0 btn-custom-md rounded-lg"
				style="padding: 25px 35px !important;"
                large
                to="/login"
                elevation="0"
              >
                Iniciar Sesión
              </v-btn>
              <v-btn
                large
                to="/afliliacion"
                style="padding: 25px 35px !important;"
                outlined
				class="rounded-lg"
                color="white"
                elevation="0"
              >
                Afiliarse
              </v-btn>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="5" lg="6">
          <v-img
            :src="require('@/assets/images/landingpage/SHOP_UK_Kits_Square_Stand_2nd-Gen_Kit_Gallery.webp')"
            alt="banner"
			max-width="750px"
          />
        </v-col>
      </v-row>

      <!-- -----------------------------------------------
            End Banner
        ----------------------------------------------- -->
    </v-container>
  </div>
</template>

<script>
  export default {
    name: 'SectionHero',

    provide: {
      theme: { isDark: true },
    },
  }
</script>