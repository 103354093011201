<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="usuarios"
            :search="search"
            >
            <template v-slot:top>
                <v-row class="pa-2">
                    <v-col cols="12" lg="3" md="5" sm="8" xs="12" >
                        <v-row class="rounded-xl var mx-5">
                            <v-btn disabled color="error" class="mx-1 mt-3" @click="crearPDF()"> <v-icon large="">mdi-file-pdf-box </v-icon>  </v-btn>
                                <vue-excel-xlsx
								
                                    :data="filteredData"
                                    :columns="columns"
                                    :filename="'Reporte de Ventas '+$store.state.usuario.localidad"
                                    :sheetname="'Ventas'"
                                    >
                                    <v-btn dark color="green">  <v-icon large="">mdi-file-excel </v-icon> </v-btn>
                                </vue-excel-xlsx> 
                            <v-card-title>Historial de Ordenes</v-card-title>
                        </v-row>
						
						 </v-col>
                    <v-col cols="12" lg="4" md="7" sm="4" xs="12">    
                        <v-text-field filled v-model="search"  append-icon="mdi-magnify"
                         label="Buscar" hide-details dense
                        ></v-text-field>
						<v-select hide-details clearable @change="selectLocalidad" :items="Localidades" filled label="Localidad" v-model="Localidad"> </v-select>
                    </v-col>
                    <v-col cols="12" lg="2" md="6" sm="6" xs="6">
                        <v-text-field dense hide-details filled v-model="start" type="date" label="Fecha inicial"></v-text-field>
					</v-col>
                    <v-col cols="12" lg="2" md="5" sm="5" xs="5">
                        <v-text-field hide-details filled v-model="end" dense type="date" label="Fecha final"></v-text-field>
					
                    </v-col>
                    <v-col cols="12" lg="1" md="1" sm="1" xs="1">
                        <v-btn color="accent" @click="selectLocalidad()" fab > <v-icon fab> mdi-cloud-search </v-icon> </v-btn>
					</v-col>
                </v-row>
               
            </template>

        </v-data-table>
        <template class="pa-3">
            <v-snackbar
                    v-model="snackbar" top vertical="" :timeout="5000"
                    >
                    {{ Mensaje }}
                    <v-btn
                    color="info"
                    text
                    @click="snackbar = false"
                    >
                    Cerrar
                    </v-btn>
            </v-snackbar>
            
            <v-row flat color="white"  class="pa-2">
                
                <v-col cols="12" sm="8">
					<div style="font-size:25px" class="text-center primary--text">	TOP 12 mas Vendido desde {{start}} hasta {{end}} en {{Localidad}} </div>
                    <canvas id="myChart" class="rounded-xl light-blue lighten-5 pa-5">
              
                     </canvas>
                </v-col>
                
                
            </v-row>
        </template>
    </div>
</template>

<script scoped>
import axios from 'axios'
import Chart from 'chart.js'
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import VueExcelXlsx from "vue-excel-xlsx";
import Vue from "vue";
import html2canvas from 'html2canvas';
import QrcodeVue from 'qrcode.vue'

Vue.use(VueExcelXlsx);
  export default {
    data: () => ({
    mesesValores: null,
    nombreMeses: [],
    totalMeses: [],
    value: 'https://www.noah.cr',
    size: 100,
    dialog: false,
    familia: '',
    search: '',
    ClienteSeleccionado : '',
    today: new Date().toISOString().substr(0, 10),
    focus: new Date().toISOString().substr(0, 10),
    headers: [
    { text: 'Orden', value: 'noFactura' },
    { text: 'Nombre', value: 'nombre' },
    { text: 'Fecha', value: 'fecha' },
    { text: 'Agente', value: 'cajero' },
    { text: 'Descuento', value: 'descuento' },
    { text: 'Total', value: 'total' },
    { text: 'Telefono', value: 'telefono'},
	{ text: 'Hora', value: 'hora' },
    { text: 'Guia', value: 'orden' },
    { text: 'Sucursal', value: 'localidad' },
	{ text: 'Nota', value: 'nota' },
	{ text: 'correo', value: 'correo' },
	{ text: 'medioOrden', value: 'medioOrden' },
	{ text: 'estadoPago', value: 'estadoPago' },
	{ text: 'mensajero', value: 'mensajero' },
	{ text: 'detalles', value: 'detalles' },
	{ text: 'Envio', value: 'tipoCompra' },
	{ text: 'facturacion', value: 'facturacion' },
	{ text: 'direccion', value: 'direccion' },
    ],
    columns : [
            { label: 'Orden', field: 'noFactura' },
			{ label: 'Nombre', field: 'nombre' },
			{ label: 'Fecha', field: 'fecha' },
			{ label: 'Agente', field: 'cajero' },
			{ label: 'Descuento', field: 'descuento' },
			{ label: 'Total', field: 'total' },
			{ label: 'Telefono', field: 'telefono'},
			{ label: 'Hora', field: 'hora' },
			{ label: 'Guia', field: 'orden' },
			{ label: 'Sucursal', field: 'localidad' },
			{ label: 'Nota', field: 'nota' },
			{ label: 'correo', field: 'correo' },
			{ label: 'medioOrden', field: 'medioOrden' },
			{ label: 'estadoPago', field: 'estadoPago' },
			{ label: 'mensajero', field: 'mensajero' },
			{ label: 'detalles', field: 'detalles' },
			{ label: 'Envio', field: 'tipoCompra' },
			{ label: 'facturacion', field: 'facturacion' },
			{ label: 'direccion', field: 'direccion' },
        ],
    usuarios: [],
    tipoDocumentos: [
        'FISICA',
        'JURIDICA',
        'EXTRANJERO',
    ],
    familias: [],
    Top10: [],
	end: '',
	start:'',
    DescTop: [],
	Localidades:[],
	Localidad :"",
    infoTop: [],
    Proveedores: [],
    proveedor: '',
    Mensaje: 'Seleccione un rango de fechas',
    snackbar: true,
    }),
    components: {
      QrcodeVue,
    },

    computed: {
		formTitle () {
				return this.editedIndex === -1 ? 'Nuevo usuario' : 'Actualizar usuario'
		},
		filteredData() {
			return this.filterItems(this.usuarios, this.search);
		},
		totalCantidadFiltrada() {
			return this.sumarCantidades(this.filteredData);
		},
		esMarketing(){
					return this.$store.state.usuario && this.$store.state.usuario.rol =='King Marketing';
		},
    },
    

    watch: {
      dialog (val) {
        val || this.close()
      },
      
    },


    methods: {
        async select(){
                let me=this;
                let header={"Authorization" : "Bearer " + this.$store.state.token};
                let configuracion= {headers : header};
                await axios.get('api/OrdenExpress/ListarFiltros/'+this.today+'/'+this.today+'/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
                    //console.log(response);
                    me.usuarios=response.data;
                }).catch(function(error){
                    console.log(error);
                });
        },
		async selectLocalidad() {
			let me = this;

			let header = {
				"Authorization": "Bearer " + this.$store.state.token
			};
			let configuracion = {
				headers: header,
				params: {} // Aquí definimos un objeto vacío para los parámetros de consulta
			};

			// Añadimos start, end y Localidad al objeto params
			if (this.start) {
				configuracion.params.fechaInicio = this.start;
			}
			if (this.end) {
				configuracion.params.fechaFin = this.end;
			}
			if (this.Localidad) {
				configuracion.params.localidad = this.Localidad;
			}

			await axios.get('api/OrdenExpress/ListarFiltros', configuracion)
				.then(function(response) {
					me.usuarios = response.data;
				})
				.catch(function(error) {
					console.log(error);
				});
		},

		filterItems(items, search) {
			if (!search) {
			return items;
			}

			const searchTerm = search.toString().toLowerCase();

			return items.filter(item => {
			return Object.keys(item).some(key => {
				const value = item[key];
				if (value !== null && value !== undefined) {
				return value.toString().toLowerCase().includes(searchTerm);
				}
				return false;
			});
			});
		},
		sumarCantidades(items) {
			return items.reduce((total, item) => {
			return total + (item.cantidad || 0);
			}, 0);
		},

        selectProveedores(){
                let me=this;
                let header={"Authorization" : "Bearer " };
                let configuracion= {headers : header};
                var proveedoresArray=[];
                axios.get('api/proveedores/Select/'+me.$store.state.usuario.localidad,configuracion).then(function(response){
                    proveedoresArray=response.data;
                    proveedoresArray.map(function(x){
                        me.Proveedores.push(x.proveedor);
                    });
                }).catch(function(error){
                    console.log(error);
                });
            },
        selectFamilias(){
             let me=this;
          let header={"Authorization" : "Bearer " };
          let configuracion= {headers : header};
          var familiasArray=[];
          axios.get('api/Familias/Select/'+me.$store.state.usuario.localidad,configuracion).then(function(response){
              familiasArray=response.data;
              familiasArray.map(function(x){
                  me.familias.push(x.nombre);
              });
          }).catch(function(error){
              console.log(error);
          });
        },
		async listarAsociadas(){
			var items = [];
			let me=this;
			me.Localidades.push(me.$store.state.usuario.localidad);
			this.Localidad = this.$store.state.usuario.localidad
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			await axios.get('api/LocalidadesAsociadas/'+this.$store.state.usuario.nombre,configuracion).then(function(response){
				items=response.data;
				items.map(function(x){
					me.Localidades.push(x.localdiadAsociada);
				});
			}).catch(function(error){
				alert("Producto no encontrado");
				me.modelCarga=false;
			});
    	},
		verLocalidad(item){
            this.NombreLocalidad= item.nombre;
            this.CedulaLocalidad= item.cedulaJuridica;
            this.DireccionLocalidad= item.dirreccion;
            },
        ReenvioFactura(){
            if(this.correo2 == ''){
                this.snackbar = true;
                this.Mensaje = 'Digite el correo al que desea reenviar la factura'
                return;
            }
            let header={"Authorization" : "Bearer " + this.$store.state.token};
                let configuracion= {headers : header};
                    let me=this;
                    axios.put('api/CorreosFacturas/Reenviar',{
                        'NoFactura':me.noFactura,
                        'Estado': 0,
                        'Correo2': me.correo2,
                        'ClaveNumerica':me.ClaveComprobante,
                    },configuracion).then(function(response){
                        me.snackbar = true;
                        me.Mensaje = 'Correo reenviado con éxito a '+me.correo2;                     
                    }).catch(function(error){
                        console.log(error);
                        me.snackbar = true;
                        me.Mensaje = 'Reenvio de factura a '+me.correo2+'. Disponible proximamente';   
                    });
        },
     	limpiar(){
                this.MensajeRespuesta="";
                this.ClaveComprobante="";
                this.localidad="";
                this.detalles=[];
                this.name = '',
                this.details = '',
                this.start = '',
                this.end = '',
                this.color = '',
                this.hora = '',
                this.hora2 = '',
                this.codigo = ''
    	},  
        crearPDF(){
           var columns = [
            {title: "Codigo", dataKey: "codigoProducto"},
            {title: "Nombre", dataKey: "descripcion"}, 
            {title: "Cantidad", dataKey: "cantidad"}, 
            {title: "Precio", dataKey: "precioUnitario"},
            ];
            var rows = [];

            this.filteredData .map(function(x){
                rows.push({codigoProducto:x.codigoProducto,descripcion:x.descripcion,cantidad:x.cantidad,precioUnitario:x.precioUnitario});
            });

            // Only pt supported (not mm or in)
            var doc = new jsPDF('p', 'pt');
            doc.autoTable(columns, rows, {
                margin: {top: 60},
                addPageContent: function(data) {
                    doc.text("Listado de Ventas", 40, 30);
                }
            });
            doc.save('Reporte ventas Por Producto.pdf');
        },
        crearPDFFactura(){
            var fact = this.noFactura;
             var quotes = document.getElementById('factura');
                html2canvas(quotes).then(function (canvas) {
                    var imgData = canvas.toDataURL('https://i.ibb.co/7rj9fLf/the-one-blanco.jpg');
                    var imgWidth = 210;
                    var pageHeight = 285;
                    var imgHeight = canvas.height * imgWidth / canvas.width;
                    var heightLeft = imgHeight;
                    var doc = new jsPDF();
                    var position = 0;

                    doc.addImage(imgData, 'JPG', 0, position, imgWidth, imgHeight);                    
                    doc.save('ComprobanteVenta'+fact+'.pdf');
                });
        },

        getColor (mensajeRespuesta) {
        if (mensajeRespuesta == 'aceptado') return '#8BC34A'
        if(mensajeRespuesta == 'Sin procesar') return 'orange'
        if(mensajeRespuesta == 'procesando') return '#42A5F5'
        if(mensajeRespuesta == 'rechazado') return '#FF1744'
        else  return '#42A5F5'
      },

      close () {
        this.dialog = false;
        this.limpiar();
          },
    

      activarDesactivarMostrar(accion,item){
                this.adModal=1;
                this.adNombre=item.nombre;
                this.adId=item.idusuario;                
                if (accion==1){
                    this.adAccion=1;
                }
                else if (accion==2){
                    this.adAccion=2;
                }
                else{
                    this.adModal=0;
                }
            },
            activarDesactivarCerrar(){
                this.adModal=0;
            },
      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.desserts[this.editedIndex], this.editedItem)
        } else {
          this.desserts.push(this.editedItem)
        }
        this.close()
      },
    },
	mounted(){
		this.listarAsociadas();
	}
  }

</script>
<style scoped>
	::v-deep .v-data-table tbody tr:nth-child(odd) {
	background-color: #f5f5f5; /*color de fondo para filas impares*/
	}

	::v-deep .v-data-table tbody tr:nth-child(even) {
	background-color: #ffffff; /*color de fondo para filas pares*/
	}
	::v-deep .my-custom-class tbody tr:nth-child(odd) {
	background-color: #f5f5f5; /*color de fondo para filas impares*/
	}

	::v-deep .my-custom-class tbody tr:nth-child(even) {
	background-color: #ffffff; /*color de fondo para filas pares*/
	}
</style>