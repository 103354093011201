<template>
    <div>
        <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
            >
            <v-card>
                <v-card-title>
                <span class="headline">Despachar {{Nombre}}</span>
                </v-card-title>
                <v-card-text>
                <v-container>
                    <v-row>
                    <v-col
                        cols="12"
                        sm="6"
                        md="4"
                    >
                        <v-text-field
                        label="Código Producto"
                        required
                        v-model="codigoProducto"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="4"
                    >
                        <v-text-field
                        label="Codigo Serial"
                        v-model="codigoSerial"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="4"
                    >
                        <v-text-field
                        label="Número Factura"
                        hint="Factura en la cual fue vendido el producto con este serial"
                        persistent-hint
                        required
                        v-model="NoFactura"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                        label="Vendedor"
                        required
                        v-model="Vendedor"
                        ></v-text-field>
                    </v-col>
                    </v-row>
                </v-container>
                <small>*Campos requeridos</small>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="black"
                    text
                    @click="dialog = false"
                >
                    Cerrar
                </v-btn>
                <v-btn
                    color="black"
                    text
                    @click="Agregar()"
                >
                    Procesar
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-data-table
            :headers="headers"
            :items="usuarios"
            :search="search"
            sort-by="id"
			sort-desc=""
            class="elevation-1 pa-1"

            >
            <template v-slot:item.estado="{ item }">
            <v-chip :color="getColor(item.estado)" dark>{{ item.estado }}</v-chip>
            </template>
            <template v-slot:top>
                <v-row class="pa-2">
                    <v-col cols="12" sm="3">
                        <v-card-actions class="rounded-xl light-blue lighten-5 mx-5">
                                <vue-excel-xlsx
                                    :data="usuarios"
                                    :columns="columns"
                                    :filename="'Reporte de Seriales '+$store.state.usuario.localidad"
                                    :sheetname="'Seriales'"
                                    >
                                    <v-btn fab="" color="success">  <v-icon large="">mdi-file-excel </v-icon> </v-btn>
                                </vue-excel-xlsx> 
                            <v-card-title>FeedBack</v-card-title>
                        </v-card-actions>
                    </v-col>
                    <v-col cols="12" sm="6">    
                        <v-text-field v-model="search"  append-icon="mdi-magnify"
                         label="Buscar" single-line hide-details
                        ></v-text-field>
                    </v-col>
                </v-row>
               
            </template>
            <template v-slot:item.actionadd="{ item }">
            <v-icon
                
                class="mr-2"
                color="green" 
                @click="MostrarComprobante(item)"
            >
                mdi-plus
            </v-icon>
            
            </template>
			<template v-slot:item.avencer="{ item }">
            <v-chip :color="getColor2(EstadoVencimiento(item))" dark>{{ EstadoVencimiento(item) }}</v-chip>
            </template>
            <template v-slot:no-data>
                <v-data-table item-key="name" class="elevation-1" loading loading-text="Por favor seleccione el rango de fechas que desea visualizar"></v-data-table>
                <v-btn color="primary" @click="select()">Reiniciar</v-btn>
            </template>
        </v-data-table>
        <template class="pa-3">
            <v-snackbar
                    v-model="snackbar" top vertical="" :timeout="5000"
                    >
                    {{ Mensaje }}
                    <v-btn
                    color="info"
                    text
                    @click="snackbar = false"
                    >
                    Cerrar
                    </v-btn>
            </v-snackbar>
        </template>
    </div>
</template>

<script scoped>
import axios from 'axios'
import jsPDF from 'jspdf'
import autotable from 'jspdf-autotable'
import VueExcelXlsx from "vue-excel-xlsx";
import Vue from "vue";
import html2canvas from 'html2canvas';
import QrcodeVue from 'qrcode.vue'

Vue.use(VueExcelXlsx);
  export default {
    data: () => ({
    mesesValores: null,
    nombreMeses: [],
    totalMeses: [],
    value: 'https://www.noah.cr',
    size: 100,
    dialog: false,
    familia: '',
    codigoSerial: '',
    Nombre: '',
    codigoProducto: '',
    Vendedor: '',

    search: '',
    ClienteSeleccionado : '',
    today: new Date().toISOString().substr(0, 10),
    focus: new Date().toISOString().substr(0, 10),
    columns : [
            { label: 'Código Producto', field: 'codigoproducto'},
            { label: 'Descripción', field: 'descricion'},
            { label: 'Estado', field: 'estado'},
            { label: 'Código Serial', field: 'codigoSerial'},
            { label: 'Fecha Registro', field: 'fecha'},
            { label: 'Hora Registro', field: 'hora' },
            { label: 'Vendedor', field: 'vendedor'},
            { label: 'Fecha Venta', field: 'fechaVenta'},
            { label: 'Factura Venta', field: 'noFactura' },
            { label: 'Usuario', field: 'usuario' },
        ],
    headers: [
    { text: 'No', value: 'id'},
    { text: 'Email', value: 'correo'},
    { text: 'Servicio al cliente', value: 'uno'},
    { text: 'Calidad', value: 'dos', },
    { text: 'Recomendacion', value: 'tres' },
    { text: 'Hora Registro', value: 'hora' },
    { text: 'Recurrencia', value: 'cuatro'},
	{ text: 'Enteración', value: 'cinco',  },
    { text: 'Nota', value: 'seis' },
	{ text: 'Sugerencia', value: 'siete',  },
    { text: 'Hora Registro', value: 'hora' },
    ],
    usuarios: [],
    tipoDocumentos: [
        'FISICA',
        'JURIDICA',
        'EXTRANJERO',
    ],
    familias: [],
    Top10: [],
    DescTop: [],
    infoTop: [],
    Proveedores: [],
    proveedor: '',
    Mensaje: 'Codigos Seriales Listados',
    snackbar: true,
    }),
    components: {
      QrcodeVue,
    },

    computed: {
    formTitle () {
            return this.editedIndex === -1 ? 'Nuevo usuario' : 'Actualizar usuario'
    },
    },
    

    watch: {
      dialog (val) {
        val || this.close()
      },
      
    },


    methods: {
        select(){
                let me=this;
                let header={"Authorization" : "Bearer " + this.$store.state.token};
                let configuracion= {headers : header};
                axios.get('api/FeedBacks/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
                    //console.log(response);
                    me.usuarios=response.data;
                }).catch(function(error){
                    console.log(error);
                });
        },
		EstadoVencimiento(item){
			var vencimiento = new Date (item.vencimiento)
			var d = new Date();
			let mesActual = d.getMonth() +1;
			var mesItem = vencimiento.getMonth();
			if(mesActual == mesItem){
				return 'Por Vencer'
			}
			if(vencimiento > d){
				return 'Vigente'
			}
			if(vencimiento < d){
				return 'Vencido'
			}
		},
		getColor2 (estado) {
        if (estado == 'Vigente') return '#8BC34A'
        if(estado == 'Por Vencer') return 'orange'
        if(estado == 'Facturado') return '#42A5F5'
        if(estado == 'Vencido') return '#FF1744'
        else  return '#FF1744'
      },
        MostrarComprobante(item){
            this.dialog = true;
            this.codigoProducto = item.codigoproducto;
            this.codigoSerial = item.codigoSerial;
            this.Nombre = item.descricion;

        },

        getColor (estado) {
        if (estado == 'Despachado') return '#8BC34A'
        if(estado == 'En Stock') return 'orange'
        if(estado == 'Facturado') return '#42A5F5'
        if(estado == 'Desechado') return '#FF1744'
        else  return '#42A5F5'
      },

      close () {
        this.dialog = false;
        this.limpiar();
          },
    
      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.desserts[this.editedIndex], this.editedItem)
        } else {
          this.desserts.push(this.editedItem)
        }
        this.close()
      },
    },
  mounted(){
      this.select();
  }
  }

</script>