<template>
  <v-container pa-0>
    
  </v-container>
</template>

<script>
  // Utilities
  import {
    mapState
  } from 'vuex'

  export default {
    computed: {
      ...mapState(['articles'])
    }
  }
</script>
