<template>
  <div>
    <div style="background-color: #f4f8fa
		line-height: 26px;" class="testimonial-component mini-spacer bg-extra-light">
      <v-container>
        <!-- -----------------------------------------------
            Start Testimonial Text
        ----------------------------------------------- -->
        <v-row  justify="center">
          <v-col cols="12" sm="10" md="9" lg="7">
            <div class="text-center">
              <h2 class="section-title font-weight-medium">
                Mirá lo que dicen nuestros clientes
              </h2>
              <p>
                Puedes acceder a todas estas funciones y beneficios, consultanos por una demostración gratiuta hoy mismo. Te estamos esperando!
              </p>
            </div>
          </v-col>
        </v-row>

        <!-- -----------------------------------------------
            End Testimonial Text
        ----------------------------------------------- -->
        <!-- -----------------------------------------------
            Start Testimonial
        ----------------------------------------------- -->
        <v-row class="mt-13" justify="center">
          <v-col cols="12" md="6" lg="4">
            <v-card style="padding: 40px 35px !important;" class="testimonial-card card-shadow">
              <v-card-text>
                <p class="font-weight-light mb-15">
                  “Este software punto de venta es excelente. Es fácil de usar, rápido y confiable. Nos ha ayudado a procesar las ventas de forma más eficiente y nos ha permitido mantener un mejor control de nuestro inventario. Además, el soporte al cliente es excepcional..”
                </p>
                <div class="bottom-bar d-flex align-center">
                  <img
                    src="@/assets/images/testimonial/1.jpg"
                    alt="Testimonial"
					style="width: 60px; height: 60px"
                    class="testimonial-img rounded-circle"
                  />
                  <div class="ml-10">
                    <h6 class="text-uppercase font-weight-medium font-14">
                      Steven Araya
                    </h6>
                    <div class="d-flex align-center font-13">
                      <a href="/" class="error--text">
                        <i class="mdi mdi-star"></i
                      ></a>
                      <a href="/" class="error--text">
                        <i class="mdi mdi-star"></i
                      ></a>
                      <a href="/" class="error--text">
                        <i class="mdi mdi-star"></i
                      ></a>
                      <a href="/" class="error--text">
                        <i class="mdi mdi-star"></i
                      ></a>
                      <a href="/" class="grey--text">
                        <i class="mdi mdi-star"></i
                      ></a>
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-card style="padding: 40px 35px !important;" class="testimonial-card card-shadow">
              <v-card-text>
                <p class="font-weight-light mb-15">
                  “Estoy muy impresionado con este software. Me ha permitido administrar mi negocio de manera efectiva y eficiente. La interfaz es fácil de usar y cuenta con todas las funciones que necesito para administrar mi tienda. Lo recomiendo altamente.”
                </p>
                <div class="bottom-bar d-flex align-center">
                  <img
                    src="@/assets/images/testimonial/2.jpg"
                    alt="Testimonial"
                    class="testimonial-img rounded-circle"
					style="width: 60px; height: 60px"
                  />
                  <div class="ml-10">
                    <h6 class="text-uppercase font-weight-medium font-14">
                      Argerie Salazar
                    </h6>
                    <div class="d-flex align-center font-13">
                      <a href="/" class="error--text">
                        <i class="mdi mdi-star"></i
                      ></a>
                      <a href="/" class="error--text">
                        <i class="mdi mdi-star"></i
                      ></a>
                      <a href="/" class="error--text">
                        <i class="mdi mdi-star"></i
                      ></a>
                      <a href="/" class="error--text">
                        <i class="mdi mdi-star"></i
                      ></a>
                      <a href="/" class="grey--text">
                        <i class="mdi mdi-star"></i
                      ></a>
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-card style="padding: 40px 35px !important;" class="testimonial-card card-shadow">
              <v-card-text>
                <p class="font-weight-light mb-15">
                  “Este software punto de venta ha superado mis expectativas. Me encanta la interfaz intuitiva y fácil de usar, y las funciones personalizables me permiten adaptar el software a mis necesidades específicas. Además, la capacidad de acceder al software desde cualquier dispositivo es una gran ventaja.”
                </p>
                <div class="bottom-bar d-flex align-center">
                  <img
                    src="@/assets/images/testimonial/3.jpg"
                    alt="Testimonial"
                    class="rounded-circle"
					style="width: 60px; height: 60px"
                  />
                  <div class="ml-10">
                    <h6 class="text-uppercase font-weight-medium font-14">
                      Rebeca Sequeira
                    </h6>
                    <div class="d-flex align-center font-13">
                      <a href="/" class="error--text">
                        <i class="mdi mdi-star"></i
                      ></a>
                      <a href="/" class="error--text">
                        <i class="mdi mdi-star"></i
                      ></a>
                      <a href="/" class="error--text">
                        <i class="mdi mdi-star"></i
                      ></a>
                      <a href="/" class="error--text">
                        <i class="mdi mdi-star"></i
                      ></a>
                      <a href="/" class="grey--text">
                        <i class="mdi mdi-star"></i
                      ></a>
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <!-- -----------------------------------------------
            End Testimonial
        ----------------------------------------------- -->
      </v-container>
    </div>
  </div>
</template>
<script>
export default {
  name: "Testimonial",
  data() {
    return {};
  },
  methods: {},
};
</script>