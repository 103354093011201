<template>
  	<v-container fluid>	
		<div class="text-center">
			<v-overlay :value="modelCarga">
			<v-progress-circular
				indeterminate
				size="64"
			></v-progress-circular>
			</v-overlay>
		</div>
		<v-snackbar
                v-model="snackbar" top vertical="" :timeout="4000"
                >
                {{ Mensaje }}
                <v-btn
                color="pink"
                text
                @click="snackbar = false"
                >
                Close
                </v-btn>
        </v-snackbar>
		
		<v-row>
			<v-toolbar  dark color="primary" class="font-weight-light body-1">
				<v-list-item-content >
					<v-list-item-title>Promociones</v-list-item-title>
					<v-list-item-subtitle>Crea descuentos apartir de cierta cantidad de venta</v-list-item-subtitle>
					
				</v-list-item-content>
					<v-btn class="mx-2" color="green" outlined @click="cargarPromosTotalTienda"> Promoción a toda la tienda </v-btn> 
					<v-btn color="red" outlined @click="eliminarPromos"> Eliminar todo</v-btn> 
       		 </v-toolbar>
            <v-row>
              	<v-col cols="12" sm="6">
					<p> Promociones actuales </p>
					
					
					<v-text-field v-model="search" filled  label="Busqueda"/>
					<v-data-table
							:headers="cabecerapromos"
							single-select
							:items="promos"
							:search="search"
							sort-by="idPromo"
							sort-desc="idPromo"
							class="elevation-1">
							
								<template slot="no-data">
									<h3>No hay artículos agregados al detalle.</h3>
								</template> 
								<template v-slot:item.actionPrecio="{ item }">
									<s>{{item.precioVenta * item.cantidad}} </s>
									{{calcularPrecioPromos(item)}}	
								</template>
								<template v-slot:item.eliminar="{ item }">
									<v-icon @click="EliminarPromo(item)"> mdi-delete </v-icon>
								</template>
								<template v-slot:item.cantidad="props">
									<v-edit-dialog
									:return-value.sync="props.item.cantidad"
									@save="ActualizarPromo(props.item)"
									@cancel="cancel"
									@open="open"
									@close="close"
									>
									{{ props.item.cantidad }} <br>
									<template v-slot:input>
										<v-text-field
										onfocus="this.select();"
										v-model="props.item.cantidad"
										
										:rules="[max25chars]"
										:label="'Cantidad minimia para aplicar descuento: '"
										></v-text-field>
									</template>
									</v-edit-dialog>
								</template>
								<template v-slot:item.descuento="props">
									<v-edit-dialog
									:return-value.sync="props.item.descuento"
									@save="ActualizarPromo(props.item)"
									@cancel="cancel"
									@open="open"
									@close="close"
									>
									{{ props.item.descuento }} <br>
									<template v-slot:input>
										<v-text-field
										onfocus="this.select();"
										v-model="props.item.descuento"
										
										:rules="[max25chars]"
										:label="'% descuento aplicar: '"
										></v-text-field>
									</template>
									</v-edit-dialog>
								</template>
								<template v-slot:item.activador="props">
									<v-checkbox @change="ActualizarPromo(props.item)" color="green" v-model="props.item.activador" />
								</template>
					</v-data-table>
					
              	</v-col>
				<v-col cols="12" sm="6">
					<v-row>
						<v-text-field @change="ActualizarCantidad" type="number" v-model="multiplo" persistent-hint filled style="width:33.33%" hint="Minimo a vender para que se aplique el descuento" label="Multiplo de redondeo" />
						<v-text-field @change="ActualizarCantidad" type="number" v-model="cantMimina" persistent-hint filled style="width:33.33%" hint="Minimo a vender para que se aplique el descuento" label="Cantidad Minima" />
						<v-text-field @change="ActualizarDescuento" type="number" v-model="descuentoMinimo" filled style="width:33.33%" label="Porcentaje Descuento" persistent-hint hint="Porcentaje de descuento aplicar si la cantida supera la cantidad minima" />

						<v-combobox :loading="loading" id="buscadorpromos" filled clearable onfocus="this.select();"  prepend-inner-icon="mdi-briefcase-outline" @keyup="selectProductosBuscar()" :items="productos" v-model="codigo" label="Busqueda de productos nuevos a promocionar"></v-combobox>
						
					</v-row>
					<v-btn color="green" @click="cargarPromos" outlined text> Guardar Promociones Seleccionadas </v-btn>
					<v-divider/>
						<v-data-table
							:headers="cabeceraDetalles"
							single-select
							:items="detalles"
							class="elevation-1">
								<template slot="no-data">
									<h3>No hay artículos agregados al detalle.</h3>
								</template> 
								<template v-slot:item.actionPrecio="{ item }">
									<s>{{item.precio * item.cantMinima}} </s>
									{{calcularPrecio(item)}}	
								</template>
								<template v-slot:item.cantMinima="props">
									<v-edit-dialog
									:return-value.sync="props.item.cantMinima"
									@save="save"
									@cancel="cancel"
									@open="open"
									@close="close"
									>
									{{ props.item.cantMinima }} <br>
									<template v-slot:input>
										<v-text-field
										onfocus="this.select();"
										v-model="props.item.cantMinima"
										
										:rules="[max25chars]"
										:label="'Cantidad minimia para aplicar descuento: '"
										></v-text-field>
									</template>
									</v-edit-dialog>
								</template>
								<template v-slot:item.descuento="props">
									<v-edit-dialog
									:return-value.sync="props.item.descuento"
									@save="save"
									@cancel="cancel"
									@open="open"
									@close="close"
									>
									{{ props.item.descuento }} <br>
									<template v-slot:input>
										<v-text-field
										onfocus="this.select();"
										v-model="props.item.descuento"
										
										:rules="[max25chars]"
										:label="'% descuento aplicar: '"
										></v-text-field>
									</template>
									</v-edit-dialog>
								</template>
								<template v-slot:item.estado="props">
									<v-checkbox color="green" v-model="props.item.estado" />
								</template>
						</v-data-table>
					
                </v-col>
			</v-row>
		</v-row>
  	</v-container>
</template>


<script>
  
  import axios from 'axios';
  export default {
    name: 'Recetas',
    data () {
      return {
		Utilidad: 30,
		loading:false,
		snackbar : false,
		Mensaje :"",
		codigo: '',
		productos: [],
		cantMimina: 2,
		descuentoMinimo: 2.5,
		search: '',
		timeouts: [],
		promos:[],
		detalles:[],
		multiplo : 10,
		modelCarga:false,
		cabecerapromos: [
		{ text: 'Codigo', value: 'codigoProducto', sortable: false  },
		{ text: 'Descripción', value: 'nombre', sortable: false  },
		{ text: 'Cantidad Minima', value: 'cantidad', sortable: false  },  
		{ text: '% Descuento', value: 'descuento', sortable: false  },
		{ text: 'Precio Final', value: 'actionPrecio', sortable: false  },
		{ text: 'Estado', value: 'activador', sortable: false },
		{ text: 'Acciones', value: 'eliminar', sortable: false },
		],
		cabeceraDetalles: [
		{ text: 'Codigo', value: 'codigoProducto', sortable: false  },
		{ text: 'Descripción', value: 'descripcion', sortable: false  },
		{ text: 'Cantidad Minima', value: 'cantMinima', sortable: false  },  
		{ text: '% Descuento', value: 'descuento', sortable: false  },
		{ text: 'Precio Final', value: 'actionPrecio', sortable: false  },
		{ text: 'Seleccionar', value: 'estado', sortable: false },
		],
      }
    },
	computed:{
		calcularCosto:function(){
			let resultado = 0;
				for(var i=0;i<this.detalles.length;i++){
					resultado=resultado+(this.detalles[i].precio * this.detalles[i].cantidad);
				}
			return resultado;
    	},
		calcularVenta:function(){
			let Ganancia = this.calcularCosto * (this.Utilidad / 100) ;
			let PrecioVenta = this.calcularCosto + Ganancia;
			let IvaPrecioVenta = PrecioVenta * 0.13;
			let resultado = (PrecioVenta + IvaPrecioVenta);
			return resultado;
    	},
	},
	created () {
		this.BuscarPromos();
		this.selectPanelControl();
	},
	
    methods: {
		selectPanelControl(){  
			let me=this;
			var localidadesArray=[];
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			axios.get('api/PanelControls/ListarLocalidad/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
				localidadesArray=response.data;
				me.multiplo =  localidadesArray[0].multiplo; // especifica el multiplo de redondeo del precio
				//me.tipoCedula=localidadesArray[0].tpcedula;
			})
		},
		ActualizarCantidad(){
			for(var i=0;i<this.detalles.length;i++){
				this.detalles[i].cantMinima = this.cantMimina;
			}
		},
		ActualizarDescuento(){
			for(var i=0;i<this.detalles.length;i++){
				this.detalles[i].descuento = this.descuentoMinimo;
			}
		},
		ActualizarPromo(item){
			let me = this;
			this.modelCarga = true;
			let header = { "Authorization": "Bearer " };
			let configuracion = { headers: header };
			axios.put('api/Promociones/'+item.idPromo, item, configuracion)
				.then(function(response) {
				me.modelCarga = false;
				me.snackbar = true;
				me.Mensaje = "Actualizado con éxito";
				})
				.catch(function(error) {
				this.loading = true;
				alert("No se se puedo actualizar" + error);
				});
		},
		EliminarPromo(item){
			let me = this;
			me.promos = me.promos.filter(e => e.idPromo != item.idPromo);
			this.modelCarga = true;
			let header = { "Authorization": "Bearer " };
			let configuracion = { headers: header };
			axios.delete('api/Promociones/'+item.idPromo, configuracion)
				.then(function(response) {
				me.modelCarga = false;
				me.snackbar = true;
				me.Mensaje = "Eliminado con éxito";
				
				})
				.catch(function(error) {
				this.loading = true;
				alert("No se se puedo eliminar" + error);
				});
		},
		BuscarPromos(){
			this.promos= [];
			let me = this;
			let header = { "Authorization": "Bearer " };
			let configuracion = { headers: header };
			axios.get('api/Promociones/'+this.$store.state.usuario.localidad,  configuracion)
				.then(function(response) {
				me.promos = response.data;
				})
				.catch(function(error) {
				this.loading = true;
				alert("No se encontraron prmociones" + error);
				});
		},
		cargarPromos() {
			let me = this;
			var localidad= this.$store.state.usuario.localidad;
			let items = [];
			this.detalles = this.detalles.filter(e => e.estado === true);
			this.detalles.map(function(x){
                items.push(
					{Nombre:x.descripcion,
					Cantidad: x.cantMinima,
					Descuento: x.descuento,
					Localidad:localidad,
					Activador: true,
					CodigoProducto:x.codigoProducto,
					}

				);
            });
			this.modelCarga = true;
			let header = { "Authorization": "Bearer " };
			let configuracion = { headers: header };
			axios.post('api/Promociones', items, configuracion)
				.then(function(response) {
				//alert("Carga Exitosa" + response);
				me.modelCarga = false;
				me.detalles = [];
				me.BuscarPromos();
				})
				.catch(function(error) {
				this.modelCarga = true;
				alert("Error cargando promociones" + error);
				});
		},
		cargarPromosTotalTienda() {
			let me = this;
			var localidad = this.$store.state.usuario.localidad;

			// Mostrar el cuadro de confirmación
			if (window.confirm("¿Está seguro de que desea cargar todas las promociones para la tienda con descuento: %"+this.descuentoMinimo)) {
				this.modelCarga = true;
				let header = { "Authorization": "Bearer " };
				let configuracion = { headers: header };

				axios.post('api/Promociones/PromosTiendaCompleta/' + localidad + '/' + this.cantMimina + '/' + this.descuentoMinimo, configuracion)
					.then(function(response) {
						// Acción después de una carga exitosa
						me.modelCarga = false;
						me.detalles = [];
						alert("Proceso completado con éxito "+response.data);
						me.BuscarPromos(); // Buscar promociones actualizadas
					})
					.catch(function(error) {
						// Manejo de errores
						me.modelCarga = false;
						alert("Error cargando promociones: " + error.data);
					});
			} else {
				// Si el usuario cancela, mostrar un mensaje opcional
				alert("La carga de promociones ha sido cancelada.");
			}
		},
		eliminarPromos() {
			let me = this;
			var localidad = this.$store.state.usuario.localidad;

			// Mostrar el cuadro de confirmación
			if (window.confirm("¿Está seguro de eliminar todas las promociones actuales? ")) {
				this.modelCarga = true;
				let header = { "Authorization": "Bearer " };
				let configuracion = { headers: header };

				axios.post('api/Promociones/DarBajas/' + localidad , configuracion)
					.then(function(response) {
						// Acción después de una carga exitosa
						me.modelCarga = false;
						me.detalles = [];
						alert("Proceso completado con éxito "+response.data);
						me.BuscarPromos(); // Buscar promociones actualizadas
					})
					.catch(function(error) {
						// Manejo de errores
						me.modelCarga = false;
						alert("No existen promociones para eliminar: " + error.data);
					});
			} else {
				// Si el usuario cancela, mostrar un mensaje opcional
				alert("La carga de promociones ha sido cancelada.");
			}
		},
		selectProductosBuscar(){
			let me=this;
			let Objeto = [];
			let myInput = document.getElementById('buscadorpromos');
			myInput = myInput.value;
			let cant = myInput.length;
			if ( cant > 2 ) {
				var search_term = myInput;
				
				this.timeouts.map( timeout => {
					window.clearTimeout( timeout );
				} );
				this.timeouts.push(
					setTimeout( () => {
						this.loading = true;
						this.detalles = [];
						axios.get('api/Productos/BuscadorEnter3/'+this.$store.state.usuario.localidad+'/'+myInput).then( response => {
							Objeto = response.data
								// Cargar Productos 
								Objeto.map(function(x){
									me.detalles.push({
										descripcion:x.descripcion,
										codigoProducto:x.codigoProducto,
										precio:x.precioVenta ,
										cantMinima:me.cantMimina,
										descuento:me.descuentoMinimo,
										estado: false,
										});
								});
								me.loading = false;
							} )
							.catch( error => { } );
					}, 500 )
				);
			} else {
				this.timeouts.map( timeout => {
					window.clearTimeout( timeout );
				} );
				this.productos = [];
			}
		},
		calcularPrecio(data) {
			var resultado = 0.0;
			var montoDescuento;
			// Calcular el descuento
			montoDescuento = (data.precio * data.cantMinima) - ((data.descuento) / 100) * (data.precio * data.cantMinima);
			resultado = Math.ceil(montoDescuento / this.multiplo) * this.multiplo;
			return resultado;
		},
		calcularPrecioPromos(data) {
			var resultado = 0.0;
			var montoDescuento;
			// Calcular el descuento
			montoDescuento = (data.precioVenta * data.cantidad) - ((data.descuento) / 100) * (data.precioVenta * data.cantidad);
			resultado = Math.ceil(montoDescuento / this.multiplo) * this.multiplo;
			return resultado;
		},
		buscarProductoMaster(){
			let me=this;
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			axios.get('api/Productos/BuscarCodigoVenta/'+this.ProductoCompuesto+'/'+this.$store.state.usuario.localidad,configuracion)
			.then(function(response){
				me.NombreProducto = response.data.descripcion;
				me.PrecioVenta = response.data.precioVenta;
				me.PrecioCostoPrevio = response.data.precioCompra;
			}).catch(function(error){
				// me.snackbar = true;
						//me.Mensaje = "Producto No encontrado";
			});
		},
	}
  }
</script>
