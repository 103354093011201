
<template>

    <v-content>
        <template>
            <v-row class="mx-2">
                <v-card :loading="loading" class="mt-2 mx-1 align-center" max-width="320" flat
                      v-for="(item , index) in usuarios"
                        :key="index">
                        <template slot="progress">
                        <v-progress-linear
                            color="primary"
                            height="10"
                            indeterminate
                        ></v-progress-linear>
                        </template>

                        <v-toolbar dark color="black">  <div class="text-h4">Orden:{{item.no_Orden}} </div> <v-spacer /><v-chip color="success" class="black--text text-h4">{{HoraOrden(item.hora)}}</v-chip> </v-toolbar>
                        <v-divider class="mx-4"></v-divider>
                        <v-card-title> <div>Orden Bar en Mesa: {{item.mesa}}</div> <br>    </v-card-title>
                        <v-card-text > {{item.detalle}} </v-card-text>

                        <div v-for="(item2 , index) in SaltosLinea(item.orden)"
                        :key="index" class="text-h5 ">  {{item2}} <br></div>

                        <v-divider class="mx-4"></v-divider>


                        <v-card-actions>
                        <v-btn
                            color="error"
                            outlined

                            @click="deleteOrden(item)"
                        >
                            Eliminar
                        </v-btn>
                        <v-card-text class="grey--text">

                        <div> Meser@:  {{item.cajero}}</div>
                        </v-card-text>
                        </v-card-actions>
                     
                    </v-card>
            </v-row>
        </template>
    </v-content>

</template>
<style lang="scss" scoped>

</style>
<script scoped>

import axios from 'axios'
  export default {
    data: () => ({
      
    dialog: false,
    search: '',
    latitud: 0,
    longitud: 0,
    center: { lat: 9.9339785 , lng: -84.0782664},
    markers: [
          {position: {lat: 9 , lng: -81}},
    ],
    headers: [
        { text: 'Opciones', value: 'action', sortable: false },
        { text: 'Factura', value: 'noFactura' },
        { text: 'Estado', value: 'estado' },
        { text: 'Nombre Cliente', value: 'nombre',  },    
        { text: 'Telefono', value: 'telefono',  },  
        { text: 'Fecha', value: 'fecha' },
        { text: 'Hora', value: 'hora' },
        { text: 'Total', value: 'total', sortable: false  },  
        { text: 'Tipo Pago', value: 'tipoPago1', },       
        { text: 'Localidad', value: 'localidad',   },   
        { text: 'Vendedor', value: 'cajero',  },   
       ],
      search: '',
        editedIndex: -1,
        id: '',
        idrol:'',
        noFactura: 0,
        comprobanteModal: 0,
        total: 0,
        detalles: [],
        localidadencabezado:[],
        NombreLocalidad:'',
        CedulaLocalidad:'',
        TipoCompra:'',
        DireccionLocalidad:'',
        TelefonoLocalidad:'',
        fecha: '',
        hora: '',
        nombreReceptor: '',
        cedulaReceptor:'',
        direccion:'',
        telefonoReceptor:'',
        correoReceptor:'',
        vendedor: '',
        subtotal: 0,
        latitudstring: '',
        longitudstring: '',
        grabado:0 ,
        excento: 0,
        iv: 0.0,
        tipo_documento: '',
        usuarios:[],    
        desserts: [],
        editedIndex: -1,
    }),

    computed: {
      formTitle () {
                return this.editedIndex === -1 ? 'Nuevo usuario' : 'Actualizar usuario'
        }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      
    },

    created () {
      this.listar();
    },

    methods: {
        deleteOrden (item) {
          this.loading = true;
          var me = this;
          if(confirm("Seguro que quiere eliminar la orden "+item.no_Orden+"?")){
              let header={"Authorization" : "Bearer " + this.$store.state.token};
              let configuracion= {headers : header};
               axios.post('api/PosicionProductoCajas/DeleteBar',{
                  	'No_Orden': item.no_Orden,
					'Localidad': me.$store.state.usuario.localidad,
					'NoFactura': item.noFactura,
                      
                },configuracion).then(function (response) {
                    me.listar();
                    me.loading = false;
                  })
                  .catch(function (error) {
                      me.snackbar = true;
                      me.Mensaje = 'Error eliminando el proveedor.';    
                    })
          }
      },
	  SaltosLinea(item){
		let srt = item.split(":");
		return srt;
	  },
	  HoraOrden (Hora) {
		
			var now = new Date();

			// Obtenemos los minutos de la orden y los minutos actuales
			var min =now.getMinutes();
			var min2 = Hora.substring(3, 5);
			var minutes = parseInt(min) - parseInt(min2);
			minutes = Math.abs(minutes)
			// Obtenemos la hora actual y la hora de la orden

			var hor1 =now.getHours();
			var hor2 = Hora.substring(0, 2);
			var hours = parseInt(hor1) - parseInt(hor2)

				return + hours + "h "+minutes+"m" ;		
      },

        getColor (estado) {
        if (estado == '0') return 'red'
        else 
        if (estado == '1') return 'orange'
        else 
        if (estado == '2') return 'success'
        else  return 'red'
      },
       listar(){
		  /* setTimeout(function(){
			window.location.reload(1);
			}, 30000);  */
			setInterval(function(){
			window.location.reload(1);
			}, 30000);
            let me =this;
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
            axios.get('api/OrdenDespachoes/ListarBar/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
                //console.log(response);
                me.usuarios=response.data;
            }).catch(function(error){
                console.log(error);
            });
        },
      close () {
        this.dialog = false;
        this.limpiar();
          },
    
    
    cargarColor(){
          for(var i = 0; i < this.localidadesColor.length; ++i)
          {
             if(this.localidadesColor[i].value == this.localidad.value){
                    this.color = this.localidadesColor[i].color;
                    break;
             }
          }
        },

      activarDesactivarMostrar(accion,item){
                this.adModal=1;
                this.adNombre=item.nombre;
                this.adId=item.idusuario;                
                if (accion==1){
                    this.adAccion=1;
                }
                else if (accion==2){
                    this.adAccion=2;
                }
                else{
                    this.adModal=0;
                }
            },
            activarDesactivarCerrar(){
                this.adModal=0;
            },
      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.desserts[this.editedIndex], this.editedItem)
        } else {
          this.desserts.push(this.editedItem)
        }
        this.close()
      },
    },
  }
</script>