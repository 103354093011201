<template>
		<v-row class="pa-2">
			<v-col cols="12" lg="3" md="5" sm="8" xs="12">
				<v-card-actions class="rounded-xl light-blue lighten-5 mx-5">
						
						<vue-excel-xlsx
							:data="usuarios"
							:columns="columns"
							:filename="'Reporte de Ventas '+$store.state.usuario.localidad"
							:sheetname="'Ventas'"
							>
							<v-btn fab="" color="success">  <v-icon large="">mdi-file-excel </v-icon> </v-btn>
						</vue-excel-xlsx> 
					<v-card-title>Ventas por Vendedor</v-card-title>
				</v-card-actions>
			</v-col>
			<v-col cols="12" lg="4" md="7" sm="4" xs="12">    
				<v-text-field filled v-model="search"  append-icon="mdi-magnify"
					label="Buscar" single-line hide-details
				/>
				<v-select hide-details filled :items="localidades" v-model="Localidad" label="Localidad" /> 
			</v-col>
			<v-col cols="12" lg="2" md="6" sm="6" xs="6">
				<v-text-field filled v-model="start" type="date" label="Fecha inicial"></v-text-field>
			</v-col>
			<v-col cols="12" lg="2" md="5" sm="5" xs="5">
				<v-text-field filled v-model="end"  type="date" label="Fecha final"></v-text-field>
			</v-col>
			<v-col cols="12" lg="1" md="1" sm="1" xs="1">
				<v-btn :loading="loading" color="info" @click="ListarResumen()" fab > <v-icon fab> mdi-cloud-search </v-icon> </v-btn>
			</v-col>
			<v-col cols="12" lg="6" md="6" sm="6" xs="6">
				Resumen de ventas por usuario y vendedor
					<v-simple-table class="boton">
						<template v-slot:default>
						<thead>
							<tr>
							<th style="color:white;" class="text-left">
								Ventas por Usuarios
							</th>
							<th style="color:white;" class="text-left">
								Venta
							</th>
							<th style="color:white;" class="text-left">
								Utilidad
							</th>
							</tr>
						</thead>
						<tbody>
							<tr
							v-for="item in VentasVendedores.ventasPorCajero"
							:key="item.cajero"
							>
							<td>{{ item.cajero }}</td>
							<td>{{ item.totalVentas.toLocaleString()  }}</td>
							<td>{{ item.utilidadFactura.toLocaleString() }}</td>
							</tr>
						</tbody>
						</template>
					</v-simple-table>
					<v-simple-table class="boton">
						<template v-slot:default>
						<thead >
							<tr>
							<th style="color:white;" class="text-left">
								Ventas por Vendedor
							</th>
							<th style="color:white;" class="text-left">
								Venta
							</th>
							<th style="color:white;" class="text-left">
								Utilidad
							</th>
							</tr>
						</thead>
						<tbody>
							<tr
							v-for="item in VentasVendedores.ventasPorVendedor"
							:key="item.cajero"
							>
							<td>{{ item.vendedor }}</td>
							<td>{{ item.totalVentas.toLocaleString() }}</td>
							<td>{{ item.utilidadFactura.toLocaleString() }}</td>

							</tr>
						</tbody>
						</template>
					</v-simple-table>
			</v-col>
			<v-col cols="12" sm="6">
                    
					Ventas Colones
					<v-simple-table class="boton">
						<template v-slot:default>
						<thead>
							<tr>
							<th style="color:white;" class="text-left">
								Tipo Pago
							</th>
							<th style="color:white;" class="text-left">
								Venta
							</th>
							</tr>
						</thead>
						<tbody>
							<tr
							v-for="item in VentasVendedores.ventasColones"
							:key="item.cajero"
							>
							<td>{{ item.tipoPago }}</td>
							<td>{{ item.totalVentas.toLocaleString()  }}</td>
							</tr>
						</tbody>
						</template>
					</v-simple-table>
					Ventas Dolares
					<v-simple-table class="boton">
						<template v-slot:default>
						<thead>
							<tr>
							<th style="color:white;" class="text-left">
								Tipo Pago
							</th>
							<th style="color:white;" class="text-left">
								Venta
							</th>
							</tr>
						</thead>
						<tbody>
							<tr
							v-for="item in VentasVendedores.ventasDolares"
							:key="item.cajero"
							>
							<td>{{ item.tipoPago }}</td>
							<td>{{ item.totalVentas.toLocaleString()  }}</td>
							</tr>
						</tbody>
						</template>
					</v-simple-table>
                </v-col>
        <template class="pa-3">
            <v-snackbar
                    v-model="snackbar" top vertical="" :timeout="5000"
                    >
                    {{ Mensaje }}
                    <v-btn
                    color="info"
                    text
                    @click="snackbar = false"
                    >
                    Cerrar
                    </v-btn>
            </v-snackbar>
        </template>
    </v-row>
</template>

<script scoped>
import axios from 'axios'
import Chart from 'chart.js'
import jsPDF from 'jspdf'
import VueExcelXlsx from "vue-excel-xlsx";
import Vue from "vue";
import html2canvas from 'html2canvas';
import QrcodeVue from 'qrcode.vue'

Vue.use(VueExcelXlsx);
  export default {
    data: () => ({
    value: 'https://www.noah.cr',
    dialog: false,
    ClienteSeleccionado : '',
	loading: false,
	localidades:[],
	Localidad:'',
    today: new Date().toISOString().substr(0, 10),
    focus: new Date().toISOString().substr(0, 10),
	start: null,
	end: null,
    headers: [
    { text: 'Vendedor', value: 'cajero' },
    { text: 'Ventas Realizadas', value: 'totalVentas' },
    { text: 'Comision Generada ', value: 'montoComision' },
    ],
    columns : [
            { label: 'Vendedor', field: 'vendedor' },
            { label: 'Monto Venta', field: 'montoVenta' },
            { label: 'Utilidad generada', field: 'utilidadFactura' },
        ],
    VentasVendedores: [],
    tipoDocumentos: [
        'FISICA',
        'JURIDICA',
        'EXTRANJERO',
    ],
    familias: [],
    Mensaje: 'Seleccione un rango de fechas',
    snackbar: true,
    }),
    components: {
      QrcodeVue,
    },

    computed: {
    formTitle () {
            return this.editedIndex === -1 ? 'Nuevo usuario' : 'Actualizar usuario'
    },
    },
    

    watch: {
      dialog (val) {
        val || this.close()
      },
      
    },

	created () {
		this.listarAsociadas();
		const currentDate = new Date();

		// Formatearla como una cadena de fecha en el formato 'YYYY-MM-DD'
		const formattedDate = currentDate.toISOString().slice(0, 10);

		// Asignar al estado
		this.start = formattedDate;
		this.end = formattedDate;
    },

    methods: {
        ListarResumen(){
            if(this.start == '' || this.end == ''){
                this.snackbar = true;
                this.Mensaje = 'Debe seleccionar una fecha inicial y final'
                return;
		   }
		   this.loading = true;
            let me=this;
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
            axios.get('api/RepVentasVistas/CajerosVendedoresPorFecha/'+this.start+'/'+this.end+'/'+this.Localidad,configuracion).then(function(response){
                //console.log(response);
				me.VentasVendedores=response.data;
				me.loading = false;
            }).catch(function(error){
                console.log(error);
            });
        },
		listarAsociadas(){
			var items = [];
			let me=this;
			me.localidades.push(me.$store.state.usuario.localidad);
			this.Localidad = me.$store.state.usuario.localidad;
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			axios.get('api/LocalidadesAsociadas/'+this.$store.state.usuario.nombre,configuracion).then(function(response){
				items=response.data;
				items.map(function(x){
					me.localidades.push(x.localdiadAsociada);
				});
			}).catch(function(error){
				me.Mensaje = "Localidades Asociadas no encontradas";
				me.snackbar = true;
				me.modelCarga=false;
			});
		},
		close () {
			this.dialog = false;
			this.limpiar();
		},
    },
  }

</script>
<style scoped>
	::v-deep .v-data-table tbody tr:nth-child(odd) {
	background-color: #f5f5f5; /*color de fondo para filas impares*/
	}

	::v-deep .v-data-table tbody tr:nth-child(even) {
	background-color: #ffffff; /*color de fondo para filas pares*/
	}
	::v-deep .my-custom-class tbody tr:nth-child(odd) {
	background-color: #f5f5f5; /*color de fondo para filas impares*/
	}

	::v-deep .my-custom-class tbody tr:nth-child(even) {
	background-color: #ffffff; /*color de fondo para filas pares*/
	}
</style>