<template>
    <div>
		<v-dialog
			transition="dialog-bottom-transition"
			max-width="600"
			v-model="DialogEstado"
		>
			<v-card>
				<v-toolbar
				color="primary"
				dark
				>Actualizar Estado</v-toolbar>
				<v-card-text>
				<div class="text-h2 pa-12">Estado</div>
				</v-card-text>
				<v-select v-model="estadoOrden" label="Selcciona el estado" class="pa-4" :items="Estados" > </v-select>
				<v-row class="justify-end">
				<v-btn
					x-large color="red" dark
					@click="DialogEstado = false"
				>Close</v-btn>	<v-spacer/>
				<v-btn
					x-large color="green" dark
					@click="AceptarProforma()"
				>Actualizar</v-btn>
				</v-row>
			</v-card>
		</v-dialog>
        <v-dialog v-model="comprobanteModal" max-width="1000px">
            <v-card class="pa-3 font">
                
                <v-card-text>
                    <v-btn class="mx-2" color="indigo" dark="" @click="crearPDFFactura()"><v-icon>mdi-file-pdf</v-icon> PDF</v-btn>
                    <div id="factura">
                        <header>
							<div style="text-align:left;" id="datos">
                                <p id="encabezado">
                                    <strong style="font-weight: bold; font-size: 20px;"> Datos del cliente de la ODT en {{$store.state.usuario.localidad}}: </strong> <br>
									<div class="font-size; 10px;">
									<strong><v-icon>mdi-account </v-icon> {{nombreReceptor}}<br>
									<v-icon>mdi-card-bulleted </v-icon> {{cedulaReceptor}} <br>
									<v-icon>mdi-phone </v-icon> {{telefonoReceptor}} <br>
									<v-icon>mdi-email </v-icon> {{correoReceptor}} </strong> </div> 
						
                            </div>
                            <div style="text-align:right;" class="font-weight-regular  body-2" id="fact">
                                <p>{{tipoDocumento}}<br>
                               No ODT: {{noFactura}}<br>
                               
                               {{fecha}} <br>
                               Vendedor: {{vendedor}} <br/>
							   Vence: {{Vencimiento}}</p> 
                                
                            </div>
                        </header>
						<br>
                        <section>
                            <div>
                                <v-simple-table dense >
                                    <thead >
                                        <tr id="fa" class="primary white--text">
                                            <th class="white--text text-center">Cant</th>
                                            <th class="white--text text-center">Descripción</th>
											<th class="white--text text-center">Instrucciones</th>
											<th class="white--text text-center">Estado</th>
											<th class="white--text text-center">Dept</th>
											<th class="white--text text-center">Entrega</th>
                                        </tr>
                                    </thead>
                                    <tbody> 
                                        <tr v-for="det in detalles" :key="det.descripcion" >
                                            <td style="text-align:center;"> {{det.cantidad}} </td>
                                            <td style="text-align:center;"> {{det.descripcion}} </td>
                                            <td style="text-align:center;">{{det.detalleLinea}} </td>
											<td style="text-align:center;"> 
												<v-edit-dialog
												:return-value.sync="det.estadoLinea"
												@save="ActualizarODT(det)"
												large
												@cancel="cancel"
												@open="open"
												@close="close"
												>
												<v-chip :color="getColor(det.estadoLinea)" dark>{{ det.estadoLinea }}</v-chip>
												<template v-slot:input>
													<v-select
													onfocus="this.select();"
													v-model="det.estadoLinea"
													:items="EstadosVista"
													:rules="[max25chars]"
													:label="'Estado: '"
													></v-select>
												</template>
												</v-edit-dialog>
											</td>
											<td style="text-align:center;"> 
												<v-edit-dialog
												:return-value.sync="det.dept"
												@save="ActualizarODT(det)"
												large
												@cancel="cancel"
												@open="open"
												@close="close"
												>
												{{ det.dept }}
												<template v-slot:input>
													<v-select
													onfocus="this.select();"
													v-model="det.dept"
													:items="Deparamentos"
													:label="'Deparamento: '"
													></v-select>
												</template>
												</v-edit-dialog>	
											</td>
											<td style="text-align:center;"> 
												<v-edit-dialog
												:return-value.sync="det.vencimiento"
												@save="ActualizarODT(det)"
												large
												@cancel="cancel"
												@open="open"
												@close="close"
												>
												{{ det.vencimiento.substring(0,10) }} <br>
												<b>{{DayName(det)}}</b>
												<template v-slot:input>
													<v-text-field
													onfocus="this.select();"
													type="date"
													v-model="det.vencimiento"
													:label="'Fecha Vencimiento: '"
													></v-text-field>
												</template>
												</v-edit-dialog>		
											</td>
                                        </tr>
                                    </tbody>
                                    
                                </v-simple-table>
                            </div>
                        </section>
                        <br>
                        <br>
                    </div>
                    <v-btn @click="OcultarComprobante()" color="error" flat><v-icon> mdi-exit-to-app </v-icon></v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-data-table
            :headers="headers"
            :items="usuarios"
            :search="search"
			dense
			:loading="loading"
            class="elevation-1 "

            >
            <template v-slot:item.estado="{ item }">
            <v-chip :color="getColor(item.estado)" dark>{{ item.estado }}</v-chip>
            </template>
            <template v-slot:top>
                <v-row class="pa-2">
                    <v-col cols="12" sm="3">
                        <v-card-actions class="rounded-xl light-blue lighten-5 mx-5">
                            <v-btn fab="" color="error" class="mx-1" @click="crearPDF()"> <v-icon large="">mdi-file-pdf </v-icon> </v-btn>
                            
                                <vue-excel-xlsx
                                    :data="usuarios"
                                    :columns="columns"
                                    :filename="'Reporte de Odts '+$store.state.usuario.localidad"
                                    :sheetname="'Ventas'"
                                    >
                                    <v-btn fab="" color="success">  <v-icon large="">mdi-file-excel </v-icon> </v-btn>
                                </vue-excel-xlsx> 
                            <v-card-title>Odts</v-card-title>
                        </v-card-actions>
                    </v-col>
                    <v-col cols="12" sm="4">    
                        <v-text-field v-model="search"  append-icon="mdi-magnify"
                         label="Buscar" single-line hide-details filled
                        ></v-text-field>
						<v-select class="mt-6" @change="FiltroPorDepartamento()" filled v-model="Departamento" label="Filtro por Departamento"  :items="Deparamentos" > </v-select>
				
                    </v-col>
                    <v-col cols="12" sm="2">
                        <v-text-field filled v-model="start" type="date" label="Fecha inicial"></v-text-field>
						<v-select @change="FiltroPorEstado()" filled v-model="estadoOrden" label="Filtro por estado"  :items="EstadosVista" > </v-select>
				
                    </v-col>
                    <v-col cols="12" sm="2">
                        <v-text-field filled v-model="end"  type="date" label="Fecha final"></v-text-field>
						<v-select @change="FiltroPorCliente()" filled v-model="ClienteSeleccionado" label="Filtro por cliente"  :items="clientes" > </v-select>
                    </v-col>
                    <v-col cols="12" sm="1">
                        
                        <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                               <v-btn  color="info" @click="listarFechas()" v-bind="attrs"
                                v-on="on" fab > <v-icon fab> mdi-cloud-search </v-icon> </v-btn>
                            </template>
                            <span>Selecciona un rango de fecha y toca este botón para realizar la busqueda filtrada entre 2 fechas</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                               <v-btn small dark color="red" v-bind="attrs"
                                v-on="on" href="https://youtu.be/4zpaZKf3lDw" fab > <v-icon fab> mdi-youtube </v-icon> </v-btn>
                            </template>
                            <span>Da click para ver un video tutorial de ayuda en YouTube</span>
                        </v-tooltip>
                 </v-col>
                </v-row>
               
            </template>
            <template v-slot:item.action="{ item }">
                <v-menu top :offset-y="offset">
                    <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        small
                        fab=""
                        v-bind="attrs"
                        v-on="on"
                        
                    > <v-icon color="info">mdi-dots-horizontal</v-icon>
                    </v-btn>
                    </template>
                    <v-list>
					<v-list-item @click="MostrarComprobante(item)">
                        <v-list-item-title><v-icon >mdi-file-document</v-icon> 
                                Ver Odt
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="AbrirEstadoDialog(item.noProforma,item.estado)">
                        <v-list-item-title > <v-icon > mdi-cash-refund </v-icon>Actualizar Estado</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="DeleteProforma(item)" >
                        <v-list-item-title >
                        <v-icon > mdi-delete </v-icon> Eliminar Odt</v-list-item-title>
                    </v-list-item>
                    </v-list>
                </v-menu>
            <template >
            </template>
            </template>

            <template v-slot:no-data>
                <v-data-table item-key="name" class="elevation-1" loading loading-text="Cargando datos... Por favor espere"></v-data-table>
                <v-btn color="primary" @click="initialize">Reiniciar</v-btn>
            </template>
        </v-data-table>
        <template class="pa-3">
            <v-snackbar
                    v-model="snackbar" top vertical="" :timeout="5000"
                    >
                    {{ Mensaje }}
                    <v-btn
                    color="info"
                    text
                    @click="snackbar = false"
                    >
                    Cerrar
                    </v-btn>
            </v-snackbar>
            
            <v-row flat color="white"  class="pa-2">
                <v-col cols="12" sm="12">
                    <v-data-table
						:headers="headers2"
						:items="items"
						:search="search"
						class="elevation-1 "
						dense	
						:loading="loading"
						>
						<template v-slot:item.vencimiento="props">
							<b>{{DayName(props.item)}}</b>
						</template>
					</v-data-table>
                </v-col>
            </v-row>
        </template>
    </div>
</template>

<script scoped>
import axios from 'axios'
import Chart from 'chart.js'
import jsPDF from 'jspdf'
import VueExcelXlsx from "vue-excel-xlsx";
import Vue from "vue";
import html2canvas from 'html2canvas';
import QrcodeVue from 'qrcode.vue'

Vue.use(VueExcelXlsx);
  export default {
    data: () => ({
    mesesValores: null,
    nombreMeses: [],
    totalMeses: [],
    value: 'https://www.noah.cr',
    size: 100,
    dialog: false,
	Odt: 0,
	DialogEstado: false,
	estadoOrden: 0,
    Aceptadas: 0,
    esIVI: false,
	Estado : 0,
	Estados: [
		{ text: 'Pendiente', value: 0 },
		{ text: 'Pausa', value: 1 },
		{ text: 'Diseño', value: 2 },
		{ text: 'Producción', value: 3 },
		{ text: 'Finalizado', value: 4 },
		{ text: 'Instalación', value: 5 },
		{ text: 'Entregado', value: 6 },
		{ text: 'Entregado Facturado', value: 7 },
	],
	EstadosVista: [
		{ text: 'Pendiente', value: 'Pendiente' },
		{ text: 'Pausa', value: 'Pausa' },
		{ text: 'Diseño', value: 'Diseño' },
		{ text: 'Producción', value: 'Producción' },
		{ text: 'Finalizado', value: 'Finalizado' },
		{ text: 'Instalación', value: 'Instalación' },
		{ text: 'Entregado', value: 'Entregado' },
		{ text: 'Entregado Facturado', value: 'Entregado Facturado' },
	],
    search: '',
    ClienteSeleccionado : '',
	Vencimiento: new Date().toISOString().substr(0, 10),
    today: new Date().toISOString().substr(0, 10),
    focus: new Date().toISOString().substr(0, 10),
    headers: [
	{ text: 'Opciones', value: 'action', sortable: false },
    { text: 'Odt', value: 'noProforma' },
    { text: 'Estado', value: 'estado' },
    { text: 'Fecha Creacion', value: 'fecha' },
    { text: 'Total', value: 'total' },
    { text: 'Vendedor', value: 'vendedor' },
	{ text: 'Telefono', value: 'contacto' },
    { text: 'Cliente', value: 'cliente' },
	{ text: 'Detalle', value: 'detalle' },
    ],
	headers2: [
	{ text: 'Nombre', value: 'descripcion', sortable: false },
    { text: 'Cant', value: 'cantidad' },
    { text: 'Estado', value: 'estadoLinea' },
    { text: 'Fecha Creacion', value: 'fecha' },
    { text: 'Vence', value: 'vencimiento' },
    { text: 'Vendedor', value: 'vendedor' },
	{ text: 'Depto', value: 'dept' },
    { text: 'Cliente', value: 'cliente' },
	{ text: 'Detalle', value: 'detalleLinea' },
	{ text: 'No ODT', value: 'noProforma' },
    ],
    columns : [
            { label: "Odts",field: "noProforma", },
            { label: "Estado",  field: "estado", },
            { label: "Fecha",field: "fecha", },
            { label: "Vendedor",field: "vendedor", },
            { label: "Total",field: "total", },
            { label: "Cliente",field: "cliente", },
			{ label: "Detalle",field: "detalle", }, 
			{ label: "Telefono",field: "contacto", }, 
        ],
    cabeceraDetalles: [
    { text: 'Código', value: 'codigoProducto', sortable: false  },
    { text: 'Descripción', value: 'descripcion', sortable: false  },
    { text: 'Precio', value: 'actionprecio', sortable: false  },
    { text: 'Cantidad a debitar', value: 'actioncantidad', sortable: false  },
    ],
    tipoDocumentos: [
        'FISICA',
        'JURIDICA',
        'EXTRANJERO',
    ],
	Deparamentos: [
        'Ventas',
        'Contabilidad',
        'Gran Formato',
		'Pequeño Formato',
		'Sublimación',
		'Corte CNC',
		'Taller',
		'Rotulación',
    ],
    PagosFiltrados: "",
    Monedas: ["USD","CRC"],
    TiposPago:["TARJETA","DEPOSITO","EFECTIVO","TRANSFERENCIA"],

    MonedaFiltrar : "",
    tipoCedulaReceptor: '',
      search: '',
    emailRules: [
    v => !!v || 'El correo electrónico es requerido',
    v => /.+@.+\..+/.test(v) || 'El correo electrónico es requerido debe ser válido',],
    cedulaRules: [value => !!value || 'La cédula es requerida'
    , value => (value && value.length >= 9) || 'Debe ingresar 9 carácteres como mínimo',],
    nameRules: [
    value => !!value || 'El nombre es requerido',
    value => (value && value.length >= 10) || 'Debe ingresar 10 carácteres como mínimo',
    ],
    celRules: [
        value => !!value || 'El número es requerido',
        value => (value && value.length >= 8) || 'Debe ingresar 8 carácteres como mínimo',
        ],
    justificacionRules: [
        value => !!value || 'La justificación es requerida',
        value => (value && value.length >= 7) || 'Debe ingresar 7 carácteres como mínimo',
        ],
    editedIndex: -1,
    id: '',
    idrol:'',
    noFactura: 0,
    nofactura2: '/ticket/2',
    comprobanteModal: 0,
    justificacionNota: ["Ajuste de precio precio" ,"Saldo incobrable"," Pronto de pago","Error en cobro"],
    productosDatos:[],
    MensajeRespuesta: '',
    ClaveComprobante: '',
    EstadoNC: 0,
	loading : true,
    ModalNotaCredito: false,
    snackbar: true,
    Mensaje: "",
    correo2: '',
    VendedorSeleccionado: '',
    total: 0,
    justificacion: '',
    medioDePago : '',
    detalles: [],
    localidadencabezado:[],
    NombreLocalidad:'',
    CedulaLocalidad:'',
    Datos: [],
    DireccionLocalidad:'',
    Logo3: '',
    TelefonoLocalidad:'',
    fecha: '',
    hora: '',
    nombreReceptor: '',
    cedulaReceptor:'',
    direccion:'',
    monedaPago: "CRC",
    telefonoReceptor:'',
    correoReceptor:'',
    linkFacturar: 'facturar',
    vendedor: '',
    subtotal: 0,
    comentario: '',
    grabado:0 ,
	Departamento: '',
	excento: 0,
	ShowNoFact: false,
	Cuentas: '',
    Logo2 : '',
	items:[],
    iv: 0.0,
    clientes: [],
    start : '',
    end : '',
    tipo_documento: '',
    Vendedores: [],
	usuarios:[],    
	modoPDV: 0,
    desserts: [],
    editedIndex: -1,
    descuento: 0,
    
    }),
    components: {
      QrcodeVue,
    },

    computed: {
    formTitle () {
            return this.editedIndex === -1 ? 'Nuevo usuario' : 'Actualizar usuario'
    },
    calcularTotal:function(){
        var resultado=0.0;
          for(var i=0;i<this.usuarios.length;i++){
                resultado=resultado+(this.usuarios[i].total * 1);
          }
        
        return resultado.toFixed(2);
      },
      calcularPendientes:function(){
        var resultado=0.0;
          for(var i=0;i<this.usuarios.length;i++){
            if(this.usuarios[i].estado == 'PENDIENTE')
                resultado=resultado+(this.usuarios[i].total * 1);
          }
        
        return resultado.toFixed(2);
      },
     calcularAceptadas:function(){
        var resultado=0.0;
          for(var i=0;i<this.usuarios.length;i++){
            if(this.usuarios[i].estado == 'Aceptado')
                resultado=resultado+(this.usuarios[i].total * 1);
          }
        
        return resultado.toFixed(2);
      },
      calcularFacturadas:function(){
        var resultado=0.0;
          for(var i=0;i<this.usuarios.length;i++){
            if(this.usuarios[i].estado == 'Facturado')
                resultado=resultado+(this.usuarios[i].total * 1);
          }
        
        return resultado.toFixed(2);
      },
    },
    

    watch: {
      dialog (val) {
        val || this.close()
      },
      
    },

    created () {
      this.listar();
	  this.select();
	  this.listarODT();
    },

    methods: {
        select(){
		let me=this;
		var clientesArray=[];
		let header={"Authorization" : "Bearer "};
		let configuracion= {headers : header};
			axios.get('api/Empleados/SelectEmpleadosLocalidadComercial/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
			clientesArray=response.data;
			clientesArray.map(function(x){
				me.clientes.push(x.nombre);
			});
		})
        },
		
        listarDetalles(id){
			let me=this;
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			axios.get('api/Odts/ListarDatosFactura/'+id+'/'+this.$store.state.usuario.localidad+'',configuracion).then(function(response){
				//console.log(response);
				me.detalles=response.data;
				me.correoReceptor = me.detalles[0].correo;
				me.telefonoReceptor = me.detalles[0].contacto;
				me.subTotal = me.detalles[0].subTotal;
				me.vendedor = me.detalles[0].vendedor;
				me.subTotal = me.detalles[0].precioSinIva;
				me.iv = me.detalles[0].iva;
				me.monedaPago = me.detalles[0].contacto1;
				me.cedulaReceptor = me.detalles[0].tipoDocumento;
			}).catch(function(error){
				console.log(error);
			});
		},
        selectVendedores(){
            let me=this;
            let header={"Authorization" : "Bearer " };
            let configuracion= {headers : header};
            var proveedoresArray=[];
            axios.get('api/Usuarios/SelectUsuariosLocalidad/'+me.$store.state.usuario.localidad,configuracion).then(function(response){
                proveedoresArray=response.data;
                proveedoresArray.map(function(x){
                    me.Vendedores.push(x.nombre);
                });
            }).catch(function(error){
                console.log(error);
            });
        },
		AbrirEstadoDialog(No){
			this.DialogEstado = true;
			this.Odt = No;
		},
		AceptarProforma () {
            var me = this;
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
            axios.post('api/Odts/CambiarEstado',{
                'Estado': parseInt(this.estadoOrden),
				'NoProforma':this.Odt
                    
            },configuracion).then(function (response) {
                alert("Odt actualizada, no necesitas hacer nada más");
				me.DialogEstado = false;
                me.listar();
				
                })
                .catch(function (error) {
                    alert("Error actualizando, por favor reporte a su proveedor "+error);  
                })
        },
        listarLocalidad(){
            let me =this;
            let LogoTest= '';
            let self = this;
            
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
                axios.get('api/Localidades/ListarFiltrada/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
                    //console.log(response);
                    me.localidadencabezado=response.data;
					me.Logo2 = me.localidadencabezado[0].imagen;
					me.modoPDV = me.localidadencabezado[0].modoPDV;
					me.ShowNoFact = me.localidadencabezado[0].express; // Si este campo viene true se muestra el NoFactura del la clave numerica, sino se muestra el NoFactura interno
					me.Cuentas = me.localidadencabezado[0].latitud; // este campo contiene los numero de cuentas bancarios
                    function toDataURL(url, callback) {
                    var xhr = new XMLHttpRequest();
                    xhr.onload = function() {
                        var reader = new FileReader();
                        reader.onloadend = function() {
                        callback(reader.result);
                        }
                        reader.readAsDataURL(xhr.response);
                    };
                    xhr.open('GET', url);
                    xhr.responseType = 'blob';
                    xhr.send();
                    }
                    var url2 = me.Logo2;
                    
                        toDataURL(url2, function(dataUrl) {
                        console.log('RESULT:', dataUrl)
                        me.Logo3 = dataUrl;
                        }) 
                    }).catch(function(error){
                        console.log(error);
                    });
                
        },
        FiltrarCliente(){
            this.usuarios = this.usuarios.filter(e => e.medioDePago == this.PagosFiltrados);
            this.snackbar = true;
            this.Mensaje = 'Filtro Medio de Pago Seleccionado sobre rango de fecha listado'
        },
        FiltrarVendedor(){
            let amp = "";
            this.Datos = this.usuarios;
            this.usuarios = this.usuarios.filter(e => e.nombreReceptor == this.ClienteSeleccionado);
            this.snackbar = true;
            this.Mensaje = 'Filtro de cliente seleccionado sobre rango de fecha listado'
        },
        FiltrarCliente2(){
            let amp = "";
            this.Datos = this.usuarios;
            this.usuarios = this.usuarios.filter(e => e.nombreReceptor == this.ClienteSeleccionado);
            this.snackbar = true;
            this.Mensaje = 'Filtro de vendedor seleccionado sobre rango de fecha listado'
        },
        FiltrarMoneda(){
            let amp = "";
            this.Datos = this.usuarios;
            amp = this.Datos.filter(e => e.moneda == this.MonedaFiltrar);
            this.usuarios = amp;
            this.snackbar = true;
            this.Mensaje = 'Filtro Medio de Moneda Seleccionado sobre rango de fecha listado'
        },
            verLocalidad(item){
            this.NombreLocalidad= item.nombre;
            this.CedulaLocalidad= item.cedulaJuridica;
            this.DireccionLocalidad= item.dirreccion;
            },
		DayName(item){
			const date1 = new Date(item.fecha);
			const date2 = new Date(item.vencimiento);
			const differenceInTime = date2.getTime() - date1.getTime();
			const differenceInDays = differenceInTime / (1000 * 3600 * 24);
			if( differenceInDays == 0){
				return 'ATRASADO';
			}
			return differenceInDays + 'Días';
		},
        ActualizarODT(item){
            let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
				let me=this;
				axios.put('api/Odts/ActualizarEstado',{
					'EstadoLinea':item.estadoLinea,
					'Cajero': me.$store.state.usuario.nombre,
					'Vencimiento': item.vencimiento,
					'Dept':item.dept,
					'Localidad':me.$store.state.usuario.localidad,
					'IdProforma':item.idProforma,
				},configuracion).then(function(response){
					me.snackbar = true;
					me.Mensaje = 'ODT Actualizada ';         
					me.listarODT();            
				}).catch(function(error){
					me.snackbar = true;
					me.Mensaje = 'Problema actualizando, por favor verifique si el registro se almacenó' +error;   
				});
        },
        MostrarComprobante(item){
            this.nombreReceptor= item.cliente;
            this.vendedor = item.vendedor;
            this.noFactura = item.noProforma;
            this.fecha = item.fecha;
            this.total = item.total;
			this.Vencimiento = item.vencimiento;
            this.nofactura2 = "/proforma/"+this.noProforma;
            this.value = location.hostname+'/perfilcliente/'+this.$store.state.usuario.localidad+'/'+this.cedulaReceptor;
            this.listarDetalles(item.noProforma);
            this.comprobanteModal = true;
			this.comentario = item.detalle
        },
        OcultarComprobante(){
            this.comprobanteModal = 0;
        },
        DeleteProforma (item) {
			this.loading = true;
			var me = this;
			if(confirm("Seguro que quiere eliminar la Proforma  "+item.noProforma+"?")){
				let header={"Authorization" : "Bearer " + this.$store.state.token};
				let configuracion= {headers : header};
				axios.post('api/Odts/Delete',{
					'NoProforma': item.noProforma,
						
				},configuracion).then(function (response) {
					
					me.snackbar = true;
					me.Mensaje = 'Odt Eliminada Con éxito.'; 
					me.listar(); 
					
					})
					.catch(function (error) {
						me.snackbar = true;
						me.Mensaje = 'Error eliminando el Odt.';    
					})
			}
      },
        crearPDF(){
           var columns = [
            {title: "Odts", dataKey: "noProforma"},
            {title: "Estado", dataKey: "estado"}, 
            {title: "Fecha", dataKey: "fecha"}, 
            {title: "Vendedor", dataKey: "vendedor"},
            {title: "Total", dataKey: "total"},
            {title: "Cliente", dataKey: "cliente"},
            ];
            var rows = [];

            this.usuarios.map(function(x){
                rows.push({noProforma:x.noProforma,estado:x.estado,fecha:x.fecha,total:x.total,vendedor:x.vendedor,cliente:x.cliente});
            });

            // Only pt supported (not mm or in)
            var doc = new jsPDF('p', 'pt');
            doc.autoTable(columns, rows, {
                margin: {top: 60},
                addPageContent: function(data) {
                    doc.text("Listado Ordenes de trabajo", 40, 30);
                }
            });
            doc.save('ReporteOdts.pdf');
        },
        crearPDFFactura(){
            var fact = this.noFactura;
             var quotes = document.getElementById('factura');
			html2canvas(quotes).then(function (canvas) {
				var imgData = canvas.toDataURL('image/png');
				var imgWidth = 210;
				var pageHeight = 285;
				var imgHeight = canvas.height * imgWidth / canvas.width;
				var heightLeft = imgHeight;
				var doc = new jsPDF();
				var position = 0;

				doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);                    
				doc.save('Odts '+fact+'.pdf');
			});
        },
        getColor (i) {
		if (i == 'Pausa') return 'pink'	
        if (i == 'Pendiente') return 'deep-purple'
        if(i == 'Diseño') return 'teal'
		if (i == 'Producción') return 'amber'
        if(i == 'Finalizado') return 'cyan'
		if (i == 'Instalación') return 'indigo'
        if(i == 'Entregado') return 'green'
        else  return 'lime'
      },
       listar(){
            let me=this;
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
            axios.get('api/Odts/ListarVista/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
                //console.log(response);
                me.usuarios=response.data;
            }).catch(function(error){
                console.log(error);
            });
        },
		listarODT(){
            let me=this;
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
            axios.get('api/Odts/ListarODT/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
                //console.log(response);
                me.items=response.data;
				me.loading = false;
            }).catch(function(error){
                console.log(error);
				me.loading = false;
            });
        },
		FiltroPorDepartamento(){
            let me=this;
			this.items = [];
			this.loading = true;
			this.ClienteSeleccionado = '';
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
            axios.get('api/Odts/ListarDepartamento/'+this.$store.state.usuario.localidad+'/'+this.Departamento,configuracion).then(function(response){
                //console.log(response);
                me.items=response.data;
				me.estadoOrden= '';
				me.loading = false;
            }).catch(function(error){
                console.log(error);
				me.loading = false;
            });
        },
		FiltroPorEstado(){
           	this.loading = true;
            let me=this;
			this.ClienteSeleccionado = '';
			this.Departamento = ''
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
            axios.get('api/Odts/ListarEstado/'+this.$store.state.usuario.localidad+'/'+this.estadoOrden,configuracion).then(function(response){
                //console.log(response);
                me.usuarios=response.data;
				me.loading = false;
            }).catch(function(error){
                alert("Error aplicando filtro: "+error)
				me.loading = false;
            });
        },
		FiltroPorCliente(){
           	this.loading = true;
            let me=this;
			this.Departamento = '';
			this.estadoOrden = '';
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
            axios.get('api/Odts/ListarCliente/'+this.$store.state.usuario.localidad+'/'+this.ClienteSeleccionado,configuracion).then(function(response){
                //console.log(response);
                me.usuarios=response.data;
				me.loading = false;
            }).catch(function(error){
                alert("Error aplicando filtro: "+error)
				me.loading = false;
            });
        },
        
        listarFechas(){
            if(this.start == '' || this.end == ''){
                this.snackbar = true;
                this.Mensaje = 'Debe seleccionar una fecha inicial y final'
                return;
           }
            let me=this;
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
            axios.get('api/Odts/ConsultaFechas/'+this.start+'/'+this.end+'/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
                //console.log(response);
                me.usuarios=response.data;
            }).catch(function(error){
                console.log(error);
            });
        },

      close () {
        this.dialog = false;
        this.limpiar();
          },
    

      activarDesactivarMostrar(accion,item){
                this.adModal=1;
                this.adNombre=item.nombre;
                this.adId=item.idusuario;                
                if (accion==1){
                    this.adAccion=1;
                }
                else if (accion==2){
                    this.adAccion=2;
                }
                else{
                    this.adModal=0;
                }
            },
            activarDesactivarCerrar(){
                this.adModal=0;
            },
      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.desserts[this.editedIndex], this.editedItem)
        } else {
          this.desserts.push(this.editedItem)
        }
        this.close()
      },
    },
  mounted(){
      this.getProductosMasVendidos();
  }
  }

</script>
My CRUD