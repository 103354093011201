<template>
    <v-container fluid>
        <v-dialog v-model="comprobanteModal" max-width="1000px">
            <v-card class="pa-3">
                
                <v-card-text>
                    <v-btn class="oculto-impresion mx-2" color="indigo" dark="" @click="crearPDFFactura()"><v-icon>mdi-file-pdf</v-icon> PDF</v-btn>
                    <v-btn class="oculto-impresion mx-2" color="grey" dark="" onclick="window.print()"> <v-icon>mdi-printer</v-icon> A1</v-btn>
                    <v-btn class="oculto-impresion mx-2" color="grey" dark="" :to="nofactura2"> <v-icon>mdi-printer</v-icon> POS </v-btn>
                    <v-btn class="oculto-impresion mx-2" color="grey" dark="" @click="ReenvioFactura()" > <v-icon>mdi-cloud-download-outline</v-icon>XML Resp</v-btn>
                    <v-btn class="oculto-impresion mx-2" color="grey" dark="" @click="ReenvioFactura()" > <v-icon>mdi-cloud-download-outline</v-icon> XML FC </v-btn>
                    <v-btn class="oculto-impresion mx-2" color="success" dark=" " @click="ReenvioFactura()" > <v-icon>mdi-email</v-icon><v-icon x-small="">mdi-send</v-icon> </v-btn>
                    <v-text-field  class="oculto-impresion mx-2" label="Correo de reenvio" v-model="correo2"> </v-text-field> 
                    <div id="factura">
                        <header>
                            <div id="logo">
                                <img :src="Logo3" width="120" height="120">
                            </div>
                            <div v-for="det in localidadencabezado" :key="det.nombre" id="datos">
                                <p id="encabezado">
                                     <b class="text-h5 primary--text"> <br>{{det.razonSocial}}, </b><br> {{det.dirreccion}}<br> 
                                    : {{det.cedulaJuridica}} <br>{{det.telefono}}<br>
                                    {{det.correo}}
                                    
                                </p>
                            </div>
                            <div style="text-align:right;" class="font-weight-regular mx-7 mt-5 body-2" id="fact">
                                {{tipoDocumento}}<br>
                                NoFactura Interno: {{noFactura}}<br>
                                <span v-if="RefComprobante != ''">Referencia: {{RefComprobante}}</span><br> <!-- Cambiado de div a span -->
                                NoFactura: {{noFacturaElectronica}}<br>
                                {{fecha}} -- {{hora}} <br>
                                Vendedor: {{vendedor}}
                            </div>

                        </header>
                         <br>
                        <section>
                            <div>
                                <tbody>
                                    <tr>
                                        <td class="body-1">
                                            <strong><v-icon>mdi-account </v-icon></strong> {{nombreReceptor}}<br>
                                            <strong><v-icon>mdi-card-bulleted </v-icon></strong> {{cedulaReceptor}} <br>
                                            <strong><v-icon>mdi-email </v-icon></strong> {{correoReceptor}} <br>
                                        </td>
                                    </tr>
                                </tbody>
                            </div>
                        </section>
                        <section>
                            <div>
                                <v-simple-table dense id="facarticulo" >
                                    <thead>
                                        <tr id="fa" class="primary white--text">
                                            <th class="white--text text-center">Código</th>
                                            <th class="white--text text-center">Cant</th>
                                            <th class="white--text text-center">Descripción</th>
                                            <th class="white--text text-center">Precio</th>
                                            <th class="white--text text-center">Iva</th>
                                            <th class="white--text text-center">% Desc</th>
                                            <th class="white--text text-center">Importe</th>
                                            <th class="white--text text-center">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody> 
                                        <tr v-for="det in detalles" :key="det.codigoProducto" >
                                            
                                            <td style="text-align:center;"> {{det.codigoProducto}} </td>
                                            <td style="text-align:center;"> {{det.cantidad}} </td>
                                            <td style="text-align:center;">{{det.descripcion}}</td>
                                            <td style="text-align:center;">{{det.precioUnitario}}</td>
                                            <td style="text-align:center;">{{det.imp}}</td> 
                                            <td style="text-align:center;">{{det.descuento}}</td> 
                                            <td style="text-align:center;">{{det.subtotal}}</td>
                                            <td style="text-align:center;">{{det.total}}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr >
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th class="secondary rounded-l-xl" style="text-align:right;">SUBTOTAL</th>
                                            <th class="secondary" style="text-align:right;"> {{monedaPago}} {{subTotal}}</th>
                                        </tr>
                                        <tr>
                                            <th></th>
                                            <th class="text--grey"> Firma:</th>
                                            <th class="text--grey">________________________</th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th class="secondary rounded-l-xl" style="text-align:right;">IVA</th>
                                            <th class="secondary" style="text-align:right;"> {{monedaPago}} {{iv}}</th>
                                        </tr>
                                        <tr>
                                            <th></th>
                                            <th class="text--grey">Cédula:</th>
                                            <th class="text--grey">________________________ </th>
                                            <th></th>
                                            <th></th>
                                            <th>  </th>
                                            <th class="primary white--text rounded-l-xl" style="text-align:right;">TOTAL</th>
                                            <th class="primary white--text " style="text-align:right;"> {{monedaPago}} {{total}}</th>
                                        </tr>
                                    </tfoot>
                                    
                                </v-simple-table>
                            </div>
                        </section>
                        <br>
                        <br>
                        <footer>
                            <v-row>
                                <v-col class="mx-7" cols="12" sm="2">
                                <qrcode-vue :value="value" :size="size" level="H"></qrcode-vue>
                                    Escanéame
                                </v-col>
                                <v-col class="body-2" cols="12" sm="8">
                                    <div id="gracias">
                                    <br> Clave Comprobante: {{ClaveComprobante}} <br> Cuentas Bancarias: {{Cuentas}}
                                    <p><b>Gracias por su compra!</b><br> Detalle:  {{comentario}}  <br> Facturas Disponibles en {{value}}<br>
                                    Autorizado mediante Resolucion DGT-R-033-2019 DEL 6-20-2019</p>
                                </div>
                                 </v-col>
                               
                            </v-row>
                        </footer>
                    </div>
                    <v-btn @click="OcultarComprobante()" color="error" flat><v-icon> mdi-exit-to-app </v-icon></v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>

		<v-row>
			<v-col cols="12" sm="5">
				<v-card-actions>
					<v-text-field :loading="loading" autofocus outlined @keyup.enter="listar()" filled label="Digite su Cédula" v-model="cedulaReceptor"> </v-text-field>
					<v-btn fab=""  @click="listar()" color="success">  <v-icon large>mdi-account-search</v-icon> </v-btn>
				</v-card-actions>
			</v-col>
			<v-col cols="12" sm="3">
				<v-text-field  v-model="start" type="date" label="Fecha inicial"></v-text-field>
			</v-col>
			<v-col cols="12" sm="3">
				<v-text-field  v-model="end"  type="date" label="Fecha final"></v-text-field>
			</v-col>
			<v-col cols="12" sm="1">
				<v-btn :loading="loading" color="info" @click="listarFechas()" fab > <v-icon fab> mdi-cloud-search </v-icon> </v-btn>
			</v-col>
            <v-col cols="12" sm="12">    
			<v-data-table 
				:headers="headers"
				:items="usuarios"
				:search="search"
				sort-by="noFactura"
				sort-desc="noFactura"
				class="elevation-1 pa-1 oculto-impresion"

				>
				<template class="oculto-impresion" v-slot:item.mensajeRespuesta="{ item }">
				<v-chip :color="getColor(item.mensajeRespuesta)" dark>{{ item.mensajeRespuesta }}</v-chip>
				</template>
				<template class="oculto-impresion" v-slot:top>
					<v-row class="pa-2">
						<v-col cols="12" sm="6">
							<v-card-actions class="rounded-xl light-blue lighten-5 mx-1">
								
									<vue-excel-xlsx
										:data="usuarios"
										:columns="columns"
										:filename="'Reporte de Compras de '+nombreReceptor+' en '+localidad"
										:sheetname="'Ventas'"
										>
										<v-btn fab="" color="success">  <v-icon large="">mdi-file-excel </v-icon> </v-btn>
									</vue-excel-xlsx> 
								<v-card-title>Compras de {{nombreReceptor}}</v-card-title>
							</v-card-actions>
						</v-col>
						<v-col cols="12" sm="6">    
							<v-text-field v-model="search"  append-icon="mdi-magnify"
							label="Buscar" single-line hide-details
							></v-text-field>
						</v-col>
						
					</v-row>
				
				</template>
				<template class="oculto-impresion" v-slot:item.action="{ item }">
				<v-icon
					
					class="mr-2"
					color="primary" 
					@click="MostrarComprobante(item)"
				>
					mdi-file-document
				</v-icon>
				</template>
				<template class="oculto-impresion" v-slot:item.actionnoFactura="{ item }">
				<div v-if="!ShowNoFact"> {{item.noFactura}} </div>
				<div v-if="ShowNoFact"> {{item.claveComprobante.substring(37, 41)}} </div>

				</template>

				<template v-slot:no-data>
					<v-data-table item-key="name" class="elevation-1" loading loading-text="Esperando datos... Por favor ingrese su cédula"></v-data-table>
					<v-btn color="primary" @click="initialize">Reiniciar</v-btn>
				</template>
			</v-data-table>
			</v-col>
		</v-row>
        <template class="pa-3">
            <v-snackbar
                    v-model="snackbar" top vertical="" :timeout="5000"
                    >
                    {{ Mensaje }}
                    <v-btn
                    color="info"
                    text
                    @click="snackbar = false"
                    >
                    Cerrar
                    </v-btn>
            </v-snackbar>
            
            <v-row  flat color="white"  class="oculto-impresion pa-2">
                <v-col cols="12" sm="4">
                    <v-row flat color="white"  class="rounded-xl light-blue lighten-5 mx-5">
                    <v-card-text>
                        <v-card-title class="primary--text text-xl-h4 text-lg-h6 text-sm-body-2"> <b>Resumen General </b></v-card-title>
                        <v-sub-title>Totales de ingresos </v-sub-title> <br>
                        <b> IVA 1%: </b>{{MonedaFiltrar}} {{calcularIva1}} <br>
                        <b> IVA 2%: </b>{{MonedaFiltrar}} {{calcularIva2}} <br>
                        <b> IVA 4%: </b>{{MonedaFiltrar}} {{calcularIva4}} <br>
                        <b> IVA 13%: </b>{{MonedaFiltrar}} {{calcularIva13}} <br> 
                        <b> Recibos: </b>{{MonedaFiltrar}} {{(calcularRecibos)}} <br> 
						<b> Transferencias: </b>{{MonedaFiltrar}} {{calcularTransferencias}} <br> 
						<b> Tarjetas: </b>{{MonedaFiltrar}} {{calcularTarjetas}} <br> 
						<b> Efectivo: </b>{{MonedaFiltrar}} {{calcularEfectivo}} <br> 
                        <b> Total Hacienda:</b> {{MonedaFiltrar}} {{(calcularTotal)-(calcularNC)-(calcularRecibos)}} <br>
                        <b> IVA Neto: </b>{{MonedaFiltrar}} {{TotalIva=(calcularIva)}} <br>
                        <b> Ventas Bruto:</b> {{MonedaFiltrar}} {{(calcularTotal)}} <br>
                        <b> Notas Crédito: </b>  {{MonedaFiltrar}} {{(calcularNC)}} <br>
                        <b> Ventas Netas:</b> {{MonedaFiltrar}} {{(calcularTotal)-(calcularNC)}} <br>
                    
                    </v-card-text>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="8">
                    <canvas id="myChart" class="rounded-xl light-blue lighten-5 mx-5">
                        
                    </canvas>
                </v-col>
                
                
            </v-row>
        </template>
    </v-container>
</template>

<script scoped>
import axios from 'axios'
import Chart from 'chart.js'
import jsPDF from 'jspdf'
import autotable from 'jspdf-autotable'
import VueExcelXlsx from "vue-excel-xlsx";
import Vue from "vue";
import html2canvas from 'html2canvas';
import QrcodeVue from 'qrcode.vue'

Vue.use(VueExcelXlsx);
  export default {
    data: () => ({
    mesesValores: null,
    nombreMeses: [],
    totalMeses: [],
    value: 'https://www.noah.cr',
    size: 100,
    dialog: false,
	search: '',
	RefComprobante: '',
    ClienteSeleccionado : '',
    today: new Date().toISOString().substr(0, 10),
    focus: new Date().toISOString().substr(0, 10),
    headers: [
    { text: 'Opciones', value: 'action', sortable: false },
    { text: 'Factura', value: 'actionnoFactura' },
    { text: 'Estado', value: 'mensajeRespuesta' },
    { text: 'Fecha', value: 'fecha' },
    { text: 'Usuario', value: 'cajero' },
    { text: 'Tipo', value: 'tipoDocumento' },
    { text: 'Grabado', value: 'gravado', sortable: false  },
    { text: 'IVA', value: 'impuesto', sortable: false  },
    { text: 'Total', value: 'total', sortable: false  },  
    { text: 'TipoPago', value: 'tipoPago',  },                  
    ],
    columns : [
            { label: "Factura",field: "noFactura", },
            { label: "Estado",  field: "mensajeRespuesta", },
            { label: "Fecha",field: "fecha", },
            { label: "Vendedor",field: "vendedor", },
            { label: 'Usuario', field: 'cajero' },
            { label: "Tipo",field: "tipoDocumento", },
            { label: "Grabado",field: "gravado", },
            { label: "Excento",field: "exento", },
            { label: 'Total', field: 'total' },  
            { label: 'Moneda', field: 'moneda',  },  
            { label: "TipoPago",field: "tipoPago", },
            { label: "Nombre Cliente",field: "nombreReceptor", },
            { label: 'Cédula Cliente', field: 'cedulaReceptor',  },  
            { label: 'Correo Cliente', field: 'correoReceptor',  },  
            { label: 'Imp 1%', field: 'impuesto1',  },  
            { label: 'Imp 2%', field: 'impuesto2',  },  
            { label: 'Imp 4%', field: 'impuesto4',  },  
            { label: 'Imp 13%', field: 'impuesto13',  }, 
            { label: 'Gravado 1%', field: 'gravado1',  },  
            { label: 'Gravado 2%', field: 'gravado2',  },  
            { label: 'Gravado 4%', field: 'gravado4',  },  
            { label: 'Gravado 13%', field: 'gravado13',  }, 
            { label: 'Clave numérica', field: 'claveComprobante',  },  
        ],
    tipoDocumentos: [
        'FISICA',
        'JURIDICA',
        'EXTRANJERO',
    ],
    PagosFiltrados: "",
    Monedas: ["USD","CRC"],
    TiposPago:["TARJETA","DEPOSITO","EFECTIVO","TRANSFERENCIA"],
	Descuento: '',
    MonedaFiltrar : "",
    tipoCedulaReceptor: '',
      search: '',
    TotalRecibos: 0,
    noFacturaElectronica: '',
    emailRules: [
    v => !!v || 'El correo electrónico es requerido',
    v => /.+@.+\..+/.test(v) || 'El correo electrónico es requerido debe ser válido',],
    cedulaRules: [value => !!value || 'La cédula es requerida'
    , value => (value && value.length >= 9) || 'Debe ingresar 9 carácteres como mínimo',],
    nameRules: [
    value => !!value || 'El nombre es requerido',
    value => (value && value.length >= 10) || 'Debe ingresar 10 carácteres como mínimo',
    ],
    celRules: [
        value => !!value || 'El número es requerido',
        value => (value && value.length >= 8) || 'Debe ingresar 8 carácteres como mínimo',
        ],
    justificacionRules: [
        value => !!value || 'La justificación es requerida',
        value => (value && value.length >= 7) || 'Debe ingresar 7 carácteres como mínimo',
        ],
    editedIndex: -1,
    id: '',
	idrol:'',
	loading: false,
    noFactura: 0,
    nofactura2: '/ticket/2',
    comprobanteModal: 0,
    justificacionNota: ["Ajuste de precio precio" ,"Saldo incobrable"," Pronto de pago","Error en cobro"],
    productosDatos:[],
    MensajeRespuesta: '',
    Logo3: null,
    ClaveComprobante: '',
    EstadoNC: 0,
    ModalNotaCredito: false,
    snackbar: false,
    Mensaje: "",
    correo2: '',
    VendedorSeleccionado: '',
    total: 0,
    justificacion: '',
    medioDePago : '',
	detalles: [],
	detalle: '',
    localidadencabezado:[],
    NombreLocalidad:'',
    CedulaLocalidad:'',
    Datos: [],
    DireccionLocalidad:'',
    TelefonoLocalidad:'',
    fecha: '',
    hora: '',
    nombreReceptor: '',
    cedulaReceptor:'',
    direccion:'',
    monedaPago: "CRC",
    telefonoReceptor:'',
	correoReceptor:'',
	Resolucion: '',
	vendedor: '',
	ShowNoFact: true,
	subtotal: 0,
	Cuentas: '',
	modoPDV: '',
	localidad: '',
	link: '',
    comentario: '',
    grabado:0 ,
    excento: 0,
    Logo2 : '',
    iv: 0.0,
    clientes: [],
    start : '',
    end : '',
    tipo_documento: '',
    Vendedores: [],
    usuarios:[],    
    desserts: [],
    editedIndex: -1,
    descuento: 0,
    
    }),
    components: {
      QrcodeVue,
    },

    computed: {
    formTitle () {
            return this.editedIndex === -1 ? 'Nuevo usuario' : 'Actualizar usuario'
	},
	calcularTotalNota:function(){
      var resultado=0.0;
	  var preresult = 0.0;
          for(var i=0;i<this.detalles.length;i++){
				if(this.detalles[i].Ivi == false){  //Si el campo estadoInventario es = a false entonces el IVA se suma
                    resultado= (parseFloat(this.totalGravado) + parseFloat(this.calcularImpuesto)+parseFloat(this.totalExento)) - parseFloat(this.totalDescuento);
              } if(this.detalles[i].Ivi == true){
				  if(this.monedaPago == 'USD'){
					preresult= (parseFloat(this.totalGravado) + parseFloat(this.calcularImpuesto)+parseFloat(this.totalExento)) - parseFloat(this.totalDescuento);
					  resultado = parseFloat(preresult)
					  return resultado.toFixed(2);
				}
                  preresult= (parseFloat(this.totalGravado) + parseFloat(this.calcularImpuesto)+parseFloat(this.totalExento)) - parseFloat(this.totalDescuento);
                  resultado = parseInt(preresult)
              }
          }
        
        
        return resultado.toFixed(2);
    },
    calcularTotal:function(){
        var resultado=0.0;
          for(var i=0;i<this.usuarios.length;i++){
			  if(this.usuarios[i].tipoDocumento == "TIQUETE ELECTRONICO" || this.usuarios[i].tipoDocumento == "FACTURA ELECTRONICA" || this.usuarios[i].tipoDocumento == "RECIBO"){
				resultado=resultado+(this.usuarios[i].total * 1);
				}
          }
        
        return resultado.toFixed(2);
      },
      calcularNC:function(){
        var resultado=0.0;
          for(var i=0;i<this.usuarios.length;i++){
            if(this.usuarios[i].tipoDocumento == 'NOTA DE CREDITO')
                resultado=resultado+(this.usuarios[i].total * 1);
          }
        
        return resultado.toFixed(2);
      },
      calcularRecibos:function(){
        var resultado=0.0;
          for(var i=0;i<this.usuarios.length;i++){
            if(this.usuarios[i].tipoDocumento == 'RECIBO')
                resultado=resultado+(this.usuarios[i].total * 1);
          }
        
        return resultado.toFixed(2);
	  },
	  calcularTransferencias:function(){
        var resultado=0.0;
          for(var i=0;i<this.usuarios.length;i++){
            if(this.usuarios[i].tipoPago == 'DEPOSITO')
                resultado=resultado+(this.usuarios[i].total * 1);
          }
        
        return resultado.toFixed(2);
	  },
	  calcularTarjetas:function(){
        var resultado=0.0;
          for(var i=0;i<this.usuarios.length;i++){
			  if(this.usuarios[i].tipoDocumento == "TIQUETE ELECTRONICO" || this.usuarios[i].tipoDocumento == "FACTURA ELECTRONICA" || this.usuarios[i].tipoDocumento == "RECIBO"){
				if(this.usuarios[i].tipoPago == 'TARJETA')
					resultado=resultado+(this.usuarios[i].total * 1);
				}
			  }
            
        
        return resultado.toFixed(2);
	  },
	  calcularEfectivo:function(){
        var resultado=0.0;
          for(var i=0;i<this.usuarios.length;i++){
			  if(this.usuarios[i].tipoDocumento == "TIQUETE ELECTRONICO" || this.usuarios[i].tipoDocumento == "FACTURA ELECTRONICA" || this.usuarios[i].tipoDocumento == "RECIBO"){
				if(this.usuarios[i].tipoPago == 'EFECTIVO')
					resultado=resultado+(this.usuarios[i].total * 1);
				}
			  }
            
        
        return resultado.toFixed(2);
      },
    calcularIva:function(){
        var resultado=0.0;
          for(var i=0;i<this.usuarios.length;i++){
            if(this.usuarios[i].tipoDocumento == "TIQUETE ELECTRONICO" || this.usuarios[i].tipoDocumento == "FACTURA ELECTRONICA"){
					resultado=resultado+(this.usuarios[i].impuesto13 * 1);
					
			}   if(this.usuarios[i].tipoDocumento == 'NOTA DE CREDITO') {
				resultado -= (this.usuarios[i].impuesto13 * 1);
			}
          }
        
        return resultado.toFixed(2);
      
    },
    calcularIva13:function(){
        var resultado=0.0;
          for(var i=0;i<this.usuarios.length;i++){
                    resultado=resultado+(this.usuarios[i].impuesto13 * 1);
                
          } 
        
        return resultado.toFixed(2);
      
    },
    calcularIva1:function(){
        var resultado=0.0;
          for(var i=0;i<this.usuarios.length;i++){
               resultado=resultado+(this.usuarios[i].impuesto1 * 1);
               
          }
        
        return resultado.toFixed(2);
      
    },
    calcularIva2:function(){
        var resultado=0.0;
          for(var i=0;i<this.usuarios.length;i++){
                    resultado=resultado+(this.usuarios[i].impuesto2 * 1);
                
          }
        
        return resultado.toFixed(2);
      
    },
    calcularIva4:function(){
        var resultado=0.0;
          for(var i=0;i<this.usuarios.length;i++){
                    resultado=resultado+(this.usuarios[i].impuesto4 * 1);
               
          }
        
        return resultado.toFixed(2);
    },
    calcularImpuestoTotal:function(){
      var resultado=0.0;
      var test= false;
        for(var i=0;i<this.detalles.length;i++){
          if(this.detalles[i].Ivi == false && this.detalles[i].gravado == 'S'){
            resultado=resultado+(this.detalles[i].precio * this.detalles[i].cantidad);
            return resultado.toFixed(2);
          }    
            if(this.detalles[i].gravado == 'S' && this.detalles[i].Ivi == true)
            {
              if(this.detalles[i].valorImpuesto == 13)
            {
              resultado+=((this.detalles[i].precio / 1.13) * this.detalles[i].cantidad);
            } else
              if(this.detalles[i].valorImpuesto == 1)
              {
                resultado+=((this.detalles[i].precio / 1.01) * this.detalles[i].cantidad);
              } else
              if(this.detalles[i].valorImpuesto == 2)
              {
                resultado+=((this.detalles[i].precio / 1.02) * this.detalles[i].cantidad);
              } else
              if(this.detalles[i].valorImpuesto == 4)
              {
                resultado+=((this.detalles[i].precio / 1.04) * this.detalles[i].cantidad);
              }
            }
        }
      return resultado.toFixed(2);
    },
    },
    

    watch: {
      dialog (val) {
        val || this.close()
      },
      
    },

    created () {
	  this.validarCliente();
      this.listar();
    },

    methods: {
        listarDetalles(id){
                let me=this;
                let header={"Authorization" : "Bearer " + this.$store.state.token};
                let configuracion= {headers : header};
                axios.get('api/Facturas/ListarDatosFactura/'+id+'/'+this.localidad+'',configuracion).then(function(response){
                    //console.log(response);
                    me.detalles=response.data;
                    me.EstadoNC = me.detalles[0].estadoNC;
                    me.hora = me.detalles[0].hora;
                    me.noFacturaElectronica = me.detalles[0].noFactura;
					me.ClaveComprobante = me.detalles[0].claveComprobante;
					me.RefComprobante = me.detalles[0].refComprobante;
                    me.comentario = me.detalles[0].cajero;
                }).catch(function(error){
                    console.log(error);
                });
			},
        listarLocalidad(){
            let me =this;
            let LogoTest= '';
            let self = this;
            
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
                axios.get('api/Localidades/ListarFiltrada/'+this.localidad,configuracion).then(function(response){
                    //console.log(response);
                    me.localidadencabezado=response.data;
					me.Logo2 = me.localidadencabezado[0].imagen;
					me.modoPDV = me.localidadencabezado[0].modoPDV;
					me.ShowNoFact = me.localidadencabezado[0].express; // Si este campo viene true se muestra el NoFactura del la clave numerica, sino se muestra el NoFactura interno
					me.Cuentas = me.localidadencabezado[0].latitud; // este campo contiene los numero de cuentas bancarios
                    function toDataURL(url, callback) {
                    var xhr = new XMLHttpRequest();
                    xhr.onload = function() {
                        var reader = new FileReader();
                        reader.onloadend = function() {
                        callback(reader.result);
                        }
                        reader.readAsDataURL(xhr.response);
                    };
                    xhr.open('GET', url);
                    xhr.responseType = 'blob';
                    xhr.send();
                    }
                    var url2 = me.Logo2;
                    
                        toDataURL(url2, function(dataUrl) {
                        console.log('RESULT:', dataUrl)
                        me.Logo3 = dataUrl;
                        }) 
                    }).catch(function(error){
                        console.log(error);
                    });
                
		},
		eliminarProducto(id){
        //console.log(id)
		this.detalles = this.detalles.filter(e => e.idproducKey != id)
		this.iv = 0;
		this.subTotal = 0;
		this.total = 0;
		for(var i = 0; i < this.detalles.length; ++i)
        {	
			if(this.detalles[i].estadoImp == true){
				if(this.detalles[i].gravado == 'S')
				{
					if(this.detalles[i].valorImpuesto == 13)
					{
					this.total +=parseInt(((this.detalles[i].precioUnitario) * this.detalles[i].cantidad) * 1.13);
					this.iv += ((this.detalles[i].precioUnitario  * this.detalles[i].cantidad) * 0.13);
					this.subTotal += parseFloat(this.detalles[i].precioUnitario) * (this.detalles[i].cantidad) 
					} else
					if(this.detalles[i].valorImpuesto == 4)
					{
					this.total+=parseInt(((this.detalles[i].precioUnitario) * this.detalles[i].cantidad) * 1.04);
					this.iv += (((this.detalles[i].precioUnitario) * this.detalles[i].cantidad) * 0.04);
					this.subTotal += parseFloat(this.detalles[i].precioUnitario) * (this.detalles[i].cantidad) 
					} else
					if(this.detalles[i].valorImpuesto == 2)
					{
					this.total+=parseInt(((this.detalles[i].precioUnitario) * this.detalles[i].cantidad) * 1.02);
					this.iv += (((this.detalles[i].precioUnitario) * this.detalles[i].cantidad) * 0.02);
					this.subTotal += parseFloat(this.detalles[i].precioUnitario) * (this.detalles[i].cantidad) 
					} else
					if(this.detalles[i].valorImpuesto == 1)
					{
					this.total+=parseInt(((this.detalles[i].precioUnitario) * this.detalles[i].cantidad) * 1.01);
					this.iv += (((this.detalles[i].precioUnitario) * this.detalles[i].cantidad) * 0.01);
					this.subTotal += parseFloat(this.detalles[i].precioUnitario) * (this.detalles[i].cantidad) 
					} 
				}   
			}
			if(this.detalles[i].estadoImp == false)
			this.total += parseFloat(this.detalles[i].precioUnitario) * (this.detalles[i].cantidad) 
				if(this.detalles[i].valorImpuesto == 13)
					{
					this.iv += parseInt(((this.detalles[i].precioUnitario / 1.13) * this.detalles[i].cantidad) * 0.13);
					this.subTotal += parseInt(((this.detalles[i].precioUnitario / 1.13) * this.detalles[i].cantidad) );
					} else
					if(this.detalles[i].valorImpuesto == 4)
					{
					this.iv += (((this.detalles[i].precioUnitario / 1.04) * this.detalles[i].cantidad) * 0.04);
					this.subTotal += parseInt(((this.detalles[i].precioUnitario / 1.04) * this.detalles[i].cantidad) );
					} else
					if(this.detalles[i].valorImpuesto == 2)
					{
					this.iv += (((this.detalles[i].precioUnitario / 1.02) * this.detalles[i].cantidad) * 0.02);
					this.subTotal += parseInt(((this.detalles[i].precioUnitario / 1.02) * this.detalles[i].cantidad) );
					} else
					if(this.detalles[i].valorImpuesto == 1)
					{
					this.iv += (((this.detalles[i].pprecioUnitariorecio / 1.01) * this.detalles[i].cantidad) * 0.01);
					this.subTotal += parseInt(((this.detalles[i].precioUnitario / 1.01) * this.detalles[i].cantidad) );
					} 
		}
        //console.log(this.cards)
	  },
	  AplicarDescuento(){
		for(var i = 0; i < this.detalles.length; ++i)
        {	
			 this.detalles[i].precioUnitario = (((this.Descuento)/100)*(this.detalles[i].precioUnitario)).toFixed(2);
		}
		
		this.Recalcular();
	  },
	  Recalcular(){
        //console.log(id)
		this.iv = 0;
		this.subTotal = 0;
		this.total = 0;
		for(var i = 0; i < this.detalles.length; ++i)
        {		this.total += parseInt(this.detalles[i].precioUnitario) * (this.detalles[i].cantidad) 
				if(this.detalles[i].gravado == 'S')
				{
					if(this.detalles[i].valorImpuesto == 13)
					{
					this.iv += parseInt(((this.detalles[i].precioUnitario / 1.13) * this.detalles[i].cantidad) * 0.13);
					this.subTotal += parseInt(((this.detalles[i].precioUnitario / 1.13) * this.detalles[i].cantidad) );
					} else
					if(this.detalles[i].valorImpuesto == 4)
					{
					this.iv += (((this.detalles[i].precioUnitario / 1.04) * this.detalles[i].cantidad) * 0.04);
					this.subTotal += parseInt(((this.detalles[i].precioUnitario / 1.04) * this.detalles[i].cantidad) );
					} else
					if(this.detalles[i].valorImpuesto == 2)
					{
					this.iv += (((this.detalles[i].precioUnitario / 1.02) * this.detalles[i].cantidad) * 0.02);
					this.subTotal += parseInt(((this.detalles[i].precioUnitario / 1.02) * this.detalles[i].cantidad) );
					} else
					if(this.detalles[i].valorImpuesto == 1)
					{
					this.iv += (((this.detalles[i].pprecioUnitariorecio / 1.01) * this.detalles[i].cantidad) * 0.01);
					this.subTotal += parseInt(((this.detalles[i].precioUnitario / 1.01) * this.detalles[i].cantidad) );
					} 
				}   
		} 
		
        //console.log(this.cards)
	  },
	  RecalcularNota(){
      var resultado=0.0;
	  var preresult = 0.0;
          for(var i=0;i<this.detalles.length;i++){
				if(this.detalles[i].Ivi == false){  //Si el campo estadoInventario es = a false entonces el IVA se suma
                    resultado= (parseFloat(this.totalGravado) + parseFloat(this.calcularImpuesto)+parseFloat(this.totalExento)) - parseFloat(this.totalDescuento);
              } if(this.detalles[i].Ivi == true){
				  if(this.monedaPago == 'USD'){
					preresult= (parseFloat(this.totalGravado) + parseFloat(this.calcularImpuesto)+parseFloat(this.totalExento)) - parseFloat(this.totalDescuento);
					  resultado = parseFloat(preresult)
					  return resultado.toFixed(2);
				}
                  preresult= (parseFloat(this.totalGravado) + parseFloat(this.calcularImpuesto)+parseFloat(this.totalExento)) - parseFloat(this.totalDescuento);
                  resultado = parseInt(preresult)
              }
          }
        
        
        return resultado.toFixed(2);
	},
	
	calcularImpuesto:function(){
      var resultado=0.0;
          resultado=this.imp1 + this.imp2+ this.imp4 + this.imp13;
      return resultado.toFixed(2);
	},
	
        FiltrarCliente(){
            this.usuarios = this.usuarios.filter(e => e.nombreReceptor == this.ClienteSeleccionado);
            this.snackbar = true;
            this.Mensaje = 'Filtro Medio de Pago Seleccionado sobre rango de fecha listado'
        },
        FiltrarVendedor(){
            let amp = "";
            this.Datos = this.usuarios;
            this.usuarios = this.usuarios.filter(e => e.vendedor == this.VendedorSeleccionado);
            this.snackbar = true;
            this.Mensaje = 'Filtro de cliente seleccionado sobre rango de fecha listado'
        },
        FiltrarPago(){
            let amp = "";
            this.Datos = this.usuarios;
            this.usuarios = this.usuarios.filter(e => e.nombreReceptor == this.ClienteSeleccionado);
            this.snackbar = true;
            this.Mensaje = 'Filtro de vendedor seleccionado sobre rango de fecha listado'
        },
        FiltrarMoneda(){
            let amp = "";
            this.Datos = this.usuarios;
            amp = this.Datos.filter(e => e.moneda == this.MonedaFiltrar);
            this.usuarios = amp;
            this.snackbar = true;
            this.Mensaje = 'Filtro Medio de Moneda Seleccionado sobre rango de fecha listado'
        },
            verLocalidad(item){
            this.NombreLocalidad= item.nombre;
            this.CedulaLocalidad= item.cedulaJuridica;
            this.DireccionLocalidad= item.dirreccion;
            },
        ReenvioFactura(){
            if(this.correo2 == ''){
                this.snackbar = true;
                this.Mensaje = 'Digite el correo al que desea reenviar la factura'
                return;
            }
            let header={"Authorization" : "Bearer " + this.$store.state.token};
                let configuracion= {headers : header};
                    let me=this;
                    axios.put('api/CorreosFacturas/Reenviar',{
                        'NoFactura':me.noFactura,
                        'Estado': 0,
                        'Correo2': me.correo2,
                        'ClaveNumerica':me.ClaveComprobante,
                    },configuracion).then(function(response){
                        me.snackbar = true;
                        me.Mensaje = 'Correo reenviado con éxito a '+me.correo2;                     
                    }).catch(function(error){
                        console.log(error);
                        me.snackbar = true;
                        me.Mensaje = 'Reenvio de factura a '+me.correo2+'. Disponible proximamente';   
                    });
        },
        loadProductosMasVendidos(){
            let me=this;
            let mesn='';
            me.mesesValores.map(function(x){
            switch(parseInt(x.etiqueta)){
                case 1:
                mesn='Enero';
                break;
                case 2:
                mesn='Febrero';
                break;
                case 3:
                mesn='Marzo';
                break;
                case 4:
                mesn='Abril';
                break;
                case 5:
                mesn='Mayo';
                break;
                case 6:
                mesn='Junio';
                break;
                case 7:
                mesn='Julio';
                break;
                case 8:
                mesn='Agosto';
                break;
                case 9:
                mesn='Setiembre';
                break;
                case 10:
                mesn='Octubre';
                break;
                case 11:
                mesn='Noviembre';
                break;
                case 12:
                mesn='Diciembre';
                break;
                default:
                mesn='Error';
            }
            me.nombreMeses.push(mesn);
            me.totalMeses.push(x.valor);
            });
            var ctx = document.getElementById("myChart");
            var myChart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: me.nombreMeses,
                    datasets: [{
                        label: 'Compras en los últimos 12 Meses',
                        data: me.totalMeses,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)',
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)'
                        ],
                        borderColor: [
                            'rgba(255,99,132,1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)',
                            'rgba(255,99,132,1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        borderWidth: 1
                    }]
                },
                options: {
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero:true
                            }
                        }]
                    }
                }
            });
        },
        getProductosMasVendidos(){
            let me=this;
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
            axios.get('api/RepVentasVistas/VentasMes12Cliente/'+me.cedulaReceptor,configuracion).then(function(response){
                //console.log(response);
                me.mesesValores=response.data;
                me.loadProductosMasVendidos();
            }).catch(function(error){
                console.log(error);
                alert("Error de conexión de graficos")
            });    
        },
        MostrarComprobante(item){
            this.nombreReceptor= item.nombreReceptor;
            this.cedulaReceptor = item.cedulaReceptor;
            this.vendedor = item.vendedor;
            this.tipoDocumento = item.tipoDocumento;
            this.correoReceptor = item.correoReceptor;
            this.noFactura = item.noFactura;
            this.tipoCedulaReceptor = item.tipoCedulaReceptor;
            this.fecha = item.fecha;
            this.subTotal = item.gravado;
            this.total = item.total;
            this.monedaPago = item.moneda;
            this.nofactura2 = "/ticket/"+this.noFactura;
            this.value = location.hostname+'/perfilcliente/'+this.$store.state.usuario.localidad+'/'+this.cedulaReceptor;
            this.grabado = item.gravado;
            this.exento = item.exento;
            this.iv = item.impuesto;
            this.listarDetalles(item.noFactura);
            this.comprobanteModal = true;
        },
        MostrarComprobante2(value){
            this.MostrarComprobante(value);
            
        },
        NotaCredito(item){
            this.nombreReceptor= item.nombreReceptor;
            this.cedulaReceptor = item.cedulaReceptor;
            this.correoReceptor = item.correoReceptor;
            this.noFactura = item.noFacturaReal;
            this.tipoCedulaReceptor = item.tipoCedulaReceptor;
            this.fecha = item.fecha;
            this.monedaPago = item.moneda;
            this.tipoCedula = item.tipoCedulaReceptor;
            this.telefonoReceptor = item.telefonoReceptor;
            this.ClaveComprobante = item.claveComprobante;
            this.descuento = 0;
            this.subTotal = item.gravado;
            this.medioDePago = item.tipoPago;
            this.total = item.total;
            this.grabado = item.gravado;
            this.exento = item.exento;
            this.iv = item.impuesto;
            this.listarDetallesNota(item.noFactura);
            this.ModalNotaCredito = 1;
        },
        OcultarComprobante(){
            this.comprobanteModal = 0;
        },
    CrearNotaCredito() 
    {
      if (this.validarPago() != 1)
      {
        return;
      }
          var localidadTicket = this.obtenerTicket();
        
    },
    obtenerTicket(){
                var self = this;
                var ticketsArray=[];
                var ticketNum = 0;
                let header={"Authorization" : "Bearer "};
                let configuracion= {headers : header};
                var localidadPrevia = "";
                var arrayTicket = [];
                    localidadPrevia = this.$store.state.usuario.localidad;
                
                axios.get('api/Facturas/SelectTickets', { params: {'localidad': localidadPrevia}},configuracion).then(function(response){
                    ticketsArray=response.data;
                    for(var i = 0; i < ticketsArray.length; ++i)
                    {
                       ticketNum = ticketsArray[i].noTicket;
                      break;
                    }  
                    self.auxVentasInsertar(ticketNum,localidadPrevia);
                }).catch(function(error){
                    console.log(error);
                });
        },
        validarPago(){
                this.valida=1;

                if (!this.idcliente && this.tipoFactura == 1){
                    alert("Seleccione un cliente para realizar Nota crédito electrónica");
                    this.valida=0;
                }
                if (!this.tipoCedulaReceptor){
                    alert("Seleccione un tipo de documento.");
                    this.valida=0;
				}
				if (!this.cedulaReceptor){
                    alert("Digite un numero de Cédula.");
                    this.valida=0;
				}
				if (!this.nombreReceptor){
                    alert("Digite un Nombre.");
                    this.valida=0;
                }
                if (this.EstadoNC == 1){
                    alert("Factura ya contiene nota de crédito.");
                    this.valida=0;
                }
                if (this.detalles.length == 0){
                    alert("Debe ingresar productos que facturar.");
                    this.valida=0;
				}
				if (this.justificacion == ''){
                    alert("Debe ingresar una justificación.");
                    this.valida=0;
                }
                return this.valida;
        },
        facturar() {
        if (this.validarPago() != 0)
        {
            this.vueltoPagar = "";
            this.detalleFactura = "";
            this.totalPagar = this.total;
            this.dialogPago = true;
            this.efectivoPagar = "";
            this.tarjetaPagar = "";
            this.obtenerCliente();
        }
        else
        {
            this.dialogPago = false;
            return;
        }
        },
        obtenerCliente(){
                var self = this;
                var clientesArray=[];
                var clientePrevio="";
                if (this.idcliente.text === undefined)
                {
                    clientePrevio = this.idcliente;
                }
                else
                {
                    clientePrevio = this.idcliente.text;
                }
                let header={"Authorization" : "Bearer "};
                let configuracion= {headers : header};
                axios.get('api/Empleados/ObtenerCliente', { params: {'clienteFactura': clientePrevio}},configuracion).then(function(response){
                    clientesArray=response.data;
                    for(var i = 0; i < clientesArray.length; ++i)
                    {
                      self.clienteNombre = clientesArray[i].nombre;
                      self.clienteCorreo = clientesArray[i].correo;
                      self.clienteCedula = clientesArray[i].cedula;
                      self.clienteTipoCedula = clientesArray[i].tipoCedula;
                      self.clienteTelefono = clientesArray[i].telefono;
                      break;
                    } 
                })
        },
        auxVentasInsertar(ticket, localidad){
          var self = this;
          var documentoHacienda = "";
          var formaPago = 0;
          var ticketObtenido = ticket +1;
          var localidadObtenida = localidad;
          var momentoActual = new Date(); 
          var hora = momentoActual.getHours(); 
          var minuto = momentoActual.getMinutes(); 
          var segundo = momentoActual.getSeconds();

          let header={"Authorization" : "Bearer "};
                let configuracion= {headers : header};
                axios.post('api/Facturas/CrearAux',{
                      'Fecha': this.today.toString(),//MM/dd/YYYY
                      'Detalle':"NOTA DE CREDITO",
                      'NoFactura':ticketObtenido,
                      'Gravado': parseFloat(this.grabado),
                      'Exento': parseFloat(this.excento),
                      'Iv': parseFloat(this.iv),
                      'Total': this.total,
                      'Localidad': this.$store.state.usuario.localidad,
                      'Cajero': this.$store.state.usuario.nombre,
                      'TipoPago': 'EFECTIVO',
                      'Descuento': this.descuento,
                      'ImpServicio': 0,
                      'MontoExpress': 0,
                      'SubTotal': this.subtotal,
                      'MontoExpres': 0,
                      'TipoDocumento': 'NOTA DE CREDITO',
                      'Nocomprobante': 0,
                      'NoNotaCredito': "",
                      'CondicionVenta': "CONTADO",
                      'ClaveComprobante': 0,
                      'Moneda': this.monedaPago.toUpperCase(),//USD O CRC
                      'RefFacturaReal': this.noFactura,
                      'RefComprobante': this.ClaveComprobante,
                      'razonReferencia': this.justificacion,
                      'MensajeRespuesta': "", //
                      'NombreReceptor': this.nombreReceptor,
                      'CedulaReceptor': this.cedulaReceptor,
                      'CorreoReceptor': this.correoReceptor,
                      'Razon': this.justificacion,
                      'RefFecha': this.fecha,
                      'Vuelto': 0,
                      'Impuesto1': 0,
                      'Impuesto4 ': 0,
                      'Impuesto13': this.iv,//Solo trabaja con 0 o 13%
                      'Impuesto2': 0,
                      'Sincronizado ': 0,
                      'Vendedor': this.$store.state.usuario.nombre,
                      'TipoCambio': 590,
                      'TotalSinR': this.total,
                      'Hora': hora + ":" + minuto + ":" + segundo,
                      'Proforma': 0,
                      
                },configuracion).then(function (response) {
                    self.datosFactura(ticketObtenido,localidadObtenida,formaPago,documentoHacienda.toUpperCase());
                  })
                  .catch(function (error) {
                })
        },
    datosFactura(ticketNum,localidadObtenida,formaPagoObtenido,documentoHaciendaObtenido){
        var momentoActual = new Date() 
        var hora = momentoActual.getHours() 
        var minuto = momentoActual.getMinutes() 
        var segundo = momentoActual.getSeconds() 
        var self = this;
        var documentoHacienda = 0;
        documentoHacienda = this.tipoFactura;
        
        for(var i = 0; i < this.detalles.length; ++i)
        {
          this.productosDatos.push(
            {
                CodigoProducto:this.detalles[i].codigoProducto,
                Cantidad:this.detalles[i].cantidad,
                UnidadMedida:"UND",
                Descripcion:this.detalles[i].descripcion,
                PrecioUnitario:this.detalles[i].precioUnitario,
                MontoDescuento:this.detalles[i].descuento,
                ValorImpuesto:this.detalles[i].valorImpuesto,
				Gravado:this.detalles[i].gravado,
				CodigoHacienda: this.detalles[i].codigoHacienda,
				EstadoImp : this.detalles[i].estadoImp,
            }
            );
          }
        let header={"Authorization" : "Bearer "};
        let configuracion= {headers : header};
        axios.post('api/Facturas/CrearDatosNota',{
                      'NoFacturaReal': ticketNum,
					  'TipoDocumento': 3,
					  'Hora': hora + ":" + minuto + ":" + segundo,
                      'Nocomprobante': 0,
                      'CondicionVenta':"CONTADO",
                      'Moneda': this.monedaPago.toUpperCase(),//USD O CRC
                      'MedioDePago': "EFECTIVO",
                      'CodigoProducto': "",
                      'Cantidad': 0,
                      'UnidadMedida': "UND",
                      'Descripcion': "",
                      'PrecioUnitario': 0,
                      'MontoDescuento': 0,
                      'DiasCredito': 15,
                      'Gravado': "",//S O N  S= GRAVADO O N= EXENTO
                      'Cajero': this.$store.state.usuario.nombre,
                      'ClaveComprobante':0,
                      'RefFacturaReal': this.noFactura,
                      'RefComprobante': this.ClaveComprobante,
                      'razonReferencia': this.justificacion,
                      'MensajeRespuesta': this.MensajeRespuesta,
                      'CodicionComprobante': "",
                      'EstadoComprobante': 0,
                      'FechaEnvio': "",
                      'FechaEstado': "",
                      'NombreReceptor': this.nombreReceptor,
                      'CedulaReceptor': this.cedulaReceptor,
                      'CorreoReceptor': this.correoReceptor,
                      'TipoCedulaReceptor': this.tipoCedulaReceptor.toUpperCase(), //FISICA, JURIDICA, EXTRANJERO
                      'TelefonoReceptor': this.telefonoReceptor,
                      'Localidad': this.$store.state.usuario.localidad,
                      'Razon': this.justificacion,
                      'RefFecha': this.fecha + " " + this.hora,
                      'Exonerado': false,
                      'Correo2': "",
                      'ValorImpuesto': 0, //0,1,2,4,13
                      'Detalle': this.detalle,
                      'Sincronizado ': 0,
                      'Vendedor':this.$store.state.usuario.nombre,
                      'EstadoNC': 0,
                      'EstadoCierre': 0,
                      'Producto': this.productosDatos,
                      'NumeroMM': "",
                  },configuracion).then(function (response) {
                    self.Mensaje = 'Nota de crédito realizada correctamente';
                    self.snackbar = true,
                    self.vueltoPagar = 0;
					self.insertarTicket(ticketNum,localidadObtenida);
                  })
                  .catch(function (error) {
                    alert("Error en nota de crédito, favor revisar sus información o solicite ayuda al 4070-1889");
                })
        },
        insertarTicket(ticketNum, localidad){
                this.dialogPago = false;
                this.productos = [];
                this.barbero = "";
                this.idcliente= "";
                this.totalPagar = "";
                this.vueltoPagar = "";
                this.limpiar();
                let header={"Authorization" : "Bearer "};
                let configuracion= {headers : header};
                this.loading  = true;
                let me=this;
                axios.post('api/Facturas/CrearTicket',{
                    'NoTicket':ticketNum,
                    'Operador':localidad,
                    'Localidad':localidad,
                },configuracion).then(function(response){ 
                  me.dialogFactura = false;  
                  me.link = location.origin+'/'+'ticket/'+ticketNum;
					if(me.modoPDV == 1){
						location.assign(me.link); 
						return;
					} else
					if(me.modoPDV == 2){
						link = location.origin+'/'+'repventas/';
						location.reload();
						return;
				  }             
                }).catch(function(error){
                    console.log(error);
                });
    },
     limpiar(){
                this.MensajeRespuesta="";
                this.ClaveComprobante="";
                this.localidad="";
                this.detalles=[];
                this.name = '',
                this.details = '',
                this.start = '',
                this.end = '',
                this.color = '',
                this.hora = '',
                this.hora2 = '',
                this.codigo = ''
    },  
        crearPDF(){
           var columns = [
            {title: "Factura", dataKey: "noFacturaReal"},
            {title: "Estado", dataKey: "estado"}, 
            {title: "Fecha", dataKey: "fecha"}, 
            {title: "Excento", dataKey: "excento"},
            {title: "IVA", dataKey: "iva"},
            {title: "Total", dataKey: "ventaTotal"},
            {title: "Cliente", dataKey: "cliente"},
            ];
            var rows = [];

            this.usuarios.map(function(x){
                rows.push({noFacturaReal:x.noFacturaReal,excento:x.exento,estado:x.mensajeRespuesta,fecha:x.fecha,iva:x.impuesto,ventaTotal:x.ventaTotal,cliente:x.nombreReceptor});
            });

            // Only pt supported (not mm or in)
            var doc = new jsPDF('p', 'pt');
            doc.autoTable(columns, rows, {
                margin: {top: 60},
                addPageContent: function(data) {
                    doc.text("Listado de Ventas", 40, 30);
                }
            });
            doc.save('ReporteVentas.pdf');
        },
        crearPDFFactura(){
            var fact = this.noFactura;
             var quotes = document.getElementById('factura');
                html2canvas(quotes).then(function (canvas) {
                    var imgData = canvas.toDataURL('https://i.ibb.co/7rj9fLf/the-one-blanco.jpg');
                    var imgWidth = 210;
                    var pageHeight = 285;
                    var imgHeight = canvas.height * imgWidth / canvas.width;
                    var heightLeft = imgHeight;
                    var doc = new jsPDF();
                    var position = 0;

                    doc.addImage(imgData, 'JPG', 0, position, imgWidth, imgHeight);                    
                    doc.save('ComprobanteVenta'+fact+'.pdf');
                });
        },

        getColor (mensajeRespuesta) {
        if (mensajeRespuesta == 'aceptado') return '#8BC34A'
        if(mensajeRespuesta == 'Sin procesar') return 'orange'
        if(mensajeRespuesta == 'procesando') return '#42A5F5'
        if(mensajeRespuesta == 'rechazado') return '#FF1744'
        else  return '#42A5F5'
      },
	  validarCliente(){
			if(localStorage.getItem("printer1") == null || localStorage.getItem("printer2") == null) {
				this.nombreReceptor = "";
				this.cedulaReceptor = "";
				return;
			}
			this.nombreReceptor = localStorage.getItem("Cliente");
			this.cedulaReceptor = localStorage.getItem("Cedula");
		},
       listar(){
		   if(this.cedulaReceptor == ""){
			   this.Mensaje = "Ingrese un número de cédula";
			   this.snackbar = true;
			   return;
		   }
            let me=this;
			this.loading = true;
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
            axios.get('api/RepVentasVistas/ListarHaciendaCliente/'+this.cedulaReceptor.trim(),configuracion).then(function(response){
                //console.log(response);
				me.usuarios=response.data;
				me.localidad = me.usuarios[0].localidad;
				me.loading = false;
				me.nombreReceptor = me.usuarios[0].nombreReceptor;
				me.listarLocalidad();
				me.getProductosMasVendidos();
				localStorage.setItem("Cliente",me.nombreReceptor);
				localStorage.setItem("Cedula",me.cedulaReceptor);
            }).catch(function(error){
                console.log(error);
            });
        },
        listarFechas(){
            if(this.start == '' || this.end == ''|| this.cedulaReceptor == '' ){
                this.snackbar = true;
                this.Mensaje = 'Debe seleccionar una fecha inicial y final y cliente'
                return;
		   }
		   	this.loading = true;
            let me=this;
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
            axios.get('api/RepVentasVistas/ConsultaFechasHaciendaCliente/'+this.start+'/'+this.end+'/'+this.cedulaReceptor,configuracion).then(function(response){
                //console.log(response);
				me.usuarios=response.data;
				me.loading = false;
            }).catch(function(error){
                console.log(error);
            });
        },

      close () {
        this.dialog = false;
        this.limpiar();
          },
    

      activarDesactivarMostrar(accion,item){
                this.adModal=1;
                this.adNombre=item.nombre;
                this.adId=item.idusuario;                
                if (accion==1){
                    this.adAccion=1;
                }
                else if (accion==2){
                    this.adAccion=2;
                }
                else{
                    this.adModal=0;
                }
            },
            activarDesactivarCerrar(){
                this.adModal=0;
            },
      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.desserts[this.editedIndex], this.editedItem)
        } else {
          this.desserts.push(this.editedItem)
        }
        this.close()
      },
    },
  }

</script>