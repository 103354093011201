<template>
    <div>
        <v-dialog v-model="comprobanteModal" max-width="1000px">
            <v-card class="pa-3">
                
                <v-card-text>
                    <v-btn class="mx-2" color="indigo" dark="" @click="crearPDFFactura()"><v-icon>mdi-file-pdf</v-icon> PDF</v-btn>
                    <v-btn class="mx-2" color="grey" dark="" onclick="window.print()"> <v-icon>mdi-printer</v-icon> A1</v-btn>
                    <v-btn class="mx-2" color="grey" dark="" :to="nofactura2"> <v-icon>mdi-printer</v-icon> POS </v-btn>
                    <v-btn class="mx-2" color="grey" dark="" @click="ReenvioFactura()" > <v-icon>mdi-cloud-download-outline</v-icon>XML Resp</v-btn>
                    <v-btn class="mx-2" color="grey" dark="" @click="ReenvioFactura()" > <v-icon>mdi-cloud-download-outline</v-icon> XML FC </v-btn>
                    <v-btn class="mx-2" color="success" dark=" " @click="ReenvioFactura()" > <v-icon>mdi-email</v-icon><v-icon x-small="">mdi-send</v-icon> </v-btn>
                    <v-text-field label="Correo de reenvio" v-model="correo2"> </v-text-field> 
                    <div id="factura">
                        <header>
                            <div id="logo">
                                <img width="120" height="120" :src="Logo2">
                            </div>
                            <div v-for="det in localidadencabezado" :key="det.nombre" id="datos">
                                <p id="encabezado">
                                    <b class="info--text text-h4 font-weight-bold" > {{det.nombre}} </b> <b class="text-h6 primary--text"> <br>{{det.razonSocial}}, </b><br> {{det.dirreccion}}, Costa Rica<br> 
                                    <v-icon> mdi-card-bulleted </v-icon>: {{det.cedulaJuridica}} <br><v-icon>mdi-phone </v-icon> {{det.telefono}}<br>
                                    <v-icon>mdi-email </v-icon>{{det.correo}}
                                    
                                </p>
                            </div>
                            <div style="text-align:right;" class="font-weight-regular  body-2" id="fact">
                                <p>{{tipoDocumento}}<br>
                               NoFactura: {{noFactura}}<br>
                               
                               {{fecha}}--{{hora}} <br>
                               Vendedor: {{vendedor}}</p>
                                
                            </div>
                        </header>
                        <br>
                        <section>
                            <div>
                                <tbody>
                                    <tr>
                                        <td id="cliente">
                                            <strong class="primary--text text-h6"> Datos del cliente: </strong> <br>
                                            <strong><v-icon>mdi-account </v-icon></strong> {{nombreReceptor}}<br>
                                            <strong><v-icon>mdi-card-bulleted </v-icon></strong> {{cedulaReceptor}} <br>
                                            <strong><v-icon>mdi-email </v-icon></strong> {{correoReceptor}} <br>
                                        </td>
                                    </tr>
                                </tbody>
                            </div>
                        </section>
                        <br>
                        <section>
                            <div>
                                <v-simple-table id="facarticulo" >
                                    <thead>
                                        <tr id="fa" class="primary white--text">
                                            <th class="white--text text-center">Código</th>
                                            <th class="white--text text-center">Cant</th>
                                            <th class="white--text text-center">Descripción</th>
                                            <th class="white--text text-center">Precio IVI</th>
                                            <th class="white--text text-center">Grabado</th>
                                        </tr>
                                    </thead>
                                    <tbody> 
                                        <tr v-for="det in detalles" :key="det.noFacturaReal" >
                                            
                                            <td class="pa-3" style="text-align:center;"> {{det.codigoProducto}} </td>
                                            <td style="text-align:center;"> {{det.cantidad}} </td>
                                            <td style="text-align:center;">{{det.descripcion}}</td>
                                            <td style="text-align:center;">{{det.precioUnitario}}</td>
                                            <td style="text-align:center;">{{det.gravado}}</td> 
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr >
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th class="secondary rounded-l-xl" style="text-align:right;">SUBTOTAL</th>
                                            <th class="secondary" style="text-align:right;"> {{monedaPago}} {{subTotal}}</th>
                                        </tr>
                                        <tr>
                                            <th></th>
                                            <th class="text--grey"> Firma:</th>
                                            <th class="text--grey">________________________</th>
                                            <th class="secondary rounded-l-xl" style="text-align:right;">IVA</th>
                                            <th class="secondary" style="text-align:right;"> {{monedaPago}} {{iv}}</th>
                                        </tr>
                                        <tr>
                                            <th></th>
                                            <th class="text--grey">Cédula:</th>
                                            <th class="text--grey">________________________ </th>
                                            <th class="primary white--text rounded-l-xl" style="text-align:right;">TOTAL</th>
                                            <th class="primary white--text " style="text-align:right;"> {{monedaPago}} {{total}}</th>
                                        </tr>
                                    </tfoot>
                                    
                                </v-simple-table>
                            </div>
                        </section>
                        <br>
                        <br>
                        <footer>
                            <div>
                                <qrcode-vue :value="value" :size="size" level="H"></qrcode-vue>
                                Escanéame
                            </div>
                            <div id="gracias">
                                <p><b>Gracias por su compra!</b><br> Detalle:  {{comentario}}  <br> Facturas Disponibles en {{value}}
                                <br> Clave Comprobante: {{ClaveComprobante}} <br>
                                Autorizado mediante Resolucion DGT-R-033-2019 DEL 6-20-2019</p>
                            </div>
                        </footer>
                    </div>
                    <v-btn @click="OcultarComprobante()" color="error" flat><v-icon> mdi-exit-to-app </v-icon></v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-data-table
            :headers="headers"
            :items="usuarios"
            :search="search"
            sort-by="noFactura"
            sort-desc="noFactura"
            class="elevation-1 pa-1"

            >
            <template v-slot:item.mensajeRespuesta="{ item }">
            <v-chip :color="getColor(item.mensajeRespuesta)" dark>{{ item.mensajeRespuesta }}</v-chip>
            </template>
            <template v-slot:top>
                <v-row class="pa-2">
                    <v-col cols="12" sm="3">
                        <v-card-actions class="var">
                                <vue-excel-xlsx
                                    :data="usuarios"
                                    :columns="columns"
                                    :filename="'Reporte de Proveedores'+$store.state.usuario.localidad"
                                    :sheetname="'Proveeores'"
                                    >
                                    <v-btn fab="" color="success">  <v-icon large="">mdi-file-excel </v-icon> </v-btn>
                                </vue-excel-xlsx> 
                            <v-card-title>Compras Proveedores</v-card-title>
                        </v-card-actions>
                    </v-col>
                    <v-col cols="12" sm="4">    
                        <v-text-field v-model="search"  append-icon="mdi-magnify"
                         label="Buscar" single-line hide-details
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2">
                        <v-text-field  v-model="start" type="date" label="Fecha inicial emisión"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2">
                        <v-text-field  v-model="end"  type="date" label="Fecha final emisión"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="1">
                        <v-btn  color="info" @click="listar()" fab > <v-icon fab> mdi-cloud-search </v-icon> </v-btn>
                    </v-col>
                </v-row>
               
            </template>
            <template v-slot:item.action="{ item }">
            <v-icon
                disabled
                class="mr-2"
                color="primary" 
                @click="MostrarComprobante(item)"
            >
                mdi-file-document
            </v-icon>
            <template >
            <v-icon
                small
                color="error"
                @click="EliminarCompra(item)"
            > 
                mdi-delete
            </v-icon>
            </template>
            
            </template>

        </v-data-table>
        <template class="pa-3">
            <v-snackbar
                    v-model="snackbar" top vertical="" :timeout="5000"
                    >
                    {{ Mensaje }}
                    <v-btn
                    color="info"
                    text
                    @click="snackbar = false"
                    >
                    Cerrar
                    </v-btn>
            </v-snackbar>
            
        </template>
    </div>
</template>

<script>
import axios from 'axios'
import jsPDF from 'jspdf'
import VueExcelXlsx from "vue-excel-xlsx";
import Vue from "vue";
import html2canvas from 'html2canvas';

Vue.use(VueExcelXlsx);
  export default {
    data: () => ({
    dialog: false,
    search: '',
	usuariosModificados: [], // Datos modificados para el Excel
	usuarios:[],
    today: new Date().toISOString().substr(0, 10),
    focus: new Date().toISOString().substr(0, 10),
    headers: [
    { text: 'Proveedor', value: 'proveedor' },
    { text: 'Total', value: 'total' },      
    { text: 'Localidad', value: 'localidad',  },          
    ],
    columns : [
		{ label: 'Proveedor', field: 'proveedor' },
		{ label: 'Total', field: 'total' },
		{ label: 'Localidad', field: 'localidad' },
	],
    start: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
    end : '',
    descuento: 0,
    
    }),

    computed: {
    },
    watch: {
		dialog (val) {
			val || this.close()
		},
    },

    created () {
		this.ObtenerDia();
      	this.listar();
    },

    methods: {
        crearPDF(){
           var columns = [
            {title: "Factura", dataKey: "noFacturaReal"},
            {title: "Estado", dataKey: "estado"}, 
            {title: "Fecha", dataKey: "fecha"}, 
            {title: "Excento", dataKey: "excento"},
            {title: "IVA", dataKey: "iva"},
            {title: "Total", dataKey: "ventaTotal"},
            {title: "Cliente", dataKey: "cliente"},
            ];
            var rows = [];

            this.usuarios.map(function(x){
                rows.push({noFacturaReal:x.noFacturaReal,excento:x.exento,estado:x.mensajeRespuesta,fecha:x.fecha,iva:x.impuesto,ventaTotal:x.ventaTotal,cliente:x.nombreReceptor});
            });

            // Only pt supported (not mm or in)
            var doc = new jsPDF('p', 'pt');
            doc.autoTable(columns, rows, {
                margin: {top: 60},
                addPageContent: function(data) {
                    doc.text("Listado de Ventas", 40, 30);
                }
            });
            doc.save('ReporteVentas.pdf');
        },
		ObtenerDia(){
			const hoy = new Date();
			const anio = hoy.getFullYear();
			const mes = ('0' + (hoy.getMonth() + 1)).slice(-2); // Los meses comienzan en 0
			const dia = ('0' + hoy.getDate()).slice(-2);
			this.end = `${anio}-${mes}-${dia}`;
		},
       	listar(){
			let me = this;
			let header = {"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion = {headers : header};
			axios.get(`api/FacturasCompras/SumaTotalPorProveedorConLocalidad/${this.start}/${this.end}/${this.$store.state.usuario.nombre}/${this.$store.state.usuario.localidad}`, configuracion)
				.then(function(response){
					// Procesa la respuesta aquí
					me.usuarios = response.data;
				})
				.catch(function(error){
					console.log(error);
				});
		},

    },
  }

</script>