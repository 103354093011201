<template>
    <div>
        <v-card flat>
            <v-app-bar
                    :clipped-left="$vuetify.breakpoint.lgAndUp"
                    app
                    height="50"
                    color="white"
                    hide-on-scroll
                    dark
                    
                    >
                <v-tabs
                    background-color="white"
                    class="mt-2 elevation-1"
                    slider-color="primary" fixed-tabs
                    >
                    <v-btn class="grey--text" text rounded=""  @click="$vuetify.goTo('#Pizzas', options)"> Pizzas </v-btn>
                    <v-btn class="grey--text" text rounded  @click="$vuetify.goTo(800, options)"> Acompañamientos </v-btn>
                    <v-btn class="grey--text" text rounded  @click="$vuetify.goTo(7000, options)"> Promociones </v-btn>
                    <v-btn class="grey--text" text rounded  @click="$vuetify.goTo(300, options)"> Bebidas </v-btn>
                    <v-btn @click="$vuetify.goTo(target, options)" class="grey--text" text rounded > Adicionales </v-btn>
                </v-tabs>
            </v-app-bar>
        </v-card>
        <v-card class="mx-auto" max-width="1500" target="Combos" >
            <v-container fluid>
                <v-subheader v-scroll="Pizzas">Pizzas</v-subheader>
                    <v-row dense>
                    
                    <v-card
                            class="mx-auto"
                            max-width="150" xs6 sm6 md6
                        >
                            <v-img
                            :src="require('../assets/Logo2019.png')"
                            height="150px"
                            ></v-img>

                            <v-card-title class="primary--text">
                            Pizza Suprema
                            </v-card-title>

                            <v-subheader class="mt-1">
                            Jamòn, Hongos, cebolla , Chile dulce y queso
                            </v-subheader>

                            <v-card-actions>
                                <v-div align="left" justify="center">
                                    <v-select
                                    :items="tamañoslujo"
                                    label="Tamaños"
                                    
                                    class="text-center "
                                    ></v-select>
                                </v-div>
                            <v-btn dense text @click="show = !show" color="success">
                                <v-icon>mdi-plus</v-icon> 
                            </v-btn>
                            </v-card-actions>

                            <v-expand-transition focusable>
                            <div v-show="show">
                                <v-divider></v-divider>

                                <v-container fluid>
                                <p> Seleccione una bebida <v-subheader>
                                *Obligatorio*
                                </v-subheader></p>
                                <v-radio-group v-model="radios" :mandatory="false">
                                <v-radio label="Coca Cola" value="Coca Cola"></v-radio>
                                <v-radio label="Fanta Kolita" value="Fanta Kolita"></v-radio>
                                <v-radio label="Fanta Naranja" value="Fanta Naranja"></v-radio>
                                <v-radio label="Sprite" value="Sprite"></v-radio>
                                <v-radio label="Te Frio" value="Te Frio"></v-radio>
                                <v-radio label="Te blanco" value="Te blanco"></v-radio>
                                </v-radio-group>
                                <p> Bebida Seleccionada:  {{ radios || 'null' }}</p>
                                <v-divider :inset="inset"></v-divider>
                                    <v-spacer></v-spacer>
                                <p> Seleccione Acompañamiento</p>
                                <v-radio-group v-model="AcompComb1" :mandatory="false">
                                <v-radio label="Papas Francesas" value="Papas Francesas"></v-radio>
                                <v-radio label="Aros de Cebolla +CRC:500" value="Aros Cebolla"></v-radio>
                                <v-radio label="Mayonesa adicional +CRC:500" value="Mayonesa Extra"></v-radio>
                                <v-radio label="Agrandar bebida +CRC:600" value="Agrandar Bebida"></v-radio>
                                </v-radio-group>
                                <p> Acompañamiento Seleccionado:  {{ AcompComb1 || 'null' }}</p>
                                <v-btn
                                    color="success"
                                    @click="show = !show"
                                    >
                                    Agregar
                                </v-btn>
                            </v-container>
                            </div>
                        </v-expand-transition>
                    </v-card>
                    <v-card
                            class="mx-auto"
                            max-width="150" xs6 sm6 md6
                        >
                            <v-img
                            src="https://scontent-mia3-2.xx.fbcdn.net/v/t1.0-9/84493438_2713612985381677_7841732051878281216_o.jpg?_nc_cat=102&_nc_sid=85a577&_nc_ohc=p1qejNNYeQAAX_46OaQ&_nc_ht=scontent-mia3-2.xx&oh=7f708c9fb715cead3aaa7e139a81843c&oe=5EADF83A"
                            height="150px"
                            ></v-img>

                            <v-card-title class="mt-4">
                            Pizza Lujo
                            </v-card-title>

                            <v-subheader class="mt-6">
                            Especies, Jamón, Carne, Hongos, Cebolla, Chile dulce, tocineta, queso, pepperony  salami
                            </v-subheader>

                            <v-card-actions class="mt-4 mx-3">
                               
                            </v-card-actions>

                            <v-expand-transition>
                                <div v-show="show">
                                    <v-divider></v-divider>

                                    <v-container fluid>
                                    <p> Seleccione una bebida <v-subheader>
                                    *Obligatorio*
                                    </v-subheader></p>
                                    <v-radio-group v-model="radios" :mandatory="false">
                                    <v-radio label="Coca Cola" value="Coca Cola"></v-radio>
                                    <v-radio label="Fanta Kolita" value="Fanta Kolita"></v-radio>
                                    <v-radio label="Fanta Naranja" value="Fanta Naranja"></v-radio>
                                    <v-radio label="Sprite" value="Sprite"></v-radio>
                                    <v-radio label="Te Frio" value="Te Frio"></v-radio>
                                    <v-radio label="Te blanco" value="Te blanco"></v-radio>
                                    </v-radio-group>
                                    <p> Bebida Seleccionada:  {{ radios || 'null' }}</p>
                                    <v-divider :inset="inset"></v-divider>
                                        <v-spacer></v-spacer>
                                    <p> Seleccione Acompañamiento</p>
                                    <v-radio-group v-model="AcompComb1" :mandatory="false">
                                    <v-radio label="Papas Francesas" value="Papas Francesas"></v-radio>
                                    <v-radio label="Aros de Cebolla +CRC:500" value="Aros Cebolla"></v-radio>
                                    <v-radio label="Mayonesa adicional +CRC:500" value="Mayonesa Extra"></v-radio>
                                    <v-radio label="Agrandar bebida +CRC:600" value="Agrandar Bebida"></v-radio>
                                    </v-radio-group>
                                    <p> Acompañamiento Seleccionado:  {{ AcompComb1 || 'null' }}</p>
                                    <v-btn
                                        color="success"
                                        @click="show = !show"
                                        >
                                        Agregar
                                    </v-btn>
                                </v-container>
                                </div>
                            </v-expand-transition>
                    </v-card>
                    <v-card
                            class="mx-auto"
                            max-width="150"
                        >
                            <v-img
                            src="https://scontent-mia3-1.xx.fbcdn.net/v/t1.0-9/50218003_2343690505650064_8149391391198281728_o.jpg?_nc_cat=100&_nc_sid=8024bb&_nc_ohc=DNyZbYxdKuAAX9lyREv&_nc_ht=scontent-mia3-1.xx&oh=c8bbd2d3bc8bf0e0fcb5b63c8f31a41f&oe=5E8DEA96"
                            height="150px"
                            ></v-img>

                            <v-card-title>
                            Combo 3
                            </v-card-title>

                            <v-subheader>
                            3 Piezas de pollo + 1 bananito + banano con chile
                            </v-subheader>

                            <v-card-actions>
                            <v-btn text @click="show = !show" color="success">Elegir
                                <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon> 
                            </v-btn>
                            </v-card-actions>

                            <v-expand-transition>
                            <div v-show="show">
                                <v-divider></v-divider>

                                <v-container fluid>
                                <p> Seleccione una bebida <v-subheader>
                                *Obligatorio*
                                </v-subheader></p>
                                <v-radio-group v-model="radios" :mandatory="false">
                                <v-radio label="Coca Cola" value="Coca Cola"></v-radio>
                                <v-radio label="Fanta Kolita" value="Fanta Kolita"></v-radio>
                                <v-radio label="Fanta Naranja" value="Fanta Naranja"></v-radio>
                                <v-radio label="Sprite" value="Sprite"></v-radio>
                                <v-radio label="Te Frio" value="Te Frio"></v-radio>
                                <v-radio label="Te blanco" value="Te blanco"></v-radio>
                                </v-radio-group>
                                <p> Bebida Seleccionada:  {{ radios || 'null' }}</p>
                                <v-divider :inset="inset"></v-divider>
                                    <v-spacer></v-spacer>
                                <p> Seleccione Acompañamiento</p>
                                <v-radio-group v-model="AcompComb1" :mandatory="false">
                                <v-radio label="Papas Francesas" value="Papas Francesas"></v-radio>
                                <v-radio label="Aros de Cebolla +CRC:500" value="Aros Cebolla"></v-radio>
                                <v-radio label="Mayonesa adicional +CRC:500" value="Mayonesa Extra"></v-radio>
                                <v-radio label="Agrandar bebida +CRC:600" value="Agrandar Bebida"></v-radio>
                                </v-radio-group>
                                <p> Acompañamiento Seleccionado:  {{ AcompComb1 || 'null' }}</p>
                                <v-btn
                                    color="success"
                                    @click="show = !show"
                                    >
                                    Agregar
                                </v-btn>
                            </v-container>
                            </div>
                        </v-expand-transition>
                    </v-card>
                    <v-card
                            class="mx-auto"
                            max-width="150"
                        >
                            <v-img
                            src="https://scontent-mia3-2.xx.fbcdn.net/v/t1.0-9/45776540_2242848355734280_92317895370997760_o.jpg?_nc_cat=110&_nc_sid=8024bb&_nc_ohc=wCYLDRtxS_wAX9Pv2Z8&_nc_ht=scontent-mia3-2.xx&oh=a052ca520da7c400c29ed96f2433481a&oe=5E8FDAE0"
                            height="150px"
                            ></v-img>

                            <v-card-title>
                            Combo 4
                            </v-card-title>

                            <v-subheader>
                            4 Alitas de pollo + 1 papitas + Refresco Peq
                            </v-subheader>

                            <v-card-actions>
                            <v-btn text @click="show = !show" color="success">Elegir
                                <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon> 
                            </v-btn>
                            </v-card-actions>

                            <v-expand-transition>
                            <div v-show="show">
                                <v-divider></v-divider>

                                <v-container fluid>
                                <p> Seleccione una bebida <v-subheader>
                                *Obligatorio*
                                </v-subheader></p>
                                <v-radio-group v-model="radios" :mandatory="false">
                                <v-radio label="Coca Cola" value="Coca Cola"></v-radio>
                                <v-radio label="Fanta Kolita" value="Fanta Kolita"></v-radio>
                                <v-radio label="Fanta Naranja" value="Fanta Naranja"></v-radio>
                                <v-radio label="Sprite" value="Sprite"></v-radio>
                                <v-radio label="Te Frio" value="Te Frio"></v-radio>
                                <v-radio label="Te blanco" value="Te blanco"></v-radio>
                                </v-radio-group>
                                <p> Bebida Seleccionada:  {{ radios || 'null' }}</p>
                                <v-divider :inset="inset"></v-divider>
                                    <v-spacer></v-spacer>
                                <p> Seleccione Acompañamiento</p>
                                <v-radio-group v-model="AcompComb1" :mandatory="false">
                                <v-radio label="Papas Francesas" value="Papas Francesas"></v-radio>
                                <v-radio label="Aros de Cebolla +CRC:500" value="Aros Cebolla"></v-radio>
                                <v-radio label="Mayonesa adicional +CRC:500" value="Mayonesa Extra"></v-radio>
                                <v-radio label="Agrandar bebida +CRC:600" value="Agrandar Bebida"></v-radio>
                                </v-radio-group>
                                <p> Acompañamiento Seleccionado:  {{ AcompComb1 || 'null' }}</p>
                                <v-btn
                                    color="success"
                                    @click="show = !show"
                                    >
                                    Agregar
                                </v-btn>
                            </v-container>
                            </div>
                        </v-expand-transition>
                    </v-card>
                    <v-card
                            class="mx-auto"
                            max-width="150"
                        >
                            <v-img
                            src="https://scontent-mia3-1.xx.fbcdn.net/v/t1.0-9/46075077_2245602008792248_200231482713702400_n.jpg?_nc_cat=106&_nc_sid=110474&_nc_ohc=Zc-qSaevybsAX_1mFsX&_nc_ht=scontent-mia3-1.xx&oh=b9a0730ed6877bd9a6b822c626ff9178&oe=5E8E3D75"
                            height="150px"
                            ></v-img>

                            <v-card-title>
                            Combo 5
                            </v-card-title>

                            <v-card-subtitle>
                            PolloBurguesa + papitas + refresco peq
                            </v-card-subtitle>

                            <v-card-actions>
                            <v-btn text @click="show = !show" color="success">Elegir
                                <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon> 
                            </v-btn>
                            </v-card-actions>

                            <v-expand-transition>
                            <div v-show="show">
                                <v-divider></v-divider>

                                <v-container fluid>
                                <p> Seleccione una bebida <v-subheader>
                                *Obligatorio*
                                </v-subheader></p>
                                <v-radio-group v-model="radios" :mandatory="false">
                                <v-radio label="Coca Cola" value="Coca Cola"></v-radio>
                                <v-radio label="Fanta Kolita" value="Fanta Kolita"></v-radio>
                                <v-radio label="Fanta Naranja" value="Fanta Naranja"></v-radio>
                                <v-radio label="Sprite" value="Sprite"></v-radio>
                                <v-radio label="Te Frio" value="Te Frio"></v-radio>
                                <v-radio label="Te blanco" value="Te blanco"></v-radio>
                                </v-radio-group>
                                <p> Bebida Seleccionada:  {{ radios || 'null' }}</p>
                                <v-divider :inset="inset"></v-divider>
                                    <v-spacer></v-spacer>
                                <p> Seleccione Acompañamiento</p>
                                <v-radio-group v-model="AcompComb1" :mandatory="false">
                                <v-radio label="Papas Francesas" value="Papas Francesas"></v-radio>
                                <v-radio label="Aros de Cebolla +CRC:500" value="Aros Cebolla"></v-radio>
                                <v-radio label="Mayonesa adicional +CRC:500" value="Mayonesa Extra"></v-radio>
                                <v-radio label="Agrandar bebida +CRC:600" value="Agrandar Bebida"></v-radio>
                                </v-radio-group>
                                <p> Acompañamiento Seleccionado:  {{ AcompComb1 || 'null' }}</p>
                                <v-btn
                                    color="success"
                                    @click="show = !show"
                                    >
                                    Agregar
                                </v-btn>
                            </v-container>
                            </div>
                        </v-expand-transition>
                    </v-card>
                    <v-card
                            class="mx-auto"
                            max-width="150"
                        >
                            <v-img
                            src="https://scontent-mia3-1.xx.fbcdn.net/v/t1.0-9/46075077_2245602008792248_200231482713702400_n.jpg?_nc_cat=106&_nc_sid=110474&_nc_ohc=Zc-qSaevybsAX_1mFsX&_nc_ht=scontent-mia3-1.xx&oh=b9a0730ed6877bd9a6b822c626ff9178&oe=5E8E3D75"
                            height="150px"
                            ></v-img>

                            <v-card-title>
                            Combo 6
                            </v-card-title>

                            <v-card-subtitle>
                            Chalupa + papitas + refresco peq
                            </v-card-subtitle>

                            <v-card-actions>
                            <v-btn text @click="show = !show" color="success">Elegir
                                <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon> 
                            </v-btn>
                            </v-card-actions>

                            <v-expand-transition>
                            <div v-show="show">
                                <v-divider></v-divider>

                                <v-container fluid>
                                <p> Seleccione una bebida <v-subheader>
                                *Obligatorio*
                                </v-subheader></p>
                                <v-radio-group v-model="radios" :mandatory="false">
                                <v-radio label="Coca Cola" value="Coca Cola"></v-radio>
                                <v-radio label="Fanta Kolita" value="Fanta Kolita"></v-radio>
                                <v-radio label="Fanta Naranja" value="Fanta Naranja"></v-radio>
                                <v-radio label="Sprite" value="Sprite"></v-radio>
                                <v-radio label="Te Frio" value="Te Frio"></v-radio>
                                <v-radio label="Te blanco" value="Te blanco"></v-radio>
                                </v-radio-group>
                                <p> Bebida Seleccionada:  {{ radios || 'null' }}</p>
                                <v-divider :inset="inset"></v-divider>
                                    <v-spacer></v-spacer>
                                <p> Seleccione Acompañamiento</p>
                                <v-radio-group v-model="AcompComb1" :mandatory="false">
                                <v-radio label="Papas Francesas" value="Papas Francesas"></v-radio>
                                <v-radio label="Aros de Cebolla +CRC:500" value="Aros Cebolla"></v-radio>
                                <v-radio label="Mayonesa adicional +CRC:500" value="Mayonesa Extra"></v-radio>
                                <v-radio label="Agrandar bebida +CRC:600" value="Agrandar Bebida"></v-radio>
                                </v-radio-group>
                                <p> Acompañamiento Seleccionado:  {{ AcompComb1 || 'null' }}</p>
                                <v-btn
                                    color="success"
                                    @click="show = !show"
                                    >
                                    Agregar
                                </v-btn>
                            </v-container>
                            </div>
                        </v-expand-transition>
                    </v-card>
                    
            </v-row>
                <v-spacer></v-spacer>
                <v-divider :inset="inset"></v-divider>
                <v-subheader>Acompañamientos</v-subheader>
                <v-spacer></v-spacer>
            <v-row dense>
                    
                    <v-card
                            class="mx-auto"
                            max-width="150" xs6 sm6 md6
                        >
                            <v-img
                            src="https://scontent-mia3-1.xx.fbcdn.net/v/t1.0-9/46075077_2245602008792248_200231482713702400_n.jpg?_nc_cat=106&_nc_sid=110474&_nc_ohc=Zc-qSaevybsAX_1mFsX&_nc_ht=scontent-mia3-1.xx&oh=b9a0730ed6877bd9a6b822c626ff9178&oe=5E8E3D75"
                            height="150px"
                            ></v-img>

                            <v-card-title>
                            Combo 1
                            </v-card-title>

                            <v-subheader>
                            2 piezas de pollo + papitas + refresco peq
                            </v-subheader>

                            <v-card-actions>
                            <v-btn text @click="show = !show" color="success">Elegir
                                <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon> 
                            </v-btn>
                            </v-card-actions>

                            <v-expand-transition>
                            <div v-show="show">
                                <v-divider></v-divider>

                                <v-container fluid>
                                <p> Seleccione una bebida <v-subheader>
                                *Obligatorio*
                                </v-subheader></p>
                                <v-radio-group v-model="radios" :mandatory="false">
                                <v-radio label="Coca Cola" value="Coca Cola"></v-radio>
                                <v-radio label="Fanta Kolita" value="Fanta Kolita"></v-radio>
                                <v-radio label="Fanta Naranja" value="Fanta Naranja"></v-radio>
                                <v-radio label="Sprite" value="Sprite"></v-radio>
                                <v-radio label="Te Frio" value="Te Frio"></v-radio>
                                <v-radio label="Te blanco" value="Te blanco"></v-radio>
                                </v-radio-group>
                                <p> Bebida Seleccionada:  {{ radios || 'null' }}</p>
                                <v-divider :inset="inset"></v-divider>
                                    <v-spacer></v-spacer>
                                <p> Seleccione Acompañamiento</p>
                                <v-radio-group v-model="AcompComb1" :mandatory="false">
                                <v-radio label="Papas Francesas" value="Papas Francesas"></v-radio>
                                <v-radio label="Aros de Cebolla +CRC:500" value="Aros Cebolla"></v-radio>
                                <v-radio label="Mayonesa adicional +CRC:500" value="Mayonesa Extra"></v-radio>
                                <v-radio label="Agrandar bebida +CRC:600" value="Agrandar Bebida"></v-radio>
                                </v-radio-group>
                                <p> Acompañamiento Seleccionado:  {{ AcompComb1 || 'null' }}</p>
                                <v-btn
                                    color="success"
                                    @click="show = !show"
                                    >
                                    Agregar
                                </v-btn>
                            </v-container>
                            </div>
                        </v-expand-transition>
                    </v-card>
                    <v-card
                            class="mx-auto"
                            max-width="150"
                        >
                            <v-img
                            src="https://scontent-mia3-1.xx.fbcdn.net/v/t1.0-9/46075077_2245602008792248_200231482713702400_n.jpg?_nc_cat=106&_nc_sid=110474&_nc_ohc=Zc-qSaevybsAX_1mFsX&_nc_ht=scontent-mia3-1.xx&oh=b9a0730ed6877bd9a6b822c626ff9178&oe=5E8E3D75"
                            height="150px"
                            ></v-img>

                            <v-card-title>
                            Combo 1
                            </v-card-title>

                            <v-card-subtitle>
                            2 piezas de pollo + papitas + refresco peq
                            </v-card-subtitle>

                            <v-card-actions>
                            <v-btn text @click="show = !show" color="success">Elegir
                                <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon> 
                            </v-btn>
                            </v-card-actions>

                            <v-expand-transition>
                            <div v-show="show">
                                <v-divider></v-divider>

                                <v-container fluid>
                                <p> Seleccione una bebida <v-subheader>
                                *Obligatorio*
                                </v-subheader></p>
                                <v-radio-group v-model="radios" :mandatory="false">
                                <v-radio label="Coca Cola" value="Coca Cola"></v-radio>
                                <v-radio label="Fanta Kolita" value="Fanta Kolita"></v-radio>
                                <v-radio label="Fanta Naranja" value="Fanta Naranja"></v-radio>
                                <v-radio label="Sprite" value="Sprite"></v-radio>
                                <v-radio label="Te Frio" value="Te Frio"></v-radio>
                                <v-radio label="Te blanco" value="Te blanco"></v-radio>
                                </v-radio-group>
                                <p> Bebida Seleccionada:  {{ radios || 'null' }}</p>
                                <v-divider :inset="inset"></v-divider>
                                    <v-spacer></v-spacer>
                                <p> Seleccione Acompañamiento</p>
                                <v-radio-group v-model="AcompComb1" :mandatory="false">
                                <v-radio label="Papas Francesas" value="Papas Francesas"></v-radio>
                                <v-radio label="Aros de Cebolla +CRC:500" value="Aros Cebolla"></v-radio>
                                <v-radio label="Mayonesa adicional +CRC:500" value="Mayonesa Extra"></v-radio>
                                <v-radio label="Agrandar bebida +CRC:600" value="Agrandar Bebida"></v-radio>
                                </v-radio-group>
                                <p> Acompañamiento Seleccionado:  {{ AcompComb1 || 'null' }}</p>
                                <v-btn
                                    color="success"
                                    @click="show = !show"
                                    >
                                    Agregar
                                </v-btn>
                            </v-container>
                            </div>
                        </v-expand-transition>
                    </v-card>
                    <v-card
                            class="mx-auto"
                            max-width="150"
                        >
                            <v-img
                            src="https://scontent-mia3-1.xx.fbcdn.net/v/t1.0-9/50218003_2343690505650064_8149391391198281728_o.jpg?_nc_cat=100&_nc_sid=8024bb&_nc_ohc=DNyZbYxdKuAAX9lyREv&_nc_ht=scontent-mia3-1.xx&oh=c8bbd2d3bc8bf0e0fcb5b63c8f31a41f&oe=5E8DEA96"
                            height="150px"
                            ></v-img>

                            <v-card-title>
                            Combo 2
                            </v-card-title>

                            <v-card-subtitle>
                            3 Piezas de pollo + 1 bananito + banano con chile
                            </v-card-subtitle>

                            <v-card-actions>
                            <v-btn text>Share</v-btn>

                            <v-btn
                                color="purple"
                                text
                            >
                                Explore
                            </v-btn>

                            <v-spacer></v-spacer>

                            <v-btn
                                icon
                                @click="show = !show"
                            >
                                <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                            </v-btn>
                            </v-card-actions>

                            <v-expand-transition>
                            <div v-show="show">
                                <v-divider></v-divider>

                                <v-card-text>
                                I'm a thing. But, like most politicians, he promised more than he could deliver. You won't have time for sleeping, soldier, not with all the bed making you'll be doing. Then we'll go with that data file! Hey, you add a one and two zeros to that or we walk! You're going to do his laundry? I've got to find a way to escape.
                                </v-card-text>
                            </div>
                        </v-expand-transition>
                    </v-card>
                    <v-card
                            class="mx-auto"
                            max-width="150"
                        >
                            <v-img
                            src="https://scontent-mia3-1.xx.fbcdn.net/v/t1.0-9/50218003_2343690505650064_8149391391198281728_o.jpg?_nc_cat=100&_nc_sid=8024bb&_nc_ohc=DNyZbYxdKuAAX9lyREv&_nc_ht=scontent-mia3-1.xx&oh=c8bbd2d3bc8bf0e0fcb5b63c8f31a41f&oe=5E8DEA96"
                            height="150px"
                            ></v-img>

                            <v-card-title>
                            Combo 3
                            </v-card-title>

                            <v-card-subtitle>
                            3 Piezas de pollo + 1 bananito + banano con chile
                            </v-card-subtitle>

                            <v-card-actions>
                            <v-btn text>Share</v-btn>

                            <v-btn
                                color="purple"
                                text
                            >
                                Explore
                            </v-btn>

                            <v-spacer></v-spacer>

                            <v-btn
                                icon
                                @click="show = !show"
                            >
                                <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                            </v-btn>
                            </v-card-actions>

                            <v-expand-transition>
                            <div v-show="show">
                                <v-divider></v-divider>

                                <v-card-text>
                                I'm a thing. But, like most politicians, he promised more than he could deliver. You won't have time for sleeping, soldier, not with all the bed making you'll be doing. Then we'll go with that data file! Hey, you add a one and two zeros to that or we walk! You're going to do his laundry? I've got to find a way to escape.
                                </v-card-text>
                            </div>
                        </v-expand-transition>
                    </v-card>
                    <v-card
                            class="mx-auto"
                            max-width="155"
                        >
                            <v-img
                            src="https://scontent-mia3-2.xx.fbcdn.net/v/t1.0-9/45776540_2242848355734280_92317895370997760_o.jpg?_nc_cat=110&_nc_sid=8024bb&_nc_ohc=wCYLDRtxS_wAX9Pv2Z8&_nc_ht=scontent-mia3-2.xx&oh=a052ca520da7c400c29ed96f2433481a&oe=5E8FDAE0"
                            height="150px"
                            ></v-img>

                            <v-card-title>
                            Combo 4
                            </v-card-title>

                            <v-card-subtitle>
                            4 Alitas de pollo + 1 papitas + Refresco Peq
                            </v-card-subtitle>

                            <v-card-actions>
                            <v-btn text>Share</v-btn>

                            <v-btn
                                color="purple"
                                text
                            >
                                Explore
                            </v-btn>

                            <v-spacer></v-spacer>

                            <v-btn
                                icon
                                @click="show = !show"
                            >
                                <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                            </v-btn>
                            </v-card-actions>

                            <v-expand-transition>
                            <div v-show="show">
                                <v-divider></v-divider>

                                <v-card-text>
                                I'm a thing. But, like most politicians, he promised more than he could deliver. You won't have time for sleeping, soldier, not with all the bed making you'll be doing. Then we'll go with that data file! Hey, you add a one and two zeros to that or we walk! You're going to do his laundry? I've got to find a way to escape.
                                </v-card-text>
                            </div>
                        </v-expand-transition>
                    </v-card>
                    <v-card
                            class="mx-auto"
                            max-width="155"
                        >
                            <v-img
                            src="https://scontent-mia3-1.xx.fbcdn.net/v/t1.0-9/46075077_2245602008792248_200231482713702400_n.jpg?_nc_cat=106&_nc_sid=110474&_nc_ohc=Zc-qSaevybsAX_1mFsX&_nc_ht=scontent-mia3-1.xx&oh=b9a0730ed6877bd9a6b822c626ff9178&oe=5E8E3D75"
                            height="150px"
                            ></v-img>

                            <v-card-title>
                            Combo 5
                            </v-card-title>

                            <v-card-subtitle>
                            PolloBurguesa + papitas + refresco peq
                            </v-card-subtitle>

                            <v-card-actions>
                            <v-btn text>Elegir</v-btn>
                            <v-btn
                                icon
                                @click="show = !show"
                            >
                                <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                            </v-btn>
                            </v-card-actions>

                            <v-expand-transition>
                            <div v-show="show">
                                <v-divider></v-divider>

                                <v-card-text>
                                I'm a thing. But, like most politicians, he promised more than he could deliver. You won't have time for sleeping, soldier, not with all the bed making you'll be doing. Then we'll go with that data file! Hey, you add a one and two zeros to that or we walk! You're going to do his laundry? I've got to find a way to escape.
                                </v-card-text>
                            </div>
                        </v-expand-transition>
                    </v-card>
                    
            </v-row>
        </v-container>
        </v-card>
      
    </div>
</template>
<script>
  export default {
    data () {
        
      return {
        type: 'number',
        number: 9999,
        selector: '#scroll-with-options',
        selected: 'Button',
        elements: ['Button', 'Radio group'],
        duration: 300,
        offset: 0,
        easing: 'easeInOutCubic',
        easings: Object.keys(easings),  
        radios: 'Seleccione',
        tamañoslujo: ['Personal ₡2000', 'MegaPersonal ₡3700', 'Pequeña ₡5900', 'Mediana ₡7000','Grande ₡8400','ExtraGrande ₡9700'],
        AcompComb1: 'Seleccione',
        e1: 1,
        show: false,
        show1: false,
        steps: 2,
        vertical: false,
        altLabels: false,
        editable: true,
        easigns: '',
        select: { state: 'Florida', abbr: 'FL' },
        items: [
          { state: 'Florida', abbr: 'FL' },
          { state: 'Georgia', abbr: 'GA' },
          { state: 'Nebraska', abbr: 'NE' },
          { state: 'California', abbr: 'CA' },
          { state: 'New York', abbr: 'NY' },
        ],
        time: 0,
        forecast: [
          { day: 'Tuesday', icon: 'mdi-white-balance-sunny', temp: '24\xB0/12\xB0' },
          { day: 'Wednesday', icon: 'mdi-white-balance-sunny', temp: '22\xB0/14\xB0' },
          { day: 'Thursday', icon: 'mdi-cloud', temp: '25\xB0/15\xB0' },
        ],
         cards: [
        { title: 'Pollos Jeffry San Roque', src: 'https://scontent-mia3-2.xx.fbcdn.net/v/t31.0-8/14524370_1268983986454060_2405387055525917152_o.jpg?_nc_cat=102&_nc_sid=2d5d41&_nc_ohc=csH0uQ7BrjgAX-4iC4m&_nc_ht=scontent-mia3-2.xx&oh=d9ec9d8fd5d12518412a7f6987fab88d&oe=5E9334C7', flex: 3 },
        { title: 'Pollos Jeffry Muelle', src: 'https://scontent-mia3-2.xx.fbcdn.net/v/t1.0-9/53632219_2420115251340922_4855147918933360640_o.jpg?_nc_cat=102&_nc_sid=8024bb&_nc_ohc=4fhPRS43Sx8AX8v-Ucg&_nc_ht=scontent-mia3-2.xx&oh=8f523628a6d91efe718a79eca6123790&oe=5E911734' , flex: 3 },
        { title: 'Pollos Jeffry Centro', src: 'https://scontent-mia3-1.xx.fbcdn.net/v/t31.0-8/10960423_928018047217324_8476191523007752443_o.jpg?_nc_cat=101&_nc_sid=8024bb&_nc_ohc=HiyNE478aDwAX8WhCKo&_nc_ht=scontent-mia3-1.xx&oh=fc8b60aca693fb2944255d177159e465&oe=5E8EAD42', flex: 3 },
        { title: 'Pollos Jeffry Florencia', src: 'https://scontent-mia3-2.xx.fbcdn.net/v/t31.0-8/13474945_1186886347997158_447505354635765881_o.jpg?_nc_cat=102&_nc_sid=2d5d41&_nc_ohc=gdSm2ywbiNoAX9F882n&_nc_ht=scontent-mia3-2.xx&oh=cdaf53bee40890c73f1e149f8b7172f9&oe=5E95675B', flex: 3 },
        
      ],
      }
    },
    
    options () {
        return {
          duration: this.duration,
          offset: this.offset,
          easing: this.easing,
        }
      },
    
      element () {
        if (this.selected === 'Button') return this.$refs.button
        else if (this.selected === 'Radio group') return this.$refs.radio
      },

    methods: {
      onInput (val) {
        this.steps = parseInt(val)
      },
     
    },
  }
</script>