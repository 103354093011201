<template>
    <div>
        <v-data-table 
            :headers="headers"
            :items="usuarios"
            :search="search"
			:loading="loading"
            class="elevation-1 pa-1 oculto-impresion"
            >
            <template class="oculto-impresion" v-slot:item.tipo="{ item }">
            <v-chip :color="getColor(item.tipo)" dark>{{ item.tipo }}</v-chip>
            </template>
			<template v-slot:item.estado="{ item }">
				{{ mapStatus(item.estado) }}
			</template>
            <template class="oculto-impresion" v-slot:top>
                <v-row class="pa-2">
                    <v-col cols="12" lg="3" md="5" sm="8" xs="12">
                        <v-row class="var mx-5">
                            <v-card-title>Movimiento Productos</v-card-title>
							<v-btn color="error" class="mx-1" @click="crearPDF()"> PDF </v-btn>
                            
                                <vue-excel-xlsx
                                    :data="usuarios"
                                    :columns="columns"
                                    :filename="'Reporte de Movimientos '+$store.state.usuario.localidad"
                                    :sheetname="'Movimientos'"
                                    >
                                    <v-btn color="green">  <v-icon large="">mdi-file-excel </v-icon> </v-btn>
                                </vue-excel-xlsx> 
                        </v-row>
                    </v-col>
                    <v-col cols="12" lg="2" md="7" sm="4" xs="12">    
                        <v-text-field v-model="search"  append-icon="mdi-magnify" filled hide-details=""
                         label="Buscar" 
                        />
						<v-select filled hide-details="" :items="Estados" @change="listar()" clearable v-model="Estado" label="Filtro por Estado"/>
                    </v-col>
                    <v-col cols="12" lg="2" md="3" sm="3" xs="6">
						<v-select filled hide-details="" :items="Vendedores" @change="listar()" clearable v-model="Usuario" label="Filtro por Usuario"/>
						<v-text-field filled hide-details=""  @keyup.enter="listar()" clearable v-model="codigoProducto" type="number" label="Filtro por código Producto"/>
                    </v-col>
					<v-col cols="12" lg="2" md="5" sm="4" xs="6">
                        <v-select hide-details filled @change="listar()" :items="Localidades" v-model="Localidad" label="Localidad" /> 
						<v-text-field hide-details clearable @keyup.enter="listar()" v-model="idMovimiento" filled type="number" label="Busqueda por ID"></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="2" md="3" sm="3" xs="5">
						<v-text-field filled hide-details="" v-model="start" type="date" label="Fecha inicial"/>
                        <v-text-field filled hide-details="" v-model="end"  type="date" label="Fecha final"/>
                    </v-col>
                    <v-col cols="12" lg="1" md="1" sm="1" xs="1">
                        <v-btn :loading="loading" x-large color="info" @click="listarFechas()" > <v-icon fab> mdi-cloud-search </v-icon> </v-btn>
                    </v-col>
                </v-row>
               
            </template>
            <template class="oculto-impresion" v-slot:item.action="{ item }">
            <v-icon
                
                class="mr-2"
                color="primary" 
                @click="MostrarComprobante(item)"
            >
                mdi-file-document
            </v-icon>
            <template >
            <v-icon
                small
                color="error"
                @click="NotaCredito(item)"
            > 
                mdi-delete
            </v-icon>
            </template>
            
            </template>
			<template class="oculto-impresion" v-slot:item.actionnoFactura="{ item }">
				<div v-if="!ShowNoFact"> {{item.noFactura}} </div>
				<div v-if="ShowNoFact"> {{item.claveComprobante.substring(37, 41)}} </div>
            </template>

            <template v-slot:no-data>
                <v-btn color="primary" @click="listar">Reiniciar</v-btn>
            </template>
        </v-data-table>
        <template class="pa-3">
            <v-snackbar
                    v-model="snackbar" top vertical="" :timeout="5000"
                    >
                    {{ Mensaje }}
                    <v-btn
                    color="info"
                    text
                    @click="snackbar = false"
                    >
                    Cerrar
                    </v-btn>
            </v-snackbar>
        </template>
    </div>
</template>

<script scoped>
import axios from 'axios'
import Chart from 'chart.js'
import jsPDF from 'jspdf'
import VueExcelXlsx from "vue-excel-xlsx";
import Vue from "vue";
import QrcodeVue from 'qrcode.vue'

Vue.use(VueExcelXlsx);
  export default {
    data: () => ({
    mesesValores: null,
    nombreMeses: [],
    totalMeses: [],
    value: 'https://www.noah.cr',
	size: 100,
	usuarios: [],
	codigoProducto:null,
	Usuario:null,
	idMovimiento:null,
	end: null,
	Localidad: '',
	DetalleMovimiento: null,
	UsuarioMovimiento: null,
	Estados:[{text:"Aprobado",value:0},{text:"Pendientes",value:1},{text:"Denegados",value:-1}],
	start: null,
	Estado: null,
	loading: true,
	Fecha:null,
    dialog: false,
	localidadencabezado:[],
	Vendedores:[],
	Logo3: null,
	snackbar : false,
	Mensaje:'',
    search: '',
    ClienteSeleccionado : '',
    today: new Date().toISOString().substr(0, 10),
    focus: new Date().toISOString().substr(0, 10),
    headers: [
		{ text: 'Opciones', value: 'action', sortable: false },
		{ text: 'Id', value: 'idsalida' },
		{ text: 'Movimiento', value: 'tipo' },
		{ text: 'Estado', value: 'estado' },
		{ text: 'Código', value: 'codigo' },
		{ text: 'Descripcion', value: 'descripcion' },
		{ text: 'Stock Anterior', value: 'inventarioAnterior' },
		{ text: 'Cantidad Ingresada', value: 'cantidad' },
		{ text: 'Balance', value: 'balance' },
		{ text: 'Costo', value: 'costo' },
		{ text: 'Fecha', value: 'fecha',  },
		{ text: 'Hora', value: 'hora',  },
		{ text: 'Usuario', value: 'cajero' },
		{ text: 'Detalle', value: 'detalle' },
		{ text: 'Id', value: 'noFactura' },
    ],
    columns : [
			{ label: 'Movimiento', field: 'tipo' },
			{ label: 'Código', field: 'codigo' },
			{ label: 'Descripcion', field: 'descripcion' },
			{ label: 'Cantidad', field: 'cantidad' },
			{ label: 'Costo', field: 'costo' },
			{ label: 'Fecha', field: 'fecha'},
			{ label: 'Hora', field: 'hora'},
			{ label: 'Usuario', field: 'cajero', sortable: false  },
			{ label: 'Detalle', field: 'detalle', sortable: false  },
        ],
    Localidades:[],
    PagosFiltrados: "",
    tipoCedulaReceptor: '',
	search: '',
    
    }),
    

    watch: {
      dialog (val) {
        val || this.close()
      },
      
    },

    created () {
	  this.listarAsociadas();
      this.listar();
	  this.listarLocalidad();
	  this.selectVendedores();
    },

    methods: {
        selectVendedores(){
            let me=this;
            let header={"Authorization" : "Bearer " };
            let configuracion= {headers : header};
            var proveedoresArray=[];
            axios.get('api/Usuarios/SelectUsuariosadmin/'+me.$store.state.usuario.localidad,configuracion).then(function(response){
                proveedoresArray=response.data;
                proveedoresArray.map(function(x){
                    me.Vendedores.push(x.nombre);
                });
            }).catch(function(error){
                console.log(error);
            });
        },
		async listarAsociadas(){
			var items = [];
			let me=this;
			me.Localidades.push(me.$store.state.usuario.localidad);
			me.Localidad = me.$store.state.usuario.localidad;
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			await axios.get('api/LocalidadesAsociadas/'+this.$store.state.usuario.nombre,configuracion).then(function(response){
				items=response.data;
				items.map(function(x){
					me.Localidades.push(x.localdiadAsociada);
				});
			}).catch(function(error){
				alert("Producto no encontrado");
				me.modelCarga=false;
			});
    	},
        crearPDF(){
           var columns = [
            { title: 'Id', dataKey: 'idsalida' },
			{ title: 'Movimiento', dataKey: 'tipo' },
			{ title: 'Código', dataKey: 'codigo' },
			{ title: 'Descripcion', dataKey: 'descripcion' },
			{ title: 'Cantidad', dataKey: 'cantidad' },
			{ title: 'Fecha', dataKey: 'fecha', sortable: false  },
			{ title: 'Detalle', dataKey: 'detalle', sortable: false  },
            ];
            var rows = [];

            this.usuarios.map(function(x){
                rows.push({idsalida:x.idsalida,tipo:x.tipo,codigo:x.codigo,fecha:x.fecha,descripcion:x.descripcion,cantidad:x.cantidad,costo:x.costo,cajero:x.cajero,});
            });

            // Only pt supported (not mm or in)
            var doc = new jsPDF('p', 'pt');
            doc.autoTable(columns, rows, {
                margin: {top: 60},
                addPageContent: function(data) {
                    doc.text("Listado de Movimientos", 40, 30);
                }
            });
            doc.save('Reporte Movimiento Productos de '+this.$store.state.usuario.localidad+'.pdf');
        },
		mapStatus(status) {
			switch (status) {
				case 1:
					return 'Pendiente';
				case 0:
					return 'Aprobado';
				case -1:
					return 'Denegado';
				default:
					return 'Estado Desconocido'; // Para cualquier otro caso
			}
		},
		getColor (tipo) {
			if (tipo == 'INGRESO') return '#8BC34A'
			if(tipo == 'Sin procesar') return 'orange'
			if(tipo == 'AJUSTE') return '#42A5F5'
			if(tipo == 'SALIDA') return '#FF1744'
			else  return '#42A5F5'
		},
       	async listar(){
			this.loading = true;
			this.start = null;
			this.end = null;
			let me = this;
			let header = { "Authorization" : "Bearer " + this.$store.state.token };
			let configuracion = { 
				headers : header,
				params: {
					NoFactura: this.idMovimiento, 
					Cajero: this.Usuario,    
					Codigo: this.codigoProducto,        
					Estado: this.Estado        
				}
			};
			await axios.get('api/SalidaProductos/ListarMovimientos?Localidad=' + this.Localidad, configuracion)
			.then(function(response){
				me.usuarios = response.data;
				me.loading = false;
			})
			.catch(function(error){
				console.log(error);
			});
		},

		listarLocalidad(){
			this.loading = true;
			let me=this;
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
            axios.get('api/SalidaProductos/ListarMovimientos/'+this.Localidad,configuracion).then(function(response){
                //console.log(response);
				me.usuarios=response.data;
				me.loading = false;
            }).catch(function(error){
                console.log(error);
            });
		},
        listarFechas(){
			if(this.start == null || this.end == null){
				alert("Debe digitar fechas inicio y final para continuar");
				this.snackbar = true;
				this.Mensaje = 'Debe seleccionar una fecha inicial y final'
				return;
			}
			this.Usuario = null;
			this.codigoProducto = null
			this.idMovimiento = null;
			this.Estado = null;
			this.loading = true;
			let me=this;
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			axios.get('api/SalidaProductos/ConsultaFechas/'+this.start+'/'+this.end+'/'+this.Localidad,configuracion).then(function(response){
				//console.log(response);
				me.usuarios=response.data;
				me.loading = false;
			}).catch(function(error){
				console.log(error);
			});
        },
		async listarLocalidad(){
			let me =this;
			let logo = null;
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
				await axios.get('api/Localidades/ListarFiltrada/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
					//console.log(response);
				me.localidadencabezado=response.data;
				me.Logo3 = me.localidadencabezado[0].imagen;
				
				
				}).catch(function(error){
					console.log(error);
				});
		},
		async MostrarComprobante(item){
			this.idMovimiento = item.noFactura;
			this.Fecha = item.fecha +" "+item.hora;
			this.DetalleMovimiento = item.detalle;
			this.UsuarioMovimiento = item.cajero;
			this.loading = true;
			this.start = null;
			this.end = null;
			let me = this;
			let header = { "Authorization" : "Bearer " + this.$store.state.token };
			let configuracion = { 
				headers : header,
				params: {
					NoFactura: this.idMovimiento, // Reemplaza esto con el valor real de NoFactura
					Cajero: this.Usuario,       // Reemplaza esto con el valor real de Cajero
					Codigo: this.codigoProducto        // Reemplaza esto con el valor real de Codigo
				}
			};
			await axios.get('api/SalidaProductos/ListarMovimientos?Localidad=' + this.Localidad, configuracion)
			.then(function(response){
				me.usuarios = response.data;
				me.loading = false;
				me.PrintDetails2();
			})
			.catch(function(error){
				console.log(error);
			});
			
		},

		PrintDetails2(){
			var products = "";
				for(var i = 0; i < this.usuarios.length; ++i)
					{
						products += `<tr>
									<td class="col-3">`+this.usuarios[i].codigo+`</td>
									<td class="col-6 text-1">`+this.usuarios[i].descripcion+`</td>
									<td class="col-3 text-center">`+this.usuarios[i].costo+`</td>
									<td class="col-1 text-center">`+this.usuarios[i].inventarioAnterior+`</td>
									<td class="col-1 text-center">`+this.usuarios[i].cantidad+`</td>
									</tr>`
					}
				var win = `
							<html lang="en">
							<head>
							<meta charset="utf-8" />
							<meta http-equiv="X-UA-Compatible" content="IE=edge">
							<meta name="viewport" content="width=device-width, initial-scale=1">
							<link href="images/favicon.png" rel="icon" />
							<title>Reporte de Movimiento Productos - Noah Systems</title>
							<meta name="author" content="harnishdesign.net">

							<!-- Web Fonts
							======================= -->
							<link rel='stylesheet' href='https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900' type='text/css'>

							<!-- Stylesheet
							======================= -->
							<link rel="stylesheet" type="text/css" href="https://www.noah.cr/FacturaCSS/vendor/bootstrap/css/bootstrap.min.css"/>
							<link rel="stylesheet" type="text/css" href="https://www.noah.cr/FacturaCSS/vendor/font-awesome/css/all.min.css"/>
							<link rel="stylesheet" type="text/css" href="https://www.noah.cr/FacturaCSS/css/stylesheet.css"/>
							</head>
							<body>
							<!-- Container -->
							<div class="container-fluid invoice-container">
							<!-- Header -->
							<header>
							<div class="row align-items-center">
								<div class="col-sm-7 text-center text-sm-start mb-3 mb-sm-0">
								<img id="logo" style="max-width:100px" src="`+this.Logo3+`" title="Logotipo" alt="LogoTipo" />
								</div>
								<div class="col-sm-5 text-center text-sm-end">
								<h4 class="text-7 mb-0">Traslado de Mercadería</h4>
								</div>
							</div>
							<hr>
							</header>
							
							<!-- Main Content -->
							<main>
							<div class="row">
								<div class="col-sm-6"><strong>Fecha:</strong> `+this.Fecha+`</div>
								<div class="col-sm-6 text-sm-end"> <strong>Número de trazlado :</strong> `+this.idMovimiento+`</div>
								
							</div>
							<hr>
							<div class="row">
								<div class="col-sm-6 text-sm-end order-sm-1"> <strong>Info de transacción:</strong>
								<address>
								Usuario: `+this.UsuarioMovimiento+`<br />
								Movimiento: `+this.DetalleMovimiento+`<br />
								`+this.localidadencabezado[0].telefono+`
								</address>
								</div>
								<div class="col-sm-6 order-sm-0"> <strong>Datos del Negocio:</strong>
								<address>
								`+this.localidadencabezado[0].nombre+`<br />
								`+this.localidadencabezado[0].razonSocial+`<br />
								`+this.localidadencabezado[0].cedulaJuridica+`<br />
								`+this.localidadencabezado[0].telefono+`
								</address>
								</div>
							</div>
								
							<div class="card">
								<div class="card-body p-0">
								<div class="table-responsive">
									<table class="table mb-0">
									<thead class="card-header">
									<tr>
										<td class="col-3"><strong>Código I</strong></td>
										<td class="col-6"><strong>Nombre</strong></td>
										<td class="col-3 text-center"><strong>Precio Venta</strong></td>
										<td class="col-1 text-center"><strong>Stock Anterior</strong></td>
										<td class="col-1 text-center"><strong>Cantidad Trasladada</strong></td>
									</tr>
									</thead>
									<tbody>
										`+products+`
									</tbody>
									<tfoot class="card-footer">
										<tr>
											<td colspan="4" class="text-end"><strong>Mensajero:</strong></td>
											<td class="text-end">________</td>
										</tr>
										<tr>
											<td colspan="4" class="text-end"><strong>Entregado por: </strong></td>
											<td class="text-end">________</td>
										</tr>
										<tr>
											<td colspan="4" class="text-end border-bottom-0"><strong>Recibido por:</strong></td>
											<td class="text-end border-bottom-0">________</td>
										</tr>
									</tfoot>
									</table>
								</div>
								</div>
							</div>
							</main>
							<!-- Footer -->
							<footer class="text-center mt-4">
							<p class="text-1"><strong>NOTE :</strong> `+this.DetalleMovimiento+`.</p>
							<div class="btn-group btn-group-sm d-print-none">  <a href="#" onclick="window.print();" class="btn btn-light border text-black-50 shadow-none"><i class="fa fa-print"></i> Print</a>  <a href="" class="btn btn-light border text-black-50 shadow-none"><i class="fa fa-download"></i> Volver</a> </div>
							</footer>
							</div>
							</body>
						</html>
					`;
					document.documentElement.innerHTML = win;
					setTimeout(() => {
						win.print();
					}, 2000);
					//win.window.close();
			},
		close () {
			this.dialog = false;
			this.limpiar();
		},
    

      	activarDesactivarMostrar(accion,item){
                this.adModal=1;
                this.adNombre=item.nombre;
                this.adId=item.idusuario;                
                if (accion==1){
                    this.adAccion=1;
                }
                else if (accion==2){
                    this.adAccion=2;
                }
                else{
                    this.adModal=0;
                }
            },
            activarDesactivarCerrar(){
                this.adModal=0;
		},
		save () {
			if (this.editedIndex > -1) {
			Object.assign(this.desserts[this.editedIndex], this.editedItem)
			} else {
			this.desserts.push(this.editedItem)
			}
			this.close()
		},
    },
	}

</script>
<style scoped>
    @media print {
    .oculto-impresion,
    .oculto-impresion * {
        display: none !important;
    }
    } 
</style>
My CRUD