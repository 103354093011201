<template>
  <v-container fluid>	
		<div class="text-center">
			<v-overlay :value="modelCarga">
			<v-progress-circular
				indeterminate
				size="64"
			></v-progress-circular>
			</v-overlay>
		</div>
		<v-snackbar
                v-model="snackbar" top vertical="" :timeout="4000"
                >
                {{ Mensaje }}
                <v-btn
                color="pink"
                text
                @click="snackbar = false"
                >
                Close
                </v-btn>
        </v-snackbar>
		
          <v-row>
			<v-toolbar  dark color="primary" class="font-weight-light body-1">
				<v-list-item-content >
					<v-list-item-title>Recetas</v-list-item-title>
					<v-list-item-subtitle>Arma la descarga automatica de insumos al vender un producto</v-list-item-subtitle>
				</v-list-item-content>
       		 </v-toolbar>
            <v-col cols="12" sm="12">
            <v-form ref="form" v-model="validFactura" @submit.prevent="facturar">
            <v-row>
              	<v-col cols="12" sm="6">
					<v-combobox v-model="ProductoCompuesto" @change="BuscarReceta()" :items="productos" label="Producto a trabajar"/>
					
					<v-card
						:loading="modelCarga"
						class=""
						:disabled="ProductoCompuesto == ''"
						max-width="100%"
					>
						<template slot="progress">
						<v-progress-linear
							color="deep-purple"
							height="10"
							indeterminate
						></v-progress-linear>
						</template>
						<v-card-title>Item: {{NombreProducto}}</v-card-title>
						<v-card-subtitle> • </v-card-subtitle>

						<v-text-field dense onfocus="this.select();" @keyup.enter="SetPrecioVenta()" class="ma-4 mt-4" outlined append-icon="mdi-sale" v-model="Utilidad" label="Digite el porcentaje de utilidad deseado" > </v-text-field>
						<v-text-field dense onfocus="this.select();" @keyup.enter="ActualizarCostos()" class="mx-4 mt-4" outlined append-icon="mdi-point-of-sale" v-model="PrecioVenta" label="Precio Venta "> </v-text-field>
						<v-divider class="mx-4"></v-divider>

						<p style="font-size: 20px">Costo de Receta: {{calcularCosto}}</p>

							<div style="color: grey; font-size: 15px;"> Cantidad de insumos <v-chip dark color="green">{{detalles.length}}</v-chip></div>
							

						<v-card-actions>
						<v-btn
							color="primary"
							
							@click="ActualizarCostos()"
						>
							Actualizar Datos de Receta
						</v-btn>
						</v-card-actions>
					</v-card>
              	</v-col>
				<v-col cols="12" sm="6">
                      <v-flex xs12 sm12 md12 lg12 xl12>
						<v-combobox :disabled="ProductoCompuesto == ''" clearable @change="buscarProductoDirecto()" @keyup.enter="buscarProductoDirecto()" :items="compuestos" v-model="codigo" label="Busqueda de insumo por nombre o código"></v-combobox>
                
                          <v-data-table
                              :headers="cabeceraDetalles"
                              single-select
                              :items="detalles"
                              class="elevation-1"
                              v-model="selected"
                              @click:row="rowClick">
                              <template slot="no-data">
                                  <h3>No hay artículos agregados al detalle.</h3>
                              </template> 
                              <template v-slot:item.actionmovimiento="{ item }">
                                <v-icon
                                  large
                                  color="error"
                                  @click="BorrarAsociado(item)"
                                >
                                  mdi-delete
                                </v-icon>
							</template>
							<template v-slot:item.precio="props">
								<v-edit-dialog
								:return-value.sync="props.item.precio"
								@save="UpdateReceta(props.item)"
								@cancel="cancel"
								@open="open"
								@close="close"
								>
								{{ props.item.precio }} <br>
								<p style="white-space: pre-line; font-size: 12px;">{{props.item.detalle}} </p>
								<template v-slot:input>
									<v-text-field
									onfocus="this.select();"
									v-model="props.item.precio"
									
									:rules="[max25chars]"
									:label="'Precio Compra: '"
									></v-text-field>
								</template>
								</v-edit-dialog>
							</template>
							<template v-slot:item.cantidad="props">
								<v-edit-dialog
								:return-value.sync="props.item.cantidad"
								@save="UpdateReceta(props.item)"
								@cancel="cancel"
								@open="open"
								@close="close"
								>
								{{ props.item.cantidad }} <br>
								<p style="white-space: pre-line; font-size: 12px;">{{props.item.detalle}} </p>
								<template v-slot:input>
									<v-text-field
									onfocus="this.select();"
									v-model="props.item.cantidad"
									
									:rules="[max25chars]"
									:label="'Cantidad: '"
									></v-text-field>
								</template>
								</v-edit-dialog>
							</template>
                          </v-data-table>
                      </v-flex>
                </v-col>
              </v-row>
              </v-form>
              </v-col>
          </v-row>
  </v-container>
</template>


<script>
  
  import axios from 'axios';
  export default {
    name: 'Recetas',
    data () {
      return {
        ProductoCompuesto : '',
        productos:[],
        modelCarga: false,
		Unidades: [{text:"Gramos", value:1},{text:"Kilogramos (1000 gms)", value:1000},{text:"Mililitro (1)", value:1}
		,{text:"Litro (1000 Ml)", value:1000},{text:"Galon (3786 ml)", value:3786},{text:"Taza (240 ml)", value:240}
		,{text:"Cucharada (15 ml)", value:15},{text:"Cucharadita (6 ml)", value:6},{text:"Unidad (1)", value:1}],
		unidad: "",
		cantidad:0,
		PrecioCostoPrevio: 0,
		localidad: '',
		Utilidad: 30,
		snackbar : false,
		Mensaje :"",
		PrecioVenta: 0,
		PrecioVentaFinal:0,
		codigo:"",
		detalles:[],
		Costo: 0,
		NombreProducto: "",
		compuestos: [],
		cabeceraDetalles: [
		{ text: 'Descripción', value: 'descripcion', sortable: false  },
		{ text: 'Precio Costo', value: 'precio', sortable: false  },  
		{ text: 'Cantidad', value: 'cantidad', sortable: false  },  
		{ text: 'Unidad Medida', value: 'unidadMedida', sortable: false  },
		{ text: 'Eliminar', value: 'actionmovimiento', sortable: false },
		],
      }
    },
	computed:{
		calcularCosto:function(){
			let resultado = 0;
				for(var i=0;i<this.detalles.length;i++){
					resultado=resultado+(this.detalles[i].precio * this.detalles[i].cantidad);
				}
			return resultado;
    	},
		calcularVenta:function(){
			let Ganancia = this.calcularCosto * (this.Utilidad / 100) ;
			let PrecioVenta = this.calcularCosto + Ganancia;
			let IvaPrecioVenta = PrecioVenta * 0.13;
			let resultado = (PrecioVenta + IvaPrecioVenta);
			return resultado;
    	},
	},
	created () {
		this.selectCompuestos();
		this.selectProductos();
	},
	
    methods: {
		SetPrecioVenta(){
			this.PrecioVenta = this.calcularVenta;
		},
		selectProductos(){
			let me=this;
			let header={"Authorization" : "Bearer " };
			let configuracion= {headers : header};
			var productosArray=[];
			axios.get('api/Productos/SelectInsumos/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
				productosArray=response.data;
				productosArray.map(function(x){
					me.compuestos.push({text:x.descripcion, value:x.codigoProducto});
				});
				me.modelCarga = false;
				me.localidad = me.$store.state.usuario.localidad;
			}).catch(function(error){
				console.log(error);
			});
		},
		selectCompuestos(){
			let me=this;
			let header={"Authorization" : "Bearer " };
			let configuracion= {headers : header};
			var productosArray=[];
			axios.get('api/Productos/SelectProductosCategoria/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
				productosArray=response.data;
				productosArray.map(function(x){
					me.productos.push({text:x.descripcion, value:x.codigoProducto});
				});
				me.modelCarga = false;
			}).catch(function(error){
				console.log(error);
			});
		},
		ActualizarCostos(){ 
			this.modelCarga = true;
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
				//Código para editar
				let me=this;
				axios.put('api/Productos/ActualizarVentaCosto',{
					'CodigoProducto':me.ProductoCompuesto,
					'Localidad' : me.$store.state.usuario.localidad,
					'PrecioCompra': me.calcularCosto,
					'PrecioVenta': me.PrecioVenta,
					'Formula':me.Utilidad, // este campo actualiza el valor de utilidad del producto
				},configuracion).then(function(response){
					me.Mensaje = 'Precios actualizados';
					me.snackbar = true;          
					me.modelCarga = false;           
				}).catch(function(error){
					alert("Problema actualizando, por favor envie una foto de este problema a su equipo de soporte" +error);
					me.modelCarga= false;
				});
		},
		BuscarReceta(){
      this.ProductoCompuesto = this.ProductoCompuesto.value !== undefined ? this.ProductoCompuesto.value : this.ProductoCompuesto;

			let me=this;
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			axios.get('api/DescargaInventarios/GetDescargaInventario/'+this.$store.state.usuario.localidad+'/'+this.ProductoCompuesto,configuracion)
			.then(function(response){
				me.detalles = response.data
				me.buscarProductoMaster();
			}).catch(function(error){
				// me.snackbar = true;
						//me.Mensaje = "Producto No encontrado";
			});
			
		},
		ColorPrecioVenta(){
			if(this.PrecioVenta < this.calcularCosto)
			{
				return red;
			} 
			return green;
		},
		buscarProductoMaster(){
			let me=this;
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			axios.get('api/Productos/BuscarCodigoVenta/'+this.ProductoCompuesto+'/'+this.$store.state.usuario.localidad,configuracion)
			.then(function(response){
				me.NombreProducto = response.data.descripcion;
				me.PrecioVenta = response.data.precioVenta;
				me.PrecioCostoPrevio = response.data.precioCompra;
				me.Utilidad= response.data.formula;
			}).catch(function(error){
				// me.snackbar = true;
						//me.Mensaje = "Producto No encontrado";
			});
		},
		async BorrarAsociado(item){

			if(confirm("Seguro que quiere eliminar producto asociado? "+item.descripcion)){
			this.modelCarga = true;
			let me = this;
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			await axios.delete('api/DescargaInventarios/'+item.id,{
						'codigoAsoiado':item.codigoAsoiado,
						
				},configuracion).then(function (response) {
						me.snackbar = true;
						me.Mensaje = "Eliminado con éxito";
						me.detalles = me.detalles.filter(e => e.id != item.id)
						me.modelCarga = false;
					})
					.catch(function (error) {
						me.modelCarga = false;
						alert(`Error Eliminando`);  
				})
			}
		},
		async UpdateReceta(item){
			this.modelCarga = true;
			let me = this;
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			await axios.put('api/DescargaInventarios/'+item.id,{
					'id':item.id,
					'codigoasoiado':item.codigoAsoiado,
					'codigoCompuesto': item.codigoCompuesto,
					'cantidad': item.cantidad,
					'descripcion': item.descripcion,
					'Precio': item.precio,
					'localidad': this.$store.state.usuario.localidad,
						
				},configuracion).then(function (response) {
						me.snackbar = true;
						me.Mensaje = "Actualizado con éxito";
						me.modelCarga = false;
					})
					.catch(function (error) {
						me.modelCarga = false;
						alert(`Error Actualizando`+error);  
				})
			
		},
		buscarProductoDirecto(){
			let me=this;
			this.codigo = this.codigo.value ?? this.codigo;


			if (this.codigo != "")
			{
				let header={"Authorization" : "Bearer "};
				let configuracion= {headers : header};
				axios.get('api/Productos/BuscarCodigoVenta/'+this.codigo+'/'+this.$store.state.usuario.localidad,configuracion)
				.then(function(response){
					me.agregarDetalleDirecto(response.data);
				}).catch(function(error){
					// me.snackbar = true;
							//me.Mensaje = "Producto No encontrado";
				});
			}
		},
		
		agregarDetalleDirecto(data = []){
			let me = this;
			this.modelCarga = false;
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			axios.post('api/DescargaInventarios ',{
					'codigoasoiado':data['codigoProducto'],
					'codigoCompuesto': this.ProductoCompuesto,
					'cantidad': this.cantidad,
					'descripcion': data['descripcion'],
					'Precio': data['precioCompra'],
					'UnidadMedida': 0,
					'localidad': this.$store.state.usuario.localidad,
				},configuracion).then(function (response) {
						me.Mensaje = "Producto Agregado con éxito.";
                    	me.snackbar = true;
						me.modelCarga = false;
						me.AgregarInsumo(response.data)
					})
					.catch(function (error) {
						me.modelCarga = false; 
						alert(`Problema Cargando el producto a la base de datos, compruebe la existencia`+error); 
						
				})	
			this.codigo = '';
			this.cantidad = 0;
		},
		AgregarInsumo(data = []){
			this.detalles.push(
				{
				id: data['id'], 
				codigoAsoiado:data['codigoasoiado'],
				codigoCompuesto:data['codigoCompuesto'],
				cantidad: data['cantidad'],
				descripcion:data['descripcion'],
				precio:data['precio'],
				unidadMedida:data['unidadMedida'],
				}
				);
			
		}
	}
  }
</script>
