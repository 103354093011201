<template>

    <v-img height="680"
        dark
        gradient="to right, rgba(5, 11, 31, .8), rgba(5, 11, 31, .8)"
        :src="require('@/assets/home-hero.jpg')">
        <v-btn color="white"  text rounded to="/"> <v-icon  >mdi-home</v-icon> Inicio</v-btn>
        <v-btn color="white"  text rounded to="/afiliacion"> <v-icon >mdi-login</v-icon> Afiliarse</v-btn>
        <v-layout align-center justify-center>
           
            <v-flex xs12 sm8 md6 lg5 xl4>
                <v-card>
                     
                    <v-toolbar dark color="primary">
                        <v-toolbar-title>
                            Acceso al Sistema
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-text-field v-model="email" autofocus type="email" color="accent" label="Email" required prepend-icon="mdi-account-circle">
                        </v-text-field>
                        <v-text-field @keyup.enter="ingresar()" v-model="password" type="password" color="accent" label="Password" required prepend-icon="mdi-lock">
                        </v-text-field>
                        <v-flex class="red--text" v-if="error">
                            {{error}}
                            <v-btn  
                            dark class="mb-2" 
                            link href="https://api.whatsapp.com/send?phone=50684610243&text=Olvidé%20mi%20contraseña" >
                            ¿Olvidaste tu contraseña?</v-btn>
                        </v-flex>
                    </v-card-text>
                    <v-card-actions class="px-6 pb-6">
                        <v-flex text-xs-right>
                            <v-btn @click="ingresar" @keyup="ingresar" color="primary" :loading="loading">Ingresar</v-btn>
                        </v-flex>
                        <v-btn color="secondary" dark class="mb-2" @click="dialog = true">Crear Usuario</v-btn>
                        
                    </v-card-actions>
                </v-card>
                <v-card>
                    <div>
                    <p class="font-weight-light">Con tu cuenta puedes realizar pedidos, consultar puntos  y acceder al sistema
                    <p class="font-weight-thin">Creado por Noah Systems Costa Rica.</p>
                </div>
                </v-card>
            </v-flex>
        </v-layout>
    </v-img>
</template>
<script>
import axios from 'axios'
export default {
    
    data(){
        return {
            
            email: '',
            password: '',
            error: null,
            loading: false,
            id: '',
            dialog: false,
            idrol:3,
            roles:[                   
            ],
            emailRules: [
            v => !!v || 'El correo electrónico es requerido',
            v => /.+@.+\..+/.test(v) || 'El correo electrónico es requerido debe ser válido',],
            cedulaRules: [value => !!value || 'La cédula es requerida'
            , value => (value && value.length >= 9) || 'Debe ingresar 9 carácteres como mínimo',],
            nameRules: [
            value => !!value || 'El nombre es requerido',
            value => (value && value.length >= 10) || 'Debe ingresar 10 carácteres como mínimo',
            ],
            direcciónRules: [
            value => !!value || 'La dirección exacta es requerida',
            value => (value && value.length >= 15) || 'Debe ingresar 15 carácteres como mínimo',
            ],
            celRules: [
            value => !!value || 'El número es requerido',
            value => (value && value.length >= 8) || 'Debe ingresar 8 carácteres como mínimo',
            ],
            nombre:'',
            tipo_documento: '',
            documentos: ['DNI','DIMEX','Cédula Fisica','Cédula Juridica'],
            num_documento: '',
            direccion: '',
            usuarios:[],    
            telefono: '',
            localidades:[],
            localidadesColor:[],
            idusuario: '',
            idLocalidadCliente: null,
            localidad: 'WebSite',
            condicion: true,
            email: '',
            password:'',
            actPassword:false,
            passwordAnt:'',
            valida: 0,
            validaMensaje:[],
            adModal: 0,
            adAccion: 1,
            adNombre: '',
        }
    },
    methods :{
         limpiar(){
                this.id="";
                this.idrol="";
                this.nombre="";
                this.tipo_documento="";
                this.num_documento="";
                this.direccion="";
                this.telefono="";
                this.email="";
                this.password="";
                this.Localidad="";
                this.passwordAnt="";
                this.actPassword=false;
                
            },
            validar(){
                this.valida=0;
                this.validaMensaje=[];

                if (this.nombre.length<3 || this.nombre.length>100){
                    this.validaMensaje.push("El nombre debe tener más de 3 caracteres y menos de 100 caracteres.");
                }
                if (!this.idrol){
                    this.validaMensaje.push("Seleccione un rol.");
                }
                if (!this.tipo_documento){
                    this.validaMensaje.push("Seleccione un tipo documento.");
                }

                if (!this.email){
                    this.validaMensaje.push("Ingrese el email del usuario.");
                }
                if (!this.password){
                    this.validaMensaje.push("Ingrese el password del usuario.");
                }
                if (this.validaMensaje.length){
                    this.valida=1;
                }
                return this.valida;
            },
            guardar () {
                if (this.validar()){
                    return;
                }
                this.loading = true;
                let header={"Authorization" : "Bearer " + this.$store.state.token};
                let configuracion= {headers : header};
                
                    //Código para guardar
                    let me=this;
                    axios.post('api/Usuarios/Crear',{
                        'idrol':me.idrol,
                        'nombre':me.nombre,
                        'tipo_documento': me.tipo_documento,
                        'num_documento':me.num_documento,
                        'direccion':me.direccion,
                        'telefono': me.telefono,
                        'email':me.email,
                        'password':me.password,
                        'localidad':me.localidad,
                    },configuracion).then(function(response){
                        me.dialog = false;     
                        alert(`Usuario creado de forma exitosa, por favor anote sus credenciales para futuros ingresos`);
                        me.ingresar2();                   
                    }).catch(function(error){
                    });
                
            },
            close () {
                this.dialog = false;
                this.limpiar();
            },
        ingresar(){
            this.loading = true;
            this.error=null;
            axios.post('api/Usuarios/Login', {email: this.email, password: this.password})
            .then(respuesta => {
                return respuesta.data
               // this.loading = false;
            })
            .then(data => {        
                this.$store.dispatch("guardarToken", data.token).then(() => {
					this.$nextTick(() => {
						this.$router.push({ name: 'home' });
						// location.reload();
					});
				});
            })
            .catch(err => {
                this.loading =false;
                //console.log(err.response);
                if (err.response.status==400){
                    this.error="No es un email válido";
                } else if (err.response.status==404){
                    this.error="No existe el usuario o sus datos son incorrectos";
                }else{
                    this.error="Ocurrió un error";
                }
                //console.log(err)
            })
        },
        ingresar2(){
            this.loading = true;
            this.error=null;
            axios.post('api/Usuarios/Login', {email: this.email, password: this.password})
            .then(respuesta => {
                return respuesta.data
               // this.loading = false;
            })
            .then(data => {        
                this.$store.dispatch("guardarToken", data.token) 
                this.$router.push({ name: 'home' })
            })
            .catch(err => {
                this.loading =false;
                //console.log(err.response);
                if (err.response.status==400){
                    this.error="No es un email válido";
                } else if (err.response.status==404){
                    this.error="No existe el usuario o sus datos son incorrectos";
                }else{
                    this.error="Ocurrió un error";
                }
                //console.log(err)
            })
          }
    }
    
}
</script>

<style lang="scss">>
    #espacioBoton {
        top: 10px;
    }
    #factura {
        padding: 20px;
    }
    #ticket {
    width: 60px;
    max-width: 155px;
    }
    #logo {
        float: left;
        margin-left: 2%;
        margin-right: 2%;
    }
    #imagen {
        width: 100px;
    }

    #fact {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
    }   

    #datos {
        float: left;
        margin-top: 0%;
        margin-left: 2%;
        margin-right: 2%;
    }

    #encabezado {
        text-align: center;
        margin-left: 10px;
        margin-right: 10px;
        font-size: 16px;
    }

    section {
        clear: left;
    }

    #cliente {
        text-align: left;
    }

    #facliente {
        width: 40%;
        border-collapse: collapse;
        border-spacing: 0;
        margin-bottom: 15px;
    }

    #fa {
        color: #FFFFFF;
        font-size: 14px;
    }
    @media print {
    .oculto-impresion,
    .oculto-impresion * {
        display: none !important;
    }
    }   
    #facarticulo {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        padding: 20px;
        margin-bottom: 15px;
    }

    #facarticulo thead {
        padding: 20px;
        background: #2183E3;
        text-align: center;
        border-bottom: 1px solid #FFFFFF;
    }

    #gracias {
        text-align: center;
    }
    tr.v-data-table__selected {
      background: #7d92f5 !important;
    }

    button#facturaBoton {
      margin: 18px;
    }

    button#hoyBoton {
      margin: 18px;
    }

    .tarjeta{
        margin-bottom: 15px!important;
        margin-top: 20px!important;
    }

    .efectivo{
        margin-bottom: 15px!important;
        margin-top: 20px!important;
    }

    .btnLimpiarGrid {
        margin-bottom: 20px;
    }

    button#productoBoton {
        top: 6px;
        margin-left: 35px;
    }
    .v-dialog:not(.v-dialog--fullscreen) {
        max-height: 100%!important;
    }
    .centrado {
    text-align: center;
    align-content: center;
    }

    .ticket {
        width: 155px;
        max-width: 155px;
    }

    img {
        max-width: inherit;
        width: inherit;
    }
    .centrado {
    text-align: center;
    align-content: center;
    }

    .ticket {
        width: 200px;
        max-width: 200px;
    }

    img {
        max-width: inherit;
        width: inherit;
    }

</style>