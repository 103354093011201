<template>
    <div>
        <v-data-table
            :headers="filteredHeaders"
            :items="usuarios"
            :search="search"
			:loading="loading"
            >
             <!-- Columna para mostrar la utilidad -->
			<template v-if="esBoss || esSupervisor || esAdministrador" v-slot:item.actionUtilidad="{ item }">
				{{ (item.totalLinea - (item.precioCompra * item.cantidad)) - item.descuentos }}
			</template>
			
			<!-- Columna para mostrar el precio de compra solo si cumple la condición -->
			<template v-if="esBoss || esSupervisor || esAdministrador" v-slot:item.precioCompra="{ item }">
				{{ item.precioCompra }}
			</template>
            <template v-slot:top>
                <v-row class="pa-2">
                    <v-col cols="12" lg="3" md="5" sm="8" xs="12" >
                        <v-row class="rounded-xl var mx-5">
                            <v-btn color="error" class="mx-1 mt-3" @click="crearPDF()"> <v-icon large="">mdi-file-pdf-box </v-icon>  </v-btn>
                                <vue-excel-xlsx
                                    :data="filteredData"
                                    :columns="columns"
                                    :filename="'Reporte de Ventas '+$store.state.usuario.localidad"
                                    :sheetname="'Ventas'"
                                    >
                                    <v-btn dark color="green">  <v-icon large="">mdi-file-excel </v-icon> </v-btn>
                                </vue-excel-xlsx> 
                            <v-card-title>Ventas por Producto</v-card-title>
							<p class="mx-2"> Unidades vendidas {{totalCantidadFiltrada}} </p>
                        </v-row>
						
						 </v-col>
                    <v-col cols="12" lg="4" md="7" sm="4" xs="12">    
                        <v-text-field filled v-model="search"  append-icon="mdi-magnify"
                         label="Buscar" hide-details dense
                        ></v-text-field>
						<v-select hide-details @change="listarFechas" :items="Localidades" filled label="Localidad" v-model="Localidad"> </v-select>
                    </v-col>
                    <v-col cols="12" lg="2" md="6" sm="6" xs="6">
                        <v-text-field dense hide-details filled v-model="start" type="date" label="Fecha inicial"></v-text-field>
						<v-select clearable hide-details filled dense @change="selectLocalidad()" :items="familias" v-model="familia" label="Filtrar por Familia"> </v-select>
					</v-col>
                    <v-col cols="12" lg="2" md="5" sm="5" xs="5">
                        <v-text-field hide-details filled v-model="end" dense type="date" label="Fecha final"></v-text-field>
						
						<v-select clearable dense hide-details filled @change="selectLocalidad()" :items="Proveedores" v-model="proveedor" label="Filtrar por Proveedor"> </v-select> 
                    </v-col>
                    <v-col cols="12" lg="1" md="1" sm="1" xs="1">
                        <v-btn :loading="loading" color="accent" @click="selectLocalidad()" fab > <v-icon fab> mdi-cloud-search </v-icon> </v-btn>
					</v-col>
                </v-row>
               
            </template>

        </v-data-table>
        <template class="pa-3">
            <v-snackbar
                    v-model="snackbar" top vertical="" :timeout="5000"
                    >
                    {{ Mensaje }}
                    <v-btn
                    color="info"
                    text
                    @click="snackbar = false"
                    >
                    Cerrar
                    </v-btn>
            </v-snackbar>
            
            <v-row flat color="white"  class="pa-2">
                
                <v-col cols="12" sm="8">
					<div style="font-size:25px" class="text-center primary--text">	TOP 12 mas Vendido desde {{start}} hasta {{end}} en {{Localidad}} </div>
                    <canvas id="myChart" class="rounded-xl light-blue lighten-5 pa-5">
              
                     </canvas>
                </v-col>
                
                
            </v-row>
        </template>
    </div>
</template>

<script scoped>
import axios from 'axios'
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import VueExcelXlsx from "vue-excel-xlsx";
import Vue from "vue";
import html2canvas from 'html2canvas';
import QrcodeVue from 'qrcode.vue'

Vue.use(VueExcelXlsx);
  export default {
    data: () => ({
    mesesValores: null,
    nombreMeses: [],
    totalMeses: [],
    value: 'https://www.noah.cr',
    size: 100,
    dialog: false,
    familia: '',
    search: '',
    ClienteSeleccionado : '',
    today: new Date().toISOString().substr(0, 10),
    focus: new Date().toISOString().substr(0, 10),
    headers: [
        { text: 'Codigo', value: 'codigoProducto' },
        { text: 'Nombre', value: 'descripcion' },
        { text: 'Cantidad', value: 'cantidad' },
        { text: 'Precio Venta', value: 'precioUnitario' },
        { text: 'Descuentos', value: 'descuentos' },
        { text: 'Total', value: 'totalLinea', sortable: false },
        { text: 'Cat', value: 'innerPack' },
        { text: 'Familia', value: 'famlia' },
        { text: 'Proveedor', value: 'proveedor' },
        { text: 'Forma Pago', value: 'medioDePago' },
        { text: 'Utilidad', value: 'actionUtilidad' },
	],
    columns : [
		{ label: 'Codigo', field: 'codigoProducto' },
		{ label: 'Nombre', field: 'descripcion' },
		{ label: 'Cantidad', field: 'cantidad' },
		{ label: 'Precio', field: 'precioUnitario' },
		{ label: 'Descuentos', field: 'descuentos' },
		{ label: 'Total', field: 'totalLinea', sortable: false  },
		{ label: 'Familia', field: 'famlia' },
		{ label: 'Proveedor', field: 'proveedor' },
		{ label: 'Forma Pago', field: 'medioDePago' },
	],
    usuarios: [],
    tipoDocumentos: [
        'FISICA',
        'JURIDICA',
        'EXTRANJERO',
    ],
    familias: [],
    Top10: [],
	end: '',
	start:'',
    DescTop: [],
	Localidades:[],
	Localidad :"",
    infoTop: [],
    Proveedores: [],
	loading : false,
    proveedor: '',
    Mensaje: 'Seleccione un rango de fechas',
    snackbar: true,
    }),
    components: {
      QrcodeVue,
    },

    computed: {
		formTitle () {
				return this.editedIndex === -1 ? 'Nuevo usuario' : 'Actualizar usuario'
		},
		filteredHeaders() {
			// Si cumple la condición de ser Boss, Supervisor o Administrador, agrega la columna de 'precioCompra'
			if (this.esBoss || this.esSupervisor || this.esAdministrador) {
				return [...this.headers, { text: 'Precio Costo', value: 'precioCompra' }];
			} else {
				return this.headers;
			}
		},
		filteredColumns() {
			// Si cumple la condición de ser Boss, Supervisor o Administrador, agrega la columna de 'precioCompra'
			if (this.esBoss || this.esSupervisor || this.esAdministrador) {
				return [...this.columns, { label: 'Precio Costo', field: 'precioCompra' }];
			} else {
				return this.columns;
			}
		},
		filteredData() {
			return this.filterItems(this.usuarios, this.search);
		},
		totalCantidadFiltrada() {
			return this.sumarCantidades(this.filteredData);
		},
		calcularUtilidad:function(){
			var resultado=0.0;
			for(var i=0;i<this.usuarios.length;i++){
				resultado += this.usuarios[i].totalLinea - (this.usuarios[i].precioCompra * this.usuarios[i].cantidad) - this.usuarios[i].descuentos
			}
			
			return resultado.toFixed(2);
		},
		calcularTotal:function(){
			var resultado=0.0;
			for(var i=0;i<this.usuarios.length;i++){
				resultado += this.usuarios[i].totalLinea - this.usuarios[i].descuentos
			}
			
			return resultado.toFixed(2);
		},
		esVendedor(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='Vendedor';
		},
		esCliente(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='Cliente';
		},
		esRecepcion(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='Recepcion';
		},
		esCrifasa(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='Crifasa';
		},
		esBoss(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='King Boss';
		},
		esMarketing(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='King Marketing';
		},
		esFacturador(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='King Facturador';
		},
		KingSupervisor(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='King Supervisor';
		},
		esAdministrador(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='Administrador';
		},
		esSupervisor(){
       	 	return this.$store.state.usuario && this.$store.state.usuario.rol =='Supervisor';
		},
    },
    

    watch: {
      dialog (val) {
        val || this.close()
      },
      
    },


    methods: {
        select(){
                let me=this;
                let header={"Authorization" : "Bearer " + this.$store.state.token};
                let configuracion= {headers : header};
                axios.get('api/VentasXProductoes/ConsultaFechas/'+this.today+'/'+this.today+'/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
                    //console.log(response);
                    me.usuarios=response.data;
                }).catch(function(error){
                    console.log(error);
                });
        },
		async selectLocalidad() {
			let me = this;
			let header = {
				"Authorization": "Bearer " + this.$store.state.token
			};
			let configuracion = {
				headers: header,
				params: {} // Aquí definimos un objeto vacío para los parámetros de consulta
			};

			// Si proveedor tiene un valor, lo añadimos al objeto params
			if (this.proveedor) {
				configuracion.params.proveedor = this.proveedor;
			}

			// Si familia tiene un valor, lo añadimos al objeto params
			if (this.familia) {
				configuracion.params.familia = this.familia;
			}
			this.loading = true;
			await axios.get('api/VentasXProductoes/ConsultaFechasFiltros/' + this.start + '/' + this.end + '/' + this.Localidad, configuracion)
				.then(function(response) {
					me.usuarios = response.data;
					me.loading = false;
				})
				.catch(function(error) {
					console.log(error);
				});
		},
		filterItems(items, search) {
			if (!search) {
			return items;
			}

			const searchTerm = search.toString().toLowerCase();

			return items.filter(item => {
			return Object.keys(item).some(key => {
				const value = item[key];
				if (value !== null && value !== undefined) {
				return value.toString().toLowerCase().includes(searchTerm);
				}
				return false;
			});
			});
		},
		sumarCantidades(items) {
			return items.reduce((total, item) => {
			return total + (item.cantidad || 0);
			}, 0);
		},
		async selectLocalidad1232(){
			let me = this;
			let header = {"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion = {headers : header};
			
			// Construye la URL con los parámetros de consulta si están presentes.
			let url = 'api/VentasXProductoes/ConsultaFechasFiltros/' + this.today + '/' + this.today + '/' + this.Localidad;
			
			// Suponiendo que tienes las variables this.proveedor y this.familia
			if(this.proveedor) url += `?proveedor=${this.proveedor}`;
			if(this.familia) url += this.proveedor ? `&familia=${this.familia}` : `?familia=${this.familia}`;
			
			await axios.get(url, configuracion).then(function(response){
				me.usuarios = response.data;
				me.familia = ""; // Esto reseteará el valor de familia después de hacer la petición, asegúrate de que es el comportamiento deseado
			}).catch(function(error){
				console.log(error);
			});
		},

        selectProveedores(){
                let me=this;
                let header={"Authorization" : "Bearer " };
                let configuracion= {headers : header};
                var proveedoresArray=[];
                axios.get('api/proveedores/Select/'+me.$store.state.usuario.localidad,configuracion).then(function(response){
                    proveedoresArray=response.data;
                    proveedoresArray.map(function(x){
                        me.Proveedores.push(x.proveedor);
                    });
                }).catch(function(error){
                    console.log(error);
                });
            },
        selectFamilias(){
             let me=this;
          let header={"Authorization" : "Bearer " };
          let configuracion= {headers : header};
          var familiasArray=[];
          axios.get('api/Familias/Select/'+me.$store.state.usuario.localidad,configuracion).then(function(response){
              familiasArray=response.data;
              familiasArray.map(function(x){
                  me.familias.push(x.nombre);
              });
          }).catch(function(error){
              console.log(error);
          });
        },
        FiltrarProveedor(){
            this.usuarios = this.usuarios.filter(e => e.proveedor == this.proveedor);
            this.snackbar = true;
            this.Mensaje = 'Filtro de proveedor seleccionado sobre rango de fecha listado'
        },
        FiltrarFamilia(){
			if(this.familia == ""){
				 this.usuarios = this.usuarios.filter(e => e.famlia == this.familia);
				this.snackbar = true;
				this.Mensaje = 'Filtro de Familia seleccionado sobre rango de fecha listado'
			} else
			{
				let me = this;
				let header={"Authorization" : "Bearer " + this.$store.state.token};
				let configuracion= {headers : header};
				 axios.get('api/VentasXProductoes/ConsultaFechas/'+this.start+'/'+this.end+'/'+this.Localidad,configuracion).then(function(response){
					//console.log(response);
					me.usuarios=response.data;
					me.usuarios = me.usuarios.filter(e => e.famlia == me.familia);
					me.snackbar = true;
					me.Mensaje = 'Filtro de Familia seleccionado sobre rango de fecha listado'
				}).catch(function(error){
					console.log(error);
					me.Mensaje  = error;
					me.snackbar = true;
				});
				
			}
           
        },
        FiltrarCliente2(){
            let amp = "";
            this.Datos = this.usuarios;
            this.usuarios = this.usuarios.filter(e => e.nombreReceptor == this.ClienteSeleccionado);
            this.snackbar = true;
            this.Mensaje = 'Filtro de vendedor seleccionado sobre rango de fecha listado'
        },
        FiltrarMoneda(){
            let amp = "";
            this.Datos = this.usuarios;
            amp = this.Datos.filter(e => e.moneda == this.familia);
            this.usuarios = amp;
            this.snackbar = true;
            this.Mensaje = 'Filtro Medio de Moneda Seleccionado sobre rango de fecha listado'
        },
		async listarAsociadas(){
			if(this.$store.state.usuario.rol != 'King Supervisor'){
				var items = [];
				let me=this;
				me.Localidades.push(me.$store.state.usuario.localidad);
				this.Localidad = this.$store.state.usuario.localidad
				let header={"Authorization" : "Bearer "};
				let configuracion= {headers : header};
				await axios.get('api/LocalidadesAsociadas/'+this.$store.state.usuario.nombre,configuracion).then(function(response){
					items=response.data;
					items.map(function(x){
						me.Localidades.push(x.localdiadAsociada);
					});
				}).catch(function(error){
					alert("Producto no encontrado");
					me.modelCarga=false;
				});
			} else
			{
				this.Localidades.push(this.$store.state.usuario.localidad);
				this.Localidad = this.$store.state.usuario.localidad
			}
			
    	},
		verLocalidad(item){
            this.NombreLocalidad= item.nombre;
            this.CedulaLocalidad= item.cedulaJuridica;
            this.DireccionLocalidad= item.dirreccion;
            },
        ReenvioFactura(){
            if(this.correo2 == ''){
                this.snackbar = true;
                this.Mensaje = 'Digite el correo al que desea reenviar la factura'
                return;
            }
            let header={"Authorization" : "Bearer " + this.$store.state.token};
                let configuracion= {headers : header};
                    let me=this;
                    axios.put('api/CorreosFacturas/Reenviar',{
                        'NoFactura':me.noFactura,
                        'Estado': 0,
                        'Correo2': me.correo2,
                        'ClaveNumerica':me.ClaveComprobante,
                    },configuracion).then(function(response){
                        me.snackbar = true;
                        me.Mensaje = 'Correo reenviado con éxito a '+me.correo2;                     
                    }).catch(function(error){
                        console.log(error);
                        me.snackbar = true;
                        me.Mensaje = 'Reenvio de factura a '+me.correo2+'. Disponible proximamente';   
                    });
        },
     	limpiar(){
                this.MensajeRespuesta="";
                this.ClaveComprobante="";
                this.localidad="";
                this.detalles=[];
                this.name = '',
                this.details = '',
                this.start = '',
                this.end = '',
                this.color = '',
                this.hora = '',
                this.hora2 = '',
                this.codigo = ''
    	},  
        crearPDF(){
           var columns = [
            {title: "Codigo", dataKey: "codigoProducto"},
            {title: "Nombre", dataKey: "descripcion"}, 
            {title: "Cantidad", dataKey: "cantidad"}, 
            {title: "Precio", dataKey: "precioUnitario"},
            ];
            var rows = [];

            this.filteredData.map(function(x){
                rows.push({codigoProducto:x.codigoProducto,descripcion:x.descripcion,cantidad:x.cantidad,precioUnitario:x.precioUnitario});
            });

            // Only pt supported (not mm or in)
            var doc = new jsPDF('p', 'pt');
            doc.autoTable(columns, rows, {
                margin: {top: 60},
                addPageContent: function(data) {
                    doc.text("Listado de Ventas", 40, 30);
                }
            });
            doc.save('Reporte ventas Por Producto.pdf');
        },
        crearPDFFactura(){
            var fact = this.noFactura;
             var quotes = document.getElementById('factura');
                html2canvas(quotes).then(function (canvas) {
                    var imgData = canvas.toDataURL('https://i.ibb.co/7rj9fLf/the-one-blanco.jpg');
                    var imgWidth = 210;
                    var pageHeight = 285;
                    var imgHeight = canvas.height * imgWidth / canvas.width;
                    var heightLeft = imgHeight;
                    var doc = new jsPDF();
                    var position = 0;

                    doc.addImage(imgData, 'JPG', 0, position, imgWidth, imgHeight);                    
                    doc.save('ComprobanteVenta'+fact+'.pdf');
                });
        },

        getColor (mensajeRespuesta) {
        if (mensajeRespuesta == 'aceptado') return '#8BC34A'
        if(mensajeRespuesta == 'Sin procesar') return 'orange'
        if(mensajeRespuesta == 'procesando') return '#42A5F5'
        if(mensajeRespuesta == 'rechazado') return '#FF1744'
        else  return '#42A5F5'
      },
        async listarFechas(){
            if(this.start == '' || this.end == ''){
                this.snackbar = true;
                this.Mensaje = 'Debe seleccionar una fecha inicial y final'
                return;
           }
            this.snackbar = true;
			this.Mensaje = 'Buscando data en: '+this.Localidad;
            let me=this;
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
            await axios.get('api/VentasXProductoes/ConsultaFechas/'+this.start+'/'+this.end+'/'+this.Localidad,configuracion).then(function(response){
                //console.log(response);
                me.usuarios=response.data;
            }).catch(function(error){
                console.log(error);
                me.Mensaje  = error;
                me.snackbar = true;
            });
        },

      close () {
        this.dialog = false;
        this.limpiar();
          },
    

      activarDesactivarMostrar(accion,item){
                this.adModal=1;
                this.adNombre=item.nombre;
                this.adId=item.idusuario;                
                if (accion==1){
                    this.adAccion=1;
                }
                else if (accion==2){
                    this.adAccion=2;
                }
                else{
                    this.adModal=0;
                }
            },
            activarDesactivarCerrar(){
                this.adModal=0;
            },
      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.desserts[this.editedIndex], this.editedItem)
        } else {
          this.desserts.push(this.editedItem)
        }
        this.close()
      },
    },
	mounted(){
		this.select();
		this.selectFamilias();
		this.selectProveedores();
		this.listarAsociadas();
	}
  }

</script>
<style scoped>
	::v-deep .v-data-table tbody tr:nth-child(odd) {
	background-color: #f5f5f5; /*color de fondo para filas impares*/
	}

	::v-deep .v-data-table tbody tr:nth-child(even) {
	background-color: #ffffff; /*color de fondo para filas pares*/
	}
	::v-deep .my-custom-class tbody tr:nth-child(odd) {
	background-color: #f5f5f5; /*color de fondo para filas impares*/
	}

	::v-deep .my-custom-class tbody tr:nth-child(even) {
	background-color: #ffffff; /*color de fondo para filas pares*/
	}
</style>