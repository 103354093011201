<template>
    <div>
		<!-- FACTURA -->
		<div id="bill" style="display: none;">
			<header>
				<table >
					<thead>
					<tr>
						<th><div id="logo">
								<img :src="Logo3" width="120" height="120">
								<h4> Estado de Cuenta </h4>
							</div>
						</th>
						<th>
							<div v-for="det in localidadencabezado" :key="det.nombre" id="datos">
								
									<h2> {{det.nombre}} </h2> <h3> {{det.razonSocial}} </h3>{{det.dirreccion}} Costa Rica<br> 
										{{det.cedulaJuridica}} <br>{{det.telefono}}<br>
									{{det.correo}}
								
							</div>
						</th>
					</tr>
					</thead>
					<tbody>
					<tr>
					</tr>
					</tbody>
				</table>
			</header>
			<div style="margin: 0 auto; text-align: center;">
			<br>
			<label style="font-size: medium; font-weight: bold;">Abonos de Factura: {{factura}}</label>
			<br>
			<label style="font-size: medium;">{{$store.state.usuario.localidad}}</label>
			<br>
			<label style="font-size: medium;">Fecha: {{today}}</label>
			<br>
			<label style="font-size: medium;">Usuario: {{$store.state.usuario.nombre}}</label>
			<br><br>
			<v-divider color="black"></v-divider>
			<br>
			</div>
			<div style="margin: 0 auto;">
			<label style="font-size: medium;">Id: {{id}}</label><br>
			<label style="font-size: medium;">Cliente: {{cliente}}</label>
			<br><br>
			<v-divider color="black"></v-divider>
			<div style="display: grid; grid-template-columns: auto auto auto;">
				<table>
					<thead>
						<tr>
						<th class="text-left">
							Usuario
						</th>
						<th class="text-left">
							Cliente
						</th>
						<th class="text-left">
							Fecha
						</th>
						<th class="text-left">
							Hora
						</th>
						<th class="text-left">
							Abono
						</th>
						<th class="text-left">
							Saldo Actual
						</th>
						<th class="text-left">
							Saldo Anterior
						</th>
						<th class="text-left">
							Factura
						</th>
						</tr>
					</thead>
					<tbody>
						<tr
						v-for="item in abonos"
						:key="item.name"
						>
						<td>{{ item.usuario }}</td>
						<td>{{ item.cliente }}</td>
						<td>{{ item.fecha }}</td>
						<td>{{ item.hora }}</td>
						<td>{{ item.abono }}</td>
						<td>{{ item.saldoActual }}</td>
						<td>{{ item.saldoAnterior }}</td>
						<td>{{ item.noFactura }}</td>
						</tr>
					</tbody>
					</table>
			</div>
			<br>
			
			<v-divider color="black"></v-divider>
			<br><br><br><br>
			<label style="font-size: medium;">Sign:_______________</label> <br>
			<label style="font-size: medium;">Nota: {{Nota}}</label>
			
			<br>
			</div>
		</div>
		<!-- FACTURA -->
        <v-data-table
			:headers="headers"
			:items="usuarios"
			:single-expand="singleExpand"
			:expanded.sync="expanded"
			item-key="id"
			show-expand
			:search="search"
			class="elevation-1"
		>
			<template v-slot:top>
				<v-row class="pa-2">
                    <v-col cols="12" sm="3">
                        <v-card-actions class="rounded-xl light-blue lighten-5 mx-5">
                             
                                <vue-excel-xlsx
                                    :data="usuarios"
                                    :columns="columns"
                                    :filename="'Reporte de Ventas '+$store.state.usuario.localidad"
                                    :sheetname="'Ventas'"
                                    >
                                    <v-btn fab="" color="success">  <v-icon large="">mdi-file-excel </v-icon> </v-btn>
                                </vue-excel-xlsx> 
                            <v-card-title>Creditos Pagados</v-card-title>
                        </v-card-actions>
                    </v-col>
                    <v-col cols="12" sm="4">    
                        <v-text-field v-model="search"  append-icon="mdi-magnify"
                         label="Buscar" single-line hide-details
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2">
                        <v-text-field  v-model="start" type="date" label="Fecha inicial"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2">
                        <v-text-field  v-model="end"  type="date" label="Fecha final"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="1">
                        <v-btn  color="info" @click="listarFechas()" fab > <v-icon fab> mdi-cloud-search </v-icon> </v-btn>
                    </v-col>
                </v-row>
			</template>
			<template v-slot:expanded-item="{ headers, item }">
			<td :colspan="headers.length">
				<v-btn @click="BuscarAbonos(item.factura,item.idCliente,item.cliente)" color="warning" small> Consultar Abonos Fact: {{ item.factura }}</v-btn>
				<v-simple-table>
					<template v-slot:default>
					<thead>
						<tr>
						<th class="text-left">
							Usuario
						</th>
						<th class="text-left">
							Cliente
						</th>
						<th class="text-left">
							Fecha
						</th>
						<th class="text-left">
							Hora
						</th>
						<th class="text-left">
							Abono
						</th>
						<th class="text-left">
							Interes
						</th>
						<th class="text-left">
							Saldo Actual
						</th>
						<th class="text-left">
							Saldo Anterior
						</th>
						<th class="text-left">
							Factura
						</th>
						<th class="text-left">
							Monto Interes
						</th>
						<th class="text-left">
							Reintegro
						</th>
						<th class="text-left">
							Reimprimir
						</th>
						</tr>
					</thead>
					<tbody>
						<tr
						v-for="item in abonos"
						:key="item.name"
						>
						<td>{{ item.usuario }}</td>
						<td>{{ item.cliente }}</td>
						<td>{{ item.fecha }}</td>
						<td>{{ item.hora }}</td>
						<td>{{ item.abono }}</td>
						<td>{{ item.interes }}</td>
						<td>{{ item.saldoActual }}</td>
						<td>{{ item.saldoAnterior }}</td>
						<td>{{ item.noFactura }}</td>
						<td>{{ item.montoInteres }}</td>
						<td>{{ item.reintegro }}</td>
						<td> <v-icon @click="ImprimirAbono()">mdi-printer</v-icon> </td>
						</tr>
					</tbody>
					</template>
				</v-simple-table>
			</td>
			</template>
		</v-data-table>
        <template class="pa-3">
            <v-snackbar
                    v-model="snackbar" top vertical="" :timeout="5000"
                    >
                    {{ Mensaje }}
                    <v-btn
                    color="info"
                    text
                    @click="snackbar = false"
                    >
                    Cerrar
                    </v-btn>
            </v-snackbar>
        </template>
    </div>
</template>

<script scoped>
import axios from 'axios'
import jsPDF from 'jspdf'
import autotable from 'jspdf-autotable'
import VueExcelXlsx from "vue-excel-xlsx";
import Vue from "vue";
import html2canvas from 'html2canvas';
import QrcodeVue from 'qrcode.vue'

Vue.use(VueExcelXlsx);
  export default {
    data: () => ({
    mesesValores: null,
    nombreMeses: [],
    totalMeses: [],
    value: 'https://www.noah.cr',
    size: 100,
    dialog: false,
    factura: '',
	expanded: [],
	abonos:[],
	singleExpand: true,
    search: '',
	cliente:'',
	id:'',
	localidadencabezado: [],
    ClienteSeleccionado : '',
    today: new Date().toISOString().substr(0, 10),
    focus: new Date().toISOString().substr(0, 10),
    headers: [
    { text: 'Factura', value: 'factura' },
    { text: 'Nombre', value: 'cliente' },
    { text: 'Cédula', value: 'idCliente' },
    { text: 'Fecha Compra', value: 'fechaCompra' },
	{ text: 'Hora Compra', value: 'horaCompra' },
    { text: 'Fecha Pago', value: 'fechaPago' },
    { text: 'Monto', value: 'creditoPagado', sortable: false  },
    { text: 'Vendedor', value: 'cajero' },
    ],
    columns : [
            { label: 'Factura', field: 'factura' },
            { label: 'Nombre', field: 'cliente' },
            { label: 'Cédula', field: 'idCliente' },
            { label: 'Fecha del Crédito', field: 'fechaCompra' },
			{ label: 'Hora Compra', field: 'horaCompra' },
            { label: 'Fecha de Pago', field: 'fechaPago' },
            { label: 'Monto', field: 'creditoPagado' },
            { label: 'Vendedor', field: 'cajero' },
        ],
    proveedor: '',
    Mensaje: 'Seleccione un rango de fechas',
    snackbar: true,
    }),
    components: {
      QrcodeVue,
    },

    computed: {
    formTitle () {
            return this.editedIndex === -1 ? 'Nuevo usuario' : 'Actualizar usuario'
    },
    },
    

    watch: {
      dialog (val) {
        val || this.close()
      },
      
    },


    methods: {
        select(){
                let me=this;
                let header={"Authorization" : "Bearer " + this.$store.state.token};
                let configuracion= {headers : header};
                axios.get('api/VentasXProductoes/CreditosPagados/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
                    //console.log(response);
                    me.usuarios=response.data;
                }).catch(function(error){
                    console.log(error);
                });
        },
		listarLocalidad(loc){
			let me =this;
			let logo = null;
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
				axios.get('api/Localidades/ListarFiltrada/'+loc,configuracion).then(function(response){
					//console.log(response);
					me.localidadencabezado=response.data;
					me.Logo3 = me.localidadencabezado[0].imagen;
					
					}).catch(function(error){
						console.log(error);
					});
				
		},
		BuscarAbonos(id,cedu,cliente){
			this.abonos = [];
			this.factura = id;
			this.id = cedu;
			this.cliente = cliente;
			var me = this;
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
				axios.get('api/Abonoscxc/'+this.$store.state.usuario.localidad+'/'+id,configuracion).then(function(response){
				me.abonos=response.data;
				
			})
		},
		ImprimirAbono(){
			var divContents = document.getElementById("bill").innerHTML;
			var a = window.open('', '', 'height=900, width=850');
			a.document.write('<html>');
			a.document.write(divContents);
			a.document.write('</body></html>');
			a.print();
		},
        listarFechas(){
            if(this.start == '' || this.end == ''){
                this.snackbar = true;
                this.Mensaje = 'Debe seleccionar una fecha inicial y final'
                return;
           }
            
            let me=this;
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
            axios.get('api/VentasXProductoes/CreditosPagados/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
                //console.log(response);
                me.usuarios=response.data;
            }).catch(function(error){
                console.log(error);
                me.Mensaje  = error;
                me.snackbar = true;
            });
        },

      close () {
        this.dialog = false;
        this.limpiar();
          },
    
    },
  mounted(){
      this.select();
	  this.listarLocalidad(this.$store.state.usuario.localidad);
  }
  }

</script>