<template>
  	<v-content>
      <v-dialog persistent v-model="dialogTarjeta"  max-width="300" class="primary">
            <v-toolbar  dark color="primary" class="font-weight-light body-1">
                <h4>Pago en Tarjeta</h4>
                
            </v-toolbar>
            <v-card>
            <v-container fluid>
                <v-row > 
                  <v-text-field @keyup.enter="PagoTarjeta()" rounded="" v-model="tarjetaPagar"  label="Pago en Tarjeta"></v-text-field>
                </v-row>
                <v-row > 
                  <v-select  rounded="" v-model="banco" :items="bancos" label="Banco"></v-select>
                </v-row>
                <v-row > 
                  <v-text-field @keyup.enter="PagoTarjeta()" rounded="" v-model="comprobantePago" label="Número de referencia"></v-text-field>
                </v-row>
                <v-row > 
                  <v-text-field @keyup.enter="PagoTarjeta()" rounded="" v-model="señas"  label="Otras señas(opcional)"></v-text-field>
                </v-row>
                <v-row>
                    <v-btn class="mr-2 mx-2" @click="PagoTarjeta()"> <v-icon> mdi-check-circle </v-icon> </v-btn>
                    <v-btn @click="limpiarPago()"> <v-icon> mdi-exit-to-app </v-icon> </v-btn>
                </v-row>
                
            </v-container>
            </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialogDeposito"  max-width="500" class="primary">
            <v-toolbar  dark color="primary" class="font-weight-light body-1">
                <h4>Pago en Deposito</h4>
            </v-toolbar>
            <v-card>
            <v-container fluid>
                <div> Cuentas Sistemas Informáticos Noah S.R.L.
                  <br> BAC SAN JOSE <br>
                  $ 939993267          cc	10200009399932673 <br>
                  ₡939993283		cc	10200009399932839  <br>
                  IBAN CR23010200009399932839 <br>
                  Cédula Jurica: 3102776292 </div>
                <v-row > 
                  <v-text-field @keyup.enter="PagoDeposito()" rounded="" v-model="depositoPagar"  label="Pago en deposito"></v-text-field>
                </v-row>
                <v-row > 
                  <v-select  rounded="" v-model="banco" :items="bancos" label="Banco"></v-select>
                </v-row>
                <v-row > 
                  <v-text-field @keyup.enter="PagoDeposito()" rounded="" v-model="comprobantePago"  label="Número de referencia"></v-text-field>
                </v-row>
                <v-row > 
                  <v-text-field @keyup.enter="PagoDeposito()" rounded="" v-model="señas"  label="Otras señas"></v-text-field>
                </v-row>
                <v-row>
                    <v-btn class="mr-2 mx-2" @click="PagoDeposito()"> <v-icon> mdi-check-circle </v-icon> </v-btn>
                    <v-btn @click="limpiarPago()"> <v-icon> mdi-exit-to-app </v-icon> </v-btn>
                </v-row>
            </v-container>
            </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialogSinpe"  max-width="300" class="primary">
            <v-toolbar  dark color="primary" class="font-weight-light body-1">
                <h4>Pago en SINPE Móvil</h4>
            </v-toolbar>
            <v-card>
            <div> Número para SINPE.
                  <br> SIMPE MOVIL: 84610243  <br>
                  Cuenta  nombre de Jeffry Sequeira Araya <br>
            </div>
            <v-container fluid>
                <v-row > 
                  <v-text-field @keyup.enter="PagoSinpe()" rounded="" v-model="depositoPagar"  label="Pago en SINPE Móvil"></v-text-field>
                </v-row>
                 <v-row > 
                  <v-text-field @keyup.enter="PagoSinpe()" rounded="" v-model="comprobantePago"  label="Número de SINPE"></v-text-field>
                </v-row>
                <v-row > 
                  <v-text-field @keyup.enter="PagoSinpe()" rounded="" v-model="señas"  label="Otras señas"></v-text-field>
                </v-row>
                <v-row>
                    <v-btn class="mr-2 mx-2" @click="PagoSinpe()"> <v-icon> mdi-check-circle </v-icon> </v-btn>
                    <v-btn @click="limpiarPago()"> <v-icon> mdi-exit-to-app </v-icon> </v-btn>
                </v-row>
            </v-container>
            </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialogEfectivo"  max-width="300" class="primary">
            <v-toolbar  dark color="primary" class="font-weight-light body-1">
                <h4>Pago en Efectivo</h4>
                
            </v-toolbar>
            <v-card>
            <v-container fluid>
                <v-row > 
                  <v-text-field @keyup.enter="PagoEfectivo()" rounded="" v-model="efectivoPagar"  label="Pago en efectivo"></v-text-field>
                </v-row>
                <v-row>
                    <v-btn class="mr-2 mx-2" @click="PagoEfectivo()"> <v-icon> mdi-check-circle </v-icon> </v-btn>
                    <v-btn @click="limpiarPago()"> <v-icon> mdi-exit-to-app </v-icon> </v-btn>
                </v-row>
            </v-container>
            </v-card>
        </v-dialog>
      <v-dialog persistent v-model="dialogCheque"  max-width="300" class="primary">
            <v-toolbar  dark color="primary" class="font-weight-light body-1">
                <h4>Pago en Cheque</h4>
                
            </v-toolbar>
            <v-card>
            <v-container fluid>
                <v-row > 
                  <v-text-field @keyup.enter="PagoCheque()" rounded="" v-model="depositoPagar"  label="Pago en Cheque"></v-text-field>
                </v-row>
                <v-row > 
                  <v-select  rounded="" v-model="banco" :items="bancos" label="Banco"></v-select>
                </v-row>
                 <v-row > 
                  <v-text-field @keyup.enter="PagoCheque()" rounded="" v-model="comprobantePago"  label="Número de Cheque"></v-text-field>
                </v-row>
                 <v-row > 
                  <v-text-field @keyup.enter="PagoCheque()" rounded="" v-model="señas"  label="Otras señas"></v-text-field>
                </v-row>
                <v-row>
                    <v-btn class="mr-2 mx-2" @click="PagoCheque()"> <v-icon> mdi-check-circle </v-icon> </v-btn>
                    <v-btn @click="limpiarPago()"> <v-icon> mdi-exit-to-app </v-icon> </v-btn>
                </v-row>
            </v-container>
            </v-card>
      </v-dialog>
		<v-img height="200"
			dark
			:src="require('@/assets/home-hero.jpg')"
			class="white--text"
			gradient="to right, rgba(5, 11, 31, .8), rgba(5, 11, 31, .8)"
		>
		<v-btn color="white"  text rounded to="/"> <v-icon  >mdi-home</v-icon> Inicio</v-btn>
		<v-btn color="white"  text rounded to="/login"> <v-icon >mdi-account-circle</v-icon> Iniciar Sesión</v-btn>
		
			<v-row
			align="center"
			justify="center" >
			<v-col class="text-center" cols="12">
				<v-flex xs12>
			
				<h1 class="Titulo text-uppercase">Afiliación</h1>
			<h4 class="subheading"> <v-icon> mdi-phone </v-icon>"Soporte Técnico: 4070-1889"</h4> 
			</v-flex>
				
			</v-col>
			</v-row>
		</v-img>
		<v-stepper  v-model="e6" vertical>
			<v-stepper-step editable="true" :complete="e6 > 1" step="1">
			Datos de sucursal o Localidad
			<small>Datos Obligatorios de tu negocio, los mismos serán los que ves impresos en las facturas y en el PDF enviado por correo a tus clientes</small>
			</v-stepper-step>
			<v-stepper-content step="1">
				<v-form ref="form1" v-model="validHacienda">
				<v-card>
					
						<v-card-text>
						<v-container grid-list-md>
							<v-layout wrap>
							<v-flex xs12 sm6 md6 >
								<v-select @change="PagoPlan()" :rules="[v => v != '' || 'El Plan es requerido']" prepend-icon="mdi-blur" v-model="plan"
								:items="Planes" label="Plan">
								</v-select>
							</v-flex>
							<v-flex xs12 sm6 md6>
								<v-btn
								
								:ripple="false"
								class="text-center pa-4"
								to="/#pro-features"
								color="info"
								height="auto"
								rounded=""
								>
								Ver Planes
								</v-btn>
							</v-flex>
							<v-flex xs12 sm6 md6>
								<v-text-field prepend-icon="mdi-storefront" v-model="nombreLocalidad" label="Nombre Comercial">
								</v-text-field>
							</v-flex>
							<v-flex xs12 sm6 md6>
								<v-text-field :rules="nameRules" prepend-icon="mdi-account-circle" v-model="RazonSocial" label="Razón Social (Nombre de la persona que factura)">
								</v-text-field>
							</v-flex>
							<v-flex xs12 sm6 md2>
								<v-text-field :rules="cedulaRules" prepend-icon="mdi-card" v-model="CedulaLocalidad" label="Número Cédula">
								</v-text-field>
							</v-flex>
							<v-flex xs12 sm6 md2>
								<v-select :rules="[v => !!v || 'El tipo de documento es requerido']" prepend-icon="mdi-blur" v-model="TipoCedulaLocalidad"
								:items="documentos" label="Tipo Documento">
								</v-select>
							</v-flex>
							<v-flex xs12 sm6 md2>
								<v-select @change="buscarCanton()" :rules="[v => !!v || 'La provincia es requerido']" prepend-icon="mdi-blur" v-model="Provincia"
								:items="Provincias" label="Provincia">
								</v-select>
							</v-flex>
							<v-flex xs12 sm6 md2>
								<v-select @change="buscarDistrito()" :rules="[v => !!v || 'El cantón requerido']" prepend-icon="mdi-blur" v-model="Canton"
								:items="Cantones" label="Cantón">
								</v-select>
							</v-flex>
							<v-flex xs12 sm6 md2>
								<v-combobox :rules="[v => !!v || 'El distrito es requerido']" prepend-icon="mdi-blur" v-model="Dis"
								:items="Distritos" label="Distrito">
								</v-combobox>
							</v-flex>
							<v-flex xs12 sm6 md2>
								<v-text-field :rules="celRules" prepend-icon="mdi-phone" type="number" v-model="TelefonoLocalidad" label="Teléfono">
								</v-text-field>
							</v-flex>
							<v-flex xs12 sm6 md6>
								<v-text-field :rules="emailRules" prepend-icon="mdi-mail" v-model="CorreoLocalidad" label="Correo Electrónico">
								</v-text-field>
							</v-flex>
							<v-flex xs12 sm6 md6>
								<v-text-field :rules="direcciónRules" prepend-icon="mdi-text" v-model="Direccion" label="Dirección">
								</v-text-field>
							</v-flex>
							
							<v-flex xs12 sm12 md12 v-show="valida">
								<div class="red--text" v-for="v in validaMensaje" :key="v" v-text="v">
								</div>
							</v-flex>
							</v-layout>
						</v-container>
						</v-card-text>
					
					</v-card>
				<v-btn :disabled="!validHacienda" color="primary" @click="e6 = 2">Continue</v-btn>
				<v-btn text>Cancel</v-btn>
				</v-form> 
			</v-stepper-content>

			<v-stepper-step editable="true" :complete="e6 > 2" step="2"> Parametros para Factura Electrónica
				<small>Estos datos son suministrados por el Ministerio de Hacienda en el ATV. Si no tienes los datos dejalos en blanco, <strong> equipo Noah se encarga </strong> si algo hace falta</small></v-stepper-step>

				<v-stepper-content step="2">
				<v-card>
					<v-card-text>
					<v-container grid-list-md>
						<v-layout wrap>
						<v-flex xs12 sm6 md6>
							<v-text-field prepend-icon="mdi-account-circle" type="number" v-model="UsuarioATV" label="Usuario de Ingreso">
							</v-text-field>
						</v-flex>
						<v-flex xs12 sm6 md6>
							<v-text-field prepend-icon="mdi-form-textbox-password" v-model="ContraseñaATV" label="Contraseña de ingreso">
							</v-text-field>
						</v-flex>
						<v-flex xs12 sm6 md3>
							<v-text-field prepend-icon="mdi-storefront" v-model="PuntoVenta" label="Número de Punto de Venta">
							</v-text-field>
						</v-flex>
						<v-flex xs6 sm3 md3>
							<v-text-field prepend-icon="mdi-cash" v-model="TipoCambio" label="Tipo de Cambio">
							</v-text-field>
						</v-flex>
						<v-flex xs6 sm3 md3>
							<v-text-field prepend-icon="mdi-text" v-model="Pin" label="PIN de la Llave Criptográfica">
							</v-text-field>
						</v-flex>
						<v-flex xs6 sm3 md3>
							<v-text-field prepend-icon="mdi-text" v-model="CodigoActividad" label="Código de actividad económica">
							</v-text-field>
						</v-flex>
						<v-flex xs12 sm12 md12 v-show="valida">
							<div class="red--text" v-for="v in validaMensaje" :key="v" v-text="v">
							</div>
						</v-flex>
						</v-layout>
					</v-container>
				</v-card-text>
					
				</v-card>
				<v-btn color="primary" @click="e6 = 3">Continue</v-btn>
				<v-btn text>Cancel</v-btn>
			</v-stepper-content>

			<v-stepper-step editable="true" :complete="e6 > 3" step="3">Datos del usuario <small>Datos Obligatorios</small></v-stepper-step>

			<v-stepper-content step="3">
				<v-card>
				<v-form ref="form2" v-model="validUsuario">
					<v-card-text>
					<v-container grid-list-md>
						<v-layout wrap>
						<v-flex xs12 sm6 md6>
							<v-text-field :rules="nameRules" prepend-icon="mdi-account-circle" v-model="nombreUsuario" label="Nombre">
							
							</v-text-field>
						</v-flex>
						<v-flex xs12 sm6 md6>
							<v-text-field :rules="cedulaRules" prepend-icon="mdi-card" v-model="cedulaUsuario" label="Cédula">
							</v-text-field>
						</v-flex>
						<v-flex xs12 sm3 md6>
							<v-select :rules="[v => !!v || 'El tipo de documento es requerido']" prepend-icon="mdi-blur" v-model="tipo_documento"
							:items="documentos" label="Tipo Documento">
							</v-select>
						</v-flex>
						<v-flex xs12 sm3 md6>
							<v-text-field :rules="celRules" prepend-icon="mdi-phone" type="number" v-model="telefonoUsuario" label="Teléfono">
							</v-text-field>
						</v-flex>
						<v-flex xs12 sm6 md6>
							<v-text-field :rules="emailRules" prepend-icon="mdi-mail" v-model="correoUsuario" label="Correo Electrónico">
							</v-text-field>
						</v-flex>
						<v-flex xs12 sm6 md6>
						<v-text-field prepend-icon="mdi-lock" type="password" v-model="password" label="Contraseña">
						</v-text-field>
						</v-flex>
						<v-flex xs12 sm12 md12 v-show="valida">
							<div class="red--text" v-for="v in validaMensaje" :key="v" v-text="v">
							</div>
						</v-flex>
						</v-layout>
					</v-container>
					</v-card-text>
				</v-form>  
				</v-card>
			<v-btn :disabled="!validUsuario" color="primary" @click="e6 = 4">Continuar</v-btn>
			<v-btn text>Cancel</v-btn>
			</v-stepper-content>

			<v-stepper-step step="4">Método de Pago</v-stepper-step>
			<v-stepper-content step="4">
			<v-row>
				<v-col cols="10" sm="4">
					<div class="primary--text font-weight">Método de Pago </div>
						<v-card-actions> 
								<v-list>
								<v-img  @click="abrirSinpe()" max-height="55" max-width="55" :src="require('../assets/SINPEMOVIL.jpeg')"> </v-img> 
								<div class="text-center font-weight pa-2 ">Sinpe </div>
								</v-list>
								<v-list>
								<v-img @click="abrirDeposito()" max-height="55" max-width="55" :src="require('../assets/pago.png')"> </v-img> 
								<div class="text-center font-weight-light pa-2">Depósito </div>
								</v-list>  
								<v-list>
								<v-img @click="abrirEfectivo()" max-height="55" max-width="55" :src="require('../assets/efectivo.png')"> </v-img> 
								<div class="text-center font-weight-light pa-2">Efectivo </div>
								</v-list>
						</v-card-actions>
				</v-col>
				<v-col cols="10" sm="3" class="grey lighten-4 blockquote">
					<v-flex class="h6">
						<strong class="primary--text">Resumen de Compra</strong>
						<div >Plan: {{NombredelPlan}} </div>
						<div>Facturas Mensuales: {{CantidadFacturas}}</div>
						<div>Pago Mensual:₡ {{plan}}</div>
						<div>Instalación: ₡ {{Instalacion}}</div>
						<v-text-field v-model="cupon" prepend-icon="mdi-brightness-percent" label="Aplicar Cupón"> </v-text-field>  <v-btn @click="AplicarCupon()" color="info"> Aplicar Cupón </v-btn>
						
					</v-flex>
				</v-col>
				<v-col cols="10" sm="3" class="grey lighten-4 blockquote">
					<v-flex class="h6">
						<strong class="primary--text">Método de Pago</strong>
						<div >Efectivo: ₡ {{efectivoPagar}} </div>
						<div>Tarjeta: ₡ {{tarjetaPagar}}</div>
						<div>Depositos: ₡ {{depositoPagar}}</div>
						<strong class="primary--text">Total: ₡ {{totalPagar}}</strong>
					</v-flex>
				</v-col>
			</v-row>
			<v-btn block="" type="submit" color="primary" id="espacioBoton" class=" pa-5" style="top: 10px;" @click="Afiliarse()" @click.stop="dialog = false">
						Afiliarse
			</v-btn>
			</v-stepper-content>
  		</v-stepper>
  	</v-content>
</template>


<script>
  import axios from 'axios';
  export default {
    data () {
      return {
        e6: 1,
        dialogSinpe: false,
        plan: '',
        Planes: [ {text:'Plan Gratis ₡0', value:1},{text:'Régimen Simplificado ₡25.000', value:25000},
        {text:'Régimen tradicional ₡30.000', value:30000},{text:'Plan e-commerce ₡60.000', value:60000},],
        dialogDeposito: false,
        dialogCheque: false,
        dialogTarjeta: false,
        Instalacion: 0,
        tarjetaPagar:0,
        efectivoPagar:0,
        vueltoPagar:0,
        totalGravado:0,
        Regimen: 'TRADICIONAL',
        emailRules: [
            v => !!v || 'El correo electrónico es requerido',
            v => /.+@.+\..+/.test(v) || 'El correo electrónico es requerido debe ser válido',],
            cedulaRules: [value => !!value || 'La cédula es requerida'
            , value => (value && value.length >= 9) || 'Debe ingresar 9 carácteres como mínimo',],
        nameRules: [
            value => !!value || 'El nombre es requerido',
            value => (value && value.length >= 10) || 'Debe ingresar 10 carácteres como mínimo',
            ],
        direcciónRules: [
            value => !!value || 'La dirección exacta es requerida',
            value => (value && value.length >= 15) || 'Debe ingresar 15 carácteres como mínimo',
            ],
        celRules: [
            value => !!value || 'El número es requerido',
            value => (value && value.length >= 8) || 'Debe ingresar 8 carácteres como mínimo',
            ],
        totalPagar:0,
        nombreLocalidad: '',
        TelefonoLocalidad: '',
        CedulaLocalidad: '',
        bancos: ["Banco Costa Rica","Banco Popular","BAC San José","Banco Nacional","Bancredito","Lafise","Davivienda","Cathay","Scotia Bank de Costa Rica" ],
    
        TipoCedulaLocalidad: '',
        RazonSocial: '',
        Provincia: '',
        Canton: '',
        Distrito: '',
        CorreoLocalidad: '',
        Direccion: '',
        TipoCambio : 506,
        cupon: '',
        CasaMatriz: '001',
        NoActividadEconomica: 0,
        dis2 : '01',
        PuntoVenta: '00001',
        NombreEmisor: '',
        Dis: '01',
        UsuarioATV: '',
        ContraseñaATV: '',
        NombredelPlan: 'Gratis',
        ClaveProduccion: '',
        detalle: '',
        Pin: 0,
        pago : '',
        efectivoPagar : 0,
        tarjetaPagar : 0,
        depositoPagar : 0,
        CodigoActividad : 0,
        documentos: [
          'FISICA',
          'JURIDICA',
          'EXTRANJERO',
        ],
        nombreUsuario:'',
        CantidadFacturas: 5,
        password: '',
        cedulaUsuario: '',
        telefonoUsuario: '',
        correoUsuario: '',
        vueltoPagar: 0,
        tipo_documento : '',
        tipoPago: '',
        Provincias: [{text:'San josé', value:1},
          {text:'Alajuela', value:2},
          {text:'Cartago', value:3},
          {text:'Heredia', value:4},
          {text:'Guanacaste', value:5},
          {text:'Puntarenas', value:6},
          {text:'Limón', value:7}],


// zona de distritos inicio  07-08-2020 
        DistritoPuriscal: [{text:'Salubridad', value:'013'},
          {text:'San Bosco', value:'014'},
          {text:'San Francisco', value:'015'},
          {text:'Santa Lucía', value:'016'},
          {text:'Silos', value:'017'},
          {text:'CATEDRAL', value:'00'},
          {text:'Bellavista', value:'01'},
          {text:'California (parte)', value:'02'},
          {text:'Carlos María Jiménez', value:'03'},
          {text:'Dolorosa (parte)', value:'04'},
        ],
        DistritoTarrazu: [{text:'Dos Pinos', value:'05'},
          {text:'Francisco Peralta (parte)', value:'06'},
          {text:'González Lahmann', value:'07'},
          {text:'González Víquez', value:'08'},
          ],
        DistritoAserri: [{text:'Güell', value:'09'},
          {text:'La Cruz', value:'010'},
          {text:'Lomas de Ocloro', value:'011'},
          {text:'Luján', value:'012'},
          {text:'Milflor', value:'013'},
          {text:'Naciones Unidas', value:'014'},
          {text:'Pacífico (parte)', value:'015'},
          {text:'San Cayetano (parte)', value:'016'},
          ],
        DistritoMora : [{text:'Soledad', value:'017'},
          {text:'Tabacalera', value:'018'},
          {text:'Vasconia', value:'019'},
          {text:'ZAPOTE', value:'00'},
          {text:'Alborada', value:'01'},
          {text:'Calderón Muñoz', value:'02'},
          {text:'Cerrito', value:'03'},
          {text:'Córdoba', value:'04'},
        ],
        DistritoGoicocheda : [{text:'Gloria', value:'05'},
          {text:'Jardín', value:'06'},
          {text:'Luisas', value:'07'},
          {text:'Mangos', value:'08'},
          {text:'Montealegre', value:'09'},
          {text:'Moreno Cañas', value:'010'},
          {text:'Quesada Durán', value:'011'},
          {text:'San Dimas', value:'012'},
          ],
        DistritoSantaAna : [{text:'San Gerardo (parte)', value:'013'},
          {text:'Trébol', value:'014'},
          {text:'Ujarrás', value:'015'},
          {text:'Vista Hermosa', value:'016'},
          {text:'Yoses Sur', value:'017'},
          {text:'Zapote (centro)', value:'018'},
          {text:'SAN FRANCISCO DE DOS RIOS', value:'00'},
          ],
        DistritoAlajuelita : [{text:'Ahogados (parte)', value:'01'},
          {text:'Bosque', value:'02'},
          {text:'Cabañas', value:'03'},
          {text:'Camelias', value:'04'},
          {text:'Coopeguaria', value:'05'},
          {text:'Faro', value:'06'},
          ],
        DistritoVasquesCoronado: [{text:'Fátima', value:'07'},
          {text:'Hispano', value:'08'},
          {text:'I Griega', value:'09'},
          {text:'Lincoln', value:'010'},
          {text:'Lomas de San Francisco', value:'011'},
          {text:'Maalot', value:'012'},
          ],
        DistritoAcosta: [{text:'Méndez', value:'013'},
          {text:'Pacífica', value:'014'},
          {text:'San Francisco de Dos Ríos (centro)', value:'015'},
          {text:'Sauces', value:'016'},
          {text:'Saucitos', value:'017'},
          {text:'Zurquí', value:'018'},
          ],
        DistritosTibas: [{text:'URUCA', value:'00'},
          {text:'Alborada', value:'01'},
          {text:'Ánimas', value:'02'},
          {text:'Árboles', value:'03'},
          {text:'Bajos del Torres', value:'04'},
          {text:'Carranza', value:'05'},
          ],
        DistritoMoravia: [{text:'Corazón de Jesús', value:'06'},
          {text:'Cristal', value:'07'},
          {text:'Carvajal Castro', value:'08'},
          {text:'Jardines de Autopista', value:'09'},
          ],
        DistritoMontesdeOca: [{text:'La Caja', value:'10'},
          {text:'La Carpio', value:'11'},
          {text:'Magnolia', value:'12'},
          {text:'Marimil', value:'13'},
          {text:'Monserrat', value:'14'},
          ],
        DistritoTurrubares : [{text:'Peregrina', value:'15'},
          {text:'Robledal', value:'16'},
          {text:'Rossiter Carballo', value:'17'},
          {text:'Santander', value:'18'},
          {text:'Saturno', value:'19'},
          {text:'Uruca (centro)', value:'20'},
          ],
        DistritoDota: [{text:'Vuelta del Virilla', value:'21'},
          {text:'MATA REDONDA', value:'00'},
          {text:'Américas', value:'01'},
          {text:'Bajo Cañada (parte)', value:'02'},
          ],
        DistritoDota: [{text:'Vuelta del Virilla', value:'01'},
          {text:'MATA REDONDA', value:'00'},
          {text:'Américas', value:'01'},
          {text:'Bajo Cañada (parte)', value:'02'},
          ],        
        DistritoCurridabat: [{text:'Balcón Verde', value:'03'},
          {text:'Colón (parte)', value:'04'},
          {text:'Del Pino', value:'05'},
          {text:'Holanda', value:'06'},
          {text:'La Luisa', value:'07'},
          ],
        DistritoPerezZeledon: [{text:'La Salle', value:'08'},
          {text:'Lomalinda', value:'09'},
          {text:'Morenos', value:'010'},
          {text:'Niza', value:'011'},
          {text:'Rancho Luna', value:'012'},
          {text:'Rohrmoser (parte)', value:'013'},
          {text:'Roma', value:'014'},
          {text:'Sabana', value:'015'},
          {text:'Tovar', value:'016'},
          {text:'PAVAS', value:'00'},
          {text:'Alfa', value:'01'},
          {text:'Asturias', value:'02'},
          {text:'Asunción', value:'03'},
          ],
        DitritoLeonCortes: [{text:'Bribrí', value:'04'},
          {text:'Favorita Norte', value:'05'},
          {text:'Favorita Sur', value:'06'},
          {text:'Galicia', value:'07'},
          {text:'Geroma', value:'08'},
          {text:'Hispania', value:'09'},
          {text:'Libertad', value:'010'},
          ],
        DistritoAlajuela : [{text:'Lomas del Río', value:'011'},
          {text:'Llanos del Sol', value:'012'},
          {text:'María Reina', value:'013'},
          {text:'Metrópolis', value:'014'},
          {text:'Navarra', value:'015'},
          {text:'Pavas (centro)', value:'016'},
          {text:'Pueblo Nuevo', value:'017'},
          {text:'Residencial del Oeste', value:'018'},
          {text:'Rincón Grande', value:'019'},
          {text:'Rohrmoser (parte)', value:'020'},
          {text:'Rotonda', value:'021'},
          {text:'San Pedro', value:'022'},
          {text:'Santa Bárbara', value:'023'},
          {text:'Santa Catalina', value:'024'},
          {text:'Santa Fé', value:'025'},
          {text:'Triángulo', value:'026'},
        ],
      DistritoSanRamon: [{text:'Villa Esperanza', value:'027'},
          {text:'HATILLO', value:'00'},
          {text:'Bajo Cañada (parte)', value:'01'},
          {text:'Belgrano', value:'02'},
          {text:'Hatillo Centro', value:'03'},
          {text:'Hatillo 1', value:'04'},
          {text:'Hatillo 2', value:'05'},
          {text:'Hatillo 3', value:'06'},
          {text:'Hatillo 4', value:'07'},
          {text:'Hatillo 5', value:'08'},
          {text:'Hatillo 6', value:'09'},
          {text:'Hatillo 7', value:'010'},
          {text:'Hatillo 8', value:'011'},
          {text:'Quince de Setiembre', value:'12'},
          {text:'Sagrada Familia', value:'13'},
          ],
        DistritoGrecia: [{text:'Tiribí', value:'14'},
        {text:'Topacio', value:'15'},
        {text:'Veinticinco de Julio', value:'16'},
        {text:'Vivienda en Marcha', value:'17'},
        {text:'SAN SEBASTIAN', value:'00'},
        {text:'Bengala', value:'01'},
        {text:'Bilbao', value:'02'},
        {text:'Cañada del Sur', value:'03'},
        ],
        DistritoSanMateo: [{text:'Carmen', value:'04'},
          {text:'Cascajal', value:'05'},
          {text:'Cerro Azul', value:'06'},
          {text:'Colombari', value:'07'},
          {text:'Domingo Savio', value:'08'},
          ],
      DistritoAtenas: [{text:'Guacamaya', value:'09'},
          {text:'Jazmín', value:'010'},
          {text:'Hogar Propio', value:'011'},
          {text:'Kennedy', value:'012'},
          {text:'López Mateos', value:'013'},
          {text:'Luna Park', value:'014'},
          {text:'Martínez', value:'015'},
          {text:'Mojados', value:'016'},
          {text:'Mongito', value:'017'},
          ],
        DistritoNaranjo: [{text:'Monte Azúl', value:'018'},
          {text:'Musmanni', value:'019'},
          {text:'Paso Ancho', value:'020'},
          {text:'Presidentes', value:'021'},
          {text:'San Cayetano (parte)', value:'022'},
          {text:'San Martín', value:'023'},
          {text:'San Sebastián (centro)', value:'024'},
          {text:'Santa Rosa', value:'025'},
          {text:'Seminario', value:'026'},
          ],
        DistritoPalmares: [{text:'ESCAZU', value:'00'},
          {text:'Alto Carrizal', value:'01'},
          {text:'Carrizal (parte)', value:'02'},
          {text:'Faroles', value:'03'},
          ],    
        DistritoPoas: [{text:'Guapinol', value:'04'},
          {text:'Hulera', value:'05'},
          {text:'Itabas', value:'06'},
          {text:'Jaboncillo', value:'07'},
          {text:'Profesores (parte)', value:'08'},
          {text:'SAN ANTONIO', value:'00'},
          ],  
        DitritoOrotina: [{text:'Avellana', value:'01'},
          {text:'Bebedero', value:'02'},
          {text:'Belo Horizonte (parte)', value:'03'},
          {text:'Carrizal (parte)', value:'04'},
          {text:'Curío', value:'05'},
          {text:'Chirca', value:'06'},
          ],
        DistritoSanCarlos: [{text:'Chiverral', value:'07'},
          {text:'Entierrillo', value:'08'},
          {text:'Filtros', value:'09'},
          {text:'Guayabos', value:'10'},
          {text:'Hojablanca', value:'11'},
          {text:'Juan Santana', value:'12'},
          {text:'Lajas', value:'13'},
          {text:'Masilla', value:'14'},
          {text:'Muta', value:'15'},
          {text:'Pedrero', value:'16'},
          {text:'Perú', value:'17'},
          {text:'Profesores (parte)', value:'18'},
          {text:'Sabanillas', value:'19'},
          {text:'Salitrillos', value:'20'},
          ],
        DistritosAlfaroRuiz: [{text:'Santa Eduvigis', value:'021'},
          {text:'Santa Teresa', value:'022'},
          {text:'Tejarcillos', value:'023'},
          {text:'Torrotillo', value:'024'},
          {text:'Vista de Oro', value:'025'},
          {text:'SAN RAFAEL', value:'00'},
          {text:'Anonos', value:'01'},
          {text:'Ayala', value:'02'},
          ],
        DistritoValverdeVega: [{text:'Bajo Anonos', value:'03'},
          {text:'Bajo Palomas', value:'04'},
          {text:'Belo Horizonte (parte)', value:'05'},
          {text:'Betina', value:'06'},
          {text:'Ceiba', value:'07'},
          {text:'Facio Castro', value:'08'},
          ],
         DistritoUpala: [{text:'Guachipelín', value:'09'},
          {text:'Herrera', value:'010'},
          {text:'Laureles', value:'011'},
          {text:'León', value:'012'},
          {text:'Loma Real', value:'013'},
          {text:'Matapalo', value:'014'},
          {text:'Maynard', value:'015'},
          {text:'Mirador', value:'016'},
          {text:'Miravalles', value:'017'},
          ],
          
         DistritoLosChiles :[{text:'Palermo', value:'018'},
          {text:'Palma de Mallorca', value:'019'},
          {text:'Pinar', value:'020'},
          {text:'Primavera', value:'021'},
          {text:'Quesada', value:'022'},
          ],
        DistritoGuatuso: [{text:'Real de Pereira (parte)', value:'023'},
          {text:'Santa Marta', value:'024'},
          {text:'Tena', value:'025'},
          {text:'Trejos Montealegre', value:'026'},
          {text:'Vista Alegre', value:'027'}],
        DistritoCartago: [{text:'DESAMPARADOS', value:'00'},
          {text:'DESAMPARADOS', value:'00'},
          {text:'Altamira', value:'01'},
          {text:'Bellavista', value:'02'},
          {text:'Calle Fallas', value:'03'},
          {text:'Camaquirí', value:'04'},
          {text:'Capilla', value:'05'},
          {text:'Centro de Amigos', value:'06'},
          {text:'Cerámica', value:'07'},
          {text:'Colonia del Sur', value:'08'},
          {text:'Contadores', value:'09'},
          {text:'Cruce', value:'010'},
          {text:'Cucubres', value:'011'},
          ],
        DistritosParaiso: [{text:'Dorados', value:'012'},
        {text:'Florita', value:'013'},
        {text:'Fortuna', value:'014'},
        {text:'Jardín', value:'015'},
        {text:'Loto', value:'016'},
        {text:'Metrópoli', value:'017'},
        ],
        DistritosLaUnion: [{text:'Monseñor Sanabria', value:'018'},
          {text:'Monteclaro', value:'019'},
          {text:'Palogrande', value:'020'},
          {text:'Pinos', value:'021'},
          {text:'Retoños', value:'022'},
          {text:'Río Jorco', value:'023'},
          {text:'Sabara', value:'024'},
          {text:'San Esteban Rey', value:'025'},
          {text:'San Jerónimo', value:'026'},
          ],
        DistritosJimenez: [{text:'San José', value:'027'},
          {text:'San Roque', value:'028'},
          {text:'Tauros', value:'029'},
          {text:'Torremolinos', value:'030'},
          ],
        DistritosTurrialba: [{text:'Venecia', value:'031'},
        {text:'Vista Verde', value:'032'},
        {text:'SAN MIGUEL', value:'00'},
        {text:'Ángeles', value:'01'},
        {text:'Capri', value:'02'},
        {text:'Damas Israelitas', value:'03'},
        {text:'Girasol', value:'04'},
        {text:'Higuito', value:'05'},
        {text:'Lindavista', value:'06'},
        {text:'Lomas de Jorco', value:'07'},
        {text:'Llano', value:'08'},
        {text:'Meseguer', value:'09'},
        {text:'Olivos', value:'010'},
        ],
        DistritosAlvarado: [{text:'Orquídeas', value:'011'},
        {text:'Peñascal', value:'012'},
        {text:'Rinconada', value:'013'},
        {text:'Rodillal', value:'014'},
        ],
        DistritoOreamuno: [{text:'Sabanilla', value:'015'},
        {text:'San Martín', value:'016'},
        {text:'Santa Eduvigis', value:'017'},
        {text:'Valverde', value:'018'},
        {text:'Alto Alumbre', value:'019'},
        {text:'Hoyo', value:'020'},
        ],
        DistritoElGuarco : [{text:'Jericó', value:'021'},
        {text:'Manzano', value:'022'},
        {text:'Pacaya', value:'023'},
        {text:'Roblar', value:'024'},
        {text:'Ticalpes (parte)', value:'025'},
        ],
        DistritoHeredia: [{text:'Calle Naranjos', value:'026'},
        {text:'SAN JUAN DE DIOS', value:'00'},
        {text:'Calabacitas', value:'01'},
        {text:'Calle Común', value:'02'},
        {text:'Cruz Roja', value:'03'},
        {text:'Itaipú', value:'04'},
        {text:'Máquinas', value:'05'},
        ],
        DistritoBarva: [{text:'Mota', value:'06'},
        {text:'Novedades', value:'07'},
        {text:'Pedrito Monge', value:'08'},
        {text:'Río', value:'09'},
        {text:'Robles', value:'010'},
        {text:'SAN RAFAEL ARRIBA', value:'00'},
        {text:'Alpino', value:'01'},
        ],
        DistritoSantoDomingo: [{text:'Arco Iris', value:'02'},
        {text:'Bambú', value:'03'},
        {text:'Berlay', value:'04'},
        {text:'Guaria', value:'05'},
        {text:'Huaso (parte)', value:'06'},
        {text:'Juncales', value:'07'},
        {text:'Lajas', value:'08'},
        {text:'Macarena', value:'09'},
        {text:'Maiquetía', value:'010'},
        ],
        DistritoSantaBarbara: [{text:'Méndez', value:'011'},
        {text:'SAN ANTONIO', value:'00'},
        {text:'Acacias', value:'01'},
        {text:'Calle Amador', value:'02'},
        {text:'Constancia', value:'03'},
        {text:'Churuca', value:'04'},
        {text:'Huetares', value:'05'},
        ],
        DistritoSanRafael: [{text:'Plazoleta', value:'06'},
        {text:'Pueblo Nuevo', value:'07'},
        {text:'Río Damas', value:'08'},
        {text:'Rotondas', value:'09'},
        {text:'Solar', value:'010'},
        {text:'FRAILES', value:'00'},
        ],
        DistritoSanIsidro: [{text:'Bajos de Tarrazú', value:'01'},
        {text:'Bustamante', value:'02'},
        {text:'Santa Elena (parte)', value:'03'},
        {text:'Violeta', value:'04'},
        {text:'PATARRA', value:'00'},
        ], 
        DistritoBelen: [{text:'Aguacate', value:'01'},
        {text:'Balneario', value:'02'},
        {text:'Don Bosco', value:'03'},
        {text:'Guatuso', value:'04'},
        ],
        DistritoFlores: [{text:'Güízaro', value:'05'},
        {text:'Jerusalén', value:'06'},
        {text:'Lince', value:'07'},
        {text:'Mesas', value:'08'},
        ],
        DistritoSanPablo:[{text:'Quebrada Honda', value:'09'},
          {text:'Ticalpes (parte)', value:'010'},
          {text:'SAN CRISTOBAL (San Cristóbal Norte)', value:'00'},
          ],
        DistritoSarapiqui: [{text:'Empalme (parte)', value:'01'},
          {text:'Lucha (parte)', value:'02'},
          {text:'San Cristóbal Sur', value:'03'},
          {text:'Sierra', value:'04'},
          {text:'ROSARIO', value:'00'},
          {text:'Bajo Tigre', value:'01'},
          ],
        DistritoGuanacaste: [{text:'Chirogres', value:'02'}, ],
        DistritoLiberia: [{text:'Guadarrama', value:'03'},
        {text:'Joya', value:'04'},
        {text:'La Fila (parte)', value:'05'},
        {text:'Llano bonito', value:'06'},
        {text:'Quebrada Honda', value:'07'},
        {text:'Trinidad (parte)', value:'08'},
        ],
        DistritoNicoya: [{text:'DAMAS Fátima)', value:'00'},
          {text:'Cajita', value:'01'},
          {text:'Dorado', value:'02'},
          {text:'Dos Cercas', value:'03'},
          {text:'Fomentera', value:'04'},
          {text:'Nuestra Señora de la Esperanza', value:'05'},
          {text:'San Lorenzo', value:'06'},
          {text:'SAN RAFAEL ABAJO', value:'00'},
          ],
        DistritoSantaCruz: [{text:'Ángeles', value:'01'},
          {text:'Autofores', value:'02'},
          {text:'Balboa', value:'03'},
          {text:'Coopelot', value:'04'},
          {text:'Gardenia', value:'05'},
          {text:'Higuerones', value:'06'},
          {text:'Leo', value:'07'},
          {text:'Mónaco', value:'08'},
          {text:'Sagitario', value:'09'},
          {text:'Santa Cecilia', value:'010'},
          ],
        DistritoBagaces: [{text:'Tejar (parte)', value:'011'},
          {text:'Treviso', value:'012'},
          {text:'Unidas', value:'013'},
          {text:'Valencia', value:'014'},
          {text:'Vizcaya', value:'015'},
          ],
        DistritoCarrillo: [{text:'GRAVILIAS', value:'00'},
          {text:'Cartonera', value:'01'},
          {text:'Claveles', value:'02'},
          {text:'Damasco', value:'03'},
          {text:'Diamante', value:'04'},
          ],
        Distriocañas: [{text:'Esmeraldas', value:'05'},
          {text:'Fortuna', value:'06'},
          {text:'Fortunita', value:'07'},
          {text:'Porvenir', value:'08'},
          {text:'Raya', value:'09'},
          {text:'Riberalta', value:'010'},
          ],
        DistritoAbangares: [{text:'Villanueva', value:'011'},
          {text:'LOS GUIDO (Guidos)', value:'00'},
          {text:'Letras', value:'01'},
          {text:'Balcón Verde', value:'02'},
          {text:'PURISCAL', value:'00'},
          ],
        DistritoTilaran:[{text:'SANTIAGO', value:'00'},
          {text:'Ángeles', value:'01'},
          {text:'Buenos Aires', value:'02'},
          {text:'Corazón de María', value:'03'},
          {text:'Jarasal', value:'04'},
          {text:'Junquillo Arriba', value:'05'},
          {text:'Pueblo Nuevo', value:'06'},
          {text:'San Isidro', value:'07'},
          ],
        DistritoNandayure:[{text:'Bajo Badilla', value:'08'},
          {text:'Bajo Moras', value:'09'},
          {text:'Cañales Abajo', value:'010'},
          {text:'Cañales Arriba', value:'011'},
          {text:'Carit', value:'012'},
          {text:'Charcón (parte)', value:'013'},
          {text:'Cirrí', value:'014'},
          ],
        DistritoLaCruz: [{text:'Junquillo Abajo', value:'015'},
          {text:'Pozos', value:'016'},
          {text:'San Francisco', value:'017'},
          {text:'San Martín', value:'018'},
          {text:'Zapote', value:'019'},
          ],
        DistritoHojancha: [{text:'MERCEDES SUR', value:'00'},
        {text:'Alto Palma', value:'01'},
        {text:'Bajo Lanas', value:'02'},
        {text:'Bajo Legua', value:'03'},
        {text:'Bajo Legüita', value:'04'},
        ],
        DistritoPuntarenas:[{text:'Bajo Quesada', value:'05'},
        {text:'Bocana', value:'06'},
        {text:'Carmona', value:'07'},
        {text:'Cerbatana', value:'08'},
        {text:'Charquillos', value:'09'},
        {text:'Jilgueral', value:'010'},
        {text:'Llano Grande', value:'011'},
        {text:'Mercedes Norte', value:'012'},
        {text:'Potenciana', value:'013'},
        {text:'Quebrada Honda', value:'014'},
        {text:'Quivel', value:'015'},
        {text:'Rancho Largo', value:'016'},
        {text:'Salitrales', value:'017'},
        {text:'Santa Marta', value:'018'},
        {text:'Túfares', value:'019'},
        {text:'Tulín', value:'020'},
        {text:'Víbora', value:'021'},
        {text:'Zapotal', value:'022'},
        ],
        DistritoEsparza: [{text:'BARBACOAS', value:'00'},
        {text:'Alto Barbacoas', value:'01'},
        {text:'Bajo Burgos', value:'02'},
        {text:'Cortezal', value:'03'},
        {text:'Guatuso', value:'04'},
        {text:'Piedades', value:'05'},
        {text:'San Juan', value:'06'},
        ],
        DistritoBuenosAires: [{text:'GRIFO ALTO', value:'00'},
          {text:'Cacao', value:'01'},
          {text:'Cuesta Mora', value:'02'},
          {text:'Grifo Bajo', value:'03'},
          {text:'Poró', value:'04'},
          {text:'Pueblo Nuevo', value:'05'},
          {text:'Salitrillo', value:'06'},
          {text:'SAN RAFAEL (San Rafael Arriba)', value:'00'},
          {text:'Bijagual', value:'01'},
          {text:'Floralia', value:'02'},
          ],
        DistritoMontesdeOro: [{text:'Punta de Lanza', value:'03'},
          {text:'San Rafael Abajo', value:'04'},
          {text:'CANDELARITA', value:'00'},
          {text:'Alto Cebadilla', value:'01'},
          ],
        DistritosOsa: [{text:'Bajo Chacones', value:'02'},
        {text:'Copalar', value:'03'},
        {text:'Pedernal', value:'04'},
        {text:'Polca', value:'05'},
        {text:'Sabanas', value:'06'},
        {text:'DESAMPARADITOS', value:'00'},
        {text:'Bajo Guevara', value:'01'},
        ],
        DistritoAguirre: [{text:'Planta', value:'02'},
          {text:'Rinconada', value:'03'},
          {text:'SAN ANTONIO', value:'00'},
          {text:'Bajo Herrera', value:'01'},
          ],
        DistritoGolfito: [{text:'Calle Herrera', value:'02'},
          {text:'Cruce Guanacaste', value:'03'},
          {text:'Charcón (parte)', value:'04'},
          {text:'Estero', value:'05'},
          {text:'Río Viejo', value:'06'},
          ],
        DistritosCotoBrus: [{text:'Salitral', value:'07'},
        {text:'Tinamaste', value:'08'},
        {text:'CHIRES (Cristo Rey)', value:'00'},
        {text:'Alto Concepción', value:'01'},
        {text:'Alto Pérez Astúa', value:'02'},
        {text:'Ángeles', value:'03'},
        {text:'Angostura (parte)', value:'04'},
        ],
        DistritosParrita: [{text:'Arenal', value:'05'},
        {text:'Bajo Chires', value:'06'},
        ],
        DistritoCorredores: [{text:'Bajo de Guarumal', value:'07'},
          {text:'Bajo el Rey', value:'08'},
          {text:'Bajo Vega', value:'09'},
          {text:'Cerdas', value:'010'},
          {text:'Fila Aguacate', value:'011'},
          ],
        DistritoGarabito: [{text:'Gamalotillo 1 (Colonia)', value:'012'},
        {text:'Gamalotillo 2 (Gamalotillo)', value:'013'},
        {text:'Gamalotillo 3 (Tierra Fértil)', value:'014'},
        ],
        DistritoLimon: [{text:'Gloria', value:'015'},
          {text:'Guarumal', value:'016'},
          {text:'Guarumalito', value:'017'},
          {text:'Mastatal', value:'018'},
          {text:'Pericos', value:'019'},
          {text:'Río Negro (parte)', value:'020'},
          ],
        DistritoPocosi: [{text:'San Miguel', value:'021'},
          {text:'San Vicente', value:'022'},
          {text:'Santa Rosa', value:'023'},
          {text:'Vista de Mar', value:'024'},
          {text:'Zapatón', value:'025'},
          {text:'TARRAZU', value:'00'},
          {text:'SAN MARCOS', value:'00'},
          {text:'Corea', value:'01'},
          ],
        DistritoSiquirres: [{text:'I Griega', value:'02'},
          {text:'Las Tres Marías', value:'03'},
          {text:'Santa Cecilia', value:'04'},
          {text:'Rodeo', value:'05'},
          {text:'Sitio', value:'06'},
          {text:'Alto Pastora', value:'07'},
          {text:'Bajo Canet', value:'08'},
          ],
        DistritosTalamanca: [{text:'Bajo San Juan', value:'09'},
          {text:'Canet', value:'010'},
          {text:'Cedral (parte)', value:'011'},
          {text:'Guadalupe', value:'012'},
          {text:'Llano Piedra', value:'013'},
          ],
        DistritosMatina: [{text:'San Cayetano', value:'014'},
          {text:'San Guillermo', value:'015'},
          {text:'Sabana (parte)', value:'016'},
          {text:'San Pedro', value:'017'},
          ],
        DistritosGuacimo: [{text:'SAN LORENZO', value:'00'},
          {text:'Alto Guarumal', value:'01'},
          {text:'Alto Portal', value:'02'},
          {text:'Alto Zapotal', value:'03'},
          {text:'Ardilla', value:'04'},
          {text:'Bajo Quebrada Honda', value:'05'},
          ],
        DistritosDesamparados: [{text:'DESAMPARADOS', value:'00'},
          {text:'Altamira', value:'01'},
          {text:'Bellavista', value:'02'},
          {text:'Calle Fallas', value:'03'},
          {text:'Camaquirí', value:'04'},
          {text:'Capilla', value:'05'},
          {text:'Centro de Amigos', value:'06'},
          {text:'Cerámica', value:'07'},
          {text:'Colonia del Sur', value:'08'},
          {text:'Contadores', value:'09'},
          {text:'Cruce', value:'10'},
          {text:'Cucubres', value:'11'},
          {text:'Dorados', value:'12'},
          {text:'Florita', value:'13'},
          {text:'Fortuna', value:'14'},
          {text:'Jardín', value:'15'},
          {text:'Loto', value:'16'},
          {text:'Metrópoli', value:'17'},
          {text:'Monseñor Sanabria', value:'18'},
          {text:'Monteclaro', value:'19'},
          {text:'Palogrande', value:'20'},
          {text:'Pinos', value:'021'},
          {text:'Retoños', value:'022'},
          {text:'Río Jorco', value:'023'},
          {text:'Sabara', value:'024'},
          {text:'San Esteban Rey', value:'025'},
          {text:'San Jerónimo', value:'026'},
          {text:'San José', value:'027'},
          {text:'San Roque', value:'028'},
          {text:'Tauros', value:'029'},
          {text:'Torremolinos', value:'030'},
          {text:'Venecia', value:'031'},
          {text:'Vista Verde', value:'032'},
          {text:'SAN MIGUEL', value:'00'},
          {text:'Ángeles', value:'01'},
          {text:'Capri', value:'02'},
          {text:'Damas Israelitas', value:'03'},
          {text:'Girasol', value:'04'},
          {text:'Higuito', value:'05'},
          {text:'Lindavista', value:'06'},
          {text:'Lomas de Jorco', value:'07'},
          {text:'Llano', value:'08'},
          {text:'Meseguer', value:'09'},
          {text:'Olivos', value:'010'},
          {text:'Orquídeas', value:'011'},
          {text:'Peñascal', value:'012'},
          {text:'Rinconada', value:'013'},
          {text:'Rodillal', value:'014'},
          {text:'Sabanilla', value:'015'},
          {text:'San Martín', value:'016'},
          {text:'Santa Eduvigis', value:'017'},
          {text:'Valverde', value:'018'},
          {text:'Alto Alumbre', value:'019'},
          {text:'Hoyo', value:'020'},
          {text:'Jericó', value:'021'},
          {text:'Manzano', value:'022'},
          {text:'Pacaya', value:'023'},
          {text:'Roblar', value:'024'},
          {text:'Ticalpes (parte)', value:'025'},
          {text:'Calle Naranjos', value:'026'},
          {text:'SAN JUAN DE DIOS', value:'00'},
          {text:'Calabacitas', value:'01'},
          {text:'Calle Común', value:'02'},
          {text:'Cruz Roja', value:'03'},
          {text:'Itaipú', value:'04'},
          {text:'Máquinas', value:'05'},
          {text:'Mota', value:'06'},
          {text:'Novedades', value:'07'},
          {text:'Pedrito Monge', value:'08'},
          {text:'Río', value:'09'},
          {text:'Robles', value:'010'},
          {text:'SAN RAFAEL ARRIBA', value:'00'},
          {text:'Alpino', value:'01'},
          {text:'Arco Iris', value:'02'},
          {text:'Bambú', value:'03'},
          {text:'Berlay', value:'04'},
          {text:'Guaria', value:'05'},
          {text:'Huaso (parte)', value:'06'},
          {text:'Juncales', value:'07'},
          {text:'Lajas', value:'08'},
          {text:'Macarena', value:'09'},
          {text:'Maiquetía', value:'010'},
          {text:'Méndez', value:'011'},
          {text:'SAN ANTONIO', value:'00'},
          {text:'Acacias', value:'01'},
          {text:'Calle Amador', value:'02'},
          {text:'Constancia', value:'03'},
          {text:'Churuca', value:'04'},
          {text:'Huetares', value:'05'},
          {text:'Plazoleta', value:'06'},
          {text:'Pueblo Nuevo', value:'07'},
          {text:'Río Damas', value:'08'},
          {text:'Rotondas', value:'09'},
          {text:'Solar', value:'010'},
          {text:'FRAILES', value:'00'},
          {text:'Bajos de Tarrazú', value:'01'},
          {text:'Bustamante', value:'02'},
          {text:'Santa Elena (parte)', value:'03'},
          {text:'Violeta', value:'04'},
          {text:'PATARRA', value:'00'},
          {text:'Aguacate', value:'01'},
          {text:'Balneario', value:'02'},
          {text:'Don Bosco', value:'03'},
          {text:'Guatuso', value:'04'},
          {text:'Güízaro', value:'05'},
          {text:'Jerusalén', value:'06'},
          {text:'Lince', value:'07'},
          {text:'Mesas', value:'08'},
          {text:'Quebrada Honda', value:'09'},
          {text:'Ticalpes (parte)', value:'010'},
          {text:'SAN CRISTOBAL (San Cristóbal Norte)', value:'00'},
          {text:'Empalme (parte)', value:'01'},
          {text:'Lucha (parte)', value:'02'},
          {text:'San Cristóbal Sur', value:'03'},
          {text:'Sierra', value:'04'},
          {text:'ROSARIO', value:'00'},
          {text:'Bajo Tigre', value:'01'},
          {text:'Chirogres', value:'02'},
          {text:'Guadarrama', value:'03'},
          {text:'Joya', value:'04'},
          {text:'La Fila (parte)', value:'05'},
          {text:'Llano bonito', value:'06'},
          {text:'Quebrada Honda', value:'07'},
          {text:'Trinidad (parte)', value:'08'},
          {text:'DAMAS Fátima)', value:'00'},
          {text:'Cajita', value:'01'},
          {text:'Dorado', value:'02'},
          {text:'Dos Cercas', value:'03'},
          {text:'Fomentera', value:'04'},
          {text:'Nuestra Señora de la Esperanza', value:'05'},
          {text:'San Lorenzo', value:'06'},
          {text:'SAN RAFAEL ABAJO', value:'00'},
          {text:'Ángeles', value:'01'},
          {text:'Autofores', value:'02'},
          {text:'Balboa', value:'03'},
          {text:'Coopelot', value:'04'},
          {text:'Gardenia', value:'05'},
          {text:'Higuerones', value:'06'},
          {text:'Leo', value:'07'},
          {text:'Mónaco', value:'08'},
          {text:'Sagitario', value:'09'},
          {text:'Santa Cecilia', value:'010'},
          {text:'Tejar (parte)', value:'011'},
          {text:'Treviso', value:'012'},
          {text:'Unidas', value:'013'},
          {text:'Valencia', value:'014'},
          {text:'Vizcaya', value:'015'},
          {text:'GRAVILIAS', value:'00'},
          {text:'Cartonera', value:'01'},
          {text:'Claveles', value:'02'},
          {text:'Damasco', value:'03'},
          {text:'Diamante', value:'04'},
          {text:'Esmeraldas', value:'05'},
          {text:'Fortuna', value:'06'},
          {text:'Fortunita', value:'07'},
          {text:'Porvenir', value:'08'},
          {text:'Raya', value:'09'},
          {text:'Riberalta', value:'010'},
          {text:'Villanueva', value:'011'},
          {text:'LOS GUIDO (Guidos)', value:'00'},
          {text:'Letras', value:'01'},
          {text:'Balcón Verde', value:'02'},],
        DistritosEscazu: [
          {text:'ESCAZU', value:'00'},
          {text:'Alto Carrizal', value:'01'},
          {text:'Carrizal (parte)', value:'02'},
          {text:'Faroles', value:'03'},
          {text:'Guapinol', value:'04'},
          {text:'Hulera', value:'05'},
          {text:'Itabas', value:'06'},
          {text:'Jaboncillo', value:'07'},
          {text:'Profesores (parte)', value:'08'},
          {text:'SAN ANTONIO', value:'00'},
          {text:'Avellana', value:'01'},
          {text:'Bebedero', value:'02'},
          {text:'Belo Horizonte (parte)', value:'03'},
          {text:'Carrizal (parte)', value:'04'},
          {text:'Curío', value:'05'},
          {text:'Chirca', value:'06'},
          {text:'Chiverral', value:'07'},
          {text:'Entierrillo', value:'08'},
          {text:'Filtros', value:'09'},
          {text:'Guayabos', value:'010'},
          {text:'Hojablanca', value:'011'},
          {text:'Juan Santana', value:'012'},
          {text:'Lajas', value:'013'},
          {text:'Masilla', value:'014'},
          {text:'Muta', value:'015'},
          {text:'Pedrero', value:'016'},
          {text:'Perú', value:'017'},
          {text:'Profesores (parte)', value:'018'},
          {text:'Sabanillas', value:'019'},
          {text:'Salitrillos', value:'020'},
          {text:'Santa Eduvigis', value:'021'},
          {text:'Santa Teresa', value:'022'},
          {text:'Tejarcillos', value:'023'},
          {text:'Torrotillo', value:'024'},
          {text:'Vista de Oro', value:'025'},
          {text:'SAN RAFAEL', value:'00'},
          {text:'Anonos', value:'01'},
          {text:'Ayala', value:'02'},
          {text:'Bajo Anonos', value:'03'},
          {text:'Bajo Palomas', value:'04'},
          {text:'Belo Horizonte (parte)', value:'05'},
          {text:'Betina', value:'06'},
          {text:'Ceiba', value:'07'},
          {text:'Facio Castro', value:'08'},
          {text:'Guachipelín', value:'09'},
          {text:'Herrera', value:'010'},
          {text:'Laureles', value:'011'},
          {text:'León', value:'012'},
          {text:'Loma Real', value:'013'},
          {text:'Matapalo', value:'014'},
          {text:'Maynard', value:'015'},
          {text:'Mirador', value:'016'},
          {text:'Miravalles', value:'017'},
          {text:'Palermo', value:'018'},
          {text:'Palma de Mallorca', value:'019'},
          {text:'Pinar', value:'020'},
          {text:'Primavera', value:'021'},
          {text:'Quesada', value:'022'},
          {text:'Real de Pereira (parte)', value:'023'},
          {text:'Santa Marta', value:'024'},
          {text:'Tena', value:'025'},
          {text:'Trejos Montealegre', value:'026'},
          {text:'Vista Alegre', value:'027'},],        
        DistritosSanJose: [{text:'SAN JOSE', value:'00'},
          {text:'SAN JOSE', value:'00'},
          {text:'CARMEN', value:'00'},
          {text:'Amón', value:'01'},
          {text:'Aranjuez', value:'02'},
          {text:'California (parte)', value:'03'},
          {text:'Carmen', value:'04'},
          {text:'Empalme', value:'05'},
          {text:'Escalante', value:'06'},
          {text:'Otoya', value:'07'},
          {text:'MERCED', value:'00'},
          {text:'Bajos de la Unión', value:'01'},
          {text:'Claret', value:'02'},
          {text:'Cocacola', value:'03'},
          {text:'Iglesias Flores', value:'04'},
          {text:'Mantica', value:'05'},
          {text:'México', value:'06'},
          {text:'Paso de la Vaca', value:'07'},
          {text:'Pitahaya', value:'08'},
          {text:'HOSPITAL', value:'00'},
          {text:'Almendares', value:'01'},
          {text:'Ángeles', value:'02'},
          {text:'Bolívar', value:'03'},
          {text:'Carit', value:'04'},
          {text:'Colón (parte)', value:'05'},
          {text:'Corazón de Jesús', value:'06'},
          {text:'Cristo Rey', value:'07'},
          {text:'Cuba', value:'08'},
          {text:'Dolorosa (parte)', value:'09'},
          {text:'Merced', value:'010'},
          {text:'Pacífico (parte)', value:'011'},
          {text:'Pinos', value:'012'},
          {text:'Salubridad', value:'013'},
          {text:'San Bosco', value:'014'},
          {text:'San Francisco', value:'015'},
          {text:'Santa Lucía', value:'016'},
          {text:'Silos', value:'017'},
          {text:'CATEDRAL', value:'00'},
          {text:'Bellavista', value:'01'},
          {text:'California (parte)', value:'02'},
          {text:'Carlos María Jiménez', value:'03'},
          {text:'Dolorosa (parte)', value:'04'},
          {text:'Dos Pinos', value:'05'},
          {text:'Francisco Peralta (parte)', value:'06'},
          {text:'González Lahmann', value:'07'},
          {text:'González Víquez', value:'08'},
          {text:'Güell', value:'09'},
          {text:'La Cruz', value:'010'},
          {text:'Lomas de Ocloro', value:'011'},
          {text:'Luján', value:'012'},
          {text:'Milflor', value:'013'},
          {text:'Naciones Unidas', value:'014'},
          {text:'Pacífico (parte)', value:'015'},
          {text:'San Cayetano (parte)', value:'016'},
          {text:'Soledad', value:'017'},
          {text:'Tabacalera', value:'018'},
          {text:'Vasconia', value:'019'},
          {text:'ZAPOTE', value:'00'},
          {text:'Alborada', value:'01'},
          {text:'Calderón Muñoz', value:'02'},
          {text:'Cerrito', value:'03'},
          {text:'Córdoba', value:'04'},
          {text:'Gloria', value:'05'},
          {text:'Jardín', value:'06'},
          {text:'Luisas', value:'07'},
          {text:'Mangos', value:'08'},
          {text:'Montealegre', value:'09'},
          {text:'Moreno Cañas', value:'010'},
          {text:'Quesada Durán', value:'011'},
          {text:'San Dimas', value:'012'},
          {text:'San Gerardo (parte)', value:'013'},
          {text:'Trébol', value:'014'},
          {text:'Ujarrás', value:'015'},
          {text:'Vista Hermosa', value:'016'},
          {text:'Yoses Sur', value:'017'},
          {text:'Zapote (centro)', value:'018'},
          {text:'SAN FRANCISCO DE DOS RIOS', value:'00'},
          {text:'Ahogados (parte)', value:'01'},
          {text:'Bosque', value:'02'},
          {text:'Cabañas', value:'03'},
          {text:'Camelias', value:'04'},
          {text:'Coopeguaria', value:'05'},
          {text:'Faro', value:'06'},
          {text:'Fátima', value:'07'},
          {text:'Hispano', value:'08'},
          {text:'I Griega', value:'09'},
          {text:'Lincoln', value:'010'},
          {text:'Lomas de San Francisco', value:'011'},
          {text:'Maalot', value:'012'},
          {text:'Méndez', value:'013'},
          {text:'Pacífica', value:'014'},
          {text:'San Francisco de Dos Ríos (centro)', value:'015'},
          {text:'Sauces', value:'016'},
          {text:'Saucitos', value:'017'},
          {text:'Zurquí', value:'018'},
          {text:'URUCA', value:'00'},
          {text:'Alborada', value:'01'},
          {text:'Ánimas', value:'02'},
          {text:'Árboles', value:'03'},
          {text:'Bajos del Torres', value:'04'},
          {text:'Carranza', value:'05'},
          {text:'Corazón de Jesús', value:'06'},
          {text:'Cristal', value:'07'},
          {text:'Carvajal Castro', value:'08'},
          {text:'Jardines de Autopista', value:'09'},
          {text:'La Caja', value:'010'},
          {text:'La Carpio', value:'011'},
          {text:'Magnolia', value:'012'},
          {text:'Marimil', value:'013'},
          {text:'Monserrat', value:'014'},
          {text:'Peregrina', value:'015'},
          {text:'Robledal', value:'016'},
          {text:'Rossiter Carballo', value:'017'},
          {text:'Santander', value:'018'},
          {text:'Saturno', value:'019'},
          {text:'Uruca (centro)', value:'020'},
          {text:'Vuelta del Virilla', value:'021'},
          {text:'MATA REDONDA', value:'00'},
          {text:'Américas', value:'01'},
          {text:'Bajo Cañada (parte)', value:'02'},
          {text:'Balcón Verde', value:'03'},
          {text:'Colón (parte)', value:'04'},
          {text:'Del Pino', value:'05'},
          {text:'Holanda', value:'06'},
          {text:'La Luisa', value:'07'},
          {text:'La Salle', value:'08'},
          {text:'Lomalinda', value:'09'},
          {text:'Morenos', value:'010'},
          {text:'Niza', value:'011'},
          {text:'Rancho Luna', value:'012'},
          {text:'Rohrmoser (parte)', value:'013'},
          {text:'Roma', value:'014'},
          {text:'Sabana', value:'015'},
          {text:'Tovar', value:'016'},
          {text:'PAVAS', value:'00'},
          {text:'Alfa', value:'01'},
          {text:'Asturias', value:'02'},
          {text:'Asunción', value:'03'},
          {text:'Bribrí', value:'04'},
          {text:'Favorita Norte', value:'05'},
          {text:'Favorita Sur', value:'06'},
          {text:'Galicia', value:'07'},
          {text:'Geroma', value:'08'},
          {text:'Hispania', value:'09'},
          {text:'Libertad', value:'010'},
          {text:'Lomas del Río', value:'011'},
          {text:'Llanos del Sol', value:'012'},
          {text:'María Reina', value:'013'},
          {text:'Metrópolis', value:'014'},
          {text:'Navarra', value:'015'},
          {text:'Pavas (centro)', value:'016'},
          {text:'Pueblo Nuevo', value:'017'},
          {text:'Residencial del Oeste', value:'018'},
          {text:'Rincón Grande', value:'019'},
          {text:'Rohrmoser (parte)', value:'020'},
          {text:'Rotonda', value:'021'},
          {text:'San Pedro', value:'022'},
          {text:'Santa Bárbara', value:'023'},
          {text:'Santa Catalina', value:'024'},
          {text:'Santa Fé', value:'025'},
          {text:'Triángulo', value:'026'},
          {text:'Villa Esperanza', value:'027'},
          {text:'HATILLO', value:'00'},
          {text:'Bajo Cañada (parte)', value:'01'},
          {text:'Belgrano', value:'02'},
          {text:'Hatillo Centro', value:'03'},
          {text:'Hatillo 1', value:'04'},
          {text:'Hatillo 2', value:'05'},
          {text:'Hatillo 3', value:'06'},
          {text:'Hatillo 4', value:'07'},
          {text:'Hatillo 5', value:'08'},
          {text:'Hatillo 6', value:'09'},
          {text:'Hatillo 7', value:'010'},
          {text:'Hatillo 8', value:'011'},
          {text:'Quince de Setiembre', value:'012'},
          {text:'Sagrada Familia', value:'013'},
          {text:'Tiribí', value:'014'},
          {text:'Topacio', value:'015'},
          {text:'Veinticinco de Julio', value:'016'},
          {text:'Vivienda en Marcha', value:'017'},
          {text:'SAN SEBASTIAN', value:'00'},
          {text:'Bengala', value:'01'},
          {text:'Bilbao', value:'02'},
          {text:'Cañada del Sur', value:'03'},
          {text:'Carmen', value:'04'},
          {text:'Cascajal', value:'05'},
          {text:'Cerro Azul', value:'06'},
          {text:'Colombari', value:'07'},
          {text:'Domingo Savio', value:'08'},
          {text:'Guacamaya', value:'09'},
          {text:'Jazmín', value:'010'},
          {text:'Hogar Propio', value:'011'},
          {text:'Kennedy', value:'012'},
          {text:'López Mateos', value:'013'},
          {text:'Luna Park', value:'014'},
          {text:'Martínez', value:'015'},
          {text:'Mojados', value:'016'},
          {text:'Mongito', value:'017'},
          {text:'Monte Azúl', value:'018'},
          {text:'Musmanni', value:'019'},
          {text:'Paso Ancho', value:'020'},
          {text:'Presidentes', value:'021'},
          {text:'San Cayetano (parte)', value:'022'},
          {text:'San Martín', value:'023'},
          {text:'San Sebastián (centro)', value:'024'},
          {text:'Santa Rosa', value:'025'},
          {text:'Seminario', value:'026'},
          {text:'Sorobarú', value:'027'},],


        Distritos: [],
        Cantones:[],
        CantonSanJose: [
          {text:'San José', value:'01'},
          {text:'Escazú', value:'02'},
          {text:'Desamparados', value:'03'},
          {text:'Puriscal', value:'04'},
          {text:'Tarrazú', value:'05'},
          {text:'Aserrí', value:'06'},
          {text:'Mora', value:'07'},
          {text:'Goicochea', value:'08'},
          {text:'Santa Ana', value:'09'},
          {text:'Alajuelita', value:'10'},
          {text:'Vásquez de Coronado', value:'11'},
          {text:'Acosta', value:'12'},
          {text:'Tibás', value:'13'},
          {text:'Moravia', value:'14'},
          {text:'Montes de Oca', value:'15'},
          {text:'Turrubares', value:'16'},
          {text:'Dota', value:'17'},
          {text:'Curridabat', value:'18'},
          {text:'Pérez Zeledón', value:'19'},
          {text:'León Cortes', value:'20'},],
        CantonAlajuela:[
          {text:'ALAJUELA', value:'01'},
          {text:'SAN RAMON', value:'02'},
          {text:'GRECIA', value:'03'},
          {text:'SAN MATEO', value:'04'},
          {text:'ATENAS', value:'05'},
          {text:'NARANJO', value:'06'},
          {text:'PALMARES', value:'07'},
          {text:'POAS', value:'08'},
          {text:'OROTINA', value:'09'},
          {text:'SAN CARLOS', value:'10'},
          {text:'ALFARO RUIZ', value:'11'},
          {text:'VALVERDE VEGA', value:'12'},
          {text:'UPALA', value:'13'},
          {text:'LOS CHILES', value:'14'},
          {text:'GUATUSO', value:'15'},
          {text:'RIO CUARTO', value:'16'},],
        CantonCartago: [
          {text:'CARTAGO', value:'01'},
          {text:'PARAISO', value:'02'},
          {text:'LA UNION', value:'03'},
          {text:'JIMENEZ', value:'04'},
          {text:'TURRIALBA', value:'05'},
          {text:'ALVARADO', value:'06'},
          {text:'OREAMUNO', value:'07'},
          {text:'EL GUARCO', value:'08'},],
        CantonHeredia: [
          {text:'HEREDIA', value:'01'},
          {text:'BARVA', value:'02'},
          {text:'SANTO DOMINGO', value:'03'},
          {text:'SANTA BARBARA', value:'04'},
          {text:'SAN RAFAEL', value:'05'},
          {text:'SAN ISIDRO', value:'06'},
          {text:'BELEN', value:'07'},
          {text:'FLORES', value:'08'},
          {text:'SAN PABLO', value:'09'},
          {text:'SARAPIQUI', value:'10'},],
        CantonGuanacaste: [
          {text:'LIBERIA', value:'01'},
          {text:'NICOYA', value:'02'},
          {text:'SANTA CRUZ', value:'03'},
          {text:'BAGACES', value:'04'},
          {text:'CARRILLO', value:'05'},
          {text:'CAÑAS', value:'06'},
          {text:'ABANGARES', value:'07'},
          {text:'TILARAN', value:'08'},
          {text:'NANDAYURE', value:'09'},
          {text:'LA CRUZ', value:'10'},
          {text:'HOJANCHA', value:'11'},],
        CantonPuntarenas:[
          {text:'PUNTARENAS', value:'01'},
          {text:'ESPARZA', value:'02'},
          {text:'BUENOS AIRES', value:'03'},
          {text:'MONTES DE ORO', value:'04'},
          {text:'OSA', value:'05'},
          {text:'AGUIRRE', value:'06'},
          {text:'GOLFITO', value:'07'},
          {text:'COTO BRUS', value:'08'},
          {text:'PARRITA', value:'09'},
          {text:'CORREDORES', value:'10'},
          {text:'GARABITO', value:'11'},],
        CantonLimon:[
          {text:'LIMON', value:'01'},
          {text:'POCOCI', value:'02'},
          {text:'SIQUIRRES', value:'03'},
          {text:'TALAMANCA', value:'04'},
          {text:'MATINA', value:'05'},
          {text:'GUACIMO', value:'06'},],
      }
    },
    methods: {
       buscarCanton(){
         if(this.Provincia == 1){
           this.Cantones = this.CantonSanJose
           return
         } else 
         if(this.Provincia == 2){
           this.Cantones = this.CantonAlajuela
           return
         } else 
         if(this.Provincia == 3){
           this.Cantones = this.CantonCartago
           return
         } else 
         if(this.Provincia == 4){
           this.Cantones = this.CantonHeredia
           return
         } else 
         if(this.Provincia == 5){
           this.Cantones = this.CantonGuanacaste
           return
         } else 
         if(this.Provincia == 6){
           this.Cantones = this.CantonPuntarenas
           return
         } else 
         if(this.Provincia == 7){
           this.Cantones = this.CantonLimon
           return
         } 
       },
    buscarDistrito(){

      if(this.Canton == "01"){
        this.Distritos = this.DistritosSanJose;
        return
      }  else
      if(this.Canton == "02"){
        this.Distritos = this.DistritosEscazu;
      }else
      if(this.Canton == "03"){
        this.Distritos = this.DistritosDesamparados;
        return
      }  else
      if(this.Canton == "04"){
        this.Distritos = this.DistritoPuriscal;
        return
      }  else
      if(this.Canton == "05"){
        this.Distritos = this.DistritoTarrazu;
        return
      }  else
      if(this.Canton == "06"){
        this.Distritos = this.DistritoAserri;
        return
      }  if(this.Canton == "07"){
        this.Distritos = this.DistritoMora;
        return
      }  else
      if(this.Canton == "08"){
        this.Distritos = this.DistritoGoicocheda;
        return
      }  else
      if(this.Canton == "09"){
        this.Distritos = this.DistritoSantaAna;
        return
      }  else
      if(this.Canton == "10"){
        this.Distritos = this.DistritoAlajuelita;
        return
      }  else
      if(this.Canton == "11"){
        this.Distritos = this.DistritoVasquesCoronado;
        return
      }  else
      if(this.Canton == "12"){
        this.Distritos = this.DistritoAcosta;
        return
      }  else
      if(this.Canton == "13"){
        this.Distritos = this.DistritosTibas;
        return
      }  else
      if(this.Canton == "14"){
        this.Distritos = this.DistritoMoravia;
        return
      }  else
      if(this.Canton == "15"){
        this.Distritos = this.DistritoMontesdeOca;
        return
      }  else
      if(this.Canton == "16"){
        this.Distritos = this.DistritoTurrubares;
        return
      }  else
      if(this.Canton == "17"){
        this.Distritos = this.DistritoDota;
        return
      }  else
      if(this.Canton == "18"){
        this.Distritos = this.DistritoCurridabat;
        return
      }  else
      if(this.Canton == "19"){
        this.Distritos = this.DistritoPerezZeledon;
        return
      }  else
      if(this.Canton == "20"){
        this.Distritos = this.DistritoPerezZeledon;
        return
      }  

       },
       abrirEfectivo(){
      this.dialogEfectivo = true;
      this.efectivoPagar = this.totalPagar;
    },
    abrirTarjeta(){
      this.dialogTarjeta = true;
      this.tarjetaPagar = this.totalPagar;
    },
    abrirDeposito(){
      this.dialogDeposito = true;
      this.depositoPagar = this.totalPagar;
    },
    abrirSinpe(){
      this.dialogSinpe = true;
      this.depositoPagar = this.totalPagar;
    },
    
    abrirCheque(){
      this.dialogCheque = true;
      this.depositoPagar = this.totalPagar;
    },
    limpiarPago()
    {
      this.pago = '';
      this.efectivoPagar = 0;
      this.tarjetaPagar = 0;
      this.depositoPagar = 0;
      this.dialogDeposito = false;
      this.dialogEfectivo = false;
      this.dialogTarjeta = false;
      this.dialogCheque = false;
      this.dialogSinpe = false;
    },
    PagoTarjeta(){
      this.tipoPago = 'TARJETA';
      this.dialogTarjeta = false;
      this.vueltoPagar = ((parseInt(this.tarjetaPagar) + parseInt(this.efectivoPagar) + parseInt(this.depositoPagar)) - parseInt(this.totalPagar));
      this.detalleFactura = this.banco +' Comprobante: '+ this.comprobantePago + ' '+ this.señas;
    },
    PagoSinpe(){
      this.tipoPago = 'DEPOSITO';
      this.dialogSinpe = false;
      this.vueltoPagar = ((parseInt(this.tarjetaPagar) + parseInt(this.efectivoPagar) + parseInt(this.depositoPagar)) - parseInt(this.totalPagar));
      this.detalleFactura = 'Comprobante de SINPE Movil: '+ this.comprobantePago + ' '+ this.señas;
    },
    PagoDeposito(){
      this.tipoPago = 'TRANSFERENCIA';
      this.dialogDeposito = false;
      this.vueltoPagar = ((parseInt(this.tarjetaPagar) + parseInt(this.efectivoPagar) + parseInt(this.depositoPagar)) - parseInt(this.totalPagar));
      this.detalleFactura = this.banco +' Comprobante Transferencia: '+ this.comprobantePago + ' '+ this.señas;

    },
    AplicarCupon(){

      if(this.cupon == 'COVID19' && this.plan == 1){
        this.CantidadFacturas = 20;
        this.Instalacion = 20000;
        this.totalPagar = 20000;
        return
      } else
      if(this.cupon == 'PROTEGER' ){
        this.CantidadFacturas = this.CantidadFacturas + 40;
        this.totalPagar =  this.totalPagar - 10000;
        return
      } 
      alert("Cupón no valido")

    },
    PagoPlan(){

      if (this.plan == 1){
        this.Instalacion = 30000;
        this.totalPagar = 30000;
        return
      }else
      if (this.plan == 15000)
      {
        this.CantidadFacturas = 100;
        this.NombredelPlan = 'Estandar';
        this.totalPagar = 15000;
        return
      } else

      if (this.plan == 30000)
      {
        this.CantidadFacturas = 999999999;
        this.NombredelPlan = 'Profesional';
        this.totalPagar = 30000;
        return
      }
      else if (this.plan == 60000)
      {
        this.CantidadFacturas = 999999999;
        this.NombredelPlan = 'Elite';
        this.totalPagar = 60000;
        return
      }
      this.totalPagar = this.plan;

    },
    Afiliarse(){
          let self=this;
         
          

          let header={"Authorization" : "Bearer "};
                let configuracion= {headers : header};
                axios.post('api/Usuarios/CrearLocalidad',{
                      'NombreUsuario':this.nombreUsuario,
                      'CedulaUsuario':this.cedulaUsuario,
                      'TipoCedulaUsuario': this.tipo_documento,
                      'TelefonoUsuario': this.telefonoUsuario,
                      'CorreoUsuario': this.correoUsuario,
                      'Password': this.password,
                      'Localidad': this.nombreLocalidad,
                      'Direccion': this.Direccion,
                      'RazonSocial': this.RazonSocial,
                      'Regimen': this.Regimen,
                      'PuntoVenta': this.PuntoVenta,
                      'CasaMatriz': this.CasaMatriz,
                      'TipoCedulaLocalidad': this.TipoCedulaLocalidad,
                      'TelefonoLocalidad': this.TelefonoLocalidad,
                      'CedulaLocalidad': this.CedulaLocalidad,
                      'Provincia': this.Provincia,
                      'CantidadFacturas': this.CantidadFacturas,
                      'Canton': this.Canton,
                      'Distrito': this.dis2,
                      'CorreoLocalidad': this.CorreoLocalidad,
                      'UsuarioATV': this.UsuarioATV,
                      'ContraseñaATV': this.ContraseñaATV,
                      'PIN': this.Pin,
                      'TipoCambio': this.TipoCambio,
                      'CodigoActividad': this.CodigoActividad,
                      'NoActividadEconomica': this.NoActividadEconomica,
                      'TipoPago': this.tipoPago,
                      'DetallePago': this.detalle,
                      'TotalPago': this.totalPagar,
                      'NombredelPlan': this.NombredelPlan,
                      
                },configuracion).then(function (response) {
                    alert("Felicidades, haz creado tu cuenta un representante se podrá en contacto con tigo en breve para que empieces a utilizar Noah");
                    self.$router.push({ name: 'login' });
                  })
                  .catch(function (error) {
                    alert("Error, favor revisar datos o solicite ayuda al 4070-1889");
                })
    },

    },
  }
</script>

<style scoped>
.Titulo {
        text-align: center;
        font-size: 42px;
        font-family: "Roboto","ABeeZee", Helvetica, Times, serif;
    }
</style>