<template>
	<v-container :style="{ backgroundColor: '#F2F4F5' }" fluid>
		<v-row v-if="esAdministrador || esSupervisor || esBoss || esTaller" align="center" justify="center">
			<v-btn v-if="esMensajero" to="/despachomensajero" x-large class="mt-4" color="primary">Ver Ordenes</v-btn>
			<v-btn v-if="esBodega" to="/inventarioasignado" class="mt-4" x-large color="primary">Control de Activos</v-btn>
				<template v-if="loading">
          <v-col v-for="n in 6" :key="`skeleton-${n}`" cols="12" sm="4" md="2">
            <v-skeleton-loader type="card" class="mx-auto my-2" />
          </v-col>
        </template>
				<!-- Elementos principales cuando carga -->
        <template v-else>
          <v-col v-for="(card, index) in cards" :key="index" cols="12" sm="4" md="2">
            <v-card color="white" width="" class="mx-auto my-2 elevation-1 rounded-lg" dark rounded>
              <v-card-text style="fontFamily: 'Poppins'" class="text-center">
                <v-icon size="40" style="color: #3e4356">{{ card.icon }}</v-icon>
                <div class="font-weight-bold" style="color: #3e4356; font-size: 20px;">{{ card.value }}</div>
                <div style="color: #75b264; font-size:11px;">{{ card.title }}</div>
              </v-card-text>
            </v-card>
          </v-col>
        </template>
        
			<v-col v-if="esAdministrador || esSupervisor || esBoss || esTaller" cols="12" md="6" lg="4" xs="6">
				<v-card height="320px">
          <div class="pa-1" style="height: 100%;">
            <canvas id="ventasHoraChart" style="width: 100%; height: 100%;"></canvas>
          </div>
        </v-card>
			</v-col>

			<v-col v-if="esAdministrador || esSupervisor || esBoss || esTaller" cols="12" md="6" lg="4" xs="6">
				<v-card height="320px">
          <div class="pa-1" style="height: 100%;">
            <canvas id="ventasFamiliaChart" ></canvas>
          </div>
        </v-card>
			</v-col>
			<v-col v-if="esAdministrador || esSupervisor || esBoss || esTaller" cols="12" md="6" lg="4" >
				<v-card class="mb-1" outlined :loading="loading" elevation="5">
					<div>
						<canvas id="paymentMethodsChart"></canvas>
					</div>
				</v-card>
				<v-card
				class="mb-1"
				height="160px"
				outlined
				elevation="5"
				:loading="loading"
				style="font-family: 'Poppins'"
				>
				<v-row>
					<!-- Primera columna: Token y Copiar Código -->
					<v-col cols="6">
					<div class="font-weight-bold" 
						style="color: #3e4356; font-size: 20px; cursor: pointer; font-family: 'Poppins'"
						@click="copyTokenToClipboard">
						Token {{ Token }}
						<p style="color: black; font-size: 10px;">Copiar Código</p>
					</div>
					<p> Expiración: {{ timeLeft }} </p>
					</v-col>

					<!-- Segunda columna: Proformas Pendientes -->
					<v-col cols="6">
					<div>
						<strong class="mb-1" style="font-size: 16px; color: #3e4356;">Proformas Pendientes</strong>
						<div v-for="det in VentasOnline" :key="det.valor">
						<v-list-item-title style="font-size: 15px;" class="mb-1">
							{{ det.etiqueta }}: {{ det.valor }}
						</v-list-item-title>
						</div>
					</div>
					</v-col>
				</v-row>
				</v-card>

				
			</v-col>
			<!-- Tarjeta 1: Ventas y Compras -->
			<v-col v-if="esAdministrador || esSupervisor || esBoss || esTaller" cols="12" md="6" lg="6" sm="12">
			<v-card :loading="loading" elevation="5" class="d-flex flex-column justify-space-between" style="height: 520px;">
				<div class="pa-2" style="flex-grow: 1;">
				<canvas id="ventasComprasChart" style="width: 100%; height: 100%;"></canvas>
				</div>
				<v-card-text class="pt-0">
				<div>  
					<b>Promedio ventas mensuales</b>: 
					{{ mediaVentas ? mediaVentas.toLocaleString('en-US', { maximumFractionDigits: 0 }).replace(/\B(?=(\d{3})+(?!\d))/g, '.') : 'N/A' }} 
				</div>
				<div>  
					<b>Total de Ventas</b>: 
					{{ totalVentas ? totalVentas.toLocaleString('en-US', { maximumFractionDigits: 0 }).replace(/\B(?=(\d{3})+(?!\d))/g, '.') : 'N/A' }} 
				</div>
				<div>  
					<b>Total de Compras</b>: 
					{{ totalCompras ? totalCompras.toLocaleString('en-US', { maximumFractionDigits: 0 }).replace(/\B(?=(\d{3})+(?!\d))/g, '.') : 'N/A' }} 
				</div>
				<div>  
					<b>Utilidad de ventas</b>: 
					{{ (totalVentas && totalCompras) ? (totalVentas - totalCompras).toLocaleString('en-US', { maximumFractionDigits: 0 }).replace(/\B(?=(\d{3})+(?!\d))/g, '.') : 'N/A' }} 
				</div>
				<v-divider class="my-2"></v-divider>
				<div class="subheading font-weight-light grey--text">
					<v-btn small color="primary" to="/repventas">Ir al reporte completo</v-btn>
				</div>
				</v-card-text>
			</v-card>
		</v-col>

		<!-- Tarjeta 2: Filtros y otro gráfico -->
		<v-col v-if="esAdministrador || esSupervisor || esBoss || esTaller" cols="12" md="6" sm="12">
		<v-card :loading="loading" elevation="5" class="d-flex flex-column justify-space-between" style="height: 520px;">
			<v-row class="pa-4">
			<v-col cols="4">
				<v-select v-model="localidad" :items="Localidades" label="Localidad" dense @change="AccionarCambioLocalidad()" /> 
			</v-col>
			<v-col cols="4">
				<v-text-field v-model="fechaInicio" type="date" label="Fecha Inicio" dense @input="AccionarCambioLocalidad()"/> 
			</v-col>
			<v-col cols="4">
				<v-text-field v-model="fechaFin" type="date" label="Fecha Fin" dense @input="AccionarCambioLocalidad()" /> 
			</v-col>
			</v-row>
			<div class="pa-2" style="flex-grow: 1;">
			<canvas id="myChart" ></canvas>
			</div>
			<v-card-text class="pt-0">
			<div class="subheading font-weight-light grey--text">
				<v-btn small color="primary" to="/repxproducto">Ir al reporte completo</v-btn>
			</div>
			<v-divider class="my-2"></v-divider>
			<span class="caption grey--text font-weight-light">Consulta de registro hace 5 minutos</span>
			</v-card-text>
		</v-card>
		</v-col>

		<v-col v-if="esAdministrador || esSupervisor || esBoss || esTaller"  cols="12" sm="12">
			<v-card
				class="mx-2"
				style="width: 100%; fontFamily: 'Poppins'"
				outlined
				elevation="5"
				:loading="loading"
			>
				<b>Facturación de sucursales</b>
				<v-list-item three-line>
					<div v-if="Datos.length === 0"> Aún no hay ventas :( </div>
				<div v-for="det in Datos" :key="det.valor">
					<v-list-item-content >
						<div class=" mb-1">
							{{det.Localidad}}
						</div>
						<v-list-item-title class="mb-1">
						<p style="font-size:15px;">
						{{Moneda(det.Moneda)}} {{det.valor.toLocaleString({maximumFractionDigits: 0}).replace(/\B(?=(\d{3})+(?!\d))/g, '.')}}<br>
						Utilidad  <br>
						{{Moneda(det.Moneda)}} {{det.utilidad.toLocaleString({maximumFractionDigits: 0}).replace(/\B(?=(\d{3})+(?!\d))/g, '.')}} </p>
						</v-list-item-title>
						
					</v-list-item-content>
				</div>
				
				<v-list-item-avatar
					size="80"
					color="indigo"
				><v-icon x-large>mdi-cash-register </v-icon> </v-list-item-avatar>
				</v-list-item>
				
				<v-card-actions>
				<v-btn
					outlined
					rounded
					text
					to="/repventas"
				>
					Reporte detallado
				</v-btn>
				</v-card-actions>
				</v-card>
		</v-col>
			
		</v-row>
	</v-container>
</template>
<script>

import Chart from 'chart.js'
import axios from 'axios'

export default {
    data() {
		return {
      mesesValores: null,
      fechaInicio: new Date().toISOString().substr(0, 10), // Formato ISO 8601
      fechaFin: new Date().toISOString().substr(0, 10), // Formato ISO 8601
      mesesValoresCompras : null,
      nombreMesesCompras: [],
      totalMesesCompras: [],
      VentasHoyWeb33:[],
      nombreMeses: [],
      totalMeses: [],
      loading:true,
      Top10: [],
      DescTop: [],
      infoTop: [],
      localidad:'',
      Ventashoy: [],
      menu:null,
      VentasOnline: [],
      totalVentas:0,
      mediaVentas:0,
      totalCompras:0,
      password: '',
      cantidadClientes: 0,
      cantidadEmpleados: 0,
      ticketPromedioHoy: 0,
      Localidades:[],
      Token: null,
      timeLeft:0,
      timer:null,
      horas:[],
      Datos:  [],
      TablaInfo:[],
      TablaInfo2:[],
      VentasTarjeta : [],
      Mensajeros:[],
      cards: [],
      familias:null,
      ventas:null,
      myChart: null, // Almacena el gráfico aquí
      };
    },
  	computed: {
		imageHeight () {
			switch (this.$vuetify.breakpoint.name) {
			case 'xs': return '2300px';
			case 'sm': return '1000px';
			case 'md': return '1200px';
			case 'lg': return '1200px';
			case 'xl': return '850px';
			default: return '1200px'; // Valor por defecto
			}
		},
		minHeight () {
			const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh';
			return `calc(${height} - ${this.$vuetify.application.top}px)`;
		},
		userName() {
      return this.$store.state.usuario?.nombre || '';
    },
		esTaller(){
			return this.$store.state.usuario && this.$store.state.usuario.rol === 'Gerente Taller';
		},
		esAdministrador(){
			return this.$store.state.usuario && this.$store.state.usuario.rol === 'Administrador';
		},
		esSupervisor(){
			return this.$store.state.usuario && this.$store.state.usuario.rol === 'Supervisor';
		},
		esBodega(){
			return this.$store.state.usuario && this.$store.state.usuario.rol === 'Bodegas';
		},
		esVendedor(){
			return this.$store.state.usuario && this.$store.state.usuario.rol === 'Vendedor';
		},
		esCliente(){
			return this.$store.state.usuario && this.$store.state.usuario.rol === 'Cliente';
		},
		esMensajero(){
			return this.$store.state.usuario && this.$store.state.usuario.rol === 'Mensajero';
		},
		esBoss(){
			return this.$store.state.usuario && this.$store.state.usuario.rol === 'King Boss';
		},
	},
	mounted(){
		this.listarAsociadas();
		this.getComprasMasVendidos();
		this.getProductosMasVendidos();
		this.getProformas();
		this.getTop10();
		this.loadToken();
		this.renderizarGrafico();
		this.obtenerResumenDiario();
		this.loadVentasPorHora();
	},

	
	beforeDestroy() {
		if (this.timer) {
			clearInterval(this.timer);
		}
	},
	methods:{
    Moneda(id){
			if(id === 'USD'){
				return "$";
			} 
			return "₡";
		},
    getTop10(){
      const me=this;
      const header={"Authorization" : "Bearer " + this.$store.state.token};
      const configuracion= {headers : header};
      axios.get('api/VentasXProductoes/ProductosHoy/'+this.localidad,configuracion).then(function(response){
          me.Top10=response.data;
          me.loadTop10();
      }).catch(function(error){
          me.Mensaje = " Sin TOP 10 , gráfico no listado "+error;
          me.snackbar = true;
      });    
    },
    loadTop10(){
      const me = this;
      me.Top10.forEach(function(x) {
          me.infoTop.push(x.cantidad);
          me.DescTop.push(x.descripcion);
      });
      const ctx = document.getElementById("myChart");
      if (this.myChart) {
          this.myChart.destroy(); // Destruye el gráfico existente antes de crear uno nuevo
      }
      this.myChart = new Chart(ctx, {
          type: 'doughnut',
          data: {
        responsive: true,
        labels: me.DescTop,
        datasets: [{
          label: 'Top 10 Productos',
          data: me.infoTop,
          hoverOffset: 4,
          backgroundColor: [
              'rgba(255,99,132,1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)', 'rgba(153, 102, 255, 1)', 'rgba(255, 159, 64, 1)',
              'rgba(255,99,132,1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)', 'rgba(153, 102, 255, 1)', 'rgba(255, 159, 64, 1)'
          ],
          borderColor: 'rgba(255, 255, 255, 1)',
          borderWidth: 4,
          }]
        },
        options: {
         responsive: true,
         title: {
            display: true,
            text: 'Top 12 en 12 días',
            fontSize: 18,
            fontFamily: 'Poppins'
         },
         legend: {
            labels: {
               fontColor: 'black',
               fontFamily: 'Poppins'
            }
         }
        }
      });
    },
    async getProformas(){
      const me=this;
			let resp = [];
      const header={"Authorization" : "Bearer " + this.$store.state.token};
      const configuracion= {headers : header};
        await axios.get('api/RepVentasVistas/VentasProformas/'+this.localidad,configuracion).then(function(response){
          resp=response.data;
          me.VentasOnline = resp;
				  // Comprobar si la respuesta es un arreglo vacío o un string vacío
					if (Array.isArray(me.VentasOnline) && me.VentasOnline.length === 0 || me.VentasOnline === '') {
					me.cards.push({
						title: 'Proformas',
						value: '0',
						color: 'green lighten-5',
						icon: 'mdi-file-chart',
						textColor: 'green darken-4',
					});
				}
				me.cards.push({
					title: 'PROFORMAS '+me.VentasOnline[0].etiqueta,
					value:  me.VentasOnline[0].valor,
					color: 'green lighten-5',
					icon: 'mdi-file-chart',
					textColor: 'green darken-4',
				});
				if(me.esBoss){
					me.consultarRegistros();
				}
            }).catch(function(error){
                me.Mensaje = " Sin Compras realizadas, gráfico no listado: "+error;
                me.snackbar = true;
            });    
    },
    AccionarCambioLocalidad(){
			this.loading = true;
			this.cards = [];
			this.totalMeses= [];
			this.nombreMeses=[];
			this.totalMesesCompras=[];
			this.Top10 = [];
			this.infoTop =[];
			this.Datos= [];
			this.DescTop = [];
			this.getComprasMasVendidos();
			this.getProductosMasVendidos();
			this.getProformas();
			this.getTop10();
			this.obtenerResumenDiario();
			this.renderizarGrafico();
			this.getVentasHoy();
			this.loadVentasPorHora();
		},	
    getProductosMasVendidos(){
      const me=this;
      const header={"Authorization" : "Bearer " + this.$store.state.token};
      const configuracion= {headers : header};
      axios.get('api/RepVentasVistas/VentasMes12/'+this.localidad,configuracion).then(function(response){
          me.mesesValores=response.data;
          me.loadProductosMasVendidos();
      }).catch(function(error){
          me.Mensaje = " Sin Compras realizadas, gráfico no listado: "+error;
          me.snackbar = true;
      });    
    },
    loadProductosMasVendidos(){
      const me=this;
      let mesn='';
			let sumaTotal = 0; // Variable para almacenar la suma de las ventas
            me.mesesValores.forEach(function(x){
            switch(parseInt(x.etiqueta)){
                case 1:
                mesn='Enero';
                break;
                case 2:
                mesn='Feb';
                break;
                case 3:
                mesn='Marzo';
                break;
                case 4:
                mesn='Abril';
                break;
                case 5:
                mesn='Mayo';
                break;
                case 6:
                mesn='Junio';
                break;
                case 7:
                mesn='Julio';
                break;
                case 8:
                mesn='Ago';
                break;
                case 9:
                mesn='Sept';
                break;
                case 10:
                mesn='Oct';
                break;
                case 11:
                mesn='Nov' ;
                break;
                case 12:
                mesn='Dic';
                break;
                default:
                mesn='Error';
            }
            me.nombreMeses.push(mesn);
            me.totalMeses.push(x.valor);
			sumaTotal += x.valor; // Sumamos el valor de las ventas al total
            });
			// Calculamos la media de las ventas
			const mediaVentas = sumaTotal / me.totalMeses.length;
			this.cards.push({
					title: 'Mensualidad promedio',
					value:  mediaVentas.toLocaleString('en-US', {style: 'currency', currency: 'CRC'}),
					color: 'deep-purple lighten-5',
					icon: 'mdi-currency-usd',
					textColor:'deep-purple darken-4',
				});
			// Asignamos el total y la media a las propiedades del componente
			me.totalVentas = sumaTotal;
			
			me.mediaVentas = mediaVentas;
			
			// Si necesitas redondear la media puedes usar:
			me.mediaVentas = Math.round(mediaVentas * 100) / 100; // Esto redondea a dos decimales

    },
    getComprasMasVendidos(){
      const me=this;
      const header={"Authorization" : "Bearer " + this.$store.state.token};
      const configuracion= {headers : header};
      axios.get('api/RepVentasVistas/ComprasMes12/'+this.localidad,configuracion).then(function(response){
          me.mesesValoresCompras=response.data;
          me.loadComprasMasVendidos();
      }).catch(function(error){
          me.Mensaje = " Sin Compras realizadas, gráfico no listado "+error;
          me.snackbar = true;
      });    
    },
    loadComprasMasVendidos(){
      const me=this;
      let mesn='';
			let sumaTotal = 0; // Variable para almacenar la suma de las comrpas
            me.mesesValoresCompras.forEach(function(x){
            switch(parseInt(x.etiqueta)){
                case 1:
                mesn='Enero';
                break;
                case 2:
                mesn='Feb';
                break;
                case 3:
                mesn='Mar';
                break;
                case 4:
                mesn='Abril';
                break;
                case 5:
                mesn='Mayo';
                break;
                case 6:
                mesn='Jun';
                break;
                case 7:
                mesn='Jul';
                break;
                case 8:
                mesn='Ago';
                break;
                case 9:
                mesn='Sept';
                break;
                case 10:
                mesn='Oct';
                break;
                case 11:
                mesn='Nov';
                break;
                case 12:
                mesn='Dic';
                break;
                default:
                mesn='Error';
            }
            me.nombreMesesCompras.push(mesn);
            me.totalMesesCompras.push(x.valor);
			        sumaTotal += x.valor; // Sumamos el valor de las compras al total
            });
				// Asignamos el total y la media a las propiedades del componente
			me.totalCompras = sumaTotal;
    },
		copyTokenToClipboard() {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(this.Token)
          .then(() => alert('Token copiado al portapapeles!'))
          .catch(() => alert('Error copiando el token'));
      }
    },
	renderizarGrafico() {
		if (!Array.isArray(this.totalMeses) || !Array.isArray(this.totalMesesCompras)) {
			console.error('Datos inválidos: totalMeses o totalMesesCompras no son arrays.');
			return;
		}
		if (this.totalMeses.length !== this.totalMesesCompras.length) {
			console.warn('Atención: totalMeses y totalMesesCompras no tienen el mismo tamaño.');
		}

		// Destruir el gráfico anterior si existe para evitar conflictos
		if (this.ventasComprasChart) {
			this.ventasComprasChart.destroy();
		}

		// Crea el gráfico con la configuración ajustada para Chart.js v3+
		this.ventasComprasChart = new Chart(document.getElementById('ventasComprasChart').getContext('2d'), {
			type: 'line',
			data: {
				labels: this.nombreMeses,
				datasets: [{
					label: 'Ingresos',
					data: this.totalMeses,
					borderColor: 'rgba(31, 65, 104)',
					borderWidth: 2,
					tension: 0.4, // Añadir suavizado a la línea para evitar problemas de punto de intersección
					fill: false
				}, {
					label: 'Egresos',
					data: this.totalMesesCompras,
					borderColor: 'rgba(255, 23, 68)',
					borderWidth: 2,
					tension: 0.4, // Añadir suavizado a la línea para evitar problemas de punto de intersección
					fill: false
				}]
			},
			options: {
				responsive: true,
				maintainAspectRatio: false, // Asegurar que el gráfico se adapte al contenedor
				plugins: {
					title: {
						display: true,
						text: 'Resumen ejecutivo últimos 12 meses'
					},
					tooltip: {
						mode: 'index',
						intersect: false
					}
				},
				interaction: {
					mode: 'nearest',  // Cambiar de 'index' a 'nearest' para evitar el error 'skip'
					intersect: true  // Cambiar a 'true' para evitar problemas de selección de elementos nulos
				},
				scales: {
					y: {
						beginAtZero: true,
						grid: {
							display: true
						},
						ticks: {
							precision: 0
						}
					},
					x: {
						grid: {
							display: false
						}
					}
				}
			}
		});
	},


	startTimer() {
			if (this.timer) clearInterval(this.timer);
			this.timer = setInterval(() => {
				if (this.timeLeft > 0) {
					this.timeLeft -= 1;
				} else {
					clearInterval(this.timer);
					this.loadToken();
				}
			}, 1000);
		},
		loadToken() {
			axios.get('/token')
				.then(response => {
					this.Token = response.data.token;
					this.timeLeft = response.data.timeLeft;
					this.startTimer();
				})
				.catch(error => alert(error));
		},
		async obtenerResumenDiario() {
			// URL de tu API
			const url = `api/RepVentasVistas/GetResumenDiario/${this.localidad}`;
			// Configuración para Axios, incluyendo el token de autenticación si es necesario
			const configuracion = {
				headers: { "Authorization": "Bearer " + this.$store.state.token }
			};
			// Hacer la petición GET
			await axios.get(url, configuracion)
				.then(response => {
				// Manejar la respuesta
				const data = response.data;
				this.cantidadClientes = data.cantidadClientes;
				this.cantidadEmpleados = data.cantidadEmpleados;
				this.ticketPromedioHoy = data.ticketPromedioHoy;
				this.cards.push({
					title: 'Colaboradores',
					value:  this.cantidadEmpleados,
					color: 'blue lighten-5',
					icon: 'mdi-account-supervisor',
					textColor:'blue darken-4',
				});
				this.cards.push({
					title: 'Clientes',
					value:  this.cantidadClientes,
					color: 'amber lighten-5',
					icon: 'mdi-account-group',
					textColor:'amber darken-4',
				});
				this.cards.push({
					title: 'Ticket promedio',
					value:  this.ticketPromedioHoy.toLocaleString('en-US', {style: 'currency', currency: 'CRC'}),
					color: 'pink lighten-5',
					icon: 'mdi-cash-fast',
					textColor:'pink darken-4',
				});
				this.loading = false;
				})
				
				.catch(error => {
				// Manejar el error
				console.error("Hubo un error al obtener el resumen diario: ", error);
				// Aquí podrías cambiar el estado para mostrar un mensaje de error en tu interfaz de usuario
				});
		},
		loadVentasPorHora() {
			axios.get(`api/RepVentasVistas/VentasPorHoraUltimos7Dias/${this.localidad}`)
				.then(response => {
					this.horas = response.data.map(v => `${v.hora}:00`);
					this.infoVentas = response.data.map(v => v.promedioVentas);
					this.renderChart();
          this.loadVentasPorFamilia();
				})
				.catch(error => alert(error));
		},
		renderChart() {
      this.ventasHoraChart = new Chart(document.getElementById("ventasHoraChart").getContext('2d'), {
          type: 'bar',
          data: {
              labels: this.horas,
              datasets: [{
                  label: 'Promedio de Ventas por Hora',
                  data: this.infoVentas,
                  backgroundColor: 'rgba(254, 200, 68, 1)',
                  borderColor: 'rgba(255, 161, 38, 01)',
                  borderWidth: 1
              }]
          },
          options: {
              scales: {
                  yAxes: [{ gridLines: { color: 'rgba(200, 200, 200, 1)', lineWidth: 0.5 }, ticks: { beginAtZero: true } }],
                  xAxes: [{ gridLines: { display: false } }]
              },
              responsive: true,
              title: {
                  display: true,
                  text: 'Promedio de Ventas por Hora'
              }
          }
      });
    },
    getCardByIndex(index) {
			return this.cards.length > index ? this.cards[index] : null;
		},
	  listarAsociadas() {
      const usuario = this.$store.state.usuario;

      // Verifica si el objeto usuario y su propiedad localidad existen
      if (usuario && usuario.localidad) {
          this.localidad = usuario.localidad;
          this.Localidades.push(usuario.localidad);

          axios.get(`api/LocalidadesAsociadas/${usuario.nombre}`, {
              headers: { "Authorization": "Bearer " + this.$store.state.token }
          })
          .then(response => {
              response.data.forEach(x => this.Localidades.push(x.localdiadAsociada));
              this.getVentasHoy();
              this.getVentasHoyWeb();
          })
          .catch(error => alert("Error listando asociadas: " + error));
      } else {
          // Maneja el caso en que no hay usuario o localidad
          console.error("No se encontró el usuario o la localidad");
      }
    },

    async getVentasHoy(){
      const me = this;
			const header = { "Authorization": "Bearer " + this.$store.state.token };
			const configuracion = { headers: header };

			// Preparar los parámetros de consulta para múltiples localidades
			const params = new URLSearchParams();
			this.Localidades.forEach(localidad => {
				params.append('localidades', localidad);
			});

				await axios.get('api/RepVentasVistas/VentasHoyWebLocalidad?' + params.toString(), configuracion)
				.then(function (response) {
				const resp = response.data;
				// Asumiendo que resp es un arreglo de objetos con cada objeto conteniendo la información de ventas por localidad
				resp.forEach(venta => {
					me.Datos.push({
					Localidad: venta.localidad,
					valor: venta.valor,
					Moneda: venta.etiqueta,
					utilidad: venta.utilidad
					});
				});
				// Comprobar si la respuesta es un arreglo vacío o un string vacío
					if (Array.isArray(me.Datos) && me.Datos.length === 0 || me.Datos === '') {
					me.cards.push({
						title: 'Ventas de hoy',
						value: '0',
						color: 'cyan lighten-5',
						icon: 'mdi-cash-register',
						textColor:'cyan darken-4',
						});
				}
				me.cards.push({
					title: 'Facturación '+me.Datos[0].Localidad,
					value:  me.Datos[0].Moneda + me.Datos[0].valor.toLocaleString({maximumFractionDigits: 0}).replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
					color: 'cyan lighten-5',
					icon: 'mdi-cash-register',
					textColor:'cyan darken-4',
				});
				})
				.catch(function (error) {
				me.Mensaje = "Sin compras realizadas, gráfico no listado: " + error;
				me.snackbar = true;
				});
    },
		getVentasHoyWeb() {
			const params = new URLSearchParams();
			this.Localidades.forEach(localidad => params.append('Localidades', localidad));
			axios.get(`api/RepVentasVistas/VentasHoyWeb?${params.toString()}`, { headers: { "Authorization": "Bearer " + this.$store.state.token } })
				.then(response => {
					this.VentasHoyWeb33 = response.data;
					this.renderChartVentas();
				})
				.catch(error => alert("Error al obtener las ventas de hoy "+error));
		}, 
    loadVentasPorFamilia() {
      const me = this;
      axios.get(`api/VentasXProductoes/ObtenerFamiliasResumen7dias/${this.localidad}`)
        .then(response => {
          const data = response.data;
          me.familias = data.map(item => item.familia);
          me.ventas = data.map(item => item.cantidad);

          me.renderChart2();
        })
        .catch(error => {
          console.error("Error al obtener los datos", error);
        });
		},
    prepareLabels() {
			return this.familias.forEach(familia => (familia || "").substring(0, 11));
		},
    renderChart2() {
      const ctx = document.getElementById("ventasFamiliaChart");
      const myChart = new Chart(ctx, {
          type: 'bar',
          data: {
              labels: this.prepareLabels(),
              datasets: [{
                  label: 'Promedio de Ventas por Familia',
                  data: this.ventas,
                  backgroundColor: 'rgba(112, 155, 209, 0.5)',
                  borderColor: 'rgba(112, 155, 209, 1)',
                  borderWidth: 1
              }]
          },
          options: {
              scales: {
                  y: { // Usar 'y' en lugar de 'yAxes' para versiones más recientes de Chart.js (3+)
                      grid: {
                          color: 'rgba(200, 200, 200, 1)',  // Líneas más sutiles
                          lineWidth: 0.5
                      },
                      ticks: {
                          beginAtZero: true,
                          color: 'rgba(200, 200, 200, 0.5)'  // Color de texto más suave
                      }
                  },
                  x: { // Usar 'x' en lugar de 'xAxes' para versiones más recientes de Chart.js (3+)
                      grid: {
                          display: false  // Desactiva las líneas horizontales
                      },
                      ticks: {
                          color: '#3e4356'
                      }
                  }
              },
              responsive: true,
              plugins: {
                  title: {
                      display: true,
                      text: 'Promedio de Ventas por Familia',
                      color: '#3e4356',  // Asegúrate de que el color del título coincida
                      font: {
                          family: 'Poppins', // Usa la fuente 'Poppins'
                          size: 18
                      }
                  },
                  legend: {
                      labels: {
                          color: '#3e4356', // Ajusta el color de la leyenda
                          font: {
                              family: 'Poppins', // Usa la fuente 'Poppins'
                              size: 14
                          }
                      }
                  }
              }
          }
      });

      // Guarda el gráfico en una propiedad si necesitas acceder a él más tarde
      this.myChart = myChart;
  },
  
    renderChartVentas() {
      const canvas = document.getElementById('paymentMethodsChart');
      if (!canvas) {
          console.error("El elemento canvas no está disponible.");
          return;
      }

      const ctx = canvas.getContext('2d');
      this.paymentMethodsChart = new Chart(ctx, {
          type: 'bar',
          data: {
              labels: this.VentasHoyWeb33.map(det => det.localidad),
              datasets: [
                  { label: 'Tarjeta', data: this.VentasHoyWeb33.map(det => det.tarjeta), backgroundColor: '#3e4356' },
                  { label: 'Efectivo', data: this.VentasHoyWeb33.map(det => det.efectivo), backgroundColor: '#75b264' },
                  { label: 'Transferencia', data: this.VentasHoyWeb33.map(det => det.transferencia), backgroundColor: 'rgba(255, 161, 38, 1)' },
                  { label: 'SINPE', data: this.VentasHoyWeb33.map(det => det.sinpe), backgroundColor: 'rgba(112, 155, 210, 1)' }
              ]
          },
          options: {
              plugins: {
                  legend: {
                      labels: {
                          font: {
                              family: 'Poppins',
                              size: 14
                          }
                      }
                  }
              },
              scales: {
                  x: {
                      stacked: true,
                      grid: { display: false },
                      ticks: {
                          font: {
                              family: 'Poppins',
                              size: 12
                          }
                      }
                  },
                  y: {
                      stacked: true,
                      grid: { display: false },
                      ticks: {
                          display: false,
                          font: {
                              family: 'Poppins',
                              size: 12
                          }
                      }
                  }
              },
              responsive: true,
              maintainAspectRatio: false
          }
      });
    }


	}
};
</script>



<style>
	.fill-height {
	height: 100%; /* Esto hará que cada tarjeta llene la altura de su contenedor */
	}
	.card-shadow {
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5); /* Ajusta los valores según necesites */
	}
</style>