<template>
    <div>
        <v-dialog v-model="comprobanteModal" max-width="1000px">
            <v-card class="pa-3 factura">
                    <v-btn class="oculto-impresion mx-2" color="accent" dark="" @click="crearPDFFactura()"><v-icon>mdi-file-pdf</v-icon> PDF</v-btn>
                    <v-btn class="oculto-impresion mx-2" color="accent" dark="" @click="imprimirA4()"> <v-icon>mdi-printer</v-icon> A1</v-btn>
                    <v-btn class="oculto-impresion mx-2" color="accent" dark="" :to="nofactura2"> <v-icon>mdi-printer</v-icon> POS </v-btn>
                    <v-btn class="oculto-impresion mx-2" color="accent" dark="" :to="'/facturar/0/0?duplicar=' + noFactura"> Duplicar</v-btn>
                    <v-btn class="oculto-impresion mx-2" color="accent" dark="" @click="ReprocesarFactura()" >  Reprocesar </v-btn>
                    <v-btn class="oculto-impresion mx-2" color="accent" dark=" " @click="ReenvioFactura()" > <v-icon>mdi-email</v-icon><v-icon x-small="">mdi-send</v-icon> </v-btn>
                    <v-text-field filled style="width:200px;" class="oculto-impresion mx-2" label="Correo de reenvio" v-model="correo2"> </v-text-field> 
                    
					<div id="factura">
                        <html lang="en">
							<head>
							<meta charset="utf-8" />
							<meta http-equiv="X-UA-Compatible" content="IE=edge">
							<meta name="viewport" content="width=device-width, initial-scale=1">
							<link href="images/favicon.png" rel="icon" />
							<title>Factura {{noFactura}} - {{$store.state.usuario.localidad}} </title>
							<meta name="author" content="harnishdesign.net">

							<!-- Web Fonts
							======================= -->
							<link rel='stylesheet' href='https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900' type='text/css'>

							<!-- Stylesheet
							======================= -->
							<link rel="stylesheet" type="text/css" href="https://www.noah.cr/FacturaCSS/vendor/bootstrap/css/bootstrap.min.css"/>
							<link rel="stylesheet" type="text/css" href="https://www.noah.cr/FacturaCSS/vendor/font-awesome/css/all.min.css"/>
							<link rel="stylesheet" type="text/css" href="https://www.noah.cr/FacturaCSS/css/stylesheet.css"/>
							</head>
							<body>
							<!-- Container -->
							<div class="container-fluid invoice-container">
							
							<!-- Main Content -->
							<main>
							<div class="table-responsive">
								<table >
									<thead >
										<tr>
											<td class="col-5"><img height="120" :src="Logo3"></td>
											<td class="col-4 text-center"> <strong> {{$store.state.usuario.localidad}} </strong> <br />
											{{localidadencabezado[0].razonSocial}}<br />
											{{localidadencabezado[0].dirreccion}}<br />
											{{localidadencabezado[0].cedulaJuridica}}<br />
											{{localidadencabezado[0].telefono}}<br />
											{{localidadencabezado[0].correo}}<br /> </td>
											<td class="col-1 text-end"><h4 class="text-7 mb-0"><strong> Factura: {{noFactura}} </strong></h4></td>
										</tr>
									</thead>
								</table>
							</div>
							<hr>
							<table >
								<tbody>
									<tr>
										<td class="col-2 text-start text-1"><address>
											
											<strong> Cliente:</strong> {{nombreReceptor}} <br>
											<strong> Cédula: </strong> {{cedulaReceptor}}<br>
											<strong> Teléfono: </strong> {{telefonoReceptor}}<br>
											<strong> Correo: </strong> {{correoReceptor}} <br>
											
											</address>
										</td>
										<td class="col-2 text-end text-1"><address>
											<strong> Tipo:</strong> {{tipoDocumento}} <br>
											<strong> Fecha:</strong> {{fecha}} {{hora}}<br>
											<strong> NoFactura:</strong> {{noFacturaElectronica}}<br />
											<strong> Vendedor:</strong> {{vendedor}}<br />
											<strong> Generado por:</strong> {{$store.state.usuario.nombre}}
											</address>
										</td>
									</tr>
								</tbody>
							</table>
								
							<div class="card">
								<div class="card-body p-0">
								<div class="table-responsive">
									<table class="table mb-0">
									<thead class="card-header">
										<tr>
											<td class="col-3"><strong>Código</strong></td>
											<td class="col-6"><strong>Descripción</strong></td>
											<td class="col-1 text-center"><strong>Cant</strong></td>
											<td class="col-1 text-center"><strong>Precio</strong></td>
											<td class="col-1 text-end"><strong>Desc</strong></td>
											<td class="col-1 text-end"><strong>Total</strong></td>
										</tr>
									</thead>
									<tbody>
										<tr v-for="det in detalles" :key="det.codigoProducto">
											<td class="col-3 text-1"> {{ det.codigoProducto }} </td>
											<td class="col-6 text-0">
												<b>{{ det.descripcion }} </b>
											</td>
											<td class="col-1 text-center">{{ det.cantidad }}</td>
											<td class="col-1 text-center">{{ det.precioUnitario | formatNumber}}</td>
											<td class="col-1 text-end">{{ det.descuento | formatNumber}}</td>
											<td class="col-1 text-end">{{ det.precioUnitario * det.cantidad | formatNumber}} </td>
										</tr>
									</tbody>
									<tfoot class="card-footer">
										<tr>
										<td colspan="2" class="text-0"> 
											
												
												<qrcode-vue :value="value" :size="size" level="H"></qrcode-vue>
										</td>
										<td class="text-0"> Moneda: {{monedaPago}}</td>
										<td colspan="2" class="text-2 text-end"><strong>Sub Total: <br> Descuento: <br> Iva:<br>Impuestos: <br> Total: </strong></td>
										<td class="text-2 text-end">
											{{ subtotal | formatNumber }} <br>
											{{ descuento | formatNumber }} <br>
											{{ iv | formatNumber }} <br>
											{{ impEspecial | formatNumber }} <br>
											{{ total | formatNumber }}
										</td>
										</tr>
									</tfoot>
									</table>
								</div>
								</div>
							</div>
							</main>
							<!-- Footer -->
							<footer class="text-center text-1">
								<p><b><div v-if="ClaveComprobante != 0 && ClaveComprobante != ''"> Clave Comprobante: {{ClaveComprobante}} </div></b><br>
									<v-edit-dialog
											:return-value.sync="comentario"
											@save="updateCliente()"
											@close="updateCliente()"
											>
											{{comentario}}
											<template v-slot:input>
												<v-text-field
												onfocus="this.select();"
												v-model="comentario"
												label="Detalle de la reserva"
												counter
												></v-text-field>
											</template>
										</v-edit-dialog> <p style="white-space: pre-line;"><b> Cuentas Bancarias:</b>  <br>  {{Cuentas}}  <br> Facturas Disponibles en {{value}}</p>
							
									{{localidadencabezado[0].pieDocumento}}
							</footer>
							</div>
							</body>
						</html>
                    </div>
                    <v-btn @click="OcultarComprobante()" color="error" flat><v-icon> mdi-exit-to-app </v-icon></v-btn>
                
            </v-card>
        </v-dialog>
        <v-dialog v-model="ModalNotaCredito"  max-width="800" class="primary">
            <v-toolbar  dark color="primary" class="font-weight-light body-1">
                <h4>Nota de crédito - {{noFactura}}</h4>
            </v-toolbar>
            <v-card>
            <v-container fluid>
                <v-col cols="12" class="mt--3" sm="12">
                <v-form ref="form" v-model="validFactura"  @submit.prevent="facturar">
                <v-row > 
                    <v-col cols="12" sm="3">
                        <v-select v-model="tipoCedulaReceptor" :rules="[v => !!v || 'El tipo cédula es requerido']" :items="tipoDocumentos" label="Tipo de Cédula"></v-select>
                    </v-col>
                    
                    <v-col cols="12" sm="3">
                        <v-text-field :rules="cedulaRules"  v-model="cedulaReceptor" label="Numero de cédula"></v-text-field>
                    </v-col>
					<v-col  cols="12" sm="6">
                        <v-text-field v-model="nombreReceptor"  label="Nombre del cliente"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-text-field :rules="emailRules"  v-model="correoReceptor" label="Correo"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-text-field :rules="celRules"  v-model="telefonoReceptor" label="Teléfono"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="5">
                        <v-combobox :items="justificacionNota" :rules="justificacionRules" v-model="justificacion" label="Justificación"></v-combobox>
                    </v-col>
					<v-col cols="12" sm="12">
                        <v-text-field  v-model="detalle" label="Detalle"></v-text-field>
						<v-combobox :loading="loading" id="buscador" filled clearable onfocus="this.select();"  prepend-inner-icon="mdi-briefcase-outline" @keyup="selectProductos()" @change="buscarProductoDirecto()" :items="productos" v-model="codigo" label="Código de producto o servicio"></v-combobox>
					</v-col>
                        <v-col cols="12" sm="12">
                            <v-flex xs12 sm12 md12 lg12 xl12>
                                
                                <v-data-table
                                    :headers="cabeceraDetalles"
                                    item-key="name" 
                                    single-select
									dense
                                    :items="detalles"
                                    class="elevation-1 "
                                    v-model="selected">
                                    <template slot="no-data">
                                        <h3>No hay artículos agregados al detalle.</h3>
                                    </template> 

									<template v-slot:item.cantidad="props">
										<v-edit-dialog
											:return-value.sync="props.item.cantidad"
											>
											{{ props.item.cantidad }} <br>
											<template v-slot:input>
												<v-text-field
												onfocus="this.select();"
												@change="Recalcular()" @keyup.enter="Recalcular()"
												v-model="props.item.cantidad"
												:label="'Digite la cantidad '"
												></v-text-field>
											</template>
										</v-edit-dialog>
									</template>
									<template v-slot:item.precioUnitario="props">
										<v-edit-dialog
											:return-value.sync="props.item.precioUnitario"
											>
											{{ props.item.precioUnitario }} <br>
											<template v-slot:input>
												<v-text-field
												onfocus="this.select();"
												@change="Recalcular()" @keyup.enter="Recalcular()"
												v-model="props.item.precioUnitario"
												:label="'Digite la cantidad '"
												></v-text-field>
											</template>
										</v-edit-dialog>
									</template>
									<template v-slot:item.actionborrar="{ item }"> 
										<v-btn small color="error" @click="eliminarProducto(item.idproducKey)">
											<v-icon small> mdi-delete </v-icon>
										</v-btn> 
                                    </template> 
                                </v-data-table>
                            </v-flex>
                        <div style="text-align:right;"> <br>SubTotal: {{subtotal}} <br> IVA {{iv}} <br>  TOTAL {{monedaPago}} {{total}}</div>
						<v-row>
								<v-text-field onfocus="this.select();"  @keyup.enter="AplicarDescuento()" label="Aplicar Descuento %" v-model="Descuento">  </v-text-field>
								<v-btn> Aplicar Descuento </v-btn>
								<v-btn color="red" @click="detalles = []"> <v-icon>mdi-delete </v-icon> </v-btn>
						</v-row>
						<v-card-actions>
							<v-btn @click="EliminarRecibo" v-if="mensajeRespuesta === 'RECIBO'"  text outlined color="orange"> Eliminar Recibo </v-btn>
							
							<v-btn color="error" text outlined @click="ModalNotaCredito = false">
							Cancelar
							</v-btn> 
							<v-btn  color="success" text outlined @click="Recalcular()">
							Recalcular Ajuste
							</v-btn>
							<v-spacer/>
							<v-btn  color="primary" :loading="loading" @click="CrearNotaCredito()">
							Generar Nota Crédito
							</v-btn>
						</v-card-actions>
                        </v-col>
                </v-row>
                </v-form>
                </v-col>
            </v-container>
            </v-card>
        </v-dialog>
        <v-data-table 
            :headers="headersDinamicos"
            :items="usuarios"
            :search="search"
            >
			<template v-slot:item.utilidadFactura="{ item }">
				{{item.utilidadFactura}} 
			</template>
            <template class="oculto-impresion" v-slot:item.mensajeRespuesta="{ item }">
            	<v-chip :color="getColor(item.mensajeRespuesta)" dark>{{ item.mensajeRespuesta }}</v-chip>
            </template>
            <template class="oculto-impresion" v-slot:top>
                <v-row class="pa-2">
                    <v-col cols="12" lg="4" md="4" sm="4" xs="12">
                        <v-row class="var mx-1">
                           
                            <p class="mx-3 mt-2" style="font-size:25px; color:#3d464d;">Reporte Ventas</p>
                        </v-row>
						<v-btn v-if="esAdministrador || esSupervisor || esTaller" color="error" class="mx-1 mt-2" @click="crearPDF()"> <v-icon large="">mdi-file-pdf-box </v-icon> </v-btn>
                            
						<vue-excel-xlsx v-if="esAdministrador || esSupervisor|| esSupervisor || esTaller"
							:data="usuarios"
							:columns="columns"
							:filename="'Reporte de Ventas '+$store.state.usuario.localidad"
							:sheetname="'Ventas'"
							>
							<v-btn color="green">  <v-icon large="">mdi-file-excel </v-icon> </v-btn>
						</vue-excel-xlsx> 
                    </v-col>
                    <v-col cols="12" lg="5" md="4" sm="4" xs="12">    
                        <v-text-field filled v-model="search"  append-icon="mdi-magnify"
                         label="Buscar" single-line hide-details
                        />
						<v-select @change="listarFechas()" filled  v-model="localidad" label="Bodega o Localidad" :items="Localidades"> </v-select>
					</v-col>
                    <v-col cols="12" lg="2" md="4" sm="4" xs="5">
						<v-text-field hide-details="" filled v-model="start" type="date" label="Fecha inicial"></v-text-field>
                        <v-text-field hide-details="" filled v-model="end"  type="date" label="Fecha final"></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="1" md="1" sm="1" xs="1"> <v-btn large :loading="loading" color="info" @click="listarFechas()" > <v-icon fab> mdi-cloud-search </v-icon> </v-btn>
                    </v-col>
                </v-row>
               
            </template>
            <template class="oculto-impresion" v-slot:item.action="{ item }">
            <v-icon
                
                class="mr-2"
                color="primary" 
                @click="MostrarComprobante(item)"
            >
                mdi-file-document
            </v-icon>
            <template >
            <v-icon
                small
                color="error"
                @click="NotaCredito(item)"
            > 
                mdi-delete
            </v-icon>
            </template>
            
            </template>

        </v-data-table>
        <template class="pa-3">
            <v-snackbar
                    v-model="snackbar" top vertical="" :timeout="5000"
                    >
                    {{ Mensaje }}
                    <v-btn
                    color="info"
                    text
                    @click="snackbar = false"
                    >
                    Cerrar
                    </v-btn>
            </v-snackbar>
            
            <v-row  flat color="white"  class="oculto-impresion pa-2">
                <v-col v-if="esAdministrador || esSupervisor || esBoss" cols="12" sm="3">
                    <v-row flat color="white"  class="rounded-xl var mx-5">
                    <v-card-text>
                        <v-card-title class="boton--text text-xl-h4 text-lg-h6 text-sm-body-2"> <b>Resumen General </b></v-card-title>
                        <v-sub-title>Datos consultos del {{start}} hasta {{end}} </v-sub-title> <br>
                        <b> IVA 1%: </b>{{MonedaFiltrar}} {{calcularIva1}} <br>
                        <b> IVA 2%: </b>{{MonedaFiltrar}} {{calcularIva2}} <br>
                        <b> IVA 4%: </b>{{MonedaFiltrar}} {{calcularIva4}} <br>
                        <b> IVA 13%: </b>{{MonedaFiltrar}} {{calcularIva13}} <br> 
                        <b> Recibos: </b>{{MonedaFiltrar}} {{(calcularRecibos)}} <br> 
						<b> Transferencias: </b>{{MonedaFiltrar}} {{calcularTransferencias}} <br> 
						<b> Tarjetas: </b>{{MonedaFiltrar}} {{calcularTarjetas}} <br> 
						<b> Efectivo: </b>{{MonedaFiltrar}} {{calcularEfectivo}} <br> 
                        <b> Total Hacienda:</b> {{MonedaFiltrar}} {{(calcularTotal)-(calcularNC)-(calcularRecibos)}} <br>
                        <b> IVA Neto: </b>{{MonedaFiltrar}} {{TotalIva=(calcularIva)}} <br>
                        <b> Ventas Bruto:</b> {{MonedaFiltrar}} {{(calcularTotal)}} <br>
                        <b> Notas Crédito: </b>  {{MonedaFiltrar}} {{(calcularNC)}} <br>
                        <b> Ventas Netas:</b> {{MonedaFiltrar}} {{(calcularTotal)-(calcularNC)}} <br>
						<b> Utilidad:</b> {{MonedaFiltrar}} {{calcularUtilidad}} <br>
                    
                    </v-card-text>
                    </v-row>
                </v-col>
                <v-col v-if="esAdministrador || esSupervisor || esCrifasa || esBoss || esTaller" cols="12" sm="4">
                    
					Ventas Colones
					<v-simple-table class="boton">
						<template v-slot:default>
						<thead>
							<tr>
							<th style="color:white;" class="text-left">
								Tipo Pago
							</th>
							<th style="color:white;" class="text-left">
								Venta
							</th>
							</tr>
						</thead>
						<tbody>
							<tr
							v-for="item in VentasVendedores.ventasColones"
							:key="item.cajero"
							>
							<td>{{ item.tipoPago }}</td>
							<td>{{ item.totalVentas.toLocaleString()  }}</td>
							</tr>
						</tbody>
						</template>
					</v-simple-table>
					Ventas Dolares
					<v-simple-table class="boton">
						<template v-slot:default>
						<thead>
							<tr>
							<th style="color:white;" class="text-left">
								Tipo Pago
							</th>
							<th style="color:white;" class="text-left">
								Venta
							</th>
							</tr>
						</thead>
						<tbody>
							<tr
							v-for="item in VentasVendedores.ventasDolares"
							:key="item.cajero"
							>
							<td>{{ item.tipoPago }}</td>
							<td>{{ item.totalVentas.toLocaleString()  }}</td>
							</tr>
						</tbody>
						</template>
					</v-simple-table>
                </v-col>
                <v-col v-if="esAdministrador || esSupervisor || esCrifasa || esBoss || esTaller" cols="12" sm="5">
                    Resumen de ventas por usuario y vendedor
					<v-simple-table class="boton">
						<template v-slot:default>
						<thead>
							<tr>
							<th style="color:white;" class="text-left">
								Ventas por Usuarios
							</th>
							<th style="color:white;" class="text-left">
								Venta
							</th>
							<th style="color:white;" class="text-left">
								Utilidad
							</th>
							</tr>
						</thead>
						<tbody>
							<tr
							v-for="item in VentasVendedores.ventasPorCajero"
							:key="item.cajero"
							>
							<td>{{ item.cajero }}</td>
							<td>{{ item.totalVentas.toLocaleString()  }}</td>
							<td>{{ item.utilidadFactura.toLocaleString() }}</td>
							</tr>
						</tbody>
						</template>
					</v-simple-table>
					<v-simple-table v-if="esAdministrador || esSupervisor || esBoss" class="boton">
						<template v-slot:default>
						<thead >
							<tr>
							<th style="color:white;" class="text-left">
								Ventas por Vendedor
							</th>
							<th style="color:white;" class="text-left">
								Venta
							</th>
							<th style="color:white;" class="text-left">
								Utilidad
							</th>
							</tr>
						</thead>
						<tbody>
							<tr
							v-for="item in VentasVendedores.ventasPorVendedor"
							:key="item.cajero"
							>
							<td>{{ item.vendedor }}</td>
							<td>{{ item.totalVentas.toLocaleString() }}</td>
							<td>{{ item.utilidadFactura.toLocaleString() }}</td>

							</tr>
						</tbody>
						</template>
					</v-simple-table>
                </v-col>
                
                
            </v-row>
        </template>
    </div>
</template>

<script scoped>
import axios from 'axios'
import jsPDF from 'jspdf'
import VueExcelXlsx from "vue-excel-xlsx";
import autotable from 'jspdf-autotable'
import Vue from "vue";
import html2canvas from 'html2canvas';
import QrcodeVue from 'qrcode.vue'

Vue.use(VueExcelXlsx);
	export default {
		filters: {
			formatNumber(value) {
				const formatter = new Intl.NumberFormat('en-US', {
				minimumFractionDigits: 0, // Mínimo número de dígitos fraccionarios
				maximumFractionDigits: 2, // Máximo número de dígitos fraccionarios
				});
				return formatter.format(value);
			}
		},
		data: () => ({
		mesesValores: null,
		nombreMeses: [],
		UsuarioSeleccionado:'',
		totalMeses: [],
		usuariosdata:[],
		usuariosUnicos:[],
		value: 'https://www.noah.cr',
		size: 100,
		dialog: false,
		mensajeRespuesta: '',
		search: '',
		RefComprobante: '',
		ClienteSeleccionado : '',
		selected: null,
		tipoDocumento: '',
		today: new Date().toISOString().substr(0, 10),
		focus: new Date().toISOString().substr(0, 10),
		headers: [
		{ text: 'Opciones', value: 'action', sortable: false },
		{ text: 'Factura', value: 'noFactura' },
		{ text: 'Estado', value: 'mensajeRespuesta' },
		{ text: 'Fecha', value: 'fecha' },
		{ text: 'Nombre Cliente', value: 'nombreReceptor',  }, 
		{ text: 'Total', value: 'total', sortable: false  },  
		{ text: 'Tipo', value: 'tipoDocumento' },
		{ text: 'Grabado', value: 'gravado', sortable: false  },
		{ text: 'Excento', value: 'exento', sortable: false  },
		{ text: 'IVA', value: 'impuesto', sortable: false  },
		{ text: 'Moneda', value: 'moneda',  },  
		{ text: 'TipoPago', value: 'tipoPago',  },       
		{ text: 'Vendedor', value: 'vendedor' },
		{ text: 'Usuario', value: 'cajero' },
		{ text: 'Utilidad', value: 'utilidadFactura' },
		{ text: 'Clave numérica', value: 'claveComprobante',  }, 
		],
		columns : [
            { label: "Factura",field: "noFactura", },
            { label: "Estado",  field: "mensajeRespuesta", },
            { label: "Fecha",field: "fecha", },
            { label: "Vendedor",field: "vendedor", },
            { label: 'Usuario', field: 'cajero' },
            { label: "Tipo",field: "tipoDocumento", },
            { label: "Grabado",field: "gravado", },
            { label: "Excento",field: "exento", },
            { label: 'Total', field: 'total' }, 
            { label: 'Moneda', field: 'moneda', },
            { label: "TipoPago",field: "tipoPago", },
            { label: "Nombre Cliente",field: "nombreReceptor", },
            { label: 'Cédula Cliente', field: 'cedulaReceptor',  }, 
            { label: 'Correo Cliente', field: 'correoReceptor',  }, 
            { label: 'Imp 1%', field: 'impuesto1',  },
            { label: 'Imp 2%', field: 'impuesto2',  },  
            { label: 'Imp 4%', field: 'impuesto4',  },
            { label: 'Imp 13%', field: 'impuesto13',  }, 
			{ label: 'Iva Devuelto', field: 'impuesto4',  }, 
            { label: 'Gravado 1%', field: 'gravado1',  },  
            { label: 'Gravado 2%', field: 'gravado2',  },  
            { label: 'Gravado 4%', field: 'gravado4',  },  
            { label: 'Gravado 13%', field: 'gravado13',  }, 
            { label: 'Clave numérica', field: 'claveComprobante',  },  
        ],
		columnsXero: [
            { label: "ContactName",field: "nombreReceptor", }, 
            { label: 'EmailAddress', field: 'correoReceptor',  },   
			{ label: 'POAddressLine1', field: 'na',  },  
			{ label: 'POAddressLine2', field: 'na',  },  
			{ label: 'POAddressLine3', field: 'na',  },  
			{ label: 'POAddressLine4', field: 'na',  },  
			{ label: 'POCity', field: 'na',  },  
			{ label: 'PORegion', field: 'na',  },  
			{ label: 'POPostalCode', field: 'na',  },  
			{ label: 'PoCountry', field: 'na',  },  
            { label: "InvoiceNumber",field: "noFactura", },
            { label: "Reference",field: "tipoPago", },
            { label: "Date",field: "fecha", },
            { label: "DueDate",field: "fecha", },
            { label: 'Total', field: 'total' },  
			{ label: 'InventoryItemCode', field: 'na' },  
			{ label: 'Description', field: 'na' },  
			{ label: 'Quantity', field: 'inventario' },  
            { label: "UnitAmount",field: "gravado", },
            { label: "Discount",field: "descuento", },
			{ label: "AccountCode",field: "cuentacontable", },
			{ label: "TaxType",field: "IVA13", },
            { label: 'TaxAmount', field: 'impuesto13',  }, 
			{ label: 'TrackingName1', field: 'localidad',  }, 
			{ label: 'TrackingOption', field: 'sucursal',  }, 
			{ label: 'TrackingName2', field: 'localidad',  }, 
			{ label: 'TrackingOption2', field: 'sucursal',  }, 
			{ label: 'Currency', field: 'moneda',  }, 
			{ label: 'BrandingTheme', field: 'na',  }, 
            { label: 'Monto Pagado', field: 'total' },  
            { label: 'Cuenta contable', field: 'cuentacontable',  },
            { label: 'FechaPago', field: 'fecha',  }, 
            { label: 'Referencia de Pago', field: 'referencia',  },  
		],
		cabeceraDetalles: [
		{ text: 'Código', value: 'codigoProducto', sortable: false  },
		{ text: 'Descripción', value: 'descripcion', sortable: false  },
		{ text: 'Precio IVI', value: 'precioUnitario', sortable: false  },
		{ text: 'Cantidad a debitar', value: 'cantidad', sortable: false  },
		{ text: 'Eliminar', value: 'actionborrar', sortable: false  },
		],
		tipoDocumentos: [
			'FISICA',
			'JURIDICA',
			'EXTRANJERO',
		],
		PagosFiltrados: "",
		Monedas: ["USD","CRC"],
		TiposPago:["TARJETA","DEPOSITO","EFECTIVO","TRANSFERENCIA"],
		Descuento: '',
		MonedaFiltrar : "",
		tipoCedulaReceptor: '',
		search: '',
		recibidoEfectivo: 0,
		TotalRecibos: 0,
		totalDescuentos: 0,
		noFacturaElectronica: '',
		emailRules: [
		v => !!v || 'El correo electrónico es requerido',
		v => /.+@.+\..+/.test(v) || 'El correo electrónico es requerido debe ser válido',],
		cedulaRules: [value => !!value || 'La cédula es requerida'
		, value => (value && value.length >= 9) || 'Debe ingresar 9 carácteres como mínimo',],
		nameRules: [
		value => !!value || 'El nombre es requerido',
		value => (value && value.length >= 10) || 'Debe ingresar 10 carácteres como mínimo',
		],
		celRules: [
			value => !!value || 'El número es requerido',
			value => (value && value.length >= 8) || 'Debe ingresar 8 carácteres como mínimo',
			],
		justificacionRules: [
			value => !!value || 'La justificación es requerida',
			value => (value && value.length >= 7) || 'Debe ingresar 7 carácteres como mínimo',
			],
		editedIndex: -1,
		id: '',
		idrol:'',

		loading: true,
		timeouts: [],
		noFactura: 0,
		nofactura2: '/ticket/2',
		comprobanteModal: 0,
		justificacionNota: ["Ajuste de precio precio" ,"Saldo incobrable"," Pronto de pago","Error en cobro"],
		productosDatos:[],
		MensajeRespuesta: '',
		VentasVendedores: [],
		impEspecial:0,
		Logo3: null,
		ClaveComprobante: '',
		EstadoNC: 0,
		ModalNotaCredito: false,
		snackbar: false,
		Mensaje: "",
		correo2: '',
		VendedorSeleccionado: '',
		total: 0,
		justificacion: '',
		medioDePago : '',
		detalles: [],
		detalle: '',
		localidadencabezado:[],
		NombreLocalidad:'',
		CedulaLocalidad:'',
		Datos: [],
		DireccionLocalidad:'',
		TelefonoLocalidad:'',
		fecha: '',
		hora: '',
		nombreReceptor: '',
		productos: [],
		cedulaReceptor:'',
		subTotal:0,
		direccion:'',
		monedaPago: "CRC",
		telefonoReceptor:'',
		correoReceptor:'',
		Resolucion: '',
		vendedor: '',
		validFactura:null,
		ShowNoFact: true,
		idFactura: 0,
		subtotal: 0,
		Cuentas: '',
		tipoPago:0,
		codigo:'',
		modoPDV: '',
		link: '',
		comentario: '',
		grabado:0 ,
		localidad: '',
		Localidades:[],
		excento: 0,
		Logo2 : '',
		iv: 0.0,
		clientes: [],
		start : '',
		end : '',
		tipo_documento: '',
		Vendedores: [],
		usuarios:[],    
		desserts: [],
		editedIndex: -1,
		descuento: 0,
		
		}),
		components: {
		QrcodeVue,
    },

    computed: {
		formTitle () {
				return this.editedIndex === -1 ? 'Nuevo usuario' : 'Actualizar usuario'
		},
		headersDinamicos() {
			let headers = [
			{ text: 'Opciones', value: 'action', sortable: false },
			{ text: 'Factura', value: 'noFactura' },
			{ text: 'Estado', value: 'mensajeRespuesta' },
			{ text: 'Fecha', value: 'fecha' },
			{ text: 'Nombre Cliente', value: 'nombreReceptor' }, 
			{ text: 'Total', value: 'total', sortable: false },  
			{ text: 'Tipo', value: 'tipoDocumento' },
			{ text: 'Grabado', value: 'gravado', sortable: false },
			{ text: 'Excento', value: 'exento', sortable: false },
			{ text: 'IVA', value: 'impuesto', sortable: false },
			{ text: 'Moneda', value: 'moneda' },  
			{ text: 'TipoPago', value: 'tipoPago' },       
			{ text: 'Vendedor', value: 'vendedor' },
			{ text: 'Usuario', value: 'cajero' },
			{ text: 'Clave numérica', value: 'claveComprobante' }
			];

			// Solo agregamos la columna de Utilidad si cumple las condiciones
			if (this.esAdministrador || this.esSupervisor || this.esCrifasa || this.esBoss || this.esContador || this.esAdministrador) {
			headers.push({ text: 'Utilidad', value: 'utilidadFactura' });
			}

			return headers;
		},
		esBoss(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='King Boss';
		},
		calcularTotalNota:function(){
		var resultado=0.0;
		var preresult = 0.0;
			for(var i=0;i<this.detalles.length;i++){
					if(this.detalles[i].Ivi == false){  //Si el campo estadoInventario es = a false entonces el IVA se suma
						resultado= (parseFloat(this.totalGravado) + parseFloat(this.calcularImpuesto)+parseFloat(this.totalExento)) - parseFloat(this.totalDescuento);
				} if(this.detalles[i].Ivi == true){
					if(this.monedaPago == 'USD'){
						preresult= (parseFloat(this.totalGravado) + parseFloat(this.calcularImpuesto)+parseFloat(this.totalExento)) - parseFloat(this.totalDescuento);
						resultado = parseFloat(preresult)
						return resultado.toFixed(2);
					}
					preresult= (parseFloat(this.totalGravado) + parseFloat(this.calcularImpuesto)+parseFloat(this.totalExento)) - parseFloat(this.totalDescuento);
					resultado = parseInt(preresult)
				}
			}
			
			
			return resultado.toFixed(2);
		},
		calcularTotal:function(){
			var resultado=0.0;
			for(var i=0;i<this.usuarios.length;i++){
				if(this.usuarios[i].tipoDocumento == "TIQUETE ELECTRONICO" || this.usuarios[i].tipoDocumento == "FACTURA ELECTRONICA" || this.usuarios[i].tipoDocumento == "RECIBO"){
					resultado=resultado+(this.usuarios[i].total * 1);
					}
			}
			
			return resultado.toFixed(2);
		},
		calcularNC:function(){
			var resultado=0.0;
			for(var i=0;i<this.usuarios.length;i++){
				if(this.usuarios[i].tipoDocumento == 'NOTA DE CREDITO')
					resultado=resultado+(this.usuarios[i].total * 1);
			}
			
			return resultado.toFixed(2);
		},
		calcularRecibos:function(){
			var resultado=0.0;
			for(var i=0;i<this.usuarios.length;i++){
				if(this.usuarios[i].tipoDocumento == 'RECIBO')
					resultado=resultado+(this.usuarios[i].total * 1);
			}
			
			return resultado.toFixed(2);
		},
		calcularTransferencias:function(){
			var resultado=0.0;
			for(var i=0;i<this.usuarios.length;i++){ 
				if(this.usuarios[i].tipoPago == 'DEPOSITO' || this.usuarios[i].tipoPago == 'TRANSFERENCIA'|| this.usuarios[i].tipoPago == 'SINPE' ) 
					resultado=resultado+(this.usuarios[i].total * 1);
			}
			
			return resultado.toFixed(2);
		},
		calcularTarjetas:function(){
			var resultado=0.0;
			for(var i=0;i<this.usuarios.length;i++){
				if(this.usuarios[i].tipoDocumento == "TIQUETE ELECTRONICO" || this.usuarios[i].tipoDocumento == "FACTURA ELECTRONICA" || this.usuarios[i].tipoDocumento == "RECIBO"){
					if(this.usuarios[i].tipoPago == 'TARJETA')
						resultado=resultado+(this.usuarios[i].total * 1);
					}
				}
				
			
			return resultado.toFixed(2);
		},
		calcularEfectivo:function(){
			var resultado=0.0;
			for(var i=0;i<this.usuarios.length;i++){
				if(this.usuarios[i].tipoDocumento == "TIQUETE ELECTRONICO" || this.usuarios[i].tipoDocumento == "FACTURA ELECTRONICA" || this.usuarios[i].tipoDocumento == "RECIBO"){
					if(this.usuarios[i].tipoPago == 'EFECTIVO')
						resultado=resultado+(this.usuarios[i].total * 1);
					}
				}
				
			
			return resultado.toFixed(2);
		},
		calcularIva:function(){
			var resultado=0.0;
			for(var i=0;i<this.usuarios.length;i++){
				if(this.usuarios[i].tipoDocumento == "TIQUETE ELECTRONICO" || this.usuarios[i].tipoDocumento == "FACTURA ELECTRONICA"){
						resultado=resultado+(this.usuarios[i].impuesto13 * 1);
						
				}   if(this.usuarios[i].tipoDocumento == 'NOTA DE CREDITO') {
					resultado -= (this.usuarios[i].impuesto13 * 1);
				}
			}
			
			return resultado.toFixed(2);
		
		},
		calcularUtilidad:function(){
			var resultado=0.0;
			for(var i=0;i<this.usuarios.length;i++){
						resultado=resultado+this.usuarios[i].utilidadFactura;
					
			} 
			
			return resultado.toFixed(2);
		
		},
		calcularIva13:function(){
			var resultado=0.0;
			for(var i=0;i<this.usuarios.length;i++){
						resultado=resultado+(this.usuarios[i].impuesto13 * 1);
					
			} 
			
			return resultado.toFixed(2);
		
		},
		calcularIva1:function(){
			var resultado=0.0;
			for(var i=0;i<this.usuarios.length;i++){
				resultado=resultado+(this.usuarios[i].impuesto1 * 1);
				
			}
			
			return resultado.toFixed(2);
		
		},
		calcularIva2:function(){
			var resultado=0.0;
			for(var i=0;i<this.usuarios.length;i++){
						resultado=resultado+(this.usuarios[i].impuesto2 * 1);
					
			}
			
			return resultado.toFixed(2);
		
		},
		calcularIva4:function(){
			var resultado=0.0;
			for(var i=0;i<this.usuarios.length;i++){
						resultado=resultado+(this.usuarios[i].impuesto4 * 1);
				
			}
			
			return resultado.toFixed(2);
		},
		esAdministrador(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='Administrador';
		},
		esSupervisor(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='Supervisor';
		},
		esVendedor(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='Vendedor';
		},
		esCliente(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='Cliente';
		},
		esTaller(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='Gerente Taller';
		},
		calcularImpuestoTotal:function(){
		var resultado=0.0;
		var test= false;
			for(var i=0;i<this.detalles.length;i++){
			if(this.detalles[i].Ivi == false && this.detalles[i].gravado == 'S'){
				resultado=resultado+(this.detalles[i].precio * this.detalles[i].cantidad);
				return resultado.toFixed(2);
			}    
				if(this.detalles[i].gravado == 'S' && this.detalles[i].Ivi == true)
				{
				if(this.detalles[i].valorImpuesto == 13)
				{
				resultado+=((this.detalles[i].precio / 1.13) * this.detalles[i].cantidad);
				} else
				if(this.detalles[i].valorImpuesto == 1)
				{
					resultado+=((this.detalles[i].precio / 1.01) * this.detalles[i].cantidad);
				} else
				if(this.detalles[i].valorImpuesto == 2)
				{
					resultado+=((this.detalles[i].precio / 1.02) * this.detalles[i].cantidad);
				} else
				if(this.detalles[i].valorImpuesto == 4)
				{
					resultado+=((this.detalles[i].precio / 1.04) * this.detalles[i].cantidad);
				}
				}
			}
		return resultado.toFixed(2);
		},
    },
    

    watch: {
		dialog (val) {
			val || this.close()
		},
      
    },

    created () {
		this.listar();
		this.listarLocalidad();
		this.listarAsociadas();
    },

    methods: {
		buscarProductoDirecto(){
			let me=this;
			
			this.codigo = this.codigo.value ?? this.codigo;

			if (this.codigo != "")
			{
				let header={"Authorization" : "Bearer "};
				let configuracion= {headers : header};
				axios.get('api/Productos/BuscarCodigoVenta/'+this.codigo+'/'+this.$store.state.usuario.localidad,configuracion)
				.then(function(response){
					me.agregarDetalleDirecto(response.data);
					
				}).catch(function(error){
					//alert(error);
				});
			}
		},
		agregarDetalleDirecto(data = []){
			if (data['codigoProducto'] != '' && data['codigoProducto'] != undefined)
			{
				this.codigo = data['codigoProducto'];
			}
				if (this.codigo != '')
				{ 
					
					this.detalles.push(
						{
						idproducKey: this.detalles.length + 5, 
						codigoProducto:data['codigoProducto'],
						descripcion:data['descripcion'],
						cantidad:1,
						precioUnitario:data['precioVenta'] ,
						Ivi:data['estadoImp'],
						descuento:data['montoDescuento'],
						subtotal:data['precioVenta'],
						valorImpuesto:data['valorImpuesto'],
						gravado:data['exe_Grad'],
						codigoHacienda:data['codigoRaiz'],
						inv:10,
						minimo:0,
						detalle: "",
						gravado:data['gravado'],
						}
						);
						this.codigo = '';
						this.Recalcular();
				}
				else
				{
					this.Mensaje = "Debe digitar un código de producto o servicio.";
					this.snackbar = true;
			}
			
		},
		selectProductos(){
			let me=this;
			let Objeto = [];
			let myInput = document.getElementById('buscador');
			myInput = myInput.value;
			let cant = myInput.length;
			if ( cant > 2 ) {
				var search_term = myInput;
				
				this.timeouts.map( timeout => {
					window.clearTimeout( timeout );
				} );
				this.timeouts.push(
					setTimeout( () => {
						this.loading = true;
						axios.get('api/Productos/BuscadorEnter/'+this.$store.state.usuario.localidad+'/'+myInput).then( response => {
							Objeto = response.data
								// Cargar Productos 
								Objeto.map(function(x){
									me.productos.push({text:x.descripcion, value:x.codigoProducto});
								});
								me.loading = false;
							} )
							.catch( error => { } );
					}, 500 )
				);
			} else {
				this.timeouts.map( timeout => {
					window.clearTimeout( timeout );
				} );
				this.productos = [];
			}
		},
		listarAsociadas(){
			var items = [];
			let me=this;
			me.Localidades.push(me.$store.state.usuario.localidad);
			this.localidad = this.$store.state.usuario.localidad;
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			axios.get('api/LocalidadesAsociadas/'+this.$store.state.usuario.nombre,configuracion).then(function(response){
				items=response.data;
				items.map(function(x){
					me.Localidades.push(x.localdiadAsociada);
				});
			}).catch(function(error){
				//alert("Producto no encontrado");
				me.modelCarga=false;
			});
		},
		finishOrder(){
			var divContents = document.getElementById("factura").innerHTML;
			var a = window.open('', '', 'height=500, width=500');
			a.document.write('<html>');
			a.document.write(divContents);
			a.document.write('</body></html>');
			a.print();
		},
		eliminarRecibo( ) {
			// Realiza la solicitud DELETE al endpoint correspondiente
			axios.delete(`${API_URL}/RepVentasVistas/VentasMes12/Localidad/${this.idFactura}/${this.$store.state.usuario.nombre}`)
				.then(response => {
				console.log('Registro eliminado con éxito');
				// Realiza cualquier otra acción necesaria después de eliminar el registro
				// Por ejemplo, mostrar una notificación al usuario
				// o actualizar la lista de registros en tu aplicación
				})
				.catch(error => {
				console.error('Error al eliminar el registro:', error);
				// Maneja el error de alguna manera adecuada
				});
		},
		EliminarRecibo(){
			
		},
		listarAux(id){
				let me=this;
				let aux = [];
                let header={"Authorization" : "Bearer " + this.$store.state.token};
                let configuracion= {headers : header};
                axios.get('api/Facturas/ListarAuxTiquet/'+id+'/'+this.$store.state.usuario.localidad+'',configuracion).then(function(response){
                    //console.log(response);
                    aux=response.data;
					me.vuelto = aux[0].vuelto;
					me.tipoDocumento = aux[0].tipoDocumento;
					me.cedulaReceptor = aux[0].cedulaReceptor;
					me.total = aux[0].total;
					me.subtotal = aux[0].subtotal;
					me.nombreReceptor = aux[0].nombreReceptor;
					me.imp1 = aux[0].impuesto1;
					me.imp2 = aux[0].impuesto2;
					me.imp4 = aux[0].impuesto4;
					me.imp13 = aux[0].impuesto13;
					me.tipoPago = aux[0].tipoPago;
					me.fecha = aux[0].fecha;
					me.vendedor = aux[0].vendedor;
					me.ClaveComprobante  = aux[0].claveComprobante;
					me.clav1 = me.ClaveComprobante.substring(0, 25);
					me.clav2 = me.ClaveComprobante.substring(25, 50);
                }).catch(function(error){
                    console.log(error);
                });	
			},
		Moneda(){
			if(this.monedaPago == 'USD'){
				return "$";
			} 
			return "₡";
		},
        listarDetalles(id){
			let me=this;
			this.impEspecial = 0;
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			axios.get('api/Facturas/ListarDatosFactura/'+id+'/'+this.localidad+'',configuracion).then(function(response){
				//console.log(response);
				me.detalles=response.data;
				me.EstadoNC = me.detalles[0].estadoNC;
				me.hora = me.detalles[0].hora;
				me.noFacturaElectronica = me.detalles[0].noFactura;
				me.telefonoReceptor = me.detalles[0].telefonoReceptor;
				me.ClaveComprobante = me.detalles[0].claveComprobante;
				me.RefComprobante = me.detalles[0].refComprobante;
				me.comentario = me.detalles[0].cajero;
				const indice = me.detalles.findIndex(detalle => detalle.codigoProducto === 'IMPESP01');

				// Verificar si se encontró el registro
				if (indice !== -1) {
					// Asignar el valor de precioVenta a impEspecial
					me.impEspecial += me.detalles[indice].precioUnitario;

					// Eliminar el registro del arreglo detalles
					me.detalles.splice(indice, 1);
}
			}).catch(function(error){
				console.log(error);
			});
		},
		listarDetallesNota(id){
                let me=this;
                let header={"Authorization" : "Bearer " + this.$store.state.token};
                let configuracion= {headers : header};
                axios.get('api/Facturas/ListarDatosFacturaNota/'+id+'/'+this.localidad+'',configuracion).then(function(response){
                    //console.log(response);
                    me.detalles=response.data;
                    me.EstadoNC = me.detalles[0].estadoNC;
                    me.hora = me.detalles[0].hora;
                    me.noFacturaElectronica = me.detalles[0].claveComprobante.substring(35, 41);
					me.ClaveComprobante = me.detalles[0].claveComprobante;
					me.CedulaReceptor = me.detalles[0].cedulaReceptor;
					me.telefonoReceptor = me.detalles[0].telefonoReceptor;
					me.tipoCedulaReceptor = me.detalles[0].tipoCedulaReceptor;
                    me.comentario = me.detalles[0].detalle;
                }).catch(function(error){
                    console.log(error);
                });
		},
        selectVendedores(){
            let me=this;
            let header={"Authorization" : "Bearer " };
            let configuracion= {headers : header};
            var proveedoresArray=[];
            axios.get('api/Usuarios/SelectUsuariosLocalidad/'+me.$store.state.usuario.localidad,configuracion).then(function(response){
                proveedoresArray=response.data;
                proveedoresArray.map(function(x){
                    me.Vendedores.push(x.nombre);
                });
            }).catch(function(error){
                console.log(error);
            });
        },
        listarLocalidad(){
            let me =this;
            let LogoTest= '';
            let self = this;
            
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
                axios.get('api/Localidades/ListarFiltrada/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
                    //console.log(response);
                    me.localidadencabezado=response.data;
					me.Logo2 = me.localidadencabezado[0].imagen;
					me.modoPDV = me.localidadencabezado[0].modoPDV;
					me.ShowNoFact = me.localidadencabezado[0].express; // Si este campo viene true se muestra el NoFactura del la clave numerica, sino se muestra el NoFactura interno
					me.Cuentas = me.localidadencabezado[0].latitud; // este campo contiene los numero de cuentas bancarios
                    function toDataURL(url, callback) {
                    var xhr = new XMLHttpRequest();
                    xhr.onload = function() {
                        var reader = new FileReader();
                        reader.onloadend = function() {
                        callback(reader.result);
                        }
                        reader.readAsDataURL(xhr.response);
                    };
                    xhr.open('GET', url);
                    xhr.responseType = 'blob';
                    xhr.send();
                    }
                    var url2 = me.Logo2;
                    
                        toDataURL(url2, function(dataUrl) {
                        //console.log('RESULT:', dataUrl)
                        me.Logo3 = dataUrl;
                        }) 
                    }).catch(function(error){
                        console.log(error);
                    });
                
		},
		Recalcular(){
			//console.log(id)
			this.iv = 0;
			this.subTotal = 0;
			this.total = 0;
			for(var i = 0; i < this.detalles.length; ++i)
			{		this.total += parseInt(this.detalles[i].precioUnitario) * (this.detalles[i].cantidad) 
				if(this.detalles[i].gravado == 'S')
				{
					if(this.detalles[i].valorImpuesto == 13)
					{
					this.iv += parseInt(((this.detalles[i].precioUnitario / 1.13) * this.detalles[i].cantidad) * 0.13);
					this.subTotal += parseInt(((this.detalles[i].precioUnitario / 1.13) * this.detalles[i].cantidad) );
					} else
					if(this.detalles[i].valorImpuesto == 4)
					{
					this.iv += (((this.detalles[i].precioUnitario / 1.04) * this.detalles[i].cantidad) * 0.04);
					this.subTotal += parseInt(((this.detalles[i].precioUnitario / 1.04) * this.detalles[i].cantidad) );
					} else
					if(this.detalles[i].valorImpuesto == 2)
					{
					this.iv += (((this.detalles[i].precioUnitario / 1.02) * this.detalles[i].cantidad) * 0.02);
					this.subTotal += parseInt(((this.detalles[i].precioUnitario / 1.02) * this.detalles[i].cantidad) );
					} else
					if(this.detalles[i].valorImpuesto == 1)
					{
					this.iv += (((this.detalles[i].pprecioUnitariorecio / 1.01) * this.detalles[i].cantidad) * 0.01);
					this.subTotal += parseInt(((this.detalles[i].precioUnitario / 1.01) * this.detalles[i].cantidad) );
					} 
					if(this.detalles[i].valorImpuesto == 0)
					{
					this.iv += (((this.detalles[i].pprecioUnitariorecio ) * this.detalles[i].cantidad));
					this.subTotal += parseInt(((this.detalles[i].precioUnitario) * this.detalles[i].cantidad) );
					} 
				} else
				{
					this.iv += 0;
					this.subTotal += parseInt(((this.detalles[i].precioUnitario) * this.detalles[i].cantidad) );
				}
			} 
			
			//console.log(this.cards)
		},
		RecalcularNota(){
		var resultado=0.0;
		var preresult = 0.0;
			for(var i=0;i<this.detalles.length;i++){
					if(this.detalles[i].Ivi == false){  //Si el campo estadoInventario es = a false entonces el IVA se suma
						resultado= (parseFloat(this.totalGravado) + parseFloat(this.calcularImpuesto)+parseFloat(this.totalExento)) - parseFloat(this.totalDescuento);
				} if(this.detalles[i].Ivi == true){
					if(this.monedaPago == 'USD'){
						preresult= (parseFloat(this.totalGravado) + parseFloat(this.calcularImpuesto)+parseFloat(this.totalExento)) - parseFloat(this.totalDescuento);
						resultado = parseFloat(preresult)
						return resultado.toFixed(2);
					}
					preresult= (parseFloat(this.totalGravado) + parseFloat(this.calcularImpuesto)+parseFloat(this.totalExento)) - parseFloat(this.totalDescuento);
					resultado = parseInt(preresult)
				}
			}
			
			
			return resultado.toFixed(2);
		},
		calcularImpuesto:function(){
			var resultado=0.0;
				resultado=this.imp1 + this.imp2+ this.imp4 + this.imp13;
			return resultado.toFixed(2);
		},
        FiltrarCliente(){
            this.usuarios = this.usuarios.filter(e => e.nombreReceptor == this.ClienteSeleccionado);
            this.snackbar = true;
            this.Mensaje = 'Filtro Medio de Pago Seleccionado sobre rango de fecha listado'
        },
		verLocalidad(item){
			this.NombreLocalidad= item.nombre;
			this.CedulaLocalidad= item.cedulaJuridica;
			this.DireccionLocalidad= item.dirreccion;
		},
        ReenvioFactura(){
            if(this.correo2 == ''){
                this.snackbar = true;
                this.Mensaje = 'Digite el correo al que desea reenviar la factura'
                return;
            }
            let header={"Authorization" : "Bearer " + this.$store.state.token};
                let configuracion= {headers : header};
                    let me=this;
                    axios.put('api/CorreosFacturas/Reenviar',{
                        'NoFactura':me.noFactura,
                        'Estado': 0,
                        'Correo2': me.correo2,
                        'ClaveNumerica':me.ClaveComprobante,
                    },configuracion).then(function(response){
                        me.snackbar = true;
                        me.Mensaje = 'Correo reenviado con éxito a '+me.correo2;                     
                    }).catch(function(error){
                        console.log(error);
                        me.snackbar = true;
                        me.Mensaje = 'Reenvio de factura a '+me.correo2+'. Disponible proximamente';   
                    });
        },
		ReprocesarFactura(){
            let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
				let me=this;
				axios.put('api/CorreosFacturas/Reprocesar',{
					'NoFacturaReal':me.noFactura,
					'Localidad':me.$store.state.usuario.localidad,
				},configuracion).then(function(response){
					me.snackbar = true;
					me.Mensaje = 'Factura Reprocesada con éxito ';        
					me.listar();
					me.comprobanteModal = false;             
				}).catch(function(error){
					console.log(error);
					me.snackbar = true;
					me.Mensaje = 'Problema reprocesando su factura, reporte a su equipo de soporte';   
				});
        },
        MostrarComprobante(item){
            this.nombreReceptor= item.nombreReceptor;
            this.cedulaReceptor = item.cedulaReceptor;
            this.vendedor = item.vendedor;
            this.tipoDocumento = item.tipoDocumento;
            this.correoReceptor = item.correoReceptor;
            this.noFactura = item.noFactura;
            this.tipoCedulaReceptor = item.tipoCedulaReceptor;
            this.fecha = item.fecha;
			this.descuento = item.descuento;
            this.subtotal = item.subtotal;
            this.total = item.total;
            this.monedaPago = item.moneda;
            this.nofactura2 = "/ticket/"+this.noFactura;
            this.value = location.hostname+'/perfilcliente/'+this.$store.state.usuario.localidad+'/'+this.cedulaReceptor;
            this.grabado = item.gravado;
            this.exento = item.exento;
            this.iv = item.impuesto;
            this.listarDetalles(item.noFactura);
            this.comprobanteModal = true;
        },
        MostrarComprobante2(value){
            this.MostrarComprobante(value);
            
        },
		imprimirA4() {
			var products = "";
			var cant = 0.0;
			for(var i = 0; i < this.detalles.length; ++i)
				{
					
					cant = this.detalles[i].cantidad
					cant = parseFloat(cant) * parseFloat(this.detalles[i].precioUnitario);
					products += `<tr>
								<td class="col-2 text-1">`+this.detalles[i].gravado+`* `+this.detalles[i].codigoProducto+`</td>
								<td class="col-1 text-1 text-center">`+this.detalles[i].cantidad+`</td>
								<td class="col-7 text-1">`+this.detalles[i].descripcion+`</td>
								<td class="col-1 text-1 text-center">`+this.detalles[i].precioUnitario+`</td>
								<td class="col-1 text-1 text-end">`+this.detalles[i].descuento+`%</td>
								<td class="col-1 text-1 text-end">`+cant+`</td>
								</tr>`
					
				}
			let html = `<html lang="en">
					<head>
					<meta charset="utf-8" />
					<meta http-equiv="X-UA-Compatible" content="IE=edge">
					<meta name="viewport" content="width=device-width, initial-scale=1">
					<link href="images/favicon.png" rel="icon" />
					<title>`+this.tipoDocumento+`: `+this.noFactura+` - `+this.$store.state.usuario.localidad+`</title>
					<meta name="author" content="harnishdesign.net">

					<!-- Web Fonts
					======================= -->
					<link rel='stylesheet' href='https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900' type='text/css'>

					<!-- Stylesheet
					======================= -->
					<link rel="stylesheet" type="text/css" href="https://www.noah.cr/FacturaCSS/vendor/bootstrap/css/bootstrap.min.css"/>
					<link rel="stylesheet" type="text/css" href="https://www.noah.cr/FacturaCSS/vendor/font-awesome/css/all.min.css"/>
					<link rel="stylesheet" type="text/css" href="https://www.noah.cr/FacturaCSS/css/stylesheet.css"/>
					</head>
					<body>
					<!-- Container -->
					<div class="container-fluid invoice-container">
					
					<!-- Main Content -->
					<main>
					<div class="table-responsive">
						<table >
							<thead >
								<tr>
									<td class="col-1 text-start"><img id="logo" src="`+this.localidadencabezado[0].imagen+ `" width="120" height="120" title="NoahLogo" alt="Noah" /></td>
									<td class="col-4 text-1 text-center"> `+this.$store.state.usuario.localidad+ `<br />
									`+this.localidadencabezado[0].razonSocial+ `<br />
									`+this.localidadencabezado[0].dirreccion+ `<br />
									`+this.localidadencabezado[0].cedulaJuridica+ `<br />
									`+this.localidadencabezado[0].telefono+ `<br />
									`+this.localidadencabezado[0].correo+ `<br /> </td>
									<td class="col-1 text-end"><h4 class="text-7 mb-0">`+this.tipoDocumento+ `: `+this.noFactura+ `</h4></td>
								</tr>
							</thead>
						</table>
					</div>
					<hr>
					<table >
						<thead >
							<tr>
								<td class="col-2 text-start text-1"><strong> Cliente:</strong> `+this.nombreReceptor+ `<br /></td>
								<td class="col-2 text-end text-1"><strong> Fecha:</strong> `+this.fecha+  `  Referencia : `+this.RefComprobante+ ` <br /></td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td class="col-2 text-start text-1"><address>
									
									<strong> Cédula :</strong> `+this.cedulaReceptor+ `<br />
									<strong> Teléfono :</strong> `+this.telefonoReceptor+ `<br />
									<strong> Correo :</strong> `+this.correoReceptor+ ` <br />
									
									</address>
								</td>
								<td class="col-2 text-end text-1"><address>
									<strong> Factura:</strong> `+this.noFacturaElectronica +`<br />
									<strong> Vendedor: </strong>  :`+this.vendedor+ `<br />
									<strong> Generado por:</strong>  `+this.$store.state.usuario.nombre+ `
									</address>
								</td>
							</tr>
						</tbody>
					</table>
						
					<div class="card">
						<div class="card-body p-0">
						<div class="table-responsive">
							<table class="table mb-0">
							<thead class="card-header">
							<tr>
								<td class="col-2 text-2"><strong>Código</strong></td>
								<td class="col-2 text-2 text-center"><strong>Cant</strong></td>
								<td class="col-8 text-2"><strong>Descripción</strong></td>
								<td class="col-1 text-2 text-center"><strong>Precio</strong></td>
								<td class="col-2 text-2 text-end"><strong>Desc</strong></td>
								<td class="col-2 text-2 text-end"><strong>Cargo</strong></td>
							</tr>
							</thead>
							<tbody>
								`+products+`
							</tbody>
							<tfoot class="card-footer">
								<tr>
								<td colspan="2" class="text-end"><strong></strong></td>
								<td class="text-0"></td>
								<td colspan="2" class="text-1"><strong>Sub Total: <br> Iva: <br> Impuestos: <br> Total:</strong></td>
								<td class="text-1">`+this.monedaPago+this.subtotal+ ` <br> `+this.monedaPago+this.iv+ ` <br> `+this.impEspecial+ ` <br> `+this.monedaPago+this.total+ `</td>
								</tr>
							</tfoot>
							</table>
						</div>
						</div>
					</div>
					</main>
					<!-- Footer -->
					<footer class="text-center">
					<div style="white-space: pre-line;"><strong>Nota :</strong> `+this.comentario+ ` Clave:`+this.ClaveComprobante+ `  Cuentas:`+this.Cuentas+ ` .</div>
					`+this.localidadencabezado[0].pieDocumento+ `
					</footer>
					</div>
					</body>
				</html>
			`;
			//this.EmailFacturaCorreo(html)
			const nuevaVentana = window.open('' , '_blank');
			nuevaVentana.document.write(html);
			nuevaVentana.document.close();
			setTimeout(() => {
				nuevaVentana.print();
				nuevaVentana.close();
				//location.reload();
			}, 2000);
		//this.$forceUpdate();
		},
        NotaCredito(item){
            this.nombreReceptor= item.nombreReceptor;
			this.idFactura = item.id;
            this.cedulaReceptor = item.cedulaReceptor;
            this.correoReceptor = item.correoReceptor;
            this.noFactura = item.noFactura;
            this.tipoCedulaReceptor = item.tipoCedulaReceptor;
            this.fecha = item.fecha;
            this.monedaPago = item.moneda;
            this.tipoCedula = item.tipoCedulaReceptor;
            this.telefonoReceptor = item.telefonoReceptor;
            this.ClaveComprobante = item.claveComprobante;
			this.tipoPago = item.tipoPago;
            this.descuento = 0;
			this.mensajeRespuesta = item.mensajeRespuesta;
            this.subtotal = item.subtotal;
            this.medioDePago = item.tipoPago;
            this.total = item.total;
            this.grabado = item.gravado;
            this.exento = item.exento;
            this.iv = item.impuesto;
            this.listarDetallesNota(item.noFactura);
            this.ModalNotaCredito = 1;
        },
        OcultarComprobante(){
            this.comprobanteModal = 0;
        },
		CrearNotaCredito() 
		{
			if (this.validarPago() != 1)
			{
				return;
			}
			this.loading = true;
			this.auxVentasInsertar();	
		},
    	obtenerTicket() {
			var self = this;
			var ticketNum = 0;
			var localidadPrevia = this.$store.state.usuario.localidad;

			// Configuración de encabezados
			let header = { "Authorization": "Bearer " };
			let configuracion = {
				headers: header,
				params: { 'localidad': localidadPrevia }
			};

			// Llamada a la API con Axios
			axios.get('api/Facturas/SelectTickets', configuracion).then(function(response) {
				// Verifica si hay datos en la respuesta
				if (response.data && response.data.length > 0) {
					ticketNum = response.data[0].noTicket; // Obtiene el primer número de ticket
				} else {
					console.log("No se encontraron tickets para la localidad proporcionada.");
				}
				alert(ticketNum)
				self.auxVentasInsertar(ticketNum, localidadPrevia);
			}).catch(function(error) {
				self.loading =false;
				console.log("Error al obtener tickets:", error);
			});
		},
        validarPago(){
                this.valida=1;

                if (!this.idcliente && this.tipoFactura == 1){
                    alert("Seleccione un cliente para realizar Nota crédito electrónica");
                    this.valida=0;
                }
                if (!this.tipoCedulaReceptor){
                    alert("Seleccione un tipo de documento.");
                    this.valida=0;
				}
				if (!this.cedulaReceptor){
                    alert("Digite un numero de Cédula.");
                    this.valida=0;
				}
				if (!this.nombreReceptor){
                    alert("Digite un Nombre.");
                    this.valida=0;
                }
                if (this.EstadoNC == 1){
                    alert("Factura ya contiene nota de crédito.");
                    this.valida=0;
                }
                if (this.detalles.length == 0){
                    alert("Debe ingresar productos que facturar.");
                    this.valida=0;
				}
				if (this.justificacion == ''){
                    alert("Debe ingresar una justificación.");
                    this.valida=0;
                }
                return this.valida;
        },
        facturar() {
			if (this.validarPago() != 0)
			{
				this.vueltoPagar = "";
				this.detalleFactura = "";
				this.totalPagar = this.total;
				this.dialogPago = true;
				this.efectivoPagar = "";
				this.tarjetaPagar = "";
				this.obtenerCliente();
			}
			else
			{
				this.dialogPago = false;
				return;
			}
        },
        obtenerCliente(){
			var self = this;
			var clientesArray=[];
			var clientePrevio="";
			if (this.idcliente.text === undefined)
			{
				clientePrevio = this.idcliente;
			}
			else
			{
				clientePrevio = this.idcliente.text;
			}
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			axios.get('api/Empleados/ObtenerCliente', { params: {'clienteFactura': clientePrevio}},configuracion).then(function(response){
				clientesArray=response.data;
				for(var i = 0; i < clientesArray.length; ++i)
				{
					self.clienteNombre = clientesArray[i].nombre;
					self.clienteCorreo = clientesArray[i].correo;
					self.clienteCedula = clientesArray[i].cedula;
					self.clienteTipoCedula = clientesArray[i].tipoCedula;
					self.clienteTelefono = clientesArray[i].telefono;
					break;
				} 
			})
        },
        auxVentasInsertar(){
			var self = this;
			var documentoHacienda = "";
			var formaPago = 0;
			var momentoActual = new Date(); 
			var hora = momentoActual.getHours(); 
			var minuto = momentoActual.getMinutes(); 
			var segundo = momentoActual.getSeconds();
			var pagoTarjeta = 0;
			var pagoEfectivo = 0;
			var pagoDeposito = 0;
			if(this.tipoPago == "TARJETA"){
				pagoTarjeta = this.total;
			} else
			if(this.tipoPago == "DEPOSITO"){
				pagoDeposito = this.total;
			} else
			if(this.tipoPago == "EFECTIVO"){
				pagoEfectivo = this.total;
			} 

			let header={"Authorization" : "Bearer "};
                let configuracion= {headers : header};
                axios.post('api/Facturas/CrearAux',{
					'Fecha': this.today.toString(),//MM/dd/YYYY
					'Detalle':"NOTA DE CREDITO",
					'Gravado': parseFloat(this.grabado),
					'Exento': parseFloat(this.excento),
					'Iv': parseFloat(this.iv),
					'Total': this.total,
					'Localidad': this.$store.state.usuario.localidad,
					'Cajero': this.$store.state.usuario.nombre,
					'TipoPago': this.tipoPago,
					'Descuento': this.descuento,
					'ImpServicio': 0,
					'MontoExpress': 0,
					'SubTotal': this.subtotal,
					'MontoExpres': 0,
					'TipoDocumento': 'NOTA DE CREDITO',
					'Nocomprobante': 0,
					'NoNotaCredito': "",
					'CondicionVenta': "CONTADO",
					'ClaveComprobante': 0,
					'Moneda': this.monedaPago.toUpperCase(),//USD O CRC
					'RefFacturaReal': this.noFactura,
					'RefComprobante': this.ClaveComprobante,
					'razonReferencia': this.justificacion,
					'MensajeRespuesta': "", //
					'NombreReceptor': this.nombreReceptor,
					'CedulaReceptor': this.cedulaReceptor,
					'CorreoReceptor': this.correoReceptor,
					'Razon': this.justificacion,
					'RefFecha': this.fecha,
					'Vuelto': 0,
					'Impuesto1': 0,
					'Impuesto4 ': 0,
					'Impuesto13': this.iv,//Solo trabaja con 0 o 13%
					'Impuesto2': 0,
					'Sincronizado ': 0,
					'Vendedor': this.$store.state.usuario.nombre,
					'TipoCambio': 590,
					'TotalSinR': this.total,
					'Hora': hora + ":" + minuto + ":" + segundo,
					'Proforma': 0,
					'TotalTarjeta':pagoTarjeta,
					'TotalEfectivo': pagoEfectivo,
					'TotalSimpe':pagoDeposito,
					'TotalDeposito':0,
					'UtilidadFactura':0,
                      
                },configuracion).then(function (response) {
                    self.datosFactura(response.data);
					console.log(response.data);
				})
                  .catch(function (error) {
					self.loading =false;
					alert("Lo sentimos ocurriò un error: "+ response.data)
			})
        },
    	async datosFactura(ticketNum){
			var momentoActual = new Date() 
			var hora = momentoActual.getHours() 
			var minuto = momentoActual.getMinutes() 
			var segundo = momentoActual.getSeconds() 
			var self = this;
			var documentoHacienda = 0;
			documentoHacienda = this.tipoFactura;
			
			for(var i = 0; i < this.detalles.length; ++i)
			{
			this.productosDatos.push(
				{
					CodigoProducto:this.detalles[i].codigoProducto,
					Cantidad:this.detalles[i].cantidad,
					UnidadMedida:"UND",
					Descripcion:this.detalles[i].descripcion,
					PrecioUnitario:this.detalles[i].precioUnitario,
					MontoDescuento:this.detalles[i].descuento,
					ValorImpuesto:this.detalles[i].valorImpuesto,
					Gravado:this.detalles[i].gravado,
					CodigoHacienda: this.detalles[i].codigoHacienda,
					EstadoImp : this.detalles[i].estadoImp,
				}
				);
			}
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
				await axios.post('api/Facturas/CrearDatosNota',{
                      'NoFacturaReal': ticketNum,
					  'TipoDocumento': 3,
					  'Hora': hora + ":" + minuto + ":" + segundo,
                      'Nocomprobante': 0,
                      'CondicionVenta':"CONTADO",
                      'Moneda': this.monedaPago.toUpperCase(),//USD O CRC
                      'MedioDePago': "EFECTIVO",
                      'CodigoProducto': "",
                      'Cantidad': 0,
                      'UnidadMedida': "UND",
                      'Descripcion': "",
                      'PrecioUnitario': 0,
                      'MontoDescuento': 0,
                      'DiasCredito': 15,
                      'Gravado': "",//S O N  S= GRAVADO O N= EXENTO
                      'Cajero': this.$store.state.usuario.nombre,
                      'ClaveComprobante':0,
                      'RefFacturaReal': this.noFactura,
                      'RefComprobante': this.ClaveComprobante,
                      'razonReferencia': this.justificacion,
                      'MensajeRespuesta': this.MensajeRespuesta,
                      'CodicionComprobante': "",
                      'EstadoComprobante': 0,
                      'FechaEnvio': "",
                      'FechaEstado': "",
                      'NombreReceptor': this.nombreReceptor,
                      'CedulaReceptor': this.cedulaReceptor,
                      'CorreoReceptor': this.correoReceptor,
                      'TipoCedulaReceptor': this.tipoCedulaReceptor.toUpperCase(), //FISICA, JURIDICA, EXTRANJERO
                      'TelefonoReceptor': this.telefonoReceptor,
                      'Localidad': this.$store.state.usuario.localidad,
                      'Razon': this.justificacion,
                      'RefFecha': this.fecha + " " + this.hora,
                      'Exonerado': false,
                      'Correo2': "",
                      'ValorImpuesto': 0, //0,1,2,4,13
                      'Detalle': this.detalle + "Factura original: ",
                      'Sincronizado ': 0,
                      'Vendedor':this.$store.state.usuario.nombre,
                      'EstadoNC': 0,
                      'EstadoCierre': 0,
                      'Producto': this.productosDatos,
                      'NumeroMM': "",
                  },configuracion).then(function (response) {
                    self.Mensaje = 'Nota de crédito realizada correctamente';
                    self.snackbar = true,
					self.loading =false;
					location.reload();
                  })
                  .catch(function (error) {
					self.loading =false;
                    alert("Error en nota de crédito, favor revisar sus información o solicite ayuda al 4070-1889 "+error );
			})
        },
        async insertarTicket(ticketNum, localidad){
			this.dialogPago = false;
			this.productos = [];
			this.barbero = "";
			this.idcliente= "";
			this.totalPagar = "";
			this.vueltoPagar = "";
			this.limpiar();
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			this.loading  = false;
			await axios.post('api/Facturas/CrearTicket',{
				'NoTicket':ticketNum,
				'Operador':localidad,
				'Localidad':localidad,
			},configuracion).then(function(response){ 
				location.reload();             
			}).catch(function(error){
				console.log(error);
			});
   	 	},
	
        eliminarProducto(id){
            //console.log(id)
            this.detalles = this.detalles.filter(e => e.idproducKey != id)
            this.iv = 0;
            this.subTotal = 0;
            this.total = 0;
			this.Recalcular();
            //console.log(this.cards)
        },
		limpiar(){
			this.MensajeRespuesta="";
			this.ClaveComprobante="";
			this.localidad="";
			this.detalles=[];
			this.name = '',
			this.details = '',
			this.start = '',
			this.end = '',
			this.color = '',
			this.hora = '',
			this.hora2 = '',
			this.codigo = ''
    	},  
		crearPDF(){
			var columns = [
			{title: "Fac", dataKey: "noFactura"},
			{title: "Fecha", dataKey: "fecha"}, 
			{title: "Pago", dataKey: "Pago"},
			{title: "Total", dataKey: "Total"},
			{title: "Vendedor", dataKey: "vendedor"},
			{title: "Cliente", dataKey: "cliente"},
			];
			var rows = [];

			this.usuarios.map(function(x){
				rows.push({Total:x.total,noFactura:x.noFactura,Pago:x.tipoPago,fecha:x.fecha,ventaTotal:x.ventaTotal,cliente:x.nombreReceptor,vendedor:x.vendedor});
			});

			// Only pt supported (not mm or in)
			var doc = new jsPDF('p', 'pt');
			doc.autoTable(columns, rows, {
				margin: {top: 60},
				addPageContent: function(data) {
					doc.text("Listado de Ventas ", 40, 30);
				}
			});
			doc.save('Reporte Ventas en '+this.$store.state.usuario.localidad+'.pdf');
        },
        crearPDFFactura(){
            var fact = this.noFactura;
			var quotes = document.getElementById('factura');
                html2canvas(quotes).then(function (canvas) {
                    var imgData = canvas.toDataURL('https://i.ibb.co/7rj9fLf/the-one-blanco.jpg');
                    var imgWidth = 210;
                    var pageHeight = 285;
                    var imgHeight = canvas.height * imgWidth / canvas.width;
                    var heightLeft = imgHeight;
                    var doc = new jsPDF();
                    var position = 0;

                    doc.addImage(imgData, 'JPG', 0, position, imgWidth, imgHeight);                    
                    doc.save('ComprobanteVenta'+fact+'.pdf');
                });
        },
        getColor (mensajeRespuesta) {
			if (mensajeRespuesta == 'aceptado') return '#8BC34A'
			if(mensajeRespuesta == 'Sin procesar') return 'orange'
			if(mensajeRespuesta == 'procesando') return '#42A5F5'
			if(mensajeRespuesta == 'rechazado') return '#FF1744'
			else  return '#42A5F5'
		},
       	listar(){
			let me=this;
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
            axios.get('api/RepVentasVistas/ListarHacienda/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
                //console.log(response);
				me.usuarios=response.data;
				me.loading = false;
            }).catch(function(error){
                console.log(error);
            });
        },
        listarFechas(){
            if(this.start == '' || this.end == ''){
                this.snackbar = true;
                this.Mensaje = 'Debe seleccionar una fecha inicial y final'
                return;
		   }
		   	this.loading = true;
            let me=this;
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
            axios.get('api/RepVentasVistas/ConsultaFechasHacienda/'+this.start+'/'+this.end+'/'+this.localidad,configuracion).then(function(response){
                //console.log(response);
				me.usuarios=response.data;
				me.loading = false;
				me.ListarResumen();
            }).catch(function(error){
				me.loading = false;
				me.ListarResumen();
				alert("Lo sentimos, no se pudo obtener la información, intente en unos minutos.");
                console.log(error);
            });
        },
		ListarResumen(){
            if(this.start == '' || this.end == ''){
                this.snackbar = true;
                this.Mensaje = 'Debe seleccionar una fecha inicial y final'
                return;
		   }
            let me=this;
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
            axios.get('api/RepVentasVistas/CajerosVendedoresPorFecha/'+this.start+'/'+this.end+'/'+this.localidad,configuracion).then(function(response){
                //console.log(response);
				me.VentasVendedores=response.data;
            }).catch(function(error){
                console.log(error);
            });
        },

		close () {
			this.dialog = false;
			this.limpiar();
		},
      	activarDesactivarMostrar(accion,item){
			this.adModal=1;
			this.adNombre=item.nombre;
			this.adId=item.idusuario;                
			if (accion==1){
				this.adAccion=1;
			}
			else if (accion==2){
				this.adAccion=2;
			}
			else{
				this.adModal=0;
			}
		},
		activarDesactivarCerrar(){
			this.adModal=0;
		},
      	save () {
			if (this.editedIndex > -1) {
			Object.assign(this.desserts[this.editedIndex], this.editedItem)
			} else {
			this.desserts.push(this.editedItem)
			}
			this.close()
      	},
    },
  }

</script>
<style scoped>
	::v-deep .v-data-table tbody tr:nth-child(odd) {
	background-color: #f5f5f5; /*color de fondo para filas impares*/
	}

	::v-deep .v-data-table tbody tr:nth-child(even) {
	background-color: #ffffff; /*color de fondo para filas pares*/
	}
	::v-deep .my-custom-class tbody tr:nth-child(odd) {
	background-color: #f5f5f5; /*color de fondo para filas impares*/
	}

	::v-deep .my-custom-class tbody tr:nth-child(even) {
	background-color: #ffffff; /*color de fondo para filas pares*/
	}
</style>