<template>
	 <div>
        <v-data-table
            :headers="headers"
            :items="detalles"
            :search="search"
			:loading="loading"
            class="elevation-1 pa-1"

            >
            <template v-slot:item.cantidadCompraRequerida="props">
				<v-edit-dialog
				:return-value.sync="props.item.cantidadCompraRequerida"
				large
				>
				{{ props.item.cantidadCompraRequerida }} <br>
				<template v-slot:input>
					<v-text-field v-model="props.item.cantidadCompraRequerida" label="Inventario"/>
				</template>
				</v-edit-dialog>
			</template>
            <template v-slot:top>
                <v-row class="pa-2">
                    <v-col cols="12" lg="3" md="5" sm="8" xs="12">
                        <v-card-actions class="rounded-xl light-blue lighten-5 mx-5">
                                <vue-excel-xlsx
                                    :data="detalles"
                                    :columns="columns"
                                    :filename="'Reporte de Minimos '+$store.state.usuario.localidad"
                                    :sheetname="'Ventas'"
                                    >
                                    <v-btn fab="" color="success">  <v-icon large="">mdi-file-excel </v-icon> </v-btn>
                                </vue-excel-xlsx> 
                            <v-card-title>Minimos</v-card-title>
                        </v-card-actions>
                    </v-col>
                    <v-col cols="12" lg="4" md="7" sm="4" xs="12">    
                        <v-text-field filled v-model="search"  append-icon="mdi-magnify"
                         label="Buscar" hide-details dense
                        ></v-text-field>
						<v-select dense hide-details @change="listarFechas" :items="Localidades" filled label="Localidad" v-model="Localidad"> </v-select>
                    </v-col>
                    <v-col cols="12" lg="2" md="6" sm="6" xs="6">
                        <v-select filled clearable @change="BuscarMinimos()" :items="Proveedores" v-model="proveedor" type="date" label="Proveedor"></v-select>
                    </v-col>
                    <v-col cols="12" lg="2" md="5" sm="5" xs="5">
                        <v-select filled clearable @change="BuscarMinimos()" :items="familias" v-model="familia"  type="date" label="Familia"></v-select>
                    </v-col>
                </v-row>
               
            </template>

            <template v-slot:no-data>
                <v-btn color="primary" @click="Reiniciar()">Reiniciar</v-btn>
            </template>
        </v-data-table>
        <template class="pa-3">
            <v-snackbar
                    v-model="snackbar" top vertical="" :timeout="5000"
                    >
                    {{ Mensaje }}
                    <v-btn
                    color="info"
                    text
                    @click="snackbar = false"
                    >
                    Cerrar
                    </v-btn>
            </v-snackbar>
        </template>
    </div>
	
</template>


<script>

import VueExcelXlsx from "vue-excel-xlsx";
import axios from 'axios';
  export default {
    name: 'ReporteMinimos',
    data() {
    return {
        TipoCedula: '',
        detalles: [],
		Localidades: [],
		search:'',
		loading:true,
        codigoProducto: null,  // Puedes inicializar con null o algún valor por defecto
        proveedor: null,       // Si no tienes un valor inicial, null es una buena opción
        familia: null,         // De la misma manera, puedes inicializar con null
		familias:[],
		Localidad:'',
		Proveedores:[],
		headers: [
		{ text: 'Código', value: 'codigoProducto' },
		{ text: 'Nombre', value: 'descripcion' },
		{ text: 'Cat 1', value: 'familia' },
		{ text: 'Familia', value: 'familia' },
		{ text: 'Proveedor', value: 'proveedor' },
		{ text: 'Precio Venta', value: 'precioVenta' },
		{ text: 'Cantidad Requerida', value: 'cantidadCompraRequerida' },
		],
		columns : [
			{ label: 'Código', field: 'codigoProducto'},  
			{ label: 'Nombre', field: 'descripcion'},
			{ label: 'Precio Venta', field: 'precioVenta' },
			{ label: 'Costo Sin IVA', field: 'precioCompra',  },
			{ label: '% Imp ', field: 'valorImpuesto'  },
			{ label: 'IVI', field: 'estadoInventario' },
			{ label: 'Cantidad Ingresar', field: 'cantidadCompraRequerida' },
			{ label: 'Impuesto', field: 'valorImpuesto'},
			{ label: "Familia",field: "familia", },
			{ label: "Codigo Barras",field: "idarticulo", },
        ],
		};
	},
	created () {
		this.listarAsociadas();
		this.BuscarMinimos();
		this.selectProveedores();
		this.selectFamilia();
    },
    methods: {
		async listarAsociadas(){
			var items = [];
			let me=this;
			me.Localidades.push(me.$store.state.usuario.localidad);
			this.Localidad = this.$store.state.usuario.localidad
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			await axios.get('api/LocalidadesAsociadas/'+this.$store.state.usuario.nombre,configuracion).then(function(response){
				items=response.data;
				items.map(function(x){
					me.Localidades.push(x.localdiadAsociada);
				});
			}).catch(function(error){
				alert("Producto no encontrado");
				me.modelCarga=false;
			});
    	},
		selectFamilia(){
			let me=this;
			this.familias = [];
			let header={"Authorization" : "Bearer " };
			let configuracion= {headers : header};
			var familiasArray=[];
			axios.get('api/Familias/Select/'+me.$store.state.usuario.localidad,configuracion).then(function(response){
				familiasArray=response.data;
				familiasArray.map(function(x){
					me.familias.push(x.nombre);
				});
			}).catch(function(error){
				console.log(error);
			});
		},
		selectProveedores(){
				let me=this;
				let header={"Authorization" : "Bearer " };
				let configuracion= {headers : header};
				var proveedoresArray=[];
				axios.get('api/proveedores/Select/'+me.$store.state.usuario.localidad,configuracion).then(function(response){
					proveedoresArray=response.data;
					proveedoresArray.map(function(x){
						me.Proveedores.push(x.proveedor);
					});
				}).catch(function(error){
					console.log(error);
				});
		},
		Reiniciar(){
			this.proveedor = null;
			this.familia = null;
			this.BuscarMinimos();
		},
		async BuscarMinimos(){
			this.loading = true;
			let header = {"Authorization" : "Bearer "}; // Nota: parece que falta el token de autenticación.
			let configuracion = {headers : header};
			let me = this;
			let params = {
				localidad: this.Localidad
			};
			
			// Añadir proveedor y familia si están definidos.
			if (this.proveedor) {
				params.proveedor = this.proveedor;
			}
			if (this.familia) {
				params.familia = this.familia;
			}

			await axios.get('api/ProductosWebs/ReporteMinimos', {
				params: params,
				headers: configuracion.headers
			})
			.then(function(response){
				me.detalles = response.data;
				me.loading = false;
			})
			.catch(function(error){
				alert("Problema obteniendo el reporte de mínimos, envie una foto de este problema a su equipo de soporte. Error: " + error);
			})
			.finally(() => {
				this.loading = false;
			});
		}


    },
  }
</script>
