<template>
  <v-container fluid>	
		<div class="text-center">
			<v-overlay :value="modelCarga">
			<v-progress-circular
				indeterminate
				size="64"
			></v-progress-circular>
			</v-overlay>
		</div>
		<v-snackbar
                v-model="snackbar" top vertical="" :timeout="4000"
                >
                {{ Mensaje }}
                <v-btn
                color="pink"
                text
                @click="snackbar = false"
                >
                Close
                </v-btn>
        </v-snackbar>
		
          <v-row>
			<v-toolbar  dark color="primary" class="font-weight-light body-1">
				<v-list-item-content >
					<v-list-item-title>Productos Compuestos - {{Compuesto}}</v-list-item-title>
					<v-list-item-subtitle>Arma tu combo o extras de un producto</v-list-item-subtitle>
				</v-list-item-content>
       		 </v-toolbar>
            <v-col cols="12" sm="12">
            <v-form ref="form" v-model="validFactura"  @submit.prevent="facturar">
            <v-row>
              <v-col class="mt-2" cols="12" sm="6">
                 <v-combobox v-model="ProductoCompuesto" @change="buscarCompuesto()" :items="productos" label="Producto Compuesto a trabajar"></v-combobox>
              </v-col>
              <v-col class="mt-2" cols="12" sm="6">
                 <v-text-field v-model="codigo" @keyup.enter="buscarProductoDirecto()"  label="Escanear Código"></v-text-field>
              </v-col>
                <v-col cols="12" sm="12">
                  <v-combobox :disabled="ProductoCompuesto == ''" clearable @change="buscarProductoDirecto()" :items="compuestos" v-model="codigo" label="Busqueda de producto por nombre"></v-combobox>
                </v-col>
                <v-col cols="12" sm="12">
					<v-data-table
						:headers="cabeceraDetalles"
						item-key="name" 
						single-select
						:items="detalles"
						class="elevation-1"
						v-model="selected"
						@click:row="rowClick">
						<template slot="no-data">
							<h3>No hay artículos agregados al detalle.</h3>
						</template> 
						<template v-slot:item.descripcion="props">
							<v-edit-dialog
							:return-value.sync="props.item.descripcion"
							@save="UpdateDescription(props.item)"
							@cancel="cancel"
							@close="close"
							large
							>
							{{ props.item.descripcion }} <br>
							<template v-slot:input>
								<v-text-field
								onfocus="this.select();"
								v-model="props.item.descripcion"
								
								:rules="[max25chars]"
								:label="'Modifique la descripción: '"
								></v-text-field>
							</template>
							</v-edit-dialog>
						</template>
						<template v-slot:item.actionmovimiento="{ item }">
						<v-icon
							large
							color="error"
							@click="BorrarAsociado(item)"
						>
							mdi-delete
						</v-icon>
						</template>
						<template v-slot:item.actionvalor="{ item }">
						<v-checkbox
							@change="UpdateValorCero(item)"
							v-model="item.valorCero"
							:label="`No suma precio`"
						></v-checkbox>
						</template>

					</v-data-table>
                </v-col>
              </v-row>
              </v-form>
              </v-col>
          </v-row>
  </v-container>
</template>


<script>
  
  import axios from 'axios';
  export default {
    name: 'Compuestos',
    data () {
      return {
        ProductoCompuesto : '',
        productos:[],
        modelCarga: false,
		PosicionProductos: [],
		snackbar : false,
		Mensaje :"",
		codigo:"",
		detalles:[],
		Compuesto:'',
		compuestos: [],
		cabeceraDetalles: [
		{ text: 'Descripción', value: 'descripcion'},
		{ text: 'Precio', value: 'precio'},  
		{ text: 'C_Compuesto', value: 'codigoCompuesto'},  
		{ text: 'C_Producto', value: 'codigoAsoiado'}, 
		{ text: 'Valor 0', value: 'actionvalor'},
		{ text: 'Eliminar', value: 'actionmovimiento'},
		],
      }
    },
	created () {
    	this.selectProductos();
		this.selectCompuestos();
	},
    methods: {
		buscarCompuesto(){  
			if (this.ProductoCompuesto.value !== undefined) {
				this.Compuesto = this.ProductoCompuesto.text;
				this.ProductoCompuesto = this.ProductoCompuesto.value;
			} else {
				this.Compuesto = '';
			}

			let me=this;
			this.modelCarga = true;
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			axios.get('api/PosicionProductoCajas/ListarAsociado/'+this.ProductoCompuesto+'/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
				me.detalles=response.data;
				me.modelCarga = false;
				});
		},
		selectProductos(){
			let me=this;
			let header={"Authorization" : "Bearer " };
			let configuracion= {headers : header};
			var productosArray=[];
			axios.get('api/Productos/SelectProductos/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
				productosArray=response.data;
				productosArray.map(function(x){
					me.compuestos.push({text:x.descripcion, value:x.codigoProducto});
				});
				me.modelCarga = false;
			}).catch(function(error){
				console.log(error);
			});
		},
		selectCompuestos(){
			let me=this;
			let header={"Authorization" : "Bearer " };
			let configuracion= {headers : header};
			var productosArray=[];
			axios.get('api/Productos/SelectProductosCompuestos/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
				productosArray=response.data;
				productosArray.map(function(x){
					me.productos.push({text:x.descripcion, value:x.codigoProducto});
				});
				me.modelCarga = false;
			}).catch(function(error){
				console.log(error);
			});
		},
		buscarProductoDirecto(){
			let me=this;
			this.codigo = this.codigo.value !== undefined ? this.codigo.value : this.codigo;
			if (this.codigo != "")
			{
				let header={"Authorization" : "Bearer "};
				let configuracion= {headers : header};
				axios.get('api/Productos/BuscarCodigoVenta/'+this.codigo+'/'+this.$store.state.usuario.localidad,configuracion)
				.then(function(response){
					me.agregarDetalleDirecto(response.data);
				}).catch(function(error){
					// me.snackbar = true;
							//me.Mensaje = "Producto No encontrado";
				});
			}
		},
		
		agregarDetalleDirecto(data = []){
			let ValorCero = false;
			let me = this;
			if(confirm("Agregar Valor 0? al seleccionar si el producto no sumará el precio")){
				ValorCero= true;
			}
			this.modelCarga = true;
			this.detalles.push(
				{
				id: this.detalles.length + 5, 
				codigoAsoiado:data['codigoProducto'],
				codigoCompuesto: this.ProductoCompuesto,
				descripcion:data['descripcion'],
				precio:data['precioVenta'],
				valorCero: ValorCero,
				}
				);
			
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			axios.post('api/PosicionProductoCajas/CrearPosicion',{
						'codigoAsoiado':data['codigoProducto'],
						'codigoCompuesto': this.ProductoCompuesto,
						'descripcion': data['descripcion'],
						'Precio': data['precioVenta'],
						'ValorCero': ValorCero,
						'Localidad': this.$store.state.usuario.localidad,
						'Id':this.detalles.length + 5, 
						
				},configuracion).then(function (response) {
						me.Mensaje = "Producto Agregado con éxito.";
                    	me.snackbar = true;
						me.modelCarga = false;
					})
					.catch(function (error) {
						me.modelCarga = false; 
						alert(`Problema Cargando el producto a la base de datos, compruebe la existencia`+error); 
						
				})	
			this.codigo = '';
		},
		BorrarAsociado(item){

			if(confirm("Seguro que quiere eliminar producto asociado? "+item.descripcion)){
			this.modelCarga = true;
			let me = this;
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			axios.delete('api/PosicionProductoCajas/EliminarAsociado232/'+item.id,{
						'Id':item.id
						
				},configuracion).then(function (response) {
						me.snackbar = true;
						me.Mensaje = "Eliminado con éxito";
						me.detalles = me.detalles.filter(e => e.id != item.id)
						me.modelCarga = false;
					})
					.catch(function (error) {
						me.modelCarga = false;
						alert(`Error Eliminando, la pagina se recargará para obtener Ids`);  
						location.reload();
				})
			}
		},
		async UpdateValorCero(item){
			this.modelCarga = true;
			let me = this;
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			await axios.post('api/PosicionProductoCajas/UpdateAsociado',{
						'codigoAsoiado':item.codigoAsoiado,
						'ValorCero': item.valorCero,
						'Localidad': this.$store.state.usuario.localidad,
						
				},configuracion).then(function (response) {
						me.snackbar = true;
						me.Mensaje = "Actualizado con éxito";
						me.modelCarga = false;
					})
					.catch(function (error) {
						me.modelCarga = false;
						alert(`Error Actualizando`+error);  
				})
			
		},
		async UpdateDescription(item){
			this.modelCarga = true;
			let me = this;
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			await axios.post('api/PosicionProductoCajas/UpdateDescription',{
						'codigoAsoiado':item.codigoAsoiado,
						'Descripcion': item.descripcion,
						'Localidad': this.$store.state.usuario.localidad,
						
				},configuracion).then(function (response) {
						me.snackbar = true;
						me.Mensaje = "Actualizado con éxito";
						me.modelCarga = false;
					})
					.catch(function (error) {
						me.modelCarga = false;
						alert(`Error Actualizando`+error);  
				})
			
		},
	}
  }
</script>
