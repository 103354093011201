<template>
    <div>
	<v-snackbar
		color="red accent-2"
		v-model="snackbar" top vertical="" :timeout="5000"
		>
		<v-alert
		prominent
		type="error"
		>
		<v-row align="center">
			<v-col class="grow">
			{{ Mensaje }}
			</v-col>
			<v-col class="shrink">
			<v-btn @click="snackbar = false">Cerrar</v-btn>
			</v-col>
		</v-row>
		</v-alert>
	<!-- FACTURA -->
	<div id="bill" style="display: none;">
		<header>
			<table >
				<thead>
				<tr>
					<th><div id="logo">
							<h4> Comprobante de abono  </h4>
						</div>
					</th>
					<th>
						<div v-for="det in localidadencabezado" :key="det.nombre" id="datos">
							
								<h2> {{det.nombre}} </h2> <h3> {{det.razonSocial}} </h3>{{det.dirreccion}} Costa Rica<br> 
									{{det.cedulaJuridica}} <br>{{det.telefono}}<br>
								{{det.correo}}
							
						</div>
					</th>
				</tr>
				</thead>
				<tbody>
				<tr>
				</tr>
				</tbody>
			</table>
		</header>
		<div style="margin: 0 auto; text-align: center;">
		<br>
		<label style="font-size: medium; font-weight: bold;">Abono a Crédito</label>
		<br>
		<label style="font-size: medium;">{{$store.state.usuario.localidad}}</label>
		<br>
		<label style="font-size: medium;">Tipo Movimiento </label>
		<br>
		<label style="font-size: medium;">Usuario: {{$store.state.usuario.nombre}}</label>
		<br><br>
		<v-divider color="black"></v-divider>
		<br>
		</div>
		<div style="margin: 0 auto;">
		<label style="font-size: medium;">Fecha: {{Fecha}}</label><br>
		<label style="font-size: medium;">Hora: {{hora}}</label><br>
		<label style="font-size: medium;">Cliente: {{cliente}}</label>
		<br><br>
		<v-divider color="black"></v-divider>
		<div style="display: grid; grid-template-columns: auto auto auto;">
			<table>
				<tr>
					<th>Fact</th>
					<th>Saldo</th>
					<th>Vendedor</th>
					<th>Detalle</th>
				</tr>
				<tr v-for="item in detalles"
					:key="item" >
					<td>{{item.factura}}</td>
					<td>{{item.credito}}</td>
					<td>{{item.cajero}}</td>
					<td>{{item.detalle}}</td>
				</tr>
				</table>
		</div>
		<br>
		<div style="display: grid; grid-template-columns: auto auto auto;">
			<table>
				<tr>
					<th>Total</th>
					<th>Abono</th>
					<th>Saldo</th>
				</tr>
				<tr>
					<td>{{total}}</td>
					<td>{{abono}}</td>
					<td>{{calcularSaldo}}</td>
				</tr>
				</table>
		</div>
		
		<v-divider color="black"></v-divider>
		<br><br>
		<label style="font-size: medium;">Sign:_______________</label> <br>
		<label style="font-size: medium;">Nota: {{Nota}}</label>
		
		<br>
		</div>
	</div>
	<!-- FACTURA -->
    </v-snackbar>
		<v-dialog
			v-model="dialogAbono"
			persistent
			max-width="1200px"
			>
			<v-card>
				<v-toolbar dark color="primary" > 
					<v-toolbar-title > Abonos de {{cliente}} a la factura {{factura}} </v-toolbar-title>
					<v-spacer/> <v-btn @click="dialogAbono = false" color="red" text> Cerrar </v-btn>
				</v-toolbar>
				<v-simple-table>
					<template v-slot:default>
					<thead>
						<tr>
						<th class="text-left">
							Usuario
						</th>
						<th class="text-left">
							Cliente
						</th>
						<th class="text-left">
							Fecha
						</th>
						<th class="text-left">
							Hora
						</th>
						<th class="text-left">
							Abono
						</th>
						<th class="text-left">
							Tipo Pago
						</th>
						<th class="text-left">
							Interes
						</th>
						<th class="text-left">
							Saldo Actual
						</th>
						<th class="text-left">
							Saldo Anterior
						</th>
						<th class="text-left">
							Factura
						</th>
						<th class="text-left">
							Monto Interes
						</th>
						<th class="text-left">
							Reintegro
						</th>
						<th class="text-left">
							Reimprimir
						</th>
						</tr>
					</thead>
					<tbody>
						<tr
						v-for="item in abonos"
						:key="item.name"
						>
						<td>{{ item.usuario }}</td>
						<td>{{ item.cliente }}</td>
						<td>{{ item.fecha }}</td>
						<td>{{ item.hora }}</td>
						<td>{{ item.abono }}</td>
						<td>{{ item.tipoCompra }}</td>
						<td>{{ item.interes }}</td>
						<td>{{ item.saldoActual }}</td>
						<td>{{ item.saldoAnterior }}</td>
						<td>{{ item.noFactura }}</td>
						<td>{{ item.montoInteres }}</td>
						<td>{{ item.reintegro }}</td>
						<td> <v-icon @click="ImprimirAbono(item)">mdi-printer</v-icon> </td>
						</tr>
					</tbody>
					</template>
				</v-simple-table>
			</v-card>
		</v-dialog>
		<v-dialog
			v-model="dialogNota"
			max-width="390"
			>
			<v-card>
				<v-card-title class="headline">
					Nota de crédito como pago
				</v-card-title>
				<v-text-field hint="Digite el numero de factura interno de nota de crédito a cual desea aplicar como método de pago para esta venta"
				label="No Factura interno" autofocus @keyup.enter="BuscarNotaCredito()" dense rounded v-model="noFactura" outlined> </v-text-field>
				<v-card-actions>
				<v-spacer></v-spacer>

				<v-btn
					color="error"
					text
					@click="dialogNota = false"
				>
					Salir
				</v-btn>
				<v-btn
					color="green darken-1"
					text
					@click="BuscarNotaCredito()"
				>
					Buscar Documento
				</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
        <v-dialog v-model="comprobanteModal" max-width="1000px">
            <v-card>
            
                <v-card-text>
                    <v-btn @click="crearPDFFactura()"><v-icon>mdi-printer</v-icon></v-btn>
                    <div id="factura">
                        <header>
                            <div v-for="det in localidadencabezado" :key="det.nombre" id="datos">
                                <p id="encabezado">
                                    <b class="primary--text" >{{det.nombre}} </b> <br>{{det.razonSocial}},<br> {{det.dirreccion}}, Costa Rica<br> 
                                    <v-icon> mdi-card-bulleted </v-icon>: {{det.cedulaJuridica}} <br><v-icon>mdi-phone </v-icon> {{det.telefono}}<br>
                                    <v-icon>mdi-email </v-icon>{{det.correo}}
                                </p>
                            </div>
                            <div style="text-align:right;" class="font-weight-regular  body-2" id="fact">
                                <p>{{tipoCompra}}<br>
                               NoFactura: {{factura}}<br>
                               {{fecha}}--{{hora}} <br>
                               Vendedor: {{vendedor}}</p>

                            </div>
                            
                        </header>
                        <br>
                        <section>
                            <div>
                                    <tbody>
                                        <tr>
                                            <td id="cliente">
                                                <strong class="primary--text"> Datos del cliente: </strong> <br>
                                                <strong><v-icon>mdi-account </v-icon></strong> {{nombre}}<br>
                                                <strong><v-icon>mdi-card-bulleted </v-icon></strong> {{cedula}} <br>
                                                <strong><v-icon>mdi-email </v-icon></strong> {{correo}} <br>
                                                <strong><v-icon>mdi-card-account-phone </v-icon></strong> {{telefono}}
                                            </td>
                                        </tr>
                                    </tbody>
                            </div>
                        </section>
                        <section>
                            <div>
                                <table id="facarticulo" >
                                    <thead>
                                        <tr id="fa" class="primary">
                                            <th>Código</th>
                                            <th>Cant</th>
                                            <th>Descripción</th>
                                            <th>Precio</th>
                                        </tr>
                                    </thead>
                                    <tbody> 
                                        <tr v-for="det in detalles" :key="det.noFacturaReal" >
                                            <td  style="text-align:center;"> {{det.codigoProducto}} </td>
                                            <td  style="text-align:center;"> {{det.cantidad}} </td>
                                            <td style="text-align:center;">{{det.descripcion}}</td>
                                            <td style="text-align:center;">{{det.precioUnitario}}</td>
                                        </tr>
                                        
                                    </tbody>
                                    <tfoot>
                                        <tr> </tr>
                                         
                                        <tr></tr>
                                        <tr> </tr>
                                        <tr> </tr> 
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th style="text-align:right;">Pendiente ₡ {{credito}}</th>
                                        </tr>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th style="text-align:right;"> Abono en {{tipoPago}}₡ {{abono}}</th>
                                        </tr>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th style="text-align:right;"> Saldo ₡ {{saldo}}</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </section>
                        <footer>
                            <div>
                              <v-card-title class="primary--text display-1">Método de Pago </v-card-title>
                              <v-card-actions> 
                                    <v-list>
                                      <v-img  @click="dialogSinpe = true" max-height="50" max-width="50" :src="require('../assets/SINPEMOVIL.jpeg')"> </v-img> 
                                      <v-card-text class="text-center hidden-sm-and-down">Sinpe </v-card-text>
                                    </v-list>
                                    <v-list>
                                      <v-img @click="dialogDeposito = true" max-height="50" max-width="50" :src="require('../assets/pago.png')"> </v-img> 
                                      <v-card-text class="text-center hidden-sm-and-down">Depósito </v-card-text>
                                    </v-list>  
                                    <v-list>
                                      <v-img @click="dialogTarjeta = true" max-height="50" max-width="50" :src="require('../assets/tarjeta-de-credito.png')"> </v-img> 
                                      <v-card-text class="text-center hidden-sm-and-down">Tarjeta </v-card-text>
                                    </v-list>
                                    <v-list>
                                      <v-img @click="dialogEfectivo = true" max-height="50" max-width="50" :src="require('../assets/efectivo.png')"> </v-img> 
                                      <v-card-text class="text-center hidden-sm-and-down">Efectivo </v-card-text>
                                    </v-list> 
                                    <v-list>
                                      <v-img @click="dialogCheque = true" max-height="50" max-width="50" :src="require('../assets/abuelo.png')"> </v-img> 
                                      <v-card-text class="text-center hidden-sm-and-down">Cheque </v-card-text>
                                    </v-list>  
                                  </v-card-actions>
                            </div>
                            <div id="gracias">
                                <p><b>Gracias por su prefencia!</b></p>
                            </div>
                        </footer>
                    </div>
                    <v-btn @click="OcultarComprobante()" color="error" class="mx-2" flat><v-icon> mdi-exit-to-app </v-icon></v-btn>
                    <v-btn @click="Limpiar()" color="success" class="mx-2" flat><v-icon> mdi-lock-reset </v-icon></v-btn>
                    <v-btn @click="AbonoCredito()" block="" color="primary" flat><v-icon> mdi-check </v-icon> Generar Abono</v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
		<v-container fluid>
			<v-row >
				<v-col style="border: 1px solid rgb(224, 224, 224);" cols="12" lg="4" md="4" sm="6" xs="12">
					<h2> Cuentas por Cobrar </h2>
					<v-row class="mx-1 mt-2">
						<v-text-field dense style="width:30%" v-model="start" type="date" label="Fecha inicial"></v-text-field>
						
						<v-text-field dense style="width:30%" v-model="end" type="date" label="Fecha final"></v-text-field>
						<v-btn :loading="loading" small fab class="mx-1" color="info" @click="listarFechas()" > <v-icon> mdi-cloud-search </v-icon> </v-btn>
					</v-row>
					<v-combobox dense clearable @change="FiltroCliente()" :items="clientes" v-model="cliente" label="Filtro por Cliente"></v-combobox>
					<v-select dense @change="FiltroEstado()" label="Filtro por estado" :items="Estados" v-model="Estado"> </v-select>
					<v-row class="mx-1">
						<v-text-field readonly v-model="total" dense outlined label="Total" style="width:50%"/> 
						<v-text-field readonly v-model="calcularSaldo" dense outlined label="Saldo" style="width:50%"/> 
						<v-text-field readonly v-model="interes" dense outlined label="Interes" style="width:50%"/> 
						<v-text-field filled class="light-green lighten-4" onfocus="this.select();" @change="calcularPendiente()" v-model="abono" dense outlined label="Abono" style="width:50%;"/>
						<v-select @change="BuscarCuentas" v-model="tipoPago" :items="TipoPagos" dense outlined label="Tipo Pago" style="width:50%"/> 
						<v-select v-if="tipoPago != 'EFECTIVO'" v-model="cuenta" :items="Cuentas" dense outlined label="Cuenta" style="width:50%"/> 
						<v-select v-model="monedaPago" :items="monedas" dense outlined label="Moneda Pago" style="width:50%"/> 
						<v-text-field v-model="Nota" dense outlined label="Nota" style="width:50%"/> 
					</v-row>
					<v-row class="rounded-sm mx-1 accent">
						<h4 class="pa-2" style="color:#FFF">Pendiente Seleccionado: {{credito}} </h4>
							<v-spacer/>
						<v-btn min-width="20%" large :loading="loading" color="#2797ce" dark @click="PagoMultiple()" > <v-icon> mdi-printer-pos</v-icon>Pagar </v-btn>
							
					</v-row>
					<v-card-text>
						<b style="color:#FF0000"> Total Pendiente Cobrar:</b>  {{Total=(calcularTotal)}} <br>
					</v-card-text>
					<v-row class="rounded-sm mb-1 accent">
						<v-btn min-width="20%" text dark style="border-right: 1px solid rgb(224, 224, 224);" @click="crearPDF()"> <v-icon >mdi-file-download </v-icon> PDF </v-btn>
						
							<vue-excel-xlsx
								:data="usuarios"
								:columns="columns"
								:filename="'Cuentas por Cobrar de '+$store.state.usuario.localidad"
								:sheetname="'CXC'"
								>
								<v-btn class="mx-2" dark text style="border-right: 1px solid rgb(224, 224, 224);">  <v-icon >mdi-file-excel </v-icon> Excel </v-btn>
							</vue-excel-xlsx> 
						<v-btn min-width="20%" text dark style="border-right: 1px solid rgb(224, 224, 224);" @click="decide_impresion()"> <v-icon >mdi-printer </v-icon> Print </v-btn>
							<v-spacer/>
							<v-btn min-width="20%" :loading="loading"  color="secondary" dark @click="Limpiar()" > <v-icon> mdi-broom </v-icon>Limpiar </v-btn>
					</v-row>
					<v-row class="rounded-sm accent">
						<v-spacer/>
						<v-btn min-width="20%" :loading="loading"  color="secondary" dark @click="dialogNota = true" > <v-icon> mdi-file </v-icon>Abono con NC </v-btn>
					</v-row>
					<p v-if="bloqueoTemp" style="color:red; font-size:30px;"> Para realizar otro abono de click en el boton LIMPIAR </p>
				</v-col>
				<v-col cols="12" lg="8" md="8" sm="6" xs="12">
					<v-data-table
					:headers="headers"
					:items="usuarios"
					dense
					:search="search"
					:loading="loading"
					class="elevation-1"
					>
					<template v-slot:item.estado="{ item }">
					<v-chip :text-color="getColorText(item.estado)" :color="getColor(item.estado)" dark>• {{ item.estado }}</v-chip>
					</template>
					<template v-slot:item.check="{ item }">
						<v-checkbox :disabled="bloqueoTemp" @change="calcularTotalCheck()" v-model="item.check" />
					</template>
					<template v-slot:top>
						<v-row class="mx-2">
								<v-text-field
								v-model="search"
								append-icon="mdi-magnify"
								label="Buscar"
								single-line
								hide-details
								></v-text-field>
						</v-row>
					</template>
					<template v-slot:item.action="{ item }">
						<v-menu top :offset-y="offset">
							<template v-slot:activator="{ on, attrs }">
							<v-btn
								small
								fab=""
								v-bind="attrs"
								v-on="on"
								
							> <v-icon color="info">mdi-dots-horizontal</v-icon>
							</v-btn>
							</template>
							<v-list>
							<v-list-item @click="MostrarComprobante(item)">
								<v-list-item-title><v-icon >mdi-file-document</v-icon> 
									Ver Comprobante
								</v-list-item-title>
							</v-list-item>
							<v-list-item  @click="VerAbonos(item)">
								<v-list-item-title >  <v-icon > mdi-rocket-launch </v-icon>  Ver Abonos</v-list-item-title>
							</v-list-item>
							<v-list-item  @click="EliminarCxc(item)">
								<v-list-item-title >  <v-icon > mdi-delete </v-icon>  Eliminar Cuenta</v-list-item-title>
							</v-list-item>
							</v-list>
						</v-menu>
					<template >
					</template>
					
					</template>

					<template v-slot:no-data>
						No Hay Información
					</template>
				</v-data-table>
				</v-col>
			</v-row>
		</v-container>
    </div>
</template>

<script>
import axios from 'axios'
import jsPDF from 'jspdf'
import Vue from "vue";
import VueExcelXlsx from "vue-excel-xlsx";
import html2canvas from 'html2canvas';

Vue.use(VueExcelXlsx);
  export default {
    data: () => ({
      
    dialog: false,
    search: '',
    headers: [
    { text: 'Check', value: 'check', sortable: false },
    { text: 'Nombre Cliente', value: 'nombre',  },  
    { text: 'Estado', value: 'estado' },
    { text: 'Factura', value: 'factura' },
	{ text: 'Detalle', value: 'detalle',  },   
    { text: 'Pendiente', value: 'credito',  },             
    { text: 'Dias Restantes', value: 'dias',  },  
    { text: 'Fecha', value: 'fecha' },
    { text: 'Hora', value: 'hora', sortable: false  },
    { text: 'Cédula Cliente', value: 'idCliente',  },  
    { text: 'Correo Cliente', value: 'correo',  },  
    { text: 'Teléfono', value: 'telefono',  },  
    { text: 'Vendedor', value: 'cajero',  },  
	{ text: 'Opciones', value: 'action', sortable: false },
	],
	columns : [
			{ label: 'Factura', field: 'factura' },
			{ label: 'Estado', field: 'estado' },
			{ label: 'Dias Restantes', field: 'dias' },  
			{ label: 'Fecha', field: 'fecha' },
			{ label: 'Hora', field: 'hora' }, 
			{ label: 'Total', field: 'credito',  },                     
			{ label: 'Nombre Cliente', field: 'nombre' },  
			{ label: 'Cédula Cliente', field: 'idCliente'  },  
			{ label: 'Correo Cliente', field: 'correo'  },  
			{ label: 'Teléfono', field: 'telefono' },  
			{ label: 'Vendedor', field: 'cajero'},  
			{ label: 'Localidad', field: 'localidad'},  
        ],
    editedIndex: -1,
    nombre: '',
	Estado: '',
	Estados:['Sin Vencer','Vencido'],
    correo: '',
	modoPDV: 0,
    dialogSinpe: false,
    dialogTarjeta: false,
    dialogCheque: false,
	abonos: [],
	dialogDeposito: false,
	Fecha: new Date().toISOString().substr(0, 10),
	loading: true,
	clientes: [],
    cedula: '',
    bancos: ["Banco Costa Rica","Banco Popular","BAC San José","Banco Nacional","Bancredito","Lafise","Davivienda","Cathay","Scotia Bank de Costa Rica" ],
    credito: 0,
	banco: '',
	start: '',
	end: '',
	Abonos:'',
    factura: 0,
    abono: 0,
    hora : '',
	noFactura: 0,
	offset :null,
	dialogAbono: false,
	bloqueoTemp:false,
    fecha: '',
    vendedor: '',
    saldo: 0,
	telefono: 0,
	cliente: '',
	snackbar : false,
	Mensaje : '',
    comprobanteModal: 0,
    localidadencabezado: [],
    localidad: '',
    productosDatos:[],
    detalles:[],
    tipoCompra: '',
    tipoPago : 'EFECTIVO',
	TipoPagos:["TARJETA","EFECTIVO","TARJETA","DEPOSITO","SINPE"],
    ModalNotaCredito: false,
	monedas: [
          'CRC',
          'USD',
    ],
    monedaPago: "CRC",
    dialogEfectivo: false,
    total: 0,
    fecha: '',
    hora: '',
	Nota:'',
    señas : '',
    comprobantePago: '',
	Logo3:null,
	dialogNota: false,
    vendedor: '',
    subtotal: 0,
    grabado:0 ,
	interes:0,
	pendiente:0,
    excento: 0,
    iv: 0.0,
	pagoCon:0,
	Cuentas:[],
    tipo_documento: '',
    usuarios:[],    
	localidad : '',
    desserts: [],
    editedIndex: -1,
	ip1: "",
	devid1: "",
    descuento: 0,
    }),

    computed: {
      formTitle () {
                return this.editedIndex === -1 ? 'Nuevo usuario' : 'Actualizar usuario'
        },
		calcularTotal:function(){
			var resultado=0.0;
			for(var i=0;i<this.usuarios.length;i++){
					resultado=resultado+(this.usuarios[i].credito * 1);
			}
			
			return resultado.toFixed(2);
      	},
		calcularSaldo:function(){
			var resultado=0.0;
			resultado = this.total - this.abono;
			
			return resultado.toFixed(2);
      	},
		
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      
    },

    created () {
		this.listar();
		this.listarCuentas();
		this.select();
		this.listar_localidad();
		this.validarImpresora();
    },

    methods: {
		validarImpresora(){
			let me=this;
			var localidadesArray=[];
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			axios.get('api/Impresoras/ListarLocalidad/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
				localidadesArray=response.data;

					for(var i = 0; i < localidadesArray.length; ++i){
					if(localidadesArray[i].nombreImpresora == "Caja"){
						me.ip1 = localidadesArray[i].ip
						me.devid1 = localidadesArray[i].devid
						//me.existeprinter1 = true;
					} else
					if(localidadesArray[i].nombreImpresora == "Cocina"){
						me.ip2 = localidadesArray[i].ip
						me.devid2 = localidadesArray[i].devid
						//me.existeprinter2 = true;
					}
					}
			})
		},
		async listarLocalidad() {
      try {
        const { localidad } = this.$store.state.usuario;
        const { token } = this.$store.state;
        const headers = { Authorization: `Bearer ${token}` };
        const response = await axios.get(`api/Localidades/ListarFiltrada/${localidad}`, { headers });

        // Desestructuramos la respuesta para mayor claridad
        const { data } = response;
        this.localidadencabezado = data;

        // Asignar el modoPDV solo si los datos están disponibles y la lista no está vacía
        if (data.length > 0) {
          this.modoPDV = data[0].modoPDV;
        }
      } catch (error) {
        console.error("Error al listar localidades:", error);
      }
    },

		calcularTotalCheck(){
			var resultado=0.0;
			for(var i=0;i<this.usuarios.length;i++){
				if(this.usuarios[i].check == true)
				{
					resultado=resultado+(this.usuarios[i].credito * 1);
				}
			}
			this.saldo = resultado.toFixed(2);
			this.total = resultado.toFixed(2);
			this.credito = resultado.toFixed(2);
		},
		BuscarNotaCredito() {
			let me = this;
			let headers = { "Authorization": "Bearer " + this.$store.state.token }; // Asegúrate de que el token esté disponible
			let config = { headers: headers };

			axios.get(`api/RepVentasVistas/ConsultaNC/${this.noFactura}/${this.$store.state.usuario.localidad}`, config)
			.then(function (response) {
				if (response.data.success) {
					// Procesa la respuesta exitosa
					console.log('Datos recibidos:', response.data.data);
					if (response.data.data.length > 0 && response.data.data[0].total !== undefined) {
						me.abono = response.data.data[0].total;
						console.log('Total capturado:', me.totalNC);
					} else {
						console.error('No se encontró el total en la respuesta');
					}
					me.Mensaje = response.data.message;
					me.snackbar = true
					me.dialogNota = false;
					me.TipoPagos = ["NC"];
					me.tipoPago = "NC";
					me.calcularPendiente();
				} else {
					// Maneja la situación donde la factura no es una nota de crédito
					me.Mensaje = response.data.message;
					me.snackbar = true
					//alert(response.data.message);
				}
			})
			.catch(function (error) {
				// Manejo de errores de la solicitud
				console.error('Error en la solicitud:', error);
				alert('Error al consultar la nota de crédito');
			});
		},
		NCManual(){
			this.tipoFactura = 3;
			this.tipoFacturas.push({text:'Nota de Crédito', value:3})
			this.NotaCreditoFactura = true;
		},
		async imprimir_abono() {
			const fechaActual = new Date();
			const fecha = fechaActual.toLocaleString();
			const hora = fechaActual.toLocaleTimeString();
			let dataParaImprimir = {
				Localidad:this.localidadencabezado[0],
				detalles: this.detalles,
				cliente: {
					nombre: this.cliente,
					vendedor: this.vendedor,
					abono: this.abono,
					documento: "",
					tipoPago :this.tipoPago,
					copias:2,
					saldo:this.saldo,
				},
				moneda:this.monedaPago,
				Fecha:fecha,
				Hora:hora,
				tipoPago:this.tipoPago,
				saldo:this.calcularSaldo,
				total: this.total,
				abono:this.abono,
				documento: "",
			};
			console.log(JSON.stringify(dataParaImprimir, null, 2));

			// Enviar el HTML al servidor Python para imprimir
			try {
				const response = await axios.post('http://localhost:5000/imprimir-abono', dataParaImprimir);
				console.log('Ticket enviado a imprimir', response.data);
			} catch (error) {
				console.error('Error al enviar el ticket para imprimir', error);
			}
			//this.FinalizarOrden();
		},
		async PagoMultiple(){
			this.detalles= [];
			let self = this;
			this.loading = true;
			var momentoActual = new Date(); 
			var hora = momentoActual.getHours(); 
			var minuto = momentoActual.getMinutes(); 
			var segundo = momentoActual.getSeconds();
			this.hora = hora + ":" + minuto + ":" + segundo;
			let abonoGenerar = 0;
			let saldoAbono = 0;
			let SaldoPendiente = 0;
			if(this.abono == 0){
				this.snackbar = true;
				this.Mensaje = 'Debe digitar un abono para continuar';
				this.loading = false;
				return
			}else
			if(this.tipoPago == 0){
				this.snackbar = true;
				this.Mensaje = 'Debe Digitar método de pago';
				this.loading = false;
				return
			}else
			if(parseFloat(this.abono) > parseFloat(this.total)){
			alert("Abono no puede ser mayor que saldo pendiente")
			this.loading = false;
			return
			} 
			saldoAbono= parseFloat(this.abono);
			this.usuarios.sort((a, b) => {
				return parseInt(a.NoFactura) - parseInt(b.NoFactura);
			});
			
			for (var i = 0; i < this.usuarios.length; i++) {
				if (this.usuarios[i].check == true) {
					let usuarioActual = this.usuarios[i];
					this.cliente = usuarioActual.nombre;
					this.detalles.push(usuarioActual);

					let creditoUsuario = parseFloat(usuarioActual.credito);
					abonoGenerar = Math.min(saldoAbono, creditoUsuario); // El menor entre lo que queda por abonar y el crédito del usuario.
					SaldoPendiente = creditoUsuario - abonoGenerar; // Nuevo saldo pendiente tras el abono.
					saldoAbono -= abonoGenerar; // Reducimos el saldo del abono total.

					let header = { "Authorization": "Bearer " };
					let configuracion = { headers: header };

					try {
					let response = await axios.post('api/RepVentasVistas/AbonoCredito', {
						'SaldoAnterior': creditoUsuario,
						'Abono': abonoGenerar,
						'Saldo': SaldoPendiente,
						'TipoPago': this.tipoPago,
						'ComprobantePago': this.comprobantedeposito,
						'Banco': this.banco,
						'Señas': this.señas,
						'Factura': usuarioActual.factura,
						'NombreCliente': usuarioActual.nombre,
						'CedulaCliente': usuarioActual.idCliente,
						'Usuario': this.$store.state.usuario.nombre,
						'FechaCompra': usuarioActual.fecha,
						'HoraCompra': this.hora,
						'Localidad': this.$store.state.usuario.localidad,
					}, configuracion);

					alert("Se abonó " + abonoGenerar + " a la factura: " + usuarioActual.factura + " Realizado, Saldo para el siguiente: " + saldoAbono);
					
					// Si ya no queda saldo de abono, salimos del bucle.
					if (saldoAbono <= 0) {
						break;
					}

					} catch (error) {
					alert("Error en el Abono, Notifique a su técnico " + error);
					this.loading = false;
					break; // Romper el bucle si hay un error.
					}
				}
			}
				// Continuar con cualquier otra lógica después del bucle...
			self.listar();
			if(self.cuenta != ''){
				self.Deposito()
			}
			self.decide_impresion();
			self.limpiar();
			self.loading = false;
		},
		listarCuentas(){
			let me=this;
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			axios.get('api/EstadoCuentas/ResumenCuentas/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
				//console.log(response);
				me.data=response.data;
				me.loading = false;
				me.data.map(function(x){
					me.Cuentas.push({text: x.alias+ '-'+ x.etiqueta +' ('+ x.moneda + ')',value:x.etiqueta});
				});
				// me.modelCarga = false;
			}).catch(function(error){
				console.log(error);
			});
		},
		async Deposito(){
			let me=this;
			this.loading = true;
			let facts = '';
			this.detalles.map(function(x){
					facts = facts +',' + x.factura;
				});
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			await axios.post('api/EstadoCuentas/Deposito',{
				'TipoRegistro':'ABONO A CXC',
				'Ingreso':me.abono,
				'Egreso': 0,
				'Documento':facts,
				'detalle': me.Nota,
				'Usuario':me.$store.state.usuario.nombre,
				'Localidad':me.$store.state.usuario.localidad, 
				'Persona': '',
				'Moneda':me.monedaPago,
				'TipoPago':me.tipoPago,
				'CuentaContable':me.cuenta,
			},configuracion).then(function(response){
					return;
				}).catch(err => {
				//console.log(err.response);
				alert("Error ingresando dinero a su cuenta, por favor ingrese el deposito manualmente y reporte a su proveedor "+error)
				return;
			})
		},
		decide_impresion() {
			if (this.modoPDV == 6) {
				this.imprimir_abono();
			} else if (this.modoPDV == 3) {
				this.imprime_epson();
			} else {
				this.imprime_windows();
			}
		},

		imprime_epson(){
			const fechaActual = new Date();

			// Obtener componentes de la fecha
			const dia = fechaActual.getDate();
			const mes = fechaActual.getMonth() + 1; // Sumamos 1 para obtener el mes correcto
			const año = fechaActual.getFullYear();

			// Obtener componentes de la hora
			const horas = fechaActual.getHours();
			const minutos = fechaActual.getMinutes();
			const segundos = fechaActual.getSeconds();

			// Formatear la fecha y hora en una cadena
			const fech = `${(dia)}/${(mes)}/${año} : ${(horas)}:${(minutos)}:${(segundos)}`;

			// Generar contenido para cada detalle
			var products = '';
			this.detalles.forEach(function (detalle) {
				products += `<text>${detalle.factura}  \t ${detalle.credito} \t ${detalle.cajero}\t ${detalle.detalle} &#10;</text>\n`;
			});
			
		
			var request = `<epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">
					<text align="center"/>
					<text font="font_c"/>
					<text width="3" height="3"/>
					<text reverse="false" ul="false" em="false" color="color_1"/>
					<text>Comprobante &#10;</text>
					<text width="1" height="1"/>
					<text> Comprobante de abono &#10;</text>
					<text>`+this.localidadencabezado[0].dirreccion+`&#10;</text>
					<text>`+this.localidadencabezado[0].nombre+`&#10;</text>
					<text> `+this.localidadencabezado[0].cedulaJuridica+`&#10;</text>
					<text>`+this.localidadencabezado[0].telefono+`&#10;</text>
					<text>`+this.localidadencabezado[0].correo+`&#10;</text>
					<text reverse="false" ul="false" em="false" color="color_1"/>
					<text>---------------------------------------&#10;</text>
					<text align="left"/>
					<text>Fecha: `+fech+` &#10;</text>
					<text>Usuario: `+this.$store.state.usuario.nombre+` &#10;</text>
					<text>Tipo Pago: `+this.tipoPago+` &#10;</text>
					<text>Moneda Pago: `+this.monedaPago+` &#10;</text>
					<text>Cliente: `+this.cliente+` &#10;</text>
					<text>---------------------------------------&#10;</text>
					<text>Factura abonadas: &#10;</text>
					<text>Fact \t Saldo Actual \t Vendedor \t Detalle     &#10;</text>
					`+products+`
					<text>---------------------------------------&#10;</text>
					<text>Saldo Anterior \t Abono \t Nuevo Saldo   &#10;</text>
					<text>${this.total}  \t ${this.abono} \t ${this.calcularSaldo}&#10;</text>
					<text>&#10; &#10; &#10;Firma: ____________________&#10;</text>
					<text>Nota: `+this.Nota+`&#10;</text>
					<feed line="3"/>
					<cut type="feed"/>
					</epos-print>`;
			
			//Create a SOAP envelop
			var soap = '<s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">' +
			'<s:Body>' + request + '</s:Body></s:Envelope>';
			//Create an XMLHttpRequest object
			var xhr = new XMLHttpRequest();
			//Set the end point address
			var url = 'http://'+this.ip1+'/cgi-bin/epos/service.cgi?devid='+this.devid1+'&timeout=60000';
			//Open an XMLHttpRequest object
			xhr.open('POST', url, true);
			//<Header settings>
			xhr.setRequestHeader('Content-Type', 'text/xml; charset=utf-8');
			xhr.setRequestHeader('If-Modified-Since', 'Thu, 01 Jan 1970 00:00:00 GMT');
			// Send print document
			xhr.send(soap);
			xhr.send(soap);
		},
		imprime_windows(){
			this.bloqueoTemp = true;
			var divContents = document.getElementById("bill").innerHTML;
			var a = window.open('', '', 'height=500, width=500');
			a.document.write('<html>');
			a.document.write(divContents);
			a.document.write('</body></html>');
			a.print();
		},
		ImprimirAbono(item){
			this.detalles = [];
			this.total= item.saldoAnterior;
			this.abono = item.abono;
			this.saldo = item.saldoActual;
			this.detalles.push(
				{credito: item.saldoAnterior,
				factura: item.noFactura,
				cajero:item.usuario,
				detalle:item.cliente,
				}
			);
			this.decide_impresion();
		},
		calcularPendiente(){
			this.credito = this.saldo - this.abono;
		},
		Limpiar(){
			this.saldo =0;
			this.abono = 0;
			this.noFactura = null;
			this.TipoPagos=["TARJETA","EFECTIVO","TARJETA","DEPOSITO","SINPE"];
			this.tipoPago ="EFECTIVO";
			this.bloqueoTemp = false;
		},
		LimpiarFiltro(){
			this.cliente = '';
			this.Estado = '';
			this.listar()
		},
	  	select(){
                let me=this;
				this.localidad = this.$store.state.usuario.localidad;
                var clientesArray=[];
                let header={"Authorization" : "Bearer "};
                let configuracion= {headers : header};
                 axios.get('api/Empleados/SelectEmpleadosLocalidad/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
                    clientesArray=response.data;
                    clientesArray.map(function(x){
                        me.clientes.push({text: (x.nombre+' | '+x.nombreFantasia), text2:x.nombre,value:x.cedula});
                    });
                })
    	},
        OcultarComprobante(){
            this.comprobanteModal = 0;
            this.nombre ='';
            this.correo= '';
            this.cedula= '';
            this.credito= 0;
            this.factura= 0;
            this.abono= 0;
            this.hora = '';
            this.fecha= '';
            this.vendedor= '';
            this.saldo= 0;
            this.telefono= 0;
        },
        PagoEfectivo(){
           this.tipoPago = 'EFECTIVO';
           this.saldo = Math.round(this.credito - this.abono);

           if(this.abono > this.credito){
             alert("El monto del abono no puede superar el total de crédito")
             this.saldo = 0
             return
           } 
          this.dialogEfectivo = false;
          

        },
		PagoTarjeta(){
			this.tipoPago = 'TARJETA';
			this.saldo = Math.round(this.credito - this.abono);
			if(this.abono > this.credito){
				alert("El monto del abono no puede superar el total de crédito")
				this.saldo = 0
				return
			} 
			this.dialogTarjeta = false;

		},
		VerAbonos(item){
			this.cliente = item.nombre;
			this.factura = item.factura;
			this.dialogAbono = true;
			this.abonos = [];
			var me = this;
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
				axios.get('api/Abonoscxc/'+this.$store.state.usuario.localidad+'/'+item.factura,configuracion).then(function(response){
				me.abonos=response.data;
			})
		},
		BuscarCuentas(){
			let me=this;
			let data = [];
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			axios.get('api/EstadoCuentas/ResumenCuentas/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
				//console.log(response);
				data=response.data;
				data.map(function(x){
					me.Cuentas.push({text: x.alias+ '-'+ x.etiqueta +' (' + x.valor + x.moneda + ')',value:x.etiqueta});
				});
				// me.modelCarga = false;
			}).catch(function(error){
				console.log(error);
			});
		},
        obtenerCliente(){
			this.usuarios = this.usuarios.filter(e => e.nombre == this.cliente.text2);
            this.snackbar = true;
            this.Mensaje = 'Filtro Medio de Pago Seleccionado sobre rango de fecha listado'
        }, 
        limpiar(){
          this.saldo = 0;
          this.abono = 0;
          this.credito = 0;
		  this.detalles = [];
		  this.tipoPago = '';
		  this.cliente = '';
        },
        AbonoCredito(){
			if(this.abono > this.credito){
			alert("Abono no puede ser Mayor que saldo pendiente")
			return
			} else
			if(this.abono == 0){
			alert("Debe elegir un método de abono y asignar el monto")
			return
			}
			let self = this;
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			axios.post('api/RepVentasVistas/AbonoCredito',{
					'SaldoAnterior': self.credito,
					'Abono': self.abono,
					'Saldo': self.saldo,
					'TipoPago': self.tipoPago,
					'ComprobantePago':self.comprobantedeposito,
					'Banco':self.banco,
					'Señas':self.señas,
					'Factura':self.factura,
					'NombreCliente': self.nombre,
					'CedulaCliente': self.cedula,
					'Usuario': self.$store.state.usuario.nombre,
					'FechaCompra': self.fecha,
					'HoraCompra': self.hora,
					'Localidad':self.localidad,
				},configuracion).then(function (response) {
				alert("Abono realizado correctamente");
				self.comprobanteModal = false;
					self.limpiar();
					self.listar();
				})
				.catch(function (error) {
				alert("Error en el Abono, Notifique a su técnico");
			})
        },
		EliminarCxc(item){
        	if(confirm("Seguro que desea eliminar la Cuenta por cobrar de "+item.nombre+" con factura No: "+item.factura))
			{
				let self = this;
				let header={"Authorization" : "Bearer "};
				let configuracion= {headers : header};
				axios.post('api/RepVentasVistas/DeleteCxc',{
                      'SaldoAnterior': item.credito,
                      'Saldo': item.saldo,
                      'Factura':item.factura,
                      'NombreCliente': item.nombre,
                      'Usuario': self.$store.state.usuario.nombre,
                      'Localidad':self.$store.state.usuario.localidad,
                  },configuracion).then(function (response) {
                    alert("Cuenta Eliminada correctamente");
                     self.limpiar();
                     self.listar();
                  })
                  .catch(function (error) {
                    alert("Error eliminando, Notifique a su técnico" + error);
                })
			}
        },
        crearPDF(){
           var columns = [
            { title: 'Factura', dataKey: 'factura' },
			{ title: 'Estado', dataKey: 'estado' },
			{ title: 'Dias Restantes', dataKey: 'dias',  },  
			{ title: 'Fecha', dataKey: 'fecha' },
			{ title: 'Hora', dataKey: 'hora'},  
			{ title: 'Total', dataKey: 'credito',  },                     
			{ title: 'Nombre Cliente', dataKey: 'nombre',  },  
            ];
            var rows = [];

            this.usuarios.map(function(x){
                rows.push({factura:x.factura,estado:x.estado,dias:x.dias,fecha:x.fecha,hora:x.hora,credito:x.credito,nombre:x.nombre,});
            });

            // Only pt supported (not mm or in)
            var doc = new jsPDF('p', 'pt');
            doc.autoTable(columns, rows, {
                margin: {top: 60},
                addPageContent: function(data) {
                    doc.text("Cuentas por Cobrar", 40, 30);
                }
            });
            doc.save('Reporte Cuentas por cobrar en '+this.$store.state.usuario.localidad+'.pdf');
        },
        crearPDFFactura(){
             var quotes = document.getElementById('factura');
                html2canvas(quotes).then(function (canvas) {
                    var imgData = canvas.toDataURL('image/png');
                    var imgWidth = 210;
                    var pageHeight = 285;
                    var imgHeight = canvas.height * imgWidth / canvas.width;
                    var heightLeft = imgHeight;
                    var doc = new jsPDF();
                    var position = 0;

                    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);                    
                    doc.save('ComprobanteVenta.pdf');
                });
        },

        getColor (estado) {
		if(estado == 'Sin Vencer') return 'light-green lighten-4'
		else  return 'indigo lighten-4'
      },
	  getColorText (mensajeRespuesta) {
			if(mensajeRespuesta == 'Sin Vencer') return 'light-green darken-4'
			else  return 'indigo darken-4'
		},
      MostrarComprobante(item){
            this.nombre= item.nombre;
            this.cedula = item.idCliente;
            this.correo = item.correo
            this.telefono = item.telefono;
            this.credito = item.credito;
			this.saldo = item.credito;
            this.hora = item.hora;
            this.fecha = item.fecha;
            this.factura =item.factura;
            this.vendedor = item.cajero;
            this.tipoCompra = item.tipoCompra;
            this.localidad = item.localidad;
            this.listarDetalles(item.factura);
            this.comprobanteModal = true;
        },
        listarDetalles(id){
			let me=this;
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			axios.get('api/RepVentasVistas/ListarDatosFactura/'+id+'/'+this.$store.state.usuario.localidad+'',configuracion).then(function(response){
				//console.log(response);
				me.detalles=response.data;ç
			}).catch(function(error){
				console.log(error);
			});
		},
		listarFechas(){
            if(this.start == '' || this.end == ''){
                alert("Debe seleccionar una fecha inicial y final");
                return;
		   }
		   	this.loading = true;
            let me=this;
			var data= [];
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
            axios.get('api/RepVentasVistas/ConsultaFechasCreditos/'+this.start+'/'+this.end+'/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
                //console.log(response);
				data=response.data;
				data.map(function(x){
					me.usuarios.push({
					check: false,
					nombre: x.nombre,
					estado:x.estado,
					factura: x.factura,
					dias: x.dias,
					fecha : x.fecha,
					hora: x.hora,
					credito: x.credito,
					idCliente: x.idCliente,
					correo: x.correo,
					telefono:x.telefono,
					cajero: x.cajero,
					}) });
				me.loading = false;
				me.cliente = '';
            }).catch(function(error){
                console.log(error);
            });
        },
       listar(){
			let me=this;
			let data=[];
			this.usuarios= [];
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			axios.get('api/RepVentasVistas/ListarCreditosLocalidad/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
				//console.log(response);
				data=response.data;
				data.map(function(x){
					me.usuarios.push({
					check: false,
					nombre: x.nombre,
					estado:x.estado,
					factura: x.factura,
					dias: x.dias,
					fecha : x.fecha,
					hora: x.hora,
					credito: x.credito,
					idCliente: x.idCliente,
					correo: x.correo,
					telefono:x.telefono,
					detalle:x.detalle,
					cajero: x.cajero,
					}) });
				me.loading = false;
			}).catch(function(error){
				console.log(error);
			});
		},
		FiltroCliente(){
			let me=this;
			let data=[];
			this.usuarios = [];
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			axios.get('api/RepVentasVistas/ListarCreditosLocalidadCliente/'+this.$store.state.usuario.localidad+'/'+this.cliente.value,configuracion).then(function(response){
				//console.log(response);
				data=response.data;
				data.map(function(x){
					me.usuarios.push({
					check: false,
					nombre: x.nombre,
					estado:x.estado,
					factura: x.factura,
					dias: x.dias,
					fecha : x.fecha,
					hora: x.hora,
					credito: x.credito,
					idCliente: x.idCliente,
					correo: x.correo,
					telefono:x.telefono,
					cajero: x.cajero,
					}) });
				me.loading = false;
			}).catch(function(error){
				console.log(error);
			});
		},
		FiltroEstado(){
			let me=this;
			let data=[];
			this.usuarios = [];
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			axios.get('api/RepVentasVistas/ListarCreditosLocalidadEstado/'+this.$store.state.usuario.localidad+'/'+this.Estado,configuracion).then(function(response){
				//console.log(response);
				data=response.data;
				data.map(function(x){
					me.usuarios.push({
					check: false,
					nombre: x.nombre,
					estado:x.estado,
					factura: x.factura,
					dias: x.dias,
					fecha : x.fecha,
					hora: x.hora,
					credito: x.credito,
					idCliente: x.idCliente,
					correo: x.correo,
					telefono:x.telefono,
					cajero: x.cajero,
					}) });
				me.loading = false;
			}).catch(function(error){
				console.log(error);
			});
		},

      	close () {
			this.dialog = false;
			this.limpiar();
		},
      	activarDesactivarMostrar(accion,item){
                this.adModal=1;
                this.adNombre=item.nombre;
                this.adId=item.idusuario;                
                if (accion==1){
                    this.adAccion=1;
                }
                else if (accion==2){
                    this.adAccion=2;
                }
                else{
                    this.adModal=0;
                }
            },
            activarDesactivarCerrar(){
                this.adModal=0;
			},
		save(){
			if (this.editedIndex > -1) {
			Object.assign(this.desserts[this.editedIndex], this.editedItem)
			} else {
			this.desserts.push(this.editedItem)
			}
			this.close()
		},
    },
  }
</script>
My CRUD