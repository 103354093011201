<template>
  <v-container fluid>
	<body id="comandacocina" class="content">
		<div class="ticket">
			<div class="caption" v-for="det in localidadencabezado" :key="det.nombre" id="centrado">
				<b style="font-size:25px;">Comanda {{noFactura}} </b> <br>
					<div >
					Vendedor: {{vendedor}} <br>
					Fecha: {{today}}  <br>
					Cliente {{clienteNombre}} <br>  </div> 
			</div>
			--------------------------
			<table >
				<thead>
					<tr>
						<th class="producto">Cant</th>
						<th class="precio">Nombre</th>
						
					</tr>
				</thead>
				<tbody >
				<tr v-for="det in detalles" :key="det.noFacturaReal" >
					<td  style="text-align:center;"> {{det.cantidad}} </td>
					<td style="text-align:center;">{{det.descripcion}}</td>
				</tr>
				</tbody>
				
			</table>
			--------------------------
			<div style="text-align:center; ticket"> 
				<div>Detalle: {{detalleFactura}} </div>
				
			</div>
		</div>
	</body>
	<v-dialog v-model="dialogProforma" fullscreen hide-overlay>
		<template>
			<v-card>

			<body class="content">
				<div class="ticket">
					<div class="caption" v-for="det in localidadencabezado" :key="det.nombre" id="centrado">
							<b class="primary--text" >{{det.nombre}} </b>  <br>{{det.razonSocial}},<br> {{det.dirreccion}}, Costa Rica <br>
							{{det.cedulaJuridica}} <br> {{det.telefono}}  <br>
							{{det.correo}} <br> 
							---------------------- <br>
							<div class="caption">
							Vendedor: {{vendedor}} <br>
							Fecha: {{today}}  <br>
							Cliente {{clienteNombre}} <br>  </div> 
							
						
					</div>
					------------------------
					<table>
						<thead>
							<tr>
								<th class="producto">Cant</th>
								<th class="precio">Nombre</th>
								<th class="precio">Precio</th>
								
							</tr>
						</thead>
						<tbody>
						<tr v-for="det in ItemProforma" :key="det.noFacturaReal" >
							<td  style="text-align:center;"> {{det.cantidad}} </td>
							<td style="text-align:center;">{{det.descripcion}}</td>
							<td v-if="ModoCant == false" style="text-align:center;">{{det.precioFinal }}</td>
							<td v-if="ModoCant == true" style="text-align:center;">{{det.precioFinal * det.cantidad }}</td>
						</tr>
						</tbody>
						
					</table>
					------------------------
					<div class="caption" style="text-align:center; ticket"> 
						<div>Imp Servicio (10%): {{Moneda()}}{{ImpServProforma}} </div>
						<div>I.V.A. (13%): {{Moneda()}}{{ImpVentas}} </div> 
						   <div class="text-h5">Total: {{Moneda()}} {{totalProforma }} </div>
						<div v-if="monedaPago == 'CRC'">$ {{(totalProforma / TipoCambioInterno).toFixed(2)}} </div> 
						<div v-if="monedaPago == 'USD'">$ {{totalProforma * TipoCambioInterno}} </div> 
					</div>
						----------------------
					<p class="caption centrado ticket">¡Factura Proforma! <br> Exchange {{TipoCambioInterno}} </p>  
				</div>
				<v-btn class="oculto-impresion" @click="dialogProforma = false" color="red"> Cerrar </v-btn>
				<v-btn class="primary mx-5 oculto-impresion" @click="ImprimirProformaClon()"> <v-icon>mdi-printer</v-icon> Red </v-btn>
				<v-btn class="primary mx-5 oculto-impresion" @click="imprimirUSB()"> <v-icon>mdi-printer</v-icon> USB </v-btn>
			</body>
			</v-card>
		</template>
	</v-dialog>
	<div class="oculto-impresion">
	  <v-dialog
			v-model="dialogdescuento"
			max-width="390"
			>
			<v-card>
				<v-card-title class="headline">
				Aplicar Descuento
				</v-card-title>

				<v-text-field type="tel" hint="Para utilizar esta funcion debe digitar el porcentaje de descuento que desea aplicar a toda la factura y despúes dar click en el botón con el simbolo porcentual"
				label="Descuento al total de factura"  @keyup.enter="AplicarDescuento()" dense rounded v-model="descuentototal" outlined> </v-text-field>

				<v-card-actions>
				<v-spacer></v-spacer>

				<v-btn
					color="error"
					text
					@click="dialogdescuento = false"
				>
					Salir
				</v-btn>

				<v-btn
					color="green darken-1"
					text
					@click="AplicarDescuento()"
				>
					Aplicar Descuento %
				</v-btn>
				</v-card-actions>
			</v-card>
	</v-dialog>
	<v-dialog v-model="dialogcuentaexpress" max-width="1200" > 
        <v-card>
          	<v-toolbar color="success"> <v-toolbar-title class="white--text">Cliente: {{clienteNombre}} </v-toolbar-title> <v-spacer/>
			  </v-toolbar>
          <v-col cols="12" sm="12">
          
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Cliente
                    </th>
                    <th class="text-left">
                      Producto
                    </th>
                    <th class="text-left">
                      Precio
                    </th>
					<th class="text-left">
                      Descuento
                    </th>
                    <th class="text-left">
                      Cant
                    </th>
                    <th class="text-left">
                      Saloner@
                    </th>
                    <th class="text-left">
                      Fecha
                    </th>
                    <th class="text-left">
                      Hora
                    </th>
                    <th class="text-left">
                      Eliminar Linea
                    </th>
					<th class="text-left">
                      Seleccionar
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in Mesa"
                    :key="item.codigop" 
                  >
				  	
                    <td>
						<v-chip :color="getColor(item.cliente)" dark>{{ item.cliente }}</v-chip>
					</td>
                    <td>{{ item.descripcion }}</td>
                    <td>{{ item.precioFinal }}</td>
					<td>%{{ item.descuento }}</td>
                    <td>{{ item.cantidad }}</td>
                    <td>{{ item.cajero }}</td>
                    <td>{{ item.fecha }}</td>
                    <td>{{ item.hora }}</td>
                    <td> <v-btn :loading="loading" large color="error" icon> <v-icon @click="BorrarLinea(item,item.mesa,item.descripcion)"> mdi-delete </v-icon> </v-btn> </td>
					<td><v-checkbox v-model="item.check" ></v-checkbox> </td>
				  </tr>
                </tbody>
              </template>
            </v-simple-table>
			<v-card-text>
					Total: {{CalcularTotalCuenta(Mesa)}}  
			</v-card-text>
			<v-card-actions>
				<v-btn :loading="loading" @click="FacturarMesa(Mesa,clienteNombre,CalcularTotalCuenta(Mesa),2)" class="mx-1" color="primary"> Facturar </v-btn>
					<v-spacer></v-spacer>
				<v-btn icon @click="CheckAll(Mesa)" class="mx-2" color="primary">
						<v-icon>mdi-check-bold</v-icon>
				</v-btn>
				<v-btn icon @click="ImprimirProforma(Mesa,clienteNombre,CalcularTotalCuenta(Mesa))" class="mx-2" color="primary"> 
						<v-icon>mdi-printer</v-icon>
				</v-btn>
			</v-card-actions>
          </v-col>
        </v-card>
    </v-dialog>
     <v-dialog v-model="dialogPrecioVariable" max-width="800px">
          <v-toolbar dark color="primary">
              <v-toolbar-title>Precio Variable</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn icon dark @click="dialogPrecioVariable = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
          </v-toolbar-items>
          </v-toolbar>
          <v-card>
            <v-card-title>
            </v-card-title>

            <v-card-text>
				<v-container grid-list-md>
					<v-layout wrap>
					<v-flex xs12 sm6 md6>
						<v-text-field v-model="descripcion" label="Nombre"/>
					</v-flex>
					
					<v-flex xs6 sm3 md3>
						<v-text-field type="number" v-model="precioVenta" label="Precio Venta"/>
					</v-flex>
					<v-flex xs3 sm3 md3>
						<v-select v-model="cocina" :items="Cocinas" label="Comanda"/>
					</v-flex>
					<v-flex xs6 sm6 md6>
							<v-select v-model="cabys" :items="selectCabys" label="Cabys"/>
					</v-flex>
					<v-flex xs6 sm6 md6>
						<v-select v-model="categoria"
						:items="Impuestos" label="% IVA">
						</v-select>
					</v-flex>
					</v-layout>
				</v-container>
            </v-card-text>

            <v-card-actions>
              <v-btn color="error" @click="dialogPrecioVariable = false">Cancelar</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="green" dark @click="PrecioVariable()">Agregar</v-btn>
            </v-card-actions>
          </v-card>
	</v-dialog>
	<v-dialog v-model="dialog" class="primary" max-width="1000"
         no-click-animation > 
        <v-toolbar  dark color="primary" class="font-weight-light body-1">
              <h4>Tomar Pedido</h4>
        </v-toolbar>
            <v-card >
				<strong class="title primary--text">Total: ₡{{Total}}</strong>
                <v-row sx6 class="mx-2"> <div class="primary--text">Datos del cliente</div> 
                <v-divider class="mt-3 mx-2"></v-divider> 
				</v-row>
                <v-row class="text-xs-right font-weight-light mx-auto" max-width="600">
                    <v-col cols="12" sm="6">
						<v-text-field filled hide-details="" clearable append-icon="mdi-account-circle-outline" v-model="clienteNombreExpress" label="Cliente"/>
						<v-text-field filled hide-details="" type="tel" clearable dense @change="obtenerCedula()" v-model="clienteCedula" label="Cédula:"/>
						<v-text-field filled hide-details="" type="email"  dense v-model="clienteCorreo" label="Correo:"/>
						<v-textarea
							v-if="!esLavaCar"
							label="Dirección 1:"
							auto-grow
							filled
							@input="updateDireccion(DireccionCliente)"
							rows="1"
							dense
							row-height="25"
							v-model="DireccionCliente"
						></v-textarea>
					</v-col>
					<v-col cols="12" sm="3">
						<v-row>
							<v-select filled :items="tipoDocumentos"  hide-details=""  v-model="clienteTipoCedula" label="Tipo de Cédula:"/>
							<v-text-field filled hide-details="" type="tel"  @keyup.enter="obtenerTelefono()"  dense v-model="clienteTelefono" label="Teléfono:"/>
							<v-select v-if="!esLavaCar" filled hide-details="" v-model="metodoPago" :items="metodosPago" label="Método de Pago" />
							<v-select  filled v-model="localidad" hide-details="" :items="localidades" label="Sucursal"/>
						</v-row>
					</v-col>
					<v-col cols="12" sm="3">
						<v-textarea
							v-if="!esLavaCar"
							label="Dirección 2:"
							auto-grow
							filled
							rows="1"
							hide-details=""
							row-height="25"
							@input="updateDireccion(DireccionCliente2)"
							v-model="DireccionCliente2"
						></v-textarea>
						<v-textarea
							v-if="!esLavaCar"
							label="Dirección 3:"
							auto-grow
							filled
							rows="1"
							hide-details=""
							row-height="25"
							@input="updateDireccion(DireccionCliente3)"
							v-model="DireccionCliente3"
						></v-textarea>
						<v-text-field v-if="!esLavaCar" filled hide-details="" label="Paga Con:" v-model="pagaCon" />
						<v-text-field v-if="esLavaCar" filled hide-details="" label="Placa:" v-model="pagaCon" />
						<v-select v-if="!esLavaCar" filter="" hide-details="" v-model="metodoToma" filled
							:items="metodosToma" label="Recibo desde" />
					</v-col>
					<v-col v-if="!esLavaCar" cols="12" sm="2" xs="2">
						<v-radio-group class="mt-1" label="Enviar a" v-model="direccion" >
							<v-radio label="Dirección 1" :value="DireccionCliente"> 
							</v-radio>
							<v-radio label="Dirección 2" :value="DireccionCliente2">
							</v-radio>
							<v-radio label="Dirección 3" :value="DireccionCliente3"></v-radio>
						</v-radio-group>
						Dirección Seleccionada : {{direccion}}
					</v-col>
					<v-col cols="12" sm="9" class="mx-3">
						<strong class="primary--text">Método de entrega</strong>
						<v-row v-if="esLavaCar" class="mt-2">
							
							<v-list class="mx-1">
								<button @click="RetirarEnSucursal()" class="payment-button cash">
									<div class="button-content">
									<i class="mdi mdi-hail"></i>
									<span>Crear Orden</span>
									</div>
								</button>
							</v-list>  
						</v-row>
						
						<v-row v-if="!esLavaCar" class="mt-2">
							<v-list class="mx-1">
								<button @click="EnvioDomicilio()" class="payment-button cash">
									<div class="button-content">
									<i class="mdi mdi-truck-fast"></i>
									<span>Domicilio</span>
									</div>
								</button>
							</v-list>  
							<v-list class="mx-1">
								<button @click="RetirarEnSucursal()" class="payment-button cash">
									<div class="button-content">
									<i class="mdi mdi-hail"></i>
									<span>Retirar</span>
									</div>
								</button>
							</v-list>  
							<v-list class="mx-1">
								<button @click="RetirarEnSucursal()" class="payment-button cash">
									<div class="button-content">
									<i class="mdi mdi-table-chair"></i>
									<span>Comer-Aquí</span>
									</div>
								</button>
							</v-list>  
						</v-row>

						<v-btn class="body-1 mt-4" @click="dialog = false" color="error">Atrás</v-btn>
					</v-col>
                </v-row>
            </v-card>
    </v-dialog>
	<v-dialog persistent v-model="dialogpuntos"  max-width="300" class="primary">
            <v-toolbar  dark color="primary" class="font-weight-light body-1">
              <h4>Puntos </h4>
            </v-toolbar>
            <v-card>
            <v-container fluid>
              <v-row > 
                <v-text-field hint="Solo se permite aplicar el 50% de la factura en puntos" persistent-hint onfocus="this.select();" @keyup.enter="PuntosMetodoPago()" rounded="" v-model="puntosAplicados"  label="Cantidad de puntos aplicar"></v-text-field>
                <v-btn large outlined color="success" class="mr-2 mx-2" @click="PuntosMetodoPago()"> <v-icon> mdi-check-circle </v-icon> </v-btn>
                <v-btn large outlined color="error" @click="limpiarPago()"> <v-icon> mdi-exit-to-app </v-icon> </v-btn>
              </v-row>
            </v-container>
            </v-card>
      	</v-dialog>
	<v-dialog v-model="dialogPagoCredito" persistent max-width="1300">
            <v-card>
                <v-toolbar dark color="primary">
                <v-toolbar-title>Proceso de Crédito</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn icon dark @click="dialogPagoCredito = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar-items>
                </v-toolbar>
                <v-card-title class="title font-weight-regular">
                </v-card-title>
                <v-card-text>
                <v-container fluid>
                    <v-row>
                     <v-col cols="12" sm="4">
                        <v-combobox dense outlined="" clearable @change="obtenerCliente()" append-icon="mdi-account-circle-outline" v-model="clienteNombre" :items="clientes" label="Cliente"></v-combobox>
                        <v-select outlined type="tel" :items="tipoDocumentos"  dense  v-model="clienteTipoCedula" label="Tipo de Cédula:"></v-select>
						 <v-textarea
							label="Detalle:"
							auto-grow
							outlined
							rows="1"
							dense
							row-height="25"
							shaped
							v-model="detalleFactura"
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12" sm="4">
						
                        <v-text-field outlined type="tel" @change="BuscarPersona()" clearable dense  v-model="clienteCedula" label="Cédula:"></v-text-field>
                        <v-text-field outlined type="email"  dense v-model="clienteCorreo" label="Correo:"></v-text-field>
                        <v-select outlined="" :readonly="esVendedor" dense v-model="vendedor" :items="Vendedores" color="success" :label="`Vendedor`"></v-select>
                    </v-col>
                    <v-col cols="12" sm="2">
						
                        <v-text-field outlined="" dense v-model="RefXML" color="success" :label="`Referencia(MM) u Orden de compra`"></v-text-field>
                        <v-text-field outlined type="email"  dense v-model="clienteCorreo2" label="Correo2:"></v-text-field>
						<v-select outlined dense type="tel" :items="TiposCredito" v-model="TipoCredito" label="Tipo de Doc"></v-select>
                    </v-col>
					<v-col cols="12" sm="2">
						<v-menu
							v-model="menu2"
							:close-on-content-click="false"
							:nudge-right="40"
							transition="scale-transition"
							offset-y
							min-width="auto"
						>
							<template v-slot:activator="{ on, attrs }">
							<v-text-field
								v-model="vencimiento"
								label="Vence"
								append-icon="mdi-calendar"
								readonly
								dense
								outlined
								v-bind="attrs"
								v-on="on"
							></v-text-field>
							</template>
							<v-date-picker
							v-model="date"
							@input="menu2 = false"
							></v-date-picker>
						</v-menu>
						 <v-text-field append-icon="mdi-phone" outlined="" dense v-model="clienteTelefono" color="success" :label="`Tel`"></v-text-field>
						 <v-text-field outlined dense v-model="diasCredito" label="Dias de crédito"></v-text-field>
                  
                       
					</v-col>
                    <v-col cols="6" sm="6">
                        <div class="primary--text font-weight">Método de Pago </div>
                              <v-row v-if="!esVendedor"> 
                                    <v-list>
                                      <v-img  @click="abrirSinpe()" max-height="55" max-width="55" :src="require('../assets/SINPEMOVIL.jpeg')"> </v-img> 
                                      <div class="text-center font-weight  ">Sinpe </div>
                                    </v-list>
                                    <v-list>
                                      <v-img @click="abrirDeposito()" max-height="55" max-width="55" :src="require('../assets/pago.png')"> </v-img> 
                                      <div class="text-center font-weight-light ">Depósito </div>
                                    </v-list>  
                                    <v-list>
                                      <v-img @click="abrirTarjeta()" max-height="55" max-width="55" :src="require('../assets/tarjeta-de-credito.png')"> </v-img> 
                                      <div class="text-center font-weight-light ">Tarjeta </div>
                                    </v-list>
                                    <v-list>
                                      <v-img @click="abrirEfectivo()" max-height="55" max-width="55" :src="require('../assets/efectivo.png')"> </v-img> 
                                      <div class="text-center font-weight-light">Efectivo </div>
                                    </v-list>
                                    <v-list>
                                      <v-img @click="abrirCheque()" max-height="55" max-width="55" :src="require('../assets/DollarIcon.jpg')"> </v-img> 
                                      <div class="text-center font-weight-light ">Dollar </div>
                                    </v-list> 
                              </v-row>
							  <v-row v-else> 
									<v-list>
										<v-img @click="abrirTarjetaGitana()" max-height="55" max-width="55" :src="require('../assets/tarjeta-de-credito.png')"> </v-img> 
										<div class="text-center font-weight-light ">Tarjeta </div>
                                    </v-list>
                              </v-row>
							  <div v-if="ComandaDigital && OrdenFactura == 0" class="primary--text font-weight ma-4">Método Entrega: 
									{{DetalleRapidoSelected}}</div>
                              	<v-row v-if="ComandaDigital && OrdenFactura == 0" class="ma-4"> 
									<v-list class="mx-1">
										<button @click="DetalleRapidoSelected = 'Aquí'"
												:class="['payment-button', 'cash', { selected2: DetalleRapidoSelected === 'Aquí' }]">
											<div class="button-content">
												<i class="mdi mdi-home"></i>
												<span>Comer-aquí</span>
											</div>
										</button>
									</v-list>

									<v-list class="mx-1">
										<button @click="DetalleRapidoSelected = 'Llevar'"
												:class="['payment-button', 'cash', { selected2: DetalleRapidoSelected === 'Llevar' }]">
											<div class="button-content">
												<i class="mdi mdi-truck"></i>
												<span>Llevar-</span>
											</div>
										</button>
									</v-list>
                              </v-row>
                    </v-col>
					<v-col cols="12" sm="6" class="grey lighten-4 blockquote">
                          <v-flex class="h6">
                            <div>  <small> Abono Efectivo: {{Moneda()}} {{efectivoPagar}}</small> |
                             <small>Aboono Tarjeta: {{Moneda()}} {{tarjetaPagar}} </small> |
                             <small>Depositos: {{Moneda()}} {{depositoPagar}} </small></div>
                            <div style="font-size:20px">  <small> IVA: {{Moneda()}} {{totalImpuesto}} </small> |
                             Vuelto: {{Moneda()}} {{vueltoPagar}}</div>
                            <strong class="text-h4 primary--text">Total: {{Moneda()}} {{Total}} | ${{ TotalDolares}} </strong> <br>
							<strong class="text-h6 primary--text">Saldo Pendiente: {{Moneda()}} {{saldo}} </strong>
                            
                          </v-flex>
                      </v-col>
						<v-col cols="7" sm="7">
							<v-btn :loading="loading" large type="submit" color="primary" id="espacioBoton" class="mr-4" style="top: 10px;" @click="validarCreditos()" @click.stop="dialog = false">
								Facturar a Crédito
							</v-btn>
							<v-btn :loading="loading" large="" v-if="$route.params.id != '0'" color="primary" class="mr-4" style="top: 10px; color: white;" @click="CrearODT()" >
								<v-icon> mdi-tools </v-icon> Crear ODT
							</v-btn> 
							<v-btn color="teal darken-1" large class="mr-4" style="top: 10px; color: white;" @click="limpiarPago()">
							<v-icon> mdi-update </v-icon> Limpiar
							</v-btn>
							<v-btn class="mr-4" large color="error" style="top: 10px; color: white;" @click="dialogPagoCredito = false">
							<v-icon> mdi-exit-to-app </v-icon>
							</v-btn> 
						</v-col>
                    </v-row>

                </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
	<v-dialog v-model="dialogPago" class="oculto-impresion" no-click-animation fullscreen>
            <v-card>
                <v-toolbar dark color="primary" class="oculto-impresion">
                <v-toolbar-title v-if="Pais == 'ESP'" class="oculto-impresion">PROCESO DE PAGO</v-toolbar-title>
				<v-toolbar-title v-if="Pais == 'ING'" class="oculto-impresion">Payment Method</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items class="oculto-impresion">
                    <v-btn icon dark @click="dialogPago = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar-items>
                </v-toolbar>
                <v-card-title class="title font-weight-regular">
                </v-card-title>
                <v-card-text>
                <v-container fluid>
                    <v-row >
					 <v-col cols="12" sm="6">
						<v-row>
							<v-combobox single-line dense outlined="" @input="onClientSelect"  clearable  style="width:100%" append-icon="mdi-account-circle-outline" v-model="clienteNombre" :items="clientes" label="Cliente"></v-combobox>
							<v-select single-line outlined type="tel" :items="tipoDocumentos"  dense style="width:50%" v-model="clienteTipoCedula" label="Tipo de Cédula:"></v-select>
							<v-text-field single-line v-model="clienteTelefono" label="Teléfono" outlined dense style="width:50%" />
							<v-textarea
								label="Detalle:"
								auto-grow
								outlined
								:disabled="Total == 0"
								rows="1"
								dense
								row-height="25"
								shaped
								v-model="detalleFactura"
							></v-textarea>
						</v-row>
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-text-field outlined type="tel" clearable dense  v-model="clienteCedula" @change="BuscarPersona()" label="Cédula | ID:"></v-text-field>
                        <v-text-field outlined type="email"  dense v-model="clienteCorreo" label="Correo | Email:"></v-text-field>
                        <v-select outlined="" dense v-model="vendedor" :items="Vendedores" color="success" :label="`Vendedor | Seller`"></v-select>
                    </v-col>
                    <v-col cols="12" sm="2">
                        <v-text-field outlined type="email"  dense v-model="clienteCorreo2" label="Correo2:"></v-text-field>
						<v-select outlined dense type="tel" :items="TiposCredito" v-model="TipoCredito" label="Tipo de Doc"></v-select>
                        <v-text-field outlined type="email"  dense v-model="motorizado" label="Motorizado:"></v-text-field>
                    </v-col>
                    <v-col cols="10" sm="7">
						<v-alert
							v-if="puntos > 0 && clienteCedula != ''"
								text
								prominent
								type="green"
								icon="mdi-alert"
							> 
							{{Moneda()}} {{puntos}} puntos disponibles
							</v-alert>
                        <div  v-if="Pais == 'ESP'" class="primary--text font-weight ma-4">Método de Pago </div>
                        <div v-if="Pais == 'ING'" class="primary--text font-weight ma-4">Payment Method </div>
                              
								<v-row v-if="vueltoPagar < 0 && esGitana" class="ma-4"> 
									<v-list class="mx-1">
										<button @click="abrirTarjetaGitana()" class="payment-button cash">
											<div class="button-content">
											<i class="mdi mdi-credit-card"></i>
											<span>Tarjeta</span>
											</div>
										</button>
									</v-list>
								</v-row>
							  	<v-row v-if="vueltoPagar < 0 && !esGitana" class="ma-4"> 
									<v-list class="mx-1">
										<button @click="abrirSinpe()" class="payment-button cash">
											<div class="button-content">
											<v-img  @click="abrirSinpe()" max-height="55" max-width="55" :src="require('../assets/SINPEMOVIL.jpeg')"> </v-img> 
											<span>Sinpe</span>
											</div>
										</button>
									</v-list>  
									<v-list class="mx-1">
										<button @click="abrirDeposito()" class="payment-button cash">
											<div class="button-content">
											<i class="mdi mdi-bank"></i>
											<span>Depósito</span>
											</div>
										</button>
									</v-list>  
									<v-list class="mx-1">
										<button @click="abrirTarjeta()" class="payment-button cash">
											<div class="button-content">
											<i class="mdi mdi-credit-card"></i>
											<span>Tarjeta</span>
											</div>
										</button>
									</v-list>
									<v-list class="mx-1">
										<button @click="abrirEfectivo()" class="payment-button cash">
											<div class="button-content"> 
											<i class="mdi mdi-cash"></i><br>
											<span>Efectivo</span>
											</div>
										</button>
									</v-list >
									<v-list class="mx-1">
										<button  @click="abrirCheque()" class="payment-button cash">
											<div class="button-content">
											<i class="mdi mdi-currency-usd"></i>
											<span>Dolares</span>
											</div>
										</button>
									</v-list> 
									<v-list class="mx-1" v-if="puntos > 0 && clienteCedula != ''">
										<button v-if="monedaPago =='CRC'" @click="abrirPuntos()" class="payment-button cash">
											<div class="button-content">
											<i class="mdi mdi-star-half-full"></i>
											<span>Puntos</span>
											</div>
										</button>
									</v-list> 
									<div v-if="esGitana">
										<v-list class="mx-1">
											<button @click="abrirTarjeta()" class="payment-button cash">
												<div class="button-content">
												<i class="mdi mdi-credit-card"></i>
												<span>Tarjeta</span>
												</div>
											</button>
										</v-list>
									</div>
								</v-row>
								<div v-if="ComandaDigital && OrdenFactura == 0" class="primary--text font-weight ma-4">Método Entrega: 
									{{DetalleRapidoSelected}}</div>
                              	<v-row v-if="ComandaDigital && OrdenFactura == 0" class="ma-4"> 
									<v-list class="mx-1">
										<button @click="DetalleRapidoSelected = 'Aquí'"
												:class="['payment-button', 'cash', { selected2: DetalleRapidoSelected === 'Aquí' }]">
											<div class="button-content">
												<i class="mdi mdi-home"></i>
												<span>Comer-aquí</span>
											</div>
										</button>
									</v-list>

									<v-list class="mx-1">
										<button @click="DetalleRapidoSelected = 'Llevar'"
												:class="['payment-button', 'cash', { selected2: DetalleRapidoSelected === 'Llevar' }]">
											<div class="button-content">
												<i class="mdi mdi-truck"></i>
												<span>Llevar-</span>
											</div>
										</button>
									</v-list>
                              </v-row>
							<v-btn height="80" width="130" :loading="loading"  dark color="#3d464d"  class="mr-4 mt-3"  @click="limpiarPantallaPago()">
								Limpiar
							</v-btn>
							<v-btn height="80" width="130" :loading="loading"  dark color="#3d464d" class="mr-4 mt-3"  @click="dialogPago = false">
								Atrás
							</v-btn> 
							<v-btn style="color: white;" :loading="loading" :disabled="vueltoPagar < 0" color="#2797ce" height="80" width="130"  class="mr-4 mt-3"  @click="pagar()">
								Pagar
							</v-btn>
							<v-btn :disabled="vueltoPagar < 0" :loading="loading"  v-if="Pais == 'ING'" height="80" width="130" type="submit" color="#2797ce" dark class="mr-4 mt-3"  @click="pagar()">
								Pay
							</v-btn>
                    </v-col>
					<v-col cols="10" sm="5" class="grey lighten-4 blockquote">
                            <div class="text-caption" >Efectivo | Cash: {{Moneda()}} {{efectivoPagar}} || Tarjeta | Card: {{Moneda()}} {{tarjetaPagar}}</div>
                            <div class="text-caption">Depositos | Deposit: {{Moneda()}} {{depositoPagar}} || IVA: {{Moneda()}} {{totalImpuesto=(calcularImpuesto)}}</div>
							<div class="text-caption">Dolares $ {{PagoDolares}} </div>
							<b v-if="tipoPago === 'EFECTIVO'"> Paga con {{Moneda()}}{{recibidoEfectivo}}  </b> <br>

							<strong v-if="vueltoPagar < 0" style="font-size: min(max(20px, 1.9vw), 35px);" class="green--text"> Faltante {{Moneda()}} {{vueltoPagar}} </strong>
                            <strong v-if="vueltoPagar >= 0" style="font-size: min(max(20px, 1.9vw), 35px);" class="green--text"> Vuelto {{Moneda()}} {{vueltoPagar.toFixed(2)}}
							<strong v-if="monedaPago == 'USD'"> Vuelto ₡ {{(vueltoPagar*TipoCambioInterno).toFixed(2)}} </strong> </strong>
                            <strong style="font-size: min(max(20px, 1.9vw), 35px);" class="primary--text">Total: {{Moneda()}}{{Total}} <strong v-if="monedaPago === 'CRC'"> ${{TotalDolares}} </strong>
							<strong v-if="monedaPago === 'USD'"> ₡{{ (Total*TipoCambioInterno).toFixed(2)}}</strong> </strong>
							
							<v-row class="pa-2"> 	
								<v-select filled style="width:30px" label="Dividir total entre" :items="Personas" v-model="dividendo"/> 
								<v-select filled style="width:30px" label="Beeper" :items="beepers" v-model="beeper"/> 
							</v-row>
							{{TotalDividido()}} Exchange {{TipoCambioInterno}}
					
					</v-col>
                  </v-row>
                  <v-row v-if="SaldoAgotado">
                      <v-flex class="h6">
                      <strong class="error--text">Lo sentimos, Tu Licencia se encuentra Vencida, por favor enviar comprobante de pago respectivo</strong>
                      <p>Plan Actual:  {{Plan}} <br>
                      <div>Limite Fact:  {{LimiteFacturas}}</div> <br>
                      <div>Facturas Realizadas este mes: {{FacturasRealizadas}}</div> <br>
                      <div>Saldo de Facturas: {{SaldoFacturas}}</div> <br> 
                      </v-flex>
                      <v-btn link href="https://chat.whatsapp.com/DGSSGdXIZd98DpWH5QxiVr" block="" color="info"> Enviar Comprobante </v-btn>
                  </v-row>
                </v-container>
                </v-card-text>
            </v-card>
	</v-dialog>
	<v-dialog
      v-model="dialogDetalle"
      width="500"
    >

      <v-card>
        <v-card-title class="headline grey lighten-2">
          Detalle del producto
        </v-card-title>

        <v-textarea  class="pa-2" label="Digite el detalle" v-model="detalledelProducto">
        </v-textarea>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="IncluirDetalle()"
          >
            Agregar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
	<v-dialog
		v-model="dialogMover"
		width="650"
		fullscreen
		no-click-animation
    >

      <v-card>
		 	<v-container>
				 
					<v-card-title class="headline grey lighten-2">
					Movimiento en productos
					</v-card-title>
					<v-card>
						<v-select v-model="KindMovement" label="Tipo de movimiento" :items="tiposMovimientos"> </v-select>
						
						<v-select v-if="KindMovement === 'ROOM'" v-model="MesaSeleccionada" label="Select Room" :items="suites" > </v-select>
						<v-select v-if="KindMovement === 'MESA'" v-model="MesaSeleccionada" label="Mesa" :items="mesastraslados"> </v-select>
						<v-combobox v-if="KindMovement === 'CLIENTE'" @change="AsignarClienteMesa()"  v-model="clienteNombre" label="Cliente" :items="ClientesEnMesa"> </v-combobox>
						<v-select :items="justificaciones" class="pa-2" label="Justificación" v-model="Justificacion">
						</v-select>

						<v-divider></v-divider>

						<v-card-actions>
							<v-btn
								color="primary"
								text
								@click="dialogMover = false"
							>
								Salir
							</v-btn>
						<v-spacer></v-spacer>
						<v-btn
							color="primary"
							text
							@click="SelectTipoMovimiento()"
						>
							Mover
						</v-btn>
						</v-card-actions>
					</v-card>
			</v-container>
      </v-card>
    </v-dialog>
	<v-dialog persistent v-model="dialogEfectivo"  max-width="300" class="primary">
		<v-toolbar  dark color="primary" class="font-weight-light body-1">
			<h4 v-if="Pais== 'ESP'">Pago en Colones</h4>
			<h4 v-if="Pais== 'ING'">Pay in Cash Colones</h4>
			
		</v-toolbar>
		<v-card >
			<v-col cols="12" sm="12"> 
				<v-text-field id="efectivo" autofocus style="font-size:25px;" @keyup.enter="PagoEfectivo()" rounded="" onfocus="this.select();" v-model="efectivoPagar"  label="Pago en efectivo"></v-text-field>
			
				<v-btn large outlined color="green" class="mr-2 mx-2" @click="PagoEfectivo()"> <v-icon> mdi-check-circle </v-icon> </v-btn>
				<v-btn large outlined color="error" @click="limpiarPago()"> <v-icon> mdi-exit-to-app </v-icon> </v-btn>
			</v-col>
			<v-col v-if="monedaPago === 'CRC'" cols="12" sm="12">
				<v-img class="mt-2 mx-2 rounded-lg"  @click="Paga1000()" max-height="100" max-width="200" :src="require('../assets/Billetes/1000.jpg')"> </v-img> 
				<v-img class="mt-2 mx-2 rounded-lg"  @click="Paga2000()" max-height="100" max-width="200" :src="require('../assets/Billetes/2000.jpg')"> </v-img> 
				<v-img class="mt-2 mx-2 rounded-lg"  @click="Paga5000()" max-height="100" max-width="200" :src="require('../assets/Billetes/5000.jpg')"> </v-img> 
			
				<v-img class="mt-2 mx-2 rounded-lg"  @click="Paga10000()" max-height="100" max-width="200" :src="require('../assets/Billetes/10000.jpg')"> </v-img> 
				<v-img class="mt-2 mx-2 rounded-lg"  @click="Paga20000()" max-height="100" max-width="200" :src="require('../assets/Billetes/20000.jpg')"> </v-img> 
			</v-col>
		</v-card>
	</v-dialog>
	<v-dialog persistent v-model="dialogTarjeta"  max-width="300" class="primary">
		<v-toolbar  dark color="primary" class="font-weight-light body-1">
			<h4>Pago en Tarjeta</h4>
			
		</v-toolbar>
		<v-card>
            <v-container fluid>
                  <v-text-field outlined id="tarjeta" autofocus style="font-size:25px;" @keyup.enter="PagoTarjeta()" rounded="" v-model="tarjetaPagar" onfocus="this.select();" label="Pago en Tarjeta"></v-text-field>
                  <v-text-field outlined @keyup.enter="PagoTarjeta()" rounded="" v-model="comprobantePago" label="Número de referencia"></v-text-field>
                    <v-btn width="100" height="90"  color="red lighten-4" @click="limpiarPago()"> <v-icon color="red darken-4"> mdi-exit-to-app </v-icon> </v-btn>
                    <v-btn width="100" height="90" color="light-green lighten-4" class="mr-2 mx-2" @click="PagoTarjeta()"> <v-icon color="light-green darken-4"> mdi-check-circle </v-icon> </v-btn>
               
            </v-container>
		</v-card>
	</v-dialog>
	<v-dialog persistent v-model="dialogDeposito"  max-width="300" class="primary">
		<v-toolbar  dark color="primary" class="font-weight-light body-1">
			<h4>Pago en Deposito</h4>
			
		</v-toolbar>
		<v-card>
            <v-container fluid>
                  <v-text-field id="deposito" autofocus class="text-h4" @keyup.enter="PagoDeposito()" outlined v-model="depositoPagar" onfocus="this.select();" label="Pago en deposito"></v-text-field>
                  <v-select outlined  v-model="banco" :items="bancos" label="Banco"></v-select>
                  <v-text-field outlined @keyup.enter="PagoDeposito()"  v-model="comprobantePago"  label="Número de referencia"></v-text-field>
                  <v-text-field outlined @keyup.enter="PagoDeposito()" v-model="señas"  label="Otras señas"></v-text-field>
					<v-btn width="100" height="90"  color="red lighten-4" @click="limpiarPago()"> <v-icon x-large color="red darken-4"> mdi-exit-to-app </v-icon> </v-btn>
                    <v-btn width="100" height="90" color="light-green lighten-4" class="mr-2 mx-2" @click="PagoDeposito()"> <v-icon x-large color="light-green darken-4"> mdi-check-circle </v-icon> </v-btn>
                
            </v-container>
            </v-card>
	</v-dialog>
	<v-dialog persistent v-model="dialogSinpe"  max-width="300" class="primary">
		<v-toolbar  dark color="primary" class="font-weight-light body-1">
			<h4>Pago en SINPE Móvil</h4>
			
		</v-toolbar>
		 <v-card>
            <v-container fluid>
                <v-row > 
                  <v-text-field id="sinpe" autofocus style="font-size:25px;" @keyup.enter="PagoSinpe()" rounded="" onfocus="this.select();" v-model="depositoPagar"  label="Pago en SINPE Móvil"></v-text-field>

                  <v-text-field @keyup.enter="PagoSinpe()" rounded="" type="tel" onfocus="this.select();" v-model="comprobantePago"  label="Número de SINPE"></v-text-field>
               
                  <v-text-field @keyup.enter="PagoSinpe()" rounded="" v-model="señas"  label="Otras señas"></v-text-field>
                
                    <v-btn outlined color="sucess" large class="mr-2 mx-2" @click="PagoSinpe()"> <v-icon> mdi-check-circle </v-icon> </v-btn>
                    <v-btn large outlined color="error" @click="limpiarPago()"> <v-icon> mdi-exit-to-app </v-icon> </v-btn>
                </v-row>

            </v-container>
		</v-card>
	</v-dialog>
	<v-dialog v-model="dialogCliente" persistent max-width="900">
		<v-card>
			<v-toolbar dark color="primary">
				<v-toolbar-title>Agregar Cliente</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-toolbar-items>
					<v-btn icon dark @click="dialogCliente = false">
					<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar-items>
			</v-toolbar>
			<v-container fluid>
				<v-row>
				<v-col cols="12" sm="12">
					<v-form ref="form" v-model="validCliente"  @submit.prevent="crearCliente">
					<v-row>
						<v-col cols="12" sm="4">
							<label style="color:red; font-size:12px;">Número Cédula o ID *</label>
							<input style="border: 1px solid #ccc; padding: 8px; width: 100%;" @change="BuscarPersona" type="text" required v-model="clienteCedula" @input="validateInputCedula" />
							
							<label style="color:grey; font-size:12px;">Teléfono principal</label>
							<input style="border: 1px solid #ccc; padding: 8px; width: 100%;" type="number" required v-model="clienteTelefono" @input="validateInputTelefono" />

							<label style="color:grey; font-size:12px;">Días de Crédito</label>
							<input style="border: 1px solid #ccc; padding: 8px; width: 100%;" type="number" required v-model="DiasCreditoCliente" />

							<label style="color:grey; font-size:12px;">Limite de crédito</label>
							<input style="border: 1px solid #ccc; padding: 8px; width: 100%;" type="number" required v-model="limiteCredito"  />

							
						
						</v-col>
						
						<v-col cols="12" sm="4">
							<label style="color:red; font-size:12px;">Nombre (Razón Social)*</label>
							<input style="border: 1px solid #ccc; padding: 8px; width: 100%;" type="text" required v-model="clienteNombre"  />

							<label style="color:red; font-size:12px;">Correo Electrónico*</label>
							<input style="border: 1px solid #ccc; padding: 8px; width: 100%;" type="text" required v-model="clienteCorreo" />

							<label style="color:grey; font-size:12px;">Correo Electrónico Alernativo</label>
							<input style="border: 1px solid #ccc; padding: 8px; width: 100%;" type="text" required v-model="clienteCorreo2" />


							<label style="color:grey; font-size:12px;">Referencia</label>
							<input style="border: 1px solid #ccc; padding: 8px; width: 100%;" type="text" required v-model="nombreFantasia" />
						</v-col>
						<v-col cols="12" sm="4">
							<label style="color:grey; font-size:12px;" for="listaDePrecio">Tipo Documento</label>
							<select style="border: 1px solid #ccc; padding: 8px; width: 100%;" v-model="clienteTipoCedula">
								<option :key="item" v-for="item in tipoDocumentos" :value="item">{{ item }}</option>
							</select>
							
							<label style="color:grey; font-size:12px;" for="listasDePrecios">Lista de Precio</label>
							<select style="border: 1px solid #ccc; padding: 8px; width: 100%;" id="listaDePrecio" v-model="listaDePrecio">
								<option :key="item" v-for="item in listasDePrecios" :value="item">{{ item }}</option>
							</select>

							<label style="color:grey; font-size:12px;" for="Vendedores">Vendedor</label>
							<select style="border: 1px solid #ccc; padding: 8px; width: 100%;" id="listaDePrecio" v-model="vendedor">
								<option :key="item" v-for="item in Vendedores" :value="item">{{ item }}</option>
							</select>

						</v-col>
						
						<v-col cols="12" sm="12"> 
							<v-divider/>
							<v-card-actions>
								<v-btn color="teal darken-1" outlined text @click="limpiarCliente()">
									Limpiar
								</v-btn>
								
								<v-btn color="error" outlined text @click="dialogCliente = false">
									Salir
								</v-btn>
								<v-spacer/>
								<v-btn type="submit" :disabled="!validCliente" color="primary" outlined >
									Crear Cliente
								</v-btn>
							</v-card-actions>
						</v-col>
					</v-row>
					
					</v-form>
				</v-col>
				</v-row>
			</v-container>
		</v-card>
	</v-dialog>
	<v-dialog persistent v-model="dialogCheque"  max-width="300" class="primary">
		<v-toolbar  dark color="primary" class="font-weight-light body-1">
			<h4 v-if="Pais== 'ESP'">Pago en Dolares</h4>
			<h4 v-if="Pais== 'ING'">Dolar Payment</h4>
		</v-toolbar>
		<v-card>
		<v-container fluid>
			<v-text-field id="dolares" style="font-size:25px;" onfocus="this.select();" @keyup.enter="PagoDolaresMethod()" rounded="" v-model="PagoDolares"  label="$"></v-text-field>
			<v-btn width="100" height="90"  color="red lighten-4" @click="limpiarPago()"> <v-icon x-large color="red darken-4"> mdi-exit-to-app </v-icon> </v-btn>
			<v-btn  width="100" height="90" color="light-green lighten-4" class="mr-2 mx-2" @click="PagoDolaresMethod()"> <v-icon x-large color="light-green darken-4"> mdi-check-circle </v-icon> </v-btn>
		</v-container>
		</v-card>
	</v-dialog>
	<v-dialog v-model="dialogmesas2" fullscreen no-click-animation>
	<v-toolbar dark color="primary">
		<v-toolbar-title>Seleccione una Mesa</v-toolbar-title>
	</v-toolbar>
	<v-card>
		<v-form>
		<v-container fluid>
			<v-row>
			<v-col>
				<div class="scroll-container">
				<svg width="1100" height="700">
					<g v-for="mesa in mesas" :key="mesa.id" @click="SeleccionarMesa(mesa.id)">
					<!-- Rectángulo detrás de la imagen de la mesa -->
					<rect
						v-if="mesa.color === 'red'"
						:x="mesa.x"
						:y="mesa.y"
						width="70"
						height="70"
						fill="red"
						fill-opacity="0.5"
					/>
					<!-- Imagen de la mesa -->
					<image
						:x="mesa.x"
						:y="mesa.y"
						width="70"
						style="border: 2px solid #ccc;"
						height="70"
						:href="require('../assets/Mesa3.png')"
					/>
					<!-- Texto de la mesa -->
					<text
						:x="mesa.x + 35"
						:y="mesa.y + 40"
						text-anchor="middle"
						fill="black"
					>
						{{ mesa.id }}
					</text>
					</g>
				</svg>
				</div>
			</v-col>
			</v-row>
			<v-divider />
			<v-row>
			<v-card max-width="180" class="mt-5 mx-5" v-for="(cuenta, index) in cuentasNoNumericas" @click="SeleccionarMesa(cuenta.value)" :key="index">
				<v-img :src="require('../assets/User.png')" height="70" width="70">
				<h3 style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
					{{ cuenta.value }}
				</h3>
				</v-img>
			</v-card>
			<v-card max-width="180" class="mt-5 mx-5">
				<v-edit-dialog
				:return-value.sync="nombreCliente"
				@save="SeleccionarMesa(nombreCliente)"
				@cancel="cancel"
				@open="open"
				large
				@close="close"
				>
				<v-img :src="require('../assets/AddUser.png')" height="70" width="70"></v-img>
				<template v-slot:input>
					<v-text-field
					autofocus
					v-model="nombreCliente"
					:rules="[max25chars]"
					single-line
					counter
					></v-text-field>
				</template>
				</v-edit-dialog>
			</v-card>
			</v-row>
			<v-divider />
			<v-card-actions>
			<v-btn height="80px" @click="dialogmesas2 = false" dark color="error">Volver</v-btn>
			</v-card-actions>
		</v-container>
		</v-form>
	</v-card>
	</v-dialog>

	<v-dialog v-model="dialogCuenta" fullscreen > 
        <v-card>
          <v-toolbar dark color="#3d464d"> <v-toolbar-title>Cuentas Abiertas </v-toolbar-title> </v-toolbar>
          <v-col cols="12" sm="12">
          
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
					<th class="text-left">
                      Eliminar
                    </th>
                    <th class="text-left">
                      Agregar
                    </th>
					<th class="text-left">
                      Ver Cuenta
                    </th>
                    <th class="text-left">
                      No Factura
                    </th>
                    <th class="text-left">
                      Fecha
                    </th>
                    <th class="text-left">
                      Cajero
                    </th>
                    <th class="text-left">
                      Total
                    </th>
                    <th class="text-left">
                      Cliente
                    </th>
                    <th class="text-left">
                      Telefono
                    </th>
                    <th class="text-left">
                      Hora
                    </th>
					<th class="text-left">
                      Dirección
                    </th>
					<th class="text-left">
                      Detalle
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in Cuentas"
                    :key="item.codigop"
                  >
                    <td> <v-btn :disabled="esVendedor" large color="error" icon> <v-icon @click="BorrarOrden(item.noFactura)"> mdi-delete </v-icon> </v-btn> </td>
                    <td> <v-btn :loading="loading" large color="green" icon> <v-icon @click="AgregarTemp(item.idRegistro,item.noFactura,item.nombre,item.tipoCompra)"> mdi-check-circle </v-icon> </v-btn> </td>
					<td> <v-btn large color="green" icon> <v-icon @click="selectCuenta(item.nombre,item.noFactura,(item.direccion + item.detalle),item.telefono,item.cajero)"> mdi-eye </v-icon> </v-btn> </td>
                    <td>{{ item.noFactura }}</td>
                    <td>{{ item.fecha }}</td>
                    <td>{{ item.cajero }}</td>
                    <td>{{ item.total }}</td>
                    <td>{{ item.nombre }}</td>
                    <td>{{ item.telefono }}</td>
                    <td>{{ item.hora }}</td>
					<td>{{ item.direccion }}</td>
					<td>{{ item.detalle }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col cols="12" sm="12">
              <v-btn height="70px" width="140px" @click="dialogCuenta = false" color="error"> Atrás </v-btn>
			  <v-btn height="70px" width="140px"  @click="dialog = true" class="mx-2" color="primary"> Crear Cuenta </v-btn>
          </v-col>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogmesa" fullscreen > 
        <v-card >
			<v-row>
				<v-card
					max-width="25%"
					min-width="420"
					class="mx-auto"
					max-height="700"
					v-for="(x , index) in TempMesa"
						:key="index"
				>
						<v-list-item class="accent" dark accent>
						<v-list-item-avatar color="white"> 
							<v-img
								:src="require('../assets/Mesa3.png')"
								alt="John"
							> <h3 style="position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);">{{MesaSeleccionada}} </h3>
							</v-img>
						</v-list-item-avatar>
						<v-list-item-content>
							<v-list-item-title class="headline">Cliente: <v-chip :color="getColor(x.Cliente)" dark>{{ x.Cliente }}</v-chip></v-list-item-title>
							<v-list-item-subtitle>Cuenta # {{x.id}}</v-list-item-subtitle>
						</v-list-item-content>
							<v-btn small fab outlined color="red" @click="dialogmesa = false">
								<v-icon>mdi-close</v-icon>
							</v-btn>  
						</v-list-item>
						<v-simple-table height="500" dense>
							<template v-slot:default>
								<thead>
								<tr>
									<th class="text-left">
									Eliminar
									</th>
									<th class="text-left">
									Select
									</th>
									<th class="text-left">
									Item
									</th>
									<th class="text-left">
									Price
									</th>
									<th class="text-left">
									Cant
									</th>
									<th class="text-left">
									Salonero
									</th>
									<th class="text-left">
									Date
									</th>
									<th class="text-left">
									Time
									</th>
								</tr>
								</thead>
								<tbody>
								<tr v-for="(item , index) in x.arr"
									:key="index"
								>
									<td> <v-btn :disabled="esVendedor" :loading="loading" color="error" icon> <v-icon @click="BorrarLineaMesa(item,item.mesa,item.descripcion)"> mdi-delete </v-icon> </v-btn> </td>
									<td><v-checkbox v-model="item.check" ></v-checkbox> </td>
									<td>{{ item.descripcion }}<br>
										<div style="font-size:10px;">{{item.detalle}} </div>
									</td>
									<td>{{ item.precioFinal }}</td>
									<td>
										<v-edit-dialog
										:return-value.sync="item.cantidad"
										@save="DividirItem(item)"
										large
										>
										{{ item.cantidad }}
										<template v-slot:input>
											<v-text-field
											onfocus="this.select();"
											v-model="cantidadDestino"
											type="num"
											label="Digite los productos a dividir"
											counter
											></v-text-field>
										</template>
										</v-edit-dialog>
									</td>
									<td>{{ item.cajero }}</td>
									<td>{{ item.fecha }}</td>
									<td>{{ item.hora }}</td>
								</tr>
								</tbody>
							</template>
						</v-simple-table>
						<v-card-actions>
								Total: {{CalcularTotalCuenta(x.arr)}}  
								<v-btn outlined @click="DividirProductos(x.arr)" class="mx-1" color="primary"> Dividir -> </v-btn>
								<v-btn outlined @click="MoverProductos(x.arr)" class="mx-1" color="primary"> Mover </v-btn>
								<v-spacer/>
								<v-text-field @keyup.enter="AplicarDescuentoMesa(x.arr)" onfocus="this.select();" style="width:10px" label="Desc" dense type="number" outlined v-model="descuentototal" />
								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<v-btn small icon v-bind="attrs" color="accent" v-on="on" @click="AplicarDescuentoMesa(x.arr)">
										<v-icon > mdi-sale </v-icon>
										</v-btn> 
									</template>
									<span>Digita un descuento para los productos seleccionados en esta cuenta</span>
								</v-tooltip>
						</v-card-actions>
						<v-card-actions>
							
							<v-btn @click="FacturarMesa(x.arr,x.Cliente,CalcularTotalCuenta(x.arr),1)" class="mx-1" color="accent"> Facturar </v-btn>
							
							<v-btn @click="FacturarMesaInvitado(x.arr,x.Cliente,CalcularTotalCuenta(x.arr))" class="mx-1" color="accent"> Invitación </v-btn>
							<v-spacer></v-spacer>
							<v-btn icon @click="CheckAll(x.arr)" class="mx-2" color="primary">
								<v-icon>mdi-check-bold</v-icon>
							</v-btn>
							<v-btn icon @click="CheckAll(x.arr)" class="mx-2" color="primary">
								<v-icon>mdi-delete</v-icon>
							</v-btn>
							<v-btn icon @click="ImprimirProforma(x.arr,x.Cliente)" class="mx-2" color="primary"> 
								<v-icon>mdi-printer</v-icon>
							</v-btn>
						</v-card-actions>
					</v-card>
					<v-card
						v-if="CuentaTemporal != []"
						max-width="30%"
						class="mx-auto"
						max-height="700"
					>
						<v-list-item class="accent" dark>
						<v-list-item-avatar color="grey"></v-list-item-avatar>
						<v-list-item-content>
							<v-list-item-title class="headline">Cliente: <v-chip :color="getColor('')" dark></v-chip></v-list-item-title>
							<v-list-item-subtitle>Cuenta Temporal</v-list-item-subtitle>
						</v-list-item-content>
						<v-btn small fab outlined color="red" @click="dialogmesa = false">
								<v-icon>mdi-close</v-icon>
							</v-btn>  
						</v-list-item>
						<v-simple-table height="500" dense>
							<template v-slot:default>
								<thead>
								<tr>
									<th class="text-left">
									Eliminar Linea
									</th>
									<th class="text-left">
									Producto
									</th>
									<th class="text-left">
									Precio
									</th>
									<th class="text-left">
									Cant
									</th>
									<th class="text-left">
									Saloner@
									</th>
									<th class="text-left">
									Fecha
									</th>
									<th class="text-left">
									Hora
									</th>
								</tr>
								</thead>
								<tbody>
								<tr v-for="(item , index) in CuentaTemporal"
									:key="index"
								>
									<td> <v-btn :disabled="esVendedor"  color="error" icon> <v-icon @click="eliminarProductoTemporal(item.idRegistro)"> mdi-delete </v-icon> </v-btn> </td>
										<td>{{ item.descripcion }}</td>
									<td>{{ item.precioFinal }}</td>
									<td>{{ item.cantidad }}</td>
									<td>{{ item.cajero }}</td>
									<td>{{ item.fecha }}</td>
									<td>{{ item.hora }}</td>
								</tr>
								</tbody>
							</template>
						</v-simple-table>
						<v-card-text>
							Total: {{CalcularTotalCuenta(CuentaTemporal)}}
						</v-card-text>
						<v-card-actions>
							
							<v-btn @click="FacturarMesa(CuentaTemporal,clienteNombre,CalcularTotalCuenta(CuentaTemporal))" class="mx-1" color="primary"> Facturar </v-btn>
							<v-btn @click="selectMesa(MesaSeleccionada)"> Limpiar Cuenta Temporal</v-btn>
							<v-spacer></v-spacer>
							<v-btn icon @click="ImprimirProforma(CuentaTemporal,'')" class="mx-2" color="primary"> 
								<v-icon>mdi-printer</v-icon>
							</v-btn>
						</v-card-actions>
				</v-card>
			</v-row>
        </v-card>
    </v-dialog>
    
	<template>
	<div class="text-center">
		<v-overlay  :value="modelCarga">
		<v-progress-circular 
			indeterminate
			size="64"
		></v-progress-circular>
		</v-overlay>
	</div>
	</template>
		<v-row class="fill-height"> <!-- Asegúrate de que v-row ocupe toda la altura -->
			<v-col cols="12" sm="7" class="fill-height"> <!-- fill-height hará que v-col ocupe toda la altura disponible -->
				<v-tabs vertical class="fill-height" color="accent" v-model="tab"> <!-- fill-height aquí también -->
				
					<v-tab
						v-for="item in Botones"  style="font-size: min(max(9px, 1.2vw), 15px); width:12vw; height: 5.3vh;"
						:key="item.text" class="var" @click="selectPosicion(item.value)"
					> 
						{{ item.text }}
					</v-tab>
						<v-tabs-items style="height: 100%;" v-model="tab">
							<v-tab-item style="height:80vh;"
							v-for="item in Botones"
							:key="item.text"
							>
							<v-row v-if="Foto" class="mx-2">
									<div style=" max-height:180vh;" 
										v-for="(platos , index) in PosicionProductos"
										:key="index">
										<div v-if="esCompuesto" class="product-button">
											<div @click="buscarCodigoBoton(platos.codigoAsoiado, platos.valorCero)" class="product-image-wrapper">
												<img class="product-image" :src="platos.foto" :alt="platos.descripcion" />
											</div>
											<div class="product-description">{{ platos.descripcion }}</div>
										</div>
										<div v-if="!esCompuesto" class="product-button">
											<div @click="buscarCodigoBoton(platos.codigoProducto,0)" class="product-image-wrapper">
												<img class="product-image" :src="platos.fotoLocalizacion" :alt="platos.descripcion" />
											</div>
											<div class="product-description">{{ platos.descripcion }}</div>
										</div>
										
									</div>
							</v-row>
							<v-row v-else class="pa-0 ml-1">
								<v-card class="mt-2 mx-1" max-width="180" flat
								v-for="(platos , index) in PosicionProductos"
									:key="index">
									<v-card 
										v-if="!esCompuesto"
										:elevation="n - 1"
										height="90"
										width="80"
										class="var black--text text-caption"
									>
									<div class="fill-height"
										align="center"
										@click="buscarCodigoBoton(platos.codigoProducto,0)"
										justify="center"> {{platos.descripcion}} </div>
									</v-card>
									<v-card
										v-if="esCompuesto"
										:elevation="n - 1"
										height="80"
										width="80"
										class="error white--text text-caption"
									>
									<div class="fill-height"
										align="center"
										@click="buscarCodigoBoton(platos.codigoAsoiado,platos.valorCero)"
										justify="center"> {{platos.descripcion}} </div>
									</v-card>
								</v-card>
							</v-row>
							</v-tab-item>
						</v-tabs-items>
				</v-tabs>
				<div class="grey--text text-caption">
					<v-icon small> mdi-printer </v-icon> POS:{{ip1}} <v-icon small> mdi-printer </v-icon> COCINA {{ip2}}  Bar {{ip3}}:
					{{Regimen}}
				</div>
				
			</v-col>
			<v-col id="columnadetalles" style=" border-left: 1px solid rgb(224, 224, 224);" cols="12" sm="5">
				
					<v-text-field hide-details="" id="buscador" @keyup.enter="BuscarCodeBar()" filled clearable onfocus="this.select();" @keyup="searchProducts()" v-model="codigo" label="Busqueda por descripción"></v-text-field>
					<v-combobox hide-details="" filled dense  class="rounded-lg" @change="AsignarClienteMesa()"  :items="ClientesEnMesa" clearable type="text" v-model="clienteNombre" label="Cliente"></v-combobox>
					
					<div>
						<div style="overflow-x:auto;">
							<v-simple-table style="min-height: 30vh; max-height:60vh;" dense>
								<template v-slot:default>
								<thead>
									<tr>
									<th class="text-left">
										Name
									</th>
									<th class="text-left">
										Cant
									</th>
									<th class="text-left">
										Precio
									</th>
									</tr>
								</thead>
								<tbody>
									
									<tr
									v-for="item in detalles"
									:key="item.name"
									
									>
									<td>
										<v-menu top :offset-y="offset">
											<template v-slot:activator="{ on, attrs }">
											<div v-bind="attrs"
												v-on="on">
												<div style="font-size: min(max(10px, 1.2vw), 15px);">{{ item.descripcion }} </div> <br>
												<div style="font-size:10px"> {{item.detalle}} </div>
											</div>
											</template>
											<v-list>
											<v-list-item>
												<v-list-item-title>
														<v-edit-dialog
															:return-value.sync="item.detalle"
															@save="save"
															@cancel="cancel"
															@open="open"
															@close="close"
															>
															<v-icon >mdi-file-document</v-icon> Detalle: {{ item.detalle }}
															<template v-slot:input>
																<v-text-field
																onfocus="this.select();"
																v-model="item.detalle"
																:rules="[max25chars]"
																single-line
																counter
																></v-text-field>
															</template>
														</v-edit-dialog>
												</v-list-item-title>
											</v-list-item>
											<v-list-item>
												<v-list-item-title>
														<v-edit-dialog
															:return-value.sync="item.descripcion"
															@save="save"
															@cancel="cancel"
															@open="open"
															@close="close"
															>
															<v-icon >mdi-pen</v-icon> Nombre Item: {{ item.descripcion }}
															<template v-slot:input>
																<v-text-field
																onfocus="this.select();"
																v-model="item.descripcion"
																:disabled="EsVendedor"
																:rules="[max25chars]"
																single-line
																counter
																></v-text-field>
															</template>
														</v-edit-dialog>
												</v-list-item-title>
											</v-list-item>
											<v-list-item  @click="FacturarProforma(item)">
												<v-list-item-title > 
													<v-edit-dialog
														:return-value.sync="item.descuento"
														large
														persistent
														@save="ValidarDescuento(item)"
														@cancel="cancel"
														@open="open"
														@close="ValidarDescuento(item)"
														>
														<div> <v-icon > mdi-sale</v-icon>  Desc: {{ item.descuento }}</div>
														<template v-slot:input>
															<div class="mt-4 text-h6">
															Descuento
															</div>
															<v-text-field
															v-model="item.descuento"
															label="Edit"
															onfocus="this.select();"
															single-line
															counter
															type="tel"
															autofocus
															></v-text-field>
														</template>
													</v-edit-dialog>	
												</v-list-item-title>
											</v-list-item>
											<v-list-item @click="item.detalle = item.detalle +' • Marcha Pasa'" >
												
												<v-list-item-title >
												<v-icon > mdi-clock-fast </v-icon> Marcha Pasa</v-list-item-title>
											</v-list-item>
											<v-list-item @click="item.detalle = item.detalle +' • Plain'" >
												
												<v-list-item-title >
												<v-icon > mdi-chili-off </v-icon> Plain</v-list-item-title>
											</v-list-item>
											<v-list-item >
												<v-edit-dialog
													large
													persistent
													@save="item.detalle = item.detalle + '• Término: '+termino"
													@cancel="cancel"
													@open="open"
													@close="close"
													>
													<v-list-item-title >
													<v-icon > mdi-food-steak </v-icon> Término Cocción  </v-list-item-title>
													<template v-slot:input>
														<div class="mt-4 text-h6">
														Seleccione término del Item
														</div>
														<v-select v-model="termino" :items="Terminos" />
													</template>
												</v-edit-dialog>
												
											</v-list-item>
											<v-list-item >
												<v-edit-dialog
													large
													persistent
													@save="item.detalle = item.detalle + ' •Posición: '+posicion"
													@cancel="cancel"
													@open="open"
													@close="close"
													>
													<v-list-item-title >
													<v-icon > mdi-account </v-icon> Posición  </v-list-item-title>
													<template v-slot:input>
														<div class="mt-4 text-h6">
														Selccion en cual silla se debe serivir el item
														</div>
														<v-select v-model="posicion" :items="Posiciones" />
													</template>
												</v-edit-dialog>
												
											</v-list-item>
											<v-list-item @click="eliminarProducto(item.id)" >
												<v-list-item-title >
												<v-icon color="error"> mdi-delete </v-icon> Eliminar Linea</v-list-item-title>
											</v-list-item>
											</v-list>
										</v-menu>
									</td>
									<td>
										<div class="amountContainerMobile" style="border-style: ridge; display: flex; width:100px"> 
											<v-btn text fab="" small="" @click="editarProductoResta(item)" > <v-icon color="red">mdi-minus  </v-icon> </v-btn>
											<label  class="cartPayLabelMobile" style="margin-top: 10px;">{{item.cantidad}}</label>
											<v-btn text class="mx-1" fab="" small="" @click="editarProductoSuma(item)" > <v-icon color="green" >mdi-plus </v-icon> </v-btn>
										</div>
									</td>
									<td>
										<v-edit-dialog
											:return-value.sync="item.precio"
											@save="save"
											@cancel="cancel"
											@open="open"
											@close="close"
											>
											<p v-if="item.descuento == 0"> {{ item.precio }}</p>
											<p v-else><s style="color:red;">{{ item.precio }}</s> </p>
											<template v-slot:input>
												<v-text-field
												onfocus="this.select();"
												v-model="item.precio"
												:rules="[max25chars]"
												single-line
												type="tel"
												:disabled="esVendedor"
												counter
												></v-text-field>
											</template>
										</v-edit-dialog>
									</td>
									</tr>
								</tbody>
								</template>
							</v-simple-table>
						</div>
						<div style="display: none;">
							<div>Sub Total: {{Moneda()}} {{(calcularPreTotal)}} </div>
							<div>Grabado: {{Moneda()}}  {{totalGravado=(calcularImpuestoTotal)}}</div>
							<div>Excento: {{Moneda()}}  {{totalExento=(calcularExento)}}</div>
							<div>IVA 1%: {{Moneda()}}  {{imp1=(calcularimp1)}}</div>
							<div>IVA 2%: {{Moneda()}}  {{imp2=(calcularimp2)}}</div>
							<div>IVA 4%: {{Moneda()}}  {{imp4=(calcularimp4)}}</div>
							<div>IVA 13%: {{Moneda()}}  {{imp13=(calcularimp13)}}</div>
						</div>
						<div v-show="!esGitana" no-gutters style="color:grey; font-size: min(max(8px, 1vw), 12px);">
							<strong>•SubTotal: </strong>{{Moneda()}} {{totalParcial=(calcularSubTotal)}}  
							<strong> •Descuento: {{Moneda()}} </strong> {{totalDescuento=(calcularDescuento)}} 
							<strong>  •Iva: </strong>  {{Moneda()}}  {{totalImpuesto=(calcularImpuesto)}} 
							<strong> •Imp Servicio: </strong>  {{Moneda()}}  {{ImpServicio=(calcularImpServicio)}} 
							<b v-if="esAdministrador || esSupervisor">Costo: {{Moneda()}}{{calcularCosto}} Utilidad: {{Moneda()}}{{(Total - calcularCosto)}} </b>
							<b v-if="esAdministrador || esSupervisor">Multiplo:{{Multiplo}}</b>
						</div>
							<strong style="font-size: min(max(20px, 1.9vw), 35px);" class="primary--text">Total: 
								<strong v-if="$store.state.usuario.localidad === 'Restaurante Don Lalo'"> {{Moneda()}}{{Total=parseFloat(calcularTotal)}} </strong>
								<strong v-else> {{Moneda()}}{{Total=parseFloat(calcularTotal)+parseInt(ImpServicio)}}</strong>
								|</strong> <strong v-if="monedaPago == 'CRC'" style="font-size: min(max(20px, 1.9vw), 35px);"> ${{ TotalDolares = (Total/TipoCambioInterno).toFixed(2)}} </strong>
							<strong v-if="monedaPago == 'USD'" style="font-size: min(max(20px, 1.9vw), 35px);"> ₡{{ (Total*TipoCambioInterno).toFixed(2)}} </strong>
							<v-btn class="btnLimpiarGrid mr-1" small @click="detalles = []" text outlined color="error"> <v-icon>mdi-delete </v-icon> </v-btn>
						<div v-if="!esGitana" >
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn small outlined text v-bind="attrs" color="accent"  v-on="on" class="btnLimpiarGrid mr-1" id="espacioBoton" @click="ExonerarProductos()"> 
									<v-icon > mdi-content-cut </v-icon>
									</v-btn> 
								</template>
									<span>Da click para exonerar el documento</span>
							</v-tooltip>
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn small outlined text v-bind="attrs" color="accent"  v-on="on" class="btnLimpiarGrid mr-1" id="espacioBoton" @click="dialogdescuento = true">
									<v-icon > mdi-sale </v-icon>
									</v-btn> 
								</template>
								<span>Digita un descuento para toda la factura</span>
							</v-tooltip>
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn small outlined  text v-bind="attrs" color="accent"  v-on="on" class="btnLimpiarGrid mr-1" id="espacioBoton" to="/conteos/Apertura">
									<v-icon> mdi-cash-lock-open </v-icon>
									</v-btn> 
								</template>
								<span>Apertura de Caja</span>
							</v-tooltip>
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn small outlined text v-bind="attrs" color="accent"  v-on="on" class="btnLimpiarGrid mr-1" id="espacioBoton" to="/conteos/Cierre">
									<v-icon > mdi-cash-lock </v-icon>
									</v-btn> 
								</template>
								<span>Cierre de Caja</span>
							</v-tooltip>
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn small outlined text v-bind="attrs" color="accent"  v-on="on" class="btnLimpiarGrid mr-1" id="espacioBoton" to="/repventas">
									<v-icon > mdi-account-cash </v-icon>
									</v-btn> 
								</template>
								<span>Consulta Facturas</span>
							</v-tooltip>
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn small outlined text v-bind="attrs" color="accent"  v-on="on" class="btnLimpiarGrid mr-1" id="espacioBoton" to="/cocina">
									<v-icon> mdi-bowl-mix</v-icon>
									</v-btn> 
								</template>
								<span>Comandas de Cocina</span>
							</v-tooltip>
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn small outlined text v-bind="attrs" color="accent"  v-on="on" class="btnLimpiarGrid mr-1" id="espacioBoton" to="/cuentasporcobrar">
									CXC
									</v-btn> 
								</template>
								<span>Cuentas por Cobrar</span>
							</v-tooltip>
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
										<v-btn small outlined text v-bind="attrs" color="accent"  v-on="on" class="btnLimpiarGrid mr-1" id="espacioBoton" @click="ExonerarImpServ()">
										<v-icon > mdi-box-cutter-off</v-icon>
										</v-btn> 
									</template>
									<span>Exonerar Impuesto Servicio</span>
							</v-tooltip>
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn small outlined text v-bind="attrs" color="accent"  v-on="on" class="btnLimpiarGrid mr-1" id="espacioBoton" @click="dialogPrecioVariable = true">
									<v-icon > mdi-basket-plus</v-icon>
									</v-btn> 
								</template>
								<span>Precio Variable</span>
							</v-tooltip>
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn small outlined text v-bind="attrs" color="accent"  v-on="on" class="btnLimpiarGrid mr-1" id="espacioBoton" @click="toggleCurrency">
									<v-icon > mdi-currency-usd</v-icon>
									</v-btn> 
								</template>
								<span>Cambiar Moneda</span>
							</v-tooltip>
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn small outlined text v-bind="attrs" color="accent"  v-on="on" class="btnLimpiarGrid mr-1" id="espacioBoton" to="/compras">
									<v-icon > mdi-shopping</v-icon>
									</v-btn> 
								</template>
								<span>Compras</span>
							</v-tooltip>
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn small outlined text v-bind="attrs" color="accent"  v-on="on" @click="dialogCliente = true" class="btnLimpiarGrid mr-1" id="espacioBoton">
									<v-icon >mdi-account-plus</v-icon>
									</v-btn> 
								</template>
								<span>Crear Cliente</span>
							</v-tooltip>
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn small outlined text v-bind="attrs" color="accent"  v-on="on" @click="DesactivarPromo" class="btnLimpiarGrid mr-1" id="espacioBoton">
									<v-icon >mdi-cookie-off </v-icon>
									</v-btn> 
								</template>
								<span>Desabilitar promociones para esta factura</span>
							</v-tooltip>
							<v-tooltip v-if="modoPDV === 3" bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn small outlined text v-bind="attrs" color="accent"  v-on="on" @click="AbrirCajon" class="btnLimpiarGrid mr-1" id="espacioBoton">
									<v-icon >mdi-folder-open</v-icon>
									</v-btn> 
								</template>
								<span>Abrir cajón de dinero</span>
							</v-tooltip>
							<v-tooltip v-if="modoPDV === 6" bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn small outlined text v-bind="attrs" color="accent"  v-on="on" @click="abrirCajonDinero" class="btnLimpiarGrid mr-1" id="espacioBoton">
									<v-icon >mdi-folder-open</v-icon>
									</v-btn> 
								</template>
								<span>Abrir cajón de dinero</span>
							</v-tooltip>
							<v-tooltip v-if="modoPDV === 6" bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn small outlined text v-bind="attrs" color="accent"  v-on="on" @click="imprimirTicket" class="btnLimpiarGrid mr-1" id="espacioBoton">
									<v-icon >mdi-printer</v-icon>
									</v-btn> 
								</template>
								<span>Imprimir Prueba</span>
							</v-tooltip>
							<v-menu top :offset-y="offset">
								<template v-slot:activator="{ on, attrs }">
									<v-btn
									small
									id="espacioBoton"
									outlined
									v-bind="attrs"
									v-on="on"
									
									> LP
									</v-btn>
								</template>

								<v-list>
									<v-list-item @click="SetPrecioVenta()">
									<v-list-item-title><v-icon >mdi-barcode-scan</v-icon> Precio Venta</v-list-item-title>
									</v-list-item>
									<v-list-item @click="SetPrecioCentral()">
										<v-list-item-title><v-icon >mdi-barcode-scan</v-icon> Precio Central</v-list-item-title> 
									</v-list-item>
									<v-list-item @click="SetPrecioMayor()">
										<v-list-item-title><v-icon >mdi-barcode-scan</v-icon> Precio Mayor</v-list-item-title>
										</v-list-item>
									<v-list-item @click="SetPrecioDetalle()">
										<v-list-item-title><v-icon >mdi-barcode-scan</v-icon> Precio al Detalle</v-list-item-title>
									</v-list-item>
								</v-list>
							</v-menu>
						</div>
						<v-row style="font-size: min(max(8px, 1.2vw), 15px);" no-gutters>
							<v-select filled dense style="width:100px" v-model="vendedor" class="rounded-lg" :items="Vendedores" color="success" :label="`Vendedor`"/>
							<v-text-field filled dense style="width:100px" label="Detalle" class="rounded-lg mx-1" :items="DetalleRapido" v-model="detalleFactura" /> 
							<v-select filled dense style="width:100px" label="Personas" class="rounded-lg mx-1" :items="Personas" v-model="dividendo" /> 
							
						</v-row>
					</div>
			</v-col>
		</v-row>
		<v-btn 
			bottom
			color="black oculto-impresion"
			dark
			fab
			fixed
			top="20"
			:loading="loading"
			right
			@click="AbrirMesa()"
		> 
			<v-icon>mdi-table-chair</v-icon>
		</v-btn >
		<v-footer
			style="#3d464d"
			color="#3d464d"
			fixed
			padless
		>
			<v-row
			justify="center"
			no-gutters
		>
				<v-btn :disabled="bloqueoMesaPosFactura" :loading="loading" style="font-size: min(max(10px, 1vw), 20px);" min-height="55" width="20%" dark @click="obtenerTicketMesa()"  text > <v-icon style="justify: center; ">  mdi-table-furniture</v-icon> <div style="font-size: min(max(10px, 1.2vw), 15px);" class="hidden-sm-and-down" v-if="detalles.length != 0"> Agregar a Mesa </div> <div style="font-size: min(max(10px, 1.2vw), 15px);" class="hidden-sm-and-down" v-if="detalles.length == 0">  Ver :  </div> {{MesaSeleccionada}}  </v-btn>
				<v-btn min-height="55" width="20%" dark @click="AbrirExpress()"  text style="border-left: 1px solid rgb(224, 224, 224);" > <v-icon style="justify: center;"> mdi-truck-fast</v-icon> <div style="font-size: min(max(10px, 1vw), 20px);" class="hidden-sm-and-down"> Crear Pre Orden </div> </v-btn>
				<v-btn min-height="55" width="20%" dark @click="BuscarCuentas()"  text style="border-left: 1px solid rgb(224, 224, 224);" > <v-icon style="justify: center;"> mdi-clipboard-list</v-icon> <div style="font-size: min(max(10px, 1vw), 20px);" class="hidden-sm-and-down">Ordenes </div>  </v-btn>
				<v-btn min-height="55" width="20%" dark @click="facturarCredito()" text style="border-left: 1px solid rgb(224, 224, 224);" > <v-icon style="justify: center;"> mdi-contactless-payment</v-icon> <div style="font-size: min(max(10px, 1vw), 20px);" class="hidden-sm-and-down"> Crédito </div>   </v-btn>
				<v-btn height="55" width="20%" @click="facturar()" dark color="#2797ce" > <div style="font-size: min(max(10px, 1vw), 20px);"> Facturar </div>  </v-btn>
		</v-row>
		</v-footer>
      <v-snackbar
        v-model="snackbar" top vertical="" :timeout="4000"
        >
        {{ Mensaje }}
        <v-btn
          color="error"
          text
          @click="snackbar = false"
        >
          Close
        </v-btn>
        
      </v-snackbar>
      
	</div>
  </v-container>
</template>
<script>
  import axios from 'axios';
  // Función para convertir una imagen a base64 y almacenarla
	function guardarImagenEnLocalStorage(url, key) {
	axios.get(url, { responseType: 'blob' })
		.then(response => {
		const reader = new FileReader();
		reader.readAsDataURL(response.data); 
		reader.onloadend = function() {
			const base64data = reader.result;                
			localStorage.setItem(key, base64data);
		}
		}).catch(error => console.error('Error al descargar la imagen:', error));
	}

	// Función para recuperar la imagen de localStorage
	function recuperarImagenDeLocalStorage(key) {
	return localStorage.getItem(key);
	}
  export default {

    
    data () {
      return {
        totalGravado:0,
        latitudrest: 5.0 ,
        longitudrest: -2.0,
        google: null,
		cuentasNoNumericas: [],
        geometry: null,
		headers: [
          {
            text: 'Descripcion',
            align: 'start',
            sortable: false,
            value: 'descripcion',
          },
          { text: 'Cant', value: 'cantidad' },
          { text: 'Precio', value: 'precio' },
          { text: 'Opciones', value: 'action', sortable: false },
        ],
		dialogDetalle: false,
        subtotal: 0,
		descuentototal: 0,
		tipoFactura: 4,
		detalledelProducto: "",
        distanciaenkms: 0,
		limiteCredito: 0,
        indexProducto:'',
        show: false,
		OrdenFactura: 0,
		validCliente: false,
		ActivadorPromos:true,
        extra: '',
		tipoPago:"",
		CierreGlobal:false,
        saborbebida: '',
		close: false,
		Colones:0,
		open:false,
		DiasCreditoCliente: 0,
		nombre:'',
		date: null,
		dialogdescuento: false,
		beeper: 0,
		beepers: Array.from({ length: 30 }, (_, i) => i + 1),
        imgcombo: '',
		Pais: "",
        pagorealizado: false,
        selectproducto: false,
		Personas: [1,2,3,4,5,6,7,8,9,10],
        combonombre: 'Nombre de Combo',
        cantidad: 1,
		suites: [{text:'1', value: 'Suite 1'},{text:'2', value: 'Suite 2'},{text:'3', value: 'Suite 3'},{text:'4', value: 'Suite 4'}
			,{text:'5', value: 'Suite 5'},{text:'6', value: 'Suite 6'},{text:'7', value: 'Suite 7'}
		],
		
		mesastraslados: [{text:'1', value: 'Suite 1'},{text:'2', value: 'Suite 2'},{text:'3', value: 'Suite 3'},{text:'4', value: 'Suite 4'}
			,{text:'5', value: 'Suite 5'},{text:'6', value: 'Suite 6'},{text:'7', value: 'Suite 7'}
		],
        mesas:[] ,
		villas: ["Villa 1","Villa 2,","Villa 3","Villa 4","Villa 5","Villa 6","Villa 7","Villa 8","Villa 9"],
		barra: ["Barra 1","Barra 2,","Barra 3","Barra 4","Barra 5","Barra 6","Barra 7"],
        combos: [],
        productos: [],
        detalles:[],
        Mesa: [],
		Regimen: "TRADICIONAL",
        dialogmesa: false,
        sandwiches: [],
        PosicionProductos: [],
        messages: 0,
		totalProforma: 0,
		ImpServProforma: 0,
		CondicionVenta : 'CONTADO',
		modoPDV:0,
		Total: 0,
		habilitarCampoTexto: false, // Controla la visibilidad del campo de texto
      	nombreCliente: '', 
        detalle:'',
		cantidadDestino:0,
		noFactura:0,
		IviBackend:false,
		RefXML:"",
		TempMesa: [],
		ComandaDigital:false,
		clienteCedula: "",
		dividendo: 1,
		menu2: null,
		clienteCorreo:"",
		clienteTelefono:"",
    	dialogPrecioVariable: false,
		PagoDolares: 0,
		clienteCorreo2: "",
		clienteTipoCedula: "",
        justificaciones: ['Cargo Habitación','Cambio de Mesa','Invitación','Cliente equivocado','Mesa equivocada','Digitación incorrecta'],
		PagaBillete: false,
        opcionesretiro: ['Recoger en Rest','A Domicilio','Comer en Rest'],
		DetalleRapido: ['Llevar','Servir'],
		DetalleRapidoSelected: null,
        metodoPago: "Efectivo",
		metodoToma: "Teléfono",
        metodosPago: ['Efectivo','Sinpe Móvil','Tarjeta'],
		metodosToma: ['Teléfono','WhatsApp','Otro'],
		PagosRecibo: ['Completo','2000','4000','5000','6000','7000','8000','9000','10000','20000'],
        sumaruno: 1,
		pagaCon: "",
        nota: '',
		monedas: [
          'CRC',
          'USD',],
		monedaPago: "CRC",
        today: new Date().toISOString().substr(0, 10),
        codigo: "",
		timeouts: [],
		Stock0:false,
        sopas: [],
        shots: [],
        localidades:[],
        center: { lat: this.latitud , lng: this.logitud},
        markers: [
          {position: {lat: this.latitudrest , lng: this.longitudrest}},
          {position: {lat: 9 , lng: -81}},
        ],
        localidad: this.$store.state.usuario.localidad,
		dialogProforma: false,
        Total: 0,
        telefono: this.$store.state.usuario.telefono,
        nombre: this.$store.state.usuario.nombre,
        direccion: "",
        email: this.$store.state.usuario.correo,
        totaliva: 0,
		dialogCuenta: false,
		CuentaTemporal: [],
		Cocinas:[    
            { text: 'NO', value: 0  },
            { text: 'Cocina', value: 1  }, 
			{ text: 'Bar', value: 2  }, 
			{ text: 'Kiosko 1', value: 3  }, 
			{ text: 'Kiosko 2', value: 4  }, 
                                
        ],
		cocina:"",
		ItemProforma: [],
        posicion:[],
		dialogmesas2: false,
		detalleFactura: "",
		depositoPagar: 0,
		efectivoPagar: 0,
		KindMovement: "",
		max25chars: v => v.length <= 25 || 'Input too long!',
        pagination: {},
		vueltoPagar: 0,
		totalExento:0,
        valida: 1,
        BuscarMesaOcupada: [],
        pollo: [],
		ModoCant: false,
		dialogcuentaexpress:false,
		listaDePrecio:'Precio Venta',
		diasCredito: 30,
		dialogCliente: false,
		AgregarCompuesto :true,
		AddImpServ: false,
		VendDocRequisito: false,
		ActivarRecibos:false,
		Stock0: 10,
		ClaveComprobante:"",
		DireccionCliente: "",
		DireccionCliente2:"",
		DireccionCliente3:"",
        Precio: 0,
		tarjetaCampo:'',
		efectivoCampo:'',
		puntos: 0,
		ImpVentas:0,
		pago:0,
		ImpServicio: 0,
		num: 0,
		dialogPago: false,
		ip1: "",
		devid2: "",
		bancos: ["Banco Costa Rica","Banco Popular","BAC San José","Banco Nacional","Bancredito","Lafise","Davivienda","Cathay","Scotia Bank de Costa Rica" ],
		banco: "",
		comprobantePago: "",
		señas: "",
		ip2: "",
		devid1: "",
		clientes: [],
        loading: false,
		copias: 1,
        MesaSeleccionada: '',
        emailRules: [
        v => !!v || 'El correo electrónico es requerido',
        v => /.+@.+\..+/.test(v) || 'El correo electrónico es requerido debe ser válido',],
        cedulaRules: [value => !!value || 'La cédula es requerida'
        , value => (value && value.length >= 9) || 'Debe ingresar 9 carácteres como mínimo',],
        nameRules: [
          value => !!value || 'El nombre es requerido',
          value => (value && value.length >= 10) || 'Debe ingresar 10 carácteres como mínimo',
        ],
        direcciónRules: [
          value => !!value || 'La dirección exacta es requerida',
          value => (value && value.length >= 15) || 'Debe ingresar 15 carácteres como mínimo',
        ],
        celRules: [
          value => !!value || 'El número es requerido',
          value => (value && value.length >= 8) || 'Debe ingresar 8 carácteres como mínimo',
        ],
        modoretiro:"",
        errorArticulo:null,
        platosFuertes: [],
		Multiplo: 5,
		Posiciones:["Silla 1","Silla 2","Silla 3","Silla 4","Silla 5","Silla 6","Silla 7"],
        agrandar: '',
		offset: 0, // Inicializa con un valor predeterminado
		termino:'',
		Terminos:["Azul","Rojo","Medio","3/4","Well Done","Pittsburgh style","Blackened"],
        bebidasFrias:[],
		tipoDocumentos: [
          'FISICA',
          'JURIDICA',
		  'DIMEX',
		  'NITE',
        ],
		tiposMovimientos: [
          'MESA',
          'CLIENTE',
		  'ROOM',
        ],
		TiposCredito: [
          'FACTURA ELECTRONICA',
          'RECIBO',
        ],
		TipoCredito : "FACTURA ELECTRONICA",
		tipoDocumento:0,
		totalImpuesto:0,
		switch1: false,
		dialogpuntos:false,
		Mesa: "",
		NotaCreditoFactura:false,
		SaldoFacturas: 0,
		SaldoAgotado : false,
		inject: ['theme'],
		localidadencabezado: [],
		multiLocalidad:0,
		EsCero: false,
		nombreFantasia: '',
		Justificacion: "",
		actuallocalidad:[],
		comidasRapidas: [],
		DetallesMesa: [],
		totalParcial:0,
		vendedor: '',
		dialogMover: false,
		selectCabys: [],
		modelCarga : true,
		puntosAplicados: 0,
		subFamilia: '',
		Paises: ["ESP","ING"],
		Pais: '',
		promociones: [],
		Plan: "",
		TipoCambioInterno: 0,
		LimiteFacturas: 0,
		TotalDolares: 0,
		FacturasRealizadas: 0,
		ClientesEnMesa: [],
		CopiasImpresion: 1,
		SaldoFacturas: 0,
		Fotografias:false,
		Comanda:false,
		Foto:false,
		detallescombo: [],
		Cuentas: [],
		desayunos: [],
		esCompuesto: false,
		motorizado: '',
		ip3:'',
		devid3:'',
		adicional: '',
		acompañamientos: [],
		snackbar: false,
		latitud: 0.0,
		dialogPagoCredito: false,
		longitud: 0.0,
		totalDescuento: 0,
		saldo: 0,
		Mensaje: "",
		productostotales: [],
		listasDePrecios: ["Precio Venta", "Precio Detalle", "Precio Central","Precio Mayor", "Precio Mall"],
   		dialog: false,
		dialogSinpe: false,
		dialogTarjeta: false,
		dialogdescuento: false,
		dialogCheque: false,
		dialogEfectivo: false,
		dialogDeposito: false,
		precio: "",
		xDiff: 0,
		dLon: 0,
		Express: true,
		montoexpress: 0,
		dLat: 0, 
		tab: null,
		Vendedores: [],
		R: 0,
		c: 0,
		llevaadicional: 0,
		productosDatos:[],
    	yDiff: 0,
		tarjetaPagar: 0,
		clienteNombre:"",
		clienteNombreExpress: "",
		clientesExpress: [],
		imp1: 0.0,
		imp2: 0.0,
		imp4: 0.0,
		bloqueoMesaPosFactura:false,
		imp13: 0.0,
		adress: '',
		LocalidadCompleta: [],
		Impuestos:[    
        { text: '0% Excento', value: 0  }, 
        { text: '1% Canasta Bàsica e Insumos', value: 1  }, 
        { text: '2% Medicamentos, Primas, ect.' , value: 2  },
        { text: '4% Boletos y Salud', value: 4  }, 
        { text: '13% Tarifa General', value: 13  },                      
        ],
		categoria:0,
		Botones: [],
		LocalidadActual: [],
		selectubicacion: false,
		descripcion: "",
		precioVenta: "",
		codigoDetalle:"",
		cabys: "",
		selectCabys: [{text:"Productos Alimenticios: 2399999002200", value:"2399999002200"},{text:"Cuidado Personal: 3532902000000", value:"3532902000000"},
		{text:"Productos de Limpieza: 3532901000000", value:"3532901000000"}],
		}
		},
    created () {
		//this.selectLocalidad();
		this.selectPanelControl();
		this.selectTipoCambioInterno();
		this.validarIdioma();
		this.validarMesa();
		this.validaMapa();
		//this.selectProductos();
		this.selectVendedores();
		this.validarImpresora();
		this.select();
		this.selectMesa(this.MesaSeleccionada)
		this.listarLocalidad();
		this.obtenerPlan();
    },
	mounted () {
		this.enterFullscreen();
			
		// Obtener el elemento <v-col> por su ID
		var colElement = document.getElementById('columnadetalles');

		// Obtener la altura de la pantalla
		var screenHeight = window.innerHeight;

		// Establecer la altura del elemento al valor de la altura de la pantalla
		colElement.style.height = screenHeight + 'px';
	},
    computed:{
		coordenadas(){
			return `${this.latitud}, ${this.longitud}`
		},
		calcularImpEspecial:function(){
			var resultado=0.0;
				for(var i=0;i<this.detalles.length;i++){
					if (this.detalles[i].impEspecial != 0 && this.detalles[i].impEspecial !== null && this.detalles[i].impEspecial !== "") {
						resultado = resultado + ((this.detalles[i].impEspecial /100)*this.detalles[i].precio );
					}
				}
			return resultado;
		},
		calcularCosto() {
      return this.detalles
          .filter(detalle => detalle.precioCompra != null && !isNaN(detalle.precioCompra))
          .reduce((resultado, detalle) => resultado + detalle.precioCompra * detalle.cantidad, 0)
          .toFixed(2);
    },

		userName (){
				return this.$store.state.usuario.nombre
		},
		correo (){
				return this.$store.state.usuario.correo
		},
		esAdministrador(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='Administrador';
		},
		esSupervisor(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='Supervisor';
		},
		esVendedor(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='Vendedor';
		},
		esCliente(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='Cliente';
		},
		esLavaCar(){
			return this.$store.state.usuario.localidad  == 'Donde Gabo Servicentro Lavacar';
		},
		esGitana(){
			return this.$store.state.usuario.rol  == 'Vendedor' && this.$store.state.usuario.localidad == 'Gitana';
		},
		esGitanaAll(){
			return this.$store.state.usuario.localidad == 'Gitana';
		},
		Localidad (){
			return this.$store.state.usuario.localidad
		},
		calcularTotal:function(){
			var resultado=0.0;
			var preresult = 0.0;
				for(var i=0;i<this.detalles.length;i++){
						if(this.detalles[i].Ivi == false){  //Si el campo estadoInventario es = a false entonces el IVA se suma
							resultado= (parseFloat(this.totalGravado) + parseFloat(this.calcularImpuesto)+parseFloat(this.totalExento)) - parseFloat(this.totalDescuento);
					} if(this.detalles[i].Ivi == true){
						if(this.monedaPago == 'USD'){
							preresult= (parseFloat(this.totalGravado) + parseFloat(this.calcularImpuesto)+parseFloat(this.totalExento)) - parseFloat(this.totalDescuento);
							resultado = Math.round(preresult / this.Multiplo) * this.Multiplo;
							return resultado.toFixed(2);
						} else
						{
							preresult = (parseFloat(this.totalGravado) + parseFloat(this.calcularImpuesto) + parseFloat(this.totalExento)) - parseFloat(this.totalDescuento);
							resultado = Math.round(preresult / this.Multiplo) * this.Multiplo;
							//resultado = preresult
						}
						
					}
				}
				
				if(resultado == 0){
					this.bloqueoMesaPosFactura = false
					this.OrdenFactura = 0;
				}
				return resultado.toFixed(2);
		},
		calcularDescuento:function(){
			var resultado=0.0; 
			var desc = 0;
				for(var i=0;i<this.detalles.length;i++)
				{
					if(this.detalles[i].activador === true){
						if(this.detalles.length > 1 || this.detalles[i].cantDescuento == 1){
							this.MensajeDescuento = true;
						}
						
						if(this.ActivadorPromos == true)
						{
							if (this.detalles[i].cantMinima <= this.detalles[i].cantidad){ 
								this.detalles[i].descuento = this.detalles[i].cantDescuento;
							} else {
								this.detalles[i].descuento = 0;
							}
							
							if(i == 0){
								desc = this.detalles[i].cantDescuento
							}	else
							{
								if(desc == this.detalles[i].cantDescuento){
									//Aplicar descuento a todas las lineas
									this.descuentototal = desc;
									//this.AplicarDescuento();
									this.detalles[i].descuento = desc;
									this.detalles[0].descuento = desc;
								}
							}
						}
					} else 
					{
						this.MensajeDescuento = false;
					}
				} // Aqui se calcula el descuento 
				if(this.Regimen == "SIMPLIFICADO"){
					for(var k=0;k<this.detalles.length;k++)
					{
						resultado = resultado+(((this.detalles[k].descuento)/100)*(this.detalles[k].precio * this.detalles[k].cantidad));
					}
				} else
				for(var j=0;j<this.detalles.length;j++)
				{
					if( this.detalles[j].Ivi == false){
						resultado = resultado+(((this.detalles[j].descuento)/100)*(this.detalles[j].precio * this.detalles[j].cantidad));
					} else
					if( this.detalles[j].Ivi == true){
						resultado = resultado + ((this.detalles[j].descuento/100)*((this.detalles[j].precio / 1.13) * this.detalles[j].cantidad));
					}
				}
			
			return resultado.toFixed(2);
		},
		
		calcularImpServicio:function(){
			var resultado=0.0;
			if(this.AddImpServ == true){
				if(this.Regimen == "TRADICIONAL"){
					resultado =  (parseFloat(this.calcularTotal) / 1.13) *0.10 ;
				} else
				if(this.Regimen != "TRADICIONAL"){
					resultado =  (parseFloat(this.calcularTotal))  *0.10 ;
				}
			}
			return resultado.toFixed(2);
		},
		calcularimp13:function(){
			var resultado=0.0;
				for(var i=0;i<this.detalles.length;i++){
				if(this.detalles[i].gravado == 'S' && this.detalles[i].Ivi == false && this.detalles[i].valorImpuesto == 13 )
				{
					resultado=resultado+((this.detalles[i].precio -(this.detalles[i].precio* (this.detalles[i].descuento)/100)) * this.detalles[i].cantidad) * 0.13;
				}
				if(this.detalles[i].gravado == 'S' && this.detalles[i].Ivi == true && this.detalles[i].valorImpuesto == 13)
				{
					resultado=resultado+(((this.detalles[i].precio / 1.13) -((this.detalles[i].precio / 1.13)* (this.detalles[i].descuento)/100)) * this.detalles[i].cantidad) * 0.13;
				}
				}
			return resultado;
		},
		calcularimp1:function(){
			var resultado=0.0;
				for(var i=0;i<this.detalles.length;i++){
				if(this.detalles[i].gravado == 'S' && this.detalles[i].Ivi == true && this.detalles[i].valorImpuesto == 1)
				{
					resultado=resultado+(((this.detalles[i].precio / 1.01) -((this.detalles[i].precio / 1.01)* (this.detalles[i].descuento)/100)) * this.detalles[i].cantidad) * 0.01;
				}
				if(this.detalles[i].gravado == 'S' && this.detalles[i].Ivi == false && this.detalles[i].valorImpuesto == 1)
				{
					resultado=resultado+((this.detalles[i].precio -(this.detalles[i].precio* (this.detalles[i].descuento)/100)) * this.detalles[i].cantidad) * 0.01;
				}
				}
			return resultado;
		},
		calcularimp2:function(){
			var resultado=0.0;
				for(var i=0;i<this.detalles.length;i++){
				if(this.detalles[i].gravado == 'S' && this.detalles[i].Ivi == false && this.detalles[i].valorImpuesto == 2)
				{
					resultado=resultado+((this.detalles[i].precio -(this.detalles[i].precio* (this.detalles[i].descuento)/100)) * this.detalles[i].cantidad) * 0.02;
				}
				if(this.detalles[i].gravado == 'S' && this.detalles[i].Ivi == true && this.detalles[i].valorImpuesto == 2)
				{
					resultado=resultado+(((this.detalles[i].precio / 1.02) -((this.detalles[i].precio / 1.02)* (this.detalles[i].descuento)/100)) * this.detalles[i].cantidad) * 0.02;
				}
				}
			return resultado;
		},
		calcularimp4:function(){
			var resultado=0.0;
				for(var i=0;i<this.detalles.length;i++){
				if(this.detalles[i].gravado == 'S' && this.detalles[i].Ivi == false && this.detalles[i].valorImpuesto == 4)
				{
					resultado=resultado+((this.detalles[i].precio -(this.detalles[i].precio* (this.detalles[i].descuento)/100)) * this.detalles[i].cantidad) * 0.04;
				}
				if(this.detalles[i].gravado == 'S' && this.detalles[i].Ivi == true && this.detalles[i].valorImpuesto == 4)
				{
					resultado=resultado+(((this.detalles[i].precio / 1.04) -((this.detalles[i].precio / 1.04)* (this.detalles[i].descuento)/100)) * this.detalles[i].cantidad) * 0.04;
				}
				}
			return resultado;
		},
		calcularExento:function(){
			var resultado=0.0;
				for(var i=0;i<this.detalles.length;i++){
				if(this.detalles[i].gravado == 'N')
				{
					resultado=resultado+(this.detalles[i].precio * this.detalles[i].cantidad);
				}
				}
			return resultado;
		},
		calcularImpuesto:function(){
			var resultado=0.0;
				resultado=this.imp1 + this.imp2+ this.imp4 + this.imp13;
			return resultado.toFixed(2);
		},
		calcularPreTotal:function(){
			var resultado=0.0;
				resultado = parseFloat(this.totalExento) + parseFloat(this.totalGravado);
			return resultado;
		},
		calcularSubTotal:function(){
			var resultado=0.0;
				if(this.AddImpServ === true){
					
					if(this.$store.state.usuario.localidad === 'Restaurante Don Lalo'){
						resultado = this.Total - this.calcularImpServicio;
					} else {
						resultado = parseFloat(this.totalExento) + parseFloat(this.totalGravado) - parseFloat(this.totalDescuento);
					}
					//salert("Entro a ImpServicio")
				} else
				{
					resultado = parseFloat(this.totalExento) + parseFloat(this.totalGravado) - parseFloat(this.totalDescuento);
				}
				
			return resultado.toFixed(2);
		},
		calcularNombreCliente:function(){
			var resultado="";
				resultado = this.clienteNombre;
			return resultado;
		},
		calcularImpuestoTotal:function(){
			var resultado=0.0;
			var test= false;
				for(var i=0;i<this.detalles.length;i++){
					if(this.detalles[i].Ivi == false && this.detalles[i].gravado == 'S'){
						resultado=resultado+(this.detalles[i].precio * this.detalles[i].cantidad);
						
					}    
						if(this.detalles[i].gravado == 'S' && this.detalles[i].Ivi == true)
						{
						if(this.detalles[i].valorImpuesto == 13)
						{
						resultado+=((this.detalles[i].precio / 1.13) * this.detalles[i].cantidad);
						} else
						if(this.detalles[i].valorImpuesto == 1)
						{
							resultado+=((this.detalles[i].precio / 1.01) * this.detalles[i].cantidad);
						} else
						if(this.detalles[i].valorImpuesto == 2)
						{
							resultado+=((this.detalles[i].precio / 1.02) * this.detalles[i].cantidad);
						} else
						if(this.detalles[i].valorImpuesto == 4)
						{
							resultado+=((this.detalles[i].precio / 1.04) * this.detalles[i].cantidad);
						}
						}
					}
					return resultado.toFixed(2);
				}
		},
		options () {
			return {
			duration: this.duration,
			offset: this.offset,
			easing: this.easing,
			}
		},
		
		element () {
			if (this.selected === 'Button') return this.$refs.button
			else if (this.selected === 'Radio group') return this.$refs.radio
	},
      

    methods: {
		validaMapa(){
			if(this.$store.state.usuario.localidad == "Gitana"){
				this.mesas = [
					{ id: 'T01', x: 50, y: 50, status: 'available', color: 'green' },
					{ id: 'T02', x: 150, y: 50, status: 'occupied', color: 'red' },
					{ id: 'T03', x: 50, y: 150, status: 'available', color: 'green' },
					{ id: 'T04', x: 150, y: 150, status: 'available', color: 'green' },
					{ id: 'T05', x: 400, y: 50, status: 'available', color: 'green' },
					{ id: 'T06', x: 500, y: 50, status: 'available', color: 'green' },
					{ id: 'T07', x: 600, y: 50, status: 'available', color: 'green' },
					{ id: 'T08', x: 400, y: 150, status: 'available', color: 'green' },
					{ id: 'T09', x: 500, y: 150, status: 'available', color: 'green' },
					{ id: 'T10', x: 600, y: 150, status: 'available', color: 'green' },
					{ id: 'S01', x: 50, y: 350, status: 'available', color: 'green' },
					{ id: 'S02', x: 150, y: 350, status: 'available', color: 'green' },
					{ id: 'S03', x: 50, y: 450, status: 'available', color: 'green' },
					{ id: 'S04', x: 150, y: 450, status: 'available', color: 'green' },
					{ id: 'S05', x: 400, y: 500, status: 'available', color: 'green' },
					{ id: 'S06', x: 500, y: 400, status: 'available', color: 'green' },
					{ id: 'S07', x: 400, y: 300, status: 'available', color: 'green' },
					{ id: 'S08', x: 500, y: 300, status: 'available', color: 'green' },
					{ id: 'S09', x: 600, y: 300, status: 'available', color: 'green' },
					{ id: 'S010', x: 700, y: 400, status: 'available', color: 'green' },
					{ id: 'S011', x: 700, y: 500, status: 'available', color: 'green' },
					{ id: 'S012', x: 600, y: 500, status: 'available', color: 'green' },
				];
			} else
			{
				let mesas = [];
				let x = 50;
				let y = 50;
				let incrementoX = 100; // Incremento en el eje X
				let incrementoY = 100; // Incremento en el eje Y
				let columnas = 10; // Número de columnas antes de saltar a la siguiente fila

				let ids = [
				'0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
				'21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40',
				't01', 't02', 't03', 't04', 't05', 't06', 't07', 't08', 't09', 't10'
				];

				ids.forEach((id, index) => {
				mesas.push({
					id: id,
					x: x,
					y: y,
					status: 'available',
					color: 'green'
				});

				x += incrementoX;
				if ((index + 1) % columnas === 0) { // Saltar a la siguiente fila después de 10 columnas
					x = 50;
					y += incrementoY;
				}
				});

				this.mesas = mesas;
			}
		},
		toggleCurrency() {
			if (this.monedaPago === "CRC") {
				this.monedaPago = "USD";
			} else {
				this.monedaPago = "CRC";
			}
		},
		Enviototal(){
			if(this.Express == false){
				alert('Lo sentimos esta sucursal aún no cuenta con Servicio a Domicilio, seleccione otro restaurante o Retire en la sucursal')
			}else{
			
			this.modoretiro = 'A Domicilio';
			this.selectubicacion = true;
			}
		},
		Moneda(){
			if(this.monedaPago == 'USD'){
				return "$";
			} 
			return "₡";
		},
		enterFullscreen() {
			const docEl = document.documentElement;
			if (docEl.requestFullscreen) {
			docEl.requestFullscreen().catch((error) => {
				console.log('Error al intentar ingresar al modo de pantalla completa:', error);
			});
			} else if (docEl.mozRequestFullScreen) { // Firefox
			docEl.mozRequestFullScreen().catch((error) => {
				console.log('Error al intentar ingresar al modo de pantalla completa:', error);
			});
			} else if (docEl.webkitRequestFullscreen) { // Chrome, Safari y Opera
			docEl.webkitRequestFullscreen().catch((error) => {
				console.log('Error al intentar ingresar al modo de pantalla completa:', error);
			});
			} else if (docEl.msRequestFullscreen) { // Internet Explorer/Edge
			docEl.msRequestFullscreen().catch((error) => {
				console.log('Error al intentar ingresar al modo de pantalla completa:', error);
			});
			}
		},
		SelectTipoMovimiento() {
			if(this.Justificacion == ""){
				this.Mensaje = "Debe digitar una justificación";
				this.snackbar = true;
				return;
			}
			if(this.KindMovement == "ROOM"){
				this.MoverMesa();
				return;
			} else
			if(this.KindMovement == "MESA"){
				this.MoverMesa();
				return;
			} else
			if(this.KindMovement == "CLIENTE"){
				this.MoverCliente();
				return;
			}

		},
		MoverProductos(items){
			this.DetallesMesa = [];
			this.dialogMover = true;
			items.filter(item => item.check)
			.forEach(item => {
				this.DetallesMesa.push({
					IdRegistro: item.idRegistro,
					descripcion: item.descripcion
				});
			});
		},
		DividirProductos(item) {
			this.CuentaTemporal = [];
			let tempMesa = [];

			this.CuentaTemporal = item.filter(e => e.check === true);

			for (let i = item.length - 1; i >= 0; i--) {
				if (item[i].check === true) {
				item.splice(i, 1);
				}
			}
		},


		async MoverCliente(){
			var me = this;
			this.modelCarga = true;
			this.dialogMover = false;
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			await axios.post('api/OrdenExpress/MovimientoCliente',{
				'Localidad': me.$store.state.usuario.localidad,
				'Nota': me.Justificacion,
				'Mesa':me.MesaSeleccionada,
				'Nombre':me.clienteNombre,
				'detalles': me.DetallesMesa,
				'Usuario':this.$store.state.usuario.nombre,
					
			},configuracion).then(function (response) {
				me.snackbar = true;
				me.Mensaje = 'Cliente actualizado con éxito.'; 
				me.selectMesa(me.MesaSeleccionada);
				me.KindMovement = "";
				me.clienteNombre = "";
				me.Justificacion= "";
				
				})
				.catch(function (error) {
					alert("Error realizando el movimiento, reporte a su tècnico 4070-1889"+ error);  
				})
			
		},
		async MoverMesa(){
			this.modelCarga = true;
			this.dialogMover = false;
			var me = this;
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			await axios.post('api/OrdenExpress/MovimientoMesa',{
				'Localidad': me.$store.state.usuario.localidad,
				'Nota': me.Justificacion,
				'Mesa':me.MesaSeleccionada,
				'detalles': me.DetallesMesa,
				'Usuario':this.$store.state.usuario.nombre,
					
			},configuracion).then(function (response) {
				me.snackbar = true;
				me.Mensaje = 'Mesa actualizada con éxito.'; 
				me.selectMesa(me.MesaSeleccionada);
				me.KindMovement = "";
				me.Justificacion= "";
				me.clienteNombre = "";
				})
				.catch(function (error) {
					alert("Error realizando el movimiento, reporte a su tècnico 4070-1889"+ error);  
				})
			
		},
      	CalcularMontoExpress(){
			if(this.distanciaenkms <= 2){
				this.montoexpress = 1000
			} else
			if(this.distanciaenkms > 2 && this.distanciaenkms <= 10){
				this.montoexpress =  this.distanciaenkms * 500
			} else
			if(this.distanciaenkms > 10){
				alert('Lo sentimos, aún no entregamos en tu zona, intenta con otra sucursal o retira en la sucursal')
				this.modoretiro = '';
				this.selectubicacion = false;
				//  this.latitud = 0;
				//  this.longitud = 0;
				this.distanciaenkms = 0;
				
			}
			return this.montoexpress;
		},
		AsignarClienteMesa(){
			this.clienteNombre = this.clienteNombre.value !== undefined ? this.clienteNombre.value : this.clienteNombre;

		},
		BorrarLineas(){
			let arr = [];
		},
      	BorrarLinea(item, mesa, producto) {
			var me = this;
			if (confirm("Seguro que quiere eliminar el producto " + producto + ", Se creará un registro y una notificación por correo?")) {
				this.loading = true;
				let header = { "Authorization": "Bearer " + this.$store.state.token };
				let configuracion = { headers: header };

				axios.post('api/OrdenExpress/DeleteTemp', {
					'IdRegistro': item.idRegistro,
					'Localidad': this.$store.state.usuario.localidad,
					'Usuario': this.$store.state.usuario.nombre,
					'Descripcion': item.descripcion,
				}, configuracion)
				.then(function (response) {
					me.loading = false;
					me.selectCuenta(item.cajero, item.noFactura, "");
					me.CrearRegistro(mesa, producto);
					me.Mensaje = 'Producto eliminado correctamente.';
					me.snackbar = true;
				})
				.catch(function (error) {
					me.loading = false;
					me.snackbar = true;
					if (error.response) {
						me.Mensaje = error.response.data || 'Error eliminando el producto.';
					} else {
						me.Mensaje = 'Error eliminando el producto.';
					}
				});
			}
		},
		updateDireccion(direccion) {
			this.direccion = direccion; // Actualiza la dirección con la nueva dirección

		},
		BorrarLineaMesa (item,mesa,producto) {
			var me = this;
			if(confirm("Seguro que quiere eliminar el producto "+producto+", Se creará un registro y una notificación por correo?")){
				this.loading = true;
				let header={"Authorization" : "Bearer " + this.$store.state.token};
				let configuracion= {headers : header};
				axios.post('api/OrdenExpress/DeleteTemp',{
					'IdRegistro': item.idRegistro,
					'Localidad': this.$store.state.usuario.localidad,
					'Usuario': this.$store.state.usuario.nombre,
					'Descripcion': item.descripcion,
						
					},configuracion).then(function (response) {
						me.loading = false;
						me.Mensaje = 'Producto eliminado correctamente.';
						me.snackbar = true;
						me.CrearRegistro(mesa,producto);
						me.selectMesa(me.MesaSeleccionada)
					})
					.catch(function (error) {
						me.loading = false;
						me.snackbar = true;
						if (error.response) {
							me.Mensaje = error.response.data || 'Error eliminando el producto.';
						} else {
							me.Mensaje = 'Error eliminando el producto.';
						}
						})
			}
      	},
	  async CrearRegistro(ev){
		var self = this;	
		var mail = `
			<!DOCTYPE html>
				<html lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
				<head>
					<meta charset="utf-8">
					<meta name="viewport" content="width=device-width">
					<meta http-equiv="X-UA-Compatible" content="IE=edge">
					<meta name="x-apple-disable-message-reformatting">
					<title></title>
					
					<link href="https://fonts.googleapis.com/css?family=Roboto:400,600" rel="stylesheet" type="text/css">
					<!-- Web Font / @font-face : BEGIN -->
					<!--[if mso]>
						<style>
							* {
								font-family: 'Roboto', sans-serif !important;
							}
						</style>
					<![endif]-->

					<!--[if !mso]>
						<link href="https://fonts.googleapis.com/css?family=Roboto:400,600" rel="stylesheet" type="text/css">
					<![endif]-->

					<!-- Web Font / @font-face : END -->

					<!-- CSS Reset : BEGIN -->
					
					
					<style>
						/* What it does: Remove spaces around the email design added by some email clients. */
						/* Beware: It can remove the padding / margin and add a background color to the compose a reply window. */
						html,
						body {
							margin: 0 auto !important;
							padding: 0 !important;
							height: 100% !important;
							width: 100% !important;
							font-family: 'Roboto', sans-serif !important;
							font-size: 14px;
							margin-bottom: 10px;
							line-height: 24px;
							color:#8094ae;
							font-weight: 400;
						}
						* {
							-ms-text-size-adjust: 100%;
							-webkit-text-size-adjust: 100%;
							margin: 0;
							padding: 0;
						}
						table,
						td {
							mso-table-lspace: 0pt !important;
							mso-table-rspace: 0pt !important;
						}
						table {
							border-spacing: 0 !important;
							border-collapse: collapse !important;
							table-layout: fixed !important;
							margin: 0 auto !important;
						}
						table table table {
							table-layout: auto;
						}
						a {
							text-decoration: none;
						}
						img {
							-ms-interpolation-mode:bicubic;
						}
					</style>

				</head>

				<body width="100%" style="margin: 0; padding: 0 !important; mso-line-height-rule: exactly; background-color: #f5f6fa;">
					<center style="width: 100%; background-color: #f5f6fa;">
						<table width="100%" border="0" cellpadding="0" cellspacing="0" bgcolor="#f5f6fa">
							<tr>
							<td style="padding: 40px 0;">
									<table style="width:100%;max-width:620px;margin:0 auto;">
										<tbody>
											<tr>
												<td style="text-align: center; padding-bottom:25px">
													<a href="#"><img style="height: 120px" src="https://www.noah.cr/ImagenesGenerales/LogoNoahHoizontal.png" alt="logo"></a>
													<p style="font-size: 14px; color: #6576ff; padding-top: 12px;">Notificación automatica</p>
												</td>
											</tr>
										</tbody>
									</table>
									<table style="width:100%;max-width:620px;margin:0 auto;background-color:#ffffff;">
										<tbody>
											<tr>
												<td style="padding: 30px 30px 20px">
													<p style="margin-bottom: 10px;">Notificación el eliminación de producto realizado por: `+this.$store.state.usuario.nombre+`</p>
													<p style="margin-bottom: 10px;">Producto: `+producto+` de mesa: `+mesa+`</p>
													<p style="margin-bottom: 10px;">Fecha:`+Date.now+`</p>
												</td>
											</tr>
										</tbody>
									</table>
									<table style="width:100%;max-width:620px;margin:0 auto;">
										<tbody>
											<tr>
												<td style="text-align: center; padding:25px 20px 0;">
													<p style="font-size: 13px;">Copyright © 2022 Noah Systems. Gracias por utilizar nuestros servicios <br> Sistemas Infórmaticos <a style="color: #6576ff; text-decoration:none;" href="https://www.noah.cr">Noah SRL</a>.</p>
													<ul style="margin: 10px -4px 0;padding: 0;">
														<li style="display: inline-block; list-style: none; padding: 4px;"><a style="display: inline-block; height: 30px; width:30px;border-radius: 50%; background-color: #ffffff" href="https://www.facebook.com/NoahSystemsCR"><img style="width: 30px" src="https://www.noah.cr/ImagenesGenerales/brand-b.png" alt="brand"></a></li>
														<li style="display: inline-block; list-style: none; padding: 4px;"><a style="display: inline-block; height: 30px; width:30px;border-radius: 50%; background-color: #ffffff" href="https://www.instagram.com/noah.systems/?hl=es"><img style="width: 30px" src="https://www.noah.cr/ImagenesGenerales/brand-e.png" alt="brand"></a></li>
														<li style="display: inline-block; list-style: none; padding: 4px;"><a style="display: inline-block; height: 30px; width:30px;border-radius: 50%; background-color: #ffffff" href="https://www.youtube.com/channel/UCpMW0Xdqdha04qh6ehq3v-A?reload=9&app=desktop"><img style="width: 30px" src="https://www.noah.cr/ImagenesGenerales/brand-d.png" alt="brand"></a></li>
													</ul>
													<p style="padding-top: 15px; font-size: 12px;">Este correo fue generado de forma automatica por <a style="color: #6576ff; text-decoration:none;" href="https://noah.cr">noah.cr</a>. Para actualizar el correo de notificaciones, actualice desde su panel de control <a style="color: #6576ff; text-decoration:none;" href="https://www.noah.cr/panelcontrol">click here</a>.</p>
												</td>
											</tr>
										</tbody>
									</table>
							</td>
							</tr>
						</table>
					</center>
				</body>
				</html>`;
		let header={"Authorization" : "Bearer "};
		let configuracion= {headers : header};
		await axios.post('api/Reservas/CrearRegistro',{
			'Usuario':this.$store.state.usuario.nombre,
			'name': 'Producto Eliminado '+producto,
			'Localidad':this.$store.state.usuario.localidad,
			'Estado':mail,
		},configuracion).then(function (response) {
		
		}).catch(function(error){
		
		}); 
	},
      CalcularDistancia(){
        this.xDiff = this.latitud - this.latitudrest; 
        this.yDiff = this.longitud - this.longitudrest; 
        
      this.distanciaenkms = (Math.sqrt(this.xDiff * this.xDiff + this.yDiff * this.yDiff));
      return Math.sqrt(this.xDiff * this.xDiff + this.yDiff * this.yDiff);
      },
	async obtenerPlan(){
            var self = this;
			var planes=[];
			var planes2=[];
            var clientePrevio="";
            let header={"Authorization" : "Bearer "};
            let configuracion= {headers : header};
            await axios.get('api/RepVentasVistas/ListarPlan/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
                planes=response.data;
                if(!planes){

                  return
                }else
                //self.diasCredito = planes[0].periodo;
                self.Plan = planes[0].planNoah;
                self.LimiteFacturas = planes[0].limiteFact;
                self.FacturasRealizadas = planes[0].facturaMes;
				self.SaldoFacturas = planes[0].saldoFacturas;
				if(self.SaldoFacturas <= 0){
					self.SaldoAgotado = true;
					self.modelCarga = false;
				}
                
			})
			
			axios.get('api/Localidades/ListarFiltrada/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
                planes2=response.data;
				self.modoPDV = planes2[0].modoPDV;
                self.Regimen = planes2[0].regimen;
            })
    },
	AplicarDescuento(){
		if(this.descuentototal == ''){
			this.snackbar = true;
			this.Mensaje= 'Debe digiar el porcentaje de descuento al total de factura';
			return;
		}
		for(var i=0;i<this.detalles.length;i++){
			this.detalles[i].descuento = this.descuentototal;
		}
		this.dialogdescuento = false;
		this.snackbar = true;
		this.Mensaje= 'Descuento de %'+this.descuentototal+' aplicado a todas las lineas actuales, si ingresa mas lineas deberá repetir este proceso';

	},
	AplicarDescuentoMesa(item){
		let desc = 0;
		let act = false;
		for(var i=0;i<item.length;i++){
			if(item[i].check == true){
				desc = ((this.descuentototal/100)*((item[i].precioFinal / 1.13) * item[i].cantidad));
				desc = desc.toFixed(2);
				item[i].precioFinal = item[i].precioFinal - desc;
				act = true;
			}
			
		}
		if(act == false){
			this.Mensaje = "Debe seleccionar productos para aplicar descuento";
			this.snackbar = true;
		}
	},
	eliminarLinea() {
      if(confirm('Esta seguro de eliminar los productos?')){
          this.detalles=[];
      }
    },
      CalcularDistanciaEnKms2() {
          var gps1 = new google.maps.LatLng
          (this.latitud , this.longitud);

          var gps2 = new google.maps.LatLng
          (this.latitudrest , this.longitudrest);

          this.c = 
          (google.maps.geometry.spherical.computeDistanceBetween(gps1,gps2)/1000);

          this.distanciaenkms = this.c.toFixed(2);
          
      },

      deg2rad(deg) {
        return deg * (Math.PI/180)
      },
	
	Paga1000(){
		if(this.PagaBillete == false){
			this.efectivoPagar =  1000;
			this.PagaBillete = true;
			return;
		}
		this.efectivoPagar = parseInt(this.efectivoPagar) + 1000;
		this.SaldoPendiente();
	},
	Paga2000(){
		if(this.PagaBillete == false){
			this.efectivoPagar =  2000;
			this.PagaBillete = true;
			return;
		}
		this.efectivoPagar = parseInt(this.efectivoPagar) + 2000;
		this.SaldoPendiente();
	},
	Paga5000(){
		if(this.PagaBillete == false){
			this.efectivoPagar =  5000;
			this.PagaBillete = true;
			return;
		}
		this.efectivoPagar = parseInt(this.efectivoPagar) + 5000;
		this.SaldoPendiente();
	},
	Paga10000(){
		if(this.PagaBillete == false){
			this.efectivoPagar =  10000;
			this.PagaBillete = true;
			return;
		}
		this.efectivoPagar = parseInt(this.efectivoPagar) + 10000;
		this.SaldoPendiente();
	},
	
	Paga20000(){
		if(this.PagaBillete == false){
			this.efectivoPagar =  20000;
			this.PagaBillete = true;
			return;
		}
		this.efectivoPagar = parseInt(this.efectivoPagar) + 20000;
		this.SaldoPendiente();
	},
    PagoEfectivo(){
		this.tipoPago = 'EFECTIVO';
		if (this.monedaPago === "USD") {
			var Dolares = this.efectivoPagar / this.TipoCambioInterno;
			//Preguntamos si el monto digitado es mayor al total de la factura
				if(this.calcularTotal < parseInt(Dolares)){
					// Si es correcto ponemos el total de la factura como monto recibio y el resto se asume conmo un vuelto
					this.efectivoPagar = this.calcularTotal;
					this.vueltoPagar = ((parseFloat(this.tarjetaPagar) + parseFloat(Dolares) + parseFloat(this.depositoPagar)) - parseInt(this.Total));
					this.recibidoEfectivo = Dolares;
				} else
				{
					//Si es menor o igual se agrega el monto que el usuario digito al efectivo recibido 
					this.efectivoPagar = Dolares.toFixed(2);
					this.vueltoPagar = ((parseFloat(this.tarjetaPagar) + parseFloat(this.efectivoPagar) + parseFloat(this.depositoPagar)) - parseInt(this.Total));
					this.recibidoEfectivo = this.efectivoPagar;
				}
				
		} else
			{
				this.vueltoPagar = ((parseInt(this.tarjetaPagar) + parseInt(this.efectivoPagar) + parseInt(this.depositoPagar)+ parseInt(this.Colones)) - parseInt(this.Total));
				this.recibidoEfectivo = this.efectivoPagar;
				if(this.calcularTotal < parseInt(this.efectivoPagar)){
					this.recibidoEfectivo = this.efectivoPagar;
					this.efectivoPagar = this.Total;
				}
			}
		this.dialogEfectivo = false;
		this.SaldoPendiente();
    },
	SaldoPendiente() { 
      this.saldo = (((parseInt(this.tarjetaPagar) + parseInt(this.efectivoPagar) +parseInt(this.depositoPagar)) - parseInt(this.Total))) * -1;
    }, 
	PagoDolaresMethod(){
		this.tipoPago = 'EFECTIVO';
		this.dialogCheque = false;
		//Pregunta si la configuracion de la moneda actual es Colones
		if (this.monedaPago === "CRC") 
		{
				// Aqui la moneda es colones por lo que se hace la conversion a colones
				this.Colones = this.PagoDolares * this.TipoCambioInterno;
				this.vueltoPagar = ((parseInt(this.Colones) + parseInt(this.depositoPagar)+ parseFloat(this.tarjetaPagar) ) - parseInt(this.Total));
					// Preguntamos si lo que estoy recibiendo es mayor al monto a pagar para agregar al cierre solamente el monto de la factura, el sobrando se asume que es un vuelto
					// if(this.calcularTotal < parseInt(Colones)){
					// 	this.efectivoPagar = this.calcularTotal;
					// } else
					// // 
					// 	this.efectivoPagar = Colones;
				
		} else
		if (this.monedaPago === "USD") 
		{
			// EN esta ocacion la moneda esta en Dolares por lo que no se hace la conversion, solo se agregar el pago
			this.Colones = this.PagoDolares;
			this.vueltoPagar = parseFloat(this.Colones) + parseFloat(this.depositoPagar) + parseFloat(this.tarjetaPagar) - parseFloat(this.Total);
				// Preguntamos si lo que estoy recibiendo es mayor al monto a pagar para agregar al cierre solamente el monto de la factura, el sobrando se asume que es un vuelto
				if(this.calcularTotal < parseInt(this.Colones)){
					this.efectivoPagar = this.calcularTotal;
					this.recibidoEfectivo = this.Colones;
				} else
				// 
				this.efectivoPagar = this.Colones;
				this.recibidoEfectivo = this.Colones;
			
		}
		this.SaldoPendiente();
	},
    vuelto()
    {
      this.vueltoPagar = ((parseInt(this.tarjetaPagar) + parseInt(this.efectivoPagar) + parseInt(this.depositoPagar)) - parseInt(this.Total) - parsefloat(this.calcularDescuento));
    }, 
	ValidarIvI(){
        for(var i=0;i<this.detalles.length;i++){
          if(this.detalles[i].Ivi == false)
          {
           this.IviBackend = true; //se va sumar el iva si llega aqui
           return;
          }
          if(this.detalles[i].Ivi == true)
          {
           this.IviBackend = false; // si entra aqui el IVa no se suma al precio de venta
           return;
          }
        } 
      
    },
	
	pagar() 
    {
		this.modelCarga =true;
		
		this.ValidarIvI();
		if(this.SaldoFacturas <= 0){
			this.SaldoAgotado = true;
			this.modelCarga = false;
			return;
		}
			this.resultadoCompra = ((parseInt(this.tarjetaPagar) + parseInt(this.efectivoPagar) + parseInt(this.depositoPagar)) - parseInt(this.Total));
			this.FacturarNew();
    },
	DesactivarPromo(){
		this.Mensaje ="Promociones desactivadas para esta factura";
		this.snackbar = true;
		for(var i=0;i<this.detalles.length;i++){
			this.detalles[i].activador = false;
			this.detalles[i].descuento = 0;
		}
	},
	async imprimirTicket(Factura,clave,documentoHacienda) {
		let clave2 = ""
		
		if (clave === undefined || clave === null || isNaN(clave)) {
			// El campo es undefined, null o NaN
			// Realiza la acción que deseas en este caso
			clave = "";
			} else {
			clave2 =  clave;
			}
		if (documentoHacienda === undefined || documentoHacienda === null || isNaN(documentoHacienda)) {
			// El campo es undefined, null o NaN
			// Realiza la acción que deseas en este caso
			documentoHacienda = "";
			} 
			var abono = parseInt(this.Total) - parseInt(this.saldo);
		//alert("Entramos al metodo")
		// Sección de detalles de los productos
		let imp13 = this.imp13.toFixed(2);
		let dataParaImprimir = {
			Localidad:this.localidadencabezado[0],
			productos: this.detalles,
			cliente: {
				nombre: this.clienteNombre,
				cedula: this.clienteCedula,
				telefono: this.clienteTelefono,
				documento: documentoHacienda,
				tipoPago: this.tipoPago,
				vendedor: this.vendedor,
				abono: abono,
				copias:this.copias,
				saldo:this.saldo,
				beeper:this.beeper,
			},
			moneda:this.monedaPago,
			noFactura:Factura,
			telefono: this.clienteTelefono,
			Fecha:new Date().toLocaleString(),
			tipoPago:this.tipoPago,
			documento:this.TipoCredito,
			total: this.Total,
			mesa: this.MesaSeleccionada,
			subtotal:this.totalParcial,
			descuento:this.calcularDescuento,
			vuelto:this.vueltoPagar,
			impEspecial: 0,
			impuestos: {
				iva13: imp13,
				iva1: this.imp1,
				iva2: this.imp2,
				iva4: this.imp4,
				otros: this.ImpServicio,
			},
			otrosDatos: {
				clave: clave2,
				detalle:this.detalleFactura,
			}
			
		};
		console.log(JSON.stringify(dataParaImprimir, null, 2));

		// Enviar el HTML al servidor Python para imprimir
		try {
			const response = await axios.post('http://localhost:5000/imprimir', dataParaImprimir);
			console.log('Ticket enviado a imprimir', response.data);
		} catch (error) {
			console.error('Error al enviar el ticket para imprimir', error);
		}
		this.FinalizarOrden();
	},
	Impresiondirecta(Factura){

		this.noFactura = Factura;
		if(this.Comanda == true && this.MesaSeleccionada == 0){
				 // Mostrar solo la comanda para la impresión
				var printContents = document.getElementById("comandacocina").innerHTML;
				var originalContents = document.body.innerHTML;

				document.body.innerHTML = printContents;

				window.print('', '', 'height=500, width=500');

				document.body.innerHTML = originalContents;
				// ...
			}
		
		const fechaActual = new Date();
		const Moneda = this.Moneda();
		// Obtener componentes de la fecha
		const dia = fechaActual.getDate();
		const mes = fechaActual.getMonth() + 1; // Sumamos 1 para obtener el mes correcto
		const año = fechaActual.getFullYear();

		// Obtener componentes de la hora
		const horas = fechaActual.getHours();
		const minutos = fechaActual.getMinutes();
		const segundos = fechaActual.getSeconds();

		// Formatear la fecha y hora en una cadena
		const fech = `${(dia)}/${(mes)}/${año} : ${(horas)}:${(minutos)}:${(segundos)}`;
		var contenidoImpresion = "\x1B\x40"; // Inicializar impresora en modo ESC/POS

		// Logo con enlace
		const logoURL = this.localidadencabezado[0].imagen; // Reemplaza con la URL de tu logo
		const logoHTML = `<a href="${logoURL}"><img style="max-width: 180px; max-height: 180px;" src="${logoURL}" alt="Logo" /></a>`;

		// Función para ajustar el texto a los 80 mm de ancho y establecer el tamaño de fuente
		function ajustarTexto(texto, anchoMaximo, tamañoFuente) {
				// Convierte "texto" en una cadena de texto usando toString()
				texto = texto.toString();

				var espacios = anchoMaximo - texto.length;
				var estilo = `font-size: ${tamañoFuente}px;`;
				
				if (espacios >= 0) {
					return `<span style="${estilo}">${texto + " ".repeat(espacios)}</span>`;
				} else {
					var primeraLinea = texto.slice(0, anchoMaximo);
					var segundaLinea = texto.slice(anchoMaximo, anchoMaximo * 2);
					
					return `<span style="${estilo}">${primeraLinea}</span><br><span style="${estilo}">${segundaLinea}</span>`;
				}
			}

		// Encabezado
			contenidoImpresion += `
			<table style="width: 100%;">
				<tr>
				<td style="width: 50%;">
					${logoHTML}
				</td>
				<td style="width: 50%;">
					${ajustarTexto(this.$store.state.usuario.localidad, 80, 25)}<br>
					${ajustarTexto(this.localidadencabezado[0].razonSocial, 80, 14)}<br>
					${ajustarTexto(this.localidadencabezado[0].dirreccion, 80, 14)}<br>
					${ajustarTexto(this.localidadencabezado[0].nombre, 80, 14)}<br>
					${ajustarTexto(this.localidadencabezado[0].cedulaJuridica, 80, 14)}<br>
					${ajustarTexto(this.localidadencabezado[0].telefono, 80, 14)}<br>
					${ajustarTexto(this.localidadencabezado[0].correo, 80, 14)}<br>
				</td>
				</tr>
			</table>
			`;

		// Línea horizontal
		contenidoImpresion += ajustarTexto(fech, 80,16) + "\n";
		contenidoImpresion += ajustarTexto("No Factura: "+Factura, 80,16) + "\n";
		contenidoImpresion += ajustarTexto("Cliente: "+this.clienteNombre, 80,16) + "\n";
		contenidoImpresion += ajustarTexto("Cédula: "+this.clienteCedula, 80,16) + "\n";
		contenidoImpresion += ajustarTexto("Tel: "+this.clienteTelefono, 80,16) + "\n";
		contenidoImpresion += ajustarTexto("Correo: "+this.clienteCorreo, 80,16) + "\n";
		contenidoImpresion += ajustarTexto("Documento: "+this.TipoCredito, 80,16) + "\n";
		contenidoImpresion += ajustarTexto("Tipo Pago: "+this.tipoPago, 80,16) + "\n";
		contenidoImpresion += "-".repeat(80) + "\n";

		// Encabezado de la tabla de productos
		
		contenidoImpresion += ajustarTexto("Cant", 5);
		contenidoImpresion += ajustarTexto("Nombre", 45);
		contenidoImpresion += ajustarTexto("Precio", 30); // Ancho máximo ajustado para el campo precio
		contenidoImpresion += "\n";
		contenidoImpresion += "-".repeat(80) + "\n";


		// Filas de la tabla
		this.detalles.forEach(function (producto) {
		contenidoImpresion += ajustarTexto(producto.cantidad.toString(), 5, 16);
		contenidoImpresion += ajustarTexto(producto.descripcion, 35, 16);
		contenidoImpresion += ajustarTexto(Moneda + producto.precio.toString(), 40, 16); // Ajuste también el campo precio
		contenidoImpresion += "\n";
		});
		contenidoImpresion += "-".repeat(80) + "\n";
		// Línea horizontal

		// Encabezado de la de impuestos
		contenidoImpresion += ajustarTexto("IVA 1%", 10,16);
		contenidoImpresion += ajustarTexto("IVA 2%", 10,16);
		contenidoImpresion += ajustarTexto("IVA 4%", 10,16);
		contenidoImpresion += ajustarTexto("IVA 13%", 40,16);
		contenidoImpresion += "\n";
		// Vallores de impuesto
		contenidoImpresion += ajustarTexto(Moneda+this.imp1.toFixed(2), 10,16);
		contenidoImpresion += ajustarTexto(Moneda+this.imp2.toFixed(2), 10,16);
		contenidoImpresion += ajustarTexto(Moneda+this.imp4.toFixed(2), 10,16);
		contenidoImpresion += ajustarTexto(Moneda+this.imp13.toFixed(2), 40,16);
		contenidoImpresion += "\n";	
		contenidoImpresion += "-".repeat(80) + "\n";
		contenidoImpresion += ajustarTexto("Servicio: "+this.ImpServicio, 80,16) + "\n";
		// Encabezado de la tabla totales
		contenidoImpresion += ajustarTexto("SubTotal", 10);
		contenidoImpresion += ajustarTexto("Descuento", 10);
		contenidoImpresion += ajustarTexto("   Total", 10);
		contenidoImpresion += ajustarTexto("Vuelto", 10);
		contenidoImpresion += "\n";
		
		
		// Valores de totales
		contenidoImpresion += ajustarTexto(Moneda+this.totalParcial, 10,16);
		contenidoImpresion += ajustarTexto(Moneda+this.calcularDescuento, 10,16);
		contenidoImpresion += ajustarTexto(Moneda+this.Total.toFixed(2), 10),20;
		contenidoImpresion += ajustarTexto(Moneda+this.vueltoPagar, 50,16);
		contenidoImpresion += "\n";
		
		contenidoImpresion += "-".repeat(80) + "\n";
		if(this.tipoPago == "CREDITO"  || this.OrdenFactura != 0){
			var abono = this.total - this.saldo;
			contenidoImpresion += ajustarTexto("Abono: "+abono, 80,16) + "\n";
			contenidoImpresion += ajustarTexto("Saldo: "+this.saldo, 80,16) + "\n";
			contenidoImpresion += "\n";
			contenidoImpresion += "\n";
			contenidoImpresion += "\n";
			contenidoImpresion += ajustarTexto("Firma: ____________________________", 80,16) + "\n";
		}

		// Píe Pagina
		contenidoImpresion += ajustarTexto("Detalle: "+this.detalleFactura, 80,16) + "\n";
		contenidoImpresion += ajustarTexto(this.ClaveComprobante, 80,16) + "\n";
		contenidoImpresion += this.localidadencabezado[0].longitud+"\n";
		contenidoImpresion += this.localidadencabezado[0].pieDocumento+"\n";


		contenidoImpresion += "\x1D\x56\x01"; // Corte de papel
		

			document.write('<pre>' + contenidoImpresion + '</pre>');
		 	setTimeout(() => {
			
			window.print();
			if (this.tipoPago === "CREDITO"  ||  this.OrdenFactura != 0) {
				const confirmImpresion = confirm("¿Desea imprimir otra copia?");
				if (confirmImpresion) {
					// Aquí podrías realizar acciones adicionales si el usuario confirma
					window.print();
				}
			}
			contenidoImpresion = '';
			window.location.reload();
		}, 1000);
		//ventanaImpresion.document.write('<pre>' + contenidoImpresion + '</pre>');
		//ventanaImpresion.document.close();
		//ventanaImpresion.print();
		//ventanaImpresion.close();
		//this.FinalizarOrden();
		//window.location.reload();
	},	
    pagarCredito() 
    {
		if(this.validarCreditos == 0)
		{
			this.Mensaje = 'Debe Rellenar todos los campos para guardar un crédito';
			this.snackbar = true;
			return;
		}
        if (!this.monedaPago){
            alert("Seleccione un tipo de moneda.");
            return;
        }
        this.resultadoCompra = ((parseInt(this.tarjetaPagar) + parseInt(this.efectivoPagar)) - parseInt(this.Total));
        this.CondicionVenta = "CREDITO";
			if(this.detalles[0].Ivi == false)
			{
			this.IviBackend = true;
			}
			this.CopiasImpresion = 2
			var localidadTicket = this.FacturarNew();
			if (this.saldo < 0)
			{
			alert("Revisar montos de pago, Saldo sobrepasa monto total de factura")
			}
      
    }, 
	DividirItem(item){
		if(this.cantidadDestino > item.cantidad){
			this.snackbar = true;
			this.Mensaje = "No puede dividir en una cantidad mayor a la actual"
			return;
			
		}
		let OldCant = 0
		OldCant = item.cantidad - this.cantidadDestino;
		let me = this;
		axios.post(`/api/OrdenExpress/ModificarCantidad/${item.idRegistro}/${this.cantidadDestino}/${OldCant}`)
			.then(response => {
			console.log('Modificación exitosa', response);
			me.selectMesa(me.MesaSeleccionada);
			// Manejo de la respuesta exitosa
			})
			.catch(error => {
			console.error('Error al modificar la cantidad', error);
			// Manejo de errores
			});
		
	},
	validarCreditos(){
		let valida = 1;
		if(this.clienteCedula === ""){
			valida = 0;
			this.Mensaje = 'Debe Rellenar la cédula';
			this.snackbar = true;
			return;
		} 
		if(this.clienteNombre === ""){
			valida = 0;
			this.Mensaje = 'Debe Rellenar el Nombre';
			this.snackbar = true;
			return;
		} 
		if(this.clienteTipoCedula === ""){
			valida = 0;
			this.Mensaje = 'Debe Rellenar el tipo de cédula';
			this.snackbar = true;
			return;
		} 
		this.pagarCredito();
	},
    PagoTarjeta(){
      this.tipoPago = 'TARJETA';
      this.dialogTarjeta = false;
      var resultado = 0;
      this.vueltoPagar = ((parseFloat(this.tarjetaPagar) + parseFloat(this.efectivoPagar) + parseFloat(this.depositoPagar) + parseFloat(this.depositoPagar)) - parseFloat(this.Total));
      this.detalleFactura = this.detalleFactura+this.banco 
	  + this.comprobantePago + ' '+ this.señas;
	  this.SaldoPendiente();
    },
    PagoSinpe(){
      this.tipoPago = 'DEPOSITO';
      this.dialogSinpe = false;
      this.vueltoPagar = ((parseFloat(this.tarjetaPagar) + parseFloat(this.efectivoPagar) + parseFloat(this.depositoPagar)) - parseFloat(this.Total));
      this.detalleFactura = this.detalleFactura+ this.comprobantePago + ' '+ this.señas;
	  this.SaldoPendiente();
    },
	ExonerarProductos(){
		if(confirm("Para mantener el mismo precio digite aceptar, de lo contrario el IVA se restará al precio de venta")){
			for(var i=0;i<this.detalles.length;i++){
				this.detalles[i].valorImpuesto = 0;
				this.detalles[i].gravado = 'N';
			}
		}else
		{
			for(var j=0;j<this.detalles.length;j++){
				this.detalles[j].precio = this.detalles[j].precioVenta / 1.13;
				this.detalles[j].precio = this.detalles[j].precio.toFixed(2);
				this.detalles[j].valorImpuesto = 0;
				this.detalles[j].gravado = 'N';
			}
			
		}
      	
    },
    PagoDeposito(){
		this.tipoPago = 'TRANSFERENCIA';
		this.dialogDeposito = false;
		this.vueltoPagar = ((parseFloat(this.tarjetaPagar) + parseFloat(this.efectivoPagar) + parseFloat(this.depositoPagar)) - parseFloat(this.Total));
		this.detalleFactura = this.detalleFactura+this.banco +' '+ this.comprobantePago + ' '+ this.señas;
		this.SaldoPendiente();
    },
    
	getColor (cliente) {
        if (cliente == this.ClientesEnMesa[0]) return '#8BC34A'
        else  if (cliente == this.ClientesEnMesa[1]) return '#42a5f6'
		else  if (cliente == this.ClientesEnMesa[2]) return '#424242'
		else  if (cliente == this.ClientesEnMesa[3]) return '#69166A'
		else  if (cliente == this.ClientesEnMesa[4]) return '#35136A'
		else  return '#FF1744'
      },
	CalcularTotalCuenta (item){
      var total=0.0;
	  var ImpServ = 0.0;
         for(var i = 0; i < item.length; ++i)
                {
					total += item[i].precioFinal * item[i].cantidad;
                }
			if(this.AddImpServ == true){
				if(this.Regimen == "TRADICIONAL"){
					ImpServ = (parseFloat(total) / 1.13)  *0.10 ;
				} else
				if(this.Regimen != "TRADICIONAL"){
				ImpServ = parseFloat(total)  *0.10 ;
				}
				if(this.$store.state.usuario.localidad === 'Restaurante Don Lalo'){
					total = parseInt(total);
				} else
				{
					total = parseInt(total) + parseInt(ImpServ);
					
				}
				
			}
        
        return total.toFixed(2);
    
	},
	TotalDividido (){
      var total=0.0;
	  total = this.Total / this.dividendo
      
        return total.toFixed(2);
	},
    SetUbicacionEnArray(){
      this.posicion = {lat:this.latitud, lng:this.longitud}
      this.markers.push({position: this.posicion});
    },
	selectVendedores(){
        this.vendedor = this.$store.state.usuario.nombre;
        let me=this;
		this.Vendedores.push(this.vendedor);
        let header={"Authorization" : "Bearer " };
        let configuracion= {headers : header};
        var proveedoresArray=[];
        axios.get('api/Usuarios/SelectUsuariosLocalidad/'+me.$store.state.usuario.localidad,configuracion).then(function(response){
            proveedoresArray=response.data;
            proveedoresArray.map(function(x){
                me.Vendedores.push(x.nombre);
            });
        }).catch(function(error){
            console.log(error);
        });
  	},
	BuscarCuentas(){
		this.dialogCuenta = true;
        let me =this;
		me.modelCarga = true;
		let header={"Authorization" : "Bearer "};
          let configuracion= {headers : header};
          axios.get('api/OrdenExpress/ListarLocalidad/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
              me.Cuentas=response.data;
              me.modelCarga = false;
              });
	},
	facturar() { 
		var esIvi = 0;
		var noesIvi = 0;
		for(var i = 0; i < this.detalles.length; ++i)
        {
		if(this.detalles[i].cabys == '0' || this.detalles[i].cabys == '' || this.detalles[i].cabys == null){
			if(confirm('ADVERTENCIA: existen algunos productos sin códigos Cabys, esto podría producir rechazo en comprobantes electrónicos, desea revisarlos?')){
			this.snackbar = true;
			this.Mensaje = 'Buena idea, revisa que tus productos tengan el cabys correcto.';
			return;
			 }
		}
          if( this.detalles[i].Ivi == false){
               noesIvi = 1;
            } else
            if( this.detalles[i].Ivi == true){
                esIvi = 1;
            }
		}
		if(esIvi == noesIvi){
			if(confirm('ADVERTENCIA: existen algunos productos IVI y otros no, esto podría producir una alteración en su cobro de impuestos, desea revisarlos?')){
			this.snackbar = true;
			this.Mensaje = 'Buena idea, revisa que tus productos tengan el impuesto correcto en el campo de precio';
			return;
			 }
		}
    if (this.validarPago() != 0)
    {
        this.vueltoPagar = this.Total * -1;
        this.dialogPago = true;
        this.efectivoPagar = 0;
        this.tarjetaPagar = 0;
        //this.obtenerPlan();
    }
    else
    {
        this.dialogPago = false;
        return;
    }
    },
	imprimirUSB() {
		window.print();
	},
    limpiarPago()
    {
	  this.pago = '';
	  this.PagaBillete = false;
      this.efectivoPagar = 0;
      this.tarjetaPagar = 0;
      this.depositoPagar = 0;
	  this.PagoDolares= 0;
      this.dialogDeposito = false;
      this.dialogEfectivo = false;
	  this.tipoPago = '';
      this.dialogTarjeta = false;
      this.dialogCheque = false;
      this.dialogSinpe = false;
	  
    },
	abrirPuntos(){
		this.dialogpuntos = true;
		let mitad = this.Total / 2;
		if(this.puntos > mitad ){
			this.puntosAplicados = mitad;
		} else
		{
			this.puntosAplicados = this.puntos;
		}
		//this.listarBancos();
	},
	PuntosMetodoPago(){
		if(this.puntosAplicados > this.puntos){
			this.Mensaje = "Este cliente solo cuenta con "+this.puntos+" puntos"
			this.snackbar = true;
			return;
		}
		this.dialogpuntos = false;
		let saldo = 0;
		let mitad = this.Total /2;
		if(this.puntos < this.puntosAplicados){
			this.Mensaje = "El maximo puntos que tiene diponible el cliente es: "+this.puntos+ " no puede aplicar "+this.puntosAplicados;
			this.snackbar = true;
			return;
		}
		if(this.puntosAplicados > mitad ){
			this.puntosAplicados = mitad;
		} 
		saldo = parseInt(this.puntos) - parseInt(this.puntosAplicados);
		// Primero, calcula la suma del valor total de todos los detalles
		var totalDetalles = this.Total;
		// Obten el valor de puntosAplicados (supongo que ya lo tienes definido)
		var puntosAplicados = this.puntosAplicados;  
		// Calcula el porcentaje de descuento que se debe aplicar
		var porcentajeDescuento = (puntosAplicados / totalDetalles) * 100;
		
		// Ahora, aplica el descuento a cada detalle individual
		for (var i = 0; i < this.detalles.length; i++) {
			this.detalles[i].descuento = porcentajeDescuento;
		}
		
		// Esperar 5 segundos antes de ejecutar el método this.vuelto()
		setTimeout(() => {
			this.vuelto();
		}, 1000); // 5000 milisegundos = 5 segundos

		this.detalleFactura = this.puntosAplicados+" Puntos aplicados en esta factura, Saldo: "+saldo
	},
	limpiarPantallaPago()
    {
	  this.pago = '';
	  this.PagaBillete = false;
      this.efectivoPagar = 0;
      this.tarjetaPagar = 0;
      this.depositoPagar = 0;
	  this.PagoDolares = 0;
      this.dialogDeposito = false;
      this.dialogEfectivo = false;
      this.dialogTarjeta = false;
      this.dialogCheque = false;
	  this.vueltoPagar = 0;
      this.dialogSinpe = false;
	  this.clienteNombre = "";
	  this.clienteCedula = "";
	  this.clienteTelefono = "";
	  this.clienteCorreo = "";
	  this.clienteCorreo2 = "";
	  this.metodoPago = '';
	  this.vueltoPagar = this.Total * -1;
	  
    },
	async BorrarOrden(NoFactura){
		if(confirm("Seguro que quiere eliminar esta Orden?")){

		var self = this;
		let header={"Authorization" : "Bearer " + this.$store.state.token};
		let configuracion= {headers : header};
		await axios.post('api/OrdenExpress/EliminarOrden',{
					'NoFactura':NoFactura,
					'Localidad': this.$store.state.usuario.localidad,
					'Fecha': "0/0/0/",
					
			},configuracion).then(function (response) {
					self.Mensaje = "Orden Eliminada";
					self.snackbar = true;
					self.loading = false;
					self.BuscarCuentas()
				})
				.catch(function (error) {
					alert(`Error Eliminando`);  
			})
		}
	},
	selectProductos(){
		let me=this;
		this.loading = true;
		let search = '';
		let myInput = document.getElementById('buscador');
		myInput = myInput.value;
		let header={"Authorization" : "Bearer " };
		let configuracion= {headers : header};
		var productosArray=[];
		axios.get('api/Productos/BuscadorEnter/'+this.$store.state.usuario.localidad+'/'+myInput.value,configuracion).then(function(response){
			productosArray=response.data;
			productosArray.map(function(x){
				me.productos.push({text:x.descripcion, value:x.codigoProducto});
			});
			me.loading = false;
		}).catch(function(error){
			console.log(error);
		});
	},
	searchProducts: function () {
		let me = this;
		let Objeto = [];
		this.esCompuesto = false;
		let myInput = document.getElementById('buscador');
		myInput = myInput.value.trim(); // Usamos trim para eliminar espacios al inicio y al final
		let cant = myInput.length;
		if (cant > 2) {
			var search_term = myInput;

			this.timeouts.map(timeout => {
				window.clearTimeout(timeout);
			});
			this.PosicionProductos = [];
			this.timeouts.push(
				setTimeout(() => {
					this.loading = true;
					// Asegúrate de que `localidad` y `myInput` estén limpios de espacios
					let localidad = this.$store.state.usuario.localidad.trim();
					axios.get('api/Productos/BuscadorEnter/' + encodeURIComponent(localidad) + '/' + encodeURIComponent(myInput))
						.then(response => {
							Objeto = response.data;
							// Cargar Productos 
							Objeto.map(function (x) {
								me.PosicionProductos.push({
									descripcion: x.descripcion,
									codigoProducto: x.codigoProducto,
									fotoLocalizacion: x.fotoLocalizacion
								});
							});
							me.loading = false;
						})
						.catch(error => {});
				}, 500)
			);
		} else {
			this.timeouts.map(timeout => {
				window.clearTimeout(timeout);
			});
			this.productos = [];
			this.PosicionProductos = [];
		}
	},
	async AgregarTemp(Id,NoFactura,nombre,TipoCompra){
		this.loading = true;
			if(confirm("Seguro que quiere agregar productos a esta Factura? "+ NoFactura)){
					var self = this;
					var momentoActual = new Date(); 
					var hora = momentoActual.getHours(); 
					var minuto = momentoActual.getMinutes(); 
					var segundo = momentoActual.getSeconds();
					var time = hora + ":" + minuto + ":" + segundo;
					let header={"Authorization" : "Bearer " + this.$store.state.token};
					let configuracion= {headers : header};
					await axios.post('api/OrdenExpress/Agregar',{
						'NoFactura':NoFactura,
						'Localidad': this.$store.state.usuario.localidad,
						'Cajero': this.$store.state.usuario.nombre,
						'Fecha': "0/0/0/",
						'ID': Id,
						'detalles': this.detalles,
						'Nombre':nombre,
						'TipoCompra':TipoCompra,
						'Nota':this.nota,
						'Mesa':this.MesaSeleccionada,
						'Hora':time
						
				},configuracion).then(function (response) {
						self.Mensaje = "Productos cargados";
						self.snackbar = true;
						self.loading = false; 
						self.detalles = [];
						self.BuscarCuentas()
					})
					.catch(function (error) {
						alert(`Error Agregando`);  
				})
			}
	},
	
	validarPago(){
		this.valida=1;

		if (this.detalles.length == 0){
			this.Mensaje = "Debe ingresar productos que facturar";
			this.snackbar = true;
			this.valida=0;
		}
		return this.valida;
    },
	CheckAll(item){
		for(var i=0;i<item.length;i++){
				item[i].check = true;
		}
	},
	PruebaCheck(){
		var test ="";
		var Prueba = "";
		for(var i=0;i<this.TempMesa.length;i++){
			if(this.TempMesa[i].cliente == this.clienteNombre){
				this.TempMesa[i].check = true;
			}
		}
	},
	FacturarMesa(item,cliente,total,delivery){
		this.clienteNombre = cliente;
		this.vendedor = item[0].cajero;
		this.bloqueoMesaPosFactura = true;
		var grabado = "S";
		this.dialogmesa= false;
		this.clienteNombre = this.clienteNombre+ ' '+ this.MesaSeleccionada;
		var Checksi = false;
		this.copias = 1;
		if(delivery === 2){
			this.copias = 2;
		}
		var cabys = "2118603000000";
		this.detalles = [];
		var ImpServ = 0.0;
		for(var i=0;i<item.length;i++){
			if(item[i].check == true){
				Checksi = true;
			}
				if(item[i].valorImpuesto != 13){
					grabado = "N"
				}
				this.detalles.push(
				{
					id: item[i].idRegistro,
					Codigop:item[i].codigop,
					descripcion:item[i].descripcion,
					cantidad:item[i].cantidad,
					precio:item[i].precioFinal,
					PrecioFinal:item[i].precioFinal,
					Ivi:true,
					descuento:item[i].descuento,
					Temp: 1,
					detalle:"",
					precioCompra:item[i].precioCompra,
					cabys:item[i].codigoRaiz,
					impuesto:item[i].valorImpuesto,
					valorImpuesto:item[i].valorImpuesto,
					gravado:grabado,
					cocina: 0,
					impEspecial:0,
					check: false,
				}
				);
			}
		if(Checksi == false){
			this.snackbar = true;
			this.Mensaje = "Debe seleccionar los productos que va facturar";
			return;
		}
		
		this.dialogcuentaexpress = false;
        this.efectivoPagar = 0;
		this.dialogPago = true;
		this.vueltoPagar = total * -1;
        this.tarjetaPagar = 0;
		//this.obtenerPlan();
		if(this.clienteCedula == ""){
			this.obtenerClienteOrden()
		}

	},
	onClientSelect(value) {
        if (this.clientes.includes(value)) {
            this.obtenerCliente(value);
        }
    },
	FacturarMesaInvitado(item,cliente,total){
		var imp = "S";
		this.clienteNombre = cliente
		var valorImp = 13;
		this.tipoPago = "EFECTIVO";
		this.bloqueoMesaPosFactura = true;
		var Checksi = false;
		var cabys = "2118603000000"; 
		this.detalles = [];
		var ImpServ = 0.0;
		if(item[0].valorImpuesto == 0){
			imp = "N";
			valorImp = 0;
			cabys = "8315900009900"; 
		}
		for(var i=0;i<item.length;i++){
			if(item[i].check == true){
				Checksi = true;
			}
				this.detalles.push(
                  {
					id: item[i].idRegistro,
					Codigop:item[i].codigop,
					descripcion:item[i].descripcion,
					cantidad:item[i].cantidad,
					precio:0,
					PrecioFinal:0,
					Ivi:true,
					descuento:0,
					Temp: 1,
					detalle:"",
					PrecioCompra:item[i].precioCompra,
					cabys:item[i].codigoRaiz,
					impuesto:valorImp,
					impEspecial:0,
					valorImpuesto:valorImp,
					gravado:imp,
					cocina: 0,
					check: false,
                  }
                  );
			
		}
		if(Checksi == false){
			this.snackbar = true;
			this.Mensaje = "Debe seleccionar los productos que va facturar";
			return;
		}
		this.dialogmesa= false;
		this.dialogcuentaexpress = false;
		this.detalleFactura = "Invitación realizada por: "+this.$store.state.usuario.nombre;
        this.efectivoPagar = 0;
		this.dialogPago = true;
		this.vueltoPagar = total * -1;
        this.tarjetaPagar = 0;
		//this.obtenerPlan();
		
		if(this.clienteCedula == ""){
			this.obtenerClienteOrden()
		}

	},
	FacturarNew(){
		if(!this.DetalleRapidoSelected && this.ComandaDigital && this.OrdenFactura == 0){
			this.Mensaje = "Seleccione un método de entrega";
			this.snackbar = true;
			return;
		}
		if(this.vueltoPagar < 0 && this.CondicionVenta != "CREDITO"){
			this.snackbar = true;
			this.Mensaje = "No alcanza para cubrir el total de la factura, añada fondos en algun método de pago para continuar";
			this.modelCarga = false;
			return;
		}
        this.loading = true;
        var self = this;
        var momentoActual = new Date(); 
        var documentoHacienda = "TIQUETE ELECTRONICO";
        var link = '';
        var hora = momentoActual.getHours(); 
        var minuto = momentoActual.getMinutes(); 
        var segundo = momentoActual.getSeconds();
		var time = hora + ":" + minuto + ":" + segundo;
		

		if (this.clienteNombre != "" && this.clienteCedula != "" && this.clienteTelefono != "")
		{
		documentoHacienda = "FACTURA ELECTRONICA";
		this.tipoFactura = 1;
		}
		else if (this.clienteNombre != "")
		{
		documentoHacienda = "TIQUETE ELECTRONICO";
		this.tipoFactura = 4;
		}
		
    	 // Pregunta si este valor esta en true para hacer un recibo (No Enviar Hacienda)
		if(this.ActivarRecibos == true && this.clienteNombre == "" && this.clienteCedula == "" && this.tipoPago == "EFECTIVO"){
			documentoHacienda = "RECIBO";
			this.tipoFactura = 9;
		} 
		if(this.TipoCredito == "RECIBO"){
			documentoHacienda = "RECIBO";
			this.tipoFactura = 9;
		}
		//Si el pago es en tarjeta siempre se envia hacienda
		for(var i = 0; i < this.detalles.length; ++i)
        {
		  var montodesc = 0.0;
		  var cant = 0.0;
		  cant = this.detalles[i].cantidad
		  cant = parseFloat(cant)
          if( this.detalles[i].Ivi == false){
                montodesc=+(((this.detalles[i].descuento)/100)*(this.detalles[i].precio * this.detalles[i].cantidad));
            } else
            if( this.detalles[i].Ivi == true){
                montodesc=  ((this.detalles[i].descuento/100)*((this.detalles[i].precio / 1.13) * this.detalles[i].cantidad));
            }
          this.productosDatos.push(
            {
                CodigoProducto:this.detalles[i].Codigop,
                Cantidad:parseFloat(cant), 
                UnidadMedida:"UND",
                Descripcion:this.detalles[i].descripcion,
                PrecioUnitario:this.detalles[i].precio,
                MontoDescuento:montodesc.toFixed(2),                          
                ValorImpuesto:this.detalles[i].valorImpuesto,
                Gravado:this.detalles[i].gravado,
                CodigoHacienda: this.detalles[i].cabys,
                EstadoImp: this.IviBackend,
                Temp : this.detalles[i].Temp,
                id : this.detalles[i].id,
                cocina : this.detalles[i].cocina,
                Detalle : '  '+this.detalles[i].detalle,
                NumeroMM:this.RefXML,
                TipoDocumento: this.tipoFactura,
                DiasCredito: this.diasCredito,
                TipoCedulaReceptor: this.clienteTipoCedula.toUpperCase()
            }
            );
			
          }
		  if(this.AddImpServ == true && this.$store.state.usuario.localidad != 'Restaurante Don Lalo'){
				this.productosDatos.push(
				{
					CodigoProducto:"IMP10",
					Cantidad:1, 
					UnidadMedida:"UND",
					Descripcion:"Impuesto Servicio",
					PrecioUnitario:this.ImpServicio,
					precio:this.ImpServicio,
					PrecioFinal:this.ImpServicio,
					MontoDescuento:0,                          
					ValorImpuesto:0,
					Gravado:"N",
					CodigoHacienda: "8315900009900",
					EstadoImp: false,
					Temp : "0",
					id : 1,
					cocina : 0,
					detalle : "",
					NumeroMM:'',
					TipoDocumento: this.tipoFactura,
					DiasCredito: this.diasCredito,
					TipoCedulaReceptor: this.clienteTipoCedula.toUpperCase()
				}
				);
			}
			let ticketnum = 0;
			let clave = '';
			var UtilidadFactura = this.Total - this.calcularCosto;
          	let header={"Authorization" : "Bearer "};
                let configuracion= {headers : header};
                axios.post('api/Facturas/CrearFacturaPOS',{
					'Fecha': this.today.toString(),//MM/dd/YYYY
					'Detalle':this.detalleFactura,
					'Gravado': parseFloat(this.totalGravado),
					'Exento': parseFloat(this.totalExento),
					'Iv': parseFloat(this.totalImpuesto),
					'Total': parseInt(this.Total),
					'Localidad': this.$store.state.usuario.localidad,
					'Cajero': this.$store.state.usuario.nombre,
					'TipoPago': this.tipoPago,
					'Descuento': this.totalDescuento,
					'ImpServicio': this.ImpServicio,
					'MontoExpress': 0,
					'SubTotal': this.totalParcial,
					'MontoExpres': 0,
					'TipoDocumento': documentoHacienda,
					'Nocomprobante': 0,
					'NoNotaCredito': "",
					'CondicionVenta': this.CondicionVenta,
					'Correo2': this.clienteCorreo2,
					'ClaveComprobante': 0,
					'Moneda': this.monedaPago.toUpperCase(),//USD O CRC
					'RefFacturaReal': "",
					'RefComprobante': "",
					'razonReferencia': "",
					'MensajeRespuesta': "",
					'NombreReceptor': this.clienteNombre,
					'CedulaReceptor': this.clienteCedula,
					'CorreoReceptor': this.clienteCorreo,
					'TelefonoReceptor': this.clienteTelefono,
					'Razon': "",
					'RefFecha': "",
					'Vuelto': this.resultadoCompra,
					'Impuesto1': this.imp1,
					'Impuesto4 ': this.imp4,
					'Impuesto13': this.imp13,//total de Iva al 13%
					'Impuesto2': this.imp2,
					'Sincronizado ': 0,
					'Vendedor': this.vendedor,
					'TipoCambio': 506.0,
					'TotalSinR': this.Total,
					'Hora': hora + ":" + minuto + ":" + segundo,
					'DiasCredito': this.diasCredito,
					'Saldo': this.saldo,
					'Producto': this.productosDatos,
					'Regimen': this.Regimen,
					'Proforma': 0,
					'OrdenFactura': this.OrdenFactura,
					'Motorizado':this.motorizado,
					'TotalTarjeta':this.tarjetaPagar,
					'TotalEfectivo': this.efectivoPagar,
					'TotalSimpe':this.depositoPagar,
					'Puntos':this.puntosAplicados,
					'UtilidadFactura':UtilidadFactura,
					'ComandaDigital':this.ComandaDigital,
					'DetalleRapidoSelected':this.DetalleRapidoSelected,
					'TotalNC':0,
					'Credito':0,
					'TotalDolares':this.PagoDolares,
					'TipoFactura':this.tipoFactura,
                },configuracion).then(function (response) {
					self.snackbar= true;
					self.Mensaje = "Factura Realizada con éxito";
					ticketnum = response.data.item1;
					clave = response.data.item2;
					self.OrdenFactura = 0;
					if(self.modoPDV == 0){
						location.reload();
						return;
					} else
					if(self.modoPDV == 1){
						var link = '';
						link = location.origin+'/'+'ticket/'+ticketnum;
						location.assign(link); 
						return;
					} else
					if(self.modoPDV == 2){
						self.dialogPago = false;
						self.detalles = [];
						link = location.origin+'/'+'repventas/';
						location.assign(link); 
						return;
					} else
					if(self.modoPDV == 6){
						//self.obtenerTicketPOS(ticketnum,clave);
						if (clave == null)
						{
							clave = 0;
						} 
						self.imprimirTicket(ticketnum,clave,documentoHacienda,);
						
						
						return;
					} else
					if(self.modoPDV == 3){
						
						//self.obtenerTicketPOS(ticketnum,clave);
						
						self.ImprimrFactura(ticketnum,clave);
						if(self.MesaSeleccionada == 0){
							self.ImprimirTicketCocina(time)
						}
						
						return;
					} 	else
					if(self.modoPDV == 5){
						if (clave == null)
						{
							self.ClaveComprobante = 0;
						} else
						{
							self.ClaveComprobante  =clave;
						}
						self.Impresiondirecta(ticketnum);
						return;
					}
					//location.reload();
                  })
                  .catch(function (error) {
					if (error.response && error.response.data && error.response.data.message) {
						alert("Ocurrió un error: " + error.response.data.message);
						location.reload();
					} else {
						alert("Ocurrió un error, por favor verifique si la factura se realizó, si el problema persiste contacte a su equipo de soporte.");
						location.reload();
					}
					self.modelCarga = false;
					self.loading = false;
					//location.reload();
				})
    },
	AbrirExpress(){
		this.dialog = true;
		//this.selectCliente();
	},
	async abrirCajonDinero() {
		try {
			const response = await axios.post('http://localhost:5000/abrir-cajon');
			console.log('Cajón de dinero abierto', response.data);
		} catch (error) {
			console.error('Error al abrir el cajón de dinero', error);
		}
	},
	abrirEfectivo(){
		this.dialogEfectivo = true;
			if(this.monedaPago === "USD"){
				this.efectivoPagar = (this.vueltoPagar * -1)  * this.TipoCambioInterno;
			} else
			{
				this.efectivoPagar = this.vueltoPagar * -1;
			}
    },
    abrirTarjeta(){
		this.dialogTarjeta = true;
		this.tarjetaPagar = this.vueltoPagar * -1;
    },
	abrirTarjetaGitana(){
		this.tarjetaPagar = parseFloat(this.Total)
		this.vueltoPagar = 0
		//this.pagar();
    },
    abrirDeposito(){
		this.dialogDeposito = true;
		this.depositoPagar = this.vueltoPagar * -1;
    },
    abrirSinpe(){
		this.dialogSinpe = true;
		this.depositoPagar = this.vueltoPagar * -1;
	  
    },
    abrirCheque(){
      	this.dialogCheque = true;
	  	if (this.monedaPago === "CRC") {
			this.PagoDolares = this.TotalDolares;
		}else
		{
			this.PagoDolares = this.calcularTotal;
		}
    },
    localidadSeleccionada(){
        let me =this;
        var ticketNum = 0;
        let header={"Authorization" : "Bearer " + this.$store.state.token};
        let configuracion= {headers : header};
            axios.get('api/Localidades/ListarFiltrada/'+me.localidad,configuracion).then(function(response){
                //console.log(response);
                me.actuallocalidad=response.data;
                me.latitudrest= me.actuallocalidad[0].latitud;
                me.longitudrest = me.actuallocalidad[0].longitud;
                me.Express = me.actuallocalidad[0].express;
               // me.MostrarComprobante(me.actuallocalidad);
                
               // alert('Longitud agregada al repositorio'+ me.latitudrest+ me.longitudrest+'Acepta express:'+me.Express)
                 me.Enviototal()
            }).catch(function(error){
                console.log(error);
            });
           
    },
     MostrarComprobante(item){
            this.latitudrest= item[0].latitud;
            this.longitudrest = item[0].longitud;
            alert('Longitud agregada al repositorio'+ this.latitudrest+ this.longitudrest)
        },
    selectLocalidad(){  
		let me=this;
		var localidadesArray=[];
		let header={"Authorization" : "Bearer "};
		let configuracion= {headers : header};
		axios.get('api/Localidades/SelectLocalidades',configuracion).then(function(response){
			localidadesArray=response.data;
			localidadesArray.map(function(x){
				me.localidades.push({text: x.nombre,value:x.nombre});
			});
		})
    },
	async selectPanelControl(){  
		let me=this;
		var localidadesArray=[];
		let header={"Authorization" : "Bearer "};
		let configuracion= {headers : header};
		await axios.get('api/PanelControls/ListarLocalidad/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
			localidadesArray=response.data;
			me.ModoCant= localidadesArray[0].modoCantidades;
			me.Multiplo = localidadesArray[0].multiplo;
			me.AddImpServ= localidadesArray[0].impServ;
			me.AgregarCompuesto= localidadesArray[0].agregarCompuesto;
			me.monedaPago = localidadesArray[0].moneda;
			me.ActivarRecibos =  localidadesArray[0].recibos;
			me.multiLocalidad = localidadesArray[0].multiLocalidad;
			me.Foto =  localidadesArray[0].foto;
			me.Comanda =  localidadesArray[0].comanda;
			me.ComandaDigital = localidadesArray[0].comandaDigital;
			//me.CierreGlobal = localidadesArray[0].cierreGlobal;
		})
		me.selectBotones();
		me.selectPosicion(1);
		if(me.multiLocalidad == true){
			me.selectLocalidad();
		}
    },
	selectTipoCambioInterno(){  
		let me=this;
		var localidadesArray=[];
		let header={"Authorization" : "Bearer "};
		let configuracion= {headers : header};
		axios.get('api/ParametrosEmisors/ListarLocalidad/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
			localidadesArray=response.data;
			me.TipoCambioInterno= localidadesArray[0].tipoCambio;
			if (typeof me.TipoCambioInterno === 'number') {
				// la variable es numerica
			} else{
				me.TipoCambioInterno = 590;
			}
		})
    },
    async selectBotones(){  
		let me=this;
		var localidadesArray=[];
		let header={"Authorization" : "Bearer "};
		let configuracion= {headers : header};
		if(this.multiLocalidad == true){
			await axios.get('api/Botones/Listar',configuracion).then(function(response){
				me.modelCarga = false;
				localidadesArray=response.data;
				me.Botones = localidadesArray;
			})
		} else
		{
			await axios.get('api/Botones/ListarLocalidad/'+me.$store.state.usuario.localidad,configuracion).then(function(response){
				me.modelCarga = false;
				localidadesArray=response.data;
				localidadesArray.map(function(x){
					me.Botones.push({text: x.nombre,value:x.idBoton});
					
				});
			})
		}
		
    },
    selectMesa(id){  
		if(id == 0){
			return;
		}
		this.CuentaTemporal = [];
		this.TempMesa = [];
          let me=this;
		  var tempMesa = [];
		  me.ClientesEnMesa = [];	
          let header={"Authorization" : "Bearer "};
          let configuracion= {headers : header};
          axios.get('api/OrdenExpress/ListarMesa/'+id+"/"+me.$store.state.usuario.localidad,configuracion).then(function(response){
              tempMesa=response.data;
              me.modelCarga = false;
					//Recopila los nombres de los clientes en la mesa
				for(var i = 0; i < tempMesa.length; ++i){
					me.ClientesEnMesa.push(tempMesa[i].cliente);
				}
				// Elimina los valores duplicados
				let result = me.ClientesEnMesa.filter((item,index)=>{
				return me.ClientesEnMesa.indexOf(item) === index;
				})
				
				me.DivideCuentas(tempMesa,result);		
              }); 
          
    },
	DivideCuentas(tempMesa,clientes){
		var mesas=[];
		this.TempMesa = [];
		for(var i = 0; i < clientes.length; ++i){
			for(var j = 0; j < tempMesa.length; ++j){
				if(tempMesa[j].cliente ==clientes[i]){
					mesas.push(tempMesa[j]);
				}
			}
			this.TempMesa.push(
				{
				id:this.TempMesa.length + 2,
				Cliente: clientes[i],
				arr: mesas,
				} 
			);  mesas = [];
		} 
	},
	VerMesaProducts(){
		this.dialogmesa = true;
		this.selectMesa(this.MesaSeleccionada);
	},
	selectCuenta(nombre,NoFactura,direccion,telefono,cajero){  
		let me=this;
    	this.AddImpServ = false;
		this.TelefonoReceptor = telefono;
		this.clienteTelefono = telefono;
		this.vendedor = cajero;
		this.detalleFactura = direccion;
		me.CopiasImpresion = 2;
		me.dialogCuenta = false;
		me.dialogcuentaexpress = true;
		me.clienteNombre = nombre;
		this.OrdenFactura = NoFactura;
		var localidadesArray=[];
		let header={"Authorization" : "Bearer "};
		let configuracion= {headers : header};
		if(this.multiLocalidad == true){
				axios.get('api/OrdenExpress/ListarCuentaGeneralGlobal/'+NoFactura,configuracion).then(function(response){
			me.Mesa=response.data;
			me.modelCarga = false;
			me.dialogcuentaexpress = true;
			me.obtenerCliente()
			});
		}else
		{
			axios.get('api/OrdenExpress/ListarCuentaGeneral/'+NoFactura,configuracion).then(function(response){
			me.Mesa=response.data;
			me.modelCarga = false;
			me.dialogcuentaexpress = true;
			me.obtenerCliente()
			});
		}
		
          
    },
    BuscarMesa() {
		let me = this;
		let mesasOcupadas = new Set();
		let cuentasNoNumericas = []; // Arreglo para almacenar cuentas no numéricas
		let header = { "Authorization": "Bearer " + this.$store.state.token };
		let configuracion = { headers: header };

		axios.get('api/OrdenExpress/BuscarMesaLocalidad2/' + me.$store.state.usuario.localidad, configuracion)
		.then(function(response) {
			// Limpiar y extraer solo IDs de mesa
			response.data.forEach(mesa => {
				let mesaTrimmed = mesa.mesa.trim().toUpperCase(); // Asegura comparación uniforme
				// Verificar si es un valor numérico o si comienza con 'T' o 'S' seguido de un número
				if (!isNaN(mesaTrimmed) || /^[TS]\d+$/.test(mesaTrimmed)) {
					mesasOcupadas.add(mesaTrimmed); // Añadir a mesas ocupadas
				} else {
					cuentasNoNumericas.push({ value: mesaTrimmed, color: '' }); // Agregar a cuentas no numéricas
				}
			});

			// Actualizar colores solo si están en la lista de mesas ocupadas
			me.mesas.forEach(mesa => {
				if (mesasOcupadas.has(mesa.id.trim().toUpperCase())) { // Comparación debe ser consistente
					mesa.color = 'red'; // Rojo para mesas ocupadas
				} else {
					mesa.color = ''; // Limpiar color para mesas disponibles
				}
			});

			// Actualizar el estado con cuentas no numéricas
			me.cuentasNoNumericas = cuentasNoNumericas;
			let valores = me.cuentasNoNumericas.map(objeto => objeto.value);
			let valores2 = me.mesas.map(objeto => objeto.id);
			me.mesastraslados = valores2.concat(valores);
			me.Mensaje = "Mesas en Rojo Estan Ocupadas";
			me.snackbar = true;
		});
	},
	selectPosicion(id){  
		let me=this;
		me.esCompuesto = false;
		var localidadesArray=[];
		let header={"Authorization" : "Bearer "};
		if(this.multiLocalidad === true){
			let configuracion= {headers : header};
			axios.get('api/PosicionProductoCajas/ListarCategoria/'+id,configuracion).then(function(response){
			me.PosicionProductos=response.data;
			});
		} else
		{
			let configuracion= {headers : header};
			axios.get('api/PosicionProductoCajas/ListarCategoriaLocalidad/'+id+'/'+me.$store.state.usuario.localidad,configuracion).then(function(response){
			me.PosicionProductos=response.data;
			});
		}
		
    },
    selectCompuesto(id){  
		let me = this;
		let header = {"Authorization" : "Bearer "};
		let configuracion = {headers : header};
		if(this.multiLocalidad === true){
			axios.get('api/PosicionProductoCajas/ListarAsociado3/' + id , configuracion)
			.then(function(response) {
			me.PosicionProductos = response.data;
			});
		} else
		{
			axios.get('api/PosicionProductoCajas/ListarAsociado4/' + id + '/' + this.$store.state.usuario.localidad, configuracion)
			.then(function(response) {
			me.PosicionProductos = response.data;
			});
		}
      
    },
	validarIdioma(){
		if(localStorage.getItem("idioma") == null) {
			this.Pais = "ESP";
			return;
		}

		this.Pais = localStorage.getItem("idioma");
	},
	idioma(){
		localStorage.setItem("idioma",this.Pais)
		location.reload();
	},
	async select(){
		let me=this;
      var clientesArray=[];
      let header={"Authorization" : "Bearer "};
      let configuracion= {headers : header};
        axios.get('api/Empleados/SelectEmpleadosLocalidad/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
          clientesArray=response.data;
          clientesArray.map(function(x){
              me.clientes.push({text: (x.nombre+' | '+x.nombreFantasia), text2:x.nombre,value:x.nombre});
          });
      }) 
    },
	validarCliente() {
        this.validaCliente=1;

        if(!this.nombre){
            alert("Debe ingresar el nombre del cliente.");
            this.validaCliente=0;
        }
        if(!this.clienteTipoCedula){
            alert("Debe seleccionar un tipo de documento.");
            this.validaCliente=0;
        }
        if(!this.cedula){
            alert("Debe ingresar un número de documento.");
            this.validaCliente=0;
        }
        if(!this.telefono){
            alert("Debe ingresar un teléfono o un celular .");
            this.validaCliente=0;
        }
        if(!this.correo){
            alert("Debe ingresar un email.");
            this.validaCliente=0;
        }
        return this.validaCliente;
    },
	crearCliente() 
    {
		if (this.validarCliente() === 1){
			var self = this;
			this.modelCarga = true;
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			axios.post('api/Empleados/Crear',{
				'Nombre':this.clienteNombre,
				'TipoCedula':this.clienteTipoCedula,
				'Cedula': this.clienteCedula.replace(/-/g,"").replace(/ /g, ""),
				'Localidad':this.$store.state.usuario.localidad,
				'Telefono':this.clienteTelefono.replace(/-/g,"").replace(/ /g, ""),
				'Periodo': this.DiasCreditoCliente,
				'Correo':this.clienteCorreo,
				'Correo2':this.clienteCorreo2,
				'Periodo':this.DiasCreditoCliente,
				'Cantidad':this.limiteCredito,
				'Genero':this.genero,
				'Edad':"0",
				'Facebook':this.direccion,
				'Vendedor': this.vendedor,
				'ListaPrecio':this.listaDePrecio,
				'Genero':'Masculino',
				'NombreFantasia':this.nombreFantasia,
				'Celular':this.clienteTelefono,
			},configuracion).then(function (response) {
				self.Mensaje = "Cliente ha sido insertado correctamente, por favor seleccione el cliente";
				self.snackbar = true;
				self.modelCarga = false;
				self.dialogCliente = false;
			}).catch(function(error){
				alert('El email o cédula ya existe');
			}); 
        }
    },
	validateInputCedula() {
		const inputValue = event.target.value;
    	this.cedula = inputValue.replace(/[^0-9]/g, '');
	},
	validateInputTelefono() {
		this.telefono = this.telefono.replace(/\D/g, '');
	},
	async selectCliente(){
		let me=this;
		let localidadValue = this.$store.state.usuario.localidad;
		var clientesArray=[];
		let header={"Authorization" : "Bearer "};
		if(this.multiLocalidad == true){
			let configuracion= {headers : header};
				axios.get('api/Clientes/ListarClientesGlobal', {
					headers: configuracion.headers
				}).then(function(response){
				clientesArray=response.data;
				clientesArray.map(function(x){
					me.clientesExpress.push({text: (x.nombre+' | '+x.telefonos), value:x.nombre , telefono:x.telefonos});
				});
			})
		
		} else
		{
			let configuracion= {headers : header};
				axios.get('api/Clientes/ListarClientes', {
					params: {
						localidad: localidadValue
					},
					headers: configuracion.headers
				}).then(function(response){
				clientesArray=response.data;
				clientesArray.map(function(x){
					me.clientesExpress.push({text: (x.nombre+' | '+x.telefonos), value:x.nombre , telefono:x.telefonos});
				});
			})
		}
    },
		BuscarPersona(){
			let me=this;
			var familiasArray=[];
			axios.get('https://apis.gometa.org/cedulas/'+this.clienteCedula+'&key=u0vSHKh0dVRckNH').then(function(response){
				familiasArray=response.data;
				me.clienteNombre = familiasArray.results[0].fullname;
				me.clienteNombreExpress = familiasArray.results[0].fullname;
				me.clienteTipoCedula = familiasArray.results[0].guess_type
				
			}).catch(function(error){
				this.snackbar = true;
				this.Mensaje = 'Error de conexión con el TSE, digite los datos de forma manual'
				console.log(error);
			});
			this.obtenerCliente2();
		},
		async obtenerCliente2(){
			var self = this;
			var clientesArray=[];
			this.clienteNombre = this.clienteNombre.value !== undefined ? this.clienteNombre.value : this.clienteNombre;

			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			await axios.get('api/Empleados/ObtenerCliente', { params: {'clienteFactura': this.clienteNombre}},configuracion).then(function(response){
				clientesArray=response.data;
				self.clienteCorreo = clientesArray[0].correo;
				for(var i = 0; i < clientesArray.length; ++i)
				{
				self.clienteTelefono = clientesArray[i].telefono;
				self.diasCredito = clientesArray[i].periodo;
				
				break;
				
				} 
			})
			self.ConsultarPuntos();
		},
	  BuscarTelefono(){
          let me=this;
          var familiasArray=[];
          axios.get('https://apis.gometa.org/cedulas/'+this.clienteCedula+'&key=u0vSHKh0dVRckNH').then(function(response){
              familiasArray=response.data;
              me.clienteNombre = familiasArray.results[0].fullname;
			  me.clienteNombreExpress = familiasArray.results[0].fullname;
              me.clienteTipoCedula = familiasArray.results[0].guess_type
              
          }).catch(function(error){
            this.snackbar = true;
            this.Mensaje = 'Error de conexión con el TSE, digite los datos de forma manual'
              console.log(error);
          });
      },
	async obtenerCliente(){
        var self = this;
        var clientesArray=[];
        this.clienteNombre = this.clienteNombre.value !== undefined ? this.clienteNombre.value : this.clienteNombre;

        let header={"Authorization" : "Bearer "};
        let configuracion= {headers : header};
        await axios.get('api/Empleados/ObtenerCliente', { params: {'clienteFactura': this.clienteNombre}},configuracion).then(function(response){
            clientesArray=response.data;
            self.clienteCorreo = clientesArray[0].correo;
            for(var i = 0; i < clientesArray.length; ++i)
            {
              
              
              self.clienteCedula = clientesArray[i].noEmpleado;
              self.clienteTipoCedula = clientesArray[i].tipoCedula;
              self.clienteTelefono = clientesArray[i].telefono;
              self.listaPrecio = clientesArray[i].listaPrecio;
              //self.vendedor = clientesArray[i].vendedor;
              self.diasCredito = clientesArray[i].periodo;
			  
              break;
             
            } 
        })
		self.ConsultarPuntos();
    },
	async ConsultarPuntos(){
		let self = this;
		let header = {"Authorization" : "Bearer "}; // Asegúrate de que el token de autorización esté incluido aquí
		let configuracion = {headers: header};

		// Añade la localidad como parte de la URL
		let url = `api/RepVentasVistas/CalcularPuntos/${this.clienteCedula}/${this.$store.state.usuario.localidad}`;

		await axios.get(url, configuracion).then(function(response){
			self.puntos = response.data;
		}).catch(function(error){
			console.error("Error en la consulta de puntos:", error);
			// Manejo de errores (opcional)
		});
	},

	ValidarDescuento(item){
		if(item.descuento > item.MaxDescuento){
			this.Mensaje = "Lo sentimos, el máximo descuento para este producto es de "+ item.MaxDescuento +", seleccione un descuento menor a este.";
			this.snackbar = true;
			item.descuento = 0;
		}
	},
	obtenerCedula(){
		this.BuscarPersona()
        var self = this;
        var clientesArray=[];
        let header={"Authorization" : "Bearer "};
        let configuracion= {headers : header};
        axios.get('api/Empleados/ObtenerClienteCedula', { params: {'clienteFactura': self.clienteCedula}},configuracion).then(function(response){
            clientesArray=response.data;
            self.clienteNombre = clientesArray[0].nombre;
            self.clienteCorreo = clientesArray[0].correo;
            for(var i = 0; i < clientesArray.length; ++i)
            {
              self.clienteCedula = clientesArray[i].cedula;
              self.clienteTipoCedula = clientesArray[i].tipoCedula;
              self.clienteTelefono = clientesArray[i].telefono;
              self.listaPrecio = clientesArray[i].listaPrecio;
              //self.vendedor = clientesArray[i].vendedor;
              self.diasCredito = clientesArray[i].periodo;
              break;
            } 
        })
    },
	facturarCredito() {
		if(this.detalles.length == 0){
			this.Mensaje ="Selecione Productos para facturar a crédito";
			this.snackbar = true;
			return
		} else
		{
			this.detalleFactura = "";
			this.totalPagar = this.total;
			this.dialogPagoCredito = true;
			//this.obtenerPlan();
			this.efectivoPagar = 0;
			this.tipoPago = "CREDITO";
			this.saldo = this.Total;
			this.tarjetaPagar = 0;
		}
    },
	obtenerTelefono(){
        var self = this;
        var clientesArray=[];
		this.clienteTelefono.toString();
        this.clienteTelefono = this.clienteTelefono.trim();
        let header={"Authorization" : "Bearer "};
        let configuracion= {headers : header};
        axios.get('api/Clientes/ObtenerTelefono', { params: {'clienteFactura': self.clienteTelefono.trim()}},configuracion).then(function(response){
            clientesArray=response.data;
            self.clienteNombre = clientesArray[0].nombre;
			self.clienteNombreExpress = clientesArray[0].nombre;
            self.clienteCorreo = clientesArray[0].correo;
            for(var i = 0; i < clientesArray.length; ++i)
            {
				self.clienteCedula = clientesArray[i].cedula;
				self.clienteTipoCedula = clientesArray[i].tipoCedula;
				self.clienteTelefono = clientesArray[i].telefonos;
				self.DireccionCliente = clientesArray[i].direccion;
				self.DireccionCliente2 = clientesArray[i].dirrecion2;
				self.DireccionCliente3 = clientesArray[i].dirreccion3;
				self.direccion = self.DireccionCliente;
				break;
            } 
        })
    },
	obtenerClienteExpress(){
        var self = this;
        var clientesArray=[];
        var clientePrevio="";
        if (this.clienteNombreExpress.value === undefined)
        {
            this.clienteNombre = this.clienteNombreExpress
        }
        else
        {
            this.clienteNombre = this.clienteNombreExpress.value;
        }
        let header={"Authorization" : "Bearer "};
        let configuracion= {headers : header};
        axios.get('api/Clientes/ObtenerCliente', { params: {'clienteFactura': this.clienteNombre}},configuracion).then(function(response){
            clientesArray=response.data;
            self.clienteNombre = clientesArray[0].nombre;
            self.clienteCorreo = clientesArray[0].correo;
            for(var i = 0; i < clientesArray.length; ++i)
            {
              self.clienteCedula = clientesArray[i].cedula;
              self.clienteTipoCedula = clientesArray[i].tipoCedula;
              self.clienteTelefono = clientesArray[i].telefonos;
              self.DireccionCliente = clientesArray[i].direccion;
			  self.DireccionCliente2 = clientesArray[i].dirrecion2;
			  self.DireccionCliente3 = clientesArray[i].dirreccion3;
			  self.direccion = self.DireccionCliente;
              break;
            } 
			
        })
    },
	obtenerClienteOrden(){
        var self = this;
        var clientesArray=[];
        let header={"Authorization" : "Bearer "};
        let configuracion= {headers : header};
        axios.get('api/Clientes/ObtenerCliente', { params: {'clienteFactura': this.clienteNombre}},configuracion).then(function(response){
            clientesArray=response.data;
			self.vueltoPagar = self.Total * -1;
            self.clienteCorreo = clientesArray[0].correo;
            for(var i = 0; i < clientesArray.length; ++i)
            {
              self.clienteCedula = clientesArray[i].cedula;
              self.clienteTipoCedula = clientesArray[i].tipoCedula;
              self.clienteTelefono = clientesArray[i].telefonos;
              break;
            } 
			
        })
    },
	CalcularPrecio(){
		let me=this;
		if(this.listaPrecio == 'Precio Detalle'){
			for(var i=0;i<this.detalles.length;i++ ){
				me.detalles[i].precio = me.detalles[i].precioDetalle;
			}
		}
		if(this.listaPrecio == 'Precio Central'){
			for(var p=0;p<this.detalles.length;p++ ){
				me.detalles[p].precio = me.detalles[p].precioCentral;
			}
		}
		if(this.listaPrecio == 'Precio Mayor'){
			for(var j=0;j<this.detalles.length;j++ ){
				me.detalles[j].precio = me.detalles[j].precioMayor;
			}
		}
      
    },
    buscarCodigoBoton(codigoboton,valor0){
		let me=this;
		me.EsCero = valor0;
		this.modelCarga = true;
		me.codigo = codigoboton;
		me.errorArticulo=null;
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			if(this.multiLocalidad === true){
				axios.get('api/Productos/BuscarCodigoVentaSingle/'+me.codigo,configuracion)
				.then(function(response){
					me.agregarDetalle(response.data);
					me.modelCarga = false;
				}).catch(function(error){
					if (error.response && error.response.status === 404) {
						alert(error.response.data.message);
						
						me.modelCarga = false;
						me.loading =false;
					} else {
						me.Mensaje = "Producto no encontrado"
						me.snackbar = true;
						me.modelCarga = false;
						me.loading =false;
					}
					
				});
			} else
			{
				axios.get('api/Productos/BuscarCodigoVenta/'+me.codigo+'/'+me.$store.state.usuario.localidad,configuracion)
				.then(function(response){
					if(valor0 == true) {
      				// Buscar el registro cuyo codigoProducto coincide con codigoboton
					
					let registro = me.detalles.find(detalle => detalle.id === me.codigoDetalle);

					// Construir el nuevo valor de detalle usando la descripción obtenida
					let nuevoValorDetalle = ' -' + response.data['descripcion'];

					// Si se encontró el registro, actualizar su campo detalle
						if(registro) {
							//alert("Registro Econtrado" +nuevoValorDetalle)
							registro.detalle = registro.detalle + nuevoValorDetalle;
						} 
						else
						{
							//alert("Registro No encontrado" +nuevoValorDetalle + " Codigo:" +codigoboton)
						}
					} else
					{
						me.agregarDetalle(response.data);
						//me.codigoDetalle = codigoboton;
						
					}		
					
					me.modelCarga = false;
				}).catch(function(error){
					if (error.response && error.response.status === 404) {
						alert(error.response.data.message);
						
						me.modelCarga = false;
						me.loading =false;
					} else {
						me.Mensaje = "Producto no encontrado"
						me.snackbar = true;
						me.modelCarga = false;
					}
				});
			}
			
        },
        buscarCodigoBoton1(){
                let me=this;
                this.messages++
                me.errorArticulo=null;
                this.codigo = this.codigo.value !== undefined ? this.codigo.value : this.codigo;

                if (this.codigo != "")
                {
                    let header={"Authorization" : "Bearer "};
                    let configuracion= {headers : header};
                    axios.get('api/Productos/BuscarCodigoVenta/'+me.codigo.value+'/'+me.$store.state.usuario.localidad,configuracion)
                    .then(function(response){
                        me.agregarDetalle(response.data);
						
                    }).catch(function(error){
                        me.Mensaje = "Producto no encontrado"
                        me.snackbar = true;
						me.modelCarga = false;
						me.loading =false;
                    });
                }
                this.snackbar = true,
                this.Mensaje = "Producto Agregado"
                this.codigo = "";
        },
        Limpiar(){
			this.detalles = [];
			this.nota = '';
			this.clienteNombreExpress= "";
			this.clienteCedula = "";
			this.clienteNombre = "";
			this.clienteTelefono= "";
			this.copias = 1;
			this.PagaBillete = false;
			this.recibidoEfectivo = 0;
			this.beeper= 0;
			this.clienteNombre = "";
			this.Colones= 0;
			this.clienteCorreo = "";
			this.nombre = "";
			this.PagoDolares = 0;
			this.clienteTelefono ="";
			this.direccion = "";
			this.DireccionCliente ="";
			this.DireccionCliente2 ="";
			this.DireccionCliente3 ="";
			this.CopiasImpresion = 1;
			this.recibidoEfectivo = 0;
			this.depositoPagar = 0,
			this.tipoPago = '';
			this.detalleFactura = '';
			this.CondicionVenta = '';
        },
        buscarCodigoBotoncombo(codigoboton){
                let me=this;
                this.messages++
                me.cantidad = 1
                me.codigo = codigoboton;
                me.errorArticulo=null;
                me.selectproducto=true;
                if (this.codigo != "")
                {
                    let header={"Authorization" : "Bearer "};
                    let configuracion= {headers : header};
                    axios.get('api/Productos/BuscarCodigoVenta2/'+this.codigo,configuracion)
                    .then(function(response){
                        me.agregarDetallecombo(response.data);
                    }).catch(function(error){
                        alert("No existe el artículo");
                    });
                }
        },
	VerProductosMesa(){
		this.dialogmesa = true;
		this.selectMesa(this.MesaSeleccionada);
	},
		buscarCodigo(){
			let me=this;
			me.errorArticulo=null;
			if (this.codigo != "")
			{
				let header={"Authorization" : "Bearer "};
				let configuracion= {headers : header};
				axios.get('api/Productos/BuscarCodigoVenta/'+this.codigo+'/'+this.$store.state.usuario.localidad,configuracion)
				.then(function(response){
					me.agregarDetalle(response.data);
				}).catch(function(error){
					alert("No existe el artículo");
				});
			}
			
			this.codigo = "";
		},
		BuscarCodeBar(){
			let me=this;
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			axios.get('api/Productos/BuscarCodigoBarras/'+this.codigo+'/'+this.$store.state.usuario.localidad,configuracion)
			.then(function(response){
				me.codigo = '';
				me.agregarDetalle(response.data);
			}).catch(function(error){
				// me.snackbar = true;
				//me.Mensaje = "Producto No encontrado";
				me.modelCarga = false;
				me.loading =false;
			});
		},
		ExonerarImpServ(){
			if(this.AddImpServ == false){
				this.AddImpServ = true;
			} else
			{
				this.AddImpServ = false;
			}
		},
		validarIdioma(){
		if(localStorage.getItem("idioma") == null) {
			this.Pais = "ESP";
			return;
		}

		this.Pais = localStorage.getItem("idioma");
		},
		validarImpresora(){
			let me=this;
			var localidadesArray=[];
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			axios.get('api/Impresoras/ListarLocalidad/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
				localidadesArray=response.data;

				for(var i = 0; i < localidadesArray.length; ++i){
					if(localidadesArray[i].nombreImpresora == "Caja"){
						me.ip1 = localidadesArray[i].ip
						me.devid1 = localidadesArray[i].devid
					} else
					if(localidadesArray[i].nombreImpresora == "Cocina"){
						me.ip2 = localidadesArray[i].ip
						me.devid2 = localidadesArray[i].devid
					}
					if(localidadesArray[i].nombreImpresora == "Bar"){
						me.ip3 = localidadesArray[i].ip
						me.devid3 = localidadesArray[i].devid
					}
				}
			})
		},
		AbrirCajon(){
			var momentoActual = new Date(); 
			var hora = momentoActual.getHours(); 
			var minuto = momentoActual.getMinutes(); 
			var segundo = momentoActual.getSeconds();
			var time = hora + ":" + minuto + ":" + segundo;
				if(this.ip1 == "" && this.devid1 == ""){
					alert("No hay impresora designada");
					return;	
				}
				var request = `<epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">
							
							<pulse drawer="drawer_1" time="pulse_100"/>
							</epos-print>`;
					
					//Create a SOAP envelop
					var soap = '<s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">' +
					'<s:Body>' + request + '</s:Body></s:Envelope>';
					//Create an XMLHttpRequest object
					var xhr = new XMLHttpRequest();
					//Set the end point address
					var url = 'http://'+this.ip1+'/cgi-bin/epos/service.cgi?devid='+this.devid1+'&timeout=40000';
					//Open an XMLHttpRequest object
					xhr.open('POST', url, true);
					//<Header settings>
					xhr.setRequestHeader('Content-Type', 'text/xml; charset=utf-8');
					xhr.setRequestHeader('If-Modified-Since', 'Thu, 01 Jan 1970 00:00:00 GMT');
					// Send print document
					xhr.send(soap);
		},
		validarMesa(){
		if(localStorage.getItem("Mesa") == null) {
			this.MesaSeleccionada = 0;
			return;
		}

		this.MesaSeleccionada = localStorage.getItem("Mesa");
		this.selectMesa(this.MesaSeleccionada);
		},
		idioma(){
			localStorage.setItem("idioma",this.Pais)
			location.reload();
		},
    PrecioVariable(){
        let imp = "S";
        if(this.categoria == 0){
          imp = "N"
        }

            this.detalles.push(
                  {
					id:this.detalles.length + 2,
					Codigop:"PV001",
					descripcion:this.descripcion,
					cantidad:1,
					precio:this.precioVenta,
					PrecioFinal:this.precioVenta,
					PrecioCompra : 0,
					Ivi:true,
					descuento:0,
					Temp: 0,
					detalle:"",
					cabys:this.cabys,
					impuesto:this.categoria,
					valorImpuesto:this.categoria,
					gravado:imp,
					cocina: this.cocina,
					impEspecial:0,
					check: 0,
                  }
            );
          this.descripcion = "";
          this.cabys = "";
          this.dialogPrecioVariable = false;
    },
    agregarDetalle(data = []){
		this.codigoDetalle = this.detalles.length + 2;
        if(this.EsCero == false) {    
          	if (data['codigoProducto'] != '' && data['codigoProducto'] != undefined)
          	{
				if(data['compuesto'] == true)
				{
                    this.selectCompuesto(data['codigoProducto']);
                    this.esCompuesto = true;
						if (this.AgregarCompuesto == true)
						{
							this.detalles.push(
							{
							id:this.detalles.length + 2,
							Codigop:data['codigoProducto'],
							descripcion:data['descripcion'],
							cantidad:1,
							PrecioFinal:data['precioVenta'],
							precio:data['precioVenta'],
							precioVenta:data['precioVenta'],
							precioDetalle:data['precioDetalle'],
							precioCentral:data['precioCentral'],
							precioMayor:data['precioMayor'],
							precioCompra:data['precioCompra'],
							Ivi:data['estadoInventario'],
							descuento:0,
							Temp: 0,
							detalle:"",
							cabys:data['codigoRaiz'],
							impuesto:data['iva'],
							valorImpuesto:data['valorImpuesto'],
							gravado:data['exe_Grad'],
							localizacion:data['localizacion'],
							cocina: data['cocina'],
							check: 0,
							MaxDescuento:data['maxDescuento'],
							activador:data['activador'],
							cantDescuento:data['descuento'],
							cantMinima:data['cantidad'],
							impEspecial:data['tipoAlimento'],
							}
							);
						}
                } else
				this.detalles.push(
					{
					id:this.detalles.length + 2,
					Codigop:data['codigoProducto'],
					descripcion:data['descripcion'],
					cantidad:1,
					precio:data['precioVenta'],
					PrecioFinal:data['precioVenta'],
					precioVenta:data['precioVenta'],
					precioDetalle:data['precioDetalle'],
					precioCentral:data['precioCentral'],
					precioMayor:data['precioMayor'],
					precioCompra:data['precioCompra'],
					Ivi:data['estadoInventario'],
					descuento:0,
					Temp: 0,
					detalle:"",
					cabys:data['codigoRaiz'],
					impuesto:data['iva'],
					valorImpuesto:data['valorImpuesto'],
					gravado:data['exe_Grad'],
					localizacion:data['localizacion'],
					cocina: data['cocina'],
					check: 0,
					MaxDescuento:data['maxDescuento'],
					activador:data['activador'],
					cantDescuento:data['descuento'],
					cantMinima:data['cantidad'],
					impEspecial:data['tipoAlimento'],
					}
					);
                
            }
            else{
			alert("Debe digitar un código de producto o servicio");
            
          }
        }  else
        if(this.EsCero == true)  {   
          	if (data['codigoProducto'] != '' && data['codigoProducto'] != undefined)
          	{
                if(data['compuesto'] == true)
				{
                    this.selectCompuesto(data['codigoProducto']);
                    this.esCompuesto = true;
						if (this.AgregarCompuesto == true)
						{
							this.detalles.push(
							{
							id:this.detalles.length + 2,
							Codigop:data['codigoProducto'],
							descripcion:data['descripcion'],
							cantidad:1,
							precio:0,
							precioCompra:0,
							PrecioFinal:0,
							Ivi:data['estadoInventario'],
							descuento:0,
							Temp: 0,
							detalle:"",
							cabys:data['codigoRaiz'],
							impuesto:data['iva'],
							valorImpuesto:0,
							gravado:'N',
							localizacion:data['localizacion'],
							cocina: data['cocina'],
							check: 0,
							MaxDescuento:data['maxDescuento'],
							impEspecial:data['tipoAlimento'],
							}
							);
						}
                } else
				this.detalles.push(
					{
					id:this.detalles.length + 2,
					Codigop:data['codigoProducto'],
					descripcion:data['descripcion'],
					cantidad:1,
					precio:0,
					PrecioFinal:0,
					Ivi:data['estadoInventario'],
					descuento:0,
					Temp: 0,
					detalle:"",
					cabys:data['codigoRaiz'],
					impuesto:data['iva'],
					valorImpuesto:data['valorImpuesto'],
					gravado:data['exe_Grad'],
					localizacion:data['localizacion'],
					cocina: data['cocina'],
					check: 0,
					MaxDescuento:data['maxDescuento']
					}
					);
            }
            else{
			alert("Debe digitar un código de producto o servicio");
            
          }
        }
        
    },
    agregarDetallecombo(data = []){
		this.codigo=data['codigoProducto'];
		this.combonombre=data['descripcion'];
		this.precio=data['precioVenta'];
		this.imgcombo=data['localizacion'];
		this.subFamilia=data['subFamilia'];
		this.llevaadicional=data['receta'];
    },
    AbrirMesa(){
      this.dialogmesas2 = true;
      this.BuscarMesa();
    },
	formattedDescription(description) {
      const maxLength = 25; // Máxima longitud del texto
      if (!description) return 'Descripción no disponible'; // Texto por defecto si no hay descripción
      return description.length > maxLength ? description.substring(0, maxLength) + '...' : description;
    },
    obtenerTicketFactura(){
                var self = this;
                var arrayTicket = [];
				
                var ticketsArray=[];
                var localidadPrevia = "";
				if (this.localidad.text === undefined)
                {
                    localidadPrevia = this.$store.state.usuario.localidad;
                }
                else
                {
                    localidadPrevia = this.localidad.text;
                }
                var link = '';
                var ticketNum = 0;
                let header={"Authorization" : "Bearer "};
                let configuracion= {headers : header};
                axios.get('api/Facturas/SelectTickets', { params: {'localidad': localidadPrevia}},configuracion).then(function(response){
                    ticketsArray=response.data;
                    for(var i = 0; i < ticketsArray.length; ++i)
                    {
                       ticketNum = ticketsArray[i].noTicket;
                      break;
					}  
					link = location.origin+'/'+'ticket/'+ticketNum;
					location.assign(link); 
                }).catch(function(error){
					self.snackbar= true;
					self.Mensaje = "Ocurrió un error de conexión al obtener el tiquet, por favor revise su conexión a internet y recargue el sitio "+error;
					self.modelCarga = false;
                });
	},
	
	SeleccionarMesa(itemMesa){
		
      this.habilitarCampoTexto = false; // Oculta el campo de texto
      if(this.MesaSeleccionada == itemMesa){
        this.dialogmesas2 = false;
		this.nombreCliente = ''
        this.habilitarCampoTexto = false; // Oculta el campo de texto
        return;
      }
      this.MesaSeleccionada = itemMesa;
      this.dialogmesas2 = false;
      localStorage.setItem("Mesa",this.MesaSeleccionada);
      this.selectMesa(itemMesa);
    },
    obtenerTicketMesa(){
		if(this.clienteNombre === null){
			this.clienteNombre = "";
		}
		
		this.clienteNombre = this.clienteNombre.value !== undefined ? this.clienteNombre.value : this.clienteNombre;

		if(this.detalles.length == 0){
			this.VerProductosMesa();
			return;
		}
		this.modoretiro = "Servir en Mesa";
		this.tipoCompra = "Servir en Mesa";
		this.metodoPago = "Sin Definir";
		var self = this;
		self.OrdenExpress();
    },
	RetirarEnSucursal(){
		this.MesaSeleccionada = 0;
        this.modoretiro = 'Retirar';
		this.OrdenExpress();
      },
	EnvioDomicilio(){
		if(this.direccion == ""){
			this.Mensaje= "Por favor selecciona a cual dirección entregar";
			this.snackbar = true;
			return;
		} else
		if(this.clienteTelefono == ""){
			this.Mensaje= "Por favor digite al menos el teléfono del cliente";
			this.snackbar = true;
			return;
		}
		if(this.clienteNombreExpress == ""){
			this.Mensaje= "Por favor digite el Nombre del Cliente";
			this.snackbar = true;
			return;
		}
		
		this.modoretiro = "Express";
		this.MesaSeleccionada = 0;
        this.OrdenExpress();
	},
	EnvioServir(){
		this.modoretiro = "Pasan";
		this.MesaSeleccionada = 0;
        this.OrdenExpress();
	},
	EnvioPasa(){
		this.modoretiro = "Pasa a Retirar";
        this.OrdenExpress();
	},
	obtenerTicket2(){
		var self = this;
		var ticketsArray=[];
		var ticketNum = 0;
		let header={"Authorization" : "Bearer "};
		let configuracion= {headers : header};
		var arrayTicket = [];
		axios.get('api/ordenexpress/Listar', configuracion).then(function(response){
			ticketsArray=response.data;
			ticketNum = ticketsArray.length;
				self.OrdenExpress(ticketNum);
			}).catch(function(error){
			console.log(error);
		});
	} ,
    validarOrden(){
		this.valida=1;
		if (this.latitud == 0 && this.modoretiro == 'A Domicilio'){
			alert("Debe Seleccionar Tipo de entrega a Domiclio para calcular monto de express.");
			this.valida=0;
		}

		if (!this.modoretiro){
			alert("Seleccione el Método de entrega.");
			this.valida=0;
		}
		if (this.detalles.length == 0){
			alert("Debe ingresar productos que facturar.");
			this.valida=0;
			return;
		}
		return this.valida;
    },
	SetPrecioVenta(){
		this.listaPrecio = 'Precio Venta';
		this.CalcularPrecio();
	},
	SetPrecioDetalle(){
		this.listaPrecio = 'Precio Detalle';
		this.CalcularPrecio();
	},
	SetPrecioCentral(){
		this.listaPrecio = 'Precio Central';
		this.CalcularPrecio();
	},
	SetPrecioMayor(){
		this.listaPrecio = 'Precio Mayor';
		this.CalcularPrecio();
	},
	OrdenExpress(){
		this.pagaCon = this.pagaCon.value !== undefined ? this.pagaCon.value : this.pagaCon;

		if (this.clienteNombre == "")
        {
            this.clienteNombre = this.clienteNombreExpress
        }
		if (this.validarOrden() != 1){
				return;
			} else {
		this.loading = true;
		this.dialog = false;
		this.modelCarga = true;
		var momentoActual = new Date(); 
		var hora = momentoActual.getHours(); 
		var minuto = momentoActual.getMinutes(); 
		var segundo = momentoActual.getSeconds();
		var time = hora + ":" + minuto + ":" + segundo;
		var self = this;
		let header={"Authorization" : "Bearer "};
                let configuracion= {headers : header};
                axios.post('api/ordenexpress/CrearMesaTotal',{
					'fecha': this.today.toString(),//MM/dd/YYYY
					'cajero': this.vendedor,
					'total': this.Total,
					'Nombre': this.clienteNombre,
					'telefono': this.clienteTelefono,
					'Direccion': this.direccion,
					'orden': this.orden,
					'tipoPago1': this.tipoPago,
					'Hora': hora + ":" + minuto + ":" + segundo,
					'descuento': this.descuento,
					'localidad': this.localidad,
					'estado': 0,
					'nota': this.metodoPago+" "+this.pagaCon+" "+this.metodoToma+" Tel:"+this.clienteTelefono +this.nota,
					'tipoCompra' :this.modoretiro,
					'detalles': this.detalles,
					'correo': this.email,
					'latitud': this.latitud,
					'longitud': this.longitud,
					'Mesa':this.MesaSeleccionada,
					'TipoCedula':this.clienteTipoCedula,
					'Cedula':this.clienteCedula,
					'Direccion2':this.DireccionCliente2,
					'Direccion3':this.DireccionCliente3,
					'Personas':this.dividendo,
					'Detalle':this.detalleFactura,
					'ModoRetiro':this.modoretiro,
                },configuracion).then(function (response) {
				if(self.modoPDV == 6){
					self.ImpresionPyhon(time);
				} else
				{
					self.ImprimirTicketCocina(time);
				}
				
				self.FinalizarOrden();
				self.dialogmesas = false;
				self.modelCarga = false;
				self.Mensaje = 'Productos cargados a: '+self.MesaSeleccionada ;
				self.snackbar = true;
				self.detalles = [];
				self.clienteNombre = "";
				self.modoretiro = "";
				})
				.catch(error => {
					self.loading = false;
					self.modelCarga = false;
					if (error.response && error.response.data && error.response.data.message) {
						alert("Error: " + error.response.data.message);
					} else {
						alert("Ocurrió un error desconocido.");
					}
				})
			} return;
    },
	async obtenerTicketPOS(){
		var self = this;
		var ticketsArray=[];
		var ticketNum = 0;
		let header={"Authorization" : "Bearer "};
		let configuracion= {headers : header};
		await axios.get('api/Facturas/SelectTickets', { params: {'localidad': this.$store.state.usuario.localidad}},configuracion).then(function(response){
			ticketsArray=response.data;
			ticketNum = ticketsArray[0].noTicket;
			self.listarDetalles(ticketNum);
		}).catch(function(error){
			self.snackbar= true;
			self.Mensaje = "Ocurrió un error de conexión al obtener el tiquet, por favor revise su conexión a internet y recargue el sitio "+error;
			self.modelCarga = false;
		});
	},
	async listarLocalidad(){
	let me =this;
	let header={"Authorization" : "Bearer " + this.$store.state.token};
	let configuracion= {headers : header};
		await axios.get('api/Localidades/ListarFiltrada/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
			//console.log(response);
			me.localidadencabezado=response.data;
			me.Regimen = localidadencabezado[0].regimen;
			
		}).catch(function(error){
			console.log(error);
		});
				
	},
	async listarDetalles(id){
		let me=this;
		let array = [];
		let header={"Authorization" : "Bearer " + this.$store.state.token};
		let configuracion= {headers : header};
		await axios.get('api/RepVentasVistas/ListarClaveFactura/'+id+'/'+this.$store.state.usuario.localidad+'',configuracion).then(function(response){
			//console.log(response);
			array=response.data;
			me.ClaveComprobante = array[0].claveComprobante;
			me.ImprimrFactura(id);
		}).catch(function(error){
			
			me.loading = false;
			me.ImprimrFactura(id);
			console.log(error);
		});
	},
	async ImprimrFactura(NoFactura,clave){
		const fechaActual = new Date();

		// Obtener componentes de la fecha
		const dia = fechaActual.getDate();
		const mes = fechaActual.getMonth() + 1; // Sumamos 1 para obtener el mes correcto
		const año = fechaActual.getFullYear();

		// Obtener componentes de la hora
		const horas = fechaActual.getHours();
		const minutos = fechaActual.getMinutes();
		const segundos = fechaActual.getSeconds();

		// Formatear la fecha y hora en una cadena
		const fech = `${(dia)}/${(mes)}/${año} : ${(horas)}:${(minutos)}:${(segundos)}`;

		var Firma = "";
		var Cajon = "";
		var infoCredito = "";
		if(this.tipoPago == "EFECTIVO"){
			Cajon = '<pulse drawer="drawer_1" time="pulse_100"/>';
		}
		if(this.ip1 == "" || this.devid1 == ""){
			this.Mensaje= "No hay impresora de red asignada";
			this.snackbar = true;
			location.reload();
		}
		if(this.CopiasImpresion == 2){
			Firma = `<feed line="2"/>
					<text>Firma: _______________________&#10;</text>
					<feed line="3"/>
					<text>Cédula: _______________________&#10;</text>`;
		}

		var products = "";
		for (var j = 0; j < this.productosDatos.length; j++) {
			products += formatLine(this.productosDatos[j].Cantidad, this.productosDatos[j].Descripcion, this.productosDatos[j].PrecioUnitario);
		}

		if(this.CondicionVenta == "CREDITO"){
			let abono = parseInt(this.Total) - parseInt(this.saldo);
			infoCredito = `<feed line="2"/>
					<text>Factura a crédito:&#10;</text>
					<text>Abono: `+abono+`&#10;</text>
					<text>Saldo: `+this.saldo+`&#10;</text>;`;
		}
		function formatLine(cantidad, descripcion, precio) {
			let cantidadFormatted = cantidad.toString().padEnd(5, ' ');
			let descripcionFormatted = descripcion.substring(0, 22).padEnd(22, ' ');
			let precioFormatted = (precio * cantidad).toFixed(2).padStart(8, ' ');
			return `<text>${cantidadFormatted}${descripcionFormatted}${precioFormatted}&#10;</text>`;
		}
		for(var i = 0; i < this.CopiasImpresion; ++i)
			{
				var request = `<epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">
				<logo key1="48" key2="48"/>
				<text align="center"/>
				<text font="font_a"/>
				<text width="2" height="2"/>
				<text reverse="false" ul="false" em="false" color="color_1"/>
				<text>`+this.$store.state.usuario.localidad+`&#10;</text>
				<text width="1" height="1"/>
				<text>`+this.localidadencabezado[0].razonSocial+`&#10;</text>
				<text>`+this.localidadencabezado[0].dirreccion+`&#10;</text>
				<text>`+this.localidadencabezado[0].nombre+`&#10;</text>
				<text> `+this.localidadencabezado[0].cedulaJuridica+`&#10;</text>
				<text>`+this.localidadencabezado[0].telefono+`&#10;</text>
				<text>`+this.localidadencabezado[0].correo+`&#10;</text>
				<text reverse="false" ul="false" em="false" color="color_1"/>
				<text>---------------------------------------&#10;</text>
				<text align="left"/>
				<text>Vendedor: `+this.$store.state.usuario.nombre+`&#10;</text>
				<text>Fecha: `+fech+`&#10;</text>
				<text>Factura: `+NoFactura+`&#10;</text>
				<text>Cliente: `+this.clienteNombre+`&#10;</text>
				<text>Cédula: `+this.clienteCedula+`&#10;</text>
        		<text>Teléfono: `+this.clienteTelefono+`&#10;</text>
				<text>Tipo de Documento: `+this.tipoDocumento+`&#10;</text>
				<text>Efectivo recibido: `+this.recibidoEfectivo+`&#10;</text>
				<text>`+this.tipoPago+`&#10;</text>
				<text>---------------------------------------&#10;</text>
				<text>Cant   &#9;   Producto   &#9;   Precio&#10;</text>
				`+products+`
				<text align="center"/>
				<text>---------------------------------------&#10;</text>
				<text>Imp%1 &#9; Imp%2 &#9; Imp%4 &#9; Imp%13 &#10;</text>
				<text>`+this.imp1.toFixed(2)+`&#9; `+this.imp2.toFixed(2)+`&#9; `+this.imp4.toFixed(2)+`&#9; `+this.imp13.toFixed(2)+`&#10;</text>
				<text>   SubTotal &#9; Vuelto &#9; Total   &#10;</text>
				<text>`+this.subtotal+`&#9; `+this.vueltoPagar+`&#9;   `+this.Total+`&#10;</text>
				`+infoCredito+`
				<text>---------------------------------------&#10;</text>
				<text>Detalle: `+this.detalleFactura+`&#10;</text>
				<text>Beeper: `+this.beeper+`&#10;</text>
				<text>`+clave+`&#10;</text>
				<text>`+this.localidadencabezado[0].pieDocumento+`&#10;</text>
				`+Firma+`
				<feed line="3"/>
				<cut type="feed"/>
				`+Cajon+`
				</epos-print>`;
				
				//Create a SOAP envelop
				var soap = '<s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">' +
				'<s:Body>' + request + '</s:Body></s:Envelope>';
				//Create an XMLHttpRequest object
				var xhr = new XMLHttpRequest();
				//Set the end point address
				var url = 'http://'+this.ip1+'/cgi-bin/epos/service.cgi?devid='+this.devid1+'&timeout=60000';
				//Open an XMLHttpRequest object
				xhr.open('POST', url, true);
				//<Header settings>
				xhr.setRequestHeader('Content-Type', 'text/xml; charset=utf-8');
				xhr.setRequestHeader('If-Modified-Since', 'Thu, 01 Jan 1970 00:00:00 GMT');
				// Send print document
				await xhr.send(soap);

			}
			this.FinalizarOrden();
		
	},
	async ImprimirTicketCocina(time){

			if(this.ip2 == "" && this.devid2 == ""){
				this.Mensaje = "No hay impresora de cocina configurada";
				this.snackbar= true;
				//location.reload();
				return;	
			} // Aqui se construye el titulo de la impresion
			
			var Cocina = false;
			var Bar = false;
			var Titulo = `<text> Comanda Mesa `+this.MesaSeleccionada+` &#10;</text>`;
			if(this.MesaSeleccionada == 0){
				Titulo = `<text>Comanda Cocina &#10;</text>`;
			}
			if(this.modoretiro == "Express"){
				Titulo = `<text>Express &#10;</text>`;
			} else
			if(this.modoretiro == "Servir"){
				Titulo = `<text>Servir &#10;</text>`;
			} else 
			if(this.modoretiro == "Pasa a Retirar"){
				Titulo = `<text> Pasan a retirar &#10;</text>`;
			} 
			var products = "";
            for(var i = 0; i < this.detalles.length; ++i)
              {
				if(this.detalles[i].cocina == 1)
				{
					Cocina = true;
					if(this.detalles[i].precio == "0"){
					products += "<text>    ***"+this.detalles[i].cantidad+"-"+this.detalles[i].descripcion.substring(0, 34)+"&#10;</text>"
					
					}else
						products += "<text>"+this.detalles[i].cantidad+"---"+this.detalles[i].descripcion.substring(0, 34)+"&#10;</text>"
					if(this.detalles[i].detalle != ""){
						products += "<text>"+"   ***"+this.detalles[i].detalle+"&#10;</text>"
					}
				}
				if(this.detalles[i].cocina == 2)
				{
					Bar = true;
				}
              }

			if(Cocina == true){

			
				var request = `<epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">
						<text align="center"/>
						<text font="font_c"/>
						<text width="3" height="3"/>
						<text reverse="false" ul="false" em="false" color="color_1"/>
						<feed line="3"/>
						<feed line="3"/>
						`+Titulo+` 
						<text width="1" height="1"/>
						<text>Mesero: `+this.vendedor+`&#10;</text>
						<text>Order: &#10;</text>
						<text>Time= `+time+`&#10;</text>
						<text>Client:`+this.clienteNombre+`&#10;</text>
						<text>-------------------------------------&#10;</text>
						<text font="font_a"/>
						<text width="1" height="1"/>
						<text dw="false" dh="true"/>
						<text align="left"/>
						<text>Cant      &#9;   Item.     &#10;</text>
						`+products+`
						<text>Detalle: `+this.detalleFactura+`&#10;</text>
						<text>Beeper: `+this.beeper+`&#10;</text>
						<feed line="3"/>
						<cut type="feed"/>
						</epos-print>`;
				
				//Create a SOAP envelop
				var soap = '<s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">' +
				'<s:Body>' + request + '</s:Body></s:Envelope>';
				//Create an XMLHttpRequest object
				var xhr = new XMLHttpRequest();
				//Set the end point address
				var url = 'http://'+this.ip2+'/cgi-bin/epos/service.cgi?devid='+this.devid2+'&timeout=60000';
				//Open an XMLHttpRequest object
				xhr.open('POST', url, true);
				//<Header settings>
				xhr.setRequestHeader('Content-Type', 'text/xml; charset=utf-8');
				xhr.setRequestHeader('If-Modified-Since', 'Thu, 01 Jan 1970 00:00:00 GMT');
				// Send print document
				await xhr.send(soap);
			}
			if(Bar == true){
				this.ImprimirBar(time)
			}
	},

	async ImpresionPyhon(time){

			// Crear el objeto de datos que se enviará al backend
		const dataParaImprimir = {
			MesaSeleccionada: this.MesaSeleccionada,
			productos: this.detalles, // Asumimos que 'detalles' es un array de productos
			vendedor: this.vendedor,
			clienteNombre: this.clienteNombre,
			time: time,  // Asegúrate de que 'time' esté en un formato adecuado
			order_id: 5
		};

		// Configuración de Axios para la solicitud POST
		axios.post('http://localhost:5000/imprimir-comanda', dataParaImprimir)
			.then(response => {
				console.log('Comanda impresa correctamente');
				this.Mensaje = "Comanda enviada";
				this.snackbar = true;
			})
			.catch(error => {
				console.error('Error al imprimir comanda:', error);
				alert('Error al imprimir la comanda: ' + error.response.data.error);
			});
	},
	async ImprimirBar(time){

			if(this.ip2 == "" && this.devid2 == ""){
				this.Mensaje = "No hay impresora de cocina configurada";
				this.snackbar= true;
				location.reload();
				return;	
			} // Aqui se construye el titulo de la impresion
			
			var Titulo = `<text>Comanda Bar - Mesa `+this.MesaSeleccionada+` &#10;</text>`;
			if(this.MesaSeleccionada == 0){
				Titulo = `<text>Comanda Bar &#10;</text>`;
			}
			if(this.modoretiro == "Express"){
				Titulo = `<text>Express &#10;</text>`;
			} else
			if(this.modoretiro == "Servir"){
				Titulo = `<text>Servir &#10;</text>`;
			} else 
			if(this.modoretiro == "Pasa a Retirar"){
				Titulo = `<text> Pasan a retirar &#10;</text>`;
			} 
			var products = "";
            for(var i = 0; i < this.detalles.length; ++i)
              {
				if(this.detalles[i].cocina == 2)
				{
					if(this.detalles[i].precio == "0"){
					products += "<text>    ***"+this.detalles[i].cantidad+"-"+this.detalles[i].descripcion.substring(0, 34)+"&#10;</text>"
					
					}else
						products += "<text>"+this.detalles[i].cantidad+"---"+this.detalles[i].descripcion.substring(0, 34)+"&#10;</text>"
					if(this.detalles[i].detalle != ""){
						products += "<text>"+"   ***"+this.detalles[i].detalle+"&#10;</text>"
					}
				}
              }


            var request = `<epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">
					<text align="center"/>
					<text font="font_c"/>
					<text width="3" height="3"/>
					<text reverse="false" ul="false" em="false" color="color_1"/>
					<feed line="3"/>
					<feed line="3"/>
					`+Titulo+` 
					<text width="1" height="1"/>
					<text>Mesero: `+this.vendedor+`&#10;</text>
					<text>Order: &#10;</text>
					<text>Time= `+time+`&#10;</text>
					<text>Client:`+this.clienteNombre+`&#10;</text>
					<text>-------------------------------------&#10;</text>
					<text font="font_a"/>
					<text width="1" height="1"/>
					<text dw="false" dh="true"/>
					<text align="left"/>
					<text>Cant      &#9;   Item.     &#10;</text>
					`+products+`
					<text>Detalle: `+this.detalleFactura+`&#10;</text>
					<feed line="3"/>
					<cut type="feed"/>
					</epos-print>`;
			
			//Create a SOAP envelop
			var soap = '<s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">' +
			'<s:Body>' + request + '</s:Body></s:Envelope>';
			//Create an XMLHttpRequest object
			var xhr = new XMLHttpRequest();
			//Set the end point address
			var url = 'http://'+this.ip3+'/cgi-bin/epos/service.cgi?devid='+this.devid3+'&timeout=60000';
			//Open an XMLHttpRequest object
			xhr.open('POST', url, true);
			//<Header settings>
			xhr.setRequestHeader('Content-Type', 'text/xml; charset=utf-8');
			xhr.setRequestHeader('If-Modified-Since', 'Thu, 01 Jan 1970 00:00:00 GMT');
			// Send print document
			await xhr.send(soap);
	},
	async ImprimirProformaClon(){
			if(this.modoPDV == 1){
					setTimeout(function(){  window.print(); }, 3000); 
				} 
			else
			{
				if(this.ip1 == ""){
					this.Mensaje = "No hay impresora de cocina configurada";
					this.snackbar= true;
					return;	
				}
			}
			const fechaActual = new Date();

			// Obtener componentes de la fecha
			const dia = fechaActual.getDate();
			const mes = fechaActual.getMonth() + 1; // Sumamos 1 para obtener el mes correcto
			const año = fechaActual.getFullYear();

			// Obtener componentes de la hora
			const horas = fechaActual.getHours();
			const minutos = fechaActual.getMinutes();
			const segundos = fechaActual.getSeconds();

			// Formatear la fecha y hora en una cadena
			const fech = `${(dia)}/${(mes)}/${año} : ${(horas)}:${(minutos)}:${(segundos)}`;

			var ImpServ = 0.0;
			var products = "";
			var total= 0;
			this.dialogProforma = true;
				for (var i = 0; i < this.ItemProforma.length; i++) {
					total += this.ItemProforma[i].precioFinal * this.ItemProforma[i].cantidad;
					if(this.ItemProforma[i].precioFinal != "0"){
						products += formatLine(this.ItemProforma[i].cantidad, this.ItemProforma[i].descripcion, this.ItemProforma[i].precioFinal);
					}
				}
			function formatLine(cantidad, descripcion, precio) {
				let cantidadFormatted = cantidad.toString().padEnd(5, ' ');
				let descripcionFormatted = descripcion.substring(0, 22).padEnd(22, ' ');
				let precioFormatted = (precio * cantidad).toFixed(2).padStart(8, ' ');
				return `<text>${cantidadFormatted}${descripcionFormatted}${precioFormatted}&#10;</text>`;
			}
			if(this.AddImpServ == true){
				if(this.Regimen == "TRADICIONAL"){
					ImpServ = (parseFloat(total) / 1.13)  *0.10 ;
				} else
				if(this.Regimen != "TRADICIONAL"){
					ImpServ = parseFloat(total)  *0.10 ;
				}
				if(this.$store.state.usuario.localidad === 'Restaurante Don Lalo'){
					total = parseInt(total) ;
				} else
				{
					total = parseInt(total) + parseInt(ImpServ);
				}
				
			}
			this.totalProforma = total;
			var dolares = total / this.TipoCambioInterno;
			dolares = dolares.toFixed(2);
			this.ImpServProforma = ImpServ.toFixed(2);
			if(this.modoPDV == 1){
				setTimeout(function(){  window.print(); }, 3000); 
			} else
			if(this.modoPDV == 3){
				if(this.ip1 == "" && this.devid1 == ""){
				this.Mensaje = "No hay impresora de cocina configurada";
				this.snackbar= true;
				return;	
			}
			} 
            var request = `<epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">
				<logo key1="48" key2="48"/>
				<text align="center"/>
				<text font="font_a"/>
				<text width="2" height="2"/>
				<text reverse="false" ul="false" em="false" color="color_1"/>
				<text>`+this.$store.state.usuario.localidad+`&#10;</text>
				<text width="1" height="1"/>
				<text>`+this.localidadencabezado[0].razonSocial+`&#10;</text>
				<text>`+this.localidadencabezado[0].dirreccion+`&#10;</text>
				<text>`+this.localidadencabezado[0].nombre+`&#10;</text>
				<text> `+this.localidadencabezado[0].cedulaJuridica+`&#10;</text>
				<text>`+this.localidadencabezado[0].telefono+`&#10;</text>
				<text>`+this.localidadencabezado[0].correo+`&#10;</text>
				<text reverse="false" ul="false" em="false" color="color_1"/>
				<text>---------------------------------------&#10;</text>
				<text align="left"/>
				<text>Cuenta:  `+this.MesaSeleccionada+` &#10;</text>
				<text>Vendedor: `+this.$store.state.usuario.nombre+`&#10;</text>
				<text>Fecha: `+fech+`&#10;</text>
				<text>Cliente: `+this.clienteNombre+`&#10;</text>
				<text>`+this.tipoPago+`&#10;</text>
				<text>---------------------------------------&#10;</text>
				<text>Cant   &#9;   Producto   &#9;   Precio&#10;</text>
				`+products+`
				<text font="font_a"/>
						<text>Impuesto Servicio: `+ImpServ.toFixed(2)+` &#10;</text>
						<text font="font_c"/>
						<text width="2" height="2"/>
						<text>Total: c`+total+` &#10;</text>
						<text>Total: $`+dolares+` &#10;</text>
						<feed line="3"/>
						<feed line="3"/>
						<text>Firma:______________ &#10;</text>
						<text>Extra Tips:________ &#10;</text>
						<feed line="3"/>
						<cut type="feed"/>
						</epos-print>`;
                
				
				//Create a SOAP envelop
				var soap = '<s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">' +
				'<s:Body>' + request + '</s:Body></s:Envelope>';
				//Create an XMLHttpRequest object
				var xhr = new XMLHttpRequest();
				//Set the end point address
				var url = 'http://'+this.ip1+'/cgi-bin/epos/service.cgi?devid='+this.devid1+'&timeout=60000';
				//Open an XMLHttpRequest object
				xhr.open('POST', url, true);
				//<Header settings>
				xhr.setRequestHeader('Content-Type', 'text/xml; charset=utf-8');
				xhr.setRequestHeader('If-Modified-Since', 'Thu, 01 Jan 1970 00:00:00 GMT');
				// Send print document
				await xhr.send(soap);
		},
	
	async ImprimirProforma(item,cliente,total){
			const fechaActual = new Date();

			// Obtener componentes de la fecha
			const dia = fechaActual.getDate();
			const mes = fechaActual.getMonth() + 1; // Sumamos 1 para obtener el mes correcto
			const año = fechaActual.getFullYear();

			// Obtener componentes de la hora
			const horas = fechaActual.getHours();
			const minutos = fechaActual.getMinutes();
			const segundos = fechaActual.getSeconds();

			// Formatear la fecha y hora en una cadena
			const fech = `${(dia)}/${(mes)}/${año} : ${(horas)}:${(minutos)}:${(segundos)}`;

			var ImpServ = 0.0;
			var products = "";
			this.clienteNombre = cliente;
			this.ItemProforma = [];
			this.ItemProforma = item;
			this.dialogProforma = true;
			var totalFinal = 0;
			
			
			for (var i = 0; i < item.length; i++) {
				// Aseguramos que precioFinal y cantidad sean numéricos antes de sumar al total
				const precio = parseFloat(item[i].precioFinal);
				const cantidad = parseFloat(item[i].cantidad);
				
				if (!isNaN(precio) && !isNaN(cantidad)) {
					totalFinal += precio * cantidad;
				} else {
					console.error(`Valores no numéricos detectados: precioFinal = ${item[i].precioFinal}, cantidad = ${item[i].cantidad}`);
				}

				if (item[i].precioFinal != "0") {
					products += formatLine(cantidad, item[i].descripcion, precio);
				}
			}

			function formatLine(cantidad, descripcion, precio) {
				let cantidadFormatted = cantidad.toString().padEnd(5, ' ');
				let descripcionFormatted = descripcion.substring(0, 22).padEnd(22, ' ');
				let precioFormatted = (precio * cantidad).toFixed(2).padStart(8, ' ');
				return `<text>${cantidadFormatted}${descripcionFormatted}${precioFormatted}&#10;</text>`;
			}
			if(this.AddImpServ == true){
				if(this.Regimen == "TRADICIONAL"){
					ImpServ = (parseFloat(total) / 1.13)  *0.10 ;
				} else
				if(this.Regimen != "TRADICIONAL"){
				ImpServ = parseFloat(totalFinal)  *0.10 ;
				}
				if(this.$store.state.usuario.localidad === 'Restaurante Don Lalo'){
					totalFinal = parseInt(totalFinal) ;
				} else
				{
					totalFinal = parseInt(totalFinal) + parseInt(ImpServ);
				}
			}
			this.totalProforma = totalFinal;
			var dolares = totalFinal / this.TipoCambioInterno;
			dolares = dolares.toFixed(2);
			this.ImpServProforma = ImpServ.toFixed(2);
			if(this.modoPDV == 1){
				setTimeout(function(){  window.print(); }, 3000); 
			} else
			if(this.modoPDV == 3){
				if(this.ip1 == "" && this.devid1 == ""){
				this.Mensaje = "No hay impresora de cocina configurada";
				this.snackbar= true;
				return;	
			}
			} 
            var request = `<epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">
				<logo key1="48" key2="48"/>
				<text align="center"/>
				<text font="font_a"/>
				<text width="2" height="2"/>
				<text reverse="false" ul="false" em="false" color="color_1"/>
				<text>`+this.$store.state.usuario.localidad+`&#10;</text>
				<text width="1" height="1"/>
				<text>`+this.localidadencabezado[0].razonSocial+`&#10;</text>
				<text>`+this.localidadencabezado[0].dirreccion+`&#10;</text>
				<text>`+this.localidadencabezado[0].nombre+`&#10;</text>
				<text> `+this.localidadencabezado[0].cedulaJuridica+`&#10;</text>
				<text>`+this.localidadencabezado[0].telefono+`&#10;</text>
				<text>`+this.localidadencabezado[0].correo+`&#10;</text>
				<text reverse="false" ul="false" em="false" color="color_1"/>
				<text>---------------------------------------&#10;</text>
				<text align="left"/>
				<text>Cuenta:  `+this.MesaSeleccionada+` &#10;</text>
				<text>Vendedor: `+this.$store.state.usuario.nombre+`&#10;</text>
				<text>Fecha: `+fech+`&#10;</text>
				<text>Cliente: `+cliente+`&#10;</text>
				<text>`+this.tipoPago+`&#10;</text>
				<text>---------------------------------------&#10;</text>
				<text>Cant   &#9;   Producto   &#9;   Precio&#10;</text>
				`+products+`
				<text font="font_a"/>
						<text>Impuesto Servicio: `+ImpServ.toFixed(2)+` &#10;</text>
						<text font="font_c"/>
						<text width="2" height="2"/>
						<text>Total: c`+totalFinal+` &#10;</text>
						<text>Total: $`+dolares+` &#10;</text>
						<feed line="3"/>
						<feed line="3"/>
						<text>Firma:______________ &#10;</text>
						<text>Extra Tips:________ &#10;</text>
						<feed line="3"/>
						<cut type="feed"/>
						</epos-print>`;
                
				
				//Create a SOAP envelop
				var soap = '<s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">' +
				'<s:Body>' + request + '</s:Body></s:Envelope>';
				//Create an XMLHttpRequest object
				var xhr = new XMLHttpRequest();
				//Set the end point address
				var url = 'http://'+this.ip1+'/cgi-bin/epos/service.cgi?devid='+this.devid1+'&timeout=60000';
				//Open an XMLHttpRequest object
				xhr.open('POST', url, true);
				//<Header settings>
				xhr.setRequestHeader('Content-Type', 'text/xml; charset=utf-8');
				xhr.setRequestHeader('If-Modified-Since', 'Thu, 01 Jan 1970 00:00:00 GMT');
				// Send print document
				await xhr.send(soap);
		},
	insertarTicket(ticketNum){
					let header={"Authorization" : "Bearer "};
					let configuracion= {headers : header};
					let me=this;
					axios.post('api/OrdenExpress/CrearTicket',{
						'NoTicket':ticketNum,
					},configuracion).then(function(response){    
					me.dialog = false; 

					}).catch(function(error){
						console.log(error);
					});
	},
		eliminarProducto(id){
			//console.log(id)
			this.detalles = this.detalles.filter(e => e.id != id)
			//console.log(this.cards)
		},
		eliminarProductoTemporal(id){
			//console.log(id)
			this.CuentaTemporal = this.CuentaTemporal.filter(e => e.idRegistro != id)
			//console.log(this.cards)
		},
		FinalizarOrden(){
			this.dialog = false
			this.pagorealizado = true
			this.dialogPagoCredito = false;
			this.loading = false
			this.dialogPago = false;
			this.modelCarga = false;
			this.clienteNombre = '';
			this.CondicionVenta = '';
			this.detalleFactura = '';
			this.pagorealizado = false;
			this.detalles = [];
			this.productosDatos = [];
			this.Limpiar();
			//setTimeout(function(){  location.reload(); }, 3000); 
		},
		SumarProdcuto(cant){
			this.sumaruno = cant
			this.cantidad = this.sumaruno + 1
		},
      RestarProducto(cant){
        if(cant != 1){
          this.sumaruno = cant
          this.cantidad = this.sumaruno - 1
        }
        },
      	detalleproducto(index){
			this.indexProducto = index 
			this.dialogDetalle = true;
		},
		IncluirDetalle(){
			this.dialogDetalle = false;
			this.detalles[this.indexProducto].detalle = "**"+ this.detalledelProducto;
			this.detalledelProducto = "";
		},
      editarProductoSuma(item){
		  item.cantidad = item.cantidad +1;
      },
      editarProductoResta(item){
          if(item.cantidad != 1){
            item.cantidad = item.cantidad - 1;
          } else
		  if(item.cantidad == 1){
			this.detalles = this.detalles.filter(e => e.id != item.id)
		  }

        
       // this.sumarCantidadProducto();
      },
      onInput (val) {
        this.steps = parseInt(val)
      },
     
    },
  }
</script>

<style scoped>
	.payment-buttons {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
	}
	.selected2 {
		background-color: #28b6aa6b; /* Un color más intenso */
		border-color: #1c837a15; /* Un borde más oscuro para mayor contraste */
	}
	/* Oculta el contenido por defecto */
	#comandacocina {
		display: none;
	}

	/* Muestra el contenido solo en la impresión */
	@media print {
		#comandacocina {
			display: block;
		}
	}


	.payment-button {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100px;
	height: 100px;
	border: none;
	border-radius: 5px;
	font-size: 12px;
	color: #1c837a;
	font-weight: bold;
	text-transform: uppercase;
	cursor: pointer;
	}

	.payment-button i {
	margin-bottom: 10px;
	font-size: 40px;
	}



	.cash {
		background-color: #ddfcf9;
		border: 2px solid #28b6aa; /* Color intenso */
	}

	.payment-button i {
	margin-right: 10px;
	}
	#scroll {
		width: 100%;
		height: 100% ;
		padding: 0px;
	}
	@media print {
		.oculto-impresion,
		.oculto-impresion * {
        display: none !important;
    	}
		#comandacocina {
        display: block !important;
    }
    } 
	.button1 {
		background-color: #424242;
		color: white;
		text-align: center;
		border-radius: 5px;
		width:10vw;
		max-width:100px;
		min-width:70px;
		max-height:100px;
		min-height:50px;
		height:7vw;
		font-size: min(max(9px, 1.2vw), 15px);
		margin: 1px 1px;
		cursor: pointer;
	}
	.button2 {
		background-color: #d13232;
		color: white;
		text-align: center;
		border-radius: 5px;
		width:7vw;
		max-width:90px;
		min-width:70px;
		max-height:90px;
		min-height:50px;
		height:7vw;
		font-size: min(max(9px, 1.2vw), 15px);
		margin: 1px 1px;
		cursor: pointer;
	}
	.btn-group button {
		border: 1px solid green; /* Green border */
		padding: 10px 24px; /* Some padding */
		cursor: pointer; /* Pointer/hand icon */
		width: 50%; /* Set a width if needed */
		display: block; /* Make the buttons appear below each other */
		}

		.btn-group button:not(:last-child) {
		border-bottom: none; /* Prevent double borders */
		}

		/* Add a background color on hover */
		.btn-group button:hover {
		background-color: #3e8e41;
		}
	.ticket {
        width: 220px;
        max-width: 220px;
    }
	h3 {
	color: rgb(85, 85, 85);
	}

	.content {
		max-width: 400px;
		margin: auto;
		}
	.product-button {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 2px;
	width: 90px; /* Ajusta al ancho deseado */
	font-size: 0.8rem; /* Tamaño de fuente */
	border: 1px solid #cccccc; /* Borde gris claro */
	box-shadow: 0px 0px 2px #cccccc; /* Sombra sutil */
	background-color: #f0f0f0; /* Fondo gris para todo el botón */
	overflow: hidden; /* Para asegurar que nada se desborde del botón */
	}

	.product-image-wrapper {
	position: relative;
	width: 100%; /* Utiliza el 100% del ancho de .product-button */
	height: 90px; /* Altura fija para las imágenes */
	background-color: #ffffff; /* Fondo blanco para las imágenes */
	}

	.product-image {
	max-width: 100%; /* Limita el ancho al 100% del contenedor */
	max-height: 100%; /* Limita la altura al 100% del contenedor */
	object-fit: contain; /* Ajusta la imagen dentro del contenedor */
	}

	.product-description {
	width: 100%; /* Utiliza el 100% del ancho de .product-button */
	text-align: center; /* Centra el texto */
	background-color: #f0f0f0; /* Fondo gris para la descripción */
	line-height: 1.2em; /* Altura de línea */
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2; /* Limita a dos líneas */
	overflow: hidden; /* Oculta desbordamiento de texto */
	min-height: 3em; /* Asegura que el espacio para dos líneas de texto siempre esté presente */
	padding: 4px 0; /* Relleno superior e inferior para la descripción */
	box-sizing: border-box; /* Asegura que el padding no aumente el tamaño del div */
	}
	.scroll-container {
	overflow-x: auto;
	white-space: nowrap;
	}


</style>