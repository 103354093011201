<template>
    <div>
		<v-dialog v-model="modeltrazlado" persistent max-width="900" class="primary">
			<v-toolbar  dark color="primary" class="font-weight-light body-1">
				<h4>Traslado de mercadería para  {{nombreReceptor}}</h4>
				
			</v-toolbar>
			{{noProforma}}
			<v-card>
			<v-container fluid>
				<v-col cols="12" sm="12">
				<v-row>
				<v-col class="mt-2" cols="12" sm="6">
					<v-select @change="BuscarInventarioMasivoOrigen()" filled v-model="localidadorigen" :rules="[v => !!v || 'El origen es requerido']" :items="Localidades" label="Localidad Origen"></v-select>
				</v-col>
				<v-col class="mt-2" cols="12" sm="6">
					<v-select @change="BuscarInventarioMasivoDestino()" filled v-model="localidaddestino" :rules="[v => !!v || 'El destino es requerido']" :items="Localidades" label="Localidad Destino"></v-select>
				</v-col>
					<v-col cols="12" sm="12">
						<v-flex xs12 sm12 md12 lg12 xl12>
							<v-data-table
									:headers="cabeceraDetalles"
									single-select
									:items="detalles2"
									class="elevation-1"
									item-class="red"
									sort-by="id"
									sort-desc=""
									v-model="selected">
								 <template v-slot:item.cantidadIngreso="{ item }">
									<v-edit-dialog
									:return-value.sync="item.cantidadIngreso"
									large
									@save="updateAlerta(item)"
									>
									{{ item.cantidadIngreso }}
									<template v-slot:input>
										<v-text-field v-model="item.cantidadIngreso" type="number" label="cantidad"/>
									</template>
									</v-edit-dialog>
								</template>
								<template slot="no-data">
									<h3>No hay artículos agregados al detalle.</h3>
								</template> 
							</v-data-table>
						</v-flex>
					<v-col class="mt-2" cols="12" sm="12">
					<v-text-field v-model="detalle"  label="Detalle, comentario o justificación"></v-text-field>
					</v-col>
					<v-switch v-model="switch1" label="Aprobado automatico"></v-switch>
					<v-btn :disabled="!localidaddestino" color="primary" :loading="modelCarga" class="mr-4" style="top: 10px;" @click="generarTrazlado()">
					Generar Proceso
					</v-btn>
					<v-btn color="error" id="espacioBoton" @click="modeltrazlado = false">
					Cancelar
					</v-btn> 
					<v-spacer/>
					<v-btn  color="primary" :loading="modelCarga" class="mt-4" @click="PrintDetails()">
					<v-icon>mdi-printer </v-icon>
					</v-btn>
					</v-col>
				</v-row>
				</v-col>
			</v-container>
			</v-card>
		</v-dialog>
        <v-dialog v-model="comprobanteModal" fullscreen>
            <v-card class="pa-1">
                
					<v-btn class="mx-2" color="accent" dark="" @click="Impresiondirecta()"><v-icon>mdi-printer</v-icon> POS</v-btn>
					<v-btn class="mx-2" color="accent" dark="" @click="imprimirA4()"><v-icon>mdi-printer</v-icon> A4- PDF</v-btn>
                    <a v-if="latitud != null" :href="`https://www.google.com/maps/search/?api=1&query=${latitud},${longitud}`" target="_blank">
						<i style="font-size: 34px;" class="mdi mdi-google-maps"></i> 
					</a>
					<a v-if="latitud != null" :href="`https://waze.com/ul?ll=${latitud},${longitud}&navigate=yes`" target="_blank">
						<i style="font-size: 34px;" class="mdi mdi-waze"></i>
					</a>
					<v-btn @click="OcultarComprobante()" color="error" flat><v-icon> mdi-exit-to-app </v-icon></v-btn>
                    <div id="factura">
                        <html lang="en">
							<head>
							<meta charset="utf-8" />
							<meta http-equiv="X-UA-Compatible" content="IE=edge">
							<meta name="viewport" content="width=device-width, initial-scale=1">
							<link href="images/favicon.png" rel="icon" />
							<title>Proforma {{noFactura}} - {{$store.state.usuario.localidad}} </title>
							<meta name="author" content="harnishdesign.net">

							<!-- Web Fonts
							======================= -->
							<link rel='stylesheet' href='https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900' type='text/css'>

							<!-- Stylesheet
							======================= -->
							<link rel="stylesheet" type="text/css" href="https://www.noah.cr/FacturaCSS/vendor/bootstrap/css/bootstrap.min.css"/>
							<link rel="stylesheet" type="text/css" href="https://www.noah.cr/FacturaCSS/vendor/font-awesome/css/all.min.css"/>
							<link rel="stylesheet" type="text/css" href="https://www.noah.cr/FacturaCSS/css/stylesheet.css"/>
							</head>
							<body>
							<!-- Container -->
							<div class="container-fluid invoice-container">
							
							<!-- Main Content -->
							<main>
							<div class="table-responsive">
								<table >
									<thead >
										<tr>
											<td class="col-5"><img height="120" :src="Logo3"></td>
											<td class="col-4 text-center"> <strong> {{$store.state.usuario.localidad}} </strong> <br />
											{{localidadencabezado[0].razonSocial}}<br />
											{{localidadencabezado[0].dirreccion}}<br />
											{{localidadencabezado[0].cedulaJuridica}}<br />
											{{localidadencabezado[0].telefono}}<br />
											{{localidadencabezado[0].correo}}<br /> </td>
											<td class="col-1 text-end"><h4 class="text-7 mb-0"><strong> Proforma: {{noFactura}} </strong></h4></td>
										</tr>
									</thead>
								</table>
							</div>
							<hr>
							<table >
								<tbody>
									<tr>
										<td class="col-2 text-start text-1"><address>
											
											<strong> Cliente:</strong> {{nombreReceptor}} <br>
											<strong> Cédula: </strong> {{cedulaReceptor}}<br>
											<strong> Teléfono: </strong> {{telefonoReceptor}}<br>
											<strong> Correo: </strong> {{correoReceptor}} <br>
											
											</address>
										</td>
										<td class="col-2 text-end text-1"><address>
											<strong> Fecha:</strong> {{fecha}} <br>
											<strong> Vencimiento:</strong> {{Vencimiento}}<br />
											<strong> Vendedor:</strong> {{vendedor}}<br />
											<strong> Generado por:</strong> {{$store.state.usuario.nombre}}
											</address>
										</td>
									</tr>
								</tbody>
							</table>
								
							<div class="card">
								<div class="card-body p-0">
								<div class="table-responsive">
									<table class="table mb-0">
									<thead class="card-header">
										<tr>
											<td class="col-3"><strong>Código</strong></td>
											<td class="col-6"><strong>Descripción</strong></td>
											<td class="col-1 text-center"><strong>Cant</strong></td>
											<td class="col-1 text-center"><strong>Precio</strong></td>
											<td class="col-1 text-end"><strong>Desc</strong></td>
											<td class="col-1 text-end"><strong>Total</strong></td>
										</tr>
									</thead>
									<tbody>
										<tr v-for="det in detalles" :key="det.descripcion">
											<td class="col-3 text-1"> {{ det.codigoProducto }} </td>
											<td class="col-6 text-0">
												<b>{{ det.descripcion }} </b><br>
												<p style="font-size:8px;">{{ det.detalleLinea }}</p>
											</td>
											<td class="col-1 text-center">{{ det.cantidad }}</td>
											<td class="col-1 text-center">{{ det.precioFinal | formatNumber}}</td>
											<td class="col-1 text-end">%{{ det.descuentoLinea }}</td>
											<td class="col-1 text-end">{{ det.precioFinal * det.cantidad | formatNumber}} </td>
										</tr>
									</tbody>
									<tfoot class="card-footer">
										<tr>
										<td colspan="2" class="text-0"> 
											
												
												<qrcode-vue :value="value" :size="size" level="H"></qrcode-vue>
										</td>
										<td class="text-0"> Moneda: {{monedaPago}}</td>
										<td colspan="2" class="text-2 text-end"><strong>Sub Total: <br> Descuento: <br> Iva: <br> Total: </strong></td>
										<td class="text-2 text-end">
											{{ subTotal | formatNumber }} <br>
											{{ totalDescuentos | formatNumber }} <br>
											{{ iv | formatNumber }} <br>
											{{ total | formatNumber }}
										</td>
										</tr>
									</tfoot>
									</table>
								</div>
								</div>
							</div>
							</main>
							<!-- Footer -->
							<footer class="text-center text-1">
								<p><b>Factura Proforma!</b><br>
									<v-edit-dialog
											:return-value.sync="comentario"
											@save="updateCliente()"
											@close="updateCliente()"
											>
											Detalle:  {{comentario}}
											<template v-slot:input>
												<v-text-field
												onfocus="this.select();"
												v-model="comentario"
												label="Detalle de la reserva"
												counter
												></v-text-field>
											</template>
										</v-edit-dialog> <p style="white-space: pre-line;"><b> Cuentas Bancarias:</b>  <br>  {{Cuentas}}  <br> Facturas Disponibles en {{value}}</p>
							<div><strong>Nota :</strong> {{comentario}}</div>
							{{pieDocumento}}
							</footer>
							</div>
							</body>
						</html>
                    </div>
                    
                
            </v-card>
        </v-dialog>
		<v-dialog v-model="dialogPago" width="500">

			<template >
				<v-card title="Dialog">
				<v-card-text>Request Id: {{RequestId}} </v-card-text>
				<div>
					<v-simple-table>
						<thead>
							<tr>
								<th class="order-id">Estado</th>
								<th class="order-date">Razón</th>
								<th class="order-status">Mensaje</th>
								<th class="order-price">Fecha</th>
							</tr>
						</thead>
						<tbody>
							<tr
							>
								<td >{{ RequestIdData.status }} </td>
								<td>{{ RequestIdData.reason }}</td>
								<td>{{ RequestIdData.message }}</td>
								<td>{{ RequestIdData.date }}</td>
							</tr>
						</tbody>
					</v-simple-table>
				</div>

				<v-card-actions>
					<v-spacer></v-spacer>

					<v-btn
					text="Close Dialog"
					@click="isActive.value = false"
					></v-btn>
				</v-card-actions>
				</v-card>
			</template>
		</v-dialog>
        <v-data-table
            :headers="headers"
            :items="usuarios"
            :search="search"
            class="elevation-1 "
			dense
            >
            <template v-slot:item.estado="props">
					<v-edit-dialog
					:return-value.sync="props.item.categoria"
					@save="ActualizarEstado(props.item)"
					large
					>
					 <v-chip :color="getColor(props.item.estado)" :text-color="getColorText(props.item.estado)">• {{ props.item.estado }}</v-chip> <br>
					<template v-slot:input>
						<v-select  v-model="props.item.estado" :items="estadosProforma" label="Estado"/>
					</template>
					</v-edit-dialog>
			</template>
			<template v-slot:item.vendedor="props">
					<v-edit-dialog
					:return-value.sync="props.item.vendedor"
					@save="ActualizarVendedor(props.item)"
					large
					>
						{{props.item.vendedor}}
					<template v-slot:input>
						<v-select  v-model="props.item.vendedor" :items="Vendedores" label="Estado"/>
					</template>
					</v-edit-dialog>
			</template>
            <template v-slot:top>
                <v-row class="pa-2">
                    <v-col cols="12" sm="3">
                        <v-card-actions class="rounded-xl light-blue lighten-5 mx-5">
                            <v-btn fab="" color="error" class="mx-1" @click="crearPDF()"> <v-icon large="">mdi-file-pdf-box </v-icon></v-btn>
                            
                                <vue-excel-xlsx
                                    :data="usuarios"
                                    :columns="columns"
                                    :filename="'Reporte de Proformas '+$store.state.usuario.localidad"
                                    :sheetname="'Ventas'"
                                    >
                                    <v-btn fab="" color="success">  <v-icon large="">mdi-file-excel </v-icon> </v-btn>
                                </vue-excel-xlsx> 
                            <b class="primary--text mx-3" style="font-size: 30px;">Proformas</b>
                        </v-card-actions>
                    </v-col>
                    <v-col cols="12" sm="4">    
                        <v-text-field v-model="search"  append-icon="mdi-magnify" hint="Tambien puede digitar el numero de proforma y presionar enter para obtener una proforma fuera del rango mostrandose"
                         label="Buscar" hide-details="" @keyup.enter="ListarNoProforma()" persistent-hint
                        />
						<v-select v-if="!esVendedor" v-model="localidad" :items="Localidades" @change="listarLocalidadAsociada()"
                         label="Localidad" hide-details=""
                        />
                    </v-col>
                    <v-col cols="12" sm="2">
                        <v-text-field  v-model="start" type="date" label="Fecha inicial"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2">
                        <v-text-field  v-model="end"  type="date" label="Fecha final"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="1">
                        
                        <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                               <v-btn  color="info" @click="listarFechas()" v-bind="attrs"
                                v-on="on" fab > <v-icon fab> mdi-cloud-search </v-icon> </v-btn>
                            </template>
                            <span>Selecciona un rango de fecha y toca este botón para realizar la busqueda filtrada entre 2 fechas</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                               <v-btn small dark color="red" v-bind="attrs"
                                v-on="on" href="https://youtu.be/4zpaZKf3lDw" fab > <v-icon fab> mdi-youtube </v-icon> </v-btn>
                            </template>
                            <span>Da click para ver un video tutorial de ayuda en YouTube</span>
                        </v-tooltip>
                 </v-col>
                </v-row>
               
            </template>
            <template v-slot:item.action="{ item }">
                <v-menu top :offset-y="offset">
                    <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        small
                        text
						fab
                        v-bind="attrs"
                        v-on="on"
                        
                    > <v-icon color="info">mdi-dots-horizontal</v-icon>
                    </v-btn>
                    </template>
                    <v-list>
					<v-list-item @click="MostrarComprobante(item)">
                        <v-list-item-title><v-icon >mdi-file-document</v-icon> 
                                Ver Proforma
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="FacturarProforma(item)">
                        <v-list-item-title >  <v-icon > mdi-rocket-launch </v-icon>  Facturar o Editar</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="esLocalidad.includes('King Vape')" @click="ConsultarId(item.noProforma)">
                        <v-list-item-title > <v-icon > mdi-cash-refund </v-icon>Consulta Estado Pago</v-list-item-title>
                    </v-list-item>
					<v-list-item @click="OpenLink(item)">
                        <v-list-item-title > <v-icon > mdi-cash-refund </v-icon>Ventana cliente</v-list-item-title>
                    </v-list-item>
					<v-list-item @click="AceptarProforma(item)">
                        <v-list-item-title > <v-icon > mdi-check </v-icon>Aceptar</v-list-item-title>
                    </v-list-item>
					<v-list-item @click="TrasladoMercaderia(item)">
                        <v-list-item-title > <v-icon > mdi-check </v-icon>Traslado Mercadería</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="DeleteProforma(item)" >
                        <v-list-item-title >
                        <v-icon > mdi-delete </v-icon> Eliminar Proforma</v-list-item-title>
                    </v-list-item>
                    </v-list>
                </v-menu>
            <template >
            </template>
            </template>

            <template v-slot:no-data>
              
            </template>
        </v-data-table>
        <template class="pa-3">
            <v-snackbar
                    v-model="snackbar" top vertical="" :timeout="5000"
                    >
                    {{ Mensaje }}
                    <v-btn
                    color="info"
                    text
                    @click="snackbar = false"
                    >
                    Cerrar
                    </v-btn>
            </v-snackbar>
            
            <v-row v-if="!esVendedor || esFacturador" flat color="white"  class="pa-2">
                <v-row flat color="white"  class="rounded-xl light-blue lighten-5 mx-5">
                <v-card-text>
                    <b> Total Proformas:</b> {{MonedaFiltrar}} {{Total=(calcularTotal)}} <br>
                    <b> Total Proformas Facturadas:</b> {{MonedaFiltrar}} {{calcularFacturadas}} <br>
                    <b> Total Proformas Pendientes:</b> {{MonedaFiltrar}} {{calcularPendientes}} <br>
                    <b> Total Proformas Aceptadas:</b> {{MonedaFiltrar}} {{Aceptadas=(calcularAceptadas)}} <br>
                    
                 
                </v-card-text>
                </v-row>
                <v-col cols="12" sm="6">
                    <canvas id="myChart" class="rounded-xl light-blue lighten-5 mx-5">
                        
                    </canvas>
                </v-col>
            </v-row>
        </template>
    </div>
</template>


<script>
import axios from 'axios'
import Chart from 'chart.js'
import jsPDF from 'jspdf'
import VueExcelXlsx from "vue-excel-xlsx";
import Vue from "vue";
import html2canvas from 'html2canvas';
import QrcodeVue from 'qrcode.vue'
import Axios from 'axios';
import moment from 'moment';

Vue.use(VueExcelXlsx);
  export default {
	filters: {
		formatNumber(value) {
			const formatter = new Intl.NumberFormat('en-US', {
			minimumFractionDigits: 0, // Mínimo número de dígitos fraccionarios
			maximumFractionDigits: 2, // Máximo número de dígitos fraccionarios
			});
			return formatter.format(value);
		}
	},
    data: () => ({
    mesesValores: null,
    nombreMeses: [],
    totalMeses: [],
    value: 'https://www.noah.cr',
    size: 100,
    dialog: false,
    Aceptadas: 0,
	localidadorigen: '',
	localidaddestino : '',
    esIVI: false,
    search: '',
    ClienteSeleccionado : '',
	modelCarga:false,
	vencimiento: new Date().toISOString().substr(0, 10),
    today: new Date().toISOString().substr(0, 10),
    focus: new Date().toISOString().substr(0, 10),
    headers: [
	{ text: 'Opciones', value: 'action', sortable: false },
    { text: 'Proforma', value: 'noProforma' },
    { text: 'Estado', value: 'estado' },
	{ text: 'Estado Pago', value: 'estadoPago' },
    { text: 'Fecha', value: 'fecha' },
    { text: 'Total', value: 'total' },
    { text: 'Vendedor', value: 'vendedor' },
	{ text: 'Telefono', value: 'contacto' },
    { text: 'Vencimiento', value: 'vencimiento' },
    { text: 'Cliente', value: 'cliente' },
	{ text: 'Detalle', value: 'detalle' },
    ],
    columns : [
            { label: "Proforma",field: "noProforma", },
            { label: "Estado",  field: "estado", },
            { label: "Fecha",field: "fecha", },
            { label: "Vendedor",field: "vendedor", },
            { label: "Total",field: "total", },
            { label: "Cliente",field: "cliente", },
			{ label: "Detalle",field: "detalle", }, 
			{ label: "Telefono",field: "contacto", }, 
        ],
    cabeceraDetalles: [
		{ text: 'Código', value: 'idarticulo', sortable: false  },
		{ text: 'Descripción', value: 'descripcion', sortable: false  },
		{ text: 'Precio', value: 'precio', sortable: false  },
		{ text: 'Stock Origien', value: 'cantidad', sortable: false  },
		{ text: 'Stock Destino', value: 'cantidadDestino', sortable: false  },
		{ text: 'Cantidad Ingresar', value: 'cantidadIngreso', sortable: false  },
      ],
    tipoDocumentos: [
        'FISICA',
        'JURIDICA',
        'EXTRANJERO',
    ],
    PagosFiltrados: "",
    Monedas: ["USD","CRC"],
	estadosProforma: [{text:"Aceptado",value:2},{text:"Rechazado",value:3},
	{text:"1 Seguimiento",value:4},{text:"2 Seguimienito",value:5},{text:"3 seguimiento",value:6},],
    TiposPago:["TARJETA","DEPOSITO","EFECTIVO","TRANSFERENCIA"],

    MonedaFiltrar : "",
    tipoCedulaReceptor: '',
	search: '',
	detalles2:[],
    emailRules: [
    v => !!v || 'El correo electrónico es requerido',
    v => /.+@.+\..+/.test(v) || 'El correo electrónico es requerido debe ser válido',],
    cedulaRules: [value => !!value || 'La cédula es requerida'
    , value => (value && value.length >= 9) || 'Debe ingresar 9 carácteres como mínimo',],
    nameRules: [
    value => !!value || 'El nombre es requerido',
    value => (value && value.length >= 10) || 'Debe ingresar 10 carácteres como mínimo',
    ],
    celRules: [
        value => !!value || 'El número es requerido',
        value => (value && value.length >= 8) || 'Debe ingresar 8 carácteres como mínimo',
        ],
    justificacionRules: [
        value => !!value || 'La justificación es requerida',
        value => (value && value.length >= 7) || 'Debe ingresar 7 carácteres como mínimo',
        ],
    editedIndex: -1,
    id: '',
    idrol:'',
    nofactura2: '/ticket/2',
    comprobanteModal: 0,
    justificacionNota: ["Ajuste de precio precio" ,"Saldo incobrable"," Pronto de pago","Error en cobro"],
    productosDatos:[],
    MensajeRespuesta: '',
    ClaveComprobante: '',
	localidad:"",
	offset: null,
    EstadoNC: 0,
    ModalNotaCredito: false,
	idMovimiento: '',
	switch1:false,
    snackbar: true,
    Mensaje: "",
    correo2: '',
	Vencimiento:null,
    VendedorSeleccionado: '',
	subTotal: 0,
    total: 0,
    justificacion: '',
	totalDescuentos: 0,
	modeltrazlado: false,
    medioDePago : '',
    detalles: [],
	detalle:'',
    localidadencabezado:[],
    NombreLocalidad:'',
    CedulaLocalidad:'',
    Datos: [],
    DireccionLocalidad:'',
    Logo3: '',
    TelefonoLocalidad:'',
	Localidades:[],
	noFactura :'',
    fecha: '',
    hora: '',
    nombreReceptor: '',
	dialogPago: false,
    cedulaReceptor:'',
    direccion:'',
    monedaPago: "CRC",
    telefonoReceptor:'',
    correoReceptor:'',
    linkFacturar: 'facturar',
    vendedor: '',
    subtotal: 0,
	RequestIdData:'',
	OrderDetails:null,
    comentario: '',
    grabado:0 ,
	excento: 0,
	ShowNoFact: false,
	Cuentas: '',
    Logo2 : '',
	RequestId:'',
    iv: 0.0,
    clientes: [],
	pieDocumento:"",
    start : '',
    end : '',
    tipo_documento: '',
    Vendedores: [],
	latitud: 0,
	longitud : 0,
	usuarios:[],    
	login: '82e9edd74433ec524d12e6d06a1e2bba',
	secretKey : '9OiP1Vbr42gDC16a',
	modoPDV: 0,
    desserts: [],
    editedIndex: -1,
	dias:'',
    descuento: 0,
    
    }),
    components: {
      QrcodeVue,
    },

    computed: {
		formTitle () {
				return this.editedIndex === -1 ? 'Nuevo usuario' : 'Actualizar usuario'
		},
		esAdministrador(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='Administrador';
		},
		esSupervisor(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='Supervisor';
		},
		esVendedor(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='Vendedor';
		},
		esBodega(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='Bodegas';
		},
		esLocalidad(){
			return this.$store.state.usuario.localidad;
		},
    	calcularTotal:function(){
			var resultado=0.0;
			for(var i=0;i<this.usuarios.length;i++){
					resultado=resultado+(this.usuarios[i].total * 1);
			}
			
			return resultado.toFixed(2);
		},
		calcularPendientes:function(){
			var resultado=0.0;
			for(var i=0;i<this.usuarios.length;i++){
				if(this.usuarios[i].estado == 'PENDIENTE')
					resultado=resultado+(this.usuarios[i].total * 1);
			}
			
			return resultado.toFixed(2);
		},
		calcularAceptadas:function(){
			var resultado=0.0;
			for(var i=0;i<this.usuarios.length;i++){
				if(this.usuarios[i].estado == 'Aceptado')
					resultado=resultado+(this.usuarios[i].total * 1);
			}
			
			return resultado.toFixed(2);
		},
		calcularFacturadas:function(){
			var resultado=0.0;
			for(var i=0;i<this.usuarios.length;i++){
				if(this.usuarios[i].estado == 'Facturado')
					resultado=resultado+(this.usuarios[i].total * 1);
			}
			
			return resultado.toFixed(2);
		},
		calcularImpuestoTotal:function(){
		var resultado=0.0;
		var test= false;
			for(var i=0;i<this.detalles.length;i++){
			if(this.detalles[i].Ivi == false && this.detalles[i].gravado == 'S'){
				resultado=resultado+(this.detalles[i].precio * this.detalles[i].cantidad);
				return resultado.toFixed(2);
			}    
				if(this.detalles[i].gravado == 'S' && this.detalles[i].Ivi == true)
				{
				if(this.detalles[i].valorImpuesto == 13)
				{
				resultado+=((this.detalles[i].precio / 1.13) * this.detalles[i].cantidad);
				} else
				if(this.detalles[i].valorImpuesto == 1)
				{
					resultado+=((this.detalles[i].precio / 1.01) * this.detalles[i].cantidad);
				} else
				if(this.detalles[i].valorImpuesto == 2)
				{
					resultado+=((this.detalles[i].precio / 1.02) * this.detalles[i].cantidad);
				} else
				if(this.detalles[i].valorImpuesto == 4)
				{
					resultado+=((this.detalles[i].precio / 1.04) * this.detalles[i].cantidad);
				}
				}
			}
		return resultado.toFixed(2);
		},
    },
    

    watch: {
      dialog (val) {
        val || this.close()
      },
      
    },

    created () {
		this.listar();
		this.listarLocalidad();
		this.listarAsociadas();
		this.selectVendedores();
    },

    methods: {
		// Función para crear el objeto auth
		getRowClass(item) {
			return item.alerta ? 'row-alerta' : '';
		},
		Impresiondirecta(){
			
			var contenidoImpresion = "\x1B\x40"; // Inicializar impresora en modo ESC/POS
			// Logo con enlace
			const logoURL = this.localidadencabezado[0].imagen; // Reemplaza con la URL de tu logo
			const logoHTML = `<a href="${logoURL}"><img style="max-width: 180px; max-height: 180px;" src="${logoURL}" alt="Logo" /></a>`;

			// Función para ajustar el texto a los 80 mm de ancho y establecer el tamaño de fuente
			function ajustarTexto(texto, anchoMaximo, tamañoFuente) {
				// Convierte "texto" en una cadena de texto usando toString()
				texto = texto.toString();

				var espacios = anchoMaximo - texto.length;
				var estilo = `font-size: ${tamañoFuente}px;`;
				
				if (espacios >= 0) {
					return `<span style="${estilo}">${texto + " ".repeat(espacios)}</span>`;
				} else {
					var primeraLinea = texto.slice(0, anchoMaximo);
					var segundaLinea = texto.slice(anchoMaximo, anchoMaximo * 2);
					
					return `<span style="${estilo}">${primeraLinea}</span><br><span style="${estilo}">${segundaLinea}</span>`;
				}
			}


			// Encabezado
			// Encabezado
				contenidoImpresion += `
				<table style="width: 100%;">
					<tr>
					<td style="width: 50%;">
						${logoHTML}
					</td>
					<td style="width: 50%;">
						${ajustarTexto(this.$store.state.usuario.localidad, 80, 25)}<br>
						${ajustarTexto(this.localidadencabezado[0].razonSocial, 80, 14)}<br>
						${ajustarTexto(this.localidadencabezado[0].dirreccion, 80, 14)}<br>
						${ajustarTexto(this.localidadencabezado[0].nombre, 80, 14)}<br>
						${ajustarTexto(this.localidadencabezado[0].cedulaJuridica, 80, 14)}<br>
						${ajustarTexto(this.localidadencabezado[0].telefono, 80, 14)}<br>
						${ajustarTexto(this.localidadencabezado[0].correo, 80, 14)}<br>
					</td>
					</tr>
				</table>
				`;

			// Línea horizontal
			contenidoImpresion += ajustarTexto("Fecha: "+this.fecha, 80,16) + "\n";
			contenidoImpresion += ajustarTexto("Vencimiento: "+this.Vencimiento, 80,16) + "\n";
			contenidoImpresion += ajustarTexto("No Profoma: "+this.noFactura, 80,16) + "\n";
			contenidoImpresion += ajustarTexto("Cliente: "+this.nombreReceptor, 80,16) + "\n";
			contenidoImpresion += ajustarTexto("Cédula: "+this.cedulaReceptor, 80,16) + "\n";
			contenidoImpresion += ajustarTexto("Tel: "+this.telefonoReceptor, 80,16) + "\n";
			contenidoImpresion += ajustarTexto("Correo: "+this.correoReceptor, 80,16) + "\n";
			contenidoImpresion += ajustarTexto("Vendedor: "+this.vendedor, 80,16) + "\n";
			contenidoImpresion += "-".repeat(80) + "\n";
			

			// Encabezado de la tabla de productos
			
			contenidoImpresion += ajustarTexto("Cant", 5);
			contenidoImpresion += ajustarTexto("Nombre", 45);
			contenidoImpresion += ajustarTexto("Precio", 30); // Ancho máximo ajustado para el campo precio
			contenidoImpresion += "\n";
			contenidoImpresion += "-".repeat(80) + "\n";

			// Filas de la tabla
			this.detalles.forEach(function (producto) {
			contenidoImpresion += ajustarTexto(producto.cantidad.toString(), 5, 16);
			contenidoImpresion += ajustarTexto(producto.descripcion, 35, 16);
			contenidoImpresion += ajustarTexto(producto.precioFinal.toString(), 40, 16); // Ajuste también el campo precio
			contenidoImpresion += "\n";
			});
			contenidoImpresion += "-".repeat(80) + "\n";
			// Línea horizontal


			// Encabezado de la tabla totales
			contenidoImpresion += ajustarTexto("SubTotal", 10,16);
			contenidoImpresion += ajustarTexto("Descuento", 10,16);
			contenidoImpresion += ajustarTexto("   Total", 10,16);
			contenidoImpresion += ajustarTexto("Total", 10,16);
			contenidoImpresion += "\n";

			// Valores de totales
			contenidoImpresion += ajustarTexto(this.subTotal, 10,16);
			contenidoImpresion += ajustarTexto(this.iv, 10,16);
			contenidoImpresion += ajustarTexto(this.iv, 10,20);
			contenidoImpresion += ajustarTexto(this.total, 50,16);
			contenidoImpresion += "\n";

			contenidoImpresion += "-".repeat(80) + "\n";
			// Píe Pagina
			contenidoImpresion += ajustarTexto("Detalle: "+this.comentario, 80,16) + "\n";
			contenidoImpresion += ajustarTexto("Este documento es solo una proforma, no tiene valor legal",80,14)+"\n";


			contenidoImpresion += "\x1D\x56\x01"; // Corte de papel

			document.write('<pre>' + contenidoImpresion + '</pre>');
			setTimeout(() => {
				
				window.print();
				setTimeout(() => {
				
				window.print();
					window.location.reload();
				}, 1000);
				//window.location.reload();
			}, 1000);
		},
		TrasladoMercaderia(item){
			this.modeltrazlado = true;
			let me = this;
			this.nombreReceptor= item.cliente;
			this.noFactura = item.noProforma;
			this.localidaddestino = item.cliente;
			this.detalles2 = [];
			this.localidadorigen = this.localidad;
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			let array = [];
			axios.get('api/Proformas/ListarDatosFactura/'+item.noProforma+'/'+this.localidad+'',configuracion).then(function(response){
				//console.log(response);
				array=response.data;
				for(var i = 0; i < array.length; ++i)
				{
					me.detalles2.push(
					{
					id: array[i].idProforma,
					idarticulo:array[i].codigoProducto,
					descripcion:array[i].descripcion,
					cantidadDestino:0,
					cantidadIngreso: array[i].cantidad,
					precio:array[i].precioFinal,
					inv: 10,
					localidad: me.$store.state.usuario.localidad,
					cantidad: 0,

					}
					);
					
				}
				me.BuscarInventarioMasivoOrigen();
				me.BuscarInventarioMasivoDestino();
			}).catch(function(error){
				console.log(error);
			});

		},
		async BuscarInventarioMasivoOrigen() {
			let codigosProductos = this.detalles2.map(item => item.idarticulo);
			let me = this;
			this.modelCarga = true;
			let header = { "Authorization": "Bearer " + this.$store.state.token };
			let configuracion = { headers: header };
			await axios.post('api/ProductosWebs/ConsultaStockMasivo', { codigos:codigosProductos, localidad: this.localidadorigen }, configuracion)
				.then(function(response) {
					me.AsignarInventarioMasivoOrigen(response.data);
					me.modelCarga = false;
				})
				.catch(function(error) {
					me.modelCarga = false;
					// Manejo de error
				});
		},
		AsignarInventarioMasivoOrigen(datosInventario) {
			// Itera sobre cada detalle en tus proformas
			this.detalles2.forEach(detalle => {
				// Encuentra el objeto de inventario correspondiente al producto en el detalle
				// Asegúrate de que la propiedad que comparas sea la correcta
				let inventarioProducto = datosInventario.find(item => item.codigoProducto === detalle.idarticulo);

				// Si se encuentra el inventario, asigna el stock al detalle
				if (inventarioProducto) {
				detalle.cantidad = inventarioProducto.stock;
				detalle.alerta = detalle.cantidadIngreso > inventarioProducto.stock; // Asigna alerta a true si cantidad es mayor que stock
				} else {
				// Si no se encuentra, asigna un stock de 0 o el valor que desees
				detalle.inv = 0;
				detalle.alerta = true; // Asigna alerta a true ya que no hay stock
				}
			});
		},
		updateAlerta(item) {
			if (item.cantidadIngreso <= item.cantidad) {
				item.alerta = false; // Asegúrate de que alerta se pone a false si la cantidad es suficiente
			} else {
				item.alerta = true; // Mantén alerta en true si la cantidad no es suficiente
			}
		},
		async BuscarInventarioMasivoDestino() {
			let codigosProductos = this.detalles2.map(item => item.idarticulo);
			let me = this;
			this.modelCarga = true;
			let header = { "Authorization": "Bearer " + this.$store.state.token };
			let configuracion = { headers: header };
			await axios.post('api/ProductosWebs/ConsultaStockMasivo', { codigos:codigosProductos, localidad: this.localidaddestino }, configuracion)
				.then(function(response) {
					me.AsignarInventarioMasivoDestino(response.data);
					me.modelCarga = false;
				})
				.catch(function(error) {
					me.modelCarga = false;
					// Manejo de error
				});
		},
		AsignarInventarioMasivoDestino(datosInventario) {
			// Itera sobre cada detalle en tus proformas
			this.detalles2.forEach(detalle => {
				// Encuentra el objeto de inventario correspondiente al producto en el detalle
				// Asegúrate de que la propiedad que comparas sea la correcta
				let inventarioProducto = datosInventario.find(item => item.codigoProducto === detalle.idarticulo);

				// Si se encuentra el inventario, asigna el stock al detalle
				if (inventarioProducto) {
					detalle.cantidadDestino = inventarioProducto.stock; // Asegúrate de que estés usando el nombre correcto de la propiedad, aquí parece ser 'stock', no 'Stock'
				} else {
					// Si no se encuentra, asigna un stock de 0 o el valor que desees
					detalle.inv = 0;
				}
			});
		},
		createAuth(Proforma) {
			var nonce = this.generateNonce();
			var seed = this.generateSeed();
			var tranKey = this.generateTranKey(nonce, seed);
			var auth = {
				login: this.login,
				tranKey: tranKey,
				nonce: btoa(nonce),
				seed: seed
			};
			const self = this;
			const data = {
				auth: auth,
			};

			axios.post('https://checkout-test.placetopay.com/api/session/'+this.RequestId, data)
			.then(response => {
				const processUrl = response.data;
				this.RequestIdData = processUrl.status; 
				this.OrderDetails = processUrl.payment; 
				this.dialogPago = true;
				this.PostEstado(Proforma);
				this.listar();
			})
			.catch(error => {
				console.error(error);
				self.isLoading = false;
			});
			
		},
		preventNonNumericInput(event) {
			const allowedChars = /[0-9]/;
			if (!event.key.match(allowedChars)) {
				event.preventDefault();
			}
		},
		async generarTrazlado() {
			if (this.localidaddestino == '') {
				this.Mensaje = 'Seleccione la localidad Destino';
				this.snackbar = true;
				return;
			}

			// Verificar si algún detalle tiene la alerta en true
			let alertaDetalle = this.detalles2.find(detalle => detalle.alerta === true);

			if (alertaDetalle) {
				alert(`No puede trasladar el producto ${alertaDetalle.descripcion} porque no tiene suficiente stock. Por favor, ajuste la cantidad para continuar.`);
				return;
			}

			let barcodepreview = Date.now();
			let idsa = barcodepreview.toString();
			this.codigoProducto = idsa.substring(5, 12);
			this.barcode = idsa.substring(5, 12);
			this.idMovimiento = idsa.substring(5, 12);
			this.modelCarga = true;

			let header = { "Authorization": "Bearer " + this.$store.state.token };
			let configuracion = { headers: header };

			try {
				await axios.post('api/ProductosWebs/TrazladoMercaderiaSalida', {
				'Usuario': this.$store.state.usuario.nombre,
				'localidad': this.localidadorigen,
				'detalles': this.detalles2,
				'detalle': this.detalle,
				'Aprobado': true,
				'TipoIngreso': this.localidaddestino,
				'NoFactura': this.idMovimiento,
				'Proforma': this.noFactura,
				}, configuracion);
				
				this.generarTrazladoIngreso();
			} catch (error) {
				this.modelCarga = false;
				alert(`Lo sentimos, ha ocurrido un error haciendo la salida de: ${this.localidadorigen} o las ventas emergentes están bloqueadas. Por favor verifique el movimiento o notifique a su equipo de soporte 4070-1889. Error: ${error}`);
			}
			},
		async generarTrazladoIngreso(){
			this.modelCarga = true;
			var me = this;
			var momentoActual = new Date() 
			var y = momentoActual.getFullYear();
			var m = momentoActual.getMonth() + 1;
			var d = momentoActual.getDate();
			var hora = momentoActual.getHours() 
			var minuto = momentoActual.getMinutes() 
			var segundo = momentoActual.getSeconds() 
			this.Fecha = m + "/" + d + "/" + y+"  "+hora+":"+minuto+":"+segundo;
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			await axios.post('api/ProductosWebs/TrazladoMercaderiaIngreso',{
				'Usuario': this.$store.state.usuario.nombre,
				'localidad': this.localidaddestino,
				'detalles': me.detalles2,
				'detalle': me.detalle,
				'Aprobado': me.switch1,
				'TipoIngreso': me.localidadorigen,
				'NoFactura': me.idMovimiento,
						
				},configuracion).then(function (response) {
					me.snackbar = true;
					me.Mensaje = "Traslado de "+me.localidadorigen +" hacia "+me.localidaddestino+" realizado de forma exitosa";
					me.modeltrazlado = false;
					me.modelCarga = false;
					})
					.catch(function (error) {
						me.modelCarga = false;
						alert(`Lo sentimos ha ocurrido un error trazladando hacia `+me.localidaddestino+` , notifique a su equipo de soporte 4070-1889`+error);  
				})   
			me.PrintDetails2();
			me.detalles = [];
		},
		PrintDetails(){
			var products = "";
			for(var i = 0; i < this.detalles2.length; ++i)
				{
					products += `<tr>
								<td class="col-3">`+this.detalles2[i].idarticulo+`</td>
								<td class="col-6 text-1">`+this.detalles2[i].descripcion+`</td>
								<td class="col-3 text-center">`+this.detalles2[i].precio+`</td>
								<td class="col-1 text-center">`+this.detalles2[i].cantidadDestino+`</td>
								<td class="col-1 text-center">`+this.detalles2[i].cantidadIngreso+`</td>
								</tr>`
				}
			var win = window.open('', '', 'height=1200, width=850');
				win.document.write(`
				<html lang="en">
						<head>
						<meta charset="utf-8" />
						<meta http-equiv="X-UA-Compatible" content="IE=edge">
						<meta name="viewport" content="width=device-width, initial-scale=1">
						<link href="images/favicon.png" rel="icon" />
						<title>General Invoice - Koice</title>
						<meta name="author" content="harnishdesign.net">

						<!-- Web Fonts
						======================= -->
						<link rel='stylesheet' href='https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900' type='text/css'>

						<!-- Stylesheet
						======================= -->
						<link rel="stylesheet" type="text/css" href="https://www.noah.cr/FacturaCSS/vendor/bootstrap/css/bootstrap.min.css"/>
						<link rel="stylesheet" type="text/css" href="https://www.noah.cr/FacturaCSS/vendor/font-awesome/css/all.min.css"/>
						<link rel="stylesheet" type="text/css" href="https://www.noah.cr/FacturaCSS/css/stylesheet.css"/>
						</head>
						<body>
						<!-- Container -->
						<div class="container-fluid invoice-container">
						<!-- Header -->
						<header>
						<div class="row align-items-center">
							<div class="col-sm-7 text-center text-sm-start mb-3 mb-sm-0">
							<img id="logo" style="max-width:100px" src="`+this.Logo3+`" title="Logotipo" alt="Koice" />
							</div>
							<div class="col-sm-5 text-center text-sm-end">
							<h4 class="text-7 mb-0">Traslado de Mercadería</h4>
							</div>
						</div>
						<hr>
						</header>
						
						<!-- Main Content -->
						<main>
						<div class="row">
							<div class="col-sm-6"><strong>Fecha:</strong> `+this.Fecha+`</div>
							<div class="col-sm-6 text-sm-end"> <strong>Número de traslado :</strong> `+this.idMovimiento+`</div>
							
						</div>
						<hr>
						<div class="row">
							<div class="col-sm-6 text-sm-end order-sm-1"> <strong>Info de transacción:</strong>
							<address>
							Usuario: `+this.$store.state.usuario.nombre+`<br />
							Origen: `+this.localidadorigen+`<br />
							Destino:`+this.localidaddestino+`<br />
							`+this.localidadencabezado[0].telefono+`
							</address>
							</div>
							<div class="col-sm-6 order-sm-0"> <strong>Datos del Negocio:</strong>
							<address>
							`+this.localidadencabezado[0].nombre+`<br />
							`+this.localidadencabezado[0].razonSocial+`<br />
							`+this.localidadencabezado[0].cedulaJuridica+`<br />
							`+this.localidadencabezado[0].telefono+`
							</address>
							</div>
						</div>
							
						<div class="card">
							<div class="card-body p-0">
							<div class="table-responsive">
								<table class="table mb-0">
								<thead class="card-header">
								<tr>
									<td class="col-3"><strong>Código I</strong></td>
									<td class="col-6"><strong>Nombre</strong></td>
									<td class="col-3 text-center"><strong>Precio Venta</strong></td>
									<td class="col-1 text-center"><strong>Stock Anterior</strong></td>
									<td class="col-1 text-center"><strong>Cantidad Trasladada</strong></td>
								</tr>
								</thead>
								<tbody>
									`+products+`
								</tbody>
								<tfoot class="card-footer">
									<tr>
										<td colspan="4" class="text-end"><strong>Mensajero:</strong></td>
										<td class="text-end">________</td>
									</tr>
									<tr>
										<td colspan="4" class="text-end"><strong>Entregado por: </strong></td>
										<td class="text-end">________</td>
									</tr>
									<tr>
										<td colspan="4" class="text-end border-bottom-0"><strong>Recibido por:</strong></td>
										<td class="text-end border-bottom-0">________</td>
									</tr>
								</tfoot>
								</table>
							</div>
							</div>
						</div>
						</main>
						<!-- Footer -->
						<footer class="text-center mt-4">
						<p class="text-1"><strong>NOTE :</strong> `+this.detalle+`.</p>
						<div class="btn-group btn-group-sm d-print-none"> <a href="window.print()" class="btn btn-light border text-black-50 shadow-none"><i class="fa fa-print"></i> Print</a> <a href="" class="btn btn-light border text-black-50 shadow-none"><i class="fa fa-download"></i> Download</a> </div>
						</footer>
						</div>
						</body>
					</html>
				`);
				setTimeout(() => {
					win.print();
				}, 2000);
				//win.window.close();
		},
		PrintDetails2(){
			var products = "";
				for(var i = 0; i < this.detalles2.length; ++i)
				{
						products += `<tr>
									<td class="col-3">`+this.detalles2[i].idarticulo+`</td>
									<td class="col-6 text-1">`+this.detalles2[i].descripcion+`</td>
									<td class="col-3 text-center">`+this.detalles2[i].precio+`</td>
									<td class="col-1 text-center">`+this.detalles2[i].cantidadDestino+`</td>
									<td class="col-1 text-center">`+this.detalles2[i].cantidadIngreso+`</td>
									</tr>`
				}
				var win = `
						<html lang="en">
						<head>
						<meta charset="utf-8" />
						<meta http-equiv="X-UA-Compatible" content="IE=edge">
						<meta name="viewport" content="width=device-width, initial-scale=1">
						<link href="images/favicon.png" rel="icon" />
						<title>General Invoice - Koice</title>
						<meta name="author" content="harnishdesign.net">

						<!-- Web Fonts
						======================= -->
						<link rel='stylesheet' href='https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900' type='text/css'>

						<!-- Stylesheet
						======================= -->
						<link rel="stylesheet" type="text/css" href="https://www.noah.cr/FacturaCSS/vendor/bootstrap/css/bootstrap.min.css"/>
						<link rel="stylesheet" type="text/css" href="https://www.noah.cr/FacturaCSS/vendor/font-awesome/css/all.min.css"/>
						<link rel="stylesheet" type="text/css" href="https://www.noah.cr/FacturaCSS/css/stylesheet.css"/>
						</head>
						<body>
						<!-- Container -->
						<div class="container-fluid invoice-container">
						<!-- Header -->
						<header>
						<div class="row align-items-center">
							<div class="col-sm-7 text-center text-sm-start mb-3 mb-sm-0">
							<img id="logo" style="max-width:100px" src="`+this.Logo3+`" title="Logotipo" alt="Koice" />
							</div>
							<div class="col-sm-5 text-center text-sm-end">
							<h4 class="text-7 mb-0">Traslado de Mercadería</h4>
							</div>
						</div>
						<hr>
						</header>
						
						<!-- Main Content -->
						<main>
						<div class="row">
							<div class="col-sm-6"><strong>Fecha:</strong> `+this.Fecha+`</div>
							<div class="col-sm-6 text-sm-end"> <strong>Número de trazlado `+this.barcode+`:</strong> 16835</div>
							
						</div>
						<hr>
						<div class="row">
							<div class="col-sm-6 text-sm-end order-sm-1"> <strong>Info de transacción:</strong>
							<address>
							Usuario: `+this.$store.state.usuario.nombre+`<br />
							Origen: `+this.localidadorigen+`<br />
							Destino:`+this.localidaddestino+`<br />
							`+this.localidadencabezado[0].telefono+`
							</address>
							</div>
							<div class="col-sm-6 order-sm-0"> <strong>Datos del Negocio:</strong>
							<address>
							`+this.localidadencabezado[0].nombre+`<br />
							`+this.localidadencabezado[0].razonSocial+`<br />
							`+this.localidadencabezado[0].cedulaJuridica+`<br />
							`+this.localidadencabezado[0].telefono+`
							</address>
							</div>
						</div>
							
						<div class="card">
							<div class="card-body p-0">
							<div class="table-responsive">
								<table class="table mb-0">
								<thead class="card-header">
								<tr>
									<td class="col-3"><strong>Código I</strong></td>
									<td class="col-6"><strong>Nombre</strong></td>
									<td class="col-3 text-center"><strong>Precio Venta</strong></td>
									<td class="col-1 text-center"><strong>Stock Anterior</strong></td>
									<td class="col-1 text-center"><strong>Cantidad Trasladada</strong></td>
								</tr>
								</thead>
								<tbody>
									`+products+`
								</tbody>
								<tfoot class="card-footer">
									<tr>
										<td colspan="4" class="text-end"><strong>Mensajero:</strong></td>
										<td class="text-end">________</td>
									</tr>
									<tr>
										<td colspan="4" class="text-end"><strong>Entregado por: </strong></td>
										<td class="text-end">________</td>
									</tr>
									<tr>
										<td colspan="4" class="text-end border-bottom-0"><strong>Recibido por:</strong></td>
										<td class="text-end border-bottom-0">________</td>
									</tr>
								</tfoot>
								</table>
							</div>
							</div>
						</div>
						</main>
						<!-- Footer -->
						<footer class="text-center mt-4">
						<p class="text-1"><strong>NOTE :</strong> `+this.detalle+`.</p>
						<div class="btn-group btn-group-sm d-print-none">  <a href="#" onclick="window.print();" class="btn btn-light border text-black-50 shadow-none"><i class="fa fa-print"></i> Print</a>  <a href="" class="btn btn-light border text-black-50 shadow-none"><i class="fa fa-download"></i> Volver</a> </div>
						</footer>
						</div>
						</body>
					</html>
				`;
				document.documentElement.innerHTML = win;
				setTimeout(() => {
					win.print();
				}, 2000);
				//win.window.close();
		},
		handleFocus(event, id) {
			this.$refs['input'+id][0].select();
		},
		async PostEstado(NoProforma)
		{ 	let me = this;
			let data= [];
			let header={"Authorization" : "Bearer ", "key": "okJ959Fp92Sdj86N"};
			let configuracion= {headers : header};
			await axios.post('api/EstadosEvertec/NotificaKingVape',{
					'status':
					{"status":me.RequestIdData.status,
						"message":me.RequestIdData.message,
						"reason":"TT",
						"date":me.RequestIdData.date},
					"requestID":me.RequestId,
					"reference":NoProforma,
					"signature": "15315435asd43as5d4a3s5d4as3d54a6sd4a8",
					"Localidad": "King Vape",
				},configuracion).then(function (response) {
					data = response.data;
					})
					.catch(function (error) {
				//	alert(Lo sentimos ha ocurrido un error, notifiuelo al 4070-1889+error);  
				});
		},
		listarAsociadas(){
			var items = [];
			let me=this;
			me.Localidades.push(me.$store.state.usuario.localidad);
			this.localidad = this.$store.state.usuario.localidad;
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			axios.get('api/LocalidadesAsociadas/'+this.$store.state.usuario.nombre,configuracion).then(function(response){
				items=response.data;
				items.map(function(x){
					me.Localidades.push(x.localdiadAsociada);
				});
			}).catch(function(error){
				alert("Producto no encontrado");
				me.modelCarga=false;
			});
		},
        select(){
                let me=this;
                var clientesArray=[];
                let header={"Authorization" : "Bearer "};
                let configuracion= {headers : header};
                 axios.get('api/Empleados/SelectEmpleadosLocalidad/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
                    clientesArray=response.data;
                    clientesArray.map(function(x){
                        me.clientes.push(x.nombre);
                    });
                })
        },
		ConsultarId (NoProforma) {
            let me=this;
            let header={"Authorization" : "Bearer " };
            let configuracion= {headers : header};
            var proveedoresArray=[];
            axios.get('api/EstadosEvertec/ByReference/'+NoProforma,configuracion).then(function(response){
                proveedoresArray=response.data;
                me.RequestId = proveedoresArray.requestID
				//alert(me.RequestId);
				me.createAuth(NoProforma);
            }).catch(function(error){
                console.log(error);
            });
        
        },
		AceptarProforma (item) {
            this.loading = true;
            var me = this;
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
            axios.post('api/Proformas/Aceptar',{
                'Estado':'2',
				'NoProforma':item.noProforma,
                    
            },configuracion).then(function (response) {
                alert("Proforma aceptada, no necesitas hacer nada más");
                me.listar();
                })
                .catch(function (error) {
                    alert("Error eliminando, por favor reporte a su proveedor");  
                })
        
        },
		ActualizarEstado (item) {
            this.loading = true;
            var me = this;
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
            axios.post('api/Proformas/Aceptar',{
                'Estado':item.estado,
				'NoProforma':item.noProforma,
                'Vendedor':item.vendedor,
            },configuracion).then(function (response) {
                //alert("Estado Actualizado")
                me.listar();
                })
                .catch(function (error) {
                    alert("Error actualizando, por favor reporte a su proveedor")  
                })
        
        },
		ActualizarVendedor (item) {
            this.loading = true;
            var me = this;
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
            axios.post('api/Proformas/CambiarVendedor',{
                'Estado':item.estado,
				'NoProforma':item.noProforma,
                'Vendedor':item.vendedor,
            },configuracion).then(function (response) {
                //alert("Estado Actualizado")
                me.listar();
                })
                .catch(function (error) {
                    alert("Error actualizando, por favor reporte a su proveedor")  
                })
        
        },
		imprimirA4() {
			var products = "";
			var cant = 0.0;
			for(var i = 0; i < this.detalles.length; ++i)
				{
					
					cant = this.detalles[i].cantidad
					cant = parseFloat(cant) * parseFloat(this.detalles[i].precioFinal);
					products += `<tr>
								<td class="col-2 text-1">`+this.detalles[i].valorImpuesto+`* `+this.detalles[i].codigoProducto+`</td>
								<td class="col-7 text-1">`+this.detalles[i].descripcion+` *<p style="white-space: pre-line;" class="text-0">` + this.detalles[i].detalleLinea+`<p></td>
								<td class="col-1 text-1 text-center">`+this.detalles[i].precioFinal+`</td>
								<td class="col-1 text-1 text-center">`+this.detalles[i].cantidad+`</td>
								<td class="col-1 text-1 text-end">`+this.detalles[i].descuentoLinea+`%</td>
								<td class="col-1 text-1 text-end">`+cant+`</td>
								</tr>`
					
				}
			let html = `<html lang="en">
					<head>
					<meta charset="utf-8" />
					<meta http-equiv="X-UA-Compatible" content="IE=edge">
					<meta name="viewport" content="width=device-width, initial-scale=1">
					<link href="images/favicon.png" rel="icon" />
					<title>Proforma `+this.noFactura+` - `+this.$store.state.usuario.localidad+`</title>
					<meta name="author" content="harnishdesign.net">

					<!-- Web Fonts
					======================= -->
					<link rel='stylesheet' href='https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900' type='text/css'>

					<!-- Stylesheet
					======================= -->
					<link rel="stylesheet" type="text/css" href="https://www.noah.cr/FacturaCSS/vendor/bootstrap/css/bootstrap.min.css"/>
					<link rel="stylesheet" type="text/css" href="https://www.noah.cr/FacturaCSS/vendor/font-awesome/css/all.min.css"/>
					<link rel="stylesheet" type="text/css" href="https://www.noah.cr/FacturaCSS/css/stylesheet.css"/>
					</head>
					<body>
					<!-- Container -->
					<div class="container-fluid invoice-container">
					
					<!-- Main Content -->
					<main>
					<div class="table-responsive">
						<table >
							<thead >
								<tr>
									<td class="col-1 text-3 text-start"><img id="logo" src="`+this.localidadencabezado[0].imagen+ `" width="120" height="120" title="NoahLogo" alt="Noah" /></td>
									<td class="col-4 text-center"> <strong> `+this.$store.state.usuario.localidad+ ` </strong> <br />
									`+this.localidadencabezado[0].razonSocial+ `<br />
									`+this.localidadencabezado[0].dirreccion+ `<br />
									`+this.localidadencabezado[0].cedulaJuridica+ `<br />
									`+this.localidadencabezado[0].telefono+ `<br />
									`+this.localidadencabezado[0].correo+ `<br /> </td>
									<td class="col-1 text-end"><h4 class="text-7 mb-0">Proforma: `+this.noFactura+ `</h4></td>
								</tr>
							</thead>
						</table>
					</div>
					<hr>
					<table >
						<thead >
							<tr>
								<td class="col-2 text-start text-1"><strong> Cliente:</strong> `+this.nombreReceptor+ `<br /></td>
								<td class="col-2 text-end text-1"><strong> Fecha:</strong> `+this.fecha+ `<br /></td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td class="col-2 text-start text-1"><address>
									
									<strong> Cédula :</strong> `+this.cedulaReceptor+ `<br />
									<strong> Teléfono :</strong> `+this.telefonoReceptor+ `<br />
									<strong> Correo :</strong> `+this.correoReceptor+ ` <br />
									
									</address>
								</td>
								<td class="col-2 text-end text-1"><address>
									<strong> Vencimiento:</strong> `+this.Vencimiento +`<br />
									<strong> Vendedor:</strong>  `+this.vendedor+ `<br />
									<strong> Generado por:</strong>  `+this.$store.state.usuario.nombre+ `
									</address>
								</td>
							</tr>
						</tbody>
					</table>
						
					<div class="card">
						<div class="card-body p-0">
						<div class="table-responsive">
							<table class="table mb-0">
							<thead class="card-header">
							<tr>
								<td class="col-2 text-2"><strong>Código</strong></td>
								<td class="col-8 text-2"><strong>Descripción</strong></td>
								<td class="col-1 text-2 text-center"><strong>Precio</strong></td>
								<td class="col-2 text-2 text-center"><strong>Cant</strong></td>
								<td class="col-2 text-2 text-end"><strong>Desc</strong></td>
								<td class="col-2 text-2 text-end"><strong>Cargo</strong></td>
							</tr>
							</thead>
							<tbody>
								`+products+`
							</tbody>
							<tfoot class="card-footer">
								<tr>
								<td colspan="2" class="text-end"><qrcode-vue :value="value" :size="size" level="H"></qrcode-vue></td>
								<td class="text-end"> </td>
								<td colspan="2" class="text-end"><strong>Sub Total: <br> Descuento: <br> IVA: <br> Total:</strong></td>
								<td class="text-end">`+this.monedaPago+this.subTotal+ `<br> `+this.monedaPago+this.totalDescuentos+ `<br>`+this.monedaPago+this.iv+ ` <br> `+this.monedaPago+this.total+ `</td>
								</tr>
							</tfoot>
							</table>
						</div>
						</div>
					</div>
					</main>
					<!-- Footer -->
					<footer class="text-center">
					<div><strong>Nota :</strong> `+this.comentario+ ` `+this.detalle+ ` <br>
					<strong>Cuentas Bancarias :</strong> `+this.Cuentas+ `
					</div>
					`+this.pieDocumento+ `
					</footer>
					</div>
					</body>
				</html>
			`;
			//this.EmailFacturaCorreo(html)
			const nuevaVentana = window.open('' , '_blank');
			nuevaVentana.document.write(html);
			nuevaVentana.document.close();
			setTimeout(() => {
				nuevaVentana.print();
				nuevaVentana.close();
				//location.reload();
			}, 2000);
		//this.$forceUpdate();
		},
        listarDetalles(id){
                let me=this;
				let coordinadas = null;
                let header={"Authorization" : "Bearer " + this.$store.state.token};
                let configuracion= {headers : header};
                axios.get('api/Proformas/ListarDatosFactura/'+id+'/'+this.localidad+'',configuracion).then(function(response){
                    //console.log(response);
                    me.detalles=response.data;
                    me.correoReceptor = me.detalles[0].correo;
                    me.subTotal = me.detalles[0].subTotal;
                    me.subTotal = me.detalles[0].precioSinIva;
					me.telefonoReceptor = me.detalles[0].contacto;
                    me.iv = me.detalles[0].iva;
                    me.monedaPago = me.detalles[0].contacto1;
					me.totalDescuentos = me.detalles[0].descuento;
                    me.cedulaReceptor = me.detalles[0].tipoDocumento;
					coordinadas = me.detalles[0].detalleLinea;
					const valores = coordinadas.split(',');

					// Obtener la latitud y la longitud como cadenas separadas
					const latitudString = valores[0].split(':')[1];
					const longitudString = valores[1].split(':')[1];

					
					// Almacenar la suma de descuentos en una propiedad del componente
					

					// Convertir las cadenas a números
					me.latitud = parseFloat(latitudString);
					me.longitud = parseFloat(longitudString);
					me.value = location.hostname+'/perfilcliente/'+me.$store.state.usuario.localidad+'/'+me.cedulaReceptor;
                }).catch(function(error){
                    console.log(error);
                });
		},
        selectVendedores(){
            let me=this;
            let header={"Authorization" : "Bearer " };
            let configuracion= {headers : header};
            var proveedoresArray=[];
            axios.get('api/Usuarios/SelectUsuariosLocalidad/'+me.$store.state.usuario.localidad,configuracion).then(function(response){
                proveedoresArray=response.data;
                proveedoresArray.map(function(x){
                    me.Vendedores.push(x.nombre);
                });
            }).catch(function(error){
                console.log(error);
            });
        },
		OpenLink(item){
			var linkProforma = location.origin+"/aceptarproforma/"+item.noProforma+"/"+this.$store.state.usuario.localidad;
			location.assign(linkProforma);
		},
        listarLocalidad(){
            let me =this;
            let LogoTest= '';
            let self = this;
            
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
                axios.get('api/Localidades/ListarFiltrada/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
                    //console.log(response);
                    me.localidadencabezado=response.data;
					me.Logo2 = me.localidadencabezado[0].imagen;
					me.pieDocumento = me.localidadencabezado[0].pieDocumento;
					me.modoPDV = me.localidadencabezado[0].modoPDV;
					me.ShowNoFact = me.localidadencabezado[0].express; // Si este campo viene true se muestra el NoFactura del la clave numerica, sino se muestra el NoFactura interno
					me.Cuentas = me.localidadencabezado[0].latitud; // este campo contiene los numero de cuentas bancarios
                    function toDataURL(url, callback) {
                    var xhr = new XMLHttpRequest();
                    xhr.onload = function() {
                        var reader = new FileReader();
                        reader.onloadend = function() {
                        callback(reader.result);
                        }
                        reader.readAsDataURL(xhr.response);
                    };
                    xhr.open('GET', url);
                    xhr.responseType = 'blob';
                    xhr.send();
                    }
                    var url2 = me.Logo2;
                    
                        toDataURL(url2, function(dataUrl) {
                        //console.log('RESULT:', dataUrl)
                        me.Logo3 = dataUrl;
                        }) 
                    }).catch(function(error){
                        console.log(error);
                    });
                
        },
        FiltrarCliente(){
            this.usuarios = this.usuarios.filter(e => e.medioDePago == this.PagosFiltrados);
            this.snackbar = true;
            this.Mensaje = 'Filtro Medio de Pago Seleccionado sobre rango de fecha listado'
        },
        FiltrarVendedor(){
            let amp = "";
            this.Datos = this.usuarios;
            this.usuarios = this.usuarios.filter(e => e.nombreReceptor == this.ClienteSeleccionado);
            this.snackbar = true;
            this.Mensaje = 'Filtro de cliente seleccionado sobre rango de fecha listado'
        },
        FiltrarCliente2(){
            let amp = "";
            this.Datos = this.usuarios;
            this.usuarios = this.usuarios.filter(e => e.nombreReceptor == this.ClienteSeleccionado);
            this.snackbar = true;
            this.Mensaje = 'Filtro de vendedor seleccionado sobre rango de fecha listado'
        },
        FiltrarMoneda(){
            let amp = "";
            this.Datos = this.usuarios;
            amp = this.Datos.filter(e => e.moneda == this.MonedaFiltrar);
            this.usuarios = amp;
            this.snackbar = true;
            this.Mensaje = 'Filtro Medio de Moneda Seleccionado sobre rango de fecha listado'
        },
            verLocalidad(item){
            this.NombreLocalidad= item.nombre;
            this.CedulaLocalidad= item.cedulaJuridica;
            this.DireccionLocalidad= item.dirreccion;
            },
        loadProductosMasVendidos(){
            let me=this;
            let mesn='';
            me.mesesValores.map(function(x){
            switch(parseInt(x.etiqueta)){
                case 1:
                mesn='Enero';
                break;
                case 2:
                mesn='Febrero';
                break;
                case 3:
                mesn='Marzo';
                break;
                case 4:
                mesn='Abril';
                break;
                case 5:
                mesn='Mayo';
                break;
                case 6:
                mesn='Junio';
                break;
                case 7:
                mesn='Julio';
                break;
                case 8:
                mesn='Agosto';
                break;
                case 9:
                mesn='Setiembre';
                break;
                case 10:
                mesn='Octubre';
                break;
                case 11:
                mesn='Noviembre';
                break;
                case 12:
                mesn='Diciembre';
                break;
                default:
                mesn='Error';
            }
            me.nombreMeses.push(mesn);
            me.totalMeses.push(x.valor);
            });
            var ctx = document.getElementById("myChart");
            var myChart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: me.nombreMeses,
                    datasets: [{
                        label: 'Ventas en los últimos 12 Meses',
                        data: me.totalMeses,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)',
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)'
                        ],
                        borderColor: [
                            'rgba(255,99,132,1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)',
                            'rgba(255,99,132,1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        borderWidth: 1
                    }]
                },
                options: {
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero:true
                            }
                        }]
                    }
                }
            });
        },
        MostrarComprobante(item){
            this.nombreReceptor= item.cliente;
            this.vendedor = item.vendedor;
            this.noFactura = item.noProforma;
            this.fecha = item.fecha.substring(0, 10);
            this.total = item.total;
			this.Vencimiento =item.vencimiento.substring(0, 10);
            this.nofactura2 = "/proforma/"+this.noProforma;
            this.listarDetalles(item.noProforma);
            this.comprobanteModal = true;
			this.comentario = item.detalle
			const date1 = new Date(this.fecha);
			const date2 = new Date(this.Vencimiento);
			const differenceInTime = date2.getTime() - date1.getTime();
			const differenceInDays = differenceInTime / (1000 * 3600 * 24);
			this.dias = differenceInDays;
        },
        MostrarComprobante2(value){
            this.MostrarComprobante(value);
            this.crearPDFFactura();
            
        },
        OcultarComprobante(){
            this.comprobanteModal = 0;
        },
        CrearNotaCredito() 
        {
        if (this.validarPago() != 1)
        {
            return;
        }
            var localidadTicket = this.obtenerTicket();
            
        },
        FacturarProforma(item){
            this.linkFacturar = 'facturar/'+item.noProforma+'/'+this.localidad;
            location.assign(this.linkFacturar);
        },
        DeleteProforma (item) {
        this.loading = true;
        var me = this;
        if(confirm("Seguro que quiere eliminar la Proforma  "+item.noProforma+"?")){
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
            axios.post('api/Proformas/Delete',{
                'NoProforma': item.noProforma,
                    
            },configuracion).then(function (response) {
                
                me.snackbar = true;
                me.Mensaje = 'Proforma Eliminada Con éxito.'; 
                me.listar(); 
                
                })
                .catch(function (error) {
                    me.snackbar = true;
                    me.Mensaje = 'Error eliminando el Proforma.';    
                })
        }
      },
        crearPDF(){
           var columns = [
            {title: "Proforma", dataKey: "noProforma"},
            {title: "Estado", dataKey: "estado"}, 
            {title: "Fecha", dataKey: "fecha"}, 
            {title: "Vendedor", dataKey: "vendedor"},
            {title: "Total", dataKey: "total"},
            {title: "Cliente", dataKey: "cliente"},
            ];
            var rows = [];

            this.usuarios.map(function(x){
                rows.push({noProforma:x.noProforma,estado:x.estado,fecha:x.fecha,total:x.total,vendedor:x.vendedor,cliente:x.cliente});
            });

            // Only pt supported (not mm or in)
            var doc = new jsPDF('p', 'pt');
            doc.autoTable(columns, rows, {
                margin: {top: 60},
                addPageContent: function(data) {
                    doc.text("Listado de Proformas", 40, 30);
                }
            });
            doc.save('ReporteProformas.pdf');
        },
        crearPDFFactura(){
            var fact = this.noFactura;
             var quotes = document.getElementById('factura');
                html2canvas(quotes).then(function (canvas) {
                    var imgData = canvas.toDataURL('image/png');
                    var imgWidth = 210;
                    var pageHeight = 285;
                    var imgHeight = canvas.height * imgWidth / canvas.width;
                    var heightLeft = imgHeight;
                    var doc = new jsPDF();
                    var position = 0;

                    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);                    
                    doc.save('Proforma '+fact+'.pdf');
                });
        },
        getColor (mensajeRespuesta) {
			if (mensajeRespuesta == 'PENDIENTE') return 'indigo lighten-4'
			if(mensajeRespuesta == 'Aceptado') return 'orange lighten-4'
			if(mensajeRespuesta == 'RECHAZADO') return 'red-lighten-4'
			else  return 'light-green lighten-4'
		},
		getColorText (mensajeRespuesta) {
			if (mensajeRespuesta == 'PENDIENTE') return 'indigo darken-4'
			if(mensajeRespuesta == 'Aceptado') return 'orange darken-4'
			if(mensajeRespuesta == 'RECHAZADO') return 'red-darken-4'
			else  return 'light-green darken-4'
		},
       listar(){
			if(this.$store.state.usuario.rol =='King Vendedor' ){
				this.ListarconVendedor();
			} else
			{	
				this.ListarSinVendedor();
			}
        },
		ListarconVendedor(){
			let me=this;
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
			axios.get('api/Proformas/ListarVistaVendedor/'+this.$store.state.usuario.localidad+'/'+this.$store.state.usuario.nombre,configuracion).then(function(response){
					//console.log(response);
					me.usuarios=response.data;
				}).catch(function(error){
					console.log(error);
				});
		},
		ListarSinVendedor(){
			let me=this;
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
			axios.get('api/Proformas/ListarVista/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
					//console.log(response);
					me.usuarios=response.data;
				}).catch(function(error){
					console.log(error);
				});
		},
		ListarNoProforma(){
			let me=this;
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
			axios.get('api/Proformas/ListarProforma/'+this.$store.state.usuario.localidad+'/'+this.search,configuracion).then(function(response){
					//console.log(response);
					me.usuarios=response.data;
				}).catch(function(error){
					console.log(error);
				});
		},
		listarLocalidadAsociada(){
            let me=this;
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
            axios.get('api/Proformas/ListarVista/'+this.localidad,configuracion).then(function(response){
                //console.log(response);
                me.usuarios=response.data;
            }).catch(function(error){
                console.log(error);
            });
        },
        listarFechas(){
            if(this.start == '' || this.end == ''){
                this.snackbar = true;
                this.Mensaje = 'Debe seleccionar una fecha inicial y final'
                return;
           }
            let me=this;
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
            axios.get('api/Proformas/ConsultaFechas/'+this.start+'/'+this.end+'/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
                //console.log(response);
                me.usuarios=response.data;
            }).catch(function(error){
                console.log(error);
            });
        },

      close () {
        this.dialog = false;
        this.limpiar();
          },
    

      activarDesactivarMostrar(accion,item){
                this.adModal=1;
                this.adNombre=item.nombre;
                this.adId=item.idusuario;                
                if (accion==1){
                    this.adAccion=1;
                }
                else if (accion==2){
                    this.adAccion=2;
                }
                else{
                    this.adModal=0;
                }
            },
            activarDesactivarCerrar(){
                this.adModal=0;
            },
      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.desserts[this.editedIndex], this.editedItem)
        } else {
          this.desserts.push(this.editedItem)
        }
        this.close()
      },
    },
  }

</script>
<style scoped>
	/* CSS para mantener el formato de escritorio en dispositivos móviles */
	@media (max-width: 600px) { /* Ajusta 600px al tamaño de pantalla que consideres para móviles */
		#factura {
			zoom: 0.5; /* Reduce el tamaño para que quepa en la pantalla, ajusta según sea necesario */
		}

		.v-dialog__content {
			width: 100%!important;
			max-width: none!important;
		}
	}
	.row-alerta {
	background-color: red;
	}
</style>