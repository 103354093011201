<template>
  <div>
    <div class="pricing-component mini-spacer">
      <v-container>
        <!-- -----------------------------------------------
            Start Pricing Text
        ----------------------------------------------- -->
        <v-row justify="center">
          <v-col cols="12" sm="10" md="9" lg="7">
            <div class="text-center">
              <h2 class="section-title font-weight-medium">
                Precios Simples y económicos para pymes o grandes empresas
              </h2>
              <p>Ofrecemos 100% satisfacción, sino te gusta te devolvemos tu dinero</p>
            </div>
          </v-col>
        </v-row>

        <!-- -----------------------------------------------
            End Pricing Text
        ----------------------------------------------- -->
        <!-- -----------------------------------------------
            Start Pricing
        ----------------------------------------------- -->
        <v-row class="mt-13" justify="center">
          <v-col cols="12" md="6" lg="4">
            <v-card
              outlined
              elevation="0"
              class="pricing-card overflow-hidden mb-15"
            >
              <v-card-text class="text-center pa-15">
                <h5 class="font-weight-regular" style="font-size: 18px;" >Régimen Simplificado</h5>
                <div>
                  <sup>₡</sup>
                  <span style="font-size: 48px;
				line-height: 72px;
				color: black;">25.000</span>
                  <h6 class=" text-uppercase font-weight-regular">
                    Mensual
                  </h6>
                </div>
                <p class="mt-16 pt-4">
                  Ideal para 1 pequeños negocio que tributan por compras, no incluye facturación electrónica
                </p>
              </v-card-text>
              <v-btn
                block
                large
                depressed
                color="info"
                class="text-uppercase rounded-t-0"
              >
                Choose Plan
              </v-btn>
            </v-card>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-card
              outlined
              elevation="0"
              class="pricing-card overflow-hidden mb-15"
            >
              <v-card-text class="text-center pa-15">
                <h5 class="font-weight-regular" style="font-size: 18px;">Régimen Tradicional</h5>
                <div>
                  <sup>₡</sup>
                  <span style="font-size: 48px;
				line-height: 72px;
				color: black;">30.000</span>
                  <h6 class="font-14 text-uppercase font-weight-regular">
                    Mensual
                  </h6>
                </div>
                <p class="mt-16 pt-4">
                  Ideal para 1 sucursal que triuta por ventas, facturación electrónica ilimitada
                </p>
              </v-card-text>
              <v-btn
                block
                large
                depressed
                color="error"
                class="text-uppercase rounded-t-0"
              >
                Choose Plan
              </v-btn>
            </v-card>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-card
              outlined
              elevation="0"
              class="pricing-card overflow-hidden mb-15"
            >
              <v-card-text class="text-center pa-15">
                <h5 class="font-weight-regular font-18" style="font-size: 18px;">Premium Plan</h5>
                <div>
                  <sup>₡</sup>
                  <span style="font-size: 48px;
				line-height: 72px;
				color: black;">290.000</span>
                  <h6 class="font-14 text-uppercase font-weight-regular">
                    Anual
                  </h6>
                </div>
                <p class="mt-16 pt-4">
                  La licencia a la medida para medianas o grandes empresas que tienen varias sucursales
                </p>
              </v-card-text>
              <v-btn
                block
                large
                depressed
                color="info"
                class="text-uppercase rounded-t-0"
              >
                Choose Plan
              </v-btn>
            </v-card>
          </v-col>
        </v-row>

        <!-- -----------------------------------------------
            End Pricing
        ----------------------------------------------- -->
      </v-container>
    </div>
  </div>
</template>
<script>
export default {
  name: "Features",
  data() {
    return {};
  },
  methods: {},
};
</script>
