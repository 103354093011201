<template>
  <div>
	<v-toolbar flat color="white">
			<v-toolbar-title>Ordenes</v-toolbar-title>
			<v-divider
			class="mx-4"
			inset
			vertical
			></v-divider>
		<v-spacer></v-spacer>
			<v-text-field
			v-model="search"
			append-icon="mdi-magnify"
			label="Buscar"
			single-line
			hide-details
			></v-text-field>
			<v-spacer></v-spacer>
			<v-btn @click="dialog = true" color="primary"> Nueva Orden </v-btn>
	</v-toolbar>
	<v-data-table
		:headers="headers"
		:items="usuarios"
		:search="search"
		sort-by="nombre"
		class="elevation-1"
	>
	<template v-slot:item.cliente="props">
			<v-edit-dialog
			:return-value.sync="props.item.cliente"
			@save="ActualizarOrden(props.item)"
			large
			>
			{{props.item.cliente}}
			<template v-slot:input>
				<v-text-field  v-model="props.item.cliente" label="Nombre"/>
			</template>
			</v-edit-dialog>
	</template>
	<template v-slot:item.nota="props">
			<v-edit-dialog
			:return-value.sync="props.item.nota"
			@save="ActualizarOrden(props.item)"
			large
			>
			{{props.item.nota}}
			<template v-slot:input>
				<v-text-field  v-model="props.item.nota" label="nota"/>
			</template>
			</v-edit-dialog>
	</template>
	<template v-slot:item.falla="props">
			<v-edit-dialog
			:return-value.sync="props.item.falla"
			@save="ActualizarOrden(props.item)"
			large
			>
			{{props.item.falla}}
			<template v-slot:input>
				<v-text-field  v-model="props.item.falla" label="falla"/>
			</template>
			</v-edit-dialog>
	</template>
	<template v-slot:item.costo="props">
			<v-edit-dialog
			:return-value.sync="props.item.costo"
			@save="ActualizarOrden(props.item)"
			large
			>
			{{props.item.costo}}
			<template v-slot:input>
				<v-text-field  v-model="props.item.costo" label="costo"/>
			</template>
			</v-edit-dialog>
	</template>
	<template v-slot:item.adelanto="props">
			<v-edit-dialog
			:return-value.sync="props.item.adelanto"
			@save="ActualizarOrden(props.item)"
			large
			>
			{{props.item.adelanto}}
			<template v-slot:input>
				<v-text-field  v-model="props.item.adelanto" type="num" label="costo"/>
			</template>
			</v-edit-dialog>
	</template>
	<template v-slot:item.tecnico="props">
			<v-edit-dialog
			:return-value.sync="props.item.tecnico"
			@save="ActualizarOrden(props.item)"
			large
			>
			{{props.item.tecnico}}
			<template v-slot:input>
				<v-select :items="Vendedores"  v-model="props.item.tecnico" label="tecnico"/>
			</template>
			</v-edit-dialog>
	</template>
    <template v-slot:item.action="{ item }">
		<v-icon
		
		class="mr-2"
		color="primary" 
		@click="editItem(item)"
		>
		mdi-pencil
		</v-icon>
		<template >
			<v-icon
			color="error"
			@click="EliminarOrden(item)"
			> 
			mdi-delete
			</v-icon>
		</template>
	</template>
	</v-data-table>
	<v-snackbar
			v-model="snackbar" top vertical="" :timeout="6000"
			>
			{{ Mensaje }}
			<v-btn
			color="pink"
			text
			@click="snackbar = false"
			>
			Close
			</v-btn>
	</v-snackbar>
	
    <v-dialog persistent v-model="dialog" max-width="1200">
      <v-card>
		<v-toolbar dark color="primary">
			<v-toolbar-title>Nueva Orden de servicio</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-btn @click="comprobanteModal = true"> PDF </v-btn>
			<v-toolbar-items>
				<v-btn icon dark @click="dialog = false">
				<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-toolbar-items>
		</v-toolbar>
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="12">
              <v-form ref="form" v-model="validCliente" @submit.prevent="RegistrarOrden()">
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-combobox
                      filled
                      hide-details
                      clearable
                      @change="obtenerCliente()"
                      dense
                      v-model="idcliente"
                      :items="clientes"
                      label="Cliente"
                    >
                      <template v-slot:append>
                        <v-btn icon small @click.stop="dialogcliente = true">
                          <v-icon>mdi-account-multiple-plus</v-icon>
                        </v-btn>
                      </template>
                    </v-combobox>
                    <v-select
                      filled
                      hide-details=""
                      v-model="vendedor"
                      append-icon="mdi-briefcase-account"
                      :items="Vendedores"
                      color="success"
                      :label="`Mecanico a cargo`"
                    ></v-select>
                    
					<v-card-actions>
						<v-select hide-details="" filled v-model="falla" :items="Fallas" type="text" label="Falla Reportada"></v-select> 
						<v-icon @click="EliminarFalla()" v-if="falla != ''" color="red">mdi-minus  </v-icon>
						<v-edit-dialog
								:return-value.sync="falla"
								@save="AgregarFalla()"
								@cancel="cancel"
								@open="open"
								@close="close"
								large
								>
								<v-icon color="green">mdi-plus  </v-icon> 
							<template v-slot:input>
								<v-text-field
								onfocus="this.select();"
								v-model="falla"
								label="Digite el nombre del Tour Operador y precione Enter para guardar"
								counter
								></v-text-field>
							</template>
						</v-edit-dialog> 
					</v-card-actions>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-text-field
                      clearable
                      filled
                      hide-details=""
                      v-model="serie"
                      label="Serie o Placa"
                    ></v-text-field>
                    <v-text-field
                      clearable
                      filled
                      hide-details=""
                      v-model="marca"
                      type="text"
                      label="Marca"
                    />
					<v-text-field
                      filled
                      hide-details="num"
                      label="Kilometraje"
                      v-model="Kilometraje"
                    />
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-text-field
                      clearable
                      filled
                      hide-details=""
                      v-model="modelo"
                      :rules="[v => !!v || 'El Modelo es requerido']"
                      type="text"
                      label="Modelo"
                    ></v-text-field>
                    <v-text-field
                      clearable
                      filled
                      hide-details=""
                      v-model="referencia"
                      :rules="nameRules"
                      required
                      type="text"
                      label="Color"
                    ></v-text-field>
                    <v-select
                      clearable
                      filled
                      hide-details=""
                      v-model="domicilio"
                      :items="['Si', 'No']"
                      label="Ingreso en Grua"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <div class="grey lighten-2 black--text text-center py-2">
                      Inventario
                    </div>
                  </v-col>

                  <v-col cols="12" md="8" sm="12">
                    <v-row class="py-0">
                      <v-col
                        cols="12"
                        sm="4"
                        md="4"
                        lg="2"
                        v-for="item in inventario"
                        :key="item.name"
                        class="py-1 px-1"
                      >
                        <v-checkbox dense :label="item.name" v-model="item.checked"></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-col>
                  <!-- Columna del Indicador de Combustible -->
                  <v-col cols="12" md="4" sm="12">
                    <div class="fuel-indicator">
                      <div class="car-container">
                        <v-slider
                          v-model="fuelLevel"
                          :max="100"
                          :min="0"
                          direction="vertical"
                          hint="Seleccione nivel de combustible"
                          persistent-hint
                          prepend-icon="mdi-gas-station-outline"
                          append-icon="mdi-gas-station"
                          thumb-label="always"
                        ></v-slider>
                      </div>
                      <!-- Agrega aquí otros elementos si necesitas -->
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <div class="grey lighten-2 black--text text-center py-2">
                      Productos o Servicios \ <strong> Total: {{costo=calcluarTotal}} </strong>
                    </div>
                    <v-combobox
                      :loading="loading"
                      id="buscador"
                      filled
                      clearable
                      onfocus="this.select();"
                      prepend-inner-icon="mdi-briefcase-outline"
                      @keyup="selectProductos()"
                      @change="buscarProductoDirecto()"
                      :items="productos"
                      v-model="codigo"
                      label="Código de producto o servicio"
                    ></v-combobox>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              Item
                            </th>
                            <th class="text-left">
                              Precio
                            </th>
                            <th class="text-left">
                              Eliminar
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in items" :key="item.name">
                            <td>
                              <v-edit-dialog
                                :return-value.sync="item.Nombre"
                                @save="ActualizarItem(item)"
                                large
                              >
                                {{item.Nombre}}
                                <template v-slot:input>
                                  <v-text-field
                                    onfocus="this.select();"
                                    autofocus
                                    v-model="item.Nombre"
                                    label="Nombre"
                                  />
                                </template>
                              </v-edit-dialog>
                            </td>
                            <td>
                              <v-edit-dialog
                                :return-value.sync="item.PrecioUnitario"
                                @save="ActualizarItem(item)"
                                large
                              >
                                {{item.PrecioUnitario}}
                                <template v-slot:input>
                                  <v-text-field
                                    onfocus="this.select();"
                                    autofocus
                                    v-model="item.PrecioUnitario"
                                    label="Precio"
                                  />
                                </template>
                              </v-edit-dialog>
                            </td>
                            <td><v-icon @click="eliminarLinea(item.id)">mdi-delete </v-icon></td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <div>
                      <div class="grey lighten-2 black--text text-center py-2">
                        Daños Pre existentes
                      </div>
                      <div class="car-container" style="position: relative;">
                        <canvas id="carCanvas" style="position: absolute; z-index: 1;"></canvas>
                        <img
                          id="carImage"
                          src="https://www.noah.cr/ImagenesGenerales/DanosCarro.png"
                          alt="Carro"
                          style="position: relative; z-index: 0; width: 100%;"
                        />
                      </div>
                      <v-btn @click="clearCanvas">Limpiar Selecciones</v-btn>
                      <!-- Botón para limpiar las selecciones -->
                    </div>
                  </v-col>
                  <v-col cols="12" sm="6" class="red--text">
                    <v-textarea
                      v-model="nota"
                      auto-grow
                      filled
                      rows="1"
                      hide-details=""
                      row-height="30"
                      label="Nota del Cliente"
                    ></v-textarea>
                    <v-text-field
                      clearable
                      filled
                      hide-details=""
                      v-model="domicilio"
                      type="text"
                      label="Domicilio"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      clearable
                      v-model="adelanto"
                      filled
                      hide-details=""
                      required
                      type="number"
                      label="Adelanto"
                    ></v-text-field>
                    {{error}}
                    <v-btn
                      v-if="IdReparacion == 0"
                      :loading="loading"
                      type="submit"
                      :disabled="!validCliente"
                      color="primary"
                      id="espacioBoton"
                      class="mr-4"
                      style="top: 10px;"
                    >
                      Registrar Orden
                    </v-btn>
                    <v-btn
                      color="teal darken-1"
                      id="espacioBoton"
                      class="mr-4"
                      style="top: 10px; color: white;"
                      @click="limpiar()"
                    >
                      <v-icon> mdi-update </v-icon>
                    </v-btn>
                    <v-btn color="error" id="espacioBoton" @click="dialog = false">
                      <v-icon> mdi-exit-to-app </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" sm="6" class="red--text">
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-container>

        <v-btn @click="clearCanvas">Limpiar Selecciones</v-btn>
        <!-- Botón para limpiar las selecciones -->
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

export default {
	data() {
		return {
		dialog: true,
		search: '',
		idcliente: '',
		referencia: '',
		modelo: '',
		modelCarga: true,
		Fallas:[],
		articulo: '',
		localidadencabezado:[],
		items:[],
		ingresoEnGrua:false,
		Cuentas:null,
		marca: '',
		Placa:'',
		accesorios:[],
		telefonoReceptor:'',
		correoReceptor:'',
		inventario: [
			{ name: 'Gato', checked: false },
			{ name: 'Herramientas', checked: false },
			{ name: 'Triángulos', checked: false },
			{ name: 'Alfombras', checked: false },
			{ name: 'Llanta refacción', checked: false },
			{ name: 'Extintor', checked: false },
			{ name: 'Antena', checked: false },
			{ name: 'Emblemas', checked: false },
			{ name: 'Tapones de rueda', checked: false },
			{ name: 'Cables', checked: false },
			{ name: 'Estéreo', checked: false },
			{ name: 'Encendedor', checked: false },
			{ name: 'Gata', checked: false },
			{ name: 'Estereo o Radio', checked: false },
			// Asegúrate de agregar o quitar elementos según los que tengas en tu inventario real
		],
		estado: '',
		falla: '',
		nota: '',
		costo: 0,
		danios: {
			delanteroIzquierdo: false,
			// Repite para cada área que necesites
		},
		adelanto: 0,
		iconoActivo: false, // Este será tu modelo para el ícono, repite para cada ícono que necesites
		headers: [
			{ text: 'Opciones', value: 'action', sortable: false },
			{ text: 'Id', value: 'id' },
			{ text: 'Cliente', value: 'cliente' },
			{ text: 'Placa', value: 'serie' },
			{ text: 'Marca', value: 'marca' },
			{ text: 'Modelo', value: 'modelo' },
			{ text: 'Tecnico', value: 'tecnico' },
			{ text: 'Estado', value: 'estado', },
			{ text: 'Falla', value: 'falla',  },
			{ text: 'Nota', value: 'nota',  },
			{ text: 'Fecha', value: 'fecha',},
			{ text: 'Usuario', value: 'usuario'},
			{ text: 'Costo', value: 'costo',},    
			{ text: 'Adelanto', value: 'adelanto',},          
		],
		search: '',
			editedIndex: -1,
			id: '',
			idrol:'',
			generos: ["MASCULINO","FEMENINO"],
			tipoDocumentos: [
			'FISICA',
			'JURIDICA',
			'EXTRANJERO',
			],
			roles:[                   
			],
			nombre:'',
			dialogcliente: false,
			correo2: '',
			error: null,
			IdReparacion:0,
			comprobanteModal:false,
			clienteTelefono: "",
			clienteCorreo:"",
			clienteCedula:"",
			correo: '',
			DiasCreditoCliente: 15,
			tipo_documento: '',
			documentos: ['DNI','DIMEX','Cédula Fisica','Cédula Juridica'],
			num_documento: '',
			direccion: '',
			cedula: '',
			edad: 0,
			listasDePrecios: ["Precio Venta", "Precio Detalle", "Precio Central","Precio Mayor", "Precio Mall"],
			listaDePrecio: 'Precio Venta',
			genero: '',
			direccion: '',
			fechanacimiento :'',
			timeouts: [],
			estadosReparaciones:["PENDIENTE","REPARADO","NO SE PUDO REPARAR","ENTREGADO"],
			Vendedores : [],
			usuarios:[],    
			telefono: '',
			productos:[],
			localidades:[],
			localidadesColor:[],
			idusuario: '',
			idLocalidadCliente: null,
			localidad: null,
			condicion: true,
			cedulaRules: [
			value => !!value || 'La cédula es requerida'
			],
			nameRules: [
				value => !!value || 'El nombre es requerido',
				value => (value && value.length >= 3) || 'Debe ingresar 3 carácteres como mínimo',
			],
			emailRules: [
			v => !!v || 'El correo electrónico es requerido',
			v => /.+@.+\..+/.test(v) || 'El correo electrónico es requerido debe ser válido',
			] ,
			email: '',
			password:'',
			fuelLevel:50,
			actPassword:false,
			vendedor: '',
			nombreComercial: "",
			nombreFantasia: "",
			tipoDocumento : "",
			codigo:0,
			passwordAnt:'',
			valida: 0,
			validCliente: false,
			Kilometraje:0,
			domicilio:"",
			points: [],
			serie:"",
			clienteNombre:"",
			validaMensaje:[],
			loading: false,
			NoEmpleado: '',
			adModal: 0,
			celular:"",
			limiteCredito: 0,
			adAccion: 1,
			adNombre: '',
			adId: ""  ,
			isDrawing: false,
      		context: null,
			clientes: [],
			desserts: [],
		};
	},
	methods: {
		adjustCanvasSize() {
		const img = document.getElementById('carImage');
		const canvas = document.getElementById('carCanvas');
		canvas.width = img.clientWidth;
		canvas.height = img.clientHeight;
		},
		initCanvas() {
		const img = document.getElementById('carImage');
		const canvas = document.getElementById('carCanvas');
		const ctx = canvas.getContext('2d');

		// Ajustar el tamaño del canvas cuando la imagen esté completamente cargada
		if (img.complete) {
			this.adjustCanvasSize();
		} else {
			img.onload = () => {
			this.adjustCanvasSize();
			};
		}

		canvas.addEventListener('click', (e) => {
			const rect = canvas.getBoundingClientRect();
			const x = e.clientX - rect.left;
			const y = e.clientY - rect.top;
			this.points.push({ x, y });
			this.drawPoints(ctx);
		});
		},
		drawPoints(ctx) {
		ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
		this.points.forEach(point => {
			ctx.beginPath();
			ctx.arc(point.x, point.y, 5, 0, 2 * Math.PI);
			ctx.fillStyle = 'red';
			ctx.fill();
			ctx.stroke();
		});
		},
		clearCanvas() {
		const canvas = document.getElementById('carCanvas');
		const ctx = canvas.getContext('2d');
		ctx.clearRect(0, 0, canvas.width, canvas.height); // Limpiar el canvas
		this.points = []; // Vaciar los puntos seleccionados
		},

		async RegistrarOrden() {
		this.loading = true;
		this.error = null;
		let fechaActual = new Date();
		let fechaISO = fechaActual.toISOString();
		fechaISO = fechaISO.replace('Z', '');
		let accesoriosSeleccionados = this.inventario
			.filter(item => item.checked)
			.map(item => item.name)
			.join(', ');
		let header = { "Authorization": "Bearer " + this.$store.state.token };
		let configuracion = { headers: header };
		let me = this;
		await axios.post('api/Reparaciones', {
			'Cliente': this.idcliente,
			'Articulo': this.articulo,
			'Domicilio': this.domicilio,
			'Marca': this.marca,
			'Modelo': this.modelo,
			'Tecnico': this.vendedor,
			'Falla': this.falla,
			'Nota': this.correo2,
			'Fecha': fechaISO,
			'Serie': this.serie,
			'Adelanto': this.adelanto,
			'Referencia': this.referencia,
			'Capacidad': this.fuelLevel,
			'Accesorios': accesoriosSeleccionados,
			'Usuario': this.$store.state.usuario.nombre,
			'Estado': 'Pendiente',
			'Costo': this.costo,
			'Localidad': this.$store.state.usuario.localidad,
			'Productos': this.items,
			'Danos': JSON.stringify(this.points)  // Guardar los puntos seleccionados
		}, configuracion).then(function (response) {
			me.Mensaje = 'Registrado con exito ' + response;
			me.snackbar = true;
			me.dialog = false;
			me.listar();
			me.limpiar();
			me.loading = false;
		}).catch(err => {
			this.loading = false;
			if (err.response.status == 400) {
			this.error = "Correo ocupado por otro cliente";
			} else if (err.response.status == 404) {
			this.error = "Cédula Ocupada por otro cliente";
			} else {
			this.error = "Validar Campos Vacios";
			}
		});
		},
		async listar(){
			let me=this;
			this.localidad = me.$store.state.usuario.localidad;
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			await axios.get('api/Reparaciones/ListarReparaciones/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
				//console.log(response);
				me.usuarios=response.data;
				me.modelCarga = false;
				me.SelectFichaProducto();
			}).catch(function(error){
				console.log(error);
			});
		},
		selectMecanicos(){
			this.vendedor = this.$store.state.usuario.nombre;
			let me=this;
			let header={"Authorization" : "Bearer " };
			let configuracion= {headers : header};
			var proveedoresArray=[];
			axios.get('api/Usuarios/SelectMecanicosLocalidad/'+me.$store.state.usuario.localidad,configuracion).then(function(response){
				proveedoresArray=response.data;
				proveedoresArray.map(function(x){
					me.Vendedores.push(x.nombre);
				});
			}).catch(function(error){
				console.log(error);
			});
		},
		listarLocalidad(){
			let me =this;
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			axios.get('api/Localidades/ListarFiltrada/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
				//console.log(response);
				me.localidadencabezado=response.data;
				}).catch(function(error){
					console.log(error);
				});
				
		},
		selectProductos(){
			let me=this;
			let Objeto = [];
			let myInput = document.getElementById('buscador');
			myInput = myInput.value;
			let cant = myInput.length;
			if ( cant > 2 ) {
				var search_term = myInput;
				
				this.timeouts.map( timeout => {
					window.clearTimeout( timeout );
				} );
				this.timeouts.push(
					setTimeout( () => {
						this.loading = true;
						axios.get('api/Productos/BuscadorEnter/'+this.$store.state.usuario.localidad+'/'+myInput).then( response => {
							Objeto = response.data
								// Cargar Productos 
								Objeto.map(function(x){
									me.productos.push({text:x.descripcion, value:x.codigoProducto});
								});
								me.loading = false;
							} )
							.catch( error => { } );
					}, 500 )
				);
			} else {
				this.timeouts.map( timeout => {
					window.clearTimeout( timeout );
				} );
				this.productos = [];
			}
		},
		buscarProductoDirecto(){
			let me=this;
			this.codigo = this.codigo.value !== undefined ? this.codigo.value : this.codigo;
			if (this.codigo != "")
			{
				let header={"Authorization" : "Bearer "};
				let configuracion= {headers : header};
				axios.get('api/Productos/BuscarCodigoVenta/'+this.codigo+'/'+this.$store.state.usuario.localidad,configuracion)
				.then(function(response){
					me.agregarDetalleDirecto(response.data);
				}).catch(function(error){
					//alert(error);
					console.log(error);
				});
			}
		},
		agregarDetalleDirecto(data = []){
			var ImpuestoIncluido= "I.V.N.I."
			var item = data;
			if (data['codigoProducto'] != '' && data['codigoProducto'] != undefined)
			{
				this.codigo = data['codigoProducto'];
			}
				if (this.codigo != '')
				{ 
					if(data['estadoInventario'] == true){
					ImpuestoIncluido= "I.V.I"
					}
					this.items.push(
						{
						id: this.items.length + 5, 
						idarticulo:data['codigoProducto'],
						Nombre:data['descripcion'],
						cantidad:1,
						PrecioUnitario:data['precioVenta'],
						Cabys:data['codigoRaiz'],
						ValorImpuesto:data['valorImpuesto']
						}
						);
						this.codigo = '';
					if(this.IdReparacion != 0){
						this.AgregarProducto(item);
					}
				}
				else
				{
					this.Mensaje = "Debe digitar un código de producto o servicio.";
					this.snackbar = true;
			}
		},
		async AgregarProducto(item){
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			let me=this;
			await axios.post('api/ProductosComprasMaps',{
				'Nombre':item.descripcion,
				'cantidad':1,
				'CodigoProducto':item.codigoProducto,
				'PrecioUnitario':item.precioVenta,
				'Cabys':item.codigoRaiz,
				'ValorImpuesto':item.valorImpuesto,
				'Clave':this.IdReparacion,
			},configuracion).then(function(response){
				//alert("Orden Registrada con exito");
				me.Mensaje = 'Item Registrado con exito en Orden  '+me.IdReparacion+response;
				me.snackbar = true;          
				me.selectItems(me.IdReparacion)  ;     
			}).catch(err => {
				this.loading =false;
				//console.log(err.response);
				if (err.response.status==400){
					this.error="Correo ocupado por otro cliente";
				} else if (err.response.status==404){
					this.error="Cédula Ocupada por otro cliente";
				}else{
					this.error="Validar Campos Vacios";
				}
			});
		},
		async ActualizarOrden(item){
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			let me=this;
			await axios.put('api/Reparaciones/'+item.id,{
				'Nombre':item.cliente,
				'CodigoProducto':item.articulo,
				'PrecioUnitario':item.id,
				'Domicilio': item.domicilio,
				'Marca': item.marca,
				'Modelo':item.modelo,
				'Tecnico': item.tecnico,
				'Falla':item.falla,
				'Nota': item.nota,
				//'Fecha':fechaISO, // Ingresar Fecha aqui
				'Serie':item.serie,
				'Adelanto':item.adelanto,
				'Referencia':item.referencia,
				'Usuario':this.$store.state.usuario.nombre,
				'Accesorios':item.accesorios,
				'Estado':item.estado,
				'Danos':item.danos,
				'Costo':item.costo,
				'Capacidad':item.capacidad,
				'Localidad':this.$store.state.usuario.localidad,
			},configuracion).then(function(response){
				//alert("Orden Registrada con exito");
				me.Mensaje = 'Actualizado con exito '+response;
				me.snackbar = true;
				me.dialog = false;
				me.listar();
				me.loading =false;                      
			}).catch(err => {
				this.loading =false;
				//console.log(err.response);
				if (err.response.status==400){
					this.error="Correo ocupado por otro cliente";
				} else if (err.response.status==404){
					this.error="Cédula Ocupada por otro cliente";
				}else{
					this.error="Validar Campos Vacios";
				}
			});

		},
		eliminarLinea(id) {
			this.items = this.items.filter(e => e.id != id)
			if(this.IdReparacion != 0){
				this.EliminarProductoCompra(id);
			}
		},
		async EliminarProductoCompra(item){
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			let me=this;
				await axios.delete('api/ProductosComprasMaps/'+item,{
				},configuracion).then(function(response){
					//alert("Orden Registrada con exito");
					me.Mensaje = 'Registro eliminado con exito '+response;
					me.snackbar = true;                  
				}).catch(err => {
					this.loading =false;
					//console.log(err.response);
					if (err.response.status==400){
						this.error="Problema eliminando producto";
					} else if (err.response.status==404){
						this.error="Problema eliminando";
					}else{
						this.error="NO se pudo eliminar";
					}
				});
		},
		async SelectFichaProducto(){
			let me=this;
			this.Fallas = [];
			var Data=[];
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			await axios.get('api/FichaProductos/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
				Data=response.data;
				for(var i = 0; i < Data.length; ++i){
					
					if(Data[i].valor == "Fallas"){
						me.Fallas.push(Data[i].nombre);
					} 
				}
			})
		},
		async AgregarFalla () {
			if(this.falla == "" || this.falla == null){
				this.Mensaje = "Debe Digitar el nombre del Tour Operador para continuar";
				this.snackbar = true;
				return;
			}
			var self = this;
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			await axios.post('api/FichaProductos',{
				'Usuario': this.$store.state.usuario.nombre,
				'Nombre':this.falla,
				'Valor':"Fallas", 
				'Localidad':this.$store.state.usuario.localidad,
				'CodigoProducto':"77"
			},configuracion).then(function (response) {
				self.Mensaje = "Tour Operador Agregada correctamente, Seleccione nuevamente la agencia requerida";
				self.snackbar = true;
				self.SelectFichaProducto();
			}).catch(function(error){
				self.Mensaje = "Lo sentimos, error Tour Agencia. "+error;
				self.snackbar = true;}); 
		},
		async ActualizarItem(item){
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			let me=this;
				await axios.put('api/ProductosComprasMaps/'+item.id,{
					'Nombre':item.Nombre,
					'cantidad':1,
					'CodigoProducto':item.idarticulo,
					'PrecioUnitario':item.PrecioUnitario,
					'ValorImpuesto':item.ValorImpuesto,
					'Clave':this.IdReparacion,
					'Id':item.id,
				},configuracion).then(function(response){
					//alert("Orden Registrada con exito");
					me.Mensaje = 'Registro actualizado con exito '+response;
					me.snackbar = true;                  
				}).catch(err => {
					this.loading =false;
					//console.log(err.response);
					if (err.response.status==400){
						this.error="Problema eliminando producto";
					} else if (err.response.status==404){
						this.error="Problema eliminando";
					}else{
						this.error="NO se pudo eliminar";
					}
				});
		},
		async obtenerCliente(){
			var self = this;
			var clientesArray=[];
			var clientePrevio="";
			if (this.idcliente.text2 === undefined)
			{
				clientePrevio = this.idcliente;
			}
			else
			{
				clientePrevio = this.idcliente.text2;
				this.idcliente = this.idcliente.text2
			}
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			await axios.get('api/Empleados/ObtenerCliente', { params: {'clienteFactura': clientePrevio}},configuracion).then(function(response){
				clientesArray=response.data;
				self.clienteNombre = clientesArray[0].nombre;
				self.clienteCorreo = clientesArray[0].correo;
				self.clienteTelefono = clientesArray[0].telefono;
				self.clienteCedula = clientesArray[0].cedula;
				self.nombreComercial= clientesArray[0].nombreFantasia;
			})
		},
		async ActualizarOrden(item){
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			let me=this;
			await axios.put('api/Reparaciones/'+item.id,{
					'Cliente':item.cliente,
					'Articulo':item.articulo,
					'ID':item.id,
					'Domicilio': item.domicilio,
					'Marca': item.marca,
					'Modelo':item.modelo,
					'Tecnico': item.tecnico,
					'Falla':item.falla,
					'Nota': item.nota,
					//'Fecha':fechaISO, // Ingresar Fecha aqui
					'Serie':item.serie,
					'Adelanto':item.adelanto,
					'Referencia':item.referencia,
					'Usuario':this.$store.state.usuario.nombre,
					'Accesorios':item.accesorios,
					'Estado':item.estado,
					'Danos':item.danos,
					'Costo':item.costo,
					'Localidad':this.$store.state.usuario.localidad,
				},configuracion).then(function(response){
					//alert("Orden Registrada con exito");
					me.Mensaje = 'Actualizado con exito '+response;
					me.snackbar = true;
					me.dialog = false;
					me.listar();
					me.loading =false;                      
				}).catch(err => {
					this.loading =false;
					//console.log(err.response);
					if (err.response.status==400){
						this.error="Correo ocupado por otro cliente";
					} else if (err.response.status==404){
						this.error="Cédula Ocupada por otro cliente";
					}else{
						this.error="Validar Campos Vacios";
					}
				});
		},
		async EliminarOrden(item){
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			let me=this;
			if (confirm("¿Estás seguro de que deseas eliminar esta orden?")) {
				me.loading = true;
				await axios.delete('api/Reparaciones/'+item.id,{
					'Cliente':item.cliente,
					'Articulo':item.articulo,
					'ID':item.id,
					'Domicilio': item.domicilio,
					'Marca': item.marca,
					'Modelo':item.modelo,
					'Tecnico': item.tecnico,
					'Falla':item.falla,
					'Nota': item.nota,
					'Serie':item.serie,
					'Adelanto':item.adelanto,
					'Referencia':item.referencia,
					'Usuario':this.$store.state.usuario.nombre,
					'Accesorios':item.accesorios,
					'Estado':item.estado,
					'Costo':item.costo,
					'Localidad':this.$store.state.usuario.localidad,
				},configuracion).then(function(response){
					//alert("Orden Registrada con exito");
					me.Mensaje = 'Actualizado con exito '+response;
					me.snackbar = true;
					me.dialog = false;
					me.listar();
					me.loading =false;                      
				}).catch(err => {
					this.loading =false;
					//console.log(err.response);
					if (err.response.status==400){
						this.error="Correo ocupado por otro cliente";
					} else if (err.response.status==404){
						this.error="Cédula Ocupada por otro cliente";
					}else{
						this.error="Validar Campos Vacios";
					}
				});
			}
		},
		close () {
			this.dialog = false;
			this.limpiar();
		},
      
		limpiar(){
			this.nombre = "";
			this.tipoDocumento = "";
			this.cedula = "";
			this.genero = "",
			this.edad = 0,
			this.idLocalidadCliente = "";
			this.telefono = "";
			this.correo = "";
			this.correo2 = "";
			this.limiteCredito = 0;
			this.DiasCreditoCliente = 0;
			this.idcliente = '';
			this.articulo = '';
			this.domicilio = '';
			this.marca = '';
			this.modelo = '';
			this.vendedor = '';
			this.falla = '';
			this.correo2 = '';
			this.serie= '';
			this.adelanto = 0;
			this.referencia = '';
			this.costo = 0;
			this.items = [];
		},
		BuscarPersona(){
			this.modelproveedor = true;
			let me=this;
			var familiasArray=[];
			axios.get('https://apis.gometa.org/cedulas/'+this.cedula+'&key=u0vSHKh0dVRckNH').then(function(response){
				familiasArray=response.data;
				me.nombre = familiasArray.results[0].fullname;
				me.tipoDocumento = familiasArray.results[0].guess_type
				
				
			}).catch(function(error){
			this.snackbar = true;
			this.Mensaje = 'Error de conexión con el TSE, digite los datos de forma manual'
				console.log(error);
			});
		},
		guardar() {
			this.loading = true;
			this.error=null;
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			
				//Código para guardar
				let me=this;
				axios.post('api/Empleados/Crear',{
						'Nombre':this.nombre,
						'TipoCedula':this.tipoDocumento,
						'Cedula': this.cedula,
						'Localidad':this.$store.state.usuario.localidad,
						'Telefono':this.telefono,
						'Periodo': this.DiasCreditoCliente,
						'Correo':this.correo,
						'Fecha':this.fechanacimiento,
						'Correo2': this.correo2,
						'Edad':this.edad,
						'ListaPrecio':this.listaDePrecio,
						'Genero':this.genero,
						'Cantidad':this.limiteCredito,
						'Facebook':this.direccion,
						'Vendedor':this.vendedor,
						'NombreFantasia':this.nombreFantasia,
						'Celular':this.celular,
				},configuracion).then(function(response){
					me.limpiar(); 
					me.dialogcliente = false;
					me.loading =false;                       
				}).catch(err => {
					this.loading =false;
					//console.log(err.response);
					if (err.response.status==400){
						this.error="Correo ocupado por otro cliente";
					} else if (err.response.status==404){
						this.error="Cédula Ocupada por otro cliente";
					}else{
						this.error="Validar Campos Vacios";
					}
				});
		},
		NuevaOrden(){
			this.IdReparacion = 0;
		},
		async EliminarFalla () {
			if(this.falla == ""){
				this.Mensaje = "Debe Digitar el nombre de la falla para continuar";
				this.snackbar = true;
				return;
			}
			if(confirm("Esta seguro que quiere eliminar la falla: "+this.falla)){
				var self = this;
				let header={"Authorization" : "Bearer "};
				let configuracion= {headers : header};
				await axios.delete('api/FichaProductos/'+this.falla,{
					'Nombre':this.falla,
				},configuracion).then(function (response) {
					self.Mensaje = "Falla Eliminado correctamente, Seleccione nuevamente la falla requerida";
					self.snackbar = true;
					self.TourOperador = '';
					self.SelectFichaProducto();
				}).catch(function(error){
					self.Mensaje = "Lo sentimos, error eliminando la falla. "+error;
					self.snackbar = true;}); 
			}
			
		},
		editItem (item) {
			this.IdReparacion = item.id;
			this.idcliente=item.cliente;
			this.articulo=item.articulo;
			this.domicilio = item.domicilio;
			this.marca=item.marca;
			this.modelo = item.modelo;
			this.vendedor=item.tecnico;
			this.falla=item.falla;
			this.correo2 = item.nota;
			this.serie=item.serie;
			this.adelanto=item.adelanto;
			this.referencia = item.referencia;
			this.fuelLevel = item.capacidad;
			const accesoriosArray = item.accesorios ? item.accesorios.split(',') : [];
			this.usuario= item.usuario;
			this.estado=item.estado;
			this.costo=item.costo;
			this.celular= item.celular;
			this.dialog = true;
			this.vendedor = item.vendedor;
			  // Recuperar los puntos de daños y dibujarlos en el canvas
			const canvas = document.getElementById('carCanvas');
			const ctx = canvas.getContext('2d');
			const danos = JSON.parse(item.danos);
			this.points = danos;
			this.drawPoints(ctx);
			// Actualizar el arreglo de inventario para marcar los items seleccionados
			this.inventario.forEach(invItem => {
				invItem.checked = item.accesorios.includes(invItem.name);
			});
			this.selectItems(item.id);
		},
		selectItems(id){
			let header={"Authorization" : "Bearer " };
			let configuracion= {headers : header};
			let proveedoresArray = [];
			this.items = [];
			let me = this;
			axios.get('api/ProductosComprasMaps/Select/'+id,configuracion).then(function(response){
				proveedoresArray=response.data;
				proveedoresArray.map(function(x){
					me.items.push({ 
						Nombre: x.nombre,
						PrecioUnitario:x.precioUnitario,
						Cabys:x.cabys,
						idarticulo:x.codigoProducto,
						Clave:x.clave,
						id:x.id,
					}); 
				});
			}).catch(function(error){
				console.log(error);
			});
		},
		validar(){
			this.valida=0;
			this.validaMensaje=[];

			if (this.nombre.length<3 || this.nombre.length>100){
				this.validaMensaje.push("El nombre debe tener más de 3 caracteres y menos de 100 caracteres.");
			}
			if (!this.idrol){
				this.validaMensaje.push("Seleccione un rol.");
			}
			if (!this.tipo_documento){
				this.validaMensaje.push("Seleccione un tipo documento.");
			}

			if (!this.email){
				this.validaMensaje.push("Ingrese el email del usuario.");
			}
			if (!this.password){
				this.validaMensaje.push("Ingrese el password del usuario.");
			}
			if (this.validaMensaje.length){
				this.valida=1;
			}
			return this.valida;
        },
		select(){
			let me=this;
			var clientesArray=[];
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
				axios.get('api/Empleados/SelectEmpleadosLocalidad/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
				clientesArray=response.data;
				clientesArray.map(function(x){
					me.clientes.push({text: (x.nombre+' | '+x.nombreFantasia), text2:x.nombre,value:x.idEmpleado});
				});
			})
		},
	},
	computed: {
		inventarioFiltrado() {
			return this.inventario.filter(item => item.checked);
		},
		calcluarTotal(){
			var resultado = 0;
			for(var i=0;i<this.items.length;i++){
				resultado += parseInt(this.items[i].PrecioUnitario);
			}
			return resultado;
		},

    },
	mounted() {
		this.initCanvas();
		
	},
	created () {
		this.listar();
		this.selectMecanicos();
		this.select();
		this.listarLocalidad();
	},
};
</script>
