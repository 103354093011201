<template>
	<v-card>
    <v-card-title>
      Nutrition
      <v-spacer></v-spacer>
	  <v-btn @click="connect3()"> Test Orbe </v-btn>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="desserts"
      :search="search"
    ></v-data-table>
  </v-card>
</template>
<script scoped>
import axios from 'axios'
import VueExcelXlsx from "vue-excel-xlsx";
import Vue from "vue";
import QrcodeVue from 'qrcode.vue'

Vue.use(VueExcelXlsx);
  export default {
    data: () => ({
	text: 'Dessert (100g serving)',
            align: 'start',
            sortable: false,
            value: 'name',
	search: '',
    headers: [
          {
            text: 'Dessert (100g serving)',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          { text: 'Calories', value: 'calories' },
          { text: 'Fat (g)', value: 'fat' },
          { text: 'Carbs (g)', value: 'carbs' },
          { text: 'Protein (g)', value: 'protein' },
          { text: 'Iron (%)', value: 'iron' },
        ],
    }),
    components: {
      QrcodeVue,
    },

    computed: {
    formTitle () {
            return this.editedIndex === -1 ? 'Nuevo usuario' : 'Actualizar usuario'
    }
    
    },
    

    watch: {
      dialog (val) {
        val || this.close()
      },
      
    },

    created () {
		this.connect();
    },

    methods: {
		
		connect(){
		var self = this;
        const sampleHeaders = {
        'Name': 'connect',
        'Binding': 'wspgBinding',
        'Endpoint': 'https://capi.orbebooking.com/OAF/AOBA-XML',
        'Content-Type': 'text/xml;charset=UTF-8',
        'soapAction': 'urn:ws#connect',
        'Style': 'rpc',
        };
        var str = 	`<ReservationsRequest>
						<Username>NoahSuitree</Username>
							<Password>Geyah8ehaugheyrq3i8</Password>
						<HotelCode>NOAHSUITREE</HotelCode>
					</ReservationsRequest>`;

			        function createCORSRequest(sampleHeaders,method, url) {
			                    var xhr = new XMLHttpRequest();
			                    if ("withCredentials" in xhr) {
			                        xhr.open(sampleHeaders,method, url, false);
			                    } else if (typeof XDomainRequest != "undefined") {
			                        alert
                              xhr.responseType = 'xml';
			                        xhr = new XDomainRequest();
			                        xhr.open(method,url);
			                    } else {
			                        alert("CORS not supported");
			                        xhr = null;
			                    }
			                    return xhr;
			                }
			        var xhr = createCORSRequest("POST", "https://capi.orbebooking.com/OAF/AOBA-XML");
			        if(!xhr){
			         console.log("XHR issue");
			         return;
			        }

							xhr.onload = function (){
					var results = xhr.responseText;
					var var1 = $(results).find("return").prevObject[1].innerText;
					var tok = $(var1).find("value")[0].innerText; 
					self.connect3(tok);
							}

							xhr.setRequestHeader('Content-Type', 'text/xml');
							xhr.send(str);
			},
        connect3(){
			var xmlhttp = new XMLHttpRequest();
				xmlhttp.open('POST', 'https://capi.orbebooking.com/OAF/AOBA-XML', true);

				// build SOAP request
				var sr =`<ReservationsRequest>
						<Username>NoahSuitree</Username>
							<Password>Geyah8ehaugheyrq3i8</Password>
						<HotelCode>NOAHSUITREE</HotelCode>
					</ReservationsRequest>`;

			xmlhttp.onreadystatechange = function () {
				if (xmlhttp.readyState == 4) {
					if (xmlhttp.status == 200) {
						alert('REQUEST SENT. CHECK FOR RESPONSE.');
					}
				}
			}

			// Send the POST request
			xmlhttp.setRequestHeader('Content-Type', 'text/xml');
			xmlhttp.setRequestHeader('Authentication-Type', 'Preemptive');
			httpReq.setRequestHeader('Access-Control-Allow-Headers', '*');
			httpReq.setRequestHeader('Access-Control-Allow-Origin', '*');
			xmlhttp.send(sr);
		},
        listarDetalles(){
                let me=this;
                let header={"Authorization" : "Bearer " + this.$store.state.token};
                let configuracion= {headers : header};
                axios.get('api/Reservas/ListarReserva/'+this.$route.params.NoReserva+'/PaddleBoard',configuracion).then(function(response){
                    console.log(response);
                }).catch(function(error){
					alert("Problema cargando datos de la reserva." +error)
                });
            },
        
    
      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.desserts[this.editedIndex], this.editedItem)
        } else {
          this.desserts.push(this.editedItem)
        }
        this.close()
      },
    },
  }

</script>