import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  theme: {
    themes: {
      light: {
        primary: '#1F4168',
        secondary: '#aebcd3',
        accent: '#424242',
        error: '#FF1744',
        info: '#42a5f6',
        success: '#2797ce',
        warning: '#C8B81F',
        boton: '#3d464d',
        var: '#ECEFF1',
        button2: '#23262d'
      },
      dark: {
        primary: "#199349",
        anchor: "#199349",
      },
    },
  },
});
