<template>
  <v-data-table
    :headers="headers"
    :items="usuarios"
    :search="search"
    sort-by="nombre"
    class="elevation-1"

  >
    <template v-slot:item.condicion="{ item }">
      <v-chip :color="getColor(item.condicion)" dark>{{ item.condicion }}</v-chip>
    </template>
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Usuarios</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
		<template>
          <div class="text-center">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-width="200"
              offset-x
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on" v-bind="attrs" text rounded > <v-icon  >mdi-account</v-icon></v-btn>
              </template>

              <v-card>
                <v-list>
                  <v-list-item>
                    <v-list-item-avatar>
                      <img src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light">
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title style="font-weight:bold;">1231</v-list-item-title>
                      <v-list-item-subtitle>sdf</v-list-item-subtitle>
                    </v-list-item-content>
					<v-card-subtitle> V3.0 </v-card-subtitle>

                    <v-list-item-action>
                      <v-btn
                        :class="fav ? 'red--text' : ''"
                        icon
                        @click="fav = !fav"
                      >
                        <v-icon>mdi-heart</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>

                <v-divider></v-divider>

                <v-list>
                  <v-select class="mt-5" prepend-icon="mdi-security" v-model="Localidad"
					:items="Localidades" style="height=30px;" label="Cambie de sucursal">
				</v-select>
				<v-text-field  prepend-icon="mdi-security" label="Contraseña" v-model="password"  /> 
                    </v-list>

                <v-card-actions>
					
                   	<v-spacer></v-spacer>

					<v-btn @click="menu = false" color="primary" text  >Cerrar</v-btn>
					<v-spacer/>
					
					<v-btn text @click="CambiarSucursal()">Cambiar Localidad</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </div>
        </template>
       <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-dialog :loading="loading" v-model="dialog" max-width="600">
          <template v-slot:activator="{ on }">
              <v-btn color="success" dark class="mb-2" v-on="on">Crear Usuario</v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container grid-list-md>
                  <v-layout wrap>
                  <v-flex xs12 sm6 md6>
                      <v-text-field prepend-icon="mdi-account-circle" v-model="nombre" label="Nombre">
                        
                      </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                      <v-text-field prepend-icon="mdi-card" v-model="num_documento" label="Cédula">
                      </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                      <v-select prepend-icon="mdi-blur" v-model="tipo_documento"
                      :items="documentos" label="Tipo Documento">
                      </v-select>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                      <v-text-field prepend-icon="mdi-phone" type="number" v-model="telefono" label="Teléfono">
                      </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                      <v-text-field prepend-icon="mdi-mail" v-model="email" label="Correo Electrónico">
                      </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field prepend-icon="mdi-lock" type="password" v-model="password" label="Contraseña">
                    </v-text-field>
                    </v-flex>
                  <v-flex xs6 sm6 md6>
                    <v-select prepend-icon="mdi-security" v-model="idrol"
                    :items="roles" label="Rol">
                    </v-select>
				</v-flex>
				<v-flex xs6 sm6 md6>
                    <v-select prepend-icon="mdi-bank" v-model="Localidad"
                    :items="Localidades" label="Localidad">
                    </v-select>
				</v-flex>
                   <v-flex xs12 sm6 md12>
                        <v-text-field prepend-icon="mdi-text" v-model="direccion" label="Puesto">
                        </v-text-field>
                        </v-flex>
                  <v-flex xs12 sm12 md12 v-show="valida">
                      <div class="red--text" v-for="v in validaMensaje" :key="v" v-text="v">
                      </div>
                  </v-flex>
                  </v-layout>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :loading="loading" color="error"  @click="close">Cancelar</v-btn>
              <v-btn :loading="loading" color="success"  @click="guardar">Guardar</v-btn>
            </v-card-actions>
            
             </v-card>
        </v-dialog>
		
        </v-toolbar>
      </template>
    <template v-slot:item.action="{ item }">
      <v-icon
        small
        class="mr-2"
        color="primary" 
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <template v-if="item.condicion == true" >
		<v-icon
			small
			color="error"
			@click="desactivar(item)"
		> 
			mdi-delete
		</v-icon>
    </template>
	<template v-if="item.condicion == false" >
		<v-icon
			small
			color="success"
			@click="activar(item)"
		> 
			mdi-check-circle
		</v-icon>
    </template>
     
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">Reset</v-btn>
    </template>
  </v-data-table>
</template>

<script>
import axios from 'axios'
  export default {
    data: () => ({
      
      dialog: false,
      search: '',
      headers: [
        { text: 'Opciones', value: 'action', sortable: false },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Rol', value: 'rol' },
        { text: 'Tipo Documento', value: 'tipo_documento' },
        { text: 'Número Documento', value: 'num_documento', sortable: false  },
        { text: 'Puesto', value: 'direccion', sortable: false  },
        { text: 'Teléfono', value: 'telefono', sortable: false  },
        { text: 'Email', value: 'email', sortable: false  },
        { text: 'Estado', value: 'condicion', sortable: false  },       
        { text: 'Localidad', value: 'localidad',   }                     
       ],
      search: '',
        editedIndex: -1,
        id: '',
        idrol:'',
        roles:[   {text:"Supervisor",value:2},{text:"Vendedor",value:3},{text:"Cliente",value:4},{text:"Contador",value:6},
		{text:"Bodeguero",value:7} ,{text:"Mensajero",value:8},{text:"Mecanico",value:17}, ],
        nombre:'',
        tipo_documento: '',
        documentos: ['DNI','DIMEX','Cédula Fisica','Cédula Juridica'],
        num_documento: '',
        direccion: '',
        usuarios:[],    
        telefono: '',
		loading: false,
        localidades:[],
		menu:false,
        localidadesColor:[],
        idusuario: '',
		dialogSwitch:true,
		Localidad: '',
		Localidades: [],
        idLocalidadCliente: null,
        localidad: null,
        condicion: true,
        email: '',
        password:'',
        actPassword:false,
        passwordAnt:'',
        valida: 0,
        validaMensaje:[],
        adModal: 0,
        adAccion: 1,
        adNombre: '',
        adId: ""  ,
      desserts: [],
      editedIndex: -1,
    }),

    computed: {
      formTitle () {
                return this.editedIndex === -1 ? 'Nuevo usuario' : 'Actualizar usuario'
        }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      
    },

    created () {
      this.listar();
      this.listarAsociadas();
    },

    methods: {
        getColor (condicion) {
        if (condicion == '1') return 'green'
        else  return 'red'
      },
       listar(){
            let me=this;
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
            axios.get('api/Usuarios/ListarFiltrado/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
                //console.log(response);
                me.usuarios=response.data;
            }).catch(function(error){
                console.log(error);
            });
        },
      close () {
        this.dialog = false;
        this.limpiar();
          },
       limpiar(){
                this.id="";
                this.idrol="";
                this.nombre="";
                this.tipo_documento="";
                this.num_documento="";
                this.direccion="";
                this.telefono="";
                this.email="";
                this.password="";
                this.passwordAnt="";
                this.actPassword=false;
                this.editedIndex=-1;
            },
            guardar () {
                if (this.validar()){
                    return;
                }
				this.loading = true;
                let header={"Authorization" : "Bearer " + this.$store.state.token};
                let configuracion= {headers : header};
                if (this.editedIndex > -1) {
                    //Código para editar
                    //Código para guardar
                    let me=this;
                    if (me.password!=me.passwordAnt){
                        me.actPassword=true;
                    }
                    axios.put('api/Usuarios/Actualizar',{
                        'idusuario':me.id,
                        'idrol':me.idrol,
                        'nombre':me.nombre,
                        'tipo_documento': me.tipo_documento,
                        'num_documento':me.num_documento,
                        'direccion':me.direccion,
                        'telefono': me.telefono,
                        'email':me.email,
                        'password':me.password,
                        'act_password':me.actPassword,
                        'localidad':me.Localidad, 
                    },configuracion).then(function(response){

                        me.close();
                        me.listar();
                        me.limpiar();                        
                    }).catch(function(error){
						me.valida = true;
						alert("No se pudo actualizar, verifique que todos los campos existen y el correo no esta repetido con otro usuario;")
                        console.log(error);
						alert("Error: "+error+" Por favor seleccione otro correo y valide los campos")
                    });
                } else {
                    //Código para guardar
                    let me=this;
                    axios.post('api/Usuarios/Crear',{
                        'idrol':me.idrol,
                        'nombre':me.nombre,
                        'tipo_documento': me.tipo_documento,
                        'num_documento':me.num_documento,
                        'direccion':me.direccion,
                        'telefono': me.telefono,
                        'empresa': me.empresa,
                        'email':me.email,
                        'password':me.password,
                        'localidad':me.Localidad, 
                    },configuracion).then(function(response){
                        me.close();
                        me.listar();
                        me.limpiar();                        
                    }).catch(err => {
                    me.loading =false;
                    //console.log(err.response);
					me.valida = 1;
                    if (err.response.status==400){
						
						alert("No es un email válido");
                    } else if (err.response.status==404){
						alert("No existe el usuario o sus datos son incorrectos");
                    }else{
						alert("Ocurrió un error, intenta con otro correo");
                    }
                    //console.log(err)
                })
                }
            },

      editItem (item) {
        this.id=item.idusuario;
        this.idrol=item.idrol;
        this.nombre=item.nombre;
        this.tipo_documento=item.tipo_documento;
        this.num_documento=item.num_documento;
        this.direccion=item.direccion;
        this.telefono=item.telefono;
        this.email=item.email;
        this.password=item.password_hash;
        this.passwordAnt=item.password_hash;
        this.localidad=item.localidad;
        this.editedIndex=1;
        this.dialog = true
      },
	CambiarSucursal(){
		if(this.password == ''){
			alert("Debe digitar una contraseña");
			return;
		}
            axios.post('api/Usuarios/SwitchUser', {Localidad: this.Localidad,email: this.$store.state.usuario.correo,password:this.password})
            .then(respuesta => {
                return respuesta.data
               // this.loading = false;
            })
            .then(data => {        
                this.$store.dispatch("guardarToken", data.token) 
                this.$router.push({ name: 'home' })
            })
            .catch(err => {
                this.loading =false;
                //console.log(err.response);
                if (err.response.status==400){
                    alert("No es un email válido");
                } else if (err.response.status==404){
					alert("No existe el usuario o sus datos son incorrectos");
                }else{
					alert("Ocurrió un error");
                }
                //console.log(err)
            })
	},
    listarAsociadas(){
		var items = [];
		let me=this;
		this.Localidad = me.$store.state.usuario.localidad;
		me.Localidades.push(me.$store.state.usuario.localidad)
		let header={"Authorization" : "Bearer "};
		let configuracion= {headers : header};
		axios.get('api/LocalidadesAsociadas/'+this.$store.state.usuario.nombre,configuracion).then(function(response){
			items=response.data;
			items.map(function(x){
				me.Localidades.push(x.localdiadAsociada);
			});
		}).catch(function(error){
			alert("Producto no encontrado");
			me.modelCarga=false;
		});
	},
    
    cargarColor(){
          for(var i = 0; i < this.localidadesColor.length; ++i)
          {
             if(this.localidadesColor[i].value == this.localidad.value){
                    this.color = this.localidadesColor[i].color;
                    break;
             }
          }
        },
      validar(){
            this.valida=0;
            this.validaMensaje=[];

            if (this.nombre.length<3 || this.nombre.length>100){
                this.validaMensaje.push("El nombre debe tener más de 3 caracteres y menos de 100 caracteres.");
            }
            if (!this.idrol){
                this.validaMensaje.push("Seleccione un rol.");
            }
            if (!this.tipo_documento){
                this.validaMensaje.push("Seleccione un tipo documento.");
            }

            if (!this.email){
                this.validaMensaje.push("Ingrese el email del usuario.");
            }
            if (!this.password){
                this.validaMensaje.push("Ingrese el password del usuario.");
            }
            if (this.validaMensaje.length){
                this.valida=1;
            }
            return this.valida;
        },

      

      close () {
		this.loading = false;
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },
      activarDesactivarMostrar(accion,item){
                this.adModal=1;
                this.adNombre=item.nombre;
                this.adId=item.idusuario;                
                if (accion==1){
                    this.adAccion=1;
                }
                else if (accion==2){
                    this.adAccion=2;
                }
                else{
                    this.adModal=0;
                }
            },
            activarDesactivarCerrar(){
                this.adModal=0;
            },
       activar(item){
                let me=this;
                this.adId=item.idusuario;
                let header={"Authorization" : "Bearer " + this.$store.state.token};
                let configuracion= {headers : header};
                axios.put('api/Usuarios/Activar/'+this.adId,{},configuracion).then(function(response){
                    me.adModal=0;
                    me.adAccion=0;
                    me.adNombre="";
                    me.adId="";
                    me.listar();                       
                }).catch(function(error){
                    console.log(error);
                });
            },
            desactivar(item){
                let me=this;
                this.adId=item.idusuario;
                let header={"Authorization" : "Bearer " + this.$store.state.token};
                let configuracion= {headers : header};
                axios.put('api/Usuarios/Desactivar/'+this.adId,{},configuracion).then(function(response){
                    me.adModal=0;
                    me.adAccion=0;
                    me.adNombre="";
                    me.adId="";
                    me.listar();                       
                }).catch(function(error){
                    console.log(error);
                });
            },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.desserts[this.editedIndex], this.editedItem)
        } else {
          this.desserts.push(this.editedItem)
        }
        this.close()
      },
    },
  }
</script>