<template>
    <body >
        <div class="oculto-impresion" id="app">
        <v-app>
            <v-app-bar
            app
            color="white"
            height="100"
            >
            <v-avatar
                class="mr-3"
                color="grey lighten-5"
                size="70"
            >
                <v-img
                contain
                max-height="150%"
                :src="require('../assets/Logo 2019 website.png')"
                ></v-img>
            </v-avatar>

            <v-toolbar-title class="font-weight-black headline">
                Noah Systems
            </v-toolbar-title>
            </v-app-bar>
            <section id="hero">
                <v-row no-gutters>
                <v-img
                    :min-height="'calc(100vh - ' + $vuetify.application.top + 'px)'"
                    src="https://images.unsplash.com/photo-1487017159836-4e23ece2e4cf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1951&q=80"
                >
                    <v-theme-provider dark>
                    <v-container fill-height>
                        <v-row
                        align="center"
                        class="white--text mx-auto"
                        justify="center"
                        >
                        <v-col
                            class="white--text text-center"
                            cols="12"
                            tag="h1"
                        >
                            <span
                            :class="[$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2']"
                            class="font-weight-light"
                            >
                            Por favor, confirme o rechace su
                            </span>

                            <br>

                            <span
                            :class="[$vuetify.breakpoint.smAndDown ? 'display-3': 'display-4']"
                            class="font-weight-black"
                            >
                            Proforma
                            </span>

                        </v-col>

                        <v-btn
                            class="align-self-end"
                            
                            outlined
                            @click="AceptarProforma()"
                        >

                            <v-icon>mdi-check</v-icon> Aceptar
                        </v-btn>
                        <v-btn
                            class="align-self-end"
                            
                            outlined
                            @click="RechazarProforma()"
                        >

                            <v-icon>mdi-cancel</v-icon> Rechazar
                        </v-btn>
                        <v-btn
                            class="align-self-end"
                            
                            outlined
                            @click="listarDetalles()"
                        >

                            <v-icon>mdi-eye</v-icon> Ver Proforma
                        </v-btn>
                        </v-row>
                    </v-container>
                    </v-theme-provider>
					
                </v-img>
                </v-row>
            </section>
            <v-footer
            class="justify-center"
            color="#292929"
            height="100"
            >
            <div class="title font-weight-light grey--text text--lighten-1 text-center">
                © {{ (new Date()).getFullYear() }} — Made with 💜 by Noah Systems
            </div>
            </v-footer>
        </v-app>
        </div>

         <v-dialog fullscreen v-model="comprobanteModal" max-width="1000px">
            <v-card class="pa-3">
                
            </v-card>
        </v-dialog>
        </body>
        
</template>

<script scoped>
import axios from 'axios'
import jsPDF from 'jspdf'
import VueExcelXlsx from "vue-excel-xlsx";
import Vue from "vue";
import QrcodeVue from 'qrcode.vue'
import html2canvas from 'html2canvas';

Vue.use(VueExcelXlsx);
  export default {
    data: () => ({
    mesesValores: null,
    nombreMeses: [],
    comprobanteModal: false,
    totalMeses: [],
    nombreReceptor: '',
    value: '',
    noFactura: 0,
    detalles: [],
    Mensaje:'',
    total: 0,
    fecha: '',
    snackbar: false,
    mcorreoReceptor: '',
    localidadencabezado: [],
    Logo2: '',
    subTotal : '',
    vendedor: '',
    subTotal: '',
    iv: '',
    monedaPago: '',
    Logo3: '',
    cedulaReceptor: '',
    
    
    }),
    components: {
      QrcodeVue,
    },

    computed: {
    formTitle () {
            return this.editedIndex === -1 ? 'Nuevo usuario' : 'Actualizar usuario'
    }
    
    },
    

    watch: {
      dialog (val) {
        val || this.close()
      },
      
    },

    created () {

    },

    methods: {
        AceptarProforma (item) {
            this.loading = true;
            var me = this;
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
            axios.post('api/Proformas/Aceptar',{
                'Estado': 2,
				'NoProforma':this.$route.params.id,
                    
            },configuracion).then(function (response) {
                alert("Proforma aceptada, no necesitas hacer nada más");
				
                
                })
                .catch(function (error) {
                    alert("Error eliminando, por favor reporte a su proveedor");  
                })
        
        },
		RechazarProforma (item) {
            this.loading = true;
            var me = this;
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
            axios.post('api/Proformas/Aceptar',{
                'Estado': 3,
				'NoProforma':this.$route.params.id,
                    
            },configuracion).then(function (response) {
                alert("Proforma recahzada, no neceistas hacer nada más")
              
                })
                .catch(function (error) {
                    alert("Error rechazando, por favor reporte a su proveedor")  
                })
        
        },
        crearPDFFactura(){
            var fact = this.$route.params.id;
             var quotes = document.getElementById('factura');
                html2canvas(quotes).then(function (canvas) {
                    var imgData = canvas.toDataURL('image/png');
                    var imgWidth = 210;
                    var pageHeight = 285;
                    var imgHeight = canvas.height * imgWidth / canvas.width;
                    var heightLeft = imgHeight;
                    var doc = new jsPDF();
                    var position = 0;

                    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);                    
                    doc.save('Proforma '+fact+'.pdf');
                });
        },
        listarLocalidad(){
            let me =this;
            let LogoTest= '';
            let self = this;
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
                axios.get('api/Localidades/ListarFiltrada/'+me.$route.params.localidad,configuracion).then(function(response){
                    //console.log(response);
                    me.localidadencabezado=response.data;
                    me.Logo2 = me.localidadencabezado[0].imagen;
                    function toDataURL(url, callback) {
                    var xhr = new XMLHttpRequest();
                    xhr.onload = function() {
                        var reader = new FileReader();
                        reader.onloadend = function() {
                        callback(reader.result);
                        }
                        reader.readAsDataURL(xhr.response);
                    };
                    xhr.open('GET', url);
                    xhr.responseType = 'blob';
                    xhr.send();
                    }
                    var url2 = me.Logo2;
                    
                        toDataURL(url2, function(dataUrl) {
                        console.log('RESULT:', dataUrl)
                        me.Logo3 = dataUrl;
                        }) 
                    }).catch(function(error){
                        console.log(error);
                    });

        },
        listarDetalles(id){
                this.comprobanteModal = true;
                let me=this;
                let header={"Authorization" : "Bearer " + this.$store.state.token};
                let configuracion= {headers : header};
                axios.get('api/Proformas/ListarDatosFactura/'+this.$route.params.id+'/'+this.$route.params.localidad+'',configuracion).then(function(response){
                    //console.log(response);
                    me.detalles=response.data;
                    me.nombreReceptor = me.detalles[0].cliente;
                    me.correoReceptor = me.detalles[0].correo;
                    me.subTotal = me.detalles[0].subTotal;
                    me.fecha = me.detalles[0].fecha;
                    me.vendedor = me.detalles[0].vendedor;
                    me.subTotal = me.detalles[0].precioSinIva;
                    me.iv = me.detalles[0].iva;
                    me.total = me.detalles[0].precioFinal;
                    me.monedaPago = me.detalles[0].contacto1;
                    me.cedulaReceptor = me.detalles[0].tipoDocumento;
                    me.listarLocalidad();
                }).catch(function(error){
                    console.log(error);
                });
                me.value = location.origin+'/aceptarproforma/'+me.$route.params.id+'/'+me.$route.params.localidad;
            },
        
    
      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.desserts[this.editedIndex], this.editedItem)
        } else {
          this.desserts.push(this.editedItem)
        }
        this.close()
      },
    },
  mounted(){
      this.getProductosMasVendidos();
  }
  }

</script>
