
<template>
    <v-container style="background-color: #23262d;" fluid>
        <template>
            <v-row class="mx-1">
                <v-card :style="{ height: 'auto' }" class="mx-auto justify-center align-center" width="270" flat v-for="(item , index) in usuarios" :key="index">
					<v-toolbar dark color="#23262d"> 
							<div >Orden {{item.noFactura}} </div> <v-spacer />
							<v-radio v-if="!esProforma" readonly color="green" v-model="item.facturacion"  />
							<v-chip v-if="!esProforma" color="#2797ce" >{{orderTimes[item.noFactura]}}</v-chip>
						</v-toolbar>
							<v-row class="mb-2 mx-2">
								
								<v-chip v-if="esProforma" color="success"> {{item.nombre}} </v-chip>
								<v-edit-dialog
									v-if="!esProforma"
									:return-value.sync="item.estado"
									@save="updateOrdenExpress(item)"
									@cancel="cancel"
									@open="open"
									@close="close"
									large
									persistent
								>
									<v-chip class="mt-2 white--text" :color="getColor(item.noFactura)" style="font-size:20px">  {{EstadoNombre(item.estado)}}</v-chip>
									<template v-slot:input>
										<v-select
											v-model="item.estado"
											label="Seleccione El estado:"
											:items="Estados"
										></v-select>
									</template>
								</v-edit-dialog>
								<v-btn v-if="!esProforma" small fab @click="AmpliarOrden(item)" color="accent"> <v-icon> mdi-fullscreen </v-icon> </v-btn>
							</v-row>
							<div v-if="!esProforma" class="mx-1" style="font-size:20px; color: secondary; border-bottom: 1px solid #DBDBDB;">  <b>Pago:</b> {{item.estadoPago}}<br>
								
								<v-edit-dialog
									v-if="!esProforma"
									:return-value.sync="item.tipoCompra"
									@save="updateOrdenExpress(item)"
									@cancel="cancel"
									@open="open"
									@close="close"
									large
									persistent
								>
									<b> Entrega:{{item.tipoCompra}} </b>
									<template v-slot:input>
										<v-select
											v-model="item.tipoCompra"
											label="Seleccione el método de entrega:"
											:items="MetodosEntrega"
										></v-select>
									</template>
								</v-edit-dialog>
								
							</div>

							<p class="mx-1" style="font-size:12px; color: secondary;"> 
								No Orden {{item.noFactura}} 
								Guía {{item.orden}} 
								<v-edit-dialog
									:return-value.sync="item.nombre"
									@save="updateOrdenExpress(item)"
									@cancel="cancel"
									@open="open"
									@close="close"
								>
									<b>Cliente:{{item.nombre}} </b>
									<template v-slot:input>
										<v-text-field
											onfocus="this.select();"
											v-model="item.nombre"
											:rules="[max25chars]"
											label="Nombre del cliente:"
										></v-text-field>
									</template>
								</v-edit-dialog>
								
								<v-edit-dialog
									:return-value.sync="item.telefono"
									@save="updateOrdenExpress(item)"
									@cancel="cancel"
									@open="open"
									@close="close"
								>
									Teléfono {{item.telefono}}
									<template v-slot:input>
										<v-text-field
											onfocus="this.select();"
											v-model="item.telefono"
											:rules="[max25chars]"
											label="Teléfono:"
										></v-text-field>
									</template>
								</v-edit-dialog>
								
								<v-edit-dialog
									v-if="!esProforma"
									:return-value.sync="item.tipoPago1"
									@save="updateOrdenExpress(item)"
									@cancel="cancel"
									@open="open"
									@close="close"
								>
									Tipo Pago:{{ item.tipoPago1 }}
									<template v-slot:input>
										<v-text-field
											onfocus="this.select();"
											v-model="item.tipoPago1"
											:rules="[max25chars]"
											label="Tipo de pago:"
										></v-text-field>
									</template>
								</v-edit-dialog>
								<v-edit-dialog
									v-if="!esProforma"
									:return-value.sync="item.EstadoPago"
									@save="updateOrdenExpress(item)"
									@cancel="cancel"
									@open="open"
									@close="close"
								>
									Estado Pago {{item.EstadoPago}} 
									<template v-slot:input>
										<v-text-field
											onfocus="this.select();"
											v-model="item.EstadoPago"
											:rules="[max25chars]"
											label="Estado Pago:"
										></v-text-field>
									</template>
								</v-edit-dialog>
								<v-edit-dialog
									v-if="!esProforma"
									:return-value.sync="item.medioOrden"
									@save="updateOrdenExpress(item)"
									@cancel="cancel"
									@open="open"
									@close="close"
								>
									Medio {{item.medioOrden}} 
									<template v-slot:input>
										<v-text-field
											onfocus="this.select();"
											v-model="item.medioOrden"
											:rules="[max25chars]"
											label="Tomado por medio de:"
										></v-text-field>
									</template>
								</v-edit-dialog>
								<v-edit-dialog
									v-if="!esProforma"
									:return-value.sync="item.mensajero"
									@save="updateOrdenExpress(item)"
									@cancel="cancel"
									@open="open"
									@close="close"
									large
								>
									<b>Mensajero {{item.mensajero}}  </b>
									<template v-slot:input>
										<v-select
											v-model="item.mensajero"
											:rules="[max25chars]"
											:items="Mensajeros"
											label="Mensajero:"
										></v-select>
									</template>
								</v-edit-dialog>
								<v-edit-dialog
									:return-value.sync="item.cajero"
									@save="updateOrdenExpress(item)"
									@cancel="cancel"
									@open="open"
									@close="close"
								>
									Agente {{item.cajero}} 
									<template v-slot:input>
										<v-text-field
											onfocus="this.select();"
											v-model="item.cajero"
											:rules="[max25chars]"
											label="Agente:"
										></v-text-field>
									</template>
								</v-edit-dialog>
								<v-edit-dialog
									v-if="!esProforma"
									:return-value.sync="item.cedula"
									@save="updateOrdenExpress(item)"
									@cancel="cancel"
									@open="open"
									@close="close"
								>
									Cédula {{item.cedula}} {{item.tipoCedula}} 
									<template v-slot:input>
										<v-text-field
											onfocus="this.select();"
											v-model="item.cedula"
											:rules="[max25chars]"
											label="Cedula:"
										></v-text-field>
									</template>
								</v-edit-dialog>
								<v-edit-dialog
									v-if="!esProforma"
									:return-value.sync="item.direccion"
									@save="updateOrdenExpress(item)"
									@cancel="cancel"
									@open="open"
									@close="close"
									large
									persistent
								>
									Dirección: {{item.direccion}}   {{item.latitud}}   {{item.longitud}}  
									<template v-slot:input>
										<v-textarea onfocus="this.select();"
											v-model="item.direccion"
											:rules="[max25chars]"
											label="Direccion:"/>
									</template>
								</v-edit-dialog> 
								<v-edit-dialog
									:return-value.sync="item.nota"
									@save="updateOrdenExpress(item)"
									@cancel="cancel"
									@open="open"
									@close="close"
									large
									persistent
								>
									Nota: {{item.nota}} 
									<template v-slot:input>
										<v-textarea onfocus="this.select();"
											v-model="item.nota"
											:rules="[max25chars]"
											label="Nota:"/>
									</template>
								</v-edit-dialog> 
								Nota del Mensajero: <b>{{item.detalle}} </b>
									<br>
								₡{{item.total}}
							</p>
							<v-simple-table v-if="esProforma" dense>
										<template v-slot:default>
										<thead>
											<tr>
											<th class="text-left">
												Item
											</th>
											<th class="text-left">
												Precio
											</th>
											</tr>
										</thead>
										<tbody>
											<tr
											v-for="x in item.detalles"
											:key="x.name"
											>
											
											<td style="font-size:12px;">{{x.descripcion}} <b>x{{x.cantidad}}</b></td>
											<td style="font-size:12px;">
												<div v-if="x.descuento != 0">
													<s>{{x.precio * x.cantidad}} </s>
													{{calcularPrecioPromos(x)}}	
												</div>
												<div v-else>
													{{x.precio * x.cantidad}}
												</div>
												
											</td>
											</tr>
										</tbody>
										
										</template>
									</v-simple-table>
							<v-expansion-panels v-if="!esProforma">
								<v-expansion-panel>
								<v-expansion-panel-header>
									Ver Productos
								</v-expansion-panel-header>

								<v-expansion-panel-content>
									<!-- Contenido que se mostrará cuando se expanda el panel -->
									<v-simple-table dense>
										<template v-slot:default>
										<thead>
											<tr>
											<th class="text-left">
												Item
											</th>
											<th class="text-left">
												Precio
											</th>
											</tr>
										</thead>
										<tbody>
											<tr
											v-for="x in item.detalles"
											:key="x.name"
											>
											
											<td style="font-size:12px;">{{x.descripcion}} <b>x{{x.cantidad}}</b></td>
											<td style="font-size:12px;">
												<div v-if="x.descuento != 0">
													<s>{{x.precio * x.cantidad}} </s>
													{{calcularPrecioPromos(x)}}	
												</div>
												<div v-else>
													{{x.precio * x.cantidad}}
												</div>
												
											</td>
											</tr>
										</tbody>
										
										</template>
									</v-simple-table>
									<!-- Puedes agregar más contenido aquí -->
								</v-expansion-panel-content>
								</v-expansion-panel>
							</v-expansion-panels>
						
						
						<a v-if="!esProforma" :href="`https://waze.com/ul?ll=${item.latitud},${item.longitud}&navigate=yes`" target="_blank">
							<i style="font-size: 34px;" class="mdi mdi-waze"></i> 
						</a>
						<a v-if="!esProforma" :href="`https://www.google.com/maps/search/?api=1&query=${item.latitud},${item.longitud}`" target="_blank">
							<i style="font-size: 34px;" class="mdi mdi-google-maps"></i> 
						</a>
						
                        <v-card-text v-if="!esProforma">
							<v-btn
								v-if="item.estado == 1"
								color="success"
								block
								large
								:loading="loading"
								@click="actualizarEstado(item,2)"
							>
								Recibido
							</v-btn>
							
							<v-btn
								v-if="item.estado == 2 || item.tipoCompra != 'Retiro En sucursal'"
								color="primary"
								block
								class="mb-2"
								large
								:loading="loading"
								@click="SelccionaMensajero(item,3)"
							>
								Enviar
							</v-btn>
							<v-btn
								v-if="item.estado == 3 "
								color="error"
								block
								class="mb-2"
								large
								:loading="loading"
								@click="actualizarEstado(item,2)"
							>
								Revertir
							</v-btn>
							<v-btn
								v-if="item.estado == 6"
								color="primary"
								block
								large
								:loading="loading"
								@click="SelccionaMensajero(item,3)"
							>
								ReAsignar Mensajero
							</v-btn>
							<v-btn
								v-if="item.estado == 3 || item.tipoCompra == 'Retiro en sucursal'"
								color="green"
								block
								large
								:loading="loading"
								@click="actualizarEstado(item,4)"
							>
								Entregado
							</v-btn>
                        </v-card-text>
				</v-card>
            </v-row>
        </template>
		<v-dialog
			transition="dialog-top-transition"
			width="auto"
			v-model="MensajeroDialog"
		>
			<template >
			<v-card>
				
				<v-toolbar
				color="primary"
				dark
				title="Opening from the top"
				> Seleccione un mensajero</v-toolbar>
				<v-card-text class="text-h2 pa-12" >
					Con quien envias a {{itemTemporal.nombre}}?
				<div v-for="x in Mensajeros" :key="x" >

					<v-btn :loading="loading" color="button2" @click="actualizarEstadoMensajero(itemTemporal,3,x)" outlined x-large> {{x}} </v-btn>
				</div>
				
				</v-card-text>
				<v-card-actions class="justify-end">
				<v-btn
					variant="text"
					color="error"
					@click="MensajeroDialog = false"
				>Atrás</v-btn>
				
				</v-card-actions>
				
			</v-card>
			</template>
		</v-dialog>
		<v-dialog
		v-model="dialog"
		fullscreen
		:scrim="false"
		transition="dialog-bottom-transition"
		>
			<v-card>
				<v-toolbar
				dark
				color="primary"
				>
				<v-btn
					icon
					dark
					@click="dialog = false"
				>
					<v-icon>mdi-close</v-icon>
				</v-btn>
				<v-toolbar-title>Orden {{itemTemporal.noFactura}}</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-toolbar-items>
					<v-btn
					variant="text"
					@click="dialog = false"
					>
					Atrás
					</v-btn>
				</v-toolbar-items>
				</v-toolbar>
				<v-list
				lines="two"
				subheader
				>
								
					<v-checkbox readonly label="Facturado" color="green" v-model="itemTemporal.facturacion"  /><br>
					
					<p class="mx-1" style="font-size:20px; color: secondary; border-bottom: 1px solid #DBDBDB;"> 
						<b>Cliente:</b> {{itemTemporal.nombre}}<br>
						<b>Pago:</b> {{itemTemporal.estadoPago}}<br>
						<b> Entrega:{{itemTemporal.tipoCompra}} </b><br>
					</p>
					<p class="mx-1" style="font-size:16px; color: secondary;"> 
						No Orden {{itemTemporal.noFactura}} <br>
						Guía {{itemTemporal.orden}} <br>
						<!-- <b>Cliente:{{item.nombre}}  -->{{itemTemporal.telefono}} <br>
						Tipo Pago:{{ itemTemporal.tipoPago1 }}<br>
						Estado Pago {{itemTemporal.EstadoPago}} <br>
						Medio {{itemTemporal.medioOrden}}<br> Mensajero {{itemTemporal.mensajero}} <br>Agente {{itemTemporal.cajero}} 
						Cédula {{itemTemporal.cedula}}<br> {{itemTemporal.tipoCedula}} <br>
						Dirección: {{itemTemporal.direccion}}<br> 
						Nota: {{itemTemporal.nota}} <br>
						Nota del Mensajero: <b>{{itemTemporal.detalle}} </b>
						<br>
						₡{{itemTemporal.total}}
					</p>
				</v-list>
				<a :href="`https://waze.com/ul?ll=${itemTemporal.latitud},${itemTemporal.longitud}&navigate=yes`" target="_blank">
					<i style="font-size: 44px;" class="mdi mdi-waze"></i> 
				</a>
				<a :href="`https://www.google.com/maps/search/?api=1&query=${itemTemporal.latitud},${itemTemporal.longitud}`" target="_blank">
					<i style="font-size: 44px;" class="mdi mdi-google-maps"></i> 
				</a>
				<v-divider></v-divider>
				<v-list
				lines="two"
				subheader
				>
				<v-btn block @click="dialog = false" color="accent"> Atrás </v-btn>
				<v-simple-table dense>
							<template v-slot:default>
							<thead>
								<tr>
								<th class="text-left">
									Item
								</th>
								<th class="text-left">
									Precio
								</th>
								</tr>
							</thead>
							<tbody>
								<tr
								v-for="x in itemTemporal.detalles"
								:key="x.name"
								>
								
								<td>{{x.descripcion}} <b>x{{x.cantidad}}</b></td>
								<td>
									<div v-if="x.descuento != 0">
										<s>{{x.precio * x.cantidad}} </s>
										{{calcularPrecioPromos(x)}}	
									</div>
									<div v-else>
										{{x.precio * x.cantidad}}
									</div>
									
								</td>
								</tr>
							</tbody>
							
							</template>
						</v-simple-table>
				</v-list>
			</v-card>
		</v-dialog>
    </v-container>
	
</template>
<script>

import axios from 'axios'
  export default {
    data: () => ({
		MetodosEntrega: ["Envio Propio","Retira en sucursal","Correos de CR","Otro"],  
		Localidades:[],
		Estados:[{text:"En Central",value:"0"},{text:"Esperando Sucursal",value:"1"},
		{text:"En Sucursal",value:"2"},{text:"Enviado",value:"3"},{text:"Entregado",value:"4"}
		,{text:"Cancelado",value:"5"},{text:"No se pudo entregar",value:"6"}],
        usuarios:[],    
		Mensajeros :[],
		itemTemporal:[],
		dialog:false,
		multiplo:10,
		esProforma:false,
        editedIndex: -1,
		cadena:0,
		timeUpdateInterval:null,
		MensajeroDialog:false,
		estado: 0,
		keySequence: '', // Aquí almacenaremos las teclas presionadas.
		orderTimes: {},
		loading : true,
		prevUsersLength: 0, // Añadir esta propiedad para almacenar la longitud anterior de 'usuarios'
      	sound: new Audio(require('@../../../assets/pageAssets/inbox.mp3'))
																	 // Ruta relativa al archivo de sonido
    }),
	mounted() {
		
		document.addEventListener('keyup', this.handleKeyUp);
		 // Establece un intervalo para actualizar el tiempo transcurrido cada segundo
    	this.timeUpdateInterval = setInterval(this.updateOrderTimes, 1000);
		this.enterFullscreen();
		// Obtener el elemento <v-col> por su ID
		var colElement = document.getElementById('columnadetalles');

		// Obtener la altura de la pantalla
		var screenHeight = window.innerHeight;

		// Establecer la altura del elemento al valor de la altura de la pantalla
		colElement.style.height = screenHeight + 'px';
	},
	beforeDestroy() {
		document.removeEventListener('keyup', this.handleKeyUp);
		clearInterval(this.timeUpdateInterval);  // Limpia el intervalo
		 if (this.intervalID) {
			clearInterval(this.intervalID); // Detiene el intervalo
		}
	},
    created () {
		this.listarMensajeros();
      	this.listar();
	  
    },

    methods: {
		AmpliarOrden(item){
			//alert("Amlpiando "+item.nombre)
			this.itemTemporal = item;
			this.dialog = true;
		},
		SelccionaMensajero(item,estado){
			this.MensajeroDialog = true;
			this.estado = estado;
			this.itemTemporal = item;
		},
		updateOrdenExpress(data) {
            axios.put('/api/OrdenExpress/ActualizarOrden', data)
                .then(response => {
                    console.log('Actualizado exitosamente');
                })
                .catch(error => {
                    console.error('Hubo un error al actualizar:', error);
                });
        },
		listarMensajeros(){
			var items = [];
			let me=this;
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			axios.get('api/Usuarios/SelectMensajeros/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
				items = response.data;
				items.map(function(x){
					me.Mensajeros.push(x.nombre);
				});
				
			}).catch(function(error){
				alert("Mensajeros no encontrados");
			});
		},
		async actualizarEstado(item,estado) {
			this.loading = true;
			try {
				const response = await axios.put('/api/OrdenExpress/ActualizarEstado', {
				NoFactura: item.noFactura,
				Estado: estado,
				Mensajero: item.mensajero,
				});

				console.log(response.data); // Si deseas ver la respuesta en la consola

				// Manejar la respuesta, por ejemplo mostrar un mensaje de éxito
				item.estado = estado;
				this.loading = false;

			} catch (error) {
				console.error("Hubo un error al actualizar el estado:", error);
				// Manejar el error, por ejemplo mostrar un mensaje de error
				this.loading = false;
				alert("Error al actualizar el estado.");
			}
		},
		async actualizarEstadoMensajero(item,estado,x) {
			this.loading = true;
			try {
				const response = await axios.put('/api/OrdenExpress/ActualizarEstado', {
				NoFactura: item.noFactura,
				Estado: estado,
				Mensajero: x,
				});

				console.log(response.data); // Si deseas ver la respuesta en la consola

				// Manejar la respuesta, por ejemplo mostrar un mensaje de éxito
				this.MensajeroDialog = false;
				item.estado = estado;
				this.loading = false;

			} catch (error) {
				console.error("Hubo un error al actualizar el estado:", error);
				// Manejar el error, por ejemplo mostrar un mensaje de error
				this.loading = false;
				alert("Error al actualizar el estado.");
			}
		},
		calcularPrecioPromos(data) {
			var resultado = 0.0;
			var montoDescuento;
			// Calcular el descuento
			montoDescuento = (data.precio * data.cantidad) - ((data.descuento) / 100) * (data.precio * data.cantidad);
			resultado = Math.ceil(montoDescuento / this.multiplo) * this.multiplo;
			return resultado;
		},
		handleKeyUp(event) {
			// Si el usuario presiona un número (sobre las letras o en el numpad).
			if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105)) {
				// Para manejar tanto el teclado normal como el numpad
				let key = (event.keyCode >= 96 && event.keyCode <= 105) ? String.fromCharCode(event.keyCode - 48) : event.key;
				this.keySequence += key;
			} 
			// Si el usuario presiona Enter.
			else if (event.keyCode == 13) {
				// Convertir la secuencia a número y verificar que está en el rango correcto.
				let orderNumber = parseInt(this.keySequence.trim());
				if (orderNumber >= 0 && orderNumber <= 100) {
				this.deleteOrden(orderNumber);
				}
				// Limpiar la secuencia de teclas.
				this.keySequence = '';
			} 
			// Si se presiona una tecla que no es número ni Enter.
			else {
				this.keySequence = '';
			}
		},
		calculateTimeElapsed(Hora) {
			var now = new Date();
			var hor1 = now.getHours();
			var min1 = now.getMinutes();
			var sec1 = now.getSeconds();

			var hor2 = parseInt(Hora.substring(0, 2));
			var min2 = parseInt(Hora.substring(3, 5));

			var diffInSec = (hor1 * 3600 + min1 * 60 + sec1) - (hor2 * 3600 + min2 * 60);

			// Si la diferencia es negativa, sumamos 24 horas (en segundos) para ajustar el cálculo
			if (diffInSec < 0) diffInSec += 24 * 3600;

			var hours = Math.floor(diffInSec / 3600);
			diffInSec %= 3600;
			var minutes = Math.floor(diffInSec / 60);
			var seconds = diffInSec % 60;

			return hours + "h: " + minutes + "m: " + seconds + "s";
		},

		SaltosLinea(item){
			let srt = item.split(":");
			return srt;
		},
		getColor (estado) {
			if (estado == '0') return '#23262d'
			else 
			if (estado == '1') return 'error'
			else 
			if (estado == '2') return 'orange'
			else 
			if (estado == '3') return 'green'
			else 
			if (estado == '4') return 'indigo'
			
			else  return 'red'
		},
		EstadoNombre (estado) {
			if (estado == '0') return 'En Central'
			else 
			if (estado == '1') return 'Esperando Sucursal'
			else 
			if (estado == '2') return 'En Sucursal'
			else 
			if (estado == '3') return 'En Ruta'
			else 
			if (estado == '4') return 'Entregado'
			else 
			if (estado == '5') return 'Cancelado'
			else 
			if (estado == '6') return 'No se pudo entregar'
			
		},
		updateOrderTimes() {
			this.orderTimes = {};  // Reiniciar el objeto orderTimes (si lo prefieres)
			for (let item of this.usuarios) {
				this.orderTimes[item.noFactura] = this.calculateTimeElapsed(item.hora);
			}
		},
		enterFullscreen() {
			const docEl = document.documentElement;
			if (docEl.requestFullscreen) {
			docEl.requestFullscreen().catch((error) => {
				console.log('Error al intentar ingresar al modo de pantalla completa:', error);
			});
			} else if (docEl.mozRequestFullScreen) { // Firefox
			docEl.mozRequestFullScreen().catch((error) => {
				console.log('Error al intentar ingresar al modo de pantalla completa:', error);
			});
			} else if (docEl.webkitRequestFullscreen) { // Chrome, Safari y Opera
			docEl.webkitRequestFullscreen().catch((error) => {
				console.log('Error al intentar ingresar al modo de pantalla completa:', error);
			});
			} else if (docEl.msRequestFullscreen) { // Internet Explorer/Edge
			docEl.msRequestFullscreen().catch((error) => {
				console.log('Error al intentar ingresar al modo de pantalla completa:', error);
			});
			}
		},
		calculateTimeElapsed(Hora) {
			var now = new Date();
			var hor1 = now.getHours();
			var min1 = now.getMinutes();
			var sec1 = now.getSeconds();

			var hor2 = parseInt(Hora.substring(0, 2));
			var min2 = parseInt(Hora.substring(3, 5));

			var diffInSec = (hor1 * 3600 + min1 * 60 + sec1) - (hor2 * 3600 + min2 * 60);

			// Si la diferencia es negativa, sumamos 24 horas (en segundos) para ajustar el cálculo
			if (diffInSec < 0) diffInSec += 24 * 3600;

			var hours = Math.floor(diffInSec / 3600);
			diffInSec %= 3600;
			var minutes = Math.floor(diffInSec / 60);
			var seconds = diffInSec % 60;

			return hours + "h: " + minutes + "m: " + seconds + "s";
		},


       listar() {
			let me = this;
			let header = {};
			let configuracion = { headers: header };

			// Llama al método inmediatamente al cargar la página.
			consultarAPI();

			// Configura el intervalo para llamar al método cada 40 segundos.
			 this.intervalID = setInterval(consultarAPI, 40000);

			function consultarAPI() {
				axios.get('api/OrdenExpress/ListarComandaSucursal/' + me.$store.state.usuario.localidad, configuracion)
				.then(function(response) {
					const oldLength = me.usuarios.length; // Guardar la longitud anterior
					me.usuarios = response.data;
					if (me.usuarios.length === 0) {
						//alert("Vacio");
					// Si el arreglo recibido es vacío, llama al método ListarProformasPorEstadoYLocalidad
					me.obtenerProformasPorEstadoYLocalidad();
					}
					me.loading = false;
					me.updateOrderTimes(); // <-- Actualiza el tiempo transcurrido inmediatamente después de cargar las órdenes.

					// Comprueba si la longitud de 'usuarios' ha aumentado
						if (me.usuarios.length > oldLength) {
						me.sound.play(); // Reproduce el sonido si la longitud ha aumentado
						}
					})
					.catch(function(error) {
						console.log(error);
						me.loading = false;
					});

			}
		},
		// Método para llamar a ListarProformasPorEstadoYLocalidad si el resultado de la primera llamada está vacío
		obtenerProformasPorEstadoYLocalidad() {
			let header = {};
			this.esProforma = true;
			let me = this;
			//alert("Entro a proforma");
			axios.get(`api/Proformas/ListarCocina/${me.$store.state.usuario.localidad}`)
			.then(response => {
				// Procesa el resultado de ListarProformasPorEstadoYLocalidad
				me.cadena = me.usuarios.length; // Guardar la longitud anterior
				me.usuarios = response.data.map(proforma => ({
					noFactura: proforma.noProforma, // Cambia NoProforma por NoFactura
					fecha: proforma.fecha,
					//estado:proforma.cliente,
					total:proforma.montoFacturado,
					nombre:proforma.cliente,
					telefono:proforma.contacto,
					nota:proforma.detalle,
					
					cajero: proforma.vendedor, // Suponiendo que Cajero en el frontend es Vendedor en el backend
					// Continúa mapeando el resto de campos según sea necesario
					detalles: proforma.detalles.map(detalle => ({
						codigoProducto: detalle.codigoProducto,
						descripcion: detalle.descripcion,
						precio: detalle.precioUnitario, // Suponiendo que PrecioUnitario en el frontend es PrecioFinal en el backend
						cantidad: detalle.cantidad,
						descuento:0,
						// Mapea otros campos de detalles según sea necesario
					}))
				}));
				// Comprueba si la longitud de 'usuarios' ha aumentado
					if (me.usuarios.length > me.cadena) {
					me.sound.play(); // Reproduce el sonido si la longitud ha aumentado
					}
			})
			.catch(error => {
				console.error("Hubo un error al obtener las proformas por estado y localidad:", error);
			});
		},
    
		cargarColor(){
			for(var i = 0; i < this.localidadesColor.length; ++i)
			{
				if(this.localidadesColor[i].value == this.localidad.value){
						this.color = this.localidadesColor[i].color;
						break;
				}
			}
        },
    },
  }
</script>