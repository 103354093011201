<template>
	 <div>
        <v-data-table
            :headers="headers"
            :items="detalles"
            :search="search"
			:loading="loading"
            class="elevation-1 pa-1"

            >
            <template v-slot:item.cantidadCompraRequerida="props">
				<v-edit-dialog
				:return-value.sync="props.item.cantidadCompraRequerida"
				large
				>
				{{ props.item.cantidadCompraRequerida }} <br>
				<template v-slot:input>
					<v-text-field v-model="props.item.cantidadCompraRequerida" label="Inventario"/>
				</template>
				</v-edit-dialog>
			</template>
            <template v-slot:top>
                <v-row class="pa-2">
                    <v-col cols="12" lg="3" md="5" sm="8" xs="12">
                        <v-card-actions class="rounded-xl light-blue lighten-5 mx-5">
                                <vue-excel-xlsx
                                    :data="detalles"
                                    :columns="columns"
                                    :filename="'Reporte de Minimos '+$store.state.usuario.localidad"
                                    :sheetname="'Ventas'"
                                    >
                                    <v-btn fab="" color="success">  <v-icon large="">mdi-file-excel </v-icon> </v-btn>
                                </vue-excel-xlsx> 
                            <v-card-title>Productos Estancados</v-card-title>
                        </v-card-actions>
						
                    </v-col>
                    <v-col cols="12" lg="4" md="7" sm="4" xs="12">    
                        <v-text-field v-model="search"  append-icon="mdi-magnify"
                         label="Buscar" single-line hide-details
                        ></v-text-field>
						<p>Productos sin facturase desde {{dias}} hasta hoy </p>
                    </v-col>
                    <v-col cols="12" lg="2" md="6" sm="6" xs="6">
					<v-menu
						v-model="menu"
						:close-on-content-click="false"
						:nudge-right="40"
						transition="scale-transition"
						offset-y
						min-width="290px"
					>
						<template v-slot:activator="{ on, attrs }">
						<v-text-field
							v-model="dias"
							label="Seleccionar fecha"
							prepend-icon="mdi-calendar"
							readonly
							v-bind="attrs"
							v-on="on"
						></v-text-field>
						</template>
						<v-date-picker v-model="dias" @input="menu = false" @change="ProductosEstancados()"></v-date-picker>
					</v-menu>
					</v-col>
                    <v-col cols="12" lg="2" md="5" sm="5" xs="5">
                        <v-select @change="ProductosEstancados()" :items="localidades" v-model="localidad"  label="Localidad"></v-select>
                    </v-col>
                </v-row>
               
            </template>

            <template v-slot:no-data>
                <v-btn color="primary" @click="Reiniciar()">Reiniciar</v-btn>
            </template>
        </v-data-table>
        <template class="pa-3">
            <v-snackbar
                    v-model="snackbar" top vertical="" :timeout="5000"
                    >
                    {{ Mensaje }}
                    <v-btn
                    color="info"
                    text
                    @click="snackbar = false"
                    >
                    Cerrar
                    </v-btn>
            </v-snackbar>
        </template>
    </div>
	
</template>


<script>

import VueExcelXlsx from "vue-excel-xlsx";
import axios from 'axios';
  export default {
    name: 'ProductosEstancados',
    data() {
    return {
        TipoCedula: '',
        detalles: [],
		search:'',
		localidad:'',
		loading:true,
        dias: this.calculateDate(),  // Puedes inicializar con null o algún valor por defecto
        proveedor: null,       // Si no tienes un valor inicial, null es una buena opción
        familia: null,         // De la misma manera, puedes inicializar con null
		localidades:[],
		headers: [
		{ text: 'Código', value: 'codigoProducto' },
		{ text: 'Nombre', value: 'descripcion' },
		{ text: 'Cat 1', value: 'familia' },
		{ text: 'Familia', value: 'familia' },
		{ text: 'Proveedor', value: 'proveedor' },
		{ text: 'Precio Venta', value: 'precioVenta' },
		{ text: 'Cantidad Requerida', value: 'cantidadCompraRequerida' },
		],
		columns : [
			{ label: 'Código', field: 'codigoProducto'},  
			{ label: 'Nombre', field: 'descripcion'},
			{ label: 'Precio Venta', field: 'precioVenta' },
			{ label: 'Costo Sin IVA', field: 'precioCompra',  },
			{ label: '% Imp ', field: 'valorImpuesto'  },
			{ label: 'IVI', field: 'estadoInventario' },
			{ label: 'Cantidad Ingresar', field: 'cantidadCompraRequerida' },
			{ label: 'Impuesto', field: 'valorImpuesto'},
			{ label: "Familia",field: "familia", },
			{ label: "Codigo Barras",field: "idarticulo", },
        ],
		};
	},
	created () {
		this.listarAsociadas();
		this.ProductosEstancados();
		
    },
    methods: {
		Reiniciar(){
			this.proveedor = null;
			this.familia = null;
			this.ProductosEstancados();
		},
		calculateDate() {
			let date = new Date();
			date.setDate(date.getDate() - 30);
			return date.toISOString().substr(0, 10); // Convertir a formato de fecha YYYY-MM-DD
		},
		listarAsociadas(){
			var items = [];
			let me=this;
			me.localidades.push(me.$store.state.usuario.localidad);
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			axios.get('api/LocalidadesAsociadas/'+this.$store.state.usuario.nombre,configuracion).then(function(response){
				items=response.data;
				items.map(function(x){
					me.localidades.push(x.localdiadAsociada);
				});
			}).catch(function(error){
				me.Mensaje = "Localidades Asociadas no encontradas";
				me.snackbar = true;
				me.modelCarga=false;
			});
		},
		ProductosEstancados(){
			this.loading = true;
			let me = this;
			const fechaInicio = "2023-01-01";
			this.localidad = this.$store.state.usuario.localidad
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			 axios.get(`/api/Productos/ProductosEstancados/${this.localidad}/${this.dias}`,configuracion)
			.then(response => {
			me.detalles = response.data;
			me.loading = false;
			})
			.catch(error => {
			console.error("Hubo un error al obtener los productos: ", error);
			});
			}


    },
  }
</script>
