<template>
    <div>
        <v-img height="200"
            dark
            :src="require('@/assets/home-hero.jpg')"
            class="white--text"
            gradient="to right, rgba(5, 11, 31, .8), rgba(5, 11, 31, .8)"
        >
			<v-row>
				<div class="pa-2" v-if="!esBader">
					<v-btn v-if="esSupervisor || esBoss || esAdministrador" @click="crearPDF()" class="mt-6" style="font-size: min(max(8px, 1.2vw), 15px);" text rounded>Descargar PDF</v-btn>
					<vue-excel-xlsx
						:data="articulos"
						:columns="columns"
						:filename="'Reporte de Inventario en '+$store.state.usuario.localidad"
						:sheetname="'Inventario Actual'"
						>
						<v-btn v-if="esSupervisor || esBoss || esAdministrador" style="font-size: min(max(8px, 1.2vw), 15px);" text rounded >  <v-icon small>mdi-file-excel </v-icon> Descargar Excel </v-btn>
						
					
					</vue-excel-xlsx> 
					<v-btn v-if="esSupervisor || esBoss || esAdministrador" @click="listarInventarioLocalidad" icon><v-icon>mdi-cloud-download </v-icon></v-btn> 
				</div>
				<div class="pa-2" v-if="esBader">
					<div :hidden="!(esBaderSucursal && esBader)">
						<v-btn v-if="esSupervisor || esBoss || esAdministrador" @click="crearPDF()" class="mt-6" style="font-size: min(max(8px, 1.2vw), 15px);" text rounded>Descargar PDF</v-btn>
						<vue-excel-xlsx
							:data="articulos"
							:columns="columns"
							:filename="'Reporte de Inventario en '+$store.state.usuario.localidad"
							:sheetname="'Inventario Actual'"
							>
							<v-btn v-if="esSupervisor || esBoss || esAdministrador" style="font-size: min(max(8px, 1.2vw), 15px);" text rounded >  <v-icon small>mdi-file-excel </v-icon> Descargar Excel </v-btn>
							
						
						</vue-excel-xlsx> 
						<v-btn v-if="esSupervisor || esBoss || esAdministrador" @click="listarInventarioLocalidad" icon><v-icon>mdi-cloud-download </v-icon></v-btn> 
					</div>
				</div>
				<v-select dense class="mx-2 mt-2"	style="font-size: min(max(8px, 1.2vw), 15px);" :items="Localidades" outlined label="Busqueda por localidad" v-model="Localidad"/>
			
				<v-text-field v-model="finder" class="pa-2" @keyup.enter="BuscarCodigo()" style="font-size: min(max(8px, 1.2vw), 15px);" dense outlined label="Busqueda por código" /> 
				
			</v-row>
			<v-row no-gutters
				align="center"
				justify="center"
				style="font-size: min(max(8px, 1.2vw), 15px);" >
				<v-col class="text-center" cols="12">
					<h1 class="Titulo text-uppercase">Inventarios en {{Localidad}} </h1>
					<h4 class="subheading"> <v-icon> mdi-alert-circle </v-icon>"Indicaciones: Seleccione la sucursal, seguidamente digite el producto a buscar y precione enter"</h4> 
				</v-col>
			</v-row>
        </v-img>
        <v-card>
            <v-card-text>
                <v-container fluid>
					<v-text-field
					v-model="search"
					label="Ingrese artículo a buscar y digite Enter"
					single-line
					hide-details
					autofocus
					@keyup.enter="BuscarItems()"
					solo
					></v-text-field>
					<template>
						<v-data-table
							item-key="id" 
							:headers="cabeceraArticulos"
							:items="articulos"
							:search="search"
							:loading="articulos"
							class="elevation-1"
						>
							<template slot="no-data">
								<h3>No hay artículos para mostrar.</h3>
							</template>
							<template v-slot:item.precioCompra="props">
								
								<div v-if="esSupervisor || esBoss || esAdministrador"> {{ props.item.precioCompra }} <br> </div>
								
							</template>
						</v-data-table> 
					</template>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="ocultarArticulos()" color="error">
                    Salir
                </v-btn>
            </v-card-actions>
        </v-card>
        <template class="pa-3">
			<div class="text-center">
				<v-overlay :value="modelCarga">
				<v-progress-circular
					indeterminate
					size="64"
				></v-progress-circular>
				</v-overlay>
			</div>
            <v-snackbar
                    v-model="snackbar" top vertical="" :timeout="5000"
                    >
                    {{ Mensaje }}
                    <v-btn
                    color="info"
                    text
                    @click="snackbar = false"
                    >
                    Cerrar
                    </v-btn>
            </v-snackbar>
            
            <v-row v-if="esSupervisor || esBoss || esAdministrador" flat class="pa-2">
                <v-row v-if="!esBader" flat class="rounded-xl light-blue lighten-5 mx-5">
                <v-card-text>
                    <b> Total Capital en Inventario buscado:</b> {{MonedaFiltrar}} {{Total=(calcularTotal)}} <br>
                    <b> Total de Productos encontrados .</b>  {{articulos.length}} <br>
                    
                 
                </v-card-text>
                </v-row>
				<div :hidden="!(esBaderSucursal && esBader)" class="rounded-xl light-blue lighten-5 mx-5">
                <v-card-text>
                    <b> Total Capital en Inventario buscado:</b> {{MonedaFiltrar}} {{Total=(calcularTotal)}} <br>
                    <b> Total de Productos encontrados</b>  {{articulos.length}} <br>
                </v-card-text>
                </div>
            </v-row>
        </template>
    </div>
</template>

<script scoped>
import axios from 'axios'
import Chart from 'chart.js'
import jsPDF from 'jspdf'
import autotable from 'jspdf-autotable'
import VueExcelXlsx from "vue-excel-xlsx";
import Vue from "vue";
import html2canvas from 'html2canvas';
import QrcodeVue from 'qrcode.vue'

Vue.use(VueExcelXlsx);
  export default {
    data: () => ({
    mesesValores: null,
    nombreMeses: [],
    totalMeses: [],
    value: 'https://www.noah.cr',
    size: 100,
    dialog: false,
	Localidades:[],
    Aceptadas: 0,
	modelCarga:false,
    search: '',
    ClienteSeleccionado : '',
    today: new Date().toISOString().substr(0, 10),
    focus: new Date().toISOString().substr(0, 10),
    cabeceraArticulos: [
    { text: 'Código Producto', value: 'codigoProducto' },
    { text: 'Descripción', value: 'descripcion' },
    { text: 'Familia', value: 'familia' },
    { text: 'Precio Venta', value: 'precioVenta'  },  
    { text: 'Precio Compra', value: 'precioCompra' },  
    { text: 'Inventario Actual', value: 'cantidadInvActual'}  ,
	{ text: 'Codigo Barras', value: 'codigoBarras'} , 
	{ text: 'Sucursal', value: 'localidad'} ,      
	{ text: 'Docs', value: 'sincServer1'}     
    ],
    tipoDocumentos: [
        'FISICA',
        'JURIDICA',
        'EXTRANJERO',
    ],
    columns : [
            { label: "Código Producto",field: "codigoProducto", },
            { label: "Descripción",  field: "descripcion", },
            { label: "Familia",field: "familia", },
            { label: "Precio Venta",field: "precioVenta", },
            { label: "Precio Compra Final",field: "precioCompra", },
            { label: "Inventario Actual",field: "cantidadInvActual", }, 
        ],
    PagosFiltrados: "",
    Monedas: ["USD","CRC"],
    TiposPago:["TARJETA","DEPOSITO","EFECTIVO","TRANSFERENCIA"],

    MonedaFiltrar : "",
    tipoCedulaReceptor: '',
      search: '',
    emailRules: [
    v => !!v || 'El correo electrónico es requerido',
    v => /.+@.+\..+/.test(v) || 'El correo electrónico es requerido debe ser válido',],
    cedulaRules: [value => !!value || 'La cédula es requerida'
    , value => (value && value.length >= 9) || 'Debe ingresar 9 carácteres como mínimo',],
    nameRules: [
    value => !!value || 'El nombre es requerido',
    value => (value && value.length >= 10) || 'Debe ingresar 10 carácteres como mínimo',
    ],
    celRules: [
        value => !!value || 'El número es requerido',
        value => (value && value.length >= 8) || 'Debe ingresar 8 carácteres como mínimo',
        ],
    justificacionRules: [
        value => !!value || 'La justificación es requerida',
        value => (value && value.length >= 7) || 'Debe ingresar 7 carácteres como mínimo',
        ],
    editedIndex: -1,
    articulos: [],
    usuarios:[],    
    desserts: [],
    editedIndex: -1,
    finder: '',
    Localidad: '',
	loading: false,
    
    }),
    components: {
      QrcodeVue,
    },

    computed: {
		formTitle () {
				return this.editedIndex === -1 ? 'Nuevo usuario' : 'Actualizar usuario'
		},
		esAdministrador(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='Administrador';
		},
		esSupervisor(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='Supervisor';
		},
		esVendedor(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='Vendedor';
		},
		esCliente(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='Cliente';
		},
		esPropietario(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='Propietario';
		},
		
		esBoss(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='King Boss';
		},
		esBaderSucursal(){
			return  this.Localidad =='King Vape Cartago';
		},
		esBader(){
			return  this.$store.state.usuario.nombre =='Bader';
		},
		esMarketing(){
				return this.$store.state.usuario && this.$store.state.usuario.rol =='King Marketing';
		},
		esLocalidad(){
				return this.$store.state.usuario.localidad;
		},
		calcularTotal:function(){
			var resultado=0.0;
			for(var i=0;i<this.articulos.length;i++){
					resultado=resultado+(this.articulos[i].cantidadInvActual * this.articulos[i].precioCompra);
			}
			
			return resultado.toFixed(2);
		},
    },
    

    watch: {
		dialog (val) {
			val || this.close()
		},
		localidad(newValue, oldValue) {
		// Cuando 'localidad' cambia, se ejecuta esta función
		this.$nextTick(() => {
			this.esBaderSucursal = newValue === 'King Vape Cartago';
		});
		},
      
    },

    created () {
        this.listarArticulo();
		this.listarAsociadas();
    },

    methods: {
        listarArticulo(){
			let me=this;
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			axios.get('api/ProductosWebs/ListarLocalidad/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
				me.articulos=response.data;
				me.modelCarga=false;
			}).catch(function(error){
				alert("Producto no encontrado");
				me.modelCarga=false;
			});
    	},
		BuscarCodigo(){
			this.modelCarga = true;
			let me=this;
			let items = [];
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			axios.get('api/ProductosWebs/StockItem/'+this.finder,configuracion).then(function(response){
				items=response.data;
				me.articulos = [];
				items.map(function(x){
					me.articulos.push({
						codigoProducto: x.codigoProducto,
						descripcion: x.descripcion,
						familia: x.familia,
						precioVenta: x.precioVenta,
						precioCompra: x.precioCompra,
						cantidadInvActual: x.cantidadInvActual,
						codigoBarras: x.codigoBarras,
						localidad: x.sitio,
						sincServer1: x.sincServer1,
					}
						);
				});
				me.modelCarga=false;
			}).catch(function(error){
				alert("Producto no encontrado "+error);
				me.modelCarga=false;
			});
    	},
		BuscarItems() {
			let me = this;
			this.modelCarga = true;
			let header = { "Authorization": "Bearer " };
			let configuracion = { headers: header };

			let localidades = [];
			if (this.Localidad === "todas") {
				localidades = this.Localidades.filter(loc => loc.value !== "todas").map(loc => loc.value);
			} else {
				localidades = [this.Localidad];
			}

			// Construimos la URL manualmente para enviar correctamente las localidades como query params
			const params = new URLSearchParams();
			localidades.forEach(loc => params.append('localidades', loc));
			params.append('finder', this.search);

			axios.get(`api/ProductosWebs/ListarLocalidadesBusquedaArreglo?${params.toString()}`, configuracion)
				.then(function(response) {
					me.articulos = response.data;
					me.modelCarga = false;
				})
				.catch(function(error) {
					alert("Producto no encontrado");
					me.modelCarga = false;
				});
		},
		listarInventarioLocalidad(){
				this.modelCarga = true;
                let me=this;
                let header={"Authorization" : "Bearer "};
                let configuracion= {headers : header};
                axios.get('api/ProductosWebs/ListarLocalidadTotal/'+this.Localidad,configuracion).then(function(response){
                    me.articulos=response.data;
					me.modelCarga=false;
                }).catch(function(error){
                  alert("Producto no encontrado");
				  me.modelCarga=false;
                });
    	},
		listarAsociadas() {
			var items = [];
			let me = this;
			this.Localidad = me.$store.state.usuario.localidad;
			me.Localidades.push({ text: 'Todas', value: 'todas' }); // Agregar opción "todas"
			me.Localidades.push({ text: me.$store.state.usuario.localidad, value: me.$store.state.usuario.localidad });
			if (this.$store.state.usuario.nombre == 'Bader') {
				me.Localidades.push({ text: 'King Vape Zapote', value: 'King Vape Zapote' });
				me.Localidades.push({ text: 'King Vape Escazu', value: 'King Vape Escazu' });
				me.Localidades.push({ text: 'King Vape', value: 'King Vape' });
			}
			let header = { "Authorization": "Bearer " };
			let configuracion = { headers: header };
			axios.get('api/LocalidadesAsociadas/' + this.$store.state.usuario.nombre, configuracion).then(function(response) {
				items = response.data;
				items.map(function(x) {
					me.Localidades.push({ text: x.localdiadAsociada, value: x.localdiadAsociada });
				});
			}).catch(function(error) {
				alert("Producto no encontrado");
				me.modelCarga = false;
			});
		},
        listarLocalidad(){
            let me =this;
            let LogoTest= '';
            let self = this;
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
                axios.get('api/Localidades/ListarFiltrada/'+me.$store.state.usuario.localidad,configuracion).then(function(response){
                    //console.log(response);
                    me.localidadencabezado=response.data;
                    me.Logo2 = me.localidadencabezado[0].imagen;
                }).catch(function(error){
                    console.log(error);
                });

        },
        FiltrarCliente(){
            this.usuarios = this.usuarios.filter(e => e.medioDePago == this.PagosFiltrados);
            this.snackbar = true;
            this.Mensaje = 'Filtro Medio de Pago Seleccionado sobre rango de fecha listado'
        },
        FiltrarVendedor(){
            let amp = "";
            this.Datos = this.usuarios;
            this.usuarios = this.usuarios.filter(e => e.nombreReceptor == this.ClienteSeleccionado);
            this.snackbar = true;
            this.Mensaje = 'Filtro de cliente seleccionado sobre rango de fecha listado'
        },
        FiltrarCliente2(){
            let amp = "";
            this.Datos = this.usuarios;
            this.usuarios = this.usuarios.filter(e => e.nombreReceptor == this.ClienteSeleccionado);
            this.snackbar = true;
            this.Mensaje = 'Filtro de vendedor seleccionado sobre rango de fecha listado'
        },
        FiltrarMoneda(){
            let amp = "";
            this.Datos = this.usuarios;
            amp = this.Datos.filter(e => e.moneda == this.MonedaFiltrar);
            this.usuarios = amp;
            this.snackbar = true;
            this.Mensaje = 'Filtro Medio de Moneda Seleccionado sobre rango de fecha listado'
        },
            verLocalidad(item){
            this.NombreLocalidad= item.nombre;
            this.CedulaLocalidad= item.cedulaJuridica;
            this.DireccionLocalidad= item.dirreccion;
            },
        ReenvioFactura(){
            if(this.correo2 == ''){
                this.snackbar = true;
                this.Mensaje = 'Digite el correo al que desea reenviar la factura'
                return;
            }
            let header={"Authorization" : "Bearer " + this.$store.state.token};
                let configuracion= {headers : header};
                    let me=this;
                    axios.put('api/CorreosFacturas/Reenviar',{
                        'NoFactura':me.noFactura,
                        'Estado': 0,
                        'Correo2': me.correo2,
                        'ClaveNumerica':me.ClaveComprobante,
                    },configuracion).then(function(response){
                        me.snackbar = true;
                        me.Mensaje = 'Correo reenviado con éxito a '+me.correo2;                     
                    }).catch(function(error){
                        console.log(error);
                        me.snackbar = true;
                        me.Mensaje = 'Reenvio de factura a '+me.correo2+'. Disponible proximamente';   
                    });
        },
        loadProductosMasVendidos(){
            let me=this;
            let mesn='';
            me.mesesValores.map(function(x){
            switch(parseInt(x.etiqueta)){
                case 1:
                mesn='Enero';
                break;
                case 2:
                mesn='Febrero';
                break;
                case 3:
                mesn='Marzo';
                break;
                case 4:
                mesn='Abril';
                break;
                case 5:
                mesn='Mayo';
                break;
                case 6:
                mesn='Junio';
                break;
                case 7:
                mesn='Julio';
                break;
                case 8:
                mesn='Agosto';
                break;
                case 9:
                mesn='Setiembre';
                break;
                case 10:
                mesn='Octubre';
                break;
                case 11:
                mesn='Noviembre';
                break;
                case 12:
                mesn='Diciembre';
                break;
                default:
                mesn='Error';
            }
            me.nombreMeses.push(mesn);
            me.totalMeses.push(x.valor);
            });
            var ctx = document.getElementById("myChart");
            var myChart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: me.nombreMeses,
                    datasets: [{
                        label: 'Ventas en los últimos 12 Meses',
                        data: me.totalMeses,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)',
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)'
                        ],
                        borderColor: [
                            'rgba(255,99,132,1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)',
                            'rgba(255,99,132,1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        borderWidth: 1
                    }]
                },
                options: {
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero:true
                            }
                        }]
                    }
                }
            });
        },
        OcultarComprobante(){
            this.comprobanteModal = 0;
        },
        CrearNotaCredito() 
        {
        if (this.validarPago() != 1)
        {
            return;
        }
            var localidadTicket = this.obtenerTicket();
            
        },
        FacturarProforma(item){
            this.linkFacturar = 'facturar/'+item.noProforma+'/'+this.$store.state.usuario.localidad;
            location.assign(this.linkFacturar);
        },
        DeleteProforma (item) {
        this.loading = true;
        var me = this;
        if(confirm("Seguro que quiere eliminar la Proforma  "+item.noProforma+"?")){
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
            axios.post('api/Proformas/Delete',{
                'NoProforma': item.noProforma,
                    
            },configuracion).then(function (response) {
                
                me.snackbar = true;
                me.Mensaje = 'Proforma Eliminada Con éxito.'; 
                me.listar(); 
                
                })
                .catch(function (error) {
                    me.snackbar = true;
                    me.Mensaje = 'Error eliminando el Proforma.';    
                })
        }
      },
        crearPDF(){
           var columns = [
            {title: "Código", dataKey: "codigo"},
            {title: "Nombre", dataKey: "Nombre"}, 
            {title: "Precio", dataKey: "precio"}, 
            {title: "Stock", dataKey: "stock"},
			{title: "Docs", dataKey: "docs"},
            ];
            var rows = [];
			var title = "Listado Inventario: "+this.search;
            this.articulos.map(function(x){
                rows.push({codigo:x.codigoProducto,Nombre:x.descripcion.substr(0, 45),precio:x.precioVenta,stock:x.cantidadInvActual,docs:x.sincServer1});
            });

            // Only pt supported (not mm or in)
            var doc = new jsPDF('p', 'pt');
            doc.autoTable(columns, rows, {
                margin: {top: 50},
                addPageContent: function(data) {
                    doc.text(title, 40, 30);
                }
            });
            doc.save('Reporte Inventarios '+this.$store.state.usuario.localidad+'.pdf');
        },
        crearPDFFactura(){
            var fact = this.noFactura;
             var quotes = document.getElementById('factura');
                html2canvas(quotes).then(function (canvas) {
                    var imgData = canvas.toDataURL('image/png');
                    var imgWidth = 210;
                    var pageHeight = 285;
                    var imgHeight = canvas.height * imgWidth / canvas.width;
                    var heightLeft = imgHeight;
                    var doc = new jsPDF();
                    var position = 0;

                    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);                    
                    doc.save('Proforma '+fact+'.pdf');
                });
        },
        getColor (mensajeRespuesta) {
        if (mensajeRespuesta == 'PENDIENTE') return 'info'
        if(mensajeRespuesta == 'Aceptado') return 'orange'
        else  return 'green'
      },
       listar(){
           
            let me=this;
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
            axios.get('api/Proformas/ListarVista/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
                //console.log(response);
                me.usuarios=response.data;
            }).catch(function(error){
                console.log(error);
            });
        },
        listarFechas(){
            if(this.start == '' || this.end == ''){
                this.snackbar = true;
                this.Mensaje = 'Debe seleccionar una fecha inicial y final'
                return;
           }
            let me=this;
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
            axios.get('api/Proformas/ConsultaFechas/'+this.start+'/'+this.end+'/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
                //console.log(response);
                me.usuarios=response.data;
            }).catch(function(error){
                console.log(error);
            });
        },

      close () {
        this.dialog = false;
        this.limpiar();
          },
    

      activarDesactivarMostrar(accion,item){
                this.adModal=1;
                this.adNombre=item.nombre;
                this.adId=item.idusuario;                
                if (accion==1){
                    this.adAccion=1;
                }
                else if (accion==2){
                    this.adAccion=2;
                }
                else{
                    this.adModal=0;
                }
            },
            activarDesactivarCerrar(){
                this.adModal=0;
            },
      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.desserts[this.editedIndex], this.editedItem)
        } else {
          this.desserts.push(this.editedItem)
        }
        this.close()
      },
    },
  }

</script>
My CRUD