<template>
  <div class="confirmation-dialog">
    <p>{{ mensaje }}</p>
    <button @click="confirmar">Aceptar</button>
    <button @click="cancelar">Cancelar</button>
  </div>
</template>

<script>
export default {
  props: {
    mensaje: String,
  },
  methods: {
    confirmar() {
      this.$emit('confirm');
    },
    cancelar() {
      this.$emit('cancel');
    },
  },
};
</script>