<template>
  <v-data-table
    :headers="headers"
    :items="usuarios"
    :search="search"
    sort-by="nombre"
    class="elevation-1"

  >
    <template v-slot:item.condicion="{ item }">
      <v-chip :color="getColor(item.condicion)" dark>{{ item.condicion }}</v-chip>
    </template>
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Usuarios</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
       <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="600">
          <template v-slot:activator="{ on }">
              <v-btn color="success" dark class="mb-2" v-on="on">Crear Usuario</v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container grid-list-md>
                  <v-layout wrap>
                  <v-flex xs12 sm6 md6>
                      <v-text-field prepend-icon="mdi-account-circle" v-model="nombre" label="Nombre">
                        
                      </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                      <v-text-field prepend-icon="mdi-card" v-model="num_documento" label="Cédula">
                      </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm3 md6>
                      <v-select prepend-icon="mdi-blur" v-model="tipo_documento"
                      :items="documentos" label="Tipo Documento">
                      </v-select>
                  </v-flex>
                  <v-flex xs12 sm3 md6>
                      <v-text-field prepend-icon="mdi-phone" type="number" v-model="telefono" label="Teléfono">
                      </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                      <v-text-field prepend-icon="mdi-mail" v-model="email" label="Correo Electrónico">
                      </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field prepend-icon="mdi-lock" type="password" v-model="password" label="Contraseña">
                    </v-text-field>
                    </v-flex>
                  <v-flex xs6 sm6 md6>
                    <v-select prepend-icon="mdi-security" v-model="idrol"
                    :items="roles" label="Rol">
                    </v-select>
                    </v-flex>
                  <v-flex xs6 sm6 md6>
                      <v-select prepend-icon="mdi-home-circle" v-model="localidad"
                      :items="localidades" label="Localidad">
                      </v-select>
                  </v-flex>
                   <v-flex xs12 sm6 md12>
                        <v-text-field prepend-icon="mdi-text" v-model="direccion" label="Puesto">
                        </v-text-field>
                        </v-flex>
                  <v-flex xs12 sm12 md12 v-show="valida">
                      <div class="red--text" v-for="v in validaMensaje" :key="v" v-text="v">
                      </div>
                  </v-flex>
                  </v-layout>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error"  @click="close">Cancelar</v-btn>
              <v-btn color="success"  @click="guardar">Guardar</v-btn>
            </v-card-actions>
            
             </v-card>
        </v-dialog>
        <v-dialog v-model="dialogHorarios" max-width="600" >
          <v-card>
            <template>
              <v-card
                class="mx-auto"
                max-width="600"
              >
                <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title class="headline">
                  Horario de: {{nombre}}
                  </v-list-item-title>
                  <v-list-item-subtitle>DOC: {{num_documento}}</v-list-item-subtitle>
                   <v-list-item-subtitle v-if="existe == 0" class="red--text" >Horario sin Definir</v-list-item-subtitle>
                </v-list-item-content>
                </v-list-item>

                <v-slider
                v-model="time"
                :max="6"
                :tick-labels="labels"
                class="mx-4"
                ticks
                ></v-slider>

                <v-list class="transparent">
                <v-list-item
                  v-for="item in forecast"
                  :key="item.day"
                >
                  <div>{{ item.day }}</div> <v-spacer></v-spacer>
                  
                    <v-switch color="success" label="Labora" v-model="item.libre"> </v-switch> <v-spacer></v-spacer>
                    <v-text-field label="Entrada" :disabled="item.libre == false" v-model="item.inicio" type="time"> </v-text-field> <v-spacer></v-spacer>
                    <v-text-field label="Salida" :disabled="item.libre == false" v-model="item.fin" type="time"> </v-text-field>
                  
                </v-list-item>
                </v-list>

                <v-divider></v-divider>

                <v-card-actions>
                <v-btn v-if="existe == 1" @click="ActualizarHorario()" text="">
                  Actualizar
                </v-btn>
                <v-btn v-if="existe == 0" @click="addEvent()" text="">
                  Crear
                </v-btn>

                <v-btn @click="dialogHorarios = false" text>
                  Salir
                </v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-card>
        </v-dialog>
        
        </v-toolbar>
      </template>
    <template v-slot:item.action="{ item }">
      <v-icon
        small
        class="mr-2"
        color="primary" 
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <template >
      <v-icon
        small
        color="error"
        @click="desactivar(item)"
      > 
        mdi-delete
      </v-icon>
      <v-icon
        small
        color="indigo"
        @click="Horarios(item)"
      > 
        mdi-calendar
      </v-icon>
      

    </template>
     
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">Reset</v-btn>
    </template>
  </v-data-table>
</template>

<script>
import axios from 'axios'
  export default {
    data: () => ({
      
      dialog: false,
      search: '',
      headers: [
        { text: 'Opciones', value: 'action', sortable: false },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Rol', value: 'rol' },
        { text: 'Tipo Documento', value: 'tipo_documento' },
        { text: 'Número Documento', value: 'num_documento', sortable: false  },
        { text: 'Puesto', value: 'direccion', sortable: false  },
        { text: 'Teléfono', value: 'telefono', sortable: false  },
        { text: 'Email', value: 'email', sortable: false  },
        { text: 'Estado', value: 'condicion', sortable: false  },       
        { text: 'Localidad', value: 'localidad',   }                     
       ],
       forecast: [
          { day: 'Lunes', inicio: '07:00', fin: '20:00' ,libre: true, Dia: 1,},
          { day: 'Martes', inicio: '07:00', fin: '20:00' ,libre: true, Dia: 2,},
          { day: 'Miercoles', inicio: '05:00', fin: '20:00' ,libre: true, Dia: 3,},
          { day: 'Jueves', inicio: '07:00', fin: '20:00' ,libre: true, Dia: 4,},
          { day: 'Viernes', inicio: '07:00', fin: '20:00' ,libre: true, Dia: 5,},
          { day: 'Sábado', inicio: '07:00', fin: '20:00' ,libre: true, Dia: 6,},
          { day: 'Domingo', inicio: '07:00', fin: '20:00' ,libre: false, Dia: 7,},
        ],
      search: '',
        editedIndex: -1,
        id: '',
        labels: ['SU', 'MO', 'TU', 'WED', 'TH', 'FR', 'SA'],
        idrol:'',
        roles:[                   
        ],
        nombre:'',
        tipo_documento: '',
        documentos: ['DNI','DIMEX','Cédula Fisica','Cédula Juridica'],
        num_documento: '',
        direccion: '',
        usuarios:[],    
        menu20: '',
        telefono: '',
        localidades:[],
        localidadesColor:[],
        idusuario: '',
        idLocalidadCliente: null,
        localidad: null,
        condicion: true,
        existe: 0,
        email: '',
        password:'',
        dialogHorarios:false,
        actPassword:false,
        passwordAnt:'',
        valida: 0,
        validaMensaje:[],
        adModal: 0,
        adAccion: 1,
        adNombre: '',
        adId: ""  ,
      desserts: [],
      editedIndex: -1,
    }),

    computed: {
      formTitle () {
                return this.editedIndex === -1 ? 'Nuevo usuario' : 'Actualizar usuario'
        }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      
    },

    created () {
      this.listar();
      this.selectLocalidad();
      this.select();
    },

    methods: {
      getColor (condicion) {
        if (condicion == '1') return 'green'
        else  return 'red'
      },
      async listar(){
        let me=this;
        let header={"Authorization" : "Bearer " + this.$store.state.token};
        let configuracion= {headers : header};
        axios.get('api/Usuarios/Listar',configuracion).then(function(response){
          //console.log(response);
          me.usuarios=response.data;
        }).catch(function(error){
          console.log(error);
        });
      },
	

      close () {
        this.dialog = false;
        this.limpiar();
      },
      
      select(){
          let me=this;
          var rolesArray=[];
          let header={"Authorization" : "Bearer " + this.$store.state.token};
          let configuracion= {headers : header};
          axios.get('api/Roles/Select',configuracion).then(function(response){
              rolesArray=response.data;
              rolesArray.map(function(x){
                  me.roles.push({text: x.nombre,value:x.idrol});
              });
          }).catch(function(error){
              console.log(error);
          });
        },
       limpiar(){
                this.id="";
                this.idrol="";
                this.nombre="";
                this.tipo_documento="";
                this.num_documento="";
                this.direccion="";
                this.telefono="";
                this.email="";
                this.password="";
                this.Localidad="";
                this.passwordAnt="";
                this.actPassword=false;
                this.editedIndex=-1;
            },
            guardar () {
                if (this.validar()){
                    return;
                }
                let header={"Authorization" : "Bearer " + this.$store.state.token};
                let configuracion= {headers : header};
                if (this.editedIndex > -1) {
                    //Código para editar
                    //Código para guardar
                    let me=this;
                    if (me.password!=me.passwordAnt){
                        me.actPassword=true;
                    }
                    axios.put('api/Usuarios/Actualizar',{
                        'idusuario':me.id,
                        'idrol':me.idrol,
                        'nombre':me.nombre,
                        'tipo_documento': me.tipo_documento,
                        'num_documento':me.num_documento,
                        'direccion':me.direccion,
                        'telefono': me.telefono,
                        'email':me.email,
                        'password':me.password,
                        'act_password':me.actPassword,
                        'localidad':me.localidad 
                    },configuracion).then(function(response){
                        me.close();
                        me.listar();
                        me.limpiar();                        
                    }).catch(function(error){
                        console.log(error);
                    });
                } else {
                    //Código para guardar
                    let me=this;
                    axios.post('api/Usuarios/Crear',{
                        'idrol':me.idrol,
                        'nombre':me.nombre,
                        'tipo_documento': me.tipo_documento,
                        'num_documento':me.num_documento,
                        'direccion':me.direccion,
                        'telefono': me.telefono,
                        'empresa': me.empresa,
                        'email':me.email,
                        'password':me.password,
                        'localidad':me.localidad 
                    },configuracion).then(function(response){
                        me.close();
                        me.listar();
                        me.limpiar();                        
                    }).catch(err => {
                    this.loading =false;
                    //console.log(err.response);
                    if (err.response.status==400){
                        this.error="No es un email válido";
                    } else if (err.response.status==404){
                        this.error="No existe el usuario o sus datos son incorrectos";
                    }else{
                        this.error="Ocurrió un error";
                    }
                    //console.log(err)
                })
                }
            },

      editItem (item) {
        this.id=item.idusuario;
        this.idrol=item.idrol;
        this.nombre=item.nombre;
        this.tipo_documento=item.tipo_documento;
        this.num_documento=item.num_documento;
        this.direccion=item.direccion;
        this.telefono=item.telefono;
        this.email=item.email;
        this.password=item.password_hash;
        this.passwordAnt=item.password_hash;
        this.localidad=item.localidad;
        this.editedIndex=1;
        this.dialog = true
      },
      selectLocalidad(){
                let me=this;
                var localidadesArray=[];
                let header={"Authorization" : "Bearer "};
                let configuracion= {headers : header};
                axios.get('api/Localidades/SelectLocalidades',configuracion).then(function(response){
                    localidadesArray=response.data;
                    localidadesArray.map(function(x){
                        me.localidades.push({text: x.nombre,value:x.nombre});
                        me.localidadesColor.push({text: x.nombre,value:x.idLocalidad, color: x.color});
                    });
                })
    },
    
    cargarColor(){
          for(var i = 0; i < this.localidadesColor.length; ++i)
          {
             if(this.localidadesColor[i].value == this.localidad.value){
                    this.color = this.localidadesColor[i].color;
                    break;
             }
          }
        },
      validar(){
            this.valida=0;
            this.validaMensaje=[];

            if (this.nombre.length<3 || this.nombre.length>100){
                this.validaMensaje.push("El nombre debe tener más de 3 caracteres y menos de 100 caracteres.");
            }
            if (!this.idrol){
                this.validaMensaje.push("Seleccione un rol.");
            }
            if (!this.tipo_documento){
                this.validaMensaje.push("Seleccione un tipo documento.");
            }

            if (!this.email){
                this.validaMensaje.push("Ingrese el email del usuario.");
            }
            if (!this.password){
                this.validaMensaje.push("Ingrese el password del usuario.");
            }
            if (this.validaMensaje.length){
                this.valida=1;
            }
            return this.valida;
        },

      

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },
      activarDesactivarMostrar(accion,item){
                this.adModal=1;
                this.adNombre=item.nombre;
                this.adId=item.idusuario;                
                if (accion==1){
                    this.adAccion=1;
                }
                else if (accion==2){
                    this.adAccion=2;
                }
                else{
                    this.adModal=0;
                }
            },
            activarDesactivarCerrar(){
                this.adModal=0;
            },
       activar(item){
                let me=this;
                this.adId=item.idusuario;
                let header={"Authorization" : "Bearer " + this.$store.state.token};
                let configuracion= {headers : header};
                axios.put('api/Usuarios/Activar/'+this.adId,{},configuracion).then(function(response){
                    me.adModal=0;
                    me.adAccion=0;
                    me.adNombre="";
                    me.adId="";
                    me.listar();                       
                }).catch(function(error){
                    console.log(error);
                });
            },
            desactivar(item){
                let me=this;
                this.adId=item.idusuario;
                let header={"Authorization" : "Bearer " + this.$store.state.token};
                let configuracion= {headers : header};
                axios.put('api/Usuarios/Desactivar/'+this.adId,{},configuracion).then(function(response){
                    me.adModal=0;
                    me.adAccion=0;
                    me.adNombre="";
                    me.adId="";
                    me.listar();                       
                }).catch(function(error){
                    console.log(error);
                });
            },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.desserts[this.editedIndex], this.editedItem)
        } else {
          this.desserts.push(this.editedItem)
        }
        this.close()
      },
    },
  }
</script>
My CRUD
