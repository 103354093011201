<template>
  <div>
    <div style="max-height: 700px;
   " class="formbanner1-component mini-spacer bg-extra-light">
      <v-container>
        <!-- -----------------------------------------------
            Start Form Banner1
        ----------------------------------------------- -->
        <v-row justify="center">
          <v-col cols="12" md="12" lg="5" xs="12" sm="12" class="d-flex align-center">
            <div class="text-center text-md-left">
              <h3 style="font-size: 48px;
				letter-spacing: -1px;
				margin: 10px 0 5px; font-size: 39px;
      			line-height: 42px;" class="formbanner1-title font-weight-bold">
                Poderoso Punto de Venta
              </h3>
              <p style="text-left" class="mt-16 pt-4">
                Funciona con o sin internet, obtienes reportes en tiempo real desde cualquier parte. <br>
				✔ Funciona con o sin Internet <br>
				✔ Reportes y estadisticas <br>
				✔ Adaptable a cada negocio <br>
				✔ Sincronización con tienda en linea <br>
				✔ Implementaciónes a la medida <br>
              </p>
            </div>
          </v-col>
          <v-col cols="12" md="12" lg="6" xs="12" sm="12" >
			<video class="pa-2" width="750" autoplay loop>
				<source style="top:15px;" :src="require('@/assets/images/form-banner/hw-creative-s3-pos-en-gb.mp4')" type="video/mp4">
			</video>
          </v-col>
        </v-row>

        <!-- -----------------------------------------------
            End Form Banner1
        ----------------------------------------------- -->
      </v-container>
    </div>
  </div>
</template>
<script>
export default {
  name: "FormBanner1",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scooped>
	// ---------------------------------------------------------------------
// Banner Search Widget
// ---------------------------------------------------------------------

.banner-title {
  font-size: 42px;
  line-height: 54px;
  margin: 20px 0 15px;
}

.banner-subtitle {
  font-size: 21px;
}

.banner-wrapper {
  background: $accent;
  padding: 20px 0 20px;
  min-height: 600px;
  display: flex;
  align-items: center;
}

@media (max-width: 767px) {
  .banner-title {
    font-size: 29px;
    line-height: 40px;
  }
  .banner-wrapper {
    padding: 90px 0 20px;
  }
}

@media (min-width: 1200px) {
  .banner-subtitle {
    margin-right: 150px;
  }
}

//
// Banner1
//
.banner1-component {
  padding: 60px 0;
  background-color: #fdbfa8;
  .banner1-title {
    font-size: 48px;
    line-height: 50px;
    margin: 20px 0 15px;
  }
}

@media (max-width: 991px) {
  .banner1-component {
    .banner1-title {
      font-size: 35px;
    }
  }
}

//
// Banner2
//
.banner2-component {
  padding: 145px 0;

  .banner2-title {
    line-height: 50px;
    font-size: 36px;
    margin: 20px 0 15px;
  }
}

@media (max-width: 991px) {
  .banner2-component {
    padding: 45px 0;
    .banner2-title {
      font-size: 32px;
    }
  }
}

//
// Form Banner
//

.formbanner1-component {
  max-height: 700px;
  background-color: #e9f3f8;
  .formbanner1-title {
    font-size: 48px;
    line-height: 1;
    letter-spacing: -1px;
    margin: 20px 0 15px;
  }
}

.stylish-input-group {
  display: flex;
  align-items: center;
  .v-text-field__details {
    display: none;
  }
  .v-input__slot {
    margin-bottom: 0;
    border-radius: 36px 0 0 36px !important;
    text-indent: 35px;
    font-weight: 300;
    text-overflow: ellipsis;
    min-height: 72px;
    padding-left: 32px !important;
  }
  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
    > .v-input__control
    > .v-input__slot {
    box-shadow: -5px 0 30px 0 rgb(0 0 0 / 5%);
  }
  .v-btn {
    border-radius: 0 36px 36px 0;
    height: 72px !important;
    padding: 0 30px !important;
  }
}

@media (max-width: 991px) {
  .formbanner1-component {
    .formbanner1-title {
      font-size: 39px;
      line-height: 42px;
    }
  }
}

@media (max-width: 767px) {
  .stylish-input-group {
    .v-input__slot {
      min-height: 60px;
      padding-left: 20px !important;
    }
    .v-btn {
      height: 60px !important;
    }
  }
}

</style>