<template>
    <div>
        <v-dialog v-model="comprobanteModal" max-width="1000px">
            <v-card class="pa-3">
                
                <v-card-text>
                    <v-btn class="oculto-impresion mx-2" color="indigo" dark="" @click="crearPDFFactura()"><v-icon>mdi-file-pdf</v-icon> PDF</v-btn>
                    <v-btn class="oculto-impresion mx-2" color="grey" dark="" onclick="window.print()"> <v-icon>mdi-printer</v-icon> A1</v-btn>
                    <v-btn class="oculto-impresion mx-2" color="grey" dark="" :to="nofactura2"> <v-icon>mdi-printer</v-icon> POS </v-btn>
                    <v-btn class="oculto-impresion mx-2" color="grey" dark="" @click="ReenvioFactura()" > <v-icon>mdi-cloud-download-outline</v-icon>XML Resp</v-btn>
                    <v-btn class="oculto-impresion mx-2" color="grey" dark="" @click="ReenvioFactura()" > <v-icon>mdi-cloud-download-outline</v-icon> XML FC </v-btn>
                    <v-btn class="oculto-impresion mx-2" color="success" dark=" " @click="ReenvioFactura()" > <v-icon>mdi-email</v-icon><v-icon x-small="">mdi-send</v-icon> </v-btn>
                    <v-text-field  class="oculto-impresion mx-2" label="Correo de reenvio" v-model="correo2"> </v-text-field> 
                    <div id="factura">
                        <header>
                            <div id="logo">
                                <img :src="Logo3" width="120" height="120">
                            </div>
                            <div v-for="det in localidadencabezado" :key="det.nombre" id="datos">
                                <p id="encabezado">
                                     <b class="text-h5 primary--text"> <br>{{det.razonSocial}}, </b><br> {{det.dirreccion}}<br> 
                                    : {{det.cedulaJuridica}} <br>{{det.telefono}}<br>
                                    {{det.correo}}
                                    
                                </p>
                            </div>
                            <div style="text-align:right;" class="font-weight-regular mx-7 mt-5 body-2" id="fact">
                             
                                {{tipoDocumento}}<br>
                               NoFactura Interno: {{noFactura}}<br>
							   	<div v-if="RefComprobante != ''"> Referencia : {{RefComprobante}} 
								</div>
                               NoFactura: {{noFacturaElectronica}}<br>
                               {{fecha}}--{{hora}} <br>
                               Vendedor: {{vendedor}}
                               
                                
                            </div>
                        </header>
                         <br>
                        <section>
                            <div>
                                <tbody>
                                    <tr>
                                        <td class="body-1">
                                            <strong><v-icon>mdi-account </v-icon></strong> {{nombreReceptor}}<br>
                                            <strong><v-icon>mdi-card-bulleted </v-icon></strong> {{cedulaReceptor}} <br>
                                            <strong><v-icon>mdi-email </v-icon></strong> {{correoReceptor}} <br>
                                        </td>
                                    </tr>
                                </tbody>
                            </div>
                        </section>
                        <section>
                            <div>
                                <v-simple-table dense id="facarticulo" >
                                    <thead>
                                        <tr id="fa" class="primary white--text">
                                            <th class="white--text text-center">Código</th>
                                            <th class="white--text text-center">Cant</th>
                                            <th class="white--text text-center">Descripción</th>
                                            <th class="white--text text-center">Precio</th>
                                            <th class="white--text text-center">Iva</th>
                                            <th class="white--text text-center">% Desc</th>
                                            <th class="white--text text-center">Importe</th>
                                            <th class="white--text text-center">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody> 
                                        <tr v-for="det in detalles" :key="det.codigoProducto" >
                                            
                                            <td style="text-align:center;"> {{det.codigoProducto}} </td>
                                            <td style="text-align:center;"> {{det.cantidad}} </td>
                                            <td style="text-align:center;">{{det.descripcion}}</td>
                                            <td style="text-align:center;">{{det.precioUnitario}}</td>
                                            <td style="text-align:center;">{{det.imp}}</td> 
                                            <td style="text-align:center;">{{det.descuento}}</td> 
                                            <td style="text-align:center;">{{det.subtotal}}</td>
                                            <td style="text-align:center;">{{det.total}}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr >
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th class="secondary rounded-l-xl" style="text-align:right;">SUBTOTAL</th>
                                            <th class="secondary" style="text-align:right;"> {{monedaPago}} {{subTotal}}</th>
                                        </tr>
                                        <tr>
                                            <th></th>
                                            <th class="text--grey"> Firma:</th>
                                            <th class="text--grey">________________________</th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th class="secondary rounded-l-xl" style="text-align:right;">IVA</th>
                                            <th class="secondary" style="text-align:right;"> {{monedaPago}} {{iv}}</th>
                                        </tr>
                                        <tr>
                                            <th></th>
                                            <th class="text--grey">Cédula:</th>
                                            <th class="text--grey">________________________ </th>
                                            <th></th>
                                            <th></th>
                                            <th>  </th>
                                            <th class="primary white--text rounded-l-xl" style="text-align:right;">TOTAL</th>
                                            <th class="primary white--text " style="text-align:right;"> {{monedaPago}} {{total}}</th>
                                        </tr>
                                    </tfoot>
                                    
                                </v-simple-table>
                            </div>
                        </section>
                        <br>
                        <br>
                        <footer>
                            <v-row>
                                <v-col class="mx-7" cols="12" sm="2">
                                <qrcode-vue :value="value" :size="size" level="H"></qrcode-vue>
                                    Escanéame
                                </v-col>
                                <v-col class="body-2" cols="12" sm="8">
                                    <div id="gracias">
                                    <br> Clave Comprobante: {{ClaveComprobante}} <br> Cuentas Bancarias: {{Cuentas}}
                                    <p><b>Gracias por su compra!</b><br> Detalle:  {{comentario}}  <br> Facturas Disponibles en {{value}}<br>
                                    Autorizado mediante Resolucion DGT-R-033-2019 DEL 6-20-2019</p>
                                </div>
                                 </v-col>
                               
                            </v-row>
                        </footer>
                    </div>
                    <v-btn @click="OcultarComprobante()" color="error" flat><v-icon> mdi-exit-to-app </v-icon></v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-data-table 
            :headers="headers"
            :items="usuarios"
            :search="search"
			:loading="loading"
			items-per-page="5"
            sort-by="noFactura"
            sort-desc="noFactura"
            class="elevation-1 oculto-impresion"

            >
            
            <template class="oculto-impresion" v-slot:top>
                <v-row class="pa-2">
                    <v-col cols="12" sm="6" md="3">
                        <v-row class="rounded-xl var">
                            <v-btn v-if="esAdministrador || esSupervisor" fab="" color="error" class="mx-2" @click="crearPDF()"> <v-icon large="">mdi-file </v-icon> </v-btn>
								<vue-excel-xlsx v-if="esAdministrador || esSupervisor"
                                    :data="usuarios"
                                    :columns="columns"
                                    :filename="'Reporte de Ventas '+$store.state.usuario.localidad"
                                    :sheetname="'Ventas'"
                                    >
                                    <v-btn fab="" color="green">  <v-icon large="">mdi-file-excel </v-icon> </v-btn>
								</vue-excel-xlsx> 
                            <v-card-title>Ventas</v-card-title>
                        </v-row>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">    
                        <v-text-field v-model="search"  append-icon="mdi-magnify"
                         label="Buscar" filled hide-details
                        />
						<v-select filled hide-details="" @change="listarFechas()" label="Localidad" v-model="Localidad" :items="Localidades" /> 
                    </v-col>
                    <v-col cols="12" sm="10" md="4">
                        <v-text-field hide-details="" filled="start" v-model="start" type="date" label="Fecha inicial"></v-text-field>
						<v-text-field hide-details="" filled v-model="end"  type="date" label="Fecha final"></v-text-field>
                  
                    </v-col>
                    <v-col cols="12" sm="1" md="1">
                        <v-btn large :loading="loading" color="info" @click="listarFechas()" > <v-icon fab> mdi-cloud-search </v-icon> </v-btn>
                    </v-col>
                </v-row>
               
            </template>
            <template class="oculto-impresion" v-slot:item.action="{ item }">
            <v-icon
                class="mr-2"
                color="primary" 
                @click="MostrarComprobante(item)"
            >
                mdi-file-document
            </v-icon>
            
            </template>

            <template v-slot:no-data>
                <v-btn color="primary" @click="listarFechas">Reiniciar</v-btn>
            </template>
        </v-data-table>
        <template class="pa-3">
            <v-snackbar
                    v-model="snackbar" top vertical="" :timeout="5000"
                    >
                    {{ Mensaje }}
                    <v-btn
                    color="info"
                    text
                    @click="snackbar = false"
                    >
                    Cerrar
                    </v-btn>
            </v-snackbar>
            
            <v-row v-if="esAdministrador || esSupervisor || esBoss" flat color="white"  class="oculto-impresion pa-2">
                <v-col v-if="esAdministrador || esSupervisor" cols="12" sm="4">
                    <v-row flat color="white"  class="rounded-xl light-blue lighten-5 mx-5">
                    <v-card-text>
                        <v-card-title class="primary--text text-xl-h4 text-lg-h6 text-sm-body-2"> <b>Resumen General </b></v-card-title>
                        <v-sub-title>Datos consultos del {{start}} hasta {{end}} </v-sub-title> <br>
                        <b> ImpServicio: </b>{{MonedaFiltrar}} {{calcularServicio}} <br>
                        <b> Recibos: </b>{{MonedaFiltrar}} {{(calcularRecibos)}} <br> 
						<b> Transferencias: </b>{{MonedaFiltrar}} {{calcularTransferencias}} <br> 
						<b> Tarjetas: </b>{{MonedaFiltrar}} {{calcularTarjetas}} <br> 
						<b> Efectivo: </b>{{MonedaFiltrar}} {{calcularEfectivo}} <br> 
                        <b> Total Hacienda:</b> {{MonedaFiltrar}} {{(calcularTotal)-(calcularNC)-(calcularRecibos)}} <br>
                        <b> IVA Neto: </b>{{MonedaFiltrar}} {{TotalIva=(calcularIva)}} <br>
                        <b> Ventas Bruto:</b> {{MonedaFiltrar}} {{(calcularTotal)}} <br>
                        <b> Notas Crédito: </b>  {{MonedaFiltrar}} {{(calcularNC)}} <br>
                        <b> Ventas Netas:</b> {{MonedaFiltrar}} {{(calcularTotal)-(calcularNC)}} <br>
                    
                    </v-card-text>
                    </v-row>
                </v-col>
                <v-col v-if="esAdministrador || esSupervisor" cols="12" sm="5">
					<v-card-title class="resumenprimary--text text-xl-h4 text-lg-h6 text-sm-body-2">
						<b>Resumen por Usuario </b>
					</v-card-title>
					<v-row>
						<div v-for="resumen in resumenPorCajero()" :key="resumen.Cajero">
							<p class="var mx-2">
								<strong>Cajero: </strong> {{ resumen.Cajero }}<br>
								Total Facturado: {{ resumen.TotalFacturado }}<br>
								ImpServicio: {{ resumen.ImpServicioTotal.toFixed(2) }}<br>
								Efectivo: {{ resumen.TotalEfectivo }}<br>
								Tarjeta: {{ resumen.TotalTarjeta }}<br>
								Sinpe: {{ resumen.TotalSinpe }}<br>
								Deposito: {{ resumen.TotalDeposito }}<br>
								Dolares: {{ resumen.TotalDolares }}<br> <!-- Corregido para mostrar TotalDolares -->
								NC: {{ resumen.TotalNC }}<br>
								Crédito: {{ resumen.credito }}
							</p>
							<!-- Muestra otros campos del resumen según necesites -->
						</div>
					</v-row>
				</v-col>

                <v-col v-if="esAdministrador || esSupervisor" cols="12" sm="3">
                    <v-card-title class="primary--text text-xl-h4 text-lg-h6 text-sm-body-2"> <b>Resumen por Vendedor </b></v-card-title>
                        
					<v-row>
                        <div v-for="resumen in resumenPorVendedor()" :key="resumen.Cajero">
							<p class="var mx-2"> <strong>Vendedor: </strong> {{ resumen.Cajero }}<br>
							Total Facturado: {{ resumen.TotalFacturado }}<br>
							ImpServicio: {{ resumen.ImpServicioTotal.toFixed(2) }}<br>
							Efectivo: {{ resumen.TotalEfectivo }}<br>
							Tarjeta: {{ resumen.TotalTarjeta }}<br>
							Sinpe: {{ resumen.TotalSinpe }}<br>
							Deposito: {{ resumen.TotalDeposito }} <br>
							Dolares: {{ resumen.TotalDolares }} <br>
							NC: {{ resumen.TotalNC }}</p>
							<!-- Muestra otros campos del resumen según necesites -->
						</div>
                    </v-row>
                </v-col>
                
                
            </v-row>
        </template>
    </div>
</template>

<script scoped>
import axios from 'axios'
import Chart from 'chart.js'
import jsPDF from 'jspdf'
import VueExcelXlsx from "vue-excel-xlsx";
import Vue from "vue";
import html2canvas from 'html2canvas';
import QrcodeVue from 'qrcode.vue'

Vue.use(VueExcelXlsx);
  export default {
    data: () => ({
    mesesValores: null,
    nombreMeses: [],
    totalMeses: [],
    value: 'https://www.noah.cr',
    size: 100,
    dialog: false,
	search: '',
	RefComprobante: '',
    ClienteSeleccionado : '',
    today: new Date().toISOString().substr(0, 10),
    focus: new Date().toISOString().substr(0, 10),
    headers: [
    { text: 'Opciones', value: 'action', sortable: false },
    { text: 'Factura', value: 'noFactura' },
    { text: 'Fecha', value: 'fecha' },
    { text: 'Nombre Cliente', value: 'nombreReceptor',  }, 
    { text: 'Tipo', value: 'tipoDocumento' },
    { text: 'Grabado', value: 'gravado', sortable: false  },
    { text: 'Excento', value: 'exento', sortable: false  },
    { text: 'IVA', value: 'iv', sortable: false  },
    { text: 'Imp Servicio', value: 'impServicio',  },  
    { text: 'Total', value: 'total' },  
    { text: 'TipoPago Gen', value: 'tipoPago',  },  
	{ text: 'Efectivo', value: 'totalEfectivo'  },  
	{ text: 'Tarjetas', value: 'totalTarjeta'  },   
	{ text: 'Transferencias', value: 'totalDeposito'  },    
	{ text: 'Sinpe', value: 'totalSimpe'  },      
	{ text: 'Dolares', value: 'totalDolares'  },  
	{ text: 'NC', value: 'totalNC'  },   
    { text: 'Vendedor', value: 'vendedor', },
    { text: 'Cajero', value: 'cajero', },
	{ text: 'Detalle', value: 'detalle', },
	{ text: 'Motorizado', value: 'motorizado', },
	{ text: 'IdCierre', value: 'idCierreCaja', },
    ],
    columns : [ 
		{ label: 'Opciones', field: 'action'},
		{ label: 'Factura', field: 'noFactura' },
		{ label: 'Fecha', field: 'fecha' },
		{ label: 'Nombre Cliente', field: 'nombreReceptor',  }, 
		{ label: 'Tipo', field: 'tipoDocumento' },
		{ label: 'Grabado', field: 'gravado' },
		{ label: 'Excento', field: 'exento' },
		{ label: 'IVA', field: 'iv' },
		{ label: 'Imp Servicio', field: 'impServicio',  },  
		{ label: 'Total', field: 'total' },  
		{ label: 'TipoPago Gen', field: 'tipoPago',  },  
		{ label: 'Efectivo', field: 'totalEfectivo'  },  
		{ label: 'Tarjetas', field: 'totalTarjeta'  },   
		{ label: 'Transferencias', field: 'totalDeposito'  },    
		{ label: 'Sinpe', field: 'totalSimpe'  },      
		{ label: 'Dolares', field: 'totalDolares'  },  
		{ label: 'NC', field: 'totalNC'  },   
		{ label: 'Vendedor', field: 'vendedor', },
		{ label: 'Cajero', field: 'cajero', },
		{ label: 'Detalle', field: 'detalle', },
		{ label: 'Motorizado', field: 'motorizado', },
		{ label: 'IdCierre', field: 'idCierreCaja', },
	],
	columnsXero: [
		{ label: "ContactName",field: "nombreReceptor", }, 
		{ label: 'EmailAddress', field: 'correoReceptor',  },   
		{ label: 'POAddressLine1', field: 'na',  },  
		{ label: 'POAddressLine2', field: 'na',  },  
		{ label: 'POAddressLine3', field: 'na',  },  
		{ label: 'POAddressLine4', field: 'na',  },  
		{ label: 'POCity', field: 'na',  },  
		{ label: 'PORegion', field: 'na',  },  
		{ label: 'POPostalCode', field: 'na',  },  
		{ label: 'PoCountry', field: 'na',  },  
		{ label: "InvoiceNumber",field: "noFactura", },
		{ label: "Reference",field: "metodoPago", },
		{ label: "Date",field: "fecha", },
		{ label: "DueDate",field: "fecha", },
		{ label: 'Total', field: 'total' },  
		{ label: 'InventoryItemCode', field: 'na' },  
		{ label: 'Description', field: 'na' },  
		{ label: 'Quantity', field: 'cantidad' },  
		{ label: "UnitAmount",field: "gravado", },
		{ label: "Discount",field: "descuento", },
		{ label: "AccountCode",field: "cuentacontable", },
		{ label: "TaxType",field: "taxType", },
		{ label: 'TaxAmount', field: 'impuesto13',  }, 
		{ label: 'TrackingName1', field: 'localidad',  }, 
		{ label: 'TrackingOption', field: 'na',  }, 
		{ label: 'TrackingName2', field: 'na',  }, 
		{ label: 'TrackingOption2', field: 'na',  }, 
		{ label: 'Currency', field: 'moneda',  }, 
		{ label: 'BrandingTheme', field: 'na',  }, 
		{ label: 'Monto Pagado', field: 'total' },  
		{ label: 'Cuenta contable', field: 'cuentacontable',  },
		{ label: 'FechaPago', field: 'fecha',  }, 
		{ label: 'Referencia de Pago', field: 'referencia',  },  
	],
	cabeceraDetalles: [
		{ text: 'Código', value: 'codigoProducto', sortable: false  },
		{ text: 'Descripción', value: 'descripcion', sortable: false  },
		{ text: 'Precio IVI', value: 'actionprecio', sortable: false  },
		{ text: 'Cantidad a debitar', value: 'actioncantidad', sortable: false  },
		{ text: 'Eliminar', value: 'actionborrar', sortable: false  },
	],
	tipoDocumentos: [
		'FISICA',
		'JURIDICA',
		'EXTRANJERO',
	],
    PagosFiltrados: "",
    Monedas: ["USD","CRC"],
    TiposPago:["TARJETA","DEPOSITO","EFECTIVO","TRANSFERENCIA"],
	Descuento: '',
    MonedaFiltrar : "",
    tipoCedulaReceptor: '',
      search: '',
    TotalRecibos: 0,
    noFacturaElectronica: '',
    emailRules: [
    v => !!v || 'El correo electrónico es requerido',
    v => /.+@.+\..+/.test(v) || 'El correo electrónico es requerido debe ser válido',],
    cedulaRules: [value => !!value || 'La cédula es requerida'
    , value => (value && value.length >= 9) || 'Debe ingresar 9 carácteres como mínimo',],
    nameRules: [
    value => !!value || 'El nombre es requerido',
    value => (value && value.length >= 10) || 'Debe ingresar 10 carácteres como mínimo',
    ],
    celRules: [
        value => !!value || 'El número es requerido',
        value => (value && value.length >= 8) || 'Debe ingresar 8 carácteres como mínimo',
        ],
    justificacionRules: [
        value => !!value || 'La justificación es requerida',
        value => (value && value.length >= 7) || 'Debe ingresar 7 carácteres como mínimo',
        ],
    editedIndex: -1,
    id: '',
	idrol:'',
	loading: true,
    noFactura: 0,
    nofactura2: '/ticket/2',
    comprobanteModal: 0,
    justificacionNota: ["Ajuste de precio precio" ,"Saldo incobrable"," Pronto de pago","Error en cobro"],
    productosDatos:[],
    MensajeRespuesta: '',
    Logo3: null,
    ClaveComprobante: '',
    EstadoNC: 0,
    ModalNotaCredito: false,
    snackbar: false,
    Mensaje: "",
    correo2: '',
    VendedorSeleccionado: '',
    total: 0,
    justificacion: '',
    medioDePago : '',
	detalles: [],
	detalle: '',
    localidadencabezado:[],
    NombreLocalidad:'',
    CedulaLocalidad:'',
    Datos: [],
    DireccionLocalidad:'',
    TelefonoLocalidad:'',
    fecha: '',
    hora: '',
	resumen:[],
    nombreReceptor: '',
    cedulaReceptor:'',
    direccion:'',
    monedaPago: "CRC",
    telefonoReceptor:'',
	correoReceptor:'',
	Resolucion: '',
	vendedor: '',
	ShowNoFact: true,
	Localidad: "",
	Localidades: [],
	subtotal: 0,
	Cuentas: '',
	modoPDV: '',
	link: '',
    comentario: '',
    grabado:0 ,
    excento: 0,
    Logo2 : '',
    iv: 0.0,
    clientes: [],
    start : new Date().toISOString().substr(0, 10),
    end : new Date().toISOString().substr(0, 10),
    tipo_documento: '',
    Vendedores: [],
    usuarios:[],    
    desserts: [],
    editedIndex: -1,
    descuento: 0,
    
    }),

    computed: {
		cajerosUnicos() {
			return [...new Set(this.usuarios.map(item => item.cajero))];
		},
		vendedoresUnicos() {
			return [...new Set(this.usuarios.map(item => item.vendedor))];
		},
		formTitle () {
				return this.editedIndex === -1 ? 'Nuevo usuario' : 'Actualizar usuario'
		},
		calcularTotalNota:function(){
		var resultado=0.0;
		var preresult = 0.0;
			for(var i=0;i<this.detalles.length;i++){
					if(this.detalles[i].Ivi == false){  //Si el campo estadoInventario es = a false entonces el IVA se suma
						resultado= (parseFloat(this.totalGravado) + parseFloat(this.calcularImpuesto)+parseFloat(this.totalExento)) - parseFloat(this.totalDescuento);
				} if(this.detalles[i].Ivi == true){
					if(this.monedaPago == 'USD'){
						preresult= (parseFloat(this.totalGravado) + parseFloat(this.calcularImpuesto)+parseFloat(this.totalExento)) - parseFloat(this.totalDescuento);
						resultado = parseFloat(preresult)
						return resultado.toFixed(2);
					}
					preresult= (parseFloat(this.totalGravado) + parseFloat(this.calcularImpuesto)+parseFloat(this.totalExento)) - parseFloat(this.totalDescuento);
					resultado = parseInt(preresult)
				}
			}
			
			
			return resultado.toFixed(2);
		},
		calcularTotal:function(){
			var resultado=0.0;
			for(var i=0;i<this.usuarios.length;i++){
				if(this.usuarios[i].tipoDocumento == "TIQUETE ELECTRONICO" || this.usuarios[i].tipoDocumento == "FACTURA ELECTRONICA" || this.usuarios[i].tipoDocumento == "RECIBO"){
					resultado=resultado+(this.usuarios[i].total * 1);
					}
			}
			
			return resultado.toFixed(2);
		},
		calcularNC:function(){
			var resultado=0.0;
			for(var i=0;i<this.usuarios.length;i++){
				if(this.usuarios[i].tipoDocumento == 'NOTA DE CREDITO')
					resultado=resultado+(this.usuarios[i].total * 1);
			}
			
			return resultado.toFixed(2);
		},
		calcularRecibos:function(){
			var resultado=0.0;
			for(var i=0;i<this.usuarios.length;i++){
				if(this.usuarios[i].tipoDocumento == 'RECIBO')
					resultado=resultado+(this.usuarios[i].total * 1);
			}
			
			return resultado.toFixed(2);
		},
		calcularTransferencias:function(){
			var resultado=0.0;
				for(var i=0;i<this.usuarios.length;i++){ 
					if(this.usuarios[i].tipoDocumento == "TIQUETE ELECTRONICO" || this.usuarios[i].tipoDocumento == "FACTURA ELECTRONICA" || this.usuarios[i].tipoDocumento == "RECIBO"){
						resultado=resultado+(this.usuarios[i].totalSimpe * 1);
					}
				}
			
			return resultado.toFixed(2);
		},
		calcularTarjetas:function(){
			var resultado=0.0;
			for(var i=0;i<this.usuarios.length;i++){
					if(this.usuarios[i].tipoDocumento == "TIQUETE ELECTRONICO" || this.usuarios[i].tipoDocumento == "FACTURA ELECTRONICA" || this.usuarios[i].tipoDocumento == "RECIBO"){
						resultado=resultado+(this.usuarios[i].totalTarjeta * 1);
					}
				}
				
			
			return resultado.toFixed(2);
		},
		calcularEfectivo:function(){
			var resultado=0.0;
			for(var i=0;i<this.usuarios.length;i++){
					if(this.usuarios[i].tipoDocumento == "TIQUETE ELECTRONICO" || this.usuarios[i].tipoDocumento == "FACTURA ELECTRONICA" || this.usuarios[i].tipoDocumento == "RECIBO"){
						resultado=resultado+(this.usuarios[i].totalEfectivo * 1);
					}
				}
				
			
			return resultado.toFixed(2);
		},
		calcularIva:function(){
			var resultado=0.0;
			for(var i=0;i<this.usuarios.length;i++){
				if(this.usuarios[i].tipoDocumento == "TIQUETE ELECTRONICO" || this.usuarios[i].tipoDocumento == "FACTURA ELECTRONICA"){
						resultado=resultado+(this.usuarios[i].impuesto13 * 1);
						
				}   if(this.usuarios[i].tipoDocumento == 'NOTA DE CREDITO') {
					resultado -= (this.usuarios[i].impuesto13 * 1);
				}
			}
			
			return resultado.toFixed(2);
		
		},
		calcularIva13:function(){
			var resultado=0.0;
			for(var i=0;i<this.usuarios.length;i++){
						resultado=resultado+(this.usuarios[i].impuesto13 * 1);
					
			} 
			
			return resultado.toFixed(2);
		
		},
		calcularIva1:function(){
			var resultado=0.0;
			for(var i=0;i<this.usuarios.length;i++){
				resultado=resultado+(this.usuarios[i].impuesto1 * 1);
				
			}
			
			return resultado.toFixed(2);
		
		},
		calcularServicio:function(){
			var resultado=0.0;
			for(var i=0;i<this.usuarios.length;i++){
				resultado=resultado+(this.usuarios[i].impServicio);
				
			}
			
			return resultado.toFixed(2);
		
		},
		calcularIva2:function(){
			var resultado=0.0;
			for(var i=0;i<this.usuarios.length;i++){
						resultado=resultado+(this.usuarios[i].impuesto2 * 1);
					
			}
			
			return resultado.toFixed(2);
		
		},
		calcularIva4:function(){
			var resultado=0.0;
			for(var i=0;i<this.usuarios.length;i++){
						resultado=resultado+(this.usuarios[i].impuesto4 * 1);
				
			}
			
			return resultado.toFixed(2);
		},
		esAdministrador(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='Administrador';
		},

		esSupervisor(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='Supervisor';
		},
		esVendedor(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='Vendedor';
		},
		esBoss(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='King Boss';
		},
		esCliente(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='Cliente';
		},
		calcularImpuestoTotal:function(){
		var resultado=0.0;
		var test= false;
			for(var i=0;i<this.detalles.length;i++){
			if(this.detalles[i].Ivi == false && this.detalles[i].gravado == 'S'){
				resultado=resultado+(this.detalles[i].precio * this.detalles[i].cantidad);
				return resultado.toFixed(2);
			}    
				if(this.detalles[i].gravado == 'S' && this.detalles[i].Ivi == true)
				{
				if(this.detalles[i].valorImpuesto == 13)
				{
				resultado+=((this.detalles[i].precio / 1.13) * this.detalles[i].cantidad);
				} else
				if(this.detalles[i].valorImpuesto == 1)
				{
					resultado+=((this.detalles[i].precio / 1.01) * this.detalles[i].cantidad);
				} else
				if(this.detalles[i].valorImpuesto == 2)
				{
					resultado+=((this.detalles[i].precio / 1.02) * this.detalles[i].cantidad);
				} else
				if(this.detalles[i].valorImpuesto == 4)
				{
					resultado+=((this.detalles[i].precio / 1.04) * this.detalles[i].cantidad);
				}
				}
			}
		return resultado.toFixed(2);
		},
    },
    

    watch: {
		dialog (val) {
			val || this.close()
		},
    },

    created () {
		this.listarFechas();
		this.listarLocalidad();
		this.listarAsociadas();
    },

    methods: {
		listarAsociadas(){
			var items = [];
			let me=this;
			this.Localidades = [];
			this.modelCarga = true;
			me.Localidades.push(me.$store.state.usuario.localidad);
			this.detalles = [];
			this.Localidad = this.$store.state.usuario.localidad
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			axios.get('api/LocalidadesAsociadas/'+this.$store.state.usuario.nombre,configuracion).then(function(response){
				items=response.data;
				items.map(function(x){
					me.Localidades.push(x.localdiadAsociada);
				});
				me.modelCarga = false
			}).catch(function(error){
				alert("Producto no encontrado");
				me.modelCarga=false;
			});
    	},
		listarAux(id){
			let me=this;
			let aux = [];
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			axios.get('api/Facturas/ListarAuxTiquet/'+id+'/'+this.$store.state.usuario.localidad+'',configuracion).then(function(response){
				//console.log(response);
				aux=response.data;
				me.vuelto = aux[0].vuelto;
				me.tipoDocumento = aux[0].tipoDocumento;
				me.cedulaReceptor = aux[0].cedulaReceptor;
				me.total = aux[0].total;
				me.subtotal = aux[0].subTotal;
				me.nombreReceptor = aux[0].nombreReceptor;
				me.cedulaReceptor = aux[0].cedulaReceptor;
				me.imp1 = aux[0].impuesto1;
				me.imp2 = aux[0].impuesto2;
				me.imp4 = aux[0].impuesto4;
				me.imp13 = aux[0].impuesto13;
				me.tipoPago = aux[0].tipoPago;
				me.fecha = aux[0].fecha;
				me.vendedor = aux[0].vendedor;
				me.ClaveComprobante  = aux[0].claveComprobante;
				me.clav1 = me.ClaveComprobante.substring(0, 25);
				me.clav2 = me.ClaveComprobante.substring(25, 50);
			}).catch(function(error){
				console.log(error);
			});	
		},
        select(){
			let me=this;
			var clientesArray=[];
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
				axios.get('api/Empleados/SelectEmpleadosLocalidad/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
				clientesArray=response.data;
				clientesArray.map(function(x){
					me.clientes.push(x.nombre);
				});
			})
        },
        listarDetalles(id){
			let me=this;
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			axios.get('api/Facturas/ListarDatosFactura/'+id+'/'+this.$store.state.usuario.localidad+'',configuracion).then(function(response){
				//console.log(response);
				me.detalles=response.data;
				me.EstadoNC = me.detalles[0].estadoNC;
				me.hora = me.detalles[0].hora;
				me.noFacturaElectronica = me.detalles[0].noFactura;
				me.ClaveComprobante = me.detalles[0].claveComprobante;
				me.RefComprobante = me.detalles[0].refComprobante;
				me.comentario = me.detalles[0].cajero;
			}).catch(function(error){
				console.log(error);
			});
		},
		listarDetallesNota(id){
			let me=this;
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			axios.get('api/Facturas/ListarDatosFacturaNota/'+id+'/'+this.$store.state.usuario.localidad+'',configuracion).then(function(response){
				//console.log(response);
				me.detalles=response.data;
				me.EstadoNC = me.detalles[0].estadoNC;
				me.hora = me.detalles[0].hora;
				me.noFacturaElectronica = me.detalles[0].claveComprobante.substring(35, 41);
				me.ClaveComprobante = me.detalles[0].claveComprobante;
				me.CedulaReceptor = me.detalles[0].cedulaReceptor;
				me.telefonoReceptor = me.detalles[0].telefonoReceptor;
				me.tipoCedulaReceptor = me.detalles[0].tipoCedulaReceptor;
				me.comentario = me.detalles[0].detalle;
			}).catch(function(error){
				console.log(error);
			});
		},
        selectVendedores(){
            let me=this;
            let header={"Authorization" : "Bearer " };
            let configuracion= {headers : header};
            var proveedoresArray=[];
            axios.get('api/Usuarios/SelectUsuariosLocalidad/'+me.$store.state.usuario.localidad,configuracion).then(function(response){
                proveedoresArray=response.data;
                proveedoresArray.map(function(x){
                    me.Vendedores.push(x.nombre);
                });
            }).catch(function(error){
                console.log(error);
            });
        },
        listarLocalidad(){
            let me =this;
            let LogoTest= '';
            let self = this;
            
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
			axios.get('api/Localidades/ListarFiltrada/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
				//console.log(response);
				me.localidadencabezado=response.data;
				me.Logo2 = me.localidadencabezado[0].imagen;
				me.modoPDV = me.localidadencabezado[0].modoPDV;
				me.ShowNoFact = me.localidadencabezado[0].express; // Si este campo viene true se muestra el NoFactura del la clave numerica, sino se muestra el NoFactura interno
				me.Cuentas = me.localidadencabezado[0].latitud; // este campo contiene los numero de cuentas bancarios
				function toDataURL(url, callback) {
				var xhr = new XMLHttpRequest();
				xhr.onload = function() {
					var reader = new FileReader();
					reader.onloadend = function() {
					callback(reader.result);
					}
					reader.readAsDataURL(xhr.response);
				};
				xhr.open('GET', url);
				xhr.responseType = 'blob';
				xhr.send();
				}
				var url2 = me.Logo2;
				
					toDataURL(url2, function(dataUrl) {
					console.log('RESULT:', dataUrl)
					me.Logo3 = dataUrl;
					}) 
				}).catch(function(error){
					console.log(error);
				});
                
		},
		eliminarProducto(id){
        //console.log(id)
		this.detalles = this.detalles.filter(e => e.idproducKey != id)
		this.iv = 0;
		this.subTotal = 0;
		this.total = 0;
		for(var i = 0; i < this.detalles.length; ++i)
        {	
			if(this.detalles[i].estadoImp == true){
				if(this.detalles[i].gravado == 'S')
				{
					if(this.detalles[i].valorImpuesto == 13)
					{
					this.total +=parseInt(((this.detalles[i].precioUnitario) * this.detalles[i].cantidad) * 1.13);
					this.iv += ((this.detalles[i].precioUnitario  * this.detalles[i].cantidad) * 0.13);
					this.subTotal += parseFloat(this.detalles[i].precioUnitario) * (this.detalles[i].cantidad) 
					} else
					if(this.detalles[i].valorImpuesto == 4)
					{
					this.total+=parseInt(((this.detalles[i].precioUnitario) * this.detalles[i].cantidad) * 1.04);
					this.iv += (((this.detalles[i].precioUnitario) * this.detalles[i].cantidad) * 0.04);
					this.subTotal += parseFloat(this.detalles[i].precioUnitario) * (this.detalles[i].cantidad) 
					} else
					if(this.detalles[i].valorImpuesto == 2)
					{
					this.total+=parseInt(((this.detalles[i].precioUnitario) * this.detalles[i].cantidad) * 1.02);
					this.iv += (((this.detalles[i].precioUnitario) * this.detalles[i].cantidad) * 0.02);
					this.subTotal += parseFloat(this.detalles[i].precioUnitario) * (this.detalles[i].cantidad) 
					} else
					if(this.detalles[i].valorImpuesto == 1)
					{
					this.total+=parseInt(((this.detalles[i].precioUnitario) * this.detalles[i].cantidad) * 1.01);
					this.iv += (((this.detalles[i].precioUnitario) * this.detalles[i].cantidad) * 0.01);
					this.subTotal += parseFloat(this.detalles[i].precioUnitario) * (this.detalles[i].cantidad) 
					} 
				}   
			}
			if(this.detalles[i].estadoImp == false)
			this.total += parseFloat(this.detalles[i].precioUnitario) * (this.detalles[i].cantidad) 
				if(this.detalles[i].valorImpuesto == 13)
					{
					this.iv += parseInt(((this.detalles[i].precioUnitario / 1.13) * this.detalles[i].cantidad) * 0.13);
					this.subTotal += parseInt(((this.detalles[i].precioUnitario / 1.13) * this.detalles[i].cantidad) );
					} else
					if(this.detalles[i].valorImpuesto == 4)
					{
					this.iv += (((this.detalles[i].precioUnitario / 1.04) * this.detalles[i].cantidad) * 0.04);
					this.subTotal += parseInt(((this.detalles[i].precioUnitario / 1.04) * this.detalles[i].cantidad) );
					} else
					if(this.detalles[i].valorImpuesto == 2)
					{
					this.iv += (((this.detalles[i].precioUnitario / 1.02) * this.detalles[i].cantidad) * 0.02);
					this.subTotal += parseInt(((this.detalles[i].precioUnitario / 1.02) * this.detalles[i].cantidad) );
					} else
					if(this.detalles[i].valorImpuesto == 1)
					{
					this.iv += (((this.detalles[i].pprecioUnitariorecio / 1.01) * this.detalles[i].cantidad) * 0.01);
					this.subTotal += parseInt(((this.detalles[i].precioUnitario / 1.01) * this.detalles[i].cantidad) );
					} 
		}
        //console.log(this.cards)
	  	},
		AplicarDescuento(){
			for(var i = 0; i < this.detalles.length; ++i)
			{	
				this.detalles[i].precioUnitario = (((this.Descuento)/100)*(this.detalles[i].precioUnitario)).toFixed(2);
			}
			
			this.Recalcular();
		},
		Recalcular(){
			//console.log(id)
			this.iv = 0;
			this.subTotal = 0;
			this.total = 0;
			for(var i = 0; i < this.detalles.length; ++i)
			{		this.total += parseInt(this.detalles[i].precioUnitario) * (this.detalles[i].cantidad) 
				if(this.detalles[i].gravado == 'S')
				{
					if(this.detalles[i].valorImpuesto == 13)
					{
					this.iv += parseInt(((this.detalles[i].precioUnitario / 1.13) * this.detalles[i].cantidad) * 0.13);
					this.subTotal += parseInt(((this.detalles[i].precioUnitario / 1.13) * this.detalles[i].cantidad) );
					} else
					if(this.detalles[i].valorImpuesto == 4)
					{
					this.iv += (((this.detalles[i].precioUnitario / 1.04) * this.detalles[i].cantidad) * 0.04);
					this.subTotal += parseInt(((this.detalles[i].precioUnitario / 1.04) * this.detalles[i].cantidad) );
					} else
					if(this.detalles[i].valorImpuesto == 2)
					{
					this.iv += (((this.detalles[i].precioUnitario / 1.02) * this.detalles[i].cantidad) * 0.02);
					this.subTotal += parseInt(((this.detalles[i].precioUnitario / 1.02) * this.detalles[i].cantidad) );
					} else
					if(this.detalles[i].valorImpuesto == 1)
					{
					this.iv += (((this.detalles[i].pprecioUnitariorecio / 1.01) * this.detalles[i].cantidad) * 0.01);
					this.subTotal += parseInt(((this.detalles[i].precioUnitario / 1.01) * this.detalles[i].cantidad) );
					} 
				}   
			} 
	  	},
	  	RecalcularNota(){
			var resultado=0.0;
			var preresult = 0.0;
			for(var i=0;i<this.detalles.length;i++){
					if(this.detalles[i].Ivi == false){  //Si el campo estadoInventario es = a false entonces el IVA se suma
						resultado= (parseFloat(this.totalGravado) + parseFloat(this.calcularImpuesto)+parseFloat(this.totalExento)) - parseFloat(this.totalDescuento);
				} if(this.detalles[i].Ivi == true){
					if(this.monedaPago == 'USD'){
						preresult= (parseFloat(this.totalGravado) + parseFloat(this.calcularImpuesto)+parseFloat(this.totalExento)) - parseFloat(this.totalDescuento);
						resultado = parseFloat(preresult)
						return resultado.toFixed(2);
					}
					preresult= (parseFloat(this.totalGravado) + parseFloat(this.calcularImpuesto)+parseFloat(this.totalExento)) - parseFloat(this.totalDescuento);
					resultado = parseInt(preresult)
				}
			}
			
			
			return resultado.toFixed(2);
		},
		calcularImpuesto:function(){
			var resultado=0.0;
				resultado=this.imp1 + this.imp2+ this.imp4 + this.imp13;
			return resultado.toFixed(2);
		},
        ReenvioFactura(){
            if(this.correo2 == ''){
                this.snackbar = true;
                this.Mensaje = 'Digite el correo al que desea reenviar la factura'
                return;
            }
            let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
				let me=this;
				axios.put('api/CorreosFacturas/Reenviar',{
					'NoFactura':me.noFactura,
					'Estado': 0,
					'Correo2': me.correo2,
					'ClaveNumerica':me.ClaveComprobante,
				},configuracion).then(function(response){
					me.snackbar = true;
					me.Mensaje = 'Correo reenviado con éxito a '+me.correo2;                     
				}).catch(function(error){
					console.log(error);
					me.snackbar = true;
					me.Mensaje = 'Reenvio de factura a '+me.correo2+'. Disponible proximamente';   
				});
        },
        MostrarComprobante(item){
            this.nombreReceptor= item.nombreReceptor;
            this.cedulaReceptor = item.cedulaReceptor;
            this.vendedor = item.vendedor;
            this.tipoDocumento = item.tipoDocumento;
            this.correoReceptor = item.correoReceptor;
            this.noFactura = item.noFactura;
            this.tipoCedulaReceptor = item.tipoCedulaReceptor;
            this.fecha = item.fecha;
            this.subTotal = item.gravado;
            this.total = item.total;
            this.monedaPago = item.moneda;
            this.nofactura2 = "/ticket/"+this.noFactura;
            this.value = location.hostname+'/perfilcliente/'+this.$store.state.usuario.localidad+'/'+this.cedulaReceptor;
            this.grabado = item.gravado;
            this.exento = item.exento;
            this.iv = item.impuesto;
            this.listarDetalles(item.noFactura);
            this.comprobanteModal = true;
        },
        MostrarComprobante2(value){
            this.MostrarComprobante(value);
        },
        OcultarComprobante(){
            this.comprobanteModal = 0;
        },
        crearPDF(){
           var columns = [
            {title: "Factura", dataKey: "noFacturaReal"},
            {title: "Estado", dataKey: "estado"}, 
            {title: "Fecha", dataKey: "fecha"}, 
            {title: "Excento", dataKey: "excento"},
            {title: "IVA", dataKey: "iva"},
            {title: "Total", dataKey: "ventaTotal"},
            {title: "Cliente", dataKey: "cliente"},
            ];
            var rows = [];

            this.usuarios.map(function(x){
                rows.push({noFacturaReal:x.noFacturaReal,excento:x.exento,estado:x.mensajeRespuesta,fecha:x.fecha,iva:x.impuesto,ventaTotal:x.ventaTotal,cliente:x.nombreReceptor});
            });

            // Only pt supported (not mm or in)
            var doc = new jsPDF('p', 'pt');
            doc.autoTable(columns, rows, {
                margin: {top: 60},
                addPageContent: function(data) {
                    doc.text("Listado de Ventas", 40, 30);
                }
            });
            doc.save('ReporteVentas.pdf');
        },
        crearPDFFactura(){
            var fact = this.noFactura;
             var quotes = document.getElementById('factura');
                html2canvas(quotes).then(function (canvas) {
                    var imgData = canvas.toDataURL('https://i.ibb.co/7rj9fLf/the-one-blanco.jpg');
                    var imgWidth = 210;
                    var pageHeight = 285;
                    var imgHeight = canvas.height * imgWidth / canvas.width;
                    var heightLeft = imgHeight;
                    var doc = new jsPDF();
                    var position = 0;

                    doc.addImage(imgData, 'JPG', 0, position, imgWidth, imgHeight);                    
                    doc.save('ComprobanteVenta'+fact+'.pdf');
                });
        },
        async listarFechas(){
			if(this.start === '' || this.end === ''){
				this.snackbar = true;
				this.Mensaje = 'Debe seleccionar una fecha inicial y final';
				return;
			}
			this.loading = true;
			let me = this;
			let header = {"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion = {headers : header};
			await axios.get(`api/RepVentasVistas/ConsultaFechas/${this.start}/${this.end}/${this.Localidad}`, configuracion)
			.then(function(response){
				me.usuarios = response.data;
				me.loading = false;
				//me.listarResumen();
			}).catch(function(error){
				console.log(error);
			});
		},
		resumenPorCajero() {
			return this.cajerosUnicos.map(cajero => {
				const filtrados = this.usuarios.filter(item => item.cajero === cajero);
				const resumen = filtrados.reduce((acc, curr) => {
					const factor = curr.tipoDocumento === "NOTA DE CREDITO" ? -1 : 1;
					acc.TotalFacturado += curr.total * factor;
					acc.ImpServicioTotal += curr.impServicio * factor;
					acc.TotalEfectivo += curr.totalEfectivo * factor;
					acc.TotalTarjeta += curr.totalTarjeta * factor;
					acc.TotalNC += curr.totalNC * factor;
					acc.TotalDeposito += curr.totalDeposito * factor;
					acc.TotalSinpe += curr.totalSimpe * factor;
					acc.TotalDolares += curr.totalDolares * factor; // Nueva variable
					acc.credito += curr.credito * factor;
					return acc;
				}, {
					Cajero: cajero,
					TotalFacturado: 0,
					ImpServicioTotal: 0,
					TotalEfectivo: 0,
					TotalTarjeta: 0,
					TotalDeposito: 0,
					TotalSinpe: 0,
					TotalNC: 0,
					TotalDolares: 0, // Inicializar la nueva variable
					credito: 0
				});
				console.log("Resumen por cajero:", resumen); // Verificar que el resumen contiene totalDolares
				return resumen;
			});
		},


		resumenPorVendedor() {
			return this.vendedoresUnicos.map(vendedor => {
				const filtrados = this.usuarios.filter(item => item.vendedor === vendedor);
				const resumen = filtrados.reduce((acc, curr) => {
					const factor = curr.tipoDocumento === "NOTA DE CREDITO" ? -1 : 1;
					acc.TotalFacturado += curr.total * factor;
					acc.ImpServicioTotal += curr.impServicio * factor;
					acc.TotalEfectivo += curr.totalEfectivo * factor;
					acc.TotalTarjeta += curr.totalTarjeta * factor;
					acc.TotalDeposito += curr.totalDeposito * factor;
					acc.TotalSinpe += curr.totalSimpe * factor;
					acc.TotalNC += curr.totalNC * factor;
					acc.TotalDolares += curr.totalDolares * factor;
					acc.credito += curr.credito * factor;
					return acc;
				}, {
					Cajero: vendedor,  // Aquí podrías querer cambiar 'Cajero' por 'Vendedor' para mantener la consistencia
					TotalFacturado: 0,
					ImpServicioTotal: 0,
					TotalEfectivo: 0,
					TotalTarjeta: 0,
					TotalDolares: 0,
					TotalDeposito: 0,
					TotalSinpe: 0,
					TotalNC: 0,
				});
				return resumen;
			});
		},

		listarResumen(){
			if(this.start === '' || this.end === ''){
				this.snackbar = true;
				this.Mensaje = 'Debe seleccionar una fecha inicial y final';
				return;
			}
			this.loading = true;
			let me = this;
			let header = {"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion = {headers : header};
			axios.get(`api/RepVentasVistas/ConsultaFechasResumido/${this.start}/${this.end}/${this.$store.state.usuario.localidad}`, configuracion)
			.then(function(response){
				me.resumen = response.data;
				me.loading = false;
			}).catch(function(error){
				console.log(error);
			});
		},
		close () {
			this.dialog = false;
			this.limpiar();
		},
      	activarDesactivarMostrar(accion,item){
			this.adModal=1;
			this.adNombre=item.nombre;
			this.adId=item.idusuario;                
			if (accion==1){
				this.adAccion=1;
			}
			else if (accion==2){
				this.adAccion=2;
			}
			else{
				this.adModal=0;
			}
		},
		activarDesactivarCerrar(){
			this.adModal=0;
		},
      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.desserts[this.editedIndex], this.editedItem)
        } else {
          this.desserts.push(this.editedItem)
        }
        this.close()
      },
    },
  }

</script>
<style scoped>
    @media print {
    .oculto-impresion,
    .oculto-impresion * {
        display: none !important;
    }
    } 
</style>
My CRUD