<template>
    <div>
        
        <v-toolbar  dark color="secondary" class="font-weight-light body-1 white--text">
            <h4>Panel de Control</h4>
        </v-toolbar>
        <v-row>
            <v-col cols="12" sm="12" md="4">
                <v-card
                        max-width="100%"
                        class="mx-auto"
                    >
                        <v-list-item>
                        <v-list-item-avatar color="grey darken-3">
                        <v-img
                            class="elevation-6"
                            src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                        ></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title class="">{{razonSocial}}</v-list-item-title>
                            <v-list-item-subtitle> {{nombre}} <br> </v-list-item-subtitle>
                        </v-list-item-content>
                        </v-list-item>
							<div>
								<strong style="font-size:18px;">Cargar Imagen </strong>
								<input type="file" @change="onChangeFileUpload" accept="image/*" >
							</div>

                        <v-img
                        :src="Logo2"
                        height="344"
                        ></v-img>

                        <v-card-text>
                        <v-icon> mdi-email </v-icon>   {{correo}} <br>
                        <v-icon> mdi-phone </v-icon> {{telefono}} <br>
                        <v-icon> mdi-user </v-icon> {{cedula}} <br>
                        Cantidad de compras: {{usuarios.length}} <br>
                        Total de compras ₡:{{total}} <br>
                        Créditos Fiscales ₡:{{TotalIva}}

                        </v-card-text>

                        <v-card-actions>
                        <v-btn
                            text
                            color="info"
                        >
                            Más
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn icon>
                            <v-icon>mdi-heart</v-icon>
                        </v-btn>
                        <v-btn icon>
                            <v-icon>mdi-share-variant</v-icon>
                        </v-btn>
                        </v-card-actions>
                    </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="8">    
                <template>
					<v-card>
						<v-toolbar
						flat
						color="primary"
						dark
						>
						<v-toolbar-title>User Profile</v-toolbar-title>
						</v-toolbar>
						<v-tabs vertical>
						<v-tab>
							<v-icon left>
							mdi-account
							</v-icon>
							Información General
						</v-tab>
						<v-tab>
							<v-icon left>
							mdi-tools
							</v-icon>
							Configuraciones Generales
						</v-tab>
						<v-tab>
							<v-icon left>
							mdi-tools
							</v-icon>
							POS Settings
						</v-tab>
						<v-tab @click="listarAsociadas()">
							<v-icon left>
							mdi-database-settings
							</v-icon>
							Bodegas
						</v-tab>
						<v-tab>
							<v-icon left>
							mdi-printer
							</v-icon>
							Impresora de Red
						</v-tab>
						<v-tab>
							<v-icon left>
							mdi-printer
							</v-icon>
							Localidades Asociadas
						</v-tab>

						<v-tab-item>
							<v-card flat>
							<v-card-text>
								<p>
								Actualiza la información de tu negocio y mantente al día 
								</p>
								<v-text-field disabled label="Nombre Comercial" v-model="nombre"> </v-text-field>
								<v-text-field label="Razón Social" v-model="razonSocial"> </v-text-field>
								<v-text-field label="Cédula del negocio" v-model="cedula"> </v-text-field>
								<v-text-field label="Correo electrónico" v-model="correo"> </v-text-field>
								<v-text-field label="Teléfono del negocio" v-model="telefono"> </v-text-field>
								<v-select :items="tipoDocumentos" label="Tipo de cédula" v-model="tipoCedula"> </v-select>
								<v-text-field label="Dirección del negocio" v-model="dirreccion"> </v-text-field>
								<v-btn color="primary" @click="ActualizarLocalidad()" > Actualizar </v-btn>
							</v-card-text>
							</v-card>
						</v-tab-item>
						<v-tab-item>
							<v-card flat>
							<v-card-text>
								<p>
								Esta función esta pensada para los negocios con puntos de venta y alto flujo de efectivo. Dentro de los principales funciones cuando esta activa es llevarte al tiquet despúes de facturar para imprimmirlo. 
								</p>
								<v-select :label="`Acción a realizar al finalizar un documento`" v-model="modoPDV" :items="Modos"> </v-select>
								<p>
								Esta otra función si la mantenemos activa nos brinda el reporte de ventas por Número de factura electrónica, si lo mantenemos apagados el reporte de ventas será ordenaddo por el número de factura interno. Ideal para aquellos negocios que no necesitan hacer recibos.
								</p>
								<v-switch
									v-model="ShowNoFact"
									:label="`Modo Reporte Continuo`"
								></v-switch>
								<p>
								Administra las cuentas bancarias mostradas en los PDFs de facturas, proformas, recibos y tiquetes electrónicos 
								</p>
								<v-textarea v-model="Cuentas"  auto-grow
								outlined
								rows="2"
								dense
								row-height="30"
								shaped label="Cuentas bancarias"></v-textarea>
								<p>
								En este campo podemos mantener actualizada la resolución impuesta por el Ministerio de Hacienda para que nuestros tiquetes y facturas electrónicas completen los requisitos solicitados por la misma entidad</p>
								<v-textarea v-model="resolucion"  auto-grow
								outlined
								rows="2"
								dense
								row-height="30"
								shaped label="Resolución"></v-textarea>
								
								<v-btn color="primary" @click="ActualizarLocalidad()" > Actualizar </v-btn>
							</v-card-text>
							</v-card>
						</v-tab-item>
						<v-tab-item>
							<v-card flat>
							<v-row>
								<v-switch
									style="width:50%" color="green"
									v-model="ModoCant" persistent-hint hint="La cantidad y el nombre del producto es editable desde el POS, Desde facturar el scaneo de productos se mantiene en la 
									casilla escanear y si se escanea el mismo producto suma la cantidad en vez de agrgar otra linea"
									:label="`Modo Cantidad`" @change="UpdatePanelControl()"
								></v-switch>
								<v-switch color="green"
									style="width:50%; color: grey; font-size: 12px; border-left: 5px solid #ccc; padding-left: 5px;"
									v-model="AddImpServ" persistent-hint hint="Se suma el impuesto de servicio al monto total en el POS"
									:label="`Añadir Imp Servicio`" @change="UpdatePanelControl()"
								></v-switch>
								<v-switch
									style="width:50%" color="green"
									v-model="AgregarCompuesto" persistent-hint hint="Añade el Producto Compuesto como una linea independiente"
									:label="`Agregar Compuesto`" @change="UpdatePanelControl()"
								></v-switch>
								<v-switch color="green"
									style="width:50%; color: grey; font-size: 12px; border-left: 5px solid #ccc; padding-left: 5px;"
									v-model="VendDocRequisito" persistent-hint hint="Siempre debe seleccionar vendedor y Tipo Documento para facturar"
									:label="`Vendedor y Docs Obligatorios`" @change="UpdatePanelControl()"
								></v-switch>
								<v-switch
									style="width:50%" color="green"
									v-model="ActivarRecibos" persistent-hint hint="Todas las ventas en efectivo y sin receptor quedan como recibos"
									:label="`Recibos Automaticos`" @change="UpdatePanelControl()"
								></v-switch>
								<v-switch v-if="esAdministrador"
									style="width:50%" color="green"
									v-model="Multilocalidad" persistent-hint hint="Busca clientes y bodegas que tenga el usuario asignadas en localidades asociadas"
									:label="`Multilocalidad`" @change="UpdatePanelControl()"
								></v-switch>
								<v-switch
									style="width:50%" color="green"
									v-model="Stock0" persistent-hint hint="No permite facturar si el stock es igual o menor a 0, ademàs muestra el inventario"
									:label="`Stock 0`" @change="UpdatePanelControl()"
								></v-switch>
								<v-switch
									style="width:50%" color="green"
									v-model="Foto" persistent-hint hint="Muestra la botonera con fotografías de productos si esta activado"
									:label="`Fotos En Productos`" @change="UpdatePanelControl()"
								></v-switch>
								<v-switch
									style="width:50%" color="green"
									v-model="Comanda" persistent-hint hint="Imprime una comanda despues de facturar con los productos o servicios facturados"
									:label="`Comandas`" @change="UpdatePanelControl()"
								></v-switch>
								<v-switch
									style="width:50%" color="green"
									v-model="ComandaDigital" persistent-hint hint="Agregar una comanda digital"
									:label="`Comandas Digitales`" @change="UpdatePanelControl()"
								></v-switch>
								<v-switch
									style="width:50%" color="green"
									v-model="PuntosCredito" persistent-hint hint="Toma en cuenta las ventas en crédito para acumulación de puntos"
									:label="`Sumar Puntos Credito`" @change="UpdatePanelControl()"
								></v-switch>
								<v-switch
									style="width:50%" color="green"
									v-model="Receta" persistent-hint hint="Descuenta los insumos de inventario cuando se factura un producto con receta"
									:label="`Recetas`" @change="UpdatePanelControl()"
								></v-switch>
								<v-switch
									style="width:50%" color="green"
									v-model="CierreGlobal" persistent-hint hint="Esta funcion crea un cierre de caja con todas las ventas de todos los usuarios desde el último cierre generado sin filtro de usuarios"
									:label="`Cierre de caja Global`" @change="UpdatePanelControl()"
								></v-switch>
								<v-switch
									style="width:50%" color="green"
									v-model="Apertura" persistent-hint hint="No se puede iniciar facturación sin realizar la apertura de caja"
									:label="`Apertura Obligatoria`" @change="UpdatePanelControl()"
								></v-switch>
								<v-select @change="UpdatePanelControl()" filled style="width:50%" :items="monedas" label="Moneda de Cobro por defecto" v-model="monedaPago"/>  
								<v-text-field filled persistent-hint style="width:50%" :hint="'Tipo Cambio $:  Compra:'+Compra+' | + Venta: $'+Venta" label="Tipo cambio interno" v-model="TipoCambioInterno"/> 
								<v-text-field @keyup.enter="UpdatePanelControl()" filled persistent-hint style="width:50%"  v-model="Multiplo" label="Multio de redondeo"/>  
								  
								<div style="width:50%" class="text-caption">Fuente Tipo Cambio: Banco Central de Costa Rica  </div>
								<v-btn style="width:50%" color="primary" @click="UpdateTipoCambio()" > Actualizar Tipo de cambio</v-btn>
							</v-row>
							</v-card>
						</v-tab-item>
						<v-tab-item>
							<v-card flat>
							<v-row>
								<p>Aqui puedes ingresar bodegas o localidades que este usuario {{$store.state.usuario.nombre}} puede visualizar para facturación, clientes, dashboard entre otras opciones <br>
								Aqui debes ingresar la localidad o bodega que va estar disponible para este usuario <br>
								NOTA: Si necesita facturación con una bodega o localidad adicinoal contacte a soporte para la asignación de parametros de emisión de documentos</p>

								<v-text-field persistent-hint hint="Puede escribir también el nombre de otro usuario" filled style="width:50%" label="Usuario" v-model="usuario" />
								<v-text-field persistent-hint hint="Aqui debe digitar la localidad o bodega que estará disponible para el usuario digitado" filled style="width:50%" label="Localidad Asociada" v-model="localidad" />
								<v-simple-table style="width:100%"> 
									<template v-slot:default>
									<thead>
										<tr>
										<th class="text-left">
											Localidad Asociada
										</th>
										<th class="text-left">
											Usuario
										</th>
										<th class="text-left">
											Fecha Registro
										</th>
										<th class="text-left">
											Localidad Original
										</th>
										<th class="text-left">
											Eliminar
										</th>
										</tr>
									</thead>
									<tbody>
										<tr
										v-for="item in localidadesAsociadas"
										:key="item.name"
										>
										<td>{{ item.localdiadAsociada}}</td>
										<td>{{ item.usuario }}</td>
										<td>{{ item.fecha }}</td>
										<td>{{ item.localidad }}</td>
										<td><v-icon @click="EliminarRegistro(item)" color="red"> mdi-delete </v-icon> </td>
										</tr>
									</tbody>
									</template>
								</v-simple-table>
								<v-card-actions>
									<v-btn @click="listarAsociadas()"> Buscar Registros </v-btn>
									<v-spacer/>
									<v-btn @click="AgregarRegistro()" >  Agregar Registros </v-btn>
								</v-card-actions>
								
							</v-row>

							</v-card>
						</v-tab-item>
						<v-tab-item>
							<v-card flat>
							<v-card-text>
								<h2>
								Ingresa los datos de la impresora Principal
								</h2>
								<v-text-field label="Dirección IP 1" v-model="ip1"> </v-text-field>
								<v-text-field label="Devid 1" v-model="devid1"> </v-text-field>
								<p>
								<v-btn v-if="!existeprinter1" @click="SavePrinter(Caja)"> Guardar Impresora Caja </v-btn>
								<v-btn v-if="existeprinter1" @click="UpdatePrinter(Caja)"> Actualizar Impresora Caja </v-btn>
									
								<v-btn v-if="existeprinter1" @click="ImprimirPrueba()"> Test Printer </v-btn>
								<v-btn v-if="existeprinter1" @click="ImprimirPrueba2()"> Test SSL </v-btn>
								<h2>
								Impresora de Cocina
								
								</h2>
								<v-text-field label="Dirección IP 2" v-model="ip2"> </v-text-field>
								<v-text-field label="Devid 2" v-model="devid2"> </v-text-field>
								<v-btn v-if="!existeprinter2" @click="SavePrinterCocina(Cocina)"> Guardar Impresora Cocina </v-btn>
								<v-btn v-if="existeprinter2" @click="UpdatePrinterCocina(Cocina)"> Actualizar Impresora Cocina </v-btn>
								<v-btn v-if="existeprinter2" @click="ImprimirPruebaCocina()"> Test Cooking Printer </v-btn>
								<h2>
								Impresora de Bar
								</h2>
								<v-text-field label="Dirección IP Bar" v-model="ip3"> </v-text-field>
								<v-text-field label="Devid Bar" v-model="devid3"> </v-text-field>
								<p>
								<v-btn v-if="!existeprinter3" @click="SavePrinterBar(Bar)"> Guardar Impresora Bar </v-btn>
								<v-btn v-if="existeprinter3" @click="UpdatePrinterBar(Bar)"> Actualizar Impresora Bar </v-btn>
								<v-btn v-if="existeprinter3" @click="ImprimirPruebaBar()"> Test Bar Printer </v-btn>
									
								
								Noah Systems use, "ePOS-Print supported printer" is a generic term for the TM-DT series/TM-i series and TM printers
								that support the ePOS-Print XML.
								The TM-DT series in this manual is a generic term for the following printers.
								• TM-H6000IV-DT
								• TM-T70II-DT
								• TM-T88V-DT
								The TM-i series in this manual is a generic term for the following printers.
								• TM-L90-i
								• TM-T20II-i
								• TM-T70-i
								• TM-T82II-i
								• TM-T83II-i
								• TM-T88V-i
								• TM-U220-i
								</p>
							</v-card-text>
							</v-card>
						</v-tab-item>
						</v-tabs>
						<v-tab-item>
							<v-card flat>
							<v-card-text>
								<p>Aqui puedes ingresar bodegas o localidades que el usuario <b> {{$store.state.usuario.nombre}} </b> puede visualizar para facturación, clientes, dashboard entre otras opciones</p>

								
							</v-card-text>
							</v-card>
						</v-tab-item>
					</v-card>
				</template>
				 <v-dialog
					v-model="modelCarga"
						width="300"
						dark
					>
						<v-card
						color="white"
						
						>
						<v-card-text class="black--text">
							Por Favor, Espere..
							<v-progress-linear
							indeterminate
							color="black"
							class="mb-0"
							></v-progress-linear>
						</v-card-text>
						<v-img
							src="https://i.pinimg.com/originals/e2/6d/87/e26d873ee68fbcd9dc1c40d8b0c6b3b4.gif"
							class="my-3"
							contain
							height="200"
						></v-img>
						</v-card>
					</v-dialog>
                <v-snackbar
                        v-model="snackbar" top vertical="" :timeout="4000"
                        >
                        {{ Mensaje }}
                        <v-btn
                        color="pink"
                        text
                        @click="snackbar = false"
                        >
                        Cerrar
                        </v-btn>
                </v-snackbar>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import axios from 'axios'
import QrcodeVue from 'qrcode.vue'
// import firebase from "firebase";
  export default {
    data: () => ({
      
    dialog: false,
    search: '',
    value: 'https://www.noah.cr',
    size: 100,
	Caja: "Caja",
	Cocina: "Cocina",
	Modos: [{text: 'Recargar el mismo sitio', value: 0}, {text: 'Ir al tiquet PDV', value: 1},
	 {text:'Ir al Reporte de Ventas',value: 2},{text:'Imprimir en Impresora de Red',value: 3}
	 ,{text:'Imprimir en Hoja 4A (Carta)',value: 4},{text:'Tiquet PDV en la misma pantalla',value: 5},
	 {text:'Usar App Python Noah',value: 6}],
    
    tipoCedulaReceptor: '',
    search: '',
	Mensaje: '',
	snackbar: false,
	Compra: 0,
	Multiplo: 5,
    Venta: 0,
    existeprinter1 : false,
	existeprinter2: false,
	existeprinter3: false,
	ip1: "",
	devid2: "",
	ip2: "",
	devid1: "",
	ip3: "",
	devid3: "",
    editedIndex: -1,
    id: '',
    idrol:'',
    noFactura: 0,
    comprobanteModal: 0,
    MensajeRespuesta: '',
	tipoDocumentos: ["JURIDICA","FISICA"],
    ClaveComprobante: '',
    EstadoNC: 0,
    ModalNotaCredito: false,
    total: 0,
    justificacion: '',
    medioDePago : '',
	detalles: [],
	resolucion: 'Autorizado mediante Resolucion DGT-R-033-2019 DEL 6-20-2019',
    localidadencabezado:[],
    NombreLocalidad:'',
    TotalIva: 0.0,
    CedulaLocalidad:'',
    ClaveNumerica: '',
    DireccionLocalidad:'',
    TelefonoLocalidad:'',
    fecha: '',
    hora: '',
    cedula: '',
    nombre:'',
	TipoCambioInterno: 0,
    dirreccion:'',
	monedaPago: "CRC",
	Imagen: '',
	ModoCant: false,
	uploadValue1:0,
	AddImpServ : false,
	AgregarCompuesto: false,
	VendDocRequisito:false,
	ActivarRecibos:false,
	Multilocalidad:false,
	Fotografias:false,
	PuntosCredito:false,
	Comanda:false,
	Foto:false,
	ComandaDigital:false,
    telefono:'',
	correo:'',
	razonSocial : '',
    vendedor: '',
    subtotal: 0,
    comentario: '',
	grabado:0 ,
	selectedFile: null,
	formula: '',
	longitud: '',
	porcentaje: '',
	provincia: '',
	canton: '',
	modelCarga: true,
	tipoCedula: 'JURIDICA',
    correo2: '',
    excento: 0,
    iv: 0.0,
	info: [],
    tipo_documento: '',
    usuarios:[],    
    desserts: [],
	editedIndex: -1,
	localidadencabezado:[],
	localidadesAsociadas:[],
	localidad: '',
	usuario:'',
	modoPDV: 0,
	CierreGlobal:false,
	Logo2: '',
	ShowNoFact: false,
	Cuentas: '',
	descuento: 0,
	Logo3: '',
	monedas: [
		'CRC',
		'USD',
	],
		
    }),
    components: {
      QrcodeVue,
    },

    computed: {
      formTitle () {
                return this.editedIndex === -1 ? 'Nuevo usuario' : 'Actualizar usuario'
        },
		esAdministrador(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='Administrador';
		},


    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      
    },

    created () {
      this.listarLocalidad1();
	  this.selectTipoCambioInterno();
	  this.TipoCambio2();
	  this.ListarImpresoras();
	  this.selectPanelControl();
	  this.selectTipoCambio()

    },
    
    computed:{
    },

    methods: {
        calcularTotal(){
          for(var i=0;i<this.usuarios.length;i++){
            this.total=this.total+(this.usuarios[i].ventaTotal * 1);
          }
        
      },
      calculariva(){
          for(var i=0;i<this.usuarios.length;i++){
            this.TotalIva=this.TotalIva+(this.usuarios[i].impuesto * 1);
          }
        
	  },
	  selectTipoCambio(){
      this.Venta = this.info[0].data.compra;

    },
	selectTipoCambioInterno(){  
		let me=this;
		//this.Venta = this.info.data.venta;
 		//this.Compra = this.info.data.compra;
		var localidadesArray=[];
		let header={"Authorization" : "Bearer "};
		let configuracion= {headers : header};
		axios.get('api/ParametrosEmisors/ListarLocalidad/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
			localidadesArray=response.data;
			me.TipoCambioInterno= localidadesArray[0].tipoCambio;
		})
    },
	selectPanelControl(){  
		let me=this;
		var localidadesArray=[];
		let header={"Authorization" : "Bearer "};
		let configuracion= {headers : header};
		axios.get('api/PanelControls/ListarLocalidad/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
			localidadesArray=response.data;
			me.ModoCant= localidadesArray[0].modoCantidades;
			me.AddImpServ= localidadesArray[0].impServ;
			me.AgregarCompuesto= localidadesArray[0].agregarCompuesto;
			me.monedaPago= localidadesArray[0].moneda;
			me.Stock0= localidadesArray[0].otro; // ESTE CAMPO SE UTILIZA PARA VALIDAR EL STOCK CUANDO SE CONSULTA UN PRODUCTO
			me.VendDocRequisito= localidadesArray[0].mostrarTeclado; //Este campo se utiliza para validar si debe solicitar vendedor y TipoDocumento Siempre al facturar
			me.Multilocalidad =  localidadesArray[0].multiLocalidad;
			me.ActivarRecibos =  localidadesArray[0].recibos;
			me.Multiplo =  localidadesArray[0].multiplo; // especifica el multiplo de redondeo del precio
			me.Foto =  localidadesArray[0].foto;
			me.Comanda =  localidadesArray[0].comanda;
			me.ComandaDigital =  localidadesArray[0].comandaDigital;
			me.PuntosCredito =  localidadesArray[0].puntosCredito;
			me.Receta =  localidadesArray[0].receta;
			me.Apertura = localidadesArray[0].apertura;
			me.CierreGlobal = localidadesArray[0].cierreGlobal;
			//me.tipoCedula=localidadesArray[0].tpcedula;
		})
    },
	onChangeFileUpload(event){
      this.selectedFile = event.target.files[0];
      this.onUpload();
    },
	/* onUpload(){
      const storageRef=firebase.storage().ref(`${this.selectedFile.name}`);
      const task = storageRef.put(this.selectedFile);
      task.on('state_changed',snapshot=>{
        let percentage = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
        this.uploadValue = percentage;
      }, error=>{console.log(error.message)},
      ()=>{this.uploadValue=100;
          task.snapshot.ref.getDownloadURL().then((url)=>{
          this.Logo2 =url;
        });
      }
      );
    }, */
	TipoCambio2(){
          this.modelproveedor = true;
          let me=this;
          var familiasArray=[];
          axios.get('http://apis.gometa.org/tdc/tdc.json').then(function(response){
              familiasArray=response.data;
              me.Compra = familiasArray.compra;
              me.Venta = familiasArray.venta;
              
              
          }).catch(function(error){
            
              console.log(error);
          });
	},
	ImprimirPrueba(){
		var momentoActual = new Date(); 
		var hora = momentoActual.getHours(); 
		var minuto = momentoActual.getMinutes(); 
		var segundo = momentoActual.getSeconds();
		var time = hora + ":" + minuto + ":" + segundo;
			if(this.ip1 == "" && this.devid1 == ""){
				alert("No hay impresora designada");
				return;	
			}
            var request = `<epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">
						<text align="center"/>
						<text font="font_c"/>
						<text width="3" height="3"/>
						<text reverse="false" ul="false" em="false" color="color_1"/>
						<text>Prueba Caja Print&#10;</text>
						<text>-------------------------------------&#10;</text>
						<feed line="3"/>
						<cut type="feed"/>
						</epos-print>`;
                
                //Create a SOAP envelop
                var soap = '<s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">' +
                '<s:Body>' + request + '</s:Body></s:Envelope>';
                //Create an XMLHttpRequest object
                var xhr = new XMLHttpRequest();
                //Set the end point address
                var url = 'http://'+this.ip1+'/cgi-bin/epos/service.cgi?devid='+this.devid1+'&timeout=60000';
                //Open an XMLHttpRequest object
                xhr.open('POST', url, true);
                //<Header settings>
                xhr.setRequestHeader('Content-Type', 'text/xml; charset=utf-8');
                xhr.setRequestHeader('If-Modified-Since', 'Thu, 01 Jan 1970 00:00:00 GMT');
                // Send print document
                xhr.send(soap);
	},
	ImprimirPrueba2(){
		var momentoActual = new Date(); 
		var hora = momentoActual.getHours(); 
		var minuto = momentoActual.getMinutes(); 
		var segundo = momentoActual.getSeconds();
		var time = hora + ":" + minuto + ":" + segundo;
			if(this.ip1 == "" && this.devid1 == ""){
				alert("No hay impresora designada");
				return;	
			}
            var request = `<epos-print xmlns="https://www.epson-pos.com/schemas/2011/03/epos-print">
						<text align="center"/>
						<text font="font_c"/>
						<text width="3" height="3"/>
						<text reverse="false" ul="false" em="false" color="color_1"/>
						<text>Prueba Caja Print&#10;</text>
						<text>-------------------------------------&#10;</text>
						<feed line="3"/>
						<cut type="feed"/>
						</epos-print>`;
                
                //Create a SOAP envelop
                var soap = '<s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">' +
                '<s:Body>' + request + '</s:Body></s:Envelope>';
                //Create an XMLHttpRequest object
                var xhr = new XMLHttpRequest();
                //Set the end point address
                var url = 'https://'+this.ip1+'/cgi-bin/epos/service.cgi?devid='+this.devid1+'&timeout=60000';
                //Open an XMLHttpRequest object
                xhr.open('POST', url, true);
                //<Header settings>
                xhr.setRequestHeader('Content-Type', 'text/xml; charset=utf-8');
                xhr.setRequestHeader('If-Modified-Since', 'Thu, 01 Jan 1970 00:00:00 GMT');
                // Send print document
                xhr.send(soap);
	},
	ImprimirPruebaCocina(){
		var momentoActual = new Date(); 
		var hora = momentoActual.getHours(); 
		var minuto = momentoActual.getMinutes(); 
		var segundo = momentoActual.getSeconds();
		var time = hora + ":" + minuto + ":" + segundo;
			if(this.ip2 == "" && this.devid2 == ""){
				alert("No hay impresora designada");
				return;	
			}
            var request = `<epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">
						<text align="center"/>
						<text font="font_c"/>
						<text width="3" height="3"/>
						<text reverse="false" ul="false" em="false" color="color_1"/>
						<text>Prueba Cocina&#10;</text>
						<text>-------------------------------------&#10;</text>
						<feed line="3"/>
						<cut type="feed"/>
						</epos-print>`;
                
                //Create a SOAP envelop
                var soap = '<s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">' +
                '<s:Body>' + request + '</s:Body></s:Envelope>';
                //Create an XMLHttpRequest object
                var xhr = new XMLHttpRequest();
                //Set the end point address
                var url = 'http://'+this.ip2+'/cgi-bin/epos/service.cgi?devid='+this.devid2+'&timeout=60000';
                //Open an XMLHttpRequest object
                xhr.open('POST', url, true);
                //<Header settings>
                xhr.setRequestHeader('Content-Type', 'text/xml; charset=utf-8');
                xhr.setRequestHeader('If-Modified-Since', 'Thu, 01 Jan 1970 00:00:00 GMT');
                // Send print document
                xhr.send(soap);
	},
	ImprimirPruebaBar(){
		var momentoActual = new Date(); 
		var hora = momentoActual.getHours(); 
		var minuto = momentoActual.getMinutes(); 
		var segundo = momentoActual.getSeconds();
		var time = hora + ":" + minuto + ":" + segundo;
		if(this.ip2 == "" && this.devid2 == ""){
			alert("No hay impresora designada");
			return;	
		}
		var request = `<epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">
					<text align="center"/>
					<text font="font_c"/>
					<text width="3" height="3"/>
					<text reverse="false" ul="false" em="false" color="color_1"/>
					<text>Prueba Bar&#10;</text>
					<text>-------------------------------------&#10;</text>
					<feed line="3"/>
					<cut type="feed"/>
					</epos-print>`;
			
			//Create a SOAP envelop
			var soap = '<s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">' +
			'<s:Body>' + request + '</s:Body></s:Envelope>';
			//Create an XMLHttpRequest object
			var xhr = new XMLHttpRequest();
			//Set the end point address
			var url = 'http://'+this.ip3+'/cgi-bin/epos/service.cgi?devid='+this.devid3+'&timeout=60000';
			//Open an XMLHttpRequest object
			xhr.open('POST', url, true);
			xhr.setRequestHeader('Content-Type', 'text/xml; charset=utf-8');
			xhr.setRequestHeader('If-Modified-Since', 'Thu, 01 Jan 1970 00:00:00 GMT');
			// Send print document
			xhr.send(soap);
	},
	listarAsociadas(){
		let me =this;
		this.modelCarga= true;
		let header={"Authorization" : "Bearer " + this.$store.state.token};
		let configuracion= {headers : header};
			axios.get('api/LocalidadesAsociadas/'+this.usuario,configuracion).then(function(response){
				//console.log(response);
				me.localidadesAsociadas=response.data;
				me.modelCarga = false;
				}).catch(function(error){
					console.log(error);
				});
			
	},
	EliminarRegistro(item){
		let me =this;
		this.modelCarga= true;
		let header={"Authorization" : "Bearer " + this.$store.state.token};
		let configuracion= {headers : header};
			axios.delete('api/LocalidadesAsociadas/'+item.id,configuracion).then(function(response){
				//console.log(response);
				me.listarAsociadas();
				me.modelCarga = false;
				}).catch(function(error){
					console.log(error);
				});
			
	},
	AgregarRegistro(){
		let me =this;
		let date = new Date(); // Obtiene la fecha y hora actual

		// Obtiene los componentes de la fecha y hora
		let year = date.getFullYear();
		let month = String(date.getMonth() + 1).padStart(2, '0'); // Se suma 1 al mes porque los meses en JavaScript van de 0 a 11
		let day = String(date.getDate()).padStart(2, '0');
		let hours = String(date.getHours()).padStart(2, '0');
		let minutes = String(date.getMinutes()).padStart(2, '0');
		let seconds = String(date.getSeconds()).padStart(2, '0');

		// Crea el string con la fecha y hora en el formato deseado
		let dateString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
		this.modelCarga= true;
		let header={"Authorization" : "Bearer " + this.$store.state.token};
		let configuracion= {headers : header};
			axios.post('api/LocalidadesAsociadas',{
				'LocaldiadAsociada': this.localidad,
				'Localidad': this.$store.state.usuario.localidad,
				'Fecha':dateString,
				'Usuario':this.usuario
			},configuracion).then(function(response){
				//console.log(response);
				me.listarAsociadas();
				me.modelCarga = false;
				}).catch(function(error){
					console.log(error);
				});
	},
	listarLocalidad1(){
            let me =this;
            let LogoTest= '';
            let self = this;
			this.usuario = this.$store.state.usuario.nombre;
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuracion= {headers : header};
                axios.get('api/Localidades/ListarFiltrada/'+me.$store.state.usuario.localidad,configuracion).then(function(response){
                    //console.log(response);
                    me.localidadencabezado=response.data;
					me.Logo2 = me.localidadencabezado[0].imagen;
					me.nombre = me.localidadencabezado[0].nombre;
					me.cedula = me.localidadencabezado[0].cedulaJuridica;
					me.razonSocial = me.localidadencabezado[0].razonSocial;
					me.modoPDV = me.localidadencabezado[0].modoPDV;
					me.correo = me.localidadencabezado[0].correo;
					me.dirreccion = me.localidadencabezado[0].dirreccion;
					me.telefono = me.localidadencabezado[0].telefono;
					me.ShowNoFact = me.localidadencabezado[0].express; // Si este campo viene true se muestra el NoFactura del la clave numerica, sino se muestra el NoFactura interno
					me.Cuentas = me.localidadencabezado[0].latitud; // este campo contiene los numero de cuentas bancarios
					me.formula =me.localidadencabezado[0].formula;
					me.longitud =me.localidadencabezado[0].longitud;
					me.porcentaje =me.localidadencabezado[0].porcentaje;
					me.provincia =me.localidadencabezado[0].provincia;
					me.canton =me.localidadencabezado[0].canton;
					me.tipoCedula =me.localidadencabezado[0].tpcedula;
					me.modelCarga = false;
                    }).catch(function(error){
                        console.log(error);
                    });
                
        },
		SavePrinter(NombreImpresora){
			var self = this;
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			axios.post('api/Impresoras/Crear',{
				'NombreImpresora': NombreImpresora,
				'Ip': this.ip1,
				'Devid': this.devid1,
				'Localidad': this.$store.state.usuario.localidad,
				
			},configuracion).then(function (response) {
				self.Mensaje = "Impresora de "+NombreImpresora+" asignada con éxito";
				self.snackbar = true;
				if(NombreImpresora == "Caja"){
					localStorage.setItem("printer1",self.ip1)
					localStorage.setItem("devid1",self.devid1)
					self.existeprinter1 = true;
				} else
				if(NombreImpresora == "Cocina"){
					localStorage.setItem("printer2",self.ip2)
					localStorage.setItem("devid2",self.devid2)
					self.existeprinter2 = true;
				}

			})
			.catch(function (error) {
				alert(`Lo sentimos ha ocurrido un error, notifiuelo al 4070-1889`+ error);  
			})
			
		},
		SavePrinterCocina(NombreImpresora){
			var self = this;
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			axios.post('api/Impresoras/Crear',{
				'NombreImpresora': NombreImpresora,
				'Ip': this.ip2,
				'Devid': this.devid2,
				'Localidad': this.$store.state.usuario.localidad,
				
			},configuracion).then(function (response) {
				self.Mensaje = "Impresora de "+NombreImpresora+" asignada con éxito";
				self.snackbar = true;

			})
			.catch(function (error) {
				alert(`Lo sentimos ha ocurrido un error, notifiuelo al 4070-1889`+ error);  
			})
			
		},
		SavePrinterBar(NombreImpresora){
			var self = this;
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			axios.post('api/Impresoras/Crear',{
				'NombreImpresora': NombreImpresora,
				'Ip': this.ip3,
				'Devid': this.devid3,
				'Localidad': this.$store.state.usuario.localidad,
				
			},configuracion).then(function (response) {
				self.Mensaje = "Impresora de "+NombreImpresora+" asignada con éxito";
				self.snackbar = true;

			})
			.catch(function (error) {
				alert(`Lo sentimos ha ocurrido un error, notifiuelo al 4070-1889`+ error);  
			})
			
		},
		ListarImpresoras(){  
				let me=this;
				var localidadesArray=[];
				let header={"Authorization" : "Bearer "};
				let configuracion= {headers : header};
				axios.get('api/Impresoras/ListarLocalidad/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
					localidadesArray=response.data;

					 for(var i = 0; i < localidadesArray.length; ++i){
						if(localidadesArray[i].nombreImpresora == "Caja"){
							me.ip1 = localidadesArray[i].ip
							me.devid1 = localidadesArray[i].devid
							me.existeprinter1 = true;
						} else
						if(localidadesArray[i].nombreImpresora == "Cocina"){
							me.ip2 = localidadesArray[i].ip
							me.devid2 = localidadesArray[i].devid
							me.existeprinter2 = true;
						} else
						if(localidadesArray[i].nombreImpresora == "Bar"){
							me.ip3 = localidadesArray[i].ip
							me.devid3 = localidadesArray[i].devid
							me.existeprinter3 = true;
						}
					 }
				})
		},
		
		verLocalidad(item){
		this.NombreLocalidad= item.nombre;
		this.CedulaLocalidad= item.cedulaJuridica;
		this.DireccionLocalidad= item.dirreccion;
		},
        OcultarComprobante(){
            this.comprobanteModal = 0;
        },
		UpdatePrinter(NombreImpresora){
			var me = this;
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			axios.post('api/Impresoras/Actualizar',{
				'NombreImpresora': NombreImpresora,
				'Ip': this.ip1,
				'Devid': this.devid1,
				'Localidad': this.$store.state.usuario.localidad,
			},configuracion).then(function (response) {
				me.Mensaje  = "Actualización realizada con éxito"
				me.snackbar = true;
			})
			.catch(function (error) {
				alert("Ocurrió un problema al actualizar la impresora, reporte a su técnico");
			})
		},
		UpdatePrinterCocina(NombreImpresora){
			var me = this;
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			axios.post('api/Impresoras/Actualizar',{
				'NombreImpresora': NombreImpresora,
				'Ip': this.ip2,
				'Devid': this.devid2,
				'Localidad': this.$store.state.usuario.localidad,
			},configuracion).then(function (response) {
				me.Mensaje  = "Actualización realizada con éxito"
				me.snackbar = true;
			})
			.catch(function (error) {
				alert("Ocurrió un problema al actualizar la impresora, reporte a su técnico");
			})
		},
		UpdatePrinterBar(NombreImpresora){
			var me = this;
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			axios.post('api/Impresoras/Actualizar',{
				'NombreImpresora': NombreImpresora,
				'Ip': this.ip3,
				'Devid': this.devid3,
				'Localidad': this.$store.state.usuario.localidad,
			},configuracion).then(function (response) {
				me.Mensaje  = "Actualización realizada con éxito"
				me.snackbar = true;
			})
			.catch(function (error) {
				alert("Ocurrió un problema al actualizar la impresora, reporte a su técnico");
			})
		},
		UpdateTipoCambio(){
			var me = this;
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			axios.post('api/ParametrosEmisors/Actualizar',{
				'TipoCambio': this.TipoCambioInterno,
				'localidad': this.$store.state.usuario.localidad,
			},configuracion).then(function (response) {
				me.Mensaje  = "Actualización de tipo cambio realizado con éxito"
				me.snackbar = true;
				//location.reload();
			})
			.catch(function (error) {
				alert("Ocurrió un problema al actualizar el tipo de cambio, reporte a su técnico");
			})
		},
		UpdatePanelControl(){
			var me = this;
			this.modelCarga = true;
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			axios.put('api/PanelControls/PutPanelControl',{
				'ModoCantidades': this.ModoCant,
				'ImpServ': this.AddImpServ,
				'AgregarCompuesto': this.AgregarCompuesto,
				'localidad': this.$store.state.usuario.localidad,
				'Moneda':this.monedaPago,
				'MostrarTeclado':this.VendDocRequisito, // Se utiliza para hacer obligatorio la seleccion de vendedor y tipo Doc
				'Recibos':this.ActivarRecibos,
				'Multilocalidad':this.Multilocalidad,
				'Otro':this.Stock0,
				'Multiplo':this.Multiplo,
				'Foto':this.Foto,
				'Comanda':this.Comanda,
				'ComandaDigital':this.ComandaDigital,
				'Receta':this.Receta,
				'PuntosCredito':this.PuntosCredito,
				'CierreGlobal':this.CierreGlobal,
				'Apertura':this.Apertura,
			},configuracion).then(function (response) {
				me.Mensaje  = "Actualización del panel de control realizado con éxito"
				me.snackbar = true;
				me.modelCarga = false;
				//location.reload();
			})
			.catch(function (error) {
				me.modelCarga = false;
				alert("Ocurrió un problema al actualizar el tipo de cambio, reporte a su técnico");
			})
		},
	ActualizarLocalidad(){
		this.modelCarga = true;
		let me = this;
		let header={"Authorization" : "Bearer " + this.$store.state.token};
		let configuracion= {headers : header};
			axios.put('api/Localidades/ActualizarLocalidad',{
				'Nombre':me.nombre,
				'CedulaJuridica':me.cedula,
				'Telefono':me.telefono,
				'Provincia': me.provincia,
				'Canton':me.canton,
				'RazonSocial':me.razonSocial,
				'CodigoLocalidad': "0",
				'Porcentaje':me.porcentaje,
				'Formula':me.formula,
				'Correo':me.correo,
				'tpcedula':me.tipoCedula,
				'Imagen':me.Logo2, 
				'Latitud':me.Cuentas, 
				'ModoPDV':me.modoPDV, 
				'Dirreccion':me.dirreccion
			},configuracion).then(function(response){
				me.modelCarga = false; 
				me.Mensaje = 'Datos Actualizados con éxito';
				me.snackbar = false;                     
			}).catch(function(error){
				me.modelCarga = false; 
				me.Mensaje = '^Problema al actualizar la información, consulte a su equipo de soporte';
				me.snackbar = false;      
				console.log(error);
			});
	},
     limpiar(){
                this.MensajeRespuesta="";
                this.ClaveComprobante="";
                this.localidad="";
                this.detalles=[];
                this.name = '',
                this.details = '',
                this.start = '',
                this.end = '',
                this.color = '',
                this.hora = '',
                this.hora2 = '',
                this.codigo = ''
    },  
	validarIdioma(){
		if(localStorage.getItem("idioma") == null) {
			this.Pais = "ESP";
			return;
		}

		this.Pais = localStorage.getItem("idioma");
	},
	idioma(){
		localStorage.setItem("idioma",this.Pais)
		location.reload();
	},
	
	close () {
		this.dialog = false;
		this.limpiar();
	},
	save () {
		if (this.editedIndex > -1) {
			Object.assign(this.desserts[this.editedIndex], this.editedItem)
		} else {
			this.desserts.push(this.editedItem)
		}
		this.close()
	},
    },
  }
</script>
My CRUD